import { Box, Image, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import './style.css';
import DetailsPopup from './DetailsPopup';
import { useToast, useDisclosure } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

//import { Swiper, SwiperSlide } from '../../../../node_modules/swiper/react';

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper-bundle.min.css';
SwiperCore.use([Pagination, Navigation]);

const HorizontalScroll = props => {
  ////debugger;
  useEffect(() => {
    setlist(props?.todaySpecialsList);
  }, [props.todaySpecialsList]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const [itemDetails, setitemDetails] = useState(null);
  const [list, setlist] = useState(props?.todaySpecialsList);
  const settings = {
    //  centerMode: true,

    arrows: true,
    infinite: false,
    speed: 500,
    adaptiveHeight: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoPlay: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          //  infinite: true,
          // centerMode: true,
          centerPadding: '20px',
          slidesToShow: 4,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          //    infinite: true,
          //centerMode: true,
          centerPadding: '20px',
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          //   infinite: true,
          // centerMode: true,
          centerPadding: '20px',
          slidesToShow: 1,
          swipeToSlide: true,
        },
      },
    ],
  };

  return (
    <Box
      w="100%"
      className={props.fromTown == true ? 'todays-special-slider' : ''}
    >
      {itemDetails != null ? (
        <DetailsPopup
          upcomingSpecialsList={props.todaySpecialsList}
          onUpcomingSpecialHeart={props.onUpcomingSpecialHeart}
          onUpcomingSpecialBookMark={props.onUpcomingSpecialBookMark}
          itemDetails={itemDetails}
          open={isOpen}
          close={onClose}
        />
      ) : (
        ''
      )}
      {props.heading ? (
        <Text mb="5px" fontWeight="bold" className='user-title'>
          {props.heading}
          <span>{' ('}
          {props.todaySpecialsList?.length}
          {props.restaurantList?.length}
          {props.favoriteRestaurantList?.length}
          {props.popularDishesList?.length}
          {')'}</span>
        </Text>
      ) : (
        ''
      )}
      <Box className="special-slider filter-slide">
        {list?.length > 0 ? (
          <Swiper
            slidesPerView={3}
            
            breakpoints={{
              370: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1400: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
            slidesPerGroup={3}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            className="mySwiper"
          >
            {/* <Slider  {...settings}> */}
            {list?.map((item, index) => (
              <SwiperSlide>
                <Box
                  data-index={index}
                  key={index}
                  className="carousel-item special-box-height"
                >
                  <Image
                    className="special-image"
                    cursor="pointer"
                    onClick={() => {
                      //debugger;
                      onOpen();
                      setitemDetails(item);
                    }}
                    // src={require('../../../assets/images/cuisineImagesNew/DemoDish.jpg').default}
                    src={
                      item.MENU_IT_PIC_LOCATION == '' ||
                      item.MENU_IT_PIC_LOCATION == null
                        ? require('../../../assets/images/cuisineImagesNew/DemoDish.jpg')
                            .default
                        : item.MENU_IT_PIC_LOCATION
                    }
                    alt="Segun Adebayo"
                  />
                  <Box pt="15px">
                    <Text fontSize="16px" mb="5px" fontWeight="500" color="#3D3D3D">
                      {item.MENU_IT_NAME}
                    </Text>
                    <Text fontSize="14px" color="#747474">{item.MENU_IT_DESC}</Text>
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : null}

        {props.restaurantList?.length > 0 ? (
          <Swiper
            slidesPerView={3}
            breakpoints={{
              370: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              
              1400: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
            slidesPerGroup={3}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            className="mySwiper"
          >
            {/* <Slider  {...settings}> */}
            {props.restaurantList?.map(item => (
              <SwiperSlide>
                <Box className="carousel-item">
                  <Image
                    cursor="pointer"
                    className="special-image"
                    onClick={() => {
                      history.push({
                        //pathname: `/Admin/${localStorage.getItem('Currentpage')}`,
                        pathname: '/RestaurantMenu/' + item?.REST_ID,
                        from: 'SpotlightRestaurants',
                        //  state: { email:props.userinfo?.email, password: props.userinfo?.pass }
                      });

                      setitemDetails(item);
                    }}
                    // src={require('../../../assets/images/cuisineImagesNew/DemoRes.jpg').default}
                    //src={item.MENU_IT_PIC_LOCATION == '' || item.MENU_IT_PIC_LOCATION == null ? require('../../../assets/images/cuisineImagesNew/DemoDish.jpg').default : item.MENU_IT_PIC_LOCATION}
                    src={
                      item.REST_PIC_LOCATION == '' ||
                      item.REST_PIC_LOCATION == null
                        ? require('../../../assets/images/cuisineImagesNew/DemoRes.jpg')
                            .default
                        : item.REST_PIC_LOCATION
                    }
                    alt="Segun Adebayo"
                  />
                  <Box pt="15px">
                  <Text fontSize="16px" mb="5px" fontWeight="500" color="#3D3D3D">
                      {item.REST_NAME}
                    </Text>
                    <Text fontSize="14px" color="#747474">{item.CITY_NAME}</Text>
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : null}

        {props.favoriteRestaurantList?.length > 0 ? (
          <Swiper
            slidesPerView={3}
            breakpoints={{
              370: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              
              1400: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
            slidesPerGroup={3}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            className="mySwiper"
          >
            {/* <Slider  {...settings}> */}
            {props.favoriteRestaurantList?.map(item => (
              <SwiperSlide>
                <Box className="carousel-item">
                  <Image
                    cursor="pointer"
                    className="special-image"
                    onClick={() => {
                      history.push({
                        //pathname: `/Admin/${localStorage.getItem('Currentpage')}`,
                        pathname: '/RestaurantMenu/' + item?.REST_ID,
                        from: 'SpotlightRestaurants',
                        //  state: { email:props.userinfo?.email, password: props.userinfo?.pass }
                      });
                      setitemDetails(item);
                    }}
                    // src={require('../../../assets/images/cuisineImagesNew/DemoRes.jpg').default}
                    src={
                      item.REST_PIC_LOCATION == '' ||
                      item.REST_PIC_LOCATION == null
                        ? require('../../../assets/images/cuisineImagesNew/DemoRes.jpg')
                            .default
                        : item.REST_PIC_LOCATION
                    }
                    alt="Segun Adebayo"
                  />
                  <Box pt="15px">
                  <Text fontSize="16px" mb="5px" fontWeight="500" color="#3D3D3D">
                      {item.REST_NAME}
                    </Text>
                    <Text fontSize="14px" color="#747474">{item.CITY_NAME}</Text>
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : null}

        {props.popularDishesList?.length > 0 ? (
          <Swiper
            slidesPerView={3}
            breakpoints={{
              370: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              
              1400: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}
            slidesPerGroup={3}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            className="mySwiper"
          >
            {/* <Slider  {...settings}> */}
            {props.popularDishesList?.map(item => (
              <SwiperSlide>
                <Box className="carousel-item">
                  <Image
                    cursor="pointer"
                    className="special-image"
                    // onClick={() => {
                    //   history.push({
                    //     //pathname: `/Admin/${localStorage.getItem('Currentpage')}`,
                    //     pathname: '/RestaurantMenu/' + item?.REST_ID,
                    //     from: 'SpotlightRestaurants',
                    //     //  state: { email:props.userinfo?.email, password: props.userinfo?.pass }
                    //   });
                    //   setitemDetails(item);
                    // }}
                    // src={require('../../../assets/images/cuisineImagesNew/DemoRes.jpg').default}
                     onClick={() => {
                      onOpen();
                      setitemDetails(item);
                    }}
                    src={
                      item.MENU_IT_PIC_LOCATION == '' ||
                      item.MENU_IT_PIC_LOCATION == null
                        ? require('../../../assets/images/cuisineImagesNew/DemoDish.jpg')
                            .default
                        : item.MENU_IT_PIC_LOCATION
                    }
                    alt="Segun Adebayo"
                  />
                  <Box pt="15px">
                  <Text fontSize="16px" mb="5px" fontWeight="500" color="#3D3D3D">
                      {item.MENU_IT_NAME}
                    </Text>
                    <Text fontSize="14px" color="#747474">{item.REST_NAME} , {item.CITY_NAME}</Text>
                  </Box>
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : null}
      </Box>
    </Box>
  );
};
export default HorizontalScroll;
