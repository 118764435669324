import {
  Box,
  Button,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  FormLabel,
  Input,
  FormControl,
  Select,
  HStack,
  Text,
  Stack,
  EditablePreview,
  Badge,
} from '@chakra-ui/react';
import { Multiselect } from 'multiselect-react-dropdown';
import React, { useRef, useState, useEffect } from 'react';
import * as yup from 'yup';
import { Formik, Form, Field, FieldArray, useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { Storage, Auth, Hub, API, graphqlOperation } from 'aws-amplify';
import PreviewPopup from '../components/PreviewPopup';
import { useToast, useDisclosure } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { getMenuChoices as GetMenuChoices } from '../graphql/queries';

let ValidationSchema = yup.object().shape({
  itemname: yup.string().required('Name is required'),
  description: yup
    .string()
    .max(100, 'Item description must be 100 characters or less'),
  price: yup.number().required('price is required'),
  status: yup.string(),
  //.required('Status is required'),
  customization: yup.array(),
  photo: yup.string(),
});

function EditPreview() {
  const a = 10;
}

function MenuUpdatePopup(props) {
  const [menuChoice, SetMenuChoice] = useState([]);
  const [oldMenu, setOldMenu] = useState([]);
  const [isPopularCheck, setIsPopularCheck] = useState(false);
  const [imageFile, setiimageFile] = useState(null);
  const [choiseData, setChoiceData] = useState();
  const [loadForm, setLoadForm] = useState(true);
  const [selectedImg, setSelectedImg] = useState(null);

  const initialRef = React.useRef();
  const finalRef = React.useRef();
  let imagePath = '';

  useEffect(() => {
    //debugger;
    if (props?.object) {
      getmenuCustomization();
      let arr = [];
      // debugger;
      const data = props.customizeData?.map(item => {
        if (item.CH_ID && item.CH_DESC) {
          arr.push({
            id: item.CH_ID,
            name:
              item.BASE_MODIFIER_IND === true && item.CH_LABEL
              ? item.CH_DESC + '* (' + item.CH_LABEL + ')'
              : item.BASE_MODIFIER_IND != true && item.CH_LABEL ?
              item.CH_DESC + ' (' + item.CH_LABEL + ')'
              : item.BASE_MODIFIER_IND === true && !item.CH_LABEL ?
              item.CH_DESC + '*'
              : item.CH_DESC
          });
        }
      });
      console.log('choise data', arr);
      console.log(props.customizeData);
      setChoiceData(arr);
    }
  }, [props?.object]);

  async function savetoS3(file) {
    if (file) {
      const productName = file.name.split('.')[0];
      const extension = file.name.split('.')[1];
      const { type: mimeType } = file;

      // const imageData = await fetch(file)
      // const blobData = await imageData.blob()

      // const extension = selectedImage.uri.split(/[#?]/)[0].split('.').pop().trim();
      //       console.log("extension--------->" + extension);
      //       const { type: mimeType } = selectedImage
      //       const key = `images/${uuid.v4()}item-special.${extension}`
      //       const ImgUrl = `https://image.squash.app/public/${key}`
      //       console.log("ImgUrl--------->" + ImgUrl);

      //   const key = `images/${uuidv4()}${productName}.${extension}`;
      const key = `${uuidv4()}.${extension}`;
      // const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`
      //   const url = `https://image.squash.app/public/${key}`;
      const url = `https://imageprod.squash.app/public/${key}`;
      //  const inputData = { name: productName , image: url }
      imagePath = url;
      console.log(url);

      try {
        ////debugger;
        const b = 10;
        const res = await Storage.put(key, file, {
          contentType: 'image/png',
          //   mimeType
        });
        ////debugger;
        const a = 10;
        //  await API.graphql(graphqlOperation(CreateProduct, { input: inputData }))
      } catch (err) {
        ////debugger;
        console.log('error: ', err);
      }
    }
  }

  async function getmenuCustomization() {
    try {
      //debugger;
      setLoadForm(false);
      const result = await API.graphql(
        graphqlOperation(GetMenuChoices, {
          MENU_IT_ID: props?.object.MENU_IT_ID,
        })
      ); //NJGAR-R1
      console.log('choices', result?.data.getMENU_CHOICES);
      const s = result?.data.getMENU_CHOICES.map(itm => {
        return { id: itm.CH_ID, name:
			itm.BASE_MODIFIER_IND === true
			  ? itm.CH_DESC + '* (' + itm.CH_LABEL + ')'
			  : itm.CH_DESC + ' (' + itm.CH_LABEL + ')', };
      });
      SetMenuChoice(s);
      setOldMenu([...s]);
      setLoadForm(true);
    } catch (err) {
      console.log(err);
    }
  }

  const {
    isOpen: isOpenPreviewModal,
    onOpen: onOpenPreviewModal,
    onClose: onClosePreviewModal,
  } = useDisclosure();

  
  const readURL = file => {
    return new Promise((res, rej) => {
      const reader = new FileReader();
      reader.onload = e => res(e.target.result);
      reader.onerror = e => rej(e);
      reader.readAsDataURL(file);
    });
  };

  // if (props.menuChoice.length > 0) {
  return (
    <>
      <Modal
        size={('sx', 'md', 'xl', 'xl')}
        isOpen={props.open}
        onClose={props.close}
        scrollBehavior="inside"
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
      >
        <ModalOverlay />
        <ModalContent className="mpopup">
          <ModalHeader>Update Menu</ModalHeader>
          <ModalCloseButton className="popup-btn" />
          <ModalBody pb={6}>
            {props.menuChoice.length > 0 ? (
              <PreviewPopup
                menuChoice={props?.menuChoice}
                menu={props.object}
                open={isOpenPreviewModal}
                close={onClosePreviewModal}
              />
            ) : (
              ''
            )}
            {loadForm && (
              <Formik
                initialValues={{
                  itemname: props.object?.MENU_IT_NAME,
                  description: props.object?.MENU_IT_DESC,
                  price: props.object?.MENU_IT_PRICE,
                  status: false,
                  popular_status: false,
                  item_status: props.object?.MENU_IT_STATUS,
                  item_taxable: props.object?.MENU_IT_TAXABLE_YN != 'N',
                  photo: '',
                  customization: menuChoice,
                }}
                validationSchema={ValidationSchema}
                onSubmit={(value, { setSubmitting }) => {
                  //debugger;
                  savetoS3(imageFile);
                  console.log(value);

                  let str = JSON.stringify(String(value.description));
                  str = str.substring(1, str.length - 1);

                  const inputData = {
                    REST_ID: localStorage.getItem('Restaurantid'),
                    MENU_IT_ID: props.object?.MENU_IT_ID,
                    MENU_IT_NAME: value.itemname,
                    MENU_IT_DESC: str ? str : ' ',
                    MENU_IT_PRICE: value.price,
                    POPULAR_YN: value.popular_status == false ? 'N' : 'Y',
                    MENU_IT_STATUS:
                      value.item_status,
                    MENU_IT_PIC_LOCATION: imagePath,
                    MENU_IT_TAXABLE_YN: value.item_taxable == false ? 'N' : 'Y',
                  };
                  props.UpdateMenu(inputData, value.customization, oldMenu);
                  setSubmitting(false);
                }}
              >
                {formik => {
                  return (
                    <form onSubmit={formik.handleSubmit}>
                      <FormControl>
                        <FormLabel>Name</FormLabel>
                        <Input
                          type="text"
                          onChange={formik.handleChange('itemname')}
                          onBlur={formik.handleBlur('itemname')}
                          value={formik.values.itemname}
                          error={formik.errors.itemname}
                          touched={formik.touched.itemname}
                          ref={initialRef}
                          //  ref={formRef}
                        />
                        {formik.errors.itemname && formik.touched.itemname && (
                          <Text className="validationColor">
                            {' '}
                            {formik.errors.itemname}
                          </Text>
                        )}
                      </FormControl>

                      <FormControl mt={4}>
                        <FormLabel>Description</FormLabel>
                        <Input
                          type="text"
                          onChange={formik.handleChange('description')}
                          onBlur={formik.handleBlur('description')}
                          value={formik.values.description}
                          error={formik.errors.description}
                          touched={formik.touched.description}
                        />
                        {formik.errors.description &&
                          formik.touched.description && (
                            <Text className="validationColor">
                              {' '}
                              {formik.errors.description}
                            </Text>
                          )}
                      </FormControl>

                      <Box display="flex">
                        <FormControl mt={4} mr={4}>
                          <FormLabel>Price ($)</FormLabel>
                          <Input
                            type="number"
                            onChange={formik.handleChange('price')}
                            onBlur={formik.handleBlur('price')}
                            value={formik.values.price}
                            error={formik.errors.price}
                            touched={formik.touched.price}
                          />
                          {formik.errors.price && formik.touched.price && (
                            <Text className="validationColor">
                              {' '}
                              {formik.errors.price}
                            </Text>
                          )}
                        </FormControl>

                        {/* <FormControl mt={4} ml={4} >
												<FormLabel>Status</FormLabel>

												<Select
													onChange={formik.handleChange('status')}
													onBlur={formik.handleBlur('status')}
													value={formik.values.status ? 'true' : 'false'}
													error={formik.errors.status}
													touched={formik.touched.status}
													placeholder="Select">
													<option value="true" label='Active'>Active</option>
													<option value="false" label='Non active'>Non Active</option>

												</Select>
												{formik.errors.status && formik.touched.status && <Text className="validationColor">{" "} {formik.errors.status}</Text>}
											</FormControl> */}
                      </Box>
                      <Box display="flex">
                        <FormControl mt={4} mr={4}>
                          {/* <FormLabel >Price ($)</FormLabel> */}
                          {/* <Input
													type='checkbox'
													onChange={formik.handleChange('price')}
													onBlur={formik.handleBlur('price')}
													value={formik.values.price}
													error={formik.errors.price}
													touched={formik.touched.price}
												/> */}
                          {/* {formik.errors.price && formik.touched.price && <Text className="validationColor">{" "} {formik.errors.price}</Text>} */}
                          <FormLabel mr={4} display="flex" alignItems="center">
                            <input
                              type="checkbox"
                              // onChange={formik.handleChange('popular_status')=='checked'? setIsPopularCheck(true) : setIsPopularCheck(false)}
                              onChange={formik.handleChange('popular_status')}
                              onBlur={formik.handleBlur('popular_status')}
                              checked={formik.values.popular_status}
                              value={formik.values.popular_status}
                              error={formik.errors.popular_status}
                              touched={formik.touched.popular_status}
                            />
                            {formik.errors.popular_status &&
                              formik.touched.popular_status && (
                                <Text className="validationColor">
                                  {' '}
                                  {formik.errors.popular_status}
                                </Text>
                              )}

                            <Text ml={2}>Popular Item</Text>
                          </FormLabel>
                        </FormControl>
                      </Box>
                      <Box display="flex">
                        <FormControl mt={4} mr={4}>
                          {/* <FormLabel >Price ($)</FormLabel> */}
                          {/* <Input
													type='checkbox'
													onChange={formik.handleChange('price')}
													onBlur={formik.handleBlur('price')}
													value={formik.values.price}
													error={formik.errors.price}
													touched={formik.touched.price}
												/> */}
                          {/* {formik.errors.price && formik.touched.price && <Text className="validationColor">{" "} {formik.errors.price}</Text>} */}
                          <FormLabel mr={4} alignItems="center">
                            {/* <input
                              type="checkbox"
                              // onChange={formik.handleChange('popular_status')=='checked'? setIsPopularCheck(true) : setIsPopularCheck(false)}
                              onChange={formik.handleChange('item_status')}
                              onBlur={formik.handleBlur('item_status')}
                              checked={formik.values.item_status}
                              value={formik.values.item_status}
                              error={formik.errors.item_status}
                              touched={formik.touched.item_status}
                            /> */}
							<FormLabel>Status</FormLabel>
							<Select
								onChange={formik.handleChange('item_status')}
								onBlur={formik.handleBlur('item_status')}
								value={formik.values.item_status}
								error={formik.errors.item_status}
								touched={formik.touched.item_status}

							>
								<option value="ACTIVE">ACTIVE</option>
								<option value="NOT AVAILABLE">NOT AVAILABLE</option>
								<option value="SOLD OUT">SOLD OUT</option>


							</Select>
                            {formik.errors.item_status &&
                              formik.touched.item_status && (
                                <Text className="validationColor">
                                  {' '}
                                  {formik.errors.item_status}
                                </Text>
                              )}

                          </FormLabel>
                        </FormControl>
                      </Box>
                      <Box display="flex">
                        <FormControl mt={4} mr={4}>
                          {/* <FormLabel >Price ($)</FormLabel> */}
                          {/* <Input
													type='checkbox'
													onChange={formik.handleChange('price')}
													onBlur={formik.handleBlur('price')}
													value={formik.values.price}
													error={formik.errors.price}
													touched={formik.touched.price}
												/> */}
                          {/* {formik.errors.price && formik.touched.price && <Text className="validationColor">{" "} {formik.errors.price}</Text>} */}
                          <FormLabel mr={4} display="flex" alignItems="center">
                            <input
                              type="checkbox"
                              // onChange={formik.handleChange('popular_status')=='checked'? setIsPopularCheck(true) : setIsPopularCheck(false)}
                              onChange={formik.handleChange('item_taxable')}
                              onBlur={formik.handleBlur('item_taxable')}
                              checked={formik.values.item_taxable}
                              value={formik.values.item_taxable}
                              error={formik.errors.item_taxable}
                              touched={formik.touched.item_taxable}
                            />
                            {formik.errors.item_taxable &&
                              formik.touched.item_taxable && (
                                <Text className="validationColor">
                                  {' '}
                                  {formik.errors.item_taxable}
                                </Text>
                              )}

                            <Text ml={2}>Taxable?</Text>
                          </FormLabel>
                        </FormControl>
                      </Box>
                      <FormControl mt={4}>
                        <FormLabel display="inline-block">
                          Photo Upload
                        </FormLabel>
                        <Box display="flex">
						<div style={{ display: 'flex' }}>
                          {/* <Box className="restaurant-file-img"></Box> */}
                          <Box className="restaurant-file-upload">
                            <FontAwesomeIcon icon={faPlus} />
                            <Input
                              type="file"
                              onChange={async e => {
                                const file = e.target.files[0];
                                setiimageFile(file);
								const url = await readURL(file);
                                setSelectedImg(url);
                                //    savetoS3(file);
                              }}
                              onBlur={formik.handleBlur('photo')}
                              value={formik.values.photo}
                              error={formik.errors.photo}
                              touched={formik.touched.photo}
                            />
                            {formik.errors.photo && formik.touched.photo && (
                              <Text className="validationColor">
                                {' '}
                                {formik.errors.photo}
                              </Text>
                            )}
                          </Box>
                          {(selectedImg || imagePath) && (
                            <img
                              height="80"
                              style={{ height: '85px', marginLeft: 15 }}
                              alt="spcial item"
                              src={selectedImg ? selectedImg : imagePath}
                            />
                          )}
						  </div>
                        </Box>
                      </FormControl>

                      <FormControl mt={4}>
                        <FormLabel>Modifier Group</FormLabel>

                        {/* {choiseData && ( */}
                        <Multiselect
                          options={choiseData}
                          selectedValues={formik.values.customization}
                          onSelect={(selectedList, selectedItem) => {
                            formik.values.customization.push(selectedItem);

                            //  console.log(formik.values.customization)
                          }}
                          displayValue="name"
                          onRemove={(selectedList, removedItem) => {
                            formik.values.customization.pop(removedItem);
                            //  console.log(formik.values.customization)
                          }}
                        />
                        {/* )} */}
                      </FormControl>

                      {/* <Stack direction="row">
                          {props.menuChoice?.map(item => (
                            <Badge>{item.CH_DESC}</Badge>
                          ))}
                        </Stack> */}

                      <Box className="popup-buttons">
                        {/* <Button w='90px' bg="#00B2FF" className="secondary-btn" color='#fff' mr={2} onClick={props.close}>Cancel</Button> */}
                        <Button
                          color="red.500"
                          mr={2}
                          onClick={() => {
                            props.onPromoOpen();
                            props.setPromoObject(props.object);
                          }}
                        >
                          Special Promo
                        </Button>
                        <Button
                          w="90px"
                          className="secondary-btn"
                          mr={2}
                          onClick={onOpenPreviewModal}
                        >
                          Preview
                        </Button>

                        <Button
                          w="90px"
                          className="primary-btn"
                          type="submit"
                          ml={2}
                        >
                          Save
                        </Button>
                      </Box>
                    </form>
                  );
                }}
              </Formik>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
  // } else return null;
}

export default MenuUpdatePopup;
