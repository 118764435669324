import {
	Box,
	Button,
	FormControl,
	FormLabel,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
	Table,
	Tbody,
	Tr,
	Td,
	Thead,
	Th,
	Input,
	Image,
	Spinner,
	Text

} from "@chakra-ui/react";
import React, { useRef, useState, useEffect } from "react";
import { Auth, Hub, API, graphqlOperation, formContainer } from 'aws-amplify';
import { useToast, useDisclosure } from "@chakra-ui/react"
import Sidebar from '../../../components/sidebar'
import Header from '../../../components/Header'
import { EditIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons'
import ExtrafoddIcon from '../../../assets/images/extrafood.png'
import { getPollsByRest as GetPollsByRest } from '../../../graphql/queries'
import { listMasterMenuChoices as ListMasterMenuChoices } from '../../../graphql/queries'
import { updateMasterMenuChoiceItems as UpdateMasterMenuChoiceItems } from '../../../graphql/mutations'
import { updateMasterMenuChoices as UpdateMasterMenuChoices } from '../../../graphql/mutations'

import { createRestPollMaster as CreateRestPollMaster } from '../../../graphql/mutations'

import { updateRestPollMaster as UpdateRestPollMaster } from '../../../graphql/mutations'
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import AddPollsPopup from '../../../components/AddPollsPopup'
import moment from 'moment'


function Polls() {

	const [togglebit, setTogglebit] = useState(false);
	const [item, setItem] = useState([])
	const [pollitem, setPollItem] = useState([])


	const [inEditMode, setInEditMode] = useState({
		status: false,
		rowKey: null
	});
	const [Price, setPrice] = useState(null);
	const [label, setLabel] = useState('');

	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingChoice, setIsLoadingChoice] = useState(false);
	const [isLoadingChoiceItem, setIsLoadingChoiceItem] = useState(false);


	const [editObject, setEditObject] = useState(null);
	const toast = useToast();

	useEffect(() => {
		PollsData();
		//CustomizeData();
	}, []);


	function toggle() {
		setTogglebit(current => !current);
	}


	async function PollsData() {

		try {
			//debugger;

			setIsLoading(true)
 
			const result = await API.graphql(graphqlOperation(GetPollsByRest, { REST_ID:localStorage.getItem('Restaurantid') }))

			console.log(result);
			setPollItem(result.data.getPOLLS_BY_REST)
		
			setIsLoading(false)

		}
		catch (err) {

			console.log(err)
		}
	}


	async function savepoll(data) {
		const a = 10;
		try {           
             let res=null;
			if(editObject)
			{
				data.POLL_ID=editObject.POLL_ID;
				 res = await API.graphql(graphqlOperation(UpdateRestPollMaster, { UpdateREST_POLL_MASTER : data }));
		
			}
			else
			{
				//debugger;
				res = await API.graphql(graphqlOperation(CreateRestPollMaster, { createREST_POLL_MASTERInput: data }));
		
			}
		
		
			
			
				onClose();
			 PollsData();
			 if(editObject)
			 showValidationMsg("Poll Successfully Updated", 'success')
			 else
			 showValidationMsg("Poll Successfully Added", 'success')
		}
		catch (err) {
			console.log('error: ', err)
			//showValidationMsg("Error occured", 'error')
		}

	}


	//Show toast for validation message method
	function showValidationMsg(msg, st) {
		toast({
			title: "",
			description: msg,
			status: st,
			duration: 4000,
			isClosable: true,
			position: "top"
		})

	}
	const { isOpen, onOpen, onClose } = useDisclosure()

	return (



		<Box bg='#f9f9f9'>
			<Helmet>
            <title>Squash | Polls</title>
          </Helmet>
			<Header isAdmin={true} toggle={toggle} />
			<Box pt='70px' position='relative' h='100vh' overflow='hidden' d='flex'>
				<Box zIndex='9' bg='#fff' position='absolute' top='0' pt='100px' left='0' h='100%'
				 w={{ base: (togglebit ? "200px" : '0px'), md: (togglebit ? "200px" : '0px'), lg: (togglebit ? "0px" : '200px') }} display={{ base: (togglebit ? "block" : 'none'), md: (togglebit ? "block" : 'none'), lg: (togglebit ? "none" : 'block') }}>
					<Sidebar current='Polls' togglebit={togglebit} />
				</Box>
				<Box ml={(togglebit ? "0px" : { base: "0", sm: "0", md: "0", lg: '200px' })} w='100%' p='20px' overflow='auto'>
					<Box className="card no-padding card-flex ">
						<Box className="card-header" position="relative">
							{/* <Box className="card-header-inner">
								<Box className="title-heading"><h2>Polls</h2></Box>
							</Box> */}
								<Box className="title-heading"><h2>Polls</h2></Box>
								<Box className="icons-wrapper specials-head">
									
									<Box>
										<Button onClick={() => { setEditObject(null); onOpen() }}  className='primary-button primary-btn addspecialbtn'><FontAwesomeIcon m='3px' icon={faPlus} /> <Text fontSize='14px'>Add Polls</Text> </Button>
									</Box>
							</Box>
						</Box>
						<Box className="menucustomtablemain card-content">

							<Box className="menucustomtablemaininr " >
								{isLoading ? (<Box className="rspinbox" > <Spinner
									thickness="4px"
									speed="0.65s"
									emptyColor="gray.200"
									color="blue.500"
									size="xl"
								/></Box >) :

									<Table className="mc-table">
										<Thead>
											<Tr>

												<Th w='23%' className="tablepadding sticky">Poll Name </Th>
												<Th w='15%' className='sticky menuscuzmobile' >Poll Status </Th>
												<Th w='15%' className='sticky menuscuzmobile'>Start Date </Th>
												<Th w='15%' className='sticky menuscuzmobile'>End Date </Th>
												<Th w='15%' className='sticky menuscuzmobile'>No of Respondents </Th>
												<Th w='13%' className='sticky'></Th>

											</Tr>
										</Thead>
										{isLoadingChoice ? (<Box className="rspinbox" > <Spinner
											thickness="4px"
											speed="0.65s"
											emptyColor="gray.200"
											color="blue.500"
											size="xl"
										/></Box >) : ''}
										<Tbody >
											<Tr>
												<Td colspan="6" className="mc-tablep0">
													<Accordion allowToggle className="accmenucusbox" p={0}>



														{pollitem ? (
															pollitem.map((obj, index) => (
																<AccordionItem >
																	<Table>
																		<Tbody>
																			<Tr 
																		//	key={CH_ID}
																			>
																				<AccordionButton fontSize='20px' className='polls-accbtn' >
																					<Td w='27%'>{obj.POLL_NAME}</Td>
																					<Td w='16%' className="menuscuzmobile  poll-status">{obj.POLL_STATUS == 'ACTIVE' ? <Text color="#00B507" bg="#def3e7">ACTIVE</Text> : <Text color="#EF0707" bg="#f6e2e7">Closed</Text>}</Td>
																					<Td w='17%' className="menuscuzmobile">{moment(new Date(obj.POLL_START_DATE_TIME * 1000)).format("DD-MMM-YYYY")}</Td>
																					<Td w='18%' className="menuscuzmobile">{moment(new Date(obj.POLL_END_DATE_TIME * 1000)).format("DD-MMM-YYYY")}</Td>
																					<Td w='15%' className="menuscuzmobile">{obj?.TOTAL_RESP}</Td>



																					<Td w='13%'>
																						<Link><EditIcon mt='10px' mr='10px' color="blue.500" onClick={() => {
																							onOpen();
																							setEditObject(pollitem[index])
																						}}

																						/></Link></Td>
																					{/* <AccordionButton fontSize='20px' mt='10px' w='30px' float='right' > */}
																					<AccordionIcon mt='10px' w='30px' float='right' />
																				</AccordionButton>
																			</Tr>
																		</Tbody>
																	</Table>

																	<AccordionPanel pb={4} >


																		<Table >
																			<Thead>
																				<Tr>
																					<Th >Choice</Th>
																					<Th >No of Respondents </Th>
																					<Th >Percentage of Respondents</Th>

																				</Tr>
																			</Thead>
																			{isLoadingChoiceItem ? (<Box className="rspinbox" > <Spinner
																				thickness="4px"
																				speed="0.65s"
																				emptyColor="gray.200"
																				color="blue.500"
																				size="xl"
																			/></Box >) : ''}

																			<Tbody >
																				{
																					obj?.relatedPollChoices.map((ob,ind)=>
                                            ob.POLL_CHOICE_VAL==''|| ob.POLL_CHOICE_VAL==null?
																					'':
																					<Tr>
																					<Td>{ob.POLL_CHOICE_VAL}</Td>
																					<Td>{ob.POLL_CHOICE_VOTES}</Td>
																					<Td>0%</Td>
																				</Tr>
																					
																					
																					)

																				}
																				

																			</Tbody>

																		</Table>
																	</AccordionPanel>


																</AccordionItem>

															))) : null}
													</Accordion>
												</Td>
											</Tr>
										</Tbody>

									</Table>
								}

							</Box >

						</Box>
					</Box>

				</Box>
			</Box>
			<AddPollsPopup savepoll={savepoll} object={editObject} open={isOpen} close={()=>{onClose();setEditObject(null)}} />
		</Box>

	)
}
export default Polls;
