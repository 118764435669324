import {
  Box,
  Button,
  Text,
  Spinner,
  IconButton,
  Input,
  FormControl,
  InputGroup,
  InputLeftElement,
  Link,
} from '@chakra-ui/react';
import React, { useEffect, useState, useRef } from 'react';
import UserSidebar from '../../src/components/UserSidebar';
import Header from './Header';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field, useFormik } from 'formik';
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import { EmailIcon, UnlockIcon } from '@chakra-ui/icons';
import { useToast, useDisclosure } from "@chakra-ui/react"
import moment from 'moment'
import * as yup from 'yup';
import Cookies from 'js-cookie';

import { Helmet } from 'react-helmet';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ValidationSchema = yup.object().shape({

  oldPassword: yup.string().required('Old Password is required'),
  newpassword: yup.string()
    .min(8, ({ min }) => `Password must be at least ${min} characters`)
    .max(30, "Password must be 30 characters or less")
    .required('Password is required'),
    confirmPassword: yup.string()
    .min(8, ({ min }) => `Password must be at least ${min} characters`)
    .max(30, "Password must be 30 characters or less")
    .required('Password is required'),

});

const ChangePassword = () => {
  const history = useHistory();
  const [cartData, setCartData] = useState([]);
  const [togglebit, setTogglebit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
   const [oldPassword, setOldPassword] = useState("");
   const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const toast = useToast()

  const [oldPasswordValidation, setoldPasswordValidation] = useState(false);
  const [newPasswordValidation, setNewPasswordValidation] = useState(false);
  const [hidePass, setHidePass] = useState(true);
  const [confirmPasswordValidation, setConfirmPasswordValidation] = useState(false);

  // const [oldPassword, setOldPassword] = useState("");
  //const oldPassword = useRef(null)

  useEffect(() => {
    let today = moment(new Date(), "YYYY-MM-DD");
    let formattedtoday = moment(today, "YYYY-MM-DD").format("YYYY-MM-DD");

    var json_cartitems = Cookies.get('cartitems');
    if (json_cartitems) {
      const items = JSON.parse(json_cartitems);
      setCartData(items);
      
      const cartlen = items.filter(
        ob => ob.cart_created_date >= formattedtoday
      );
      console.log(cartlen)
      if(cartlen==0){
        Cookies.remove('cartitems');
      }   
      //const a = 10;
      console.log("cart",items);
    
    
    }
  }, []);  

  function toggle() {
    setTogglebit(current => !current);
  }
  function signIn(data) {
    ////debugger;
    console.log(data);
    setOldPassword(data.oldPassword);
    setNewPassword(data.newpassword);
    setConfirmPassword(data.confirmPassword);
    if (data.newpassword !== data.confirmPassword) {
      
      toast({
        title: "",
        description: "Password not matches",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top"
      })
    }
    else{
      Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(user, data.oldPassword, data.confirmPassword);
        })
        .then((data) => {
          // showValidationMsg("Password Changed Succesfully")
          console.log(data)
            toast({
              title: "",
              description: "Password Changed Succesfully",
              status: "success",
              duration: 9000,
              isClosable: true,
              position: "top"
            })
        })
        .catch((err) => {
          // showValidationMsg("incorrect old passowrd");
          console.log(err);
          toast({
            title: "",
            description: "incorrect old passowrd",
            status: "error",
            duration: 9000,
            isClosable: true,
            position: "top"
          })
         
        });
    }   
  }
  
  return (
    <Box bg="#f9f9f9">
      <Helmet>
        <title>Squash | Change Password</title>
      </Helmet>
      <Header isAdmin={false} toggle={toggle} />
      <Box pt="70px" position="relative" h="100vh" overflow="hidden" d="flex">
        <Box
          zIndex="9"
          bg="#ffffff"
          position="absolute"
          top="0"
          pt="100px"
          left="0"
          h="100%"
          w={togglebit ? '200px' : '0px'}
          display={togglebit ? 'block' : 'none'}
        >
          <UserSidebar />
        </Box>
        {isLoading ? (
          <Box className="rspinbox">
            {' '}
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Box>
        ) : (
          <Box  w="100%" p="20px" overflow="auto">
            <Box className="card content-card">
              <Box
                className="mapBox"
                d="flex"
                pb="3"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text className='user-title'>
                  Change Password
                </Text>
              </Box>
              <Box>
                <Formik
                  initialValues={{
                    oldPassword: '',
                    newpassword: '',
                    confirmPassword: '',
                  }}
                  
                  onSubmit={(values, { setSubmitting }) => {
                    // alert(JSON.stringify(values, null, 2));
                    // alert('Hi');
                    setSubmitting(false);
                    signIn(values)
                  }}
                 validationSchema={ValidationSchema}
                >
                  {formik => {
                    return (
                      <form onSubmit={formik.handleSubmit}>
                         <Box className='user-profile'>
                        <FormControl mt={6}>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              children={<UnlockIcon color="gray.300" />}
                            />
                            <Input
                              fontSize={['13px', '13px', '18px', '18px']}
                              type="password"
                              placeholder="Old Password *"
                              onChange={formik.handleChange('oldPassword')}
                              value={formik.values.oldPassword}
                              error={formik.errors.oldPassword}
                              onBlur={formik.handleBlur('oldPassword')}
                              touched={formik.touched.oldPassword}
                            />
                          </InputGroup>
                        </FormControl>
                        {formik.errors.oldPassword &&
                          formik.touched.oldPassword && (
                            <Text className="validationColor">
                              {' '}
                              {formik.errors.oldPassword}
                            </Text>
                          )}
                           
            
                          

                        <FormControl mt={6}>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              children={<UnlockIcon color="gray.300" />}
                            />
                            <Input
                              fontSize={['13px', '13px', '18px', '18px']}
                              type="password"
                              placeholder="New Password *"
                              onChange={formik.handleChange('newpassword')}
                              value={formik.values.newpassword}
                              error={formik.errors.newpassword}
                              onBlur={formik.handleBlur('newpassword')}
                              touched={formik.touched.newpassword}
                            />
                          </InputGroup>
                        </FormControl>
                        {formik.errors.newpassword &&
                          formik.touched.newpassword && (
                            <Text className="validationColor">
                              {' '}
                              {formik.errors.newpassword}
                            </Text>
                          )}
                         
                        <FormControl mt={6}>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              children={<UnlockIcon color="gray.300" />}
                            />
                            <Input
                              fontSize={['13px', '13px', '18px', '18px']}
                              type="password"
                              placeholder="Confirm Password *"
                              onChange={formik.handleChange('confirmPassword')}
                              value={formik.values.confirmPassword}
                              error={formik.errors.confirmPassword}
                              onBlur={formik.handleBlur('confirmPassword')}
                              touched={formik.touched.confirmPassword}
                            />
                          </InputGroup>
                        </FormControl>
                        {formik.errors.confirmPassword &&
                          formik.touched.confirmPassword && (
                            <Text className="validationColor">
                              {' '}
                              {formik.errors.confirmPassword}
                            </Text>
                          )}
                       </Box>

                       <Box textAlign='right'>
                          <Button
                            disabled={formik.isSubmitting}
                            display={isLoading ? 'none' : 'inline-block'}
                            className="primary-btn"
                            mt={4}
                            px={4}
                            type="submit"
                          >
                            Change Password
                          </Button>
                        </Box>
                      </form>
                    );
                  }}
                </Formik>
              </Box>
            </Box>

            <Box className="fixed-position">
              {cartData.length == 0 ? (
                ''
              ) : (
                <Button
                  w="auto"
                  minWidth={{ base: '30px', md: '34px' }}
                  h={{ base: '30px', md: '34px' }}
                  p="0px"
                  background="#001d56"
                  color="#ffffff"
                  as={IconButton}
                  aria-label="Options"
                  icon={
                    <FontAwesomeIcon fontSize="22px" icon={faShoppingCart} />
                  }
                  variant="outline"
                  onClick={() => {
                    history.push({
                      pathname: '/RestaurantMenu/' + cartData[0]?.restId,
                    });
                  }}
                />
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default ChangePassword;
