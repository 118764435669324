
import {
	Box,
	Input,
	Button,
	Text,
	Checkbox,
	Modal,
	ModalHeader,
	ModalOverlay,
	ModalCloseButton,
	ModalBody,
	ModalContent,
	ModalFooter,
	List,
	ListItem,
	ListIcon

} from "@chakra-ui/react";
import React, { useRef, useState, useEffect } from "react";
import { EditIcon,StarIcon } from '@chakra-ui/icons'


function ReviewPopup(props) {


	return (
		<>


			<Modal blockScrollOnMount={false}
				isOpen={props.open}
				onClose={props.close}
				size ={'sx','md','xl','xl'}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Reviews</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<List spacing={3}>
							<ListItem>
								<ListIcon as={StarIcon} color="green.500" />
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit
                      </ListItem>
							<ListItem>
								<ListIcon as={StarIcon} color="green.500" />
                Assumenda, quia temporibus eveniet a libero incidunt suscipit
                                 </ListItem>
							<ListItem>
								<ListIcon as={StarIcon} color="green.500" />
             Quidem, ipsam illum quis sed voluptatum quae eum fugit earum
                         </ListItem>
						
							<ListItem>
								<ListIcon as={StarIcon} color="green.500" />
                          Quidem, ipsam illum quis sed voluptatum quae eum fugit earum
                                        </ListItem>
						</List>

					</ModalBody>

					<ModalFooter>
						<Button colorScheme="blue" mr={3} onClick={props.close}>
							Close
            </Button>
					
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}
export default ReviewPopup;