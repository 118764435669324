
import {
    Box,
    Input,
    Button,
    Text,
    Checkbox,
    Modal,
    ModalHeader,
    ModalOverlay,
    ModalCloseButton,
    ModalBody,
    ModalContent,
    ModalFooter,
    List,
    ListItem,
    ListIcon

} from "@chakra-ui/react";
import React, { useRef, useState, useEffect } from "react";
import { EditIcon, StarIcon } from '@chakra-ui/icons'
import TimePicker from 'rc-time-picker';
import moment from 'moment'


function OrderDetailsPopup(props) {

    const [timevalue, settimeValue] = React.useState(moment('00:00', 'HH:mm'))
    const [Updatedtimevalue, setUpdatedtimeValue] = React.useState(moment('00:00', 'HH:mm'))


    const a = 10;


    return (
        <>


            <Modal blockScrollOnMount={false}
                isOpen={props.open}
                onClose={props.close}
                size={'sx', 'md', 'xl', 'xl'}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{props?.orderDetails?.REST_NAME}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody p='20px'>
                        <Box>
                            {/* <Text textAlign='center' >{props?.orderDetails?.REST_NAME}</Text> */}
                            <Text>Order# : {props?.orderDetails?.ORDER_ID}</Text>
                            <Box d='flex' mt='12px' justifyContent="space-between" >
                                <Text pr='10px'>Estimated pickup time </Text>

                                {

                                    props.accepted || props.finished ?

                                        <Text>{Updatedtimevalue.format("hh:mm A")}</Text>
                                        :
                                        <TimePicker
                                            value={timevalue}
                                            onChange={(value) => {
                                                const aa = value.format("hh:mm A");

                                                console.log(aa);
                                                settimeValue(moment(aa, 'HH:mm A'))

                                            }}
                                            showSecond={false}
                                            use12Hours
                                            allowEmpty
                                            format="hh:mm A"
                                        />
                                }
                            </Box>

                            <Box d='flex' my='12px' justifyContent="space-between" >
                                <Text pr='10px'>Updated pickup time   </Text>
                                {

                                    props.accepted || props.finished  ?

                                        <Text>{Updatedtimevalue.format("hh:mm A")}</Text>
                                        :
                                        <TimePicker
                                            value={Updatedtimevalue}
                                            onChange={(value) => {
                                                const aa = value.format("hh:mm A");

                                                console.log(aa);
                                                setUpdatedtimeValue(moment(aa, 'HH:mm A'))

                                            }}
                                            showSecond={false}
                                            use12Hours
                                            allowEmpty
                                            format="hh:mm A"
                                        />
                                }
                            </Box>

                        </Box>

                        <Box>
                            {


                                props?.orderDetails?.relatedCartMenuItms?.map((ob, i) => (


                                    <Box className="order-details-popuplist">
                                        <Box d='flex' justifyContent='space-between'>
                                            <Text>{ob?.MENU_IT_NAME}</Text>
                                            <Text>{ob?.CART_ITEM_QUANTITY + ' x'}</Text>
                                            <Text>${ob?.CART_ITEM_TOTAL_PRICE}</Text>
                                        </Box>
                                        {
                                            <Text fontSize='13px' display='inline'>
                                                {ob?.relatedCartMenuChItms?.map((obb, ind) =>
                                                    obb.CH_ITEM_DESC + (ob.relatedCartMenuChItms.length > (ind + 1) ? ', ' : '')
                                                )
                                                }
                                            </Text>
                                        }
                                    </Box>
                                ))

                            }
                        </Box>




                        <Box pt='20px' d='flex' justifyContent='space-between' alignItems='center' className="order-details-popup-footer">
                            <Text>Total : <strong>${props?.orderDetails?.ORDER_TOTAL_FINAL_COST}</strong></Text>
                            

                            {props.accepted && !props.finished?
                             <Button
                             onClick={() => {
                                 props.updateOrderStatus('Completed')
                             }}
                            color='green.500' mr='15px'>Finish</Button>
                             :''
                            }

                            <Box d={ !props.accepted && !props.finished ? 'block'  : 'none'}>
                             <Button
                                 onClick={() => {
                                   
                                     props.updateOrderStatus('Rejected')
                                 }}
                                 color='red.500' mr='15px'>Reject</Button>
                             <Button
                                 onClick={() => {
                                     props.updateOrderStatus('Accepted')
                                 }}
                                 color='green.500'>Accept</Button>
                         </Box>
                            
                           

                        </Box>

                    </ModalBody>


                    {/* <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={props.close}>
                            Close
                        </Button>

                    </ModalFooter> */}
                </ModalContent>
            </Modal>
        </>
    )
}
export default OrderDetailsPopup;