import {
	Box,
	InputGroup,
	InputLeftElement,
	Button,
	Input,
	Text,
	Spinner,
	Tab,
	Tabs,
	TabPanel,
	TabList,
	TabPanels,
	Image,
	IconButton

} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import UserSidebar from '../../../components/UserSidebar'
import Header from '../../../components/Header'
import { SearchIcon, AddIcon, WarningIcon } from '@chakra-ui/icons'
import { Helmet } from 'react-helmet';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { listSearch as listSearch } from "../../../graphql/queries";
import { listUserCuisineTypeMasterFavOnlyY as ListUserCuisineTypeMasterFavOnlyY } from '../../../graphql/queries'
import { listUserCuisineTypeMasterFav as ListUserCuisineTypeMasterFav } from '../../../graphql/queries'
import { getMenuChoices as GetMenuChoices } from '../../../graphql/queries'
import CartPopup from "../../../components/CartPopup";
import Quickfilter from '../Search/SearchQuickFilter'
import { useToast, useDisclosure } from "@chakra-ui/react";
import Cookies from 'js-cookie';
import moment from 'moment'
const Search = () => {

	const history = useHistory();
	const [togglebit, setTogglebit] = useState(false);

	const [isLoading, setIsLoading] = useState(false);

	const [notifications, setnotifications] = useState([]);

	const [pollRespond, setPollRespond] = useState([])

	const [cartData, setCartData] = useState([]);

	const [questions, setQuestions] = useState([])
	const [orderList, setOrderList] = useState([]);
	let orders = [];

	let [searchresult, setSearchresult] = useState(null)
	let [cuisinesList, setCuisineList] = useState([])
	let [filterCuisines, setFilterCuisines] = useState([]);
	let [filterCuisinesNames, setFilterCuisinesNames] = useState([]);
	let [searchtxt, setSearchtxt] = useState('')

	const { isOpen, onOpen, onClose } = useDisclosure()
	const [menuChoice, SetMenuChoice] = useState([])
	const [editObject, setEditObject] = useState(null);

	useEffect(() => {
		const abortController = new AbortController();
		let mounted = true;


		listCuisines();

		let today = moment(new Date(), "YYYY-MM-DD");
		let formattedtoday = moment(today, "YYYY-MM-DD").format("YYYY-MM-DD");

		var json_cartitems = Cookies.get('cartitems');
		if (json_cartitems) {
			const items = JSON.parse(json_cartitems);
			setCartData(items);

			const cartlen = items.filter(
				ob => ob.cart_created_date >= formattedtoday
			);
			console.log(cartlen)
			if (cartlen == 0) {
				Cookies.remove('cartitems');
			}
			//const a = 10;
			console.log("cart", items);


		}


		return () => {
			abortController.abort();
			mounted = false;

		};

	}, []);

	useEffect(() => {
		MenuDetails();
	}, [editObject]);

	async function MenuDetails() {

		try {
			const user = await Auth.currentCredentials();
			let result = null;
			if (user.authenticated == true && editObject !== null) {
				result = await API.graphql(graphqlOperation(GetMenuChoices, { MENU_IT_ID: editObject?.MENU_IT_ID }))
				console.log(result)
				const s = result?.data.getMENU_CHOICES[0]?.data;
				SetMenuChoice(result?.data.getMENU_CHOICES)
			}
			else if (user.authenticated == false && editObject !== null) {
				result = await API.graphql({
					query: GetMenuChoices,
					variables: { MENU_IT_ID: editObject?.MENU_IT_ID },
					authMode: 'AWS_IAM'
				})
				const s = result?.data.getMENU_CHOICES[0]?.data;
				SetMenuChoice(result?.data.getMENU_CHOICES)
			}

		}
		catch (err) {

			console.log(err)
		}
	}

	function toggle() {
		setTogglebit(current => !current);
	}

	async function listCuisines() {
		setIsLoading(true)
		try {
			////debugger;
			// const cuisines = await API.graphql(graphqlOperation(ListUserCuisineTypeMasterFavOnlyY))
			// setCuisineList(cuisines.data.listUSER_CUISINE_TYPE_MASTER_FAV_ONLY_Y?.map(elem => (
			// 	{
			// 		CUISINE_ID: elem.CUISINE_ID,
			// 		CUISINE_TYPE_NAME: elem.CUISINE_TYPE_NAME
			// 	}
			// )))
			const cuisines = await API.graphql(graphqlOperation(ListUserCuisineTypeMasterFav))
			////debugger;
			setCuisineList(cuisines.data.listUSER_CUISINE_TYPE_MASTER_FAV?.map(elem => (
				{
					CUISINE_ID: elem.CUISINE_ID,
					CUISINE_TYPE_NAME: elem.CUISINE_TYPE_NAME
				}
			)))
			////debugger;

		}
		catch (err) {
			const d = err;
		}


		setIsLoading(false)
	}

	async function filterList(CUISINE_ID, CUISINE_TYPE_NAME) {
		//alert('hi');
		//////debugger;
		// const restaurants = await API.graphql(
		// 	graphqlOperation(GetRestMaster, { REST_ID: REST_ID })
		//   );
		const newFilterCuisines = [...filterCuisines];
		let cuisineNames = [...filterCuisinesNames];
		if (newFilterCuisines.includes(CUISINE_ID)) {
			let id = newFilterCuisines.indexOf(CUISINE_ID);
			let name = cuisineNames.indexOf(CUISINE_TYPE_NAME);
			newFilterCuisines.splice(id, 1);
			cuisineNames.splice(name, 1);
		}
		else {
			newFilterCuisines.push(CUISINE_ID)
			cuisineNames.push(CUISINE_TYPE_NAME)

		}
		setFilterCuisines(newFilterCuisines);
		setFilterCuisinesNames(cuisineNames);
		console.log(CUISINE_ID);
		console.log(CUISINE_TYPE_NAME);
		console.log(cuisineNames);
		if (newFilterCuisines.length == 0) {
			//updateTodaySpecials(todaySpecialsListAll)

			//setRestaurantList(restaurantListAll)

			//updateUpcomingSpecials(upcomingSpecialsListAll)

			console.log(CUISINE_ID)
			return
		}
		let cuisine_Menu_List = await API.graphql(
			graphqlOperation(listSearch, { SEARCHTERM: cuisineNames })
		);
		console.log(cuisine_Menu_List)
		setSearchresult(cuisine_Menu_List)

		// let filterArray = todaySpecialsListAll.filter(
		// 		data => newFilterCuisines.includes(data.CUISINE_TYPE)
		// )
		// filterArray.length > 0 ? updateTodaySpecials(filterArray) : updateTodaySpecials([])

		// filterArray = restaurantListAll.filter(
		// 		data => newFilterCuisines.includes(data.CUISINE_TYPE)
		// )

		// filterArray.length > 0 ? setRestaurantList(filterArray) : setRestaurantList([])


		// filterArray = upcomingSpecialsListAll.filter(
		// 		data => newFilterCuisines.includes(data.CUISINE_TYPE)
		// )
		// filterArray.length > 0 ? updateUpcomingSpecials(filterArray) : updateUpcomingSpecials([])
	}

	async function restuarant_menu_list() {
		console.log('searchtxt', searchtxt)
		try {
			if (searchtxt.length >= 3) {
				let resturant_Menu_List = [];
				resturant_Menu_List = await API.graphql(
					graphqlOperation(listSearch, { SEARCHTERM: searchtxt, LATITUDE : 0, LONGITUDE : 0 })
				);
				// graphqlOperation(listSearch, { SEARCHTERM: searchText, LATITUDE : 0, LONGITUDE : 0 })
				console.log('listSearch -------------', resturant_Menu_List.data)
				////debugger;
				setSearchresult(resturant_Menu_List)
				const a = 10;

			} else {
				alert("Please specify minimum 3 letters");
			}
		}
		catch (error) {
			console.log(error);
		}
	}
	async function enterKeyPress(event) {

		if (event.key === 'Enter') {
			//////debugger;
			restuarant_menu_list()
		}

	}

	async function searchEvent() {
		try {
			let event_List = [];
			event_List = await API.graphql(
				graphqlOperation(listSearch, { SEARCHTERM: "Happy Hour" })
			);
			setSearchresult(event_List)

		}
		catch (error) {
			console.log(error);
		}
	}

	async function searchEvent1() {
		try {
			let event_List1 = [];
			event_List1 = await API.graphql(
				graphqlOperation(listSearch, { SEARCHTERM: "Karaoke" })
			);
			setSearchresult(event_List1)

		}
		catch (error) {
			console.log(error);
		}
	}

	async function searchEvent2() {
		try {
			let event_List2 = [];
			event_List2 = await API.graphql(
				graphqlOperation(listSearch, { SEARCHTERM: "Live Music" })
			);
			setSearchresult(event_List2)

		}
		catch (error) {
			console.log(error);
		}
	}

	return (

		<Box bg='#f9f9f9'>
			<Helmet>
				<title>Squash | Search</title>
			</Helmet>
			{
				menuChoice.length > 0 && editObject && isOpen ?
					<CartPopup
						// editcartitem={editcartitem}
						menuChoice={menuChoice} menu={editObject} open={isOpen}
						close={() => {
							onClose();
							//setCartIsLoading(false); seteditcartitem([]);
							SetMenuChoice([]); setEditObject(null)
						}}
						// addtocart={addtocart} 
						fromsearch={true}
					/> : null
			}

			<Header isAdmin={false} toggle={toggle} />
			<Box pt='70px' position='relative' h='100vh' overflow='hidden' d='flex'>
				<Box zIndex='999' bg='#fff' position='absolute' top='0' pt='100px' left='0' h='100%' w={(togglebit ? "200px" : '0px')} display={(togglebit ? 'block' : "none")}>
					<UserSidebar />
				</Box>
				{isLoading ? (<Box className="rspinbox" > <Spinner
					thickness="4px"
					speed="0.65s"
					emptyColor="gray.200"
					color="blue.500"
					size="xl"
				/></Box >) :
					<Box
						//   ml={(togglebit ? "0px" : { base: "0", sm: "0", md: "0", lg: '200px' })}
						w='100%' p='20px' overflow='auto'>
						<Box className="card content-card">

							<Box className=" table-padding">
								<Box className="mapBox" d='flex' pb='3' justifyContent='space-between' alignItems='center'>
									<Text className="user-title">Search</Text>



								</Box>
								<Box d='flex' className="search-restaurant">
									<InputGroup>
										<InputLeftElement
											pointerEvents="none"
											children={<SearchIcon color="gray.300" />}
										/>
										<Input type="text" placeholder="Search Restaurant or Dish"
											value={searchtxt}
											onChange={(event) => {
												setSearchtxt(event.target.value)
											}}
											onKeyPress={(e) =>
												enterKeyPress(e)
											}
										/>
									</InputGroup>
									<Button
										onClick={() => {
											//////debugger;
											restuarant_menu_list()
										}}
									// onClick={() => {
									// 	restuarant_menu_list()
									// }}
									>Search</Button>
								</Box >
								<Box>
									{cuisinesList?.length > 0 ? <Quickfilter cuisinesList={cuisinesList} filterList={filterList} filterCuisines={filterCuisines} /> : ''}
								</Box >
								<Box className="fixed-position">
									{/* <Image src={CartImage} width={100} height={100} onClick={}/> */}
									{(cartData.length == 0) ? (
										''
									) : (
										<Button
											w='auto'
											minWidth={{ base: '30px', md: '34px' }}
											h={{ base: '30px', md: '34px' }}
											// h='auto'
											ml='15px'
											p='0px'
											background='#001d56'
											color='#ffffff'
											as={IconButton}
											aria-label="Options"
											icon={<FontAwesomeIcon fontSize='22px' icon={faShoppingCart} />}
											variant="outline"
											onClick={() => {



												history.push(
													{
														//pathname: `/Admin/${localStorage.getItem('Currentpage')}`,
														pathname: '/RestaurantMenu/' + cartData[0]?.restId,


														//  state: { email:props.userinfo?.email, password: props.userinfo?.pass }
													}
												);

											}}
										/>)}

								</Box>





								{searchresult == null ?
									<Box p='50px' d='flex' justifyContent='center'>
										<Box textAlign="center" fontSize="14px" fontWeight="500">
											<Image cursor='pointer'
												src={require('../../../assets/images/search/HappyHour.jpg').default}
												alt="Segun Adebayo" onClick={() => { searchEvent() }} />
											<Text color="#878787">Drinks</Text>
										</Box>
										<Box textAlign="center" fontSize="14px" fontWeight="500">
											<Image pl='10px' pr='10px' cursor='pointer'
												src={require('../../../assets/images/search/Karaoke.jpg').default}
												alt="Segun Adebayo" onClick={() => { searchEvent1() }} />
											<Text color="#878787">Karaoke</Text>
										</Box>
										<Box textAlign="center" fontSize="14px" fontWeight="500">
											<Image cursor='pointer'
												src={require('../../../assets/images/search/LiveMusic.jpg').default}
												alt="Segun Adebayo" onClick={() => { searchEvent2() }} />
											<Text color="#878787">Music</Text>
										</Box>
									</Box>
									:
									<Box className="search-result-tab">

										<Tabs m='5px' variant="soft-rounded" colorScheme="blue" >
											<Box textAlign='center'>
												<TabList justifyContent='space-around'>
													<Tab>Menu</Tab>
													<Tab>Restaurant</Tab>

												</TabList>
											</Box>


											<Box >

												<TabPanels>

													<TabPanel p='0' pt="5">
														<Box>
															{searchresult?.data?.listSEARCH == null ? 'No menu items found...' :
																searchresult?.data?.listSEARCH[0]?.relatedSrcMenuItems.map((obj, ind) => (
																	<Box cursor='pointer'
																		onClick={
																			() => {
																				setEditObject(obj)
																				onOpen();
																			}
																		}
																		mb='15px' p='10px 15px' borderRadius='10px' border='1px solid #C3DDFF;'>
																		<Text fontWeight='bold' pb='1'>{obj.MENU_IT_NAME}</Text>
																		<Box d='flex' flexDirection={{ base: 'column', sm: 'column', md: 'row' }}>
																			<Box d='flex'>
																				<Text fontWeight='medium' pr='1'>Price : </Text>
																				<Text fontWeight='light'>{obj.MENU_IT_PRICE}</Text>
																			</Box>

																			<Box d='flex' ml={{ base: '0', sm: '0', md: 'auto' }}>
																				<Text fontWeight='medium' pr='1'>Restaurant : </Text>
																				<Text fontWeight='light'>{obj.REST_NAME}</Text>
																			</Box>
																		</Box>
																	</Box>
																))}
														</Box >


													</TabPanel>

													<TabPanel p='0' pt="5">

														<Box>
															{searchresult?.data?.listSEARCH == null ? 'No restaurants found...' :
																searchresult?.data?.listSEARCH[0]?.relatedSrcRestaurants.map((obj, ind) => (
																	<Box
																		onClick={
																			() => {
																				history.push(
																					{
																						//pathname: `/Admin/${localStorage.getItem('Currentpage')}`,
																						pathname: '/RestaurantMenu/' + obj.REST_ID,
																						from: 'SpotlightRestaurants'
																						//  state: { email:props.userinfo?.email, password: props.userinfo?.pass }
																					}
																				);

																			}
																		}
																		cursor='pointer' mb='15px' p='10px 15px' borderRadius='10px' border='1px solid #ccc;'>
																		<Text fontWeight='bold' pb='1'>{obj.REST_NAME}</Text>
																		<Box d='flex' flexDirection={{ base: 'column', sm: 'column', md: 'row' }}>
																			<Box d='flex'>
																				<Text fontWeight='medium' pr='1'>City : </Text>
																				<Text fontWeight='light'>{obj.CITY_NAME}</Text>
																			</Box>

																			<Box d='flex' ml={{ base: '0', sm: '0', md: 'auto' }}>
																				<Text fontWeight='medium' pr='1'>Cuisine Type : </Text>
																				<Text fontWeight='light'>{obj.CUISINE_TYPE_NAME}</Text>
																			</Box>
																		</Box>
																	</Box>
																))}
														</Box >
													</TabPanel>

												</TabPanels>
											</Box >
										</Tabs>

									</Box>
								}
							</Box >
						</Box >
					</Box>
				}
			</Box>
		</Box>
	);
}
export default Search;