import {
  Box,
  Button,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  FormLabel,
  Input,
  FormControl,
  Select,
  HStack,
  Text,
  Stack,
  RadioGroup,
  Radio,
} from '@chakra-ui/react';
import { Multiselect } from 'multiselect-react-dropdown';
import React, { useRef, useState, useEffect } from 'react';
import moment from 'moment';
import * as yup from 'yup';
import { Formik, Form, Field, FieldArray, useFormik } from 'formik';
import { createSpecialMenuMaster as CreateSpecialMenuMaster } from '../graphql/mutations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { getMasterMenuChoices as GetMasterMenuChoices } from '../graphql/queries';
import { getMenuChoices as GetMenuChoices } from '../graphql/queries';
import { Storage, Auth, Hub, API, graphqlOperation } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { useToast } from '@chakra-ui/react';
import config from '../aws-exports';
const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket,
} = config;

let ValidationSchema = yup.object().shape({
  itemname: yup
    .string()
    .max(30, 'Item name must be 30 characters or less')
    .required('Name is required'),
  description: yup
    .string()
    .max(100, 'Item description must be 100 characters or less')
    .required('Description is required'),
  price: yup.number().required('Price is required'),
  startdate: yup.date().required('Start date is required'),
  enddate: yup.date().required('End date is required'),
  photo: yup.string(),
  //.required('Photo is required'),
  eventtype: yup.string(),
  status: yup.string(),
  //.required('Status is required'),
  daysId: yup.array(),
  // .min(1, "Need at least a day"),
  customization: yup.array(),
});

const days = [
  { id: 'monday', name: 'Mo' },
  { id: 'tuesday', name: 'Tu' },
  { id: 'wednesday', name: 'We' },
  { id: 'thursday', name: 'Th' },
  { id: 'friday', name: 'Fr' },
  { id: 'saturday', name: 'Sa' },
  { id: 'sunday', name: 'Su' },
];

function AddEventPopup(props) {
  let Count = props?.Count;
  let [custmizationData, setCustomizationData] = useState(null);
  let [custmizationFullData, setCustomizationFullData] = useState(null);
  let [sheduleBit, setSheduleBit] = useState(true);
  const [imageFile, setiimageFile] = useState(null);
  const initialRef = React.useRef();
  const finalRef = React.useRef();
  // const [valuee, setValue] = useState(props?.object?.EVERYDAY_YN =='Y' ?'1':'2')

  const [selectedImage, setSelectedImage] = useState(null);

  const [itemPic, setItemPic] = useState('');

  const [valuee, setValue] = useState('1');
  const [eventvalue, seteventvalue] = useState('1');
  // const [checked, setChecked] = React.useState('Happy Hour');
  // const [typeId, setTypeId] = useState('MG-HH');
  // const [eventname, seteventName] = useState('')
  let eventname = ''
  let typeId = ''
  let today = moment(new Date(), 'YYYY-MM-DD');
  let formattedtoday = moment(today, 'YYYY-MM-DD').format('YYYY-MM-DD');

  let imagePath = '';
  const [menuChoice, SetMenuChoice] = useState([]);
  let upImg = '';
  const toast = useToast();

  //Show toast for validation message method
  function showValidationMsg(msg, st) {
    toast({
      title: '',
      description: msg,
      status: st,
      duration: 9000,
      isClosable: true,
      position: 'top',
    });
  }

  useEffect(() => {
    upImg = props?.object?.MENU_IT_PIC_LOCATION;
    getCustomizationData();
  }, []);

  useEffect(() => {
    //debugger;
    setItemPic(props?.object?.MENU_IT_PIC_LOCATION);
    // setChecked(props?.object?.MENU_GR_NAME);
    // setTypeId(props?.object?.MENU_GR_ID);
    if (props?.object?.MENU_GR_NAME == "Happy Hour") {
      seteventvalue('1')
    }
    else if (props?.object?.MENU_GR_NAME == "Karoke") {
      seteventvalue('2')
    }
    else if (props?.object?.MENU_GR_NAME == "Live Music") {
      seteventvalue('3')
    }
    else {
      seteventvalue('')
    }


    if (props?.object) getmenuCustomization();
  }, [props?.object]);

  async function getmenuCustomization() {
    try {
      //debugger;
      if (props?.object?.EVERYDAY_YN == 'N') {
        setValue('2');
      } else {
        setValue('1');
      }
      const result = await API.graphql(
        graphqlOperation(GetMenuChoices, {
          MENU_IT_ID: props?.object.MENU_IT_ID,
        })
      ); //NJGAR-R1
      console.log(result);
      const s = result?.data.getMENU_CHOICES;
      SetMenuChoice(result?.data.getMENU_CHOICES);
      //debugger;
    } catch (err) {
      console.log(err);
    }
  }

  // function getmenuCustomization()
  // {
  //   const a=10;
  //  const input= props.object.MENU_IT_ID;
  // }

  var inputBox = document.getElementById('inputBox');

  var invalidChars = ['-', '+', 'e'];

  inputBox?.addEventListener('keydown', function (e) {
    if (invalidChars.includes(e.key)) {
      //debugger;
      e.preventDefault();
    }
  });


  const getCustomizationData = async () => {
    if (props?.isCreate) {
      setValue('1');
    }
    const result = await API.graphql(
      graphqlOperation(GetMasterMenuChoices, {
        REST_ID: localStorage.getItem('Restaurantid'),
      })
    );
    //debugger;
    let obj = []
    let res = result?.data.getMASTER_MENU_CHOICES?.map(item => {
      if (item.CH_ID && item.CH_DESC) {
        obj.push({ id: item.CH_ID, name: item.CH_DESC })
      }
    });
    setCustomizationData(obj);
    setCustomizationFullData(result?.data.getMASTER_MENU_CHOICES);
  };

  // async function savetoS3(file) {
  //   if (file) {
  //     const productName = file.name.split(".")[0]
  //     const extension = file.name.split(".")[1]
  //     const { type: mimeType } = file

  //     // const imageData = await fetch(file)
  //     // const blobData = await imageData.blob()

  //     const key = `images//${uuidv4()}${productName}.${extension}`
  //     // const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`
  //     const url = `https://image.squash.app/public/${key}`
  //     //  const inputData = { name: productName , image: url }
  //     imagePath = url;
  //     console.log(url);

  //     try {
  //       const res = await Storage.put(key, file, {
  //         contentType: mimeType
  //       })

  //       const a = 10;
  //       //  await API.graphql(graphqlOperation(CreateProduct, { input: inputData }))
  //     } catch (err) {
  //       console.log('error: ', err)
  //     }
  //     // try {
  //     //   const result = await Storage.get(key, { download: true });

  //     //     const a=10
  //     //    } catch (err) {
  //     //      console.log('error: ', err)
  //     //    }
  //   }

  // }
  async function savetoS3(file) {
    // //debugger;

    if (file) {
      const productName = file.name.split('.')[0];
      const extension = file.name.split('.')[1];
      const { type: mimeType } = file;

      // const imageData = await fetch(file)
      // const blobData = await imageData.blob()

      // const extension = selectedImage.uri.split(/[#?]/)[0].split('.').pop().trim();
      //       console.log("extension--------->" + extension);
      //       const { type: mimeType } = selectedImage
      //       const key = `images/${uuid.v4()}item-special.${extension}`
      //       const ImgUrl = `https://image.squash.app/public/${key}`
      //       console.log("ImgUrl--------->" + ImgUrl);

      //const key = `images/${uuidv4()}${productName}.${extension}`
      const key = `${uuidv4()}item-special.${extension}`;
      // const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`
      //const url = `https://image.squash.app/public/${key}`
      const url = `https://imageprod.squash.app/public/${key}`;
      //  const inputData = { name: productName , image: url }

      imagePath = url;
      console.log(url);

      try {
        ////debugger;
        const b = 10;
        const res = await Storage.put(key, file, {
          contentType: 'image/png',
          //   mimeType
        });
        ////debugger;
        const a = 10;
        //  await API.graphql(graphqlOperation(CreateProduct, { input: inputData }))
      } catch (err) {
        ////debugger;
        console.log('error: ', err);
      }
    } else if (itemPic) {
      imagePath = itemPic;
    }

  }
  async function ChangeEvent() {
    if (eventvalue == '1') {
      eventname = "Happy Hour";
      typeId = 'MG-HH';
    }
    else if (eventvalue == '2') {
      eventname = "Karoke";
      typeId = 'MG-KAR';
    }
    else if (eventvalue == '3') {
      eventname = 'Live Music';
      typeId = 'MG-LM';
    }
    else {

      eventname = "Other";
      typeId = 'MG-HH';
    }


  }

  return (
    <>
      <Modal
        size={('sx', 'md', 'xl', 'xl')}
        isOpen={props?.open}
        onClose={props?.close}
        scrollBehavior="inside"
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
      // key={["xs", "sm", "md", "lg", "xl", "full"]}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {props?.isupdate == true ? 'Update Special' : 'Create Special'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Formik
              initialValues={{
                itemname: props?.object?.MENU_IT_NAME,
                description: props?.object?.MENU_IT_DESC,
                price: props?.object?.MENU_IT_PRICE,
                startdate: props?.object?.DATE_FROM_DATE,
                enddate: props?.object?.DATE_TO_DATE,
                photo: upImg,

                eventtype: eventvalue,

                // EVERYDAY_YN:String(1),
                daysId: [
                  props?.object?.SUNDAY_YN == 'Y' ? 'sunday' : null,
                  props?.object?.MONDAY_YN == 'Y' ? 'monday' : null,
                  props?.object?.TUESDAY_YN == 'Y' ? 'tuesday' : null,
                  props?.object?.WEDNESAY_YN == 'Y' ? 'wednesday' : null,
                  props?.object?.THURSDAY_YN == 'Y' ? 'thursday' : null,
                  props?.object?.FRIDAY_YN == 'Y' ? 'friday' : null,
                  props?.object?.SATURDAY_YN == 'Y' ? 'saturday' : null,
                ],
                customization: menuChoice?.map(item => {
                  const { CH_ID, CH_DESC } = item;
                  return { id: CH_ID, name: CH_DESC };
                }),
              }}
              validationSchema={ValidationSchema}
              onSubmit={(value, { setSubmitting }) => {
                //debugger;
                savetoS3(imageFile);
                ChangeEvent(eventvalue)
                console.log(formattedtoday);
                console.log(value.startdate);
                if (!props?.isupdate && (value.startdate <= formattedtoday || value.enddate <= formattedtoday)) {
                  //debugger;
                  showValidationMsg(
                    'Error occured  :Dates should be greater or equal to today',
                    'error'
                  );
                  setSubmitting(false);
                }
                else if (value.startdate > value.enddate) {
                  //debugger;
                  showValidationMsg(
                    'Error occured: Start date should be smaller than End date',
                    'error'
                  );
                  setSubmitting(false);
                }

                else {
                  //debugger;
                  const inputData = {
                    // MENU_IT_ID: uuidv4(),
                    MENU_IT_ID: props?.isupdate ? props?.object?.MENU_IT_ID : uuidv4(),
                    MENU_IT_NAME: value.itemname,
                    MENU_IT_DESC: value.description,
                    MENU_IT_PRICE: value.price,
                    MENU_IT_PIC_LOCATION: imagePath,
                    DATE_FROM_DATE: value.startdate,
                    DATE_TO_DATE: value.enddate,
                    REST_ID: localStorage.getItem('Restaurantid'),
                    MENU_GR_ID: typeId,
                    MENU_GR_NAME: eventname,
                    MENU_GR_ORDER: 1,
                    MENU_ID: 'ME1',
                    MENU_TYPE: 'Specials',
                    MENU_ORDER: 90,
                    MENU_NAME: 'Special Event',
                    MENU_DESC: 'Special Event',
                    MENU_IT_ORDER: 2,
                    EVERYDAY_YN: valuee == '1' ? 'Y' : 'N',
                    //EVERYDAY_YN:props?.isupdate=="true"? 'Y': 'N',
                    SUNDAY_YN: value.daysId.includes('sunday') ? 'Y' : 'N',
                    MONDAY_YN: value.daysId.includes('monday') ? 'Y' : 'N',
                    TUESDAY_YN: value.daysId.includes('tuesday') ? 'Y' : 'N',
                    WEDNESAY_YN: value.daysId.includes('wednesday') ? 'Y' : 'N',
                    THURSDAY_YN: value.daysId.includes('thursday') ? 'Y' : 'N',
                    FRIDAY_YN: value.daysId.includes('friday') ? 'Y' : 'N',
                    SATURDAY_YN: value.daysId.includes('saturday') ? 'Y' : 'N',
                  };
                  //debugger;
                  if (
                    valuee == '2' &&
                    inputData.SUNDAY_YN == 'N' &&
                    inputData.MONDAY_YN == 'N' &&
                    inputData.TUESDAY_YN == 'N' &&
                    inputData.WEDNESAY_YN == 'N' &&
                    inputData.THURSDAY_YN == 'N' &&
                    inputData.FRIDAY_YN == 'N' &&
                    inputData.SATURDAY_YN == 'N'
                  ) {
                    showValidationMsg('Error occured: Select Days', 'error');
                    setSubmitting(false);
                  }


                  if (!props?.object?.MENU_IT_NAME) {
                    props?.Save(inputData, false);
                  } else {
                    props?.Save(inputData, true);
                  }

                  setSubmitting(false);

                }
              }}
            >
              {formik => {
                return (
                  <form onSubmit={formik.handleSubmit}>
                    <FormControl>
                      <FormLabel>Item Name</FormLabel>
                      <Input
                        type="text"
                        onChange={formik.handleChange('itemname')}
                        onBlur={formik.handleBlur('itemname')}
                        value={formik.values.itemname}
                        error={formik.errors.itemname}
                        touched={formik.touched.itemname}
                        ref={initialRef}
                      //  ref={formRef}
                      />
                      {formik.errors.itemname && formik.touched.itemname && (
                        <Text className="validationColor">
                          {' '}
                          {formik.errors.itemname}
                        </Text>
                      )}
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>Description</FormLabel>
                      <Input
                        type="text"
                        onChange={formik.handleChange('description')}
                        onBlur={formik.handleBlur('description')}
                        value={formik.values.description}
                        error={formik.errors.description}
                        touched={formik.touched.description}
                      />
                      {formik.errors.description &&
                        formik.touched.description && (
                          <Text className="validationColor">
                            {' '}
                            {formik.errors.description}
                          </Text>
                        )}
                    </FormControl>

                    <FormControl mt={4}>
                      <FormLabel>Price</FormLabel>
                      <Input
                        type="number"
                        // type="text"
                        id="inputBox"

                        onChange={formik.handleChange('price')}
                        onBlur={formik.handleBlur('price')}
                        value={formik.values.price}
                        error={formik.errors.price}
                        touched={formik.touched.price}
                      />
                      {formik.errors.price && formik.touched.price && (
                        <Text className="validationColor">
                          {' '}
                          {formik.errors.price}
                        </Text>
                      )}
                    </FormControl>

                    <Box display="flex" className="mspecialbox">
                      <FormControl mt={4} pr="10px">
                        <FormLabel>Start Date</FormLabel>
                        <Input
                          type="date"
                          onChange={formik.handleChange('startdate')}
                          onBlur={formik.handleBlur('startdate')}
                          value={formik.values.startdate}
                          error={formik.errors.startdate}
                          touched={formik.touched.startdate}
                        />
                        {formik.errors.startdate &&
                          formik.touched.startdate && (
                            <Text className="validationColor">
                              {' '}
                              {formik.errors.startdate}
                            </Text>
                          )}
                      </FormControl>

                      <FormControl mt={4}>
                        <FormLabel>End Date</FormLabel>
                        <Input
                          type="date"
                          onChange={formik.handleChange('enddate')}
                          onBlur={formik.handleBlur('enddate')}
                          value={formik.values.enddate}
                          error={formik.errors.enddate}
                          touched={formik.touched.enddate}
                        />
                        {formik.errors.enddate && formik.touched.enddate && (
                          <Text className="validationColor">
                            {' '}
                            {formik.errors.enddate}
                          </Text>
                        )}
                      </FormControl>
                    </Box>

                    <FormControl mt={4}>
                      <FormLabel>Photo Upload</FormLabel>
                      <Box className=" restaurant-file-upload">
                        <FontAwesomeIcon icon={faPlus} />
                        <Input
                          type="file"
                          onChange={e => {
                            //debugger;
                            const file = e.target.files[0];
                            setiimageFile(file);
                            //    savetoS3(file);
                          }}
                          onBlur={formik.handleBlur('photo')}
                          value={formik.values.photo}
                          error={formik.errors.photo}
                          touched={formik.touched.photo}
                        />
                        {formik.errors.photo && formik.touched.photo && (
                          <Text className="validationColor">
                            {' '}
                            {formik.errors.photo}
                          </Text>
                        )}
                      </Box>
                    </FormControl>

                    {/* <FormControl mt={4} >
                      <FormLabel>Status</FormLabel>

                      <Select
                        onChange={formik.handleChange('status')}
                        onBlur={formik.handleBlur('status')}
                        value={formik.values.status}
                        error={formik.errors.status}
                        touched={formik.touched.status}
                        placeholder="Select">
                        <option value="Active">Active</option>
                        <option value="Non Active">Non Active</option>

                      </Select>
                      {formik.errors.status && formik.touched.status && <Text className="validationColor">{" "} {formik.errors.status}</Text>}
                    </FormControl> */}
                    {/* 
                    <FormControl mt={4}>
                      <FormLabel>Customization</FormLabel>

                      <Multiselect
                        options={custmizationData}
                        selectedValues={formik.values.customization}
                        onSelect={(selectedList, selectedItem) => {
                          formik.values.customization.push(selectedItem);

                          //  console.log(formik.values.customization)
                        }}
                        displayValue="name"
                        onRemove={(selectedList, removedItem) => {
                          formik.values.customization.pop(removedItem);
                          //  console.log(formik.values.customization)
                        }}
                      />
                    </FormControl> */}


                    <FormControl mt={7}>
                      <FormLabel color="#00B2FF" fontSize="17px !important">
                        {' '}
                        Event Type{' '}
                      </FormLabel>
                      <RadioGroup pb="5px"
                        onChange={seteventvalue}

                        value={eventvalue}
                      >
                        <Stack direction="row">
                          <Radio value="1" >Happy Hour</Radio>
                          <Radio value="2" >Karoke</Radio>
                          <Radio value="3" >Live Music</Radio>

                        </Stack>
                      </RadioGroup>

                    </FormControl>

                    <FormControl mt={7}>
                      <FormLabel color="#00B2FF" fontSize="17px !important">
                        {' '}
                        Schedule{' '}
                      </FormLabel>
                      <RadioGroup pb="5px" onChange={setValue} value={valuee}>
                        <Stack direction="row">
                          <Radio value="1">All days</Radio>
                          <Radio value="2">Select days</Radio>
                        </Stack>
                      </RadioGroup>

                      {valuee == '2' ? (
                        <FieldArray
                          name="daysId"
                          render={arrayHelpers => (
                            <div>
                              <HStack>
                                {days.map(day => (
                                  <div key={day.id}>
                                    <label>
                                      <input
                                        name={`daysId${day.id}`}
                                        type="checkbox"
                                        value={day.id}
                                        checked={formik.values.daysId.includes(
                                          day.id
                                        )}
                                        touched={formik.values.daysId.includes(
                                          day.id
                                        )}
                                        onChange={e => {
                                          if (e.target.checked) {
                                            arrayHelpers.push(day.id);
                                            //  console.log(JSON.stringify(formik.values.daysId, null, 2))
                                          } else {
                                            const idx =
                                              formik.values.daysId.indexOf(
                                                day.id
                                              );
                                            arrayHelpers.remove(idx);
                                          }
                                        }}
                                      />{' '}
                                      {day.name}
                                    </label>
                                  </div>
                                ))}
                              </HStack>
                              {typeof formik.errors.daysId === 'string' &&
                                formik.touched.daysId ? (
                                <Text className="validationColor">
                                  {formik.errors.daysId}
                                </Text>
                              ) : null}
                            </div>
                          )}
                        />
                      ) : (
                        ''
                      )}
                    </FormControl>

                    <Box className="popup-buttons">
                      <Button
                        className="secondary-btn"
                        mr={2}
                        onClick={props?.close}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="primary-btn"
                        type="submit"
                        color="#fff"
                        bg="#0554F2"
                        ml={2}
                      >
                        {props?.isupdate == true ? 'Update' : 'Create'}
                      </Button>
                    </Box>
                  </form>
                );
              }}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AddEventPopup;
