/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCityMaster = /* GraphQL */ `
  query GetCityMaster($id: Int!) {
    getCITY_MASTER(id: $id) {
      id
      CITY_NAME
      STATE
      LATITUDE
      LONGITUDE
      DISPLAY_INDICATOR_YN
    }
  }
`;
export const listCityMasteRs = /* GraphQL */ `
  query ListCityMasteRs {
    listCITY_MASTERs {
      id
      CITY_NAME
      STATE
      LATITUDE
      LONGITUDE
      DISPLAY_INDICATOR_YN
    }
  }
`;
export const getCuisineTypeMaster = /* GraphQL */ `
  query GetCuisineTypeMaster($CUISINE_ID: String!) {
    getCUISINE_TYPE_MASTER(CUISINE_ID: $CUISINE_ID) {
      CUISINE_ID
      CUISINE_TYPE_NAME
      CUISINE_PIC_LOACTION
      relatedRestaurants {
        REST_ID
        REST_NAME
        ADDRESS
        WEBSITE
        PHONE
        LATITUDE
        LONGITUDE
        RATING
        REVIEW_NUMBERS
        PRICE_LEVEL
        SUNDAY_HOURS
        MONDAY_HOURS
        TUESDAY_HOURS
        WEDNESDAY_HOURS
        THURSDAY_HOURS
        FRIDAY_HOURS
        SATURDAY_HOURS
        CUISINE_TYPE
        CITY_NAME
        TAKEOUT_YN
        DELIVERY_YN
        PRICE_LEVEL_DOLLARS
        REST_OWNER
        CURBSIDE_YN
        DISPLAY_REST_YN
        OPEN_YN
        REST_PIC_LOCATION
        REST_ABOUT
        YELP_ID
        PICKUP_ENABLED_YN
        DINEIN_ENABLED_YN
        RESERVE_ENABLED_YN
        RDELIVERY_ENABLED_YN
        ORDER_PREPTIMEMOD_YN
        MENU_ITEM_INSTR_ENABLED_YN
        RDELIVERY_COMMENT
        APPLY_PROCESS_FEE
        PROCESS_FEE_PCT
        PROCESS_FEE_UNIT
        PROCESS_FEE_AMT
        PROCESS_AMT_UNIT
        PAY_NOW_FEE_MSG
        PAY_REST_FEE_MSG
        relatedMenuGroups {
          REST_ID
          MENU_ID
          MENU_NAME
          MENU_DESC
          MENU_GR_ID
          MENU_GR_NAME
          MENU_GR_DESC
          MENU_GR_ORDER
        }
        relatedSplUpcomingByRest {
          MENU_IT_ID
          MENU_IT_NAME
          REST_ID
          REST_NAME
          CITY_NAME
          CUISINE_TYPE
          MENU_IT_PIC_LOCATION
          DATE_FROM_DATE
          DATE_TO_DATE
          MENU_IT_DESC
          MENU_IT_PRICE
          MENU_ID
          MENU_NAME
          MENU_DESC
          MENU_TYPE
          MENU_GR_ID
          MENU_GR_NAME
          MENU_GR_ORDER
          MENU_ORDER
          MENU_IT_ORDER
          USER_LIKE_YN
          USER_BOOKMARK_YN
          EVERYDAY_YN
          SUNDAY_YN
          MONDAY_YN
          TUESDAY_YN
          WEDNESAY_YN
          THURSDAY_YN
          FRIDAY_YN
          SATURDAY_YN
          EVENT_ALLDAY_YN
          EVENT_TIME
          MENU_IT_TAXABLE_YN
        }
        relatedSplTodayByRest {
          MENU_IT_ID
          MENU_IT_NAME
          REST_ID
          REST_NAME
          CITY_NAME
          CUISINE_TYPE
          MENU_IT_PIC_LOCATION
          DATE_FROM_DATE
          DATE_TO_DATE
          MENU_IT_DESC
          MENU_IT_PRICE
          MENU_ID
          MENU_NAME
          MENU_DESC
          MENU_TYPE
          MENU_GR_ID
          MENU_GR_NAME
          MENU_GR_ORDER
          MENU_ORDER
          MENU_IT_ORDER
          USER_LIKE_YN
          USER_BOOKMARK_YN
          EVERYDAY_YN
          SUNDAY_YN
          MONDAY_YN
          TUESDAY_YN
          WEDNESAY_YN
          THURSDAY_YN
          FRIDAY_YN
          SATURDAY_YN
          EVENT_ALLDAY_YN
          EVENT_TIME
          MENU_IT_TAXABLE_YN
        }
        relatedRestBkmrk {
          REST_ID
          USER_BOOKMARK_YN
          USER_EMAIL
        }
        relatedRestPolls {
          REST_ID
          POLL_ID
          POLL_NAME
          POLL_QUESTION
          POLL_START_DATE_TIME
          POLL_END_DATE_TIME
          POLL_CREATE_DATE_TIME
          POLL_STATUS
          POLL_CHOICE_ID_1
          POLL_CHOICE_1
          POLL_CHOICE_ID_2
          POLL_CHOICE_2
          POLL_CHOICE_ID_3
          POLL_CHOICE_3
          POLL_CHOICE_ID_4
          POLL_CHOICE_4
        }
        relatedTaxRewards {
          REST_ID
          TAX_RATE
          REWARD_PCT
          REWARD_BAL
          DISCOUNT_ID
          DISCOUNT_TYPE
          DISCOUNT_CODE
          DISCOUNT_RATE
          DISCOUNT_AMT
          ORDER_AMT_MIN
          DISCOUNT_MAX_USAGE_COUNT
          DISCOUNT_USE_COUNT
          APPLY_DEFAULT
        }
      }
    }
  }
`;
export const listCuisineTypeMasteRs = /* GraphQL */ `
  query ListCuisineTypeMasteRs {
    listCUISINE_TYPE_MASTERs {
      CUISINE_ID
      CUISINE_TYPE_NAME
      CUISINE_PIC_LOACTION
      relatedRestaurants {
        REST_ID
        REST_NAME
        ADDRESS
        WEBSITE
        PHONE
        LATITUDE
        LONGITUDE
        RATING
        REVIEW_NUMBERS
        PRICE_LEVEL
        SUNDAY_HOURS
        MONDAY_HOURS
        TUESDAY_HOURS
        WEDNESDAY_HOURS
        THURSDAY_HOURS
        FRIDAY_HOURS
        SATURDAY_HOURS
        CUISINE_TYPE
        CITY_NAME
        TAKEOUT_YN
        DELIVERY_YN
        PRICE_LEVEL_DOLLARS
        REST_OWNER
        CURBSIDE_YN
        DISPLAY_REST_YN
        OPEN_YN
        REST_PIC_LOCATION
        REST_ABOUT
        YELP_ID
        PICKUP_ENABLED_YN
        DINEIN_ENABLED_YN
        RESERVE_ENABLED_YN
        RDELIVERY_ENABLED_YN
        ORDER_PREPTIMEMOD_YN
        MENU_ITEM_INSTR_ENABLED_YN
        RDELIVERY_COMMENT
        APPLY_PROCESS_FEE
        PROCESS_FEE_PCT
        PROCESS_FEE_UNIT
        PROCESS_FEE_AMT
        PROCESS_AMT_UNIT
        PAY_NOW_FEE_MSG
        PAY_REST_FEE_MSG
        relatedMenuGroups {
          REST_ID
          MENU_ID
          MENU_NAME
          MENU_DESC
          MENU_GR_ID
          MENU_GR_NAME
          MENU_GR_DESC
          MENU_GR_ORDER
        }
        relatedSplUpcomingByRest {
          MENU_IT_ID
          MENU_IT_NAME
          REST_ID
          REST_NAME
          CITY_NAME
          CUISINE_TYPE
          MENU_IT_PIC_LOCATION
          DATE_FROM_DATE
          DATE_TO_DATE
          MENU_IT_DESC
          MENU_IT_PRICE
          MENU_ID
          MENU_NAME
          MENU_DESC
          MENU_TYPE
          MENU_GR_ID
          MENU_GR_NAME
          MENU_GR_ORDER
          MENU_ORDER
          MENU_IT_ORDER
          USER_LIKE_YN
          USER_BOOKMARK_YN
          EVERYDAY_YN
          SUNDAY_YN
          MONDAY_YN
          TUESDAY_YN
          WEDNESAY_YN
          THURSDAY_YN
          FRIDAY_YN
          SATURDAY_YN
          EVENT_ALLDAY_YN
          EVENT_TIME
          MENU_IT_TAXABLE_YN
        }
        relatedSplTodayByRest {
          MENU_IT_ID
          MENU_IT_NAME
          REST_ID
          REST_NAME
          CITY_NAME
          CUISINE_TYPE
          MENU_IT_PIC_LOCATION
          DATE_FROM_DATE
          DATE_TO_DATE
          MENU_IT_DESC
          MENU_IT_PRICE
          MENU_ID
          MENU_NAME
          MENU_DESC
          MENU_TYPE
          MENU_GR_ID
          MENU_GR_NAME
          MENU_GR_ORDER
          MENU_ORDER
          MENU_IT_ORDER
          USER_LIKE_YN
          USER_BOOKMARK_YN
          EVERYDAY_YN
          SUNDAY_YN
          MONDAY_YN
          TUESDAY_YN
          WEDNESAY_YN
          THURSDAY_YN
          FRIDAY_YN
          SATURDAY_YN
          EVENT_ALLDAY_YN
          EVENT_TIME
          MENU_IT_TAXABLE_YN
        }
        relatedRestBkmrk {
          REST_ID
          USER_BOOKMARK_YN
          USER_EMAIL
        }
        relatedRestPolls {
          REST_ID
          POLL_ID
          POLL_NAME
          POLL_QUESTION
          POLL_START_DATE_TIME
          POLL_END_DATE_TIME
          POLL_CREATE_DATE_TIME
          POLL_STATUS
          POLL_CHOICE_ID_1
          POLL_CHOICE_1
          POLL_CHOICE_ID_2
          POLL_CHOICE_2
          POLL_CHOICE_ID_3
          POLL_CHOICE_3
          POLL_CHOICE_ID_4
          POLL_CHOICE_4
        }
        relatedTaxRewards {
          REST_ID
          TAX_RATE
          REWARD_PCT
          REWARD_BAL
          DISCOUNT_ID
          DISCOUNT_TYPE
          DISCOUNT_CODE
          DISCOUNT_RATE
          DISCOUNT_AMT
          ORDER_AMT_MIN
          DISCOUNT_MAX_USAGE_COUNT
          DISCOUNT_USE_COUNT
          APPLY_DEFAULT
        }
      }
    }
  }
`;
export const getCustomers = /* GraphQL */ `
  query GetCustomers($id: Int!) {
    getCustomers(id: $id) {
      id
      name
      phone
      email
    }
  }
`;
export const listCustomerss = /* GraphQL */ `
  query ListCustomerss {
    listCustomerss {
      id
      name
      phone
      email
    }
  }
`;
export const getRestMaster = /* GraphQL */ `
  query GetRestMaster($REST_ID: String!) {
    getREST_MASTER(REST_ID: $REST_ID) {
      REST_ID
      REST_NAME
      ADDRESS
      WEBSITE
      PHONE
      LATITUDE
      LONGITUDE
      RATING
      REVIEW_NUMBERS
      PRICE_LEVEL
      SUNDAY_HOURS
      MONDAY_HOURS
      TUESDAY_HOURS
      WEDNESDAY_HOURS
      THURSDAY_HOURS
      FRIDAY_HOURS
      SATURDAY_HOURS
      CUISINE_TYPE
      CITY_NAME
      TAKEOUT_YN
      DELIVERY_YN
      PRICE_LEVEL_DOLLARS
      REST_OWNER
      CURBSIDE_YN
      DISPLAY_REST_YN
      OPEN_YN
      REST_PIC_LOCATION
      REST_ABOUT
      YELP_ID
      PICKUP_ENABLED_YN
      DINEIN_ENABLED_YN
      RESERVE_ENABLED_YN
      RDELIVERY_ENABLED_YN
      ORDER_PREPTIMEMOD_YN
      MENU_ITEM_INSTR_ENABLED_YN
      RDELIVERY_COMMENT
      APPLY_PROCESS_FEE
      PROCESS_FEE_PCT
      PROCESS_FEE_UNIT
      PROCESS_FEE_AMT
      PROCESS_AMT_UNIT
      PAY_NOW_FEE_MSG
      PAY_REST_FEE_MSG
      relatedMenuGroups {
        REST_ID
        MENU_ID
        MENU_NAME
        MENU_DESC
        MENU_GR_ID
        MENU_GR_NAME
        MENU_GR_DESC
        MENU_GR_ORDER
        relatedMenuItems {
          REST_ID
          MENU_IT_ID
          MENU_IT_NAME
          MENU_IT_DESC
          MENU_IT_PRICE
          MENU_IT_PIC_LOCATION
          POPULAR_YN
          MENU_IT_STATUS
          MENU_IT_TAXABLE_YN
        }
      }
      relatedSplUpcomingByRest {
        MENU_IT_ID
        MENU_IT_NAME
        REST_ID
        REST_NAME
        CITY_NAME
        CUISINE_TYPE
        MENU_IT_PIC_LOCATION
        DATE_FROM_DATE
        DATE_TO_DATE
        MENU_IT_DESC
        MENU_IT_PRICE
        MENU_ID
        MENU_NAME
        MENU_DESC
        MENU_TYPE
        MENU_GR_ID
        MENU_GR_NAME
        MENU_GR_ORDER
        MENU_ORDER
        MENU_IT_ORDER
        USER_LIKE_YN
        USER_BOOKMARK_YN
        EVERYDAY_YN
        SUNDAY_YN
        MONDAY_YN
        TUESDAY_YN
        WEDNESAY_YN
        THURSDAY_YN
        FRIDAY_YN
        SATURDAY_YN
        EVENT_ALLDAY_YN
        EVENT_TIME
        MENU_IT_TAXABLE_YN
        relatedMenuItemLikes {
          MENU_IT_ID
          LIKE_COUNT
        }
      }
      relatedSplTodayByRest {
        MENU_IT_ID
        MENU_IT_NAME
        REST_ID
        REST_NAME
        CITY_NAME
        CUISINE_TYPE
        MENU_IT_PIC_LOCATION
        DATE_FROM_DATE
        DATE_TO_DATE
        MENU_IT_DESC
        MENU_IT_PRICE
        MENU_ID
        MENU_NAME
        MENU_DESC
        MENU_TYPE
        MENU_GR_ID
        MENU_GR_NAME
        MENU_GR_ORDER
        MENU_ORDER
        MENU_IT_ORDER
        USER_LIKE_YN
        USER_BOOKMARK_YN
        EVERYDAY_YN
        SUNDAY_YN
        MONDAY_YN
        TUESDAY_YN
        WEDNESAY_YN
        THURSDAY_YN
        FRIDAY_YN
        SATURDAY_YN
        EVENT_ALLDAY_YN
        EVENT_TIME
        MENU_IT_TAXABLE_YN
        relatedMenuItemLikes {
          MENU_IT_ID
          LIKE_COUNT
        }
      }
      relatedRestBkmrk {
        REST_ID
        USER_BOOKMARK_YN
        USER_EMAIL
      }
      relatedRestPolls {
        REST_ID
        POLL_ID
        POLL_NAME
        POLL_QUESTION
        POLL_START_DATE_TIME
        POLL_END_DATE_TIME
        POLL_CREATE_DATE_TIME
        POLL_STATUS
        POLL_CHOICE_ID_1
        POLL_CHOICE_1
        POLL_CHOICE_ID_2
        POLL_CHOICE_2
        POLL_CHOICE_ID_3
        POLL_CHOICE_3
        POLL_CHOICE_ID_4
        POLL_CHOICE_4
      }
      relatedTaxRewards {
        REST_ID
        TAX_RATE
        REWARD_PCT
        REWARD_BAL
        DISCOUNT_ID
        DISCOUNT_TYPE
        DISCOUNT_CODE
        DISCOUNT_RATE
        DISCOUNT_AMT
        ORDER_AMT_MIN
        DISCOUNT_MAX_USAGE_COUNT
        DISCOUNT_USE_COUNT
        APPLY_DEFAULT
      }
    }
  }
`;
export const listUserHomeTownSetInd = /* GraphQL */ `
  query ListUserHomeTownSetInd {
    listUSER_HOME_TOWN_SET_IND {
      USER_HOME_TOWN_SET_IND
    }
  }
`;
export const listRestMasteRs = /* GraphQL */ `
  query ListRestMasteRs {
    listREST_MASTERs {
      REST_ID
      REST_NAME
      ADDRESS
      WEBSITE
      PHONE
      LATITUDE
      LONGITUDE
      RATING
      REVIEW_NUMBERS
      PRICE_LEVEL
      SUNDAY_HOURS
      MONDAY_HOURS
      TUESDAY_HOURS
      WEDNESDAY_HOURS
      THURSDAY_HOURS
      FRIDAY_HOURS
      SATURDAY_HOURS
      CUISINE_TYPE
      CITY_NAME
      TAKEOUT_YN
      DELIVERY_YN
      PRICE_LEVEL_DOLLARS
      REST_OWNER
      CURBSIDE_YN
      DISPLAY_REST_YN
      OPEN_YN
      REST_PIC_LOCATION
      REST_ABOUT
      YELP_ID
      PICKUP_ENABLED_YN
      DINEIN_ENABLED_YN
      RESERVE_ENABLED_YN
      RDELIVERY_ENABLED_YN
      ORDER_PREPTIMEMOD_YN
      MENU_ITEM_INSTR_ENABLED_YN
      RDELIVERY_COMMENT
      APPLY_PROCESS_FEE
      PROCESS_FEE_PCT
      PROCESS_FEE_UNIT
      PROCESS_FEE_AMT
      PROCESS_AMT_UNIT
      PAY_NOW_FEE_MSG
      PAY_REST_FEE_MSG
      relatedMenuGroups {
        REST_ID
        MENU_ID
        MENU_NAME
        MENU_DESC
        MENU_GR_ID
        MENU_GR_NAME
        MENU_GR_DESC
        MENU_GR_ORDER
        relatedMenuItems {
          REST_ID
          MENU_IT_ID
          MENU_IT_NAME
          MENU_IT_DESC
          MENU_IT_PRICE
          MENU_IT_PIC_LOCATION
          POPULAR_YN
          MENU_IT_STATUS
          MENU_IT_TAXABLE_YN
        }
      }
      relatedSplUpcomingByRest {
        MENU_IT_ID
        MENU_IT_NAME
        REST_ID
        REST_NAME
        CITY_NAME
        CUISINE_TYPE
        MENU_IT_PIC_LOCATION
        DATE_FROM_DATE
        DATE_TO_DATE
        MENU_IT_DESC
        MENU_IT_PRICE
        MENU_ID
        MENU_NAME
        MENU_DESC
        MENU_TYPE
        MENU_GR_ID
        MENU_GR_NAME
        MENU_GR_ORDER
        MENU_ORDER
        MENU_IT_ORDER
        USER_LIKE_YN
        USER_BOOKMARK_YN
        EVERYDAY_YN
        SUNDAY_YN
        MONDAY_YN
        TUESDAY_YN
        WEDNESAY_YN
        THURSDAY_YN
        FRIDAY_YN
        SATURDAY_YN
        EVENT_ALLDAY_YN
        EVENT_TIME
        MENU_IT_TAXABLE_YN
        relatedMenuItemLikes {
          MENU_IT_ID
          LIKE_COUNT
        }
      }
      relatedSplTodayByRest {
        MENU_IT_ID
        MENU_IT_NAME
        REST_ID
        REST_NAME
        CITY_NAME
        CUISINE_TYPE
        MENU_IT_PIC_LOCATION
        DATE_FROM_DATE
        DATE_TO_DATE
        MENU_IT_DESC
        MENU_IT_PRICE
        MENU_ID
        MENU_NAME
        MENU_DESC
        MENU_TYPE
        MENU_GR_ID
        MENU_GR_NAME
        MENU_GR_ORDER
        MENU_ORDER
        MENU_IT_ORDER
        USER_LIKE_YN
        USER_BOOKMARK_YN
        EVERYDAY_YN
        SUNDAY_YN
        MONDAY_YN
        TUESDAY_YN
        WEDNESAY_YN
        THURSDAY_YN
        FRIDAY_YN
        SATURDAY_YN
        EVENT_ALLDAY_YN
        EVENT_TIME
        MENU_IT_TAXABLE_YN
        relatedMenuItemLikes {
          MENU_IT_ID
          LIKE_COUNT
        }
      }
      relatedRestBkmrk {
        REST_ID
        USER_BOOKMARK_YN
        USER_EMAIL
      }
      relatedRestPolls {
        REST_ID
        POLL_ID
        POLL_NAME
        POLL_QUESTION
        POLL_START_DATE_TIME
        POLL_END_DATE_TIME
        POLL_CREATE_DATE_TIME
        POLL_STATUS
        POLL_CHOICE_ID_1
        POLL_CHOICE_1
        POLL_CHOICE_ID_2
        POLL_CHOICE_2
        POLL_CHOICE_ID_3
        POLL_CHOICE_3
        POLL_CHOICE_ID_4
        POLL_CHOICE_4
      }
      relatedTaxRewards {
        REST_ID
        TAX_RATE
        REWARD_PCT
        REWARD_BAL
        DISCOUNT_ID
        DISCOUNT_TYPE
        DISCOUNT_CODE
        DISCOUNT_RATE
        DISCOUNT_AMT
        ORDER_AMT_MIN
        DISCOUNT_MAX_USAGE_COUNT
        DISCOUNT_USE_COUNT
        APPLY_DEFAULT
      }
    }
  }
`;
export const listSpotlightRestMasteRs = /* GraphQL */ `
  query ListSpotlightRestMasteRs {
    listSPOTLIGHT_REST_MASTERs {
      REST_ID
      REST_NAME
      ADDRESS
      WEBSITE
      PHONE
      LATITUDE
      LONGITUDE
      RATING
      REVIEW_NUMBERS
      PRICE_LEVEL
      SUNDAY_HOURS
      MONDAY_HOURS
      TUESDAY_HOURS
      WEDNESDAY_HOURS
      THURSDAY_HOURS
      FRIDAY_HOURS
      SATURDAY_HOURS
      CUISINE_TYPE
      CITY_NAME
      TAKEOUT_YN
      DELIVERY_YN
      PRICE_LEVEL_DOLLARS
      REST_OWNER
      CURBSIDE_YN
      DISPLAY_REST_YN
      OPEN_YN
      REST_PIC_LOCATION
      REST_ABOUT
      YELP_ID
      PICKUP_ENABLED_YN
      DINEIN_ENABLED_YN
      RESERVE_ENABLED_YN
      RDELIVERY_ENABLED_YN
      ORDER_PREPTIMEMOD_YN
      MENU_ITEM_INSTR_ENABLED_YN
      RDELIVERY_COMMENT
      APPLY_PROCESS_FEE
      PROCESS_FEE_PCT
      PROCESS_FEE_UNIT
      PROCESS_FEE_AMT
      PROCESS_AMT_UNIT
      PAY_NOW_FEE_MSG
      PAY_REST_FEE_MSG
      relatedMenuGroups {
        REST_ID
        MENU_ID
        MENU_NAME
        MENU_DESC
        MENU_GR_ID
        MENU_GR_NAME
        MENU_GR_DESC
        MENU_GR_ORDER
        relatedMenuItems {
          REST_ID
          MENU_IT_ID
          MENU_IT_NAME
          MENU_IT_DESC
          MENU_IT_PRICE
          MENU_IT_PIC_LOCATION
          POPULAR_YN
          MENU_IT_STATUS
          MENU_IT_TAXABLE_YN
        }
      }
      relatedSplUpcomingByRest {
        MENU_IT_ID
        MENU_IT_NAME
        REST_ID
        REST_NAME
        CITY_NAME
        CUISINE_TYPE
        MENU_IT_PIC_LOCATION
        DATE_FROM_DATE
        DATE_TO_DATE
        MENU_IT_DESC
        MENU_IT_PRICE
        MENU_ID
        MENU_NAME
        MENU_DESC
        MENU_TYPE
        MENU_GR_ID
        MENU_GR_NAME
        MENU_GR_ORDER
        MENU_ORDER
        MENU_IT_ORDER
        USER_LIKE_YN
        USER_BOOKMARK_YN
        EVERYDAY_YN
        SUNDAY_YN
        MONDAY_YN
        TUESDAY_YN
        WEDNESAY_YN
        THURSDAY_YN
        FRIDAY_YN
        SATURDAY_YN
        EVENT_ALLDAY_YN
        EVENT_TIME
        MENU_IT_TAXABLE_YN
        relatedMenuItemLikes {
          MENU_IT_ID
          LIKE_COUNT
        }
      }
      relatedSplTodayByRest {
        MENU_IT_ID
        MENU_IT_NAME
        REST_ID
        REST_NAME
        CITY_NAME
        CUISINE_TYPE
        MENU_IT_PIC_LOCATION
        DATE_FROM_DATE
        DATE_TO_DATE
        MENU_IT_DESC
        MENU_IT_PRICE
        MENU_ID
        MENU_NAME
        MENU_DESC
        MENU_TYPE
        MENU_GR_ID
        MENU_GR_NAME
        MENU_GR_ORDER
        MENU_ORDER
        MENU_IT_ORDER
        USER_LIKE_YN
        USER_BOOKMARK_YN
        EVERYDAY_YN
        SUNDAY_YN
        MONDAY_YN
        TUESDAY_YN
        WEDNESAY_YN
        THURSDAY_YN
        FRIDAY_YN
        SATURDAY_YN
        EVENT_ALLDAY_YN
        EVENT_TIME
        MENU_IT_TAXABLE_YN
        relatedMenuItemLikes {
          MENU_IT_ID
          LIKE_COUNT
        }
      }
      relatedRestBkmrk {
        REST_ID
        USER_BOOKMARK_YN
        USER_EMAIL
      }
      relatedRestPolls {
        REST_ID
        POLL_ID
        POLL_NAME
        POLL_QUESTION
        POLL_START_DATE_TIME
        POLL_END_DATE_TIME
        POLL_CREATE_DATE_TIME
        POLL_STATUS
        POLL_CHOICE_ID_1
        POLL_CHOICE_1
        POLL_CHOICE_ID_2
        POLL_CHOICE_2
        POLL_CHOICE_ID_3
        POLL_CHOICE_3
        POLL_CHOICE_ID_4
        POLL_CHOICE_4
      }
      relatedTaxRewards {
        REST_ID
        TAX_RATE
        REWARD_PCT
        REWARD_BAL
        DISCOUNT_ID
        DISCOUNT_TYPE
        DISCOUNT_CODE
        DISCOUNT_RATE
        DISCOUNT_AMT
        ORDER_AMT_MIN
        DISCOUNT_MAX_USAGE_COUNT
        DISCOUNT_USE_COUNT
        APPLY_DEFAULT
      }
    }
  }
`;
export const listBkmrkRestMasteRs = /* GraphQL */ `
  query ListBkmrkRestMasteRs {
    listBKMRK_REST_MASTERs {
      REST_ID
      REST_NAME
      ADDRESS
      WEBSITE
      PHONE
      LATITUDE
      LONGITUDE
      RATING
      REVIEW_NUMBERS
      PRICE_LEVEL
      SUNDAY_HOURS
      MONDAY_HOURS
      TUESDAY_HOURS
      WEDNESDAY_HOURS
      THURSDAY_HOURS
      FRIDAY_HOURS
      SATURDAY_HOURS
      CUISINE_TYPE
      CITY_NAME
      TAKEOUT_YN
      DELIVERY_YN
      PRICE_LEVEL_DOLLARS
      REST_OWNER
      CURBSIDE_YN
      DISPLAY_REST_YN
      OPEN_YN
      REST_PIC_LOCATION
      REST_ABOUT
      YELP_ID
      PICKUP_ENABLED_YN
      DINEIN_ENABLED_YN
      RESERVE_ENABLED_YN
      RDELIVERY_ENABLED_YN
      ORDER_PREPTIMEMOD_YN
      MENU_ITEM_INSTR_ENABLED_YN
      RDELIVERY_COMMENT
      APPLY_PROCESS_FEE
      PROCESS_FEE_PCT
      PROCESS_FEE_UNIT
      PROCESS_FEE_AMT
      PROCESS_AMT_UNIT
      PAY_NOW_FEE_MSG
      PAY_REST_FEE_MSG
      relatedMenuGroups {
        REST_ID
        MENU_ID
        MENU_NAME
        MENU_DESC
        MENU_GR_ID
        MENU_GR_NAME
        MENU_GR_DESC
        MENU_GR_ORDER
        relatedMenuItems {
          REST_ID
          MENU_IT_ID
          MENU_IT_NAME
          MENU_IT_DESC
          MENU_IT_PRICE
          MENU_IT_PIC_LOCATION
          POPULAR_YN
          MENU_IT_STATUS
          MENU_IT_TAXABLE_YN
        }
      }
      relatedSplUpcomingByRest {
        MENU_IT_ID
        MENU_IT_NAME
        REST_ID
        REST_NAME
        CITY_NAME
        CUISINE_TYPE
        MENU_IT_PIC_LOCATION
        DATE_FROM_DATE
        DATE_TO_DATE
        MENU_IT_DESC
        MENU_IT_PRICE
        MENU_ID
        MENU_NAME
        MENU_DESC
        MENU_TYPE
        MENU_GR_ID
        MENU_GR_NAME
        MENU_GR_ORDER
        MENU_ORDER
        MENU_IT_ORDER
        USER_LIKE_YN
        USER_BOOKMARK_YN
        EVERYDAY_YN
        SUNDAY_YN
        MONDAY_YN
        TUESDAY_YN
        WEDNESAY_YN
        THURSDAY_YN
        FRIDAY_YN
        SATURDAY_YN
        EVENT_ALLDAY_YN
        EVENT_TIME
        MENU_IT_TAXABLE_YN
        relatedMenuItemLikes {
          MENU_IT_ID
          LIKE_COUNT
        }
      }
      relatedSplTodayByRest {
        MENU_IT_ID
        MENU_IT_NAME
        REST_ID
        REST_NAME
        CITY_NAME
        CUISINE_TYPE
        MENU_IT_PIC_LOCATION
        DATE_FROM_DATE
        DATE_TO_DATE
        MENU_IT_DESC
        MENU_IT_PRICE
        MENU_ID
        MENU_NAME
        MENU_DESC
        MENU_TYPE
        MENU_GR_ID
        MENU_GR_NAME
        MENU_GR_ORDER
        MENU_ORDER
        MENU_IT_ORDER
        USER_LIKE_YN
        USER_BOOKMARK_YN
        EVERYDAY_YN
        SUNDAY_YN
        MONDAY_YN
        TUESDAY_YN
        WEDNESAY_YN
        THURSDAY_YN
        FRIDAY_YN
        SATURDAY_YN
        EVENT_ALLDAY_YN
        EVENT_TIME
        MENU_IT_TAXABLE_YN
        relatedMenuItemLikes {
          MENU_IT_ID
          LIKE_COUNT
        }
      }
      relatedRestBkmrk {
        REST_ID
        USER_BOOKMARK_YN
        USER_EMAIL
      }
      relatedRestPolls {
        REST_ID
        POLL_ID
        POLL_NAME
        POLL_QUESTION
        POLL_START_DATE_TIME
        POLL_END_DATE_TIME
        POLL_CREATE_DATE_TIME
        POLL_STATUS
        POLL_CHOICE_ID_1
        POLL_CHOICE_1
        POLL_CHOICE_ID_2
        POLL_CHOICE_2
        POLL_CHOICE_ID_3
        POLL_CHOICE_3
        POLL_CHOICE_ID_4
        POLL_CHOICE_4
      }
      relatedTaxRewards {
        REST_ID
        TAX_RATE
        REWARD_PCT
        REWARD_BAL
        DISCOUNT_ID
        DISCOUNT_TYPE
        DISCOUNT_CODE
        DISCOUNT_RATE
        DISCOUNT_AMT
        ORDER_AMT_MIN
        DISCOUNT_MAX_USAGE_COUNT
        DISCOUNT_USE_COUNT
        APPLY_DEFAULT
      }
    }
  }
`;
export const listUserCuisineTypeMasterFav = /* GraphQL */ `
  query ListUserCuisineTypeMasterFav {
    listUSER_CUISINE_TYPE_MASTER_FAV {
      CUISINE_ID
      CUISINE_TYPE_NAME
      CUISINE_PIC_LOACTION
      USER_FAVORITE_YN
      relatedRestaurants {
        REST_ID
        REST_NAME
        ADDRESS
        WEBSITE
        PHONE
        LATITUDE
        LONGITUDE
        RATING
        REVIEW_NUMBERS
        PRICE_LEVEL
        SUNDAY_HOURS
        MONDAY_HOURS
        TUESDAY_HOURS
        WEDNESDAY_HOURS
        THURSDAY_HOURS
        FRIDAY_HOURS
        SATURDAY_HOURS
        CUISINE_TYPE
        CITY_NAME
        TAKEOUT_YN
        DELIVERY_YN
        PRICE_LEVEL_DOLLARS
        REST_OWNER
        CURBSIDE_YN
        DISPLAY_REST_YN
        OPEN_YN
        REST_PIC_LOCATION
        REST_ABOUT
        YELP_ID
        PICKUP_ENABLED_YN
        DINEIN_ENABLED_YN
        RESERVE_ENABLED_YN
        RDELIVERY_ENABLED_YN
        ORDER_PREPTIMEMOD_YN
        MENU_ITEM_INSTR_ENABLED_YN
        RDELIVERY_COMMENT
        APPLY_PROCESS_FEE
        PROCESS_FEE_PCT
        PROCESS_FEE_UNIT
        PROCESS_FEE_AMT
        PROCESS_AMT_UNIT
        PAY_NOW_FEE_MSG
        PAY_REST_FEE_MSG
        relatedMenuGroups {
          REST_ID
          MENU_ID
          MENU_NAME
          MENU_DESC
          MENU_GR_ID
          MENU_GR_NAME
          MENU_GR_DESC
          MENU_GR_ORDER
        }
        relatedSplUpcomingByRest {
          MENU_IT_ID
          MENU_IT_NAME
          REST_ID
          REST_NAME
          CITY_NAME
          CUISINE_TYPE
          MENU_IT_PIC_LOCATION
          DATE_FROM_DATE
          DATE_TO_DATE
          MENU_IT_DESC
          MENU_IT_PRICE
          MENU_ID
          MENU_NAME
          MENU_DESC
          MENU_TYPE
          MENU_GR_ID
          MENU_GR_NAME
          MENU_GR_ORDER
          MENU_ORDER
          MENU_IT_ORDER
          USER_LIKE_YN
          USER_BOOKMARK_YN
          EVERYDAY_YN
          SUNDAY_YN
          MONDAY_YN
          TUESDAY_YN
          WEDNESAY_YN
          THURSDAY_YN
          FRIDAY_YN
          SATURDAY_YN
          EVENT_ALLDAY_YN
          EVENT_TIME
          MENU_IT_TAXABLE_YN
        }
        relatedSplTodayByRest {
          MENU_IT_ID
          MENU_IT_NAME
          REST_ID
          REST_NAME
          CITY_NAME
          CUISINE_TYPE
          MENU_IT_PIC_LOCATION
          DATE_FROM_DATE
          DATE_TO_DATE
          MENU_IT_DESC
          MENU_IT_PRICE
          MENU_ID
          MENU_NAME
          MENU_DESC
          MENU_TYPE
          MENU_GR_ID
          MENU_GR_NAME
          MENU_GR_ORDER
          MENU_ORDER
          MENU_IT_ORDER
          USER_LIKE_YN
          USER_BOOKMARK_YN
          EVERYDAY_YN
          SUNDAY_YN
          MONDAY_YN
          TUESDAY_YN
          WEDNESAY_YN
          THURSDAY_YN
          FRIDAY_YN
          SATURDAY_YN
          EVENT_ALLDAY_YN
          EVENT_TIME
          MENU_IT_TAXABLE_YN
        }
        relatedRestBkmrk {
          REST_ID
          USER_BOOKMARK_YN
          USER_EMAIL
        }
        relatedRestPolls {
          REST_ID
          POLL_ID
          POLL_NAME
          POLL_QUESTION
          POLL_START_DATE_TIME
          POLL_END_DATE_TIME
          POLL_CREATE_DATE_TIME
          POLL_STATUS
          POLL_CHOICE_ID_1
          POLL_CHOICE_1
          POLL_CHOICE_ID_2
          POLL_CHOICE_2
          POLL_CHOICE_ID_3
          POLL_CHOICE_3
          POLL_CHOICE_ID_4
          POLL_CHOICE_4
        }
        relatedTaxRewards {
          REST_ID
          TAX_RATE
          REWARD_PCT
          REWARD_BAL
          DISCOUNT_ID
          DISCOUNT_TYPE
          DISCOUNT_CODE
          DISCOUNT_RATE
          DISCOUNT_AMT
          ORDER_AMT_MIN
          DISCOUNT_MAX_USAGE_COUNT
          DISCOUNT_USE_COUNT
          APPLY_DEFAULT
        }
      }
    }
  }
`;
export const listUserCuisineTypeMasterFavOnlyY = /* GraphQL */ `
  query ListUserCuisineTypeMasterFavOnlyY {
    listUSER_CUISINE_TYPE_MASTER_FAV_ONLY_Y {
      CUISINE_ID
      CUISINE_TYPE_NAME
      CUISINE_PIC_LOACTION
      USER_FAVORITE_YN
      relatedRestaurants {
        REST_ID
        REST_NAME
        ADDRESS
        WEBSITE
        PHONE
        LATITUDE
        LONGITUDE
        RATING
        REVIEW_NUMBERS
        PRICE_LEVEL
        SUNDAY_HOURS
        MONDAY_HOURS
        TUESDAY_HOURS
        WEDNESDAY_HOURS
        THURSDAY_HOURS
        FRIDAY_HOURS
        SATURDAY_HOURS
        CUISINE_TYPE
        CITY_NAME
        TAKEOUT_YN
        DELIVERY_YN
        PRICE_LEVEL_DOLLARS
        REST_OWNER
        CURBSIDE_YN
        DISPLAY_REST_YN
        OPEN_YN
        REST_PIC_LOCATION
        REST_ABOUT
        YELP_ID
        PICKUP_ENABLED_YN
        DINEIN_ENABLED_YN
        RESERVE_ENABLED_YN
        RDELIVERY_ENABLED_YN
        ORDER_PREPTIMEMOD_YN
        MENU_ITEM_INSTR_ENABLED_YN
        RDELIVERY_COMMENT
        APPLY_PROCESS_FEE
        PROCESS_FEE_PCT
        PROCESS_FEE_UNIT
        PROCESS_FEE_AMT
        PROCESS_AMT_UNIT
        PAY_NOW_FEE_MSG
        PAY_REST_FEE_MSG
        relatedMenuGroups {
          REST_ID
          MENU_ID
          MENU_NAME
          MENU_DESC
          MENU_GR_ID
          MENU_GR_NAME
          MENU_GR_DESC
          MENU_GR_ORDER
        }
        relatedSplUpcomingByRest {
          MENU_IT_ID
          MENU_IT_NAME
          REST_ID
          REST_NAME
          CITY_NAME
          CUISINE_TYPE
          MENU_IT_PIC_LOCATION
          DATE_FROM_DATE
          DATE_TO_DATE
          MENU_IT_DESC
          MENU_IT_PRICE
          MENU_ID
          MENU_NAME
          MENU_DESC
          MENU_TYPE
          MENU_GR_ID
          MENU_GR_NAME
          MENU_GR_ORDER
          MENU_ORDER
          MENU_IT_ORDER
          USER_LIKE_YN
          USER_BOOKMARK_YN
          EVERYDAY_YN
          SUNDAY_YN
          MONDAY_YN
          TUESDAY_YN
          WEDNESAY_YN
          THURSDAY_YN
          FRIDAY_YN
          SATURDAY_YN
          EVENT_ALLDAY_YN
          EVENT_TIME
          MENU_IT_TAXABLE_YN
        }
        relatedSplTodayByRest {
          MENU_IT_ID
          MENU_IT_NAME
          REST_ID
          REST_NAME
          CITY_NAME
          CUISINE_TYPE
          MENU_IT_PIC_LOCATION
          DATE_FROM_DATE
          DATE_TO_DATE
          MENU_IT_DESC
          MENU_IT_PRICE
          MENU_ID
          MENU_NAME
          MENU_DESC
          MENU_TYPE
          MENU_GR_ID
          MENU_GR_NAME
          MENU_GR_ORDER
          MENU_ORDER
          MENU_IT_ORDER
          USER_LIKE_YN
          USER_BOOKMARK_YN
          EVERYDAY_YN
          SUNDAY_YN
          MONDAY_YN
          TUESDAY_YN
          WEDNESAY_YN
          THURSDAY_YN
          FRIDAY_YN
          SATURDAY_YN
          EVENT_ALLDAY_YN
          EVENT_TIME
          MENU_IT_TAXABLE_YN
        }
        relatedRestBkmrk {
          REST_ID
          USER_BOOKMARK_YN
          USER_EMAIL
        }
        relatedRestPolls {
          REST_ID
          POLL_ID
          POLL_NAME
          POLL_QUESTION
          POLL_START_DATE_TIME
          POLL_END_DATE_TIME
          POLL_CREATE_DATE_TIME
          POLL_STATUS
          POLL_CHOICE_ID_1
          POLL_CHOICE_1
          POLL_CHOICE_ID_2
          POLL_CHOICE_2
          POLL_CHOICE_ID_3
          POLL_CHOICE_3
          POLL_CHOICE_ID_4
          POLL_CHOICE_4
        }
        relatedTaxRewards {
          REST_ID
          TAX_RATE
          REWARD_PCT
          REWARD_BAL
          DISCOUNT_ID
          DISCOUNT_TYPE
          DISCOUNT_CODE
          DISCOUNT_RATE
          DISCOUNT_AMT
          ORDER_AMT_MIN
          DISCOUNT_MAX_USAGE_COUNT
          DISCOUNT_USE_COUNT
          APPLY_DEFAULT
        }
      }
    }
  }
`;
export const listUserHomeFeedSpecialsUpcoming = /* GraphQL */ `
  query ListUserHomeFeedSpecialsUpcoming {
    listUSER_HOME_FEED_SPECIALS_UPCOMING {
      MENU_IT_ID
      MENU_IT_NAME
      REST_ID
      REST_NAME
      CITY_NAME
      CUISINE_TYPE
      MENU_IT_PIC_LOCATION
      DATE_FROM_DATE
      DATE_TO_DATE
      MENU_IT_DESC
      MENU_IT_PRICE
      MENU_ID
      MENU_NAME
      MENU_DESC
      MENU_TYPE
      MENU_GR_ID
      MENU_GR_NAME
      MENU_GR_ORDER
      MENU_ORDER
      MENU_IT_ORDER
      USER_LIKE_YN
      USER_BOOKMARK_YN
      EVERYDAY_YN
      SUNDAY_YN
      MONDAY_YN
      TUESDAY_YN
      WEDNESAY_YN
      THURSDAY_YN
      FRIDAY_YN
      SATURDAY_YN
      EVENT_ALLDAY_YN
      EVENT_TIME
      MENU_IT_TAXABLE_YN
      relatedMenuItemLikes {
        MENU_IT_ID
        LIKE_COUNT
      }
    }
  }
`;
export const listUserHomeFeedSpecialsToday = /* GraphQL */ `
  query ListUserHomeFeedSpecialsToday {
    listUSER_HOME_FEED_SPECIALS_TODAY {
      MENU_IT_ID
      MENU_IT_NAME
      REST_ID
      REST_NAME
      CITY_NAME
      CUISINE_TYPE
      MENU_IT_PIC_LOCATION
      DATE_FROM_DATE
      DATE_TO_DATE
      MENU_IT_DESC
      MENU_IT_PRICE
      MENU_ID
      MENU_NAME
      MENU_DESC
      MENU_TYPE
      MENU_GR_ID
      MENU_GR_NAME
      MENU_GR_ORDER
      MENU_ORDER
      MENU_IT_ORDER
      USER_LIKE_YN
      USER_BOOKMARK_YN
      EVERYDAY_YN
      SUNDAY_YN
      MONDAY_YN
      TUESDAY_YN
      WEDNESAY_YN
      THURSDAY_YN
      FRIDAY_YN
      SATURDAY_YN
      EVENT_ALLDAY_YN
      EVENT_TIME
      MENU_IT_TAXABLE_YN
      relatedMenuItemLikes {
        MENU_IT_ID
        LIKE_COUNT
      }
    }
  }
`;
export const listUserPopularMenuItems = /* GraphQL */ `
  query ListUserPopularMenuItems {
    listUSER_POPULAR_MENU_ITEMS {
      MENU_IT_ID
      MENU_IT_NAME
      REST_ID
      REST_NAME
      CITY_NAME
      CUISINE_TYPE
      MENU_IT_PIC_LOCATION
      MENU_IT_DESC
      MENU_IT_PRICE
      MENU_ID
      MENU_NAME
      MENU_DESC
      MENU_TYPE
      MENU_GR_ID
      MENU_GR_NAME
      MENU_GR_ORDER
      MENU_ORDER
      MENU_IT_ORDER
      POPULAR_YN
    }
  }
`;
export const getMasterMenuChoices = /* GraphQL */ `
  query GetMasterMenuChoices($REST_ID: String) {
    getMASTER_MENU_CHOICES(REST_ID: $REST_ID) {
      REST_ID
      CH_GRP_ID
      CH_ID
      CH_DESC
      CH_LABEL
      CH_ID_ORDER
      MUST_CHOOSE_FLAG
      MULTI_SELECT_IND
      BASE_MODIFIER_IND
      MIN_QTY
      MAX_QTY
      relatedMasterChoiceItems {
        CH_ITEM_ID
        CH_ITEM_DESC
        CH_ITEM_ID_ORDER
        CH_DEFAULT
        CH_PRICE
        CH_ITEM_STATUS
      }
    }
  }
`;
export const getMenuChoices = /* GraphQL */ `
  query GetMenuChoices($MENU_IT_ID: String) {
    getMENU_CHOICES(MENU_IT_ID: $MENU_IT_ID) {
      REST_ID
      MENU_IT_ID
      MENU_IT_NAME
      MENU_IT_PRICE
      CH_ID
      CH_DESC
      data {
        CH_ITEM_ID
        CH_ITEM_DESC
        CH_PRICE
        CH_DEFAULT
        MUST_CHOOSE_FLAG
        BASE_MODIFIER_IND
        MULTI_SELECT_IND
        MIN_QTY
        MAX_QTY
      }
    }
  }
`;
export const getCitySearch = /* GraphQL */ `
  query GetCitySearch($CITY_NAME: String!) {
    getCITY_SEARCH(CITY_NAME: $CITY_NAME) {
      city_state_id
      city
      state_id
      zip_code
    }
  }
`;
export const getNearbyCitySearch = /* GraphQL */ `
  query GetNearbyCitySearch($ZIPCODE: String!) {
    getNEARBY_CITY_SEARCH(ZIPCODE: $ZIPCODE) {
      city_state_id
      city
      state_id
      zip_code
    }
  }
`;
export const listNewcity = /* GraphQL */ `
  query ListNewcity($CITY_NAME: String!) {
    listNEWCITY(CITY_NAME: $CITY_NAME) {
      city
      state_id
      state_name
    }
  }
`;
export const listMasterMenuChoices = /* GraphQL */ `
  query ListMasterMenuChoices {
    listMASTER_MENU_CHOICES {
      REST_ID
      CH_GRP_ID
      CH_ID
      CH_DESC
      CH_LABEL
      CH_ID_ORDER
      MUST_CHOOSE_FLAG
      MULTI_SELECT_IND
      BASE_MODIFIER_IND
      MIN_QTY
      MAX_QTY
      relatedMasterChoiceItems {
        CH_ITEM_ID
        CH_ITEM_DESC
        CH_ITEM_ID_ORDER
        CH_DEFAULT
        CH_PRICE
        CH_ITEM_STATUS
      }
    }
  }
`;
export const listUserTowns = /* GraphQL */ `
  query ListUserTowns {
    listUSER_TOWNS {
      city_state_id
      city
      state_id
      zip_code
      ht_ind
    }
  }
`;
export const getRestMasterByTown = /* GraphQL */ `
  query GetRestMasterByTown($ZIPCODE: String!) {
    getREST_MASTER_BY_TOWN(ZIPCODE: $ZIPCODE) {
      REST_ID
      REST_NAME
      ADDRESS
      WEBSITE
      PHONE
      LATITUDE
      LONGITUDE
      RATING
      REVIEW_NUMBERS
      PRICE_LEVEL
      SUNDAY_HOURS
      MONDAY_HOURS
      TUESDAY_HOURS
      WEDNESDAY_HOURS
      THURSDAY_HOURS
      FRIDAY_HOURS
      SATURDAY_HOURS
      CUISINE_TYPE
      CITY_NAME
      TAKEOUT_YN
      DELIVERY_YN
      PRICE_LEVEL_DOLLARS
      REST_OWNER
      CURBSIDE_YN
      DISPLAY_REST_YN
      OPEN_YN
      REST_PIC_LOCATION
      REST_ABOUT
      YELP_ID
      PICKUP_ENABLED_YN
      DINEIN_ENABLED_YN
      RESERVE_ENABLED_YN
      RDELIVERY_ENABLED_YN
      ORDER_PREPTIMEMOD_YN
      MENU_ITEM_INSTR_ENABLED_YN
      RDELIVERY_COMMENT
      APPLY_PROCESS_FEE
      PROCESS_FEE_PCT
      PROCESS_FEE_UNIT
      PROCESS_FEE_AMT
      PROCESS_AMT_UNIT
      PAY_NOW_FEE_MSG
      PAY_REST_FEE_MSG
      relatedMenuGroups {
        REST_ID
        MENU_ID
        MENU_NAME
        MENU_DESC
        MENU_GR_ID
        MENU_GR_NAME
        MENU_GR_DESC
        MENU_GR_ORDER
        relatedMenuItems {
          REST_ID
          MENU_IT_ID
          MENU_IT_NAME
          MENU_IT_DESC
          MENU_IT_PRICE
          MENU_IT_PIC_LOCATION
          POPULAR_YN
          MENU_IT_STATUS
          MENU_IT_TAXABLE_YN
        }
      }
      relatedSplUpcomingByRest {
        MENU_IT_ID
        MENU_IT_NAME
        REST_ID
        REST_NAME
        CITY_NAME
        CUISINE_TYPE
        MENU_IT_PIC_LOCATION
        DATE_FROM_DATE
        DATE_TO_DATE
        MENU_IT_DESC
        MENU_IT_PRICE
        MENU_ID
        MENU_NAME
        MENU_DESC
        MENU_TYPE
        MENU_GR_ID
        MENU_GR_NAME
        MENU_GR_ORDER
        MENU_ORDER
        MENU_IT_ORDER
        USER_LIKE_YN
        USER_BOOKMARK_YN
        EVERYDAY_YN
        SUNDAY_YN
        MONDAY_YN
        TUESDAY_YN
        WEDNESAY_YN
        THURSDAY_YN
        FRIDAY_YN
        SATURDAY_YN
        EVENT_ALLDAY_YN
        EVENT_TIME
        MENU_IT_TAXABLE_YN
        relatedMenuItemLikes {
          MENU_IT_ID
          LIKE_COUNT
        }
      }
      relatedSplTodayByRest {
        MENU_IT_ID
        MENU_IT_NAME
        REST_ID
        REST_NAME
        CITY_NAME
        CUISINE_TYPE
        MENU_IT_PIC_LOCATION
        DATE_FROM_DATE
        DATE_TO_DATE
        MENU_IT_DESC
        MENU_IT_PRICE
        MENU_ID
        MENU_NAME
        MENU_DESC
        MENU_TYPE
        MENU_GR_ID
        MENU_GR_NAME
        MENU_GR_ORDER
        MENU_ORDER
        MENU_IT_ORDER
        USER_LIKE_YN
        USER_BOOKMARK_YN
        EVERYDAY_YN
        SUNDAY_YN
        MONDAY_YN
        TUESDAY_YN
        WEDNESAY_YN
        THURSDAY_YN
        FRIDAY_YN
        SATURDAY_YN
        EVENT_ALLDAY_YN
        EVENT_TIME
        MENU_IT_TAXABLE_YN
        relatedMenuItemLikes {
          MENU_IT_ID
          LIKE_COUNT
        }
      }
      relatedRestBkmrk {
        REST_ID
        USER_BOOKMARK_YN
        USER_EMAIL
      }
      relatedRestPolls {
        REST_ID
        POLL_ID
        POLL_NAME
        POLL_QUESTION
        POLL_START_DATE_TIME
        POLL_END_DATE_TIME
        POLL_CREATE_DATE_TIME
        POLL_STATUS
        POLL_CHOICE_ID_1
        POLL_CHOICE_1
        POLL_CHOICE_ID_2
        POLL_CHOICE_2
        POLL_CHOICE_ID_3
        POLL_CHOICE_3
        POLL_CHOICE_ID_4
        POLL_CHOICE_4
      }
      relatedTaxRewards {
        REST_ID
        TAX_RATE
        REWARD_PCT
        REWARD_BAL
        DISCOUNT_ID
        DISCOUNT_TYPE
        DISCOUNT_CODE
        DISCOUNT_RATE
        DISCOUNT_AMT
        ORDER_AMT_MIN
        DISCOUNT_MAX_USAGE_COUNT
        DISCOUNT_USE_COUNT
        APPLY_DEFAULT
      }
    }
  }
`;
export const listSearch = /* GraphQL */ `
  query ListSearch($SEARCHTERM: String!, $LATITUDE: Float, $LONGITUDE: Float) {
    listSEARCH(
      SEARCHTERM: $SEARCHTERM
      LATITUDE: $LATITUDE
      LONGITUDE: $LONGITUDE
    ) {
      SEARCH_VAL
      LATITUDE_VAL
      LONGITUDE_VAL
      relatedSrcRestaurants {
        score
        REST_ID
        REST_NAME
        CITY_NAME
        ZIP_CODE
        CUISINE_ID
        CUISINE_TYPE_NAME
      }
      relatedSrcMenuItems {
        score
        MENU_ID
        MENU_NAME
        MENU_GR_ID
        MENU_GR_NAME
        MENU_TYPE
        MENU_IT_ID
        MENU_IT_NAME
        MENU_IT_DESC
        MENU_IT_PRICE
        REST_ID
        REST_NAME
        CITY_NAME
        ZIP_CODE
        MENU_IT_PIC_LOCATION
        DATE_FROM_DATE
        DATE_TO_DATE
        EVERYDAY_YN
        SUNDAY_YN
        MONDAY_YN
        TUESDAY_YN
        WEDNESAY_YN
        THURSDAY_YN
        FRIDAY_YN
        SATURDAY_YN
        MENU_IT_STATUS
        EVENT_ALLDAY_YN
        EVENT_TIME
        USER_LIKE_YN
        USER_BOOKMARK_YN
        MENU_IT_TAXABLE_YN
        relatedMenuItemLikes {
          MENU_IT_ID
          LIKE_COUNT
        }
      }
    }
  }
`;
export const getRestTaxRate = /* GraphQL */ `
  query GetRestTaxRate($REST_ID: String!) {
    getREST_TAX_RATE(REST_ID: $REST_ID) {
      REST_ID
      TAX_RATE
    }
  }
`;
export const checkRestDiscValid = /* GraphQL */ `
  query CheckRestDiscValid($REST_ID: String!, $DISC_CODE: String!) {
    checkREST_DISC_VALID(REST_ID: $REST_ID, DISC_CODE: $DISC_CODE) {
      REST_ID
      DISCOUNT_ID
      DISC_VALID
      DISCOUNT_TYPE
      DISCOUNT_RATE
    }
  }
`;
export const getRestRewardPct = /* GraphQL */ `
  query GetRestRewardPct($REST_ID: String!) {
    getREST_REWARD_PCT(REST_ID: $REST_ID) {
      REST_ID
      REWARD_PCT
    }
  }
`;
export const getUserRestRewardTrans = /* GraphQL */ `
  query GetUserRestRewardTrans($REST_ID: String!) {
    getUSER_REST_REWARD_TRANS(REST_ID: $REST_ID) {
      REST_ID
      ORDER_ID
      ORDER_AMT
      REWARD_TRANS_TYPE
      REWARD_AMT
    }
  }
`;
export const getUserRestRewardBal = /* GraphQL */ `
  query GetUserRestRewardBal($REST_ID: String!, $USER_OWNER: String!) {
    getUSER_REST_REWARD_BAL(REST_ID: $REST_ID, USER_OWNER: $USER_OWNER) {
      REST_ID
      REWARD_BAL
    }
  }
`;
export const listUserRestRewardBaLs = /* GraphQL */ `
  query ListUserRestRewardBaLs {
    listUSER_REST_REWARD_BALs {
      REST_ID
      REWARD_BAL
    }
  }
`;
export const getOrdersByRest = /* GraphQL */ `
  query GetOrdersByRest($REST_ID: String!) {
    getORDERS_BY_REST(REST_ID: $REST_ID) {
      REST_ID
      ORDER_ID
      CART_ID
      ORDER_STATUS_DATE_TIME
      ORDER_STATUS
      ORDER_PICKUP_DATE_TIME
      ORDER_TOTAL_FINAL_COST
      TAX_APPLIED
      ORDER_TOTAL_COST_BFR_TAX_BFR_DISC
      ORDER_COMMENTS
      ORDER_COMMENTS_BY
      USER_EMAIL
      REST_NAME
      CITY_NAME
      ORDER_TYPE
      PARTY_SIZE
      PAYMENT_STATUS
      USER_PHONE
      REWARDS_APPLIED
      DISCOUNT_APPLIED
      CUSTOMER_NAME
      USER_OWNER
      TIP_AMOUNT
      PROCESSING_FEE
      relatedCartMenuItms {
        REST_ID
        CART_ID
        CART_ITEM_NUM
        CART_ITEM_QUANTITY
        CART_ITEM_TOTAL_PRICE
        MENU_IT_ID
        MENU_IT_NAME
        MENU_ITEM_INSTRUCTION
        relatedCartMenuChItms {
          CH_DESC
          CH_ITEM_ID
          CH_ITEM_DESC
          CH_PRICE
          CH_ID_ORDER
        }
      }
    }
  }
`;
export const getAllordersByRest = /* GraphQL */ `
  query GetAllordersByRest($REST_ID: String!) {
    getALLORDERS_BY_REST(REST_ID: $REST_ID) {
      REST_ID
      ORDER_ID
      CART_ID
      ORDER_STATUS_DATE_TIME
      ORDER_STATUS
      ORDER_PICKUP_DATE_TIME
      ORDER_TOTAL_FINAL_COST
      TAX_APPLIED
      ORDER_TOTAL_COST_BFR_TAX_BFR_DISC
      ORDER_COMMENTS
      ORDER_COMMENTS_BY
      USER_EMAIL
      REST_NAME
      CITY_NAME
      ORDER_TYPE
      PARTY_SIZE
      PAYMENT_STATUS
      USER_PHONE
      REWARDS_APPLIED
      DISCOUNT_APPLIED
      CUSTOMER_NAME
      USER_OWNER
      TIP_AMOUNT
      PROCESSING_FEE
      relatedCartMenuItms {
        REST_ID
        CART_ID
        CART_ITEM_NUM
        CART_ITEM_QUANTITY
        CART_ITEM_TOTAL_PRICE
        MENU_IT_ID
        MENU_IT_NAME
        MENU_ITEM_INSTRUCTION
        relatedCartMenuChItms {
          CH_DESC
          CH_ITEM_ID
          CH_ITEM_DESC
          CH_PRICE
          CH_ID_ORDER
        }
      }
    }
  }
`;
export const getOrdersByRestAndOrder = /* GraphQL */ `
  query GetOrdersByRestAndOrder($REST_ID: String!, $ORDER_ID: Int!) {
    getORDERS_BY_REST_AND_ORDER(REST_ID: $REST_ID, ORDER_ID: $ORDER_ID) {
      REST_ID
      ORDER_ID
      CART_ID
      ORDER_STATUS_DATE_TIME
      ORDER_STATUS
      ORDER_PICKUP_DATE_TIME
      ORDER_TOTAL_FINAL_COST
      TAX_APPLIED
      ORDER_TOTAL_COST_BFR_TAX_BFR_DISC
      ORDER_COMMENTS
      ORDER_COMMENTS_BY
      USER_EMAIL
      REST_NAME
      CITY_NAME
      ORDER_TYPE
      PARTY_SIZE
      PAYMENT_STATUS
      USER_PHONE
      REWARDS_APPLIED
      DISCOUNT_APPLIED
      CUSTOMER_NAME
      USER_OWNER
      TIP_AMOUNT
      PROCESSING_FEE
      relatedCartMenuItms {
        REST_ID
        CART_ID
        CART_ITEM_NUM
        CART_ITEM_QUANTITY
        CART_ITEM_TOTAL_PRICE
        MENU_IT_ID
        MENU_IT_NAME
        MENU_ITEM_INSTRUCTION
        relatedCartMenuChItms {
          CH_DESC
          CH_ITEM_ID
          CH_ITEM_DESC
          CH_PRICE
          CH_ID_ORDER
        }
      }
    }
  }
`;
export const getOrdersByUser = /* GraphQL */ `
  query GetOrdersByUser {
    getORDERS_BY_USER {
      REST_ID
      ORDER_ID
      CART_ID
      ORDER_STATUS_DATE_TIME
      ORDER_STATUS
      ORDER_PICKUP_DATE_TIME
      ORDER_TOTAL_FINAL_COST
      TAX_APPLIED
      ORDER_TOTAL_COST_BFR_TAX_BFR_DISC
      ORDER_COMMENTS
      ORDER_COMMENTS_BY
      USER_EMAIL
      REST_NAME
      CITY_NAME
      ORDER_TYPE
      PARTY_SIZE
      PAYMENT_STATUS
      USER_PHONE
      REWARDS_APPLIED
      DISCOUNT_APPLIED
      CUSTOMER_NAME
      USER_OWNER
      TIP_AMOUNT
      PROCESSING_FEE
      relatedCartMenuItms {
        REST_ID
        CART_ID
        CART_ITEM_NUM
        CART_ITEM_QUANTITY
        CART_ITEM_TOTAL_PRICE
        MENU_IT_ID
        MENU_IT_NAME
        MENU_ITEM_INSTRUCTION
        relatedCartMenuChItms {
          CH_DESC
          CH_ITEM_ID
          CH_ITEM_DESC
          CH_PRICE
          CH_ID_ORDER
        }
      }
    }
  }
`;
export const getAllordersByUser = /* GraphQL */ `
  query GetAllordersByUser {
    getALLORDERS_BY_USER {
      REST_ID
      ORDER_ID
      CART_ID
      ORDER_STATUS_DATE_TIME
      ORDER_STATUS
      ORDER_PICKUP_DATE_TIME
      ORDER_TOTAL_FINAL_COST
      TAX_APPLIED
      ORDER_TOTAL_COST_BFR_TAX_BFR_DISC
      ORDER_COMMENTS
      ORDER_COMMENTS_BY
      USER_EMAIL
      REST_NAME
      CITY_NAME
      ORDER_TYPE
      PARTY_SIZE
      PAYMENT_STATUS
      USER_PHONE
      REWARDS_APPLIED
      DISCOUNT_APPLIED
      CUSTOMER_NAME
      USER_OWNER
      TIP_AMOUNT
      PROCESSING_FEE
      relatedCartMenuItms {
        REST_ID
        CART_ID
        CART_ITEM_NUM
        CART_ITEM_QUANTITY
        CART_ITEM_TOTAL_PRICE
        MENU_IT_ID
        MENU_IT_NAME
        MENU_ITEM_INSTRUCTION
        relatedCartMenuChItms {
          CH_DESC
          CH_ITEM_ID
          CH_ITEM_DESC
          CH_PRICE
          CH_ID_ORDER
        }
      }
    }
  }
`;
export const getGroupTest = /* GraphQL */ `
  query GetGroupTest {
    getGroupTest {
      str_group
    }
  }
`;
export const getUserProfileGroup = /* GraphQL */ `
  query GetUserProfileGroup {
    getUserProfileGroup {
      REST_ID
      REST_OWNER_GROUP_PRIORITY
    }
  }
`;
export const listUserRestPolls = /* GraphQL */ `
  query ListUserRestPolls {
    listUserRestPolls {
      POLL_ID
      POLL_NAME
      POLL_QUESTION
      POLL_START_DATE_TIME
      POLL_END_DATE_TIME
      POLL_STATUS
      REST_ID
      REST_NAME
      CITY_NAME
      USER_POLL_STATUS
      relatedPollChoices {
        REST_ID
        POLL_ID
        POLL_CHOICE_ID
        POLL_CHOICE_VAL
        POLL_CHOICE_VOTES
        USER_PREF_POLL_CHOICE_VAL
      }
    }
  }
`;
export const getPollsByRest = /* GraphQL */ `
  query GetPollsByRest($REST_ID: String!) {
    getPOLLS_BY_REST(REST_ID: $REST_ID) {
      REST_ID
      REST_NAME
      CITY_NAME
      POLL_ID
      POLL_NAME
      POLL_QUESTION
      POLL_START_DATE_TIME
      POLL_END_DATE_TIME
      POLL_STATUS
      CHOICE_1_RESP
      CHOICE_2_RESP
      CHOICE_3_RESP
      CHOICE_4_RESP
      TOTAL_RESP
      relatedPollChoices {
        REST_ID
        POLL_ID
        POLL_CHOICE_ID
        POLL_CHOICE_VAL
        POLL_CHOICE_VOTES
      }
    }
  }
`;
export const listUserSpecials = /* GraphQL */ `
  query ListUserSpecials {
    listUserSpecials {
      MENU_IT_ID
      MENU_IT_NAME
      REST_ID
      REST_NAME
      CITY_NAME
      CUISINE_TYPE
      MENU_IT_PIC_LOCATION
      DATE_FROM_DATE
      DATE_TO_DATE
      MENU_IT_DESC
      MENU_IT_PRICE
      MENU_ID
      MENU_NAME
      MENU_DESC
      MENU_TYPE
      MENU_GR_ID
      MENU_GR_NAME
      MENU_GR_ORDER
      MENU_ORDER
      MENU_IT_ORDER
      USER_LIKE_YN
      USER_BOOKMARK_YN
      EVERYDAY_YN
      SUNDAY_YN
      MONDAY_YN
      TUESDAY_YN
      WEDNESAY_YN
      THURSDAY_YN
      FRIDAY_YN
      SATURDAY_YN
      EVENT_ALLDAY_YN
      EVENT_TIME
      MENU_IT_TAXABLE_YN
      relatedMenuItemLikes {
        MENU_IT_ID
        LIKE_COUNT
      }
    }
  }
`;
export const getSpecialsByRest = /* GraphQL */ `
  query GetSpecialsByRest($REST_ID: String!) {
    getSPECIALS_BY_REST(REST_ID: $REST_ID) {
      MENU_IT_ID
      MENU_IT_NAME
      REST_ID
      REST_NAME
      CITY_NAME
      CUISINE_TYPE
      MENU_IT_PIC_LOCATION
      DATE_FROM_DATE
      DATE_TO_DATE
      MENU_IT_DESC
      MENU_IT_PRICE
      MENU_ID
      MENU_NAME
      MENU_DESC
      MENU_TYPE
      MENU_GR_ID
      MENU_GR_NAME
      MENU_GR_ORDER
      MENU_ORDER
      MENU_IT_ORDER
      USER_LIKE_YN
      USER_BOOKMARK_YN
      EVERYDAY_YN
      SUNDAY_YN
      MONDAY_YN
      TUESDAY_YN
      WEDNESAY_YN
      THURSDAY_YN
      FRIDAY_YN
      SATURDAY_YN
      EVENT_ALLDAY_YN
      EVENT_TIME
      MENU_IT_TAXABLE_YN
      relatedMenuItemLikes {
        MENU_IT_ID
        LIKE_COUNT
      }
    }
  }
`;
export const getRestOrderPrepTime = /* GraphQL */ `
  query GetRestOrderPrepTime($REST_ID: String!) {
    getREST_ORDER_PREP_TIME(REST_ID: $REST_ID) {
      REST_ID
      ORDER_PREP_MIN_TIME
      ORDER_PREP_MAX_TIME
      REST_OPERATION_STATUS
    }
  }
`;
export const getUserprofile = /* GraphQL */ `
  query GetUserprofile {
    getUSERPROFILE {
      name
      email
      email_verified
      phone_number
      phone_number_verified
    }
  }
`;
export const getRestBkmrkUsers = /* GraphQL */ `
  query GetRestBkmrkUsers($REST_ID: String!) {
    getREST_BKMRK_USERS(REST_ID: $REST_ID) {
      REST_ID
      USER_EMAIL
      PLAYER_ID
    }
  }
`;
export const getOnesignalUser = /* GraphQL */ `
  query GetOnesignalUser($USER_EMAIL: String!) {
    getONESIGNAL_USER(USER_EMAIL: $USER_EMAIL) {
      USER_EMAIL
      PLAYER_ID
    }
  }
`;
export const getRestDailySpecialsCount = /* GraphQL */ `
  query GetRestDailySpecialsCount($REST_ID: String!) {
    getREST_DAILY_SPECIALS_COUNT(REST_ID: $REST_ID) {
      DAILY_SPECIALS_COUNT
    }
  }
`;
export const getRestOwnerContact = /* GraphQL */ `
  query GetRestOwnerContact($REST_ID: String!) {
    getREST_OWNER_CONTACT(REST_ID: $REST_ID) {
      REST_ID
      USER_PHONE
      PLAYER_ID
    }
  }
`;
export const getUserPastOrderCount = /* GraphQL */ `
  query GetUserPastOrderCount($REST_ID: String!, $USER_OWNER: String!) {
    getUSER_PAST_ORDER_COUNT(REST_ID: $REST_ID, USER_OWNER: $USER_OWNER) {
      PAST_ORDER_COUNT
    }
  }
`;
export const getCurrenttime = /* GraphQL */ `
  query GetCurrenttime {
    getCURRENTTIME {
      CURRENTTIME
    }
  }
`;
export const getUserOpenOrders = /* GraphQL */ `
  query GetUserOpenOrders {
    getUserOpenOrders {
      OpenOrderCount
    }
  }
`;
export const getUserOpenPolls = /* GraphQL */ `
  query GetUserOpenPolls {
    getUserOpenPolls {
      OpenPollCount
    }
  }
`;
export const sendOrderEmail = /* GraphQL */ `
  query SendOrderEmail(
    $REST_NAME: String
    $CITY_NAME: String
    $ORDER_ID: Int
    $ORDER_STATUS: String
  ) {
    SendOrderEmail(
      REST_NAME: $REST_NAME
      CITY_NAME: $CITY_NAME
      ORDER_ID: $ORDER_ID
      ORDER_STATUS: $ORDER_STATUS
    ) {
      AlertStatus
    }
  }
`;
export const getReferenceInfo = /* GraphQL */ `
  query GetReferenceInfo {
    getREFERENCE_INFO {
      USER_OWNER
      REF_USER_EMAIL
      REST_ID
      REST_NAME
      REWARDED_YN
      USER_REWARD_AMT
      CREATED_AT
    }
  }
`;
export const getReferenceRewardDef = /* GraphQL */ `
  query GetReferenceRewardDef {
    getREFERENCE_REWARD_DEF {
      REST_ID
      REST_NAME
      MIN_REF_COUNT
      CLAIM_DAY_LIMIT
      USER_REF_REWARD_AMT
      GUEST_REF_REWARD_AMT
    }
  }
`;
export const getReferenceUserOrderInfo = /* GraphQL */ `
  query GetReferenceUserOrderInfo($REF_USER_EMAIL: String!) {
    getREFERENCE_USER_ORDER_INFO(REF_USER_EMAIL: $REF_USER_EMAIL) {
      REF_USER_OWNER
      REF_USER_EMAIL
      TOTAL_ORDERS
    }
  }
`;
export const sendReferenceEmail = /* GraphQL */ `
  query SendReferenceEmail(
    $USER_EMAIL: String
    $EMAIL_SUB: String
    $EMAIL_MSG: String
  ) {
    SendReferenceEmail(
      USER_EMAIL: $USER_EMAIL
      EMAIL_SUB: $EMAIL_SUB
      EMAIL_MSG: $EMAIL_MSG
    ) {
      AlertStatus
    }
  }
`;
export const getStripeCustomer = /* GraphQL */ `
  query GetStripeCustomer {
    getSTRIPE_CUSTOMER {
      STRIPE_CUSTOMER_ID
    }
  }
`;
export const getUserJoinDate = /* GraphQL */ `
  query GetUserJoinDate($REF_USER_EMAIL: String!) {
    getUSER_JOIN_DATE(REF_USER_EMAIL: $REF_USER_EMAIL) {
      USER_EMAIL
      JOIN_DATE
    }
  }
`;
export const getStripePayMethod = /* GraphQL */ `
  query GetStripePayMethod($STRIPE_CUSTOMER_ID: String) {
    getSTRIPE_PAY_METHOD(STRIPE_CUSTOMER_ID: $STRIPE_CUSTOMER_ID) {
      STRIPE_PAY_METHOD
    }
  }
`;
export const getRestTipDefine = /* GraphQL */ `
  query GetRestTipDefine($REST_ID: String!) {
    getREST_TIP_DEFINE(REST_ID: $REST_ID) {
      REST_ID
      TIP1
      TIP2
      TIP3
      TIP_UNIT
    }
  }
`;
export const getRestMasterGuest = /* GraphQL */ `
  query GetRestMasterGuest($REST_ID: String!) {
    getREST_MASTER_GUEST(REST_ID: $REST_ID) {
      REST_ID
      REST_NAME
      ADDRESS
      WEBSITE
      PHONE
      LATITUDE
      LONGITUDE
      RATING
      REVIEW_NUMBERS
      PRICE_LEVEL
      SUNDAY_HOURS
      MONDAY_HOURS
      TUESDAY_HOURS
      WEDNESDAY_HOURS
      THURSDAY_HOURS
      FRIDAY_HOURS
      SATURDAY_HOURS
      CUISINE_TYPE
      CITY_NAME
      TAKEOUT_YN
      DELIVERY_YN
      PRICE_LEVEL_DOLLARS
      REST_OWNER
      CURBSIDE_YN
      DISPLAY_REST_YN
      OPEN_YN
      REST_PIC_LOCATION
      REST_ABOUT
      YELP_ID
      PICKUP_ENABLED_YN
      DINEIN_ENABLED_YN
      RESERVE_ENABLED_YN
      RDELIVERY_ENABLED_YN
      ORDER_PREPTIMEMOD_YN
      MENU_ITEM_INSTR_ENABLED_YN
      RDELIVERY_COMMENT
      APPLY_PROCESS_FEE
      PROCESS_FEE_PCT
      PROCESS_FEE_UNIT
      PROCESS_FEE_AMT
      PROCESS_AMT_UNIT
      PAY_NOW_FEE_MSG
      PAY_REST_FEE_MSG
      relatedMenuGroupsGuest {
        REST_ID
        MENU_ID
        MENU_NAME
        MENU_DESC
        MENU_GR_ID
        MENU_GR_NAME
        MENU_GR_DESC
        MENU_GR_ORDER
        relatedMenuItems {
          REST_ID
          MENU_IT_ID
          MENU_IT_NAME
          MENU_IT_DESC
          MENU_IT_PRICE
          MENU_IT_PIC_LOCATION
          POPULAR_YN
          MENU_IT_STATUS
          MENU_IT_TAXABLE_YN
        }
      }
      relatedSplUpcomingByRestGuest {
        MENU_IT_ID
        MENU_IT_NAME
        REST_ID
        REST_NAME
        CITY_NAME
        CUISINE_TYPE
        MENU_IT_PIC_LOCATION
        DATE_FROM_DATE
        DATE_TO_DATE
        MENU_IT_DESC
        MENU_IT_PRICE
        MENU_ID
        MENU_NAME
        MENU_DESC
        MENU_TYPE
        MENU_GR_ID
        MENU_GR_NAME
        MENU_GR_ORDER
        MENU_ORDER
        MENU_IT_ORDER
        USER_LIKE_YN
        USER_BOOKMARK_YN
        EVERYDAY_YN
        SUNDAY_YN
        MONDAY_YN
        TUESDAY_YN
        WEDNESAY_YN
        THURSDAY_YN
        FRIDAY_YN
        SATURDAY_YN
        EVENT_ALLDAY_YN
        EVENT_TIME
        MENU_IT_TAXABLE_YN
        relatedMenuItemLikes {
          MENU_IT_ID
          LIKE_COUNT
        }
      }
      relatedSplTodayByRestGuest {
        MENU_IT_ID
        MENU_IT_NAME
        REST_ID
        REST_NAME
        CITY_NAME
        CUISINE_TYPE
        MENU_IT_PIC_LOCATION
        DATE_FROM_DATE
        DATE_TO_DATE
        MENU_IT_DESC
        MENU_IT_PRICE
        MENU_ID
        MENU_NAME
        MENU_DESC
        MENU_TYPE
        MENU_GR_ID
        MENU_GR_NAME
        MENU_GR_ORDER
        MENU_ORDER
        MENU_IT_ORDER
        USER_LIKE_YN
        USER_BOOKMARK_YN
        EVERYDAY_YN
        SUNDAY_YN
        MONDAY_YN
        TUESDAY_YN
        WEDNESAY_YN
        THURSDAY_YN
        FRIDAY_YN
        SATURDAY_YN
        EVENT_ALLDAY_YN
        EVENT_TIME
        MENU_IT_TAXABLE_YN
        relatedMenuItemLikes {
          MENU_IT_ID
          LIKE_COUNT
        }
      }
    }
  }
`;
export const getMyType = /* GraphQL */ `
  query GetMyType($id: ID!) {
    getMyType(id: $id) {
      id
      title
      content
      price
      rating
    }
  }
`;
export const listMyTypes = /* GraphQL */ `
  query ListMyTypes(
    $filter: ModelMyTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMyTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        content
        price
        rating
      }
      nextToken
    }
  }
`;
