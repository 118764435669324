import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Spinner,
    Text

} from "@chakra-ui/react";
import React, { useRef, useState, useEffect } from "react";
import { Switch } from "@chakra-ui/react"
import { Auth, Hub, API, graphqlOperation } from 'aws-amplify';
import { useToast, useDisclosure } from "@chakra-ui/react"
import Sidebar from '../../../components/sidebar'
import Header from '../../../components/Header'
import SpecialsData from '../../../components/SpecialsData'
import AddSpecialPopup from '../../../components/AddSpecialPopup'
import { getRestMaster as GetRestMaster } from '../../../../src/graphql/queries'

import { listUserPopularMenuItems as listUserPopularMenuItems } from '../../../../src/graphql/queries'

import { createSpecialMenuMaster as CreateSpecialMenuMaster, updateSpecialMenuMasterWeb } from '../../../graphql/mutations'
import { createSpecialMenuMasterWeb as CreateSpecialMenuMasterWeb } from '../../../graphql/mutations'
import { createRestSpecialMenuChoices as CreateRestSpecialMenuChoices } from '../../../graphql/mutations'
import { updateSpecialMenuMasterWeb as UpdateSpecialMenuMasterWeb } from '../../../graphql/mutations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Helmet } from 'react-helmet';
import { getSpecialsByRest as getSpecialsByRest } from "../../../graphql/queries";

function Specials() {

    const [togglebit, setTogglebit] = useState(false);
    const [switchbit, setSwitchbit] = useState(true);
    const [specialData, setspecialData] = useState([]);
    const [specialFilteredData, setSpecialFilteredData] = useState([]);
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);
    let today = new Date().toISOString().slice(0, 10);
    const [refreshSpecial, setRefreshSpecial] = useState([]);

    //Show toast for validation message method
    function showValidationMsg(msg, st) {
        toast({
            title: "",
            description: msg,
            status: st,
            duration: 9000,
            isClosable: true,
            position: "top"
        })

    }
    async function Test() {
        const result = await API.graphql(graphqlOperation(listUserPopularMenuItems));
        console.log(result);
    }

    async function SpecialData() {

        try {

            let data = [];
            let filtereddata = []
            setIsLoading(true)
            //debugger;
            const result = await API.graphql(graphqlOperation(GetRestMaster, { REST_ID: localStorage.getItem('Restaurantid') }))
            // const resultone = await API.graphql(graphqlOperation(getSpecialsByRest, { REST_ID: localStorage.getItem('Restaurantid') }))

            console.log('result"""""""""""""""', result);
            // const Upcomingdata = result.data.getREST_MASTER.relatedSplUpcomingByRest?.filter((obj) =>
            //     obj.MENU_GR_ID == 'MS1-MG01');

            // Upcomingdata?.map((obj) => {
            //     obj.active = 'true'
            // });
            // data.push(...Upcomingdata);
            // first.push(...second);

            const Todaydata = result.data.getREST_MASTER.relatedSplTodayByRest?.filter((obj) =>
                obj.MENU_GR_ID == 'MS1-MG01' && today <= obj.DATE_TO_DATE);

            Todaydata?.map((obj) => {
                obj.active = 'true'
            });
            //debugger;
            const specialData = result.data.getREST_MASTER.relatedSplUpcomingByRest?.filter((obj) =>
                (obj.MENU_ID.includes('MS1')) && today <= obj.DATE_TO_DATE);

            specialData?.map((obj) => {
                obj.active = 'true'
            });
            data.push(...Todaydata, ...specialData);
            const key = 'MENU_IT_ID';
            const unique = [...new Map(data.map(item =>
                [item[key], item])).values()];
            setspecialData(unique);
            setSpecialFilteredData(unique);
            setIsLoading(false)

        }
        catch (err) {

            console.log(err)
        }
    }

    // async function getSpecialsRest() {
    //     setIsLoading(true);

    //     try {
    //         debugger;
    //         const result = await API.graphql(graphqlOperation(getSpecialsByRest, { REST_ID: localStorage.getItem('Restaurantid') }));
    //         const specialRes = result.data.getSPECIALS_BY_REST?.filter((obj) =>

    //             (obj.MENU_ID.includes('MS1')) && (today <= obj.DATE_TO_DATE));

    //         setspecialData([]);
    //         setRefreshSpecial(specialRes);
    //         setIsLoading(false);


    //     }
    //     catch (err) {

    //     }
    // }

    // async function AddSpecialSave(data) {

    //     try {

    //         const a = 0;
    //         const res = await API.graphql(graphqlOperation(CreateSpecialMenuMaster, { createSPECIAL_MENU_MASTERInput: data }));
    //         // await API.graphql(graphqlOperation(CreateSpecialMenuMaster, { createSPECIAL_MENU_MASTERInput: data })).then(
    //         //     () => { onClose() }).then(() => { SpecialData(); showValidationMsg("Special Successfully Added", 'success') })
    //         // console.log('Ok')
    //         onClose();
    //         SpecialData();
    //         showValidationMsg("Special Successfully Added", 'success')
    //     }
    //     catch (err) {
    //         console.log('error: ', err)
    //         showValidationMsg("Error occured", 'error')
    //     }
    // }


    async function callMenuCustomizationMutation(customizationinput) {
        try {
            const result = await API.graphql(graphqlOperation(CreateRestSpecialMenuChoices, { CreateREST_SPECIAL_MENU_CHOICESInput: customizationinput }));

            const a = 10;
        }
        catch (error) {
            console.log(error)
        }


    }




    async function Save(data, custmizationData, isUpdate) {

        try {


            const a = 0;
            let res = null;
            if (isUpdate === true) {

                res = await API.graphql(graphqlOperation(updateSpecialMenuMasterWeb, { updateSPECIAL_MENU_MASTER_WEBInput: data }));
                console.log(res);

            }
            else {

                res = await API.graphql(graphqlOperation(CreateSpecialMenuMasterWeb, { CreateSPECIAL_MENU_MASTER_WEBInput: data }));
                console.log(res);
                ////debugger;



            }
            let chids = '';
            custmizationData.forEach((item) => {
                if (chids === '') chids = `'${item.CH_ID}'`;
                else chids = `${chids},'${item.CH_ID}'`;
            })

            let customizationinput = {
                REST_ID: res.data.createSPECIAL_MENU_MASTER_WEB.REST_ID,
                MENU_IT_ID: res.data.createSPECIAL_MENU_MASTER_WEB.MENU_IT_ID,
                CH_IDS: chids
            }

            //             let customizationinput ={          
            // REST_ID:"ChIJ-31cQeb_wokRBkrXYIedkCE",
            // MENU_IT_ID:"0ffa4f10-a2ee-4e28-aa77-1025924b7572",
            // CH_IDS:"'NJMTC-C1'"
            //             }

            callMenuCustomizationMutation(customizationinput)


            onClose();
            SpecialData();
            showValidationMsg("Special Successfully Added", 'success')
        }
        catch (err) {
            //////debugger;
            console.log('error: ', err)
            showValidationMsg("Error occured", 'error')

        }
    }
    useEffect(() => {

        SpecialData();
        // getSpecialsRest()
        // ////debugger;
        // Test();

    }, []);

    function toggle() {
        setTogglebit(current => !current);
    }
    function LoadAllData() {
        setSwitchbit(current => !current);

    }
    function deh() {
        //debugger;
        console.log("HI")
    }

    const { isOpen, onOpen, onClose } = useDisclosure()
    return (

        <Box bg='#f9f9f9'>
            <Helmet>
                <title>Squash | Specials</title>
            </Helmet>
            <Header isAdmin={true} toggle={toggle} />
            <Box pt='70px' position='relative' h='100vh' overflow='hidden' d='flex'>
                <Box zIndex='9' bg='#fff' position='absolute' top='0' pt='100px' left='0' h='100%' w={{ base: (togglebit ? "200px" : '0px'), md: (togglebit ? "200px" : '0px'), lg: (togglebit ? "0px" : '200px') }} display={{ base: (togglebit ? "block" : 'none'), md: (togglebit ? "block" : 'none'), lg: (togglebit ? "none" : 'block') }}>
                    <Sidebar current='Specials' togglebit={togglebit} />
                </Box>
                <Box ml={(togglebit ? "0px" : { base: "0", sm: "0", md: "0", lg: '200px' })} w='100%' p={{ base: '8px', md: '20px' }} overflow='auto'>
                    <Box className="card no-padding card-flex">
                        <Box className="card-header" position="relative">
                            <Box className="title-heading"><h2>Specials</h2></Box>
                            <Box className="icons-wrapper specials-head">
                                {/* <Box mr='15px'>
                                        <FormControl display="flex" justifyContent='flex-end' >
                                            <FormLabel htmlFor="status-switch" mb="0">
                                                Active
                                            </FormLabel>
                                            <Switch  className="box-remove" id="status-switch" isChecked={switchbit} onChange={() => LoadAllData()} />
                                        </FormControl>
                                    </Box> */}
                                <Box>
                                    {/* <Button size='sm' bg='#0554F2' className='primary-button' onClick={onOpen}>Add Special</Button> */}
                                    <Button onClick={onOpen} className='primary-button primary-btn addspecialbtn'><FontAwesomeIcon m='3px' icon={faPlus} /> <Text fontSize='14px'>Add Special</Text> </Button>
                                </Box>
                            </Box>
                        </Box>
                        <Box className="card-content table-padding specialbox">
                            <Box className="scrollable">
                                {isLoading ? (<Box className="rspinbox" > <Spinner
                                    thickness="4px"
                                    speed="0.65s"
                                    emptyColor="gray.200"
                                    color="blue.500"
                                    size="xl"
                                /></Box >) :
                                    specialFilteredData?.length > 0 || !switchbit ?
                                        <SpecialsData SpecialData={SpecialData} special={true} data={switchbit ? specialFilteredData : specialData} />
                                        : <Box className="rspinbox" > <Text>No Data Available</Text></Box >}
                            </Box >
                        </Box >
                    </Box >
                </Box>
            </Box>
            {true ?
                <AddSpecialPopup isCreate={true} Save={Save} Count={specialData?.length || 0} IsSpecial={true} open={isOpen} close={onClose} />
                : ''}
        </Box>

    )
}
export default Specials;
