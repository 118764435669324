import {
  Box,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Table,
  Tbody,
  Tr,
  Td,
  Thead,
  Th,
  Input,
  Link,
  Spinner,
  Image,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  ListItem,
  List,
} from '@chakra-ui/react';
import React, { useRef, useState, useEffect } from 'react';
import { Auth, Hub, API, graphqlOperation } from 'aws-amplify';
import { useToast, useDisclosure } from '@chakra-ui/react';
import Sidebar from '../../../components/sidebar';
import Header from '../../../components/Header';
import { Helmet } from 'react-helmet';
import FoodIcon from '../../../assets/images/dish.png';
import { getRestMaster as GetRestMaster } from '../../../../src/graphql/queries';
import MenuUpdatePopup from '../../../components/MenuUpdatePopup';
import MenuPromoPopup from '../../../components/MenuPromoPopup';
import { getMasterMenuChoices as GetMASTER_MENU_CHOICES } from '../../../graphql/queries';
import { listUserPopularMenuItems as ListUserPopularMenuItems } from '../../../graphql/queries';
import { EditIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faList,
  faEdit,
  faHamburger,
  faUtensils,
  faTachometerAlt,
  faChartBar,
  faCalendar,
  faConciergeBell,
  faSwatchbook,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { getMenuChoices as GetMenuChoices } from '../../../graphql/queries';

import {
  updateMenuItemMaster as UpdateMenuItemMaster,
  updateSpecialMenuMasterWeb,
  deleteRestSpecialMenuChoices,
  createRestSpecialMenuChoices,
  createSpecialMenuMasterWeb,
} from '../../../graphql/mutations';
import AddMenuPopup from '../../../components/AddMenuPopup';

function Menu() {
  const [togglebit, setTogglebit] = useState(false);
  const [item, setItem] = useState([]);
  const [itemMenu, setItemMenu] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const toast = useToast();
  const [customizeData, setCustomizeData] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState({})
  // const [customizeData1, setCustomizeData1] = useState(null)
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    onClose: onPromoClose,
    onOpen: onPromoOpen,
    isOpen: isPromoOpen,
  } = useDisclosure();
  const {
    onClose: onPopupClose,
    onOpen: onPopupOpen,
    isOpen: isPopupOpen,
  } = useDisclosure();
  const [editObject, setEditObject] = useState(null);
  const [promoObject, setPromoObject] = useState(null);

  const [tabIndex, SetTabIndex] = useState(0);
  const [subMenu, SetSubMenu] = useState(null);

  const [currentmenu, setcurrentmenu] = useState('');

  const [menuChoice, SetMenuChoice] = useState([]);
  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: null,
  });
  const [Price, setPrice] = useState(0);

  useEffect(() => {
    ManuData();
    CustomizeData();
  }, []);

  useEffect(() => {
    MenuDetails();
  }, [editObject]);

  async function MenuDetails() {
    try {
      if (editObject) {
        ////debugger;

        const result = await API.graphql(
          graphqlOperation(GetMenuChoices, {
            MENU_IT_ID: editObject?.MENU_IT_ID,
          })
        ); //NJGAR-R1
        // console.log(result);
        ////debugger;
        const s = result?.data.getMENU_CHOICES[0].data;
        SetMenuChoice(result?.data.getMENU_CHOICES);
      }
    } catch (err) {
      console.log(err);
    }
  }

  //Show toast for validation message method
  function showValidationMsg(msg, st) {
    toast({
      title: '',
      description: msg,
      status: st,
      duration: 6000,
      isClosable: true,
      position: 'top',
    });
  }

  async function ManuData() {
    try {
      //debugger;
      setIsLoading(true);
      const result = await API.graphql(
        graphqlOperation(GetRestMaster, {
          REST_ID: localStorage.getItem('Restaurantid'),
        })
      );
      console.log(result);
      setItem(result?.data.getREST_MASTER);
      //debugger;

      const distinctItemMENU_NAME =
        result?.data.getREST_MASTER.relatedMenuGroups
          .map(item => item.MENU_NAME)
          .filter((value, index, self) => self.indexOf(value) === index);

      const aa =
        result?.data.getREST_MASTER?.relatedMenuGroups[0]?.MENU_GR_NAME;
      // console.log('dfsfsfss', result?.data.getREST_MASTER?.relatedMenuGroups[0]);
      setSelectedGroup(result?.data.getREST_MASTER?.relatedMenuGroups[0])
      ////debugger;
      SetSubMenu(aa);
      setcurrentmenu(aa);
      //	SetSubMenus(result?.data.getREST_MASTER.relatedMenuGroups)

      setItemMenu(distinctItemMENU_NAME);

      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  // async function CustomizeData1() {

  // 	try {

  // 		setIsLoading(true)
  // 		const result = await API.graphql(graphqlOperation(ListUserPopularMenuItems))
  // 		console.log(result);
  // 		setCustomizeData1(result?.data.listUSER_POPULAR_MENU_ITEMS);
  // 		setIsLoading(false)
  // 	}
  // 	catch (err) {

  // 		console.log(err)
  // 	}
  // }
  // async function CustomizeData() {
  //   try {
  //     setIsLoading(true);
  //     const result = await API.graphql(graphqlOperation(ListMasterMenuChoices));
  //     console.log(result);
  //     setCustomizeData(result?.data.listMASTER_MENU_CHOICES);
  //     setIsLoading(false);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  const CustomizeData = async () => {
    const result = await API.graphql(
      graphqlOperation(GetMASTER_MENU_CHOICES, {
        REST_ID: localStorage.getItem('Restaurantid'),
      })
    );
    // //debugger;
    // let res = result?.data.getMASTER_MENU_CHOICES?.map(item => {
    //   const { CH_ID, CH_DESC } = item;
    //   return { id: CH_ID, name: CH_DESC };
    // });
    // setCustomizeData(res);
    setCustomizeData(result?.data.getMASTER_MENU_CHOICES);
  };

  async function UpdateMenu(data, custmizationData, oldChoices) {
    try {
      setIsLoadingUpdate(true);
      const a = 0;
      //////debugger;
      const b = await API.graphql(
        graphqlOperation(UpdateMenuItemMaster, {
          updateMENU_ITEM_MASTERInput: data,
        })
      );
      console.log(b);
      let chids = '';
      custmizationData.forEach(item => {
        if (chids === '') chids = `'${item.id}'`;
        else chids = `${chids},'${item.id}'`;
      });

      let customizationinput = {
        REST_ID: data.REST_ID,
        MENU_IT_ID: data.MENU_IT_ID,
        CH_IDS: chids,
      };

      let oldCh = '';
      oldChoices.forEach(item => {
        if (oldCh === '') oldCh = `'${item.id}'`;
        else oldCh = `${oldCh},'${item.id}'`;
      });

      let customizationDeleteInput = {
        REST_ID: data.REST_ID,
        MENU_IT_ID: data.MENU_IT_ID,
        CH_IDS: oldCh,
      };

      const result = await API.graphql(
        graphqlOperation(deleteRestSpecialMenuChoices, {
          CreateREST_SPECIAL_MENU_CHOICESInput: customizationDeleteInput,
        })
      );

      setTimeout(() => {
        callMenuCustomizationMutation(customizationinput);
        ManuData();
        //////debugger;
      onClose();
      SetTabIndex(0);
      // setEditObject(data)
      showValidationMsg('Menu Updated Successfully', 'success');
      setIsLoadingUpdate(false);
      }, 3000);
      
    } catch (err) {
      ////debugger;
      console.log('error: ', err);
      showValidationMsg('Error occured', 'error');
    }
  }

  async function callMenuCustomizationMutation(customizationinput) {
    //debugger;
    try {
      const result = await API.graphql(
        graphqlOperation(createRestSpecialMenuChoices, {
          CreateREST_SPECIAL_MENU_CHOICESInput: customizationinput,
        })
      );

      const a = 10;
    } catch (error) {
      console.log(error);
    }
  }

  async function UpdatePromo(data) {
    try {
      setIsLoadingUpdate(true);
      const a = 0;
      ////debugger;
      const b = await API.graphql(
        graphqlOperation(updateSpecialMenuMasterWeb, {
          UpdateSPECIAL_MENU_MASTER_WEBInput: data,
        })
      );
      // console.log(b);
      ////debugger;
      onPromoClose();
      SetTabIndex(0);
      // setEditObject(data)
      ManuData();
      showValidationMsg('Menu Updated Successfully', 'success');
      setIsLoadingUpdate(false);
    } catch (err) {
      ////debugger;
      console.log('error: ', err);
      showValidationMsg('Error occured', 'error');
    }
  }

  function toggle() {
    setTogglebit(current => !current);
  }

  const onSave = obj => {
    // console.log(obj);
    // console.log(Price);
    //debugger;
    const data = {
      MENU_IT_ID: obj?.MENU_IT_ID,
      MENU_IT_NAME: obj?.MENU_IT_NAME,
      MENU_IT_DESC: obj?.MENU_IT_DESC,
      MENU_IT_PRICE: parseFloat(obj?.MENU_IT_PRICE),
    };
    UpdateMenu(data);
    setInEditMode({
      status: false,
      rowKey: null,
    });
  };
  const onCancel = () => {
    setInEditMode({
      status: false,
      rowKey: null,
    });
    // reset the unit price state value
    //setPrice(null);
  };

  // async function callMenuCustomizationMutation(customizationinput) {
  //   try {
  //     const result = await API.graphql(graphqlOperation(createRestSpecialMenuChoices, { CreateREST_SPECIAL_MENU_CHOICESInput: customizationinput }));

  //     const a = 10;
  //   }
  //   catch (error) {
  //     console.log(error)
  //   }


  // }

  async function callMenuCustomizationMutationFun(customizationinput) {
    try {
      const result = await API.graphql(graphqlOperation(createRestSpecialMenuChoices, { CreateREST_SPECIAL_MENU_CHOICESInput: customizationinput }));
      // console.log(result, 'tettegeg');

      const a = 10;
    }
    catch (error) {
      console.log(error)
    }

  }

  async function Save(data, custmizationData, isUpdate) {
    console.log(custmizationData);

    try {
      setIsLoading(true);

      const a = 0;
      let res = null;

      res = await API.graphql(graphqlOperation(createSpecialMenuMasterWeb, { CreateSPECIAL_MENU_MASTER_WEBInput: data }));
      // console.log(res);
      ////debugger;

      let chids = '';
      custmizationData.forEach((item) => {
        if (chids === '') chids = `'${item.id}'`;
        else chids = `${chids},'${item.id}'`;
      })
      // debugger;

      let customizationinput = {
        REST_ID: res.data.createSPECIAL_MENU_MASTER_WEB.REST_ID,
        MENU_IT_ID: res.data.createSPECIAL_MENU_MASTER_WEB.MENU_IT_ID,
        CH_IDS: chids
      }

      if (res.data?.createSPECIAL_MENU_MASTER_WEB != null) {
        callMenuCustomizationMutationFun(customizationinput)

        setTimeout(() => {
          ManuData();

          onPopupClose();
          SetTabIndex(0);
          showValidationMsg("'Menu Added Successfully Added", 'success')
          setIsLoading(false);
        }, 3000);
      } else {
        setIsLoading(false);
        showValidationMsg("Error occured", 'error')
      }
    }
    catch (err) {
      showValidationMsg("Error occured", 'error')
    }
  }

  // useEffect(() => {
  //   console.log(subMenu, currentmenu, item, selectedGroup);
  // }, [subMenu, currentmenu, item, selectedGroup])

  return (
    <Box bg="#F6FAFF">
      <Helmet>
        <title>Squash | Menu</title>
      </Helmet>
      <Header isAdmin={true} toggle={toggle} />
      <Box pt="85px" position="relative" h="100vh" overflow="hidden" d="flex">
        <Box
          zIndex="9"
          bg="#fff"
          position="absolute"
          top="0"
          pt="100px"
          left="0"
          h="100%"
          w={{
            base: togglebit ? '200px' : '0px',
            md: togglebit ? '200px' : '0px',
            lg: togglebit ? '0px' : '200px',
          }}
          display={{
            base: togglebit ? 'block' : 'none',
            md: togglebit ? 'block' : 'none',
            lg: togglebit ? 'none' : 'block',
          }}
        >
          <Sidebar current="Menu" togglebit={togglebit} />
        </Box>
        <Box
          ml={togglebit ? '0px' : { base: '0', sm: '0', md: '0', lg: '200px' }}
          w="100%"
          p={{ base: '8px', md: '20px' }}
          overflow="auto"
        >
          <Box className="card no-padding card-flex">
            <Box className="card-header">
              <Box className="title-heading">
                <h2>Menu</h2>
              </Box>
            </Box>
            <Box pt={0} className="card-content table-padding">
              {isLoading ? (
                <Box className="rspinbox">
                  {' '}
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                  />
                </Box>
              ) : (
                <Box className="scrollable tabnonscrolable m-table">
                  <Tabs
                    className="custom-tab-wrapper"
                    onChange={index => {
                      SetTabIndex(index);
                      // console.log(
                      //   item.relatedMenuGroups.filter(
                      //     obj => obj.MENU_NAME == itemMenu[index]
                      //   )[0]
                      // );

                      setSelectedGroup(item.relatedMenuGroups.filter(
                        obj => obj.MENU_NAME == itemMenu[index]
                      )[0])

                      SetSubMenu(
                        item.relatedMenuGroups.filter(
                          obj => obj.MENU_NAME == itemMenu[index]
                        )[0]?.MENU_GR_NAME
                      );
                      setcurrentmenu(
                        item.relatedMenuGroups.filter(
                          obj => obj.MENU_NAME == itemMenu[index]
                        )[0]?.MENU_GR_NAME
                      );
                    }}
                  >
                    <TabList className="custom-tabs">
                      {itemMenu?.map(
                        (item, index) =>
                          item.toUpperCase() !== 'SPECIAL MENU' &&
                          item.toUpperCase() !== 'SPECIAL EVENT' && (
                            <Tab
                              p={{ base: '9px', md: '20px' }}
                              className="custom-tab-button"
                              key={index}
                            >
                              {/* <Box className="ctabicon"><FontAwesomeIcon icon={faConciergeBell} /></Box> */}
                              {item.toUpperCase()}{' '}
                            </Tab>
                          )
                      )}
                    </TabList>
                    <Box className="innermenumobilemain">
                      <Box className="innermenumobile">
                        <List className="inner-menu">
                          {item?.relatedMenuGroups
                            ? item.relatedMenuGroups
                              ?.filter(
                                obj => obj.MENU_NAME == itemMenu[tabIndex]
                                //		obj.MENU_GR_ID == "MG1"
                              )
                              .map((data, i) => (
                                <ListItem
                                  className={
                                    currentmenu == data.MENU_GR_NAME
                                      ? 'active inner-menu-item '
                                      : 'inner-menu-item '
                                  }
                                >
                                  <Link
                                    style={{ textDecoration: 'none' }}
                                    onClick={() => {
                                      setSelectedGroup(data)
                                      SetSubMenu(data.MENU_GR_NAME);
                                      setcurrentmenu(data.MENU_GR_NAME);
                                    }}
                                  >
                                    <span>
                                      <FontAwesomeIcon icon={faSwatchbook} />
                                    </span>
                                    <Box>
                                      <Text fontSize="14px" pl="10px">
                                        {' '}
                                        {data.MENU_GR_NAME}{' '}
                                      </Text>
                                      <Text fontSize="xs" pl="10px">
                                        No of item :{' '}
                                        {data.relatedMenuItems.length}{' '}
                                      </Text>
                                    </Box>
                                  </Link>
                                </ListItem>
                              ))
                            : null}
                        </List>
                      </Box>
                      <Box className="innermenumobile">
                        <Box display="flex" alignItems="center" justifyContent="end" mb={3}>
                          <Button onClick={onPopupOpen} className='primary-button primary-btn addspecialbtn' style={{ minWidth: '150px' }}><FontAwesomeIcon m='3px' icon={faPlus} /> <Text fontSize='14px'>Create Menu Item</Text> </Button>
                        </Box>
                        <TabPanels
                          className="inner-content"
                        /*	w={{base:'100%',md:` calc(100% - 225px) !important`}}
                  h={{base:`calc(70vh - 270px)` ,md:`calc(100vh - 320px)`}} */
                        >
                          {itemMenu?.map((ob, index) => (
                            <TabPanel p={4} key={index}>
                              <List>
                                {item?.relatedMenuGroups
                                  ? //		tmpArry.findIndex((obj) => obj.CH_ID === rowID)

                                  item.relatedMenuGroups
                                    ?.filter(
                                      obj =>
                                        obj.MENU_NAME == itemMenu[tabIndex] &&
                                        obj.MENU_GR_NAME == subMenu
                                    )
                                    .map((data, i) =>
                                      //console.log(data)
                                      data?.relatedMenuItems
                                        ? data.relatedMenuItems?.map(
                                          (val, index) => (
                                            <Box
                                              className="inner-content-div"
                                              d="flex"
                                            >
                                              <Box
                                                cursor="pointer"
                                                onClick={() => {
                                                  onOpen();
                                                  setEditObject(val);
                                                }}
                                                className="fmenubox1"
                                              >
                                                {' '}
                                                <Box className="fmenuicon">
                                                  {' '}
                                                  <FontAwesomeIcon
                                                    icon={faConciergeBell}
                                                  />
                                                </Box>
                                              </Box>
                                              <Box
                                                cursor="pointer"
                                                onClick={() => {
                                                  onOpen();
                                                  setEditObject(val);
                                                }}
                                                className="fmenubox2 admin-menu-name"
                                                w={{
                                                  base: '40%',
                                                  sm: '40%',
                                                  md: '40%',
                                                  lg: '30%',
                                                  xl: '30%',
                                                }}
                                              >
                                                <Text>
                                                  {' '}
                                                  {val.MENU_IT_NAME}
                                                </Text>
                                              </Box>
                                              <Box
                                                className="fmenubox3 admin-menu-description"
                                                w={{
                                                  xl: '45%',
                                                }}
                                                d={{
                                                  base: 'none',
                                                  sm: 'none',
                                                  md: 'none',
                                                  lg: 'none',
                                                  xl: 'block',
                                                }}
                                              >
                                                <Text>
                                                  {' '}
                                                  {val.MENU_IT_DESC}
                                                </Text>
                                              </Box>
                                              {inEditMode.status &&
                                                inEditMode.rowKey ===
                                                val.MENU_IT_ID ? (
                                                <Input
                                                  type="number"
                                                  //	defaultValue={val.MENU_IT_PRICE}
                                                  value={Price}
                                                  onChange={event =>
                                                    setPrice(
                                                      event.target.value
                                                    )
                                                  }
                                                />
                                              ) : (
                                                <Box
                                                  w={{
                                                    base: '100%',
                                                    md: '30%',
                                                    lg: '20%',
                                                    xl: '15%',
                                                  }}
                                                  onClick={() => {
                                                    setInEditMode({
                                                      status: true,
                                                      rowKey:
                                                        val.MENU_IT_ID,
                                                    });
                                                    setPrice(
                                                      val.MENU_IT_PRICE
                                                    );
                                                  }}
                                                >
                                                  <Text>
                                                    $
                                                    {val.MENU_IT_PRICE.toFixed(
                                                      2
                                                    )}
                                                  </Text>
                                                </Box>
                                                //updatedObject?.CH_ITEM_ID === item.CH_ITEM_ID? updatedObject.CH_ITEM_DESC :
                                              )}
                                              <Box
                                                  w={{
                                                    base: '100%',
                                                    md: '30%',
                                                    lg: '20%',
                                                    xl: '15%',
                                                  }}
                                                  paddingLeft={4}
                                                  paddingRight={4}
                                                >
                                                  <Text>
                                                    {val.MENU_IT_STATUS}
                                                  </Text>
                                                </Box>

                                              {inEditMode.status &&
                                                inEditMode.rowKey ===
                                                val.MENU_IT_ID ? (
                                                <Box
                                                  display="flex"
                                                  pt="5px"
                                                  pl="10px"
                                                  pb="5px"
                                                  pr="10px"
                                                >
                                                  <Box>
                                                    {' '}
                                                    <Link>
                                                      {' '}
                                                      <CheckIcon
                                                        className="ticongreen"
                                                        color="green.500"
                                                        onClick={() => {
                                                          onSave(val);
                                                          //debugger;
                                                        }}
                                                      />
                                                    </Link>
                                                  </Box>

                                                  <Box pl="10px">
                                                    {' '}
                                                    <Link>
                                                      {' '}
                                                      <CloseIcon
                                                        color="red.500"
                                                        className="ticonred"
                                                        onClick={() =>
                                                          onCancel()
                                                        }
                                                      />
                                                    </Link>
                                                  </Box>
                                                </Box>
                                              ) : null}

                                              {/* <Box pr="20px">
                                                    <Button
                                                      color="red.500"
                                                      onClick={() => {
                                                        onPromoOpen();
                                                        setPromoObject(val);
                                                      }}
                                                    >
                                                      Special Promo
                                                    </Button>
                                                  </Box> */}

                                              <Box
                                                className="fmenubox1"
                                                ml="auto"
                                              >
                                                <Link>
                                                  <EditIcon
                                                    color="red.500"
                                                    onClick={() => {
                                                      onOpen();

                                                      setEditObject(val);
                                                    }}
                                                  />
                                                </Link>
                                              </Box>
                                            </Box>

                                            // <ListItem>

                                            // 	{val.MENU_IT_NAME}
                                            // </ListItem>
                                          )
                                        )
                                        : null
                                    )
                                  : null}
                              </List>
                            </TabPanel>
                          ))}
                        </TabPanels>
                      </Box>
                    </Box>
                  </Tabs>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {editObject ? (
        <MenuUpdatePopup
          onPromoOpen={onPromoOpen}
          setPromoObject={setPromoObject}
          menuChoice={menuChoice}
          customizeData={customizeData}
          UpdateMenu={UpdateMenu}
          object={editObject}
          open={isOpen}
          close={onClose}
        />
      ) : (
        ''
      )}
      {promoObject ? (
        <MenuPromoPopup
          UpdatePromo={UpdatePromo}
          object={promoObject}
          open={isPromoOpen}
          close={onPromoClose}
        />
      ) : (
        ''
      )}


      <AddMenuPopup isCreate={true} open={isPopupOpen} Save={Save} selectGroup={selectedGroup} close={onPopupClose} />


    </Box>
  );
}
export default Menu;
