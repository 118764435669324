import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Image,
    Link,
  } from '@chakra-ui/react';
  import React, { useRef, useState, useEffect } from 'react';
  import { useToast, useDisclosure } from '@chakra-ui/react';
  import { EditIcon } from '@chakra-ui/icons';
  
  import SpecialfoddIcon from '../assets/images/special-dish.png';
  import { updateSpecialMenuMasterWeb as UpdateSpecialMenuMasterWeb } from '../graphql/mutations';
  import { createRestSpecialMenuChoices as CreateRestSpecialMenuChoices } from '../graphql/mutations';
  import { Auth, Hub, API, graphqlOperation } from 'aws-amplify';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { faConciergeBell } from '@fortawesome/free-solid-svg-icons';
import AddEventPopup from './AddEventPopup';
  function EventsData(props) {
    const [editObject, setEditObject] = useState({});
    // const [updateData,setUpdateData] = useState({});
  
    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
      isOpen: isOpenEdit,
      onOpen: onOpenEdit,
      onClose: onCloseEdit,
    } = useDisclosure();
    const toast = useToast();
  
    //Show toast for validation message method
    function showValidationMsg(msg, st) {
      toast({
        title: '',
        description: msg,
        status: st,
        duration: 9000,
        isClosable: true,
        position: 'top',
      });
    }
  
    async function callMenuCustomizationMutation(customizationinput) {
      //debugger;
      try {
        const result = await API.graphql(
          graphqlOperation(CreateRestSpecialMenuChoices, {
            CreateREST_SPECIAL_MENU_CHOICESInput: customizationinput,
          })
        );
  
        const a = 10;
      } catch (error) {
        console.log(error);
      }
    }
    // function EditFunction(item){
    //     setUpdateData(item);
  
    //     setEditObject(item);
    // }
  
    async function Save(data, isUpdate) {
      try {
         //debugger;
        const inputData = {
          MENU_IT_ID: data?.MENU_IT_ID,
          MENU_IT_NAME: data?.MENU_IT_NAME,
          MENU_IT_DESC: data?.MENU_IT_DESC,
          MENU_IT_PRICE: data?.MENU_IT_PRICE.toString(),
          DATE_FROM_DATE: data?.DATE_FROM_DATE,
          DATE_TO_DATE: data?.DATE_TO_DATE,
          REST_ID: data?.REST_ID,
  
          MENU_IT_PIC_LOCATION: data.MENU_IT_PIC_LOCATION,
          // MENU_IT_ORDER:props.Count? props.Count++ :1,
          // MENU_IT_ORDER: ++Count,
  
              MENU_GR_ID: data.MENU_GR_ID,
              MENU_GR_NAME: data.MENU_GR_NAME,
          //    MENU_GR_ORDER: data.MENU_GR_ORDER,
          //    MENU_NAME: data.MENU_NAME,
          //    MENU_ID: data.MENU_ID,
          //    MENU_TYPE: data.MENU_TYPE,
  
          EVERYDAY_YN: data.EVERYDAY_YN,
          SUNDAY_YN: data.SUNDAY_YN,
          MONDAY_YN: data.MONDAY_YN,
          TUESDAY_YN: data.TUESDAY_YN,
          WEDNESAY_YN: data.WEDNESAY_YN,
          THURSDAY_YN: data.THURSDAY_YN,
          FRIDAY_YN: data.FRIDAY_YN,
          SATURDAY_YN: data.SATURDAY_YN,
        };
  
        console.log(inputData);
  
        let res = null;
        if (isUpdate === true) {
          res = await API.graphql(
            graphqlOperation(UpdateSpecialMenuMasterWeb, {
              UpdateSPECIAL_MENU_MASTER_WEBInput: inputData,
            })
          );
        }
  
        
  
       
        onCloseEdit();
        props.SpecialData();
        showValidationMsg('Event Successfully Updated', 'success');
      } catch (err) {
        console.log('error: ', err);
        showValidationMsg('Error occured', 'error');
      }
    }
  
    return props?.data ? (
      <Table className="special-data">
        <Thead>
          <Tr>
            <Th w="26%" className="sticky">
              {props.special ? 'Special Name' : 'Event Name'}
            </Th>
            <Th w="25%" className="sticky special-data-column">
              Start Date{' '}
            </Th>
            <Th w="25%" className="sticky special-data-column">
              End Date{' '}
            </Th>
            <Th w="25%" className="sticky special-data-column">
              Status
            </Th>
            <Th w="24%" className="sticky" textAlign="center">
              Action
            </Th>
          </Tr>
        </Thead>
  
        <Tbody className="table-contents">
          <Tr>
            <Td colspan="5">
              <Table>
                {props.data.map(item => (
                  <Tr key={item.MENU_IT_ID}>
                    <Td w="25%">
                      <Box display="flex" alignItems="center">
                        <Box className="fmenuicon" mr={3}>
                          {' '}
                          {/* <FontAwesomeIcon icon={faConciergeBell} /> */}
                          {(item.MENU_IT_PIC_LOCATION != "") ?
                          <Image src={item.MENU_IT_PIC_LOCATION}></Image>:
                          <FontAwesomeIcon icon={faConciergeBell} />
                          }
                        </Box>
                        {/* {item.MENU_IT_NAME} */}
                        {item.MENU_IT_DESC }
                      </Box>
                      {/* <Box className="tableicon"> <Image src={SpecialfoddIcon} /></Box>{item.MENU_IT_NAME} */}
                    </Td>
                    <Td w="25%" className="special-data-column">
                      {item.DATE_FROM_DATE}
                    </Td>
                    <Td w="25%" className="special-data-column">
                      {item.DATE_TO_DATE}
                    </Td>
                    <Td w="25%" className="special-data-column">
                      {item.active == 'true' ? 'Active' : 'Not active'}
                    </Td>
                    {/* <Td w='25%' textAlign='center'><Link><EditIcon color="red.500" onClick={() => { onOpenEdit(); EditFunction(item) }} /> */}
                    <Td w="25%" textAlign="center">
                      <Link>
                        <EditIcon
                          color="red.500"
                          onClick={() => {
                            //debugger;
                            onOpenEdit();
                            setEditObject(item);
                          }}
                        />
                      </Link>
                    </Td>
                  </Tr>
                ))}
              </Table>
            </Td>
          </Tr>
        </Tbody>
  
        {/* <AddSpecialPopup IsSpecial={true} isupdate={true} Save={Save} object={editObject} open={isOpenEdit} close={() => { onCloseEdit(); setEditObject({}) }} /> */}
        <AddEventPopup
          IsSpecial={false}
          isupdate={true}
          Save={Save}
          object={editObject}
          open={isOpenEdit}
          close={() => {
            //debugger;
            onCloseEdit();
            setEditObject(null);
          }}
        />
      </Table>
    ) : null;
  }
  export default EventsData;
  