
import {
    Box,
    Input,
    Button,
    Text,
    Checkbox,
    Modal,
    ModalHeader,
    ModalOverlay,
    ModalCloseButton,
    ModalBody,
    ModalContent,
    ModalFooter,
    List,
    ListItem,
    ListIcon

} from "@chakra-ui/react";
import React, { useRef, useState, useEffect } from "react";
import { EditIcon, StarIcon } from '@chakra-ui/icons'


function PollRespondsPopup(props) {


    return (
        <>


            <Modal blockScrollOnMount={false}
                isOpen={props.open}
                onClose={props.close}
                size={'sx', 'md', 'xl', 'xl'}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Answer your poll qustion</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      
                            <Box p='5px'>
                                {props?.data?.POLL_QUESTION}
                            </Box>


                            {
                                props?.data?.relatedPollChoices?.map((ob, ind) => (
                                    <Box  cursor='pointer' p='5px' m='5px' borderRadius='10px' border='2px solid #ccc;' p='5px'  >{ob.POLL_CHOICE_VAL}</Box>

                                ))
                            }
                         
                       
                         <Text pt='20px' textAlign='center'>Response has been recorded</Text>
                    </ModalBody>

                    <ModalFooter>
                        {/* <Button colorScheme="blue" mr={3} onClick={props.close}>
                            Close
                        </Button> */}

                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
export default PollRespondsPopup;