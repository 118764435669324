import {
  Box,
  Input,
  Button,
  Text,
  Checkbox,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  ModalFooter,
  Image,
  Badge,
  Link,
} from '@chakra-ui/react';
import React, { useRef, useState, useEffect } from 'react';
import { format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHeart,
  faBookmark,
  faShare,
  faUtensils,
  faMapMarkerAlt,
  faCalendarAlt
} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { EditIcon, AddIcon, MinusIcon, StarIcon } from '@chakra-ui/icons';
import Cookies from 'js-cookie';
import { useToast, useDisclosure } from '@chakra-ui/react';
import AddtocartconfirmPopup from '../../../components/AddtocartconfirmPopup';
import OrderTypeConfirmPopup from './OrderTypeConfirmPopup';

function DetailsPopup(props) {
  const [itemDetails, setitemDetails] = useState(props.itemDetails);
  const history = useHistory();
  let [quantity, setquantity] = useState(1);
  const toast = useToast();
  const {
    isOpen: alertisOpen,
    onOpen: alertonOpen,
    onClose: alertonClose,
  } = useDisclosure();
  const {
    isOpen: orderTypeisOpen,
    onOpen: orderTypeonOpen,
    onClose: orderTypeonClose,
  } = useDisclosure();

  // function abc(){
  // 	alert("hi");
  // }
  useEffect(() => {
    var arr = props.upcomingSpecialsList?.filter(
      x => x.MENU_IT_ID == props.itemDetails.MENU_IT_ID
    );
    const a = 10;
    if (arr?.length > 0) setitemDetails(arr[0]);
    //	MENU_IT_ID
    setquantity(1);
   // //debugger;
    console.log(itemDetails);
  }, [props.upcomingSpecialsList, props.itemDetails]);

  function confirmcartclear(flag) {
    if (flag == true) {
      var specialitem = JSON.stringify(itemDetails);
      localStorage.setItem('specialItem', specialitem);
      history.push({
        //pathname: `/Admin/${localStorage.getItem('Currentpage')}`,
        pathname: '/RestaurantMenu/' + itemDetails?.REST_ID,
        prevouspage: 'Home',
        quantity: quantity,
        //  state: { email:props.userinfo?.email, password: props.userinfo?.pass }
      });
    }
  }
  function showValidationMsg(msg, st) {
    ////debugger;
    toast({
      title: '',
      description: msg,
      status: st,
      duration: 9000,
      isClosable: true,
      position: 'top',
    });
  }
  function AddOrder(){
    ////debugger;
	var specialitem = JSON.stringify(itemDetails);
	var json_cartitems = Cookies.get('cartitems');
	////debugger;
	if (json_cartitems == undefined || json_cartitems == "") {
	  if (json_cartitems == undefined || json_cartitems == '') {
		orderTypeonOpen();
	  } else {
		localStorage.setItem('restID', itemDetails?.REST_ID);
		var json_cartitems = Cookies.get('cartitems');
		if (json_cartitems) {
		  const items = JSON.parse(json_cartitems);

		  if (items[0]?.restId == itemDetails?.REST_ID) {
			localStorage.setItem('specialItem', specialitem);
			////debugger;
			history.push({
			  //pathname: `/Admin/${localStorage.getItem('Currentpage')}`,
			  pathname: '/RestaurantMenu/' + itemDetails?.REST_ID,
			  prevouspage: 'Home',
			  quantity: quantity,
			  //  state: { email:props.userinfo?.email, password: props.userinfo?.pass }
			});
			////debugger;

			//orderTypeonOpen();
			////debugger;
		  } else {
			////debugger;
			alertonOpen();
		  }

		  const a = 10;
		} else {
		  localStorage.setItem('specialItem', specialitem);
		  history.push({
			//pathname: `/Admin/${localStorage.getItem('Currentpage')}`,
			pathname: '/RestaurantMenu/' + itemDetails?.REST_ID,
			prevouspage: 'Home',
			quantity: quantity,
			//  state: { email:props.userinfo?.email, password: props.userinfo?.pass }
		  });
		}
	  }
	} else {
    ////debugger;
	  console.log(itemDetails?.DATE_FROM_DATE);
	  const orderDetails = JSON.parse(
		localStorage.getItem('orderDetails')
	  );
	  console.log(orderDetails);
    
    

	  if (
		(itemDetails?.DATE_FROM_DATE <= orderDetails.orderdate &&
		itemDetails?.DATE_TO_DATE >= orderDetails.orderdate) || (itemDetails?.DATE_FROM_DATE == undefined)
	  ) {
		// alert('HI');
    ////debugger;
		localStorage.setItem('restID', itemDetails?.REST_ID);
		var json_cartitems = Cookies.get('cartitems');
		if (json_cartitems) {
		  const items = JSON.parse(json_cartitems);

		  if (items[0]?.restId == itemDetails?.REST_ID) {
			localStorage.setItem('specialItem', specialitem);
			////debugger;
			history.push({
			  //pathname: `/Admin/${localStorage.getItem('Currentpage')}`,
			  pathname: '/RestaurantMenu/' + itemDetails?.REST_ID,
			  prevouspage: 'Home',
			  quantity: quantity,
			  //  state: { email:props.userinfo?.email, password: props.userinfo?.pass }
			});
			////debugger;

			//orderTypeonOpen();
			////debugger;
		  } else {
			////debugger;
			alertonOpen();
		  }

		  const a = 10;
		} else {
		  localStorage.setItem('specialItem', specialitem);
		  history.push({
			//pathname: `/Admin/${localStorage.getItem('Currentpage')}`,
			pathname: '/RestaurantMenu/' + itemDetails?.REST_ID,
			prevouspage: 'Home',
			quantity: quantity,
			//  state: { email:props.userinfo?.email, password: props.userinfo?.pass }
		  });
		}
	  } else {
		showValidationMsg('Special Not Available', 'error');
	  }

	  var specialitem = JSON.stringify(itemDetails);
	  var json_cartitems = Cookies.get('cartitems');
	  ////debugger
	}
  }

  return (
    <>
      <Modal
        blockScrollOnMount={false}
        isOpen={props.open}
        onClose={props.close}
        size={('sx', 'md', 'xl', 'xl')}
      >
        <ModalOverlay />
        <ModalContent m="0" alignSelf="center">
          <ModalHeader>{itemDetails?.MENU_IT_NAME}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pt={0}>
            {/* <Box className="carousel-item" 
                        >
                            <Image
                                src={require('../../../assets/images/cuisineImagesNew/DemoDish.jpg').default}
                                alt="Segun Adebayo" />
                            <Box p='15px'>
                                <Text fontSize='14px' mb='5px' fontWeight='bold'>{props.itemDetails?.MENU_IT_NAME}</Text>
                                <Text fontSize='14px'>{props.itemDetails?.MENU_IT_DESC}</Text>
                            </Box>
                        </Box> */}

            <Box>
              <AddtocartconfirmPopup
                confirmcartclear={confirmcartclear}
                quantity={quantity}
                itemDetails={itemDetails}
                onClose={alertonClose}
                isOpen={alertisOpen}
              />

              <OrderTypeConfirmPopup
                quantity={quantity}
                itemDetails={itemDetails}
                onClose={orderTypeonClose}
                isOpen={orderTypeisOpen}
              />
  <Box className="upcomingspecial-box-popup">
              <Image
                cursor="pointer"
                onClick={console.log('clicked')}
                // src={require('../../../assets/images/cuisineImagesNew/DemoDish.jpg').default}
                src={
                  itemDetails.MENU_IT_PIC_LOCATION == '' ||
                  itemDetails.MENU_IT_PIC_LOCATION == null
                    ? require('../../../assets/images/cuisineImagesNew/DemoDish.jpg')
                        .default
                    : itemDetails.MENU_IT_PIC_LOCATION
                }
                alt="Segun Adebayo"
              />
              <Box className="upcomingicons">
                <Box
                  className="upcomingicon1"
                  cursor="pointer"
                  onClick={() => {
                    props.onUpcomingSpecialHeart(
                      itemDetails?.MENU_IT_ID,
                      itemDetails?.USER_LIKE_YN,
                      itemDetails?.USER_BOOKMARK_YN,
                      itemDetails?.relatedMenuItemLikes.LIKE_COUNT
                    );
                  }}
                >
                  <FontAwesomeIcon
                    color={
                      itemDetails?.USER_LIKE_YN == 'Y' ? '#00acfe' : '#555555'
                    }
                    m="5px"
                    icon={faHeart}
                  />{' '}
                  <Badge className="badge-like">
                    {itemDetails?.relatedMenuItemLikes?.LIKE_COUNT}
                  </Badge>
                </Box>

                <Box className="upcomingicon1" cursor="pointer">
                  <FontAwesomeIcon color="black" icon={faShare} />
                </Box>
                <Box
                  className="upcomingicon1"
                  cursor="pointer"
                  onClick={() => {
                    props.onUpcomingSpecialBookMark(
                      itemDetails?.MENU_IT_ID,
                      itemDetails?.USER_BOOKMARK_YN,
                      itemDetails?.USER_LIKE_YN
                    );
                  }}
                >
                  <FontAwesomeIcon
                    color={
                      itemDetails?.USER_BOOKMARK_YN == 'Y'
                        ? '#00acfe'
                        : '#555555'
                    }
                    icon={faBookmark}
                  />
                </Box>
              </Box>
              </Box>
              <Box pt="15px" className="popup-details">
                {/* <Text fontSize='14px' mb='5px' fontWeight='bold'>{itemDetails?.MENU_IT_NAME}</Text> */}
                <Box d='flex'  mb={2} borderBottom='1px solid #E7E7E7'>
<Box d="flex" className="popup-detailsin">
                  <FontAwesomeIcon fontSize="22px" icon={faUtensils} />
                  <Text pl="7px" fontSize="15x" fontWeight='600'>
                    {itemDetails?.REST_NAME}
                  </Text>
                </Box>

                <Box d="flex" ml='auto' pl={3} className="popup-detailsin">
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                  <Text pl="7px" fontSize="15x" fontWeight='600'>
                    {itemDetails?.CITY_NAME}
                  </Text>
                </Box>
</Box>
                <Text fontSize="14px">{itemDetails?.MENU_IT_DESC}</Text>

                 
                <Box
                  d="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  className="quantitybox"
                  flexDirection={{base: 'column', sm: 'row'}}
                  mt={3}
                >
                   {props.itemDetails?.DATE_FROM_DATE == undefined ? null :(
                 <Box d='flex' alignItems="center" mb={{base: '3', sm: '0'}}>
                  <FontAwesomeIcon icon={faCalendarAlt} color='#9E9E9E'/>
                    <Text ml={2} color='#3D3D3D'> Available from:</Text>

                    <Text fontSize="14px" color='#747474' ml={2}>
                    {format(new Date(itemDetails?.DATE_FROM_DATE), "dd-MMM") + ' '} - {' '}
                    {' '+ format(new Date(itemDetails?.DATE_TO_DATE), "dd-MMM") + ' '}
                      {/* {itemDetails?.DATE_FROM_DATE + ' '} To{' '}
                      {' ' + itemDetails?.DATE_TO_DATE} */}
                    </Text>
                  </Box> 
                 ) }

                  <Box
                    d="flex"
                    justifyContent="center"
                    className="popupcart-btn-group"
                  >
                    <Box className="cartbtn">
                      <Link>
                        <MinusIcon
                          onClick={() => {
                            if (quantity > 1) setquantity(--quantity);
                          }}
                        />
                      </Link>
                    </Box>
                    <Text fontWeight="medium" m="10px">
                      {quantity}
                    </Text>
                    <Box className="cartbtn">
                      <Link>
                        <AddIcon
                          onClick={() => {
                            if (quantity >= 1 && quantity < 100)
                              setquantity(++quantity);
                          }}
                        />
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </ModalBody>

          <ModalFooter pt="3">
          <Box w="100%" d="flex" justifyContent="space-between" alignItems='center'>
                <Text fontSize="20px" fontWeight='600' pr={3}>
                  ${(itemDetails?.MENU_IT_PRICE * quantity).toFixed(2)}
                </Text>
            <Button className='primary-btn' fontSize="16px" px={4}
              onClick={() => {
				AddOrder();
        //debugger;
              }}>
                  Add To Cart
            </Button>
              </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default DetailsPopup;
