/*global google*/
import {
	Box,
	Input,
	Button,
	Text,
	Checkbox,
	Modal,
	ModalHeader,
	ModalOverlay,
	ModalCloseButton,
	ModalBody,
	ModalContent,
	ModalFooter,
	List,
	ListItem,
	ListIcon,
	Heading,
	UnorderedList,
	Radio,
	RadioGroup


} from "@chakra-ui/react";
import React, { useRef, useState, useEffect } from "react";
import { EditIcon, StarIcon } from '@chakra-ui/icons'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';


function MapPopup(props) {

//	const google = window.google;

	let val = 1;
	const defaultCenter = {
		lat: 41.3851, lng: 2.1734
	}
	const mapStyles = {
		height: "80vh",
		width: "100%"
	};
	const [currentPosition, setCurrentPosition] = useState({ lat: 41.3851, lng: 2.1734 });

	const onMarkerDragEnd = (e) => {
		const lat = e.latLng.lat();
		const lng = e.latLng.lng();
		setCurrentPosition({ lat, lng })
		getReverseGeocodingData(lat,lng)
	};

	function getReverseGeocodingData(lat, lng) {
		var geocoder = new google.maps.Geocoder();
		var latlng = new google.maps.LatLng(lat, lng);
		// This is making the Geocode request
		
		geocoder.geocode({ 'latLng': latlng }, function (results, status) {
			if (status !== google.maps.GeocoderStatus.OK) {
				alert(status);
			}
			// This is checking to see if the Geoeode Status is OK before proceeding
			if (status == google.maps.GeocoderStatus.OK) {
				console.log(results);
				//var address = (results[0].formatted_address);
			}
		});
	}


	return (
		<>


			<Modal blockScrollOnMount={false}
				isOpen={props.open}
				onClose={props.close}
				size={'sx', 'md', 'sm', 'xl'}
			>
				<ModalOverlay />
				<ModalContent>
					{/* <ModalHeader></ModalHeader> 
					 <ModalCloseButton color='blue.500' top='0px'/> */}
					<ModalBody p='20px'>
						<LoadScript
							googleMapsApiKey='AIzaSyCBxt3zFfZDs0ycEGJ70_Ez31nYoAYLIzk'>
							<GoogleMap
								mapContainerStyle={mapStyles}
								zoom={13}
								center={defaultCenter}>
								{
									currentPosition.lat ?
										<Marker
											position={currentPosition}
											onDragEnd={(e) => onMarkerDragEnd(e)}
											draggable={true} /> :
										null
								}
							</GoogleMap>
						</LoadScript>

					</ModalBody>

					<ModalFooter>
						{/* <Button colorScheme="blue" mr={3} onClick={props.close}>
							Close
            </Button> */}

					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}
export default MapPopup;