
import {
    Box,
    Input,
    Button,
    Text,
    Checkbox,
    Modal,
    ModalHeader,
    ModalOverlay,
    ModalCloseButton,
    ModalBody,
    ModalContent,
    ModalFooter,
    Image,
    Badge,
    Link

} from "@chakra-ui/react";
import React, { useRef, useState, useEffect } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faBookmark, faShare, faUtensils, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom';
import { EditIcon, AddIcon, MinusIcon, StarIcon } from '@chakra-ui/icons'
//import aa from '../assets/images'
import ImageRestaurant from '../assets/images/Restaurant.jpg'
import ReactStars from "react-rating-stars-component";
import MapPopup from '../components/MapPopup'
import { useToast, useDisclosure } from "@chakra-ui/react";

function RestaurantDetailsPopup(props) {

    const [itemDetails, setitemDetails] = useState(props.itemDetails)
    const history = useHistory();
    let [quantity, setquantity] = useState(1);
    let [todayWorkinghour, setTodayWorkinghour] = useState(1);
    const {
		isOpen: isOpenMap,
		onOpen: onOpenMap,
		onClose: onCloseMap
	} = useDisclosure()

    useEffect(() => {

        const date = new Date();
        const weekday = date.toLocaleString("default", { weekday: "long" })
        const time = props.restaurantInfo?.day?.filter(x => x.includes(weekday));
        if (time) {
            setTodayWorkinghour(time[0]?.substr(time[0]?.indexOf(' ') + 1))
        }


    }, []);

    return (
        <>


            <Modal blockScrollOnMount={false}
                isOpen={props.open}
                onClose={props.close}
                size={'sx', 'md', 'xl', 'xl'}
            >
                <ModalOverlay />
                <ModalContent m="0" alignSelf="center">
                    <ModalHeader>{props.restaurantInfo?.REST_NAME}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pt={0}>



                        <Box >
                        <MapPopup restaurantInfo={props.restaurantInfo} open={isOpenMap} close={onCloseMap} />
                        <Box className="upcomingspecial-box-popup">
                            <Image
                                src={require('../assets/images/Restaurant.jpg').default}


                                alt="Segun Adebayo" />

                            <Box className='upcomingicons'>
                                <Box className='upcomingicon1'
                                 cursor='pointer'
                                onClick={() => {
                                    props.savePopup('12','Y')

                                }}
                                ><FontAwesomeIcon 
                                 color={'#555555'} 
                                m='5px'
                                    
                                    icon={faHeart} /> <Badge className='badge-like' >5</Badge></Box>

            
                            

                            </Box>
</Box>
<Box pt="15px" className="popup-details">

                                <Box d='flex' className="popup-detailsin" justifyContent='space-between'>
                                    {/* <FontAwesomeIcon fontSize='22px' icon={faUtensils} /> */}
                                    <Text pt='5px' pl='7px' fontSize='14px'>American-Burger-FastFood</Text>
                                    <Box d='flex'>
                                        <ReactStars

                                            count={props.restaurantInfo?.RATING}

                                            size={20}
                                            value={4}
                                            activeColor="#ffd700"
                                            edit={false}

                                        />
                                        <Text pt='5px !important'>({props.restaurantInfo?.REVIEW_NUMBERS} ratings)</Text>
                                    </Box>

                                </Box>
                                <Box d='flex' className="popup-detailsin">
                                    {/* <FontAwesomeIcon fontSize='22px' icon={faUtensils} /> */}
                                    <Text pl='7px' fontSize='14px'>{props.restaurantInfo?.OPEN_YN == 'Y' ? 'Open Now   (' + todayWorkinghour + ')' : 'Closed  '}</Text>
                                </Box>
                                <Box d='flex' className="popup-detailsin">
                                    {/* <FontAwesomeIcon fontSize='22px' icon={faUtensils} /> */}
                                    <Text pl='7px' fontSize='14px'>{props.restaurantInfo?.REST_NAME}</Text>
                                </Box>


                                <Box d='flex' className="popup-detailsin">
                                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                                    <Link onClick={() => onOpenMap()} color='blue.400' pl='7px' fontSize='14px'>{props.restaurantInfo?.ADDRESS}</Link>
                                </Box>

                                {/* 
                                <Box d='flex'>
                                    <Box>Sunday</Box>    <Text> {props.restaurantInfo?.day[0]?.substr(props.restaurantInfo?.day[0].indexOf(' ') + 1)}</Text>
                                </Box>
                                <Box d='flex'>
                                    <Box> Monday</Box> <Text> {props.restaurantInfo?.day[1]?.substr(props.restaurantInfo?.day[1].indexOf(' ') + 1)}</Text>
                                </Box>
                                <Box d='flex'>
                                    <Box>Tuesday</Box>   <Text> {props.restaurantInfo?.day[2]?.substr(props.restaurantInfo?.day[2].indexOf(' ') + 1)}</Text>
                                </Box>
                                <Box d='flex'>
                                    <Box>wednesday</Box>  <Text> {props.restaurantInfo?.day[3]?.substr(props.restaurantInfo?.day[3].indexOf(' ') + 1)}</Text>
                                </Box>
                                <Box d='flex'>
                                    <Box>Thursday</Box>  <Text> {props.restaurantInfo?.day[4]?.substr(props.restaurantInfo?.day[4].indexOf(' ') + 1)}</Text>
                                </Box>
                                <Box d='flex'>
                                    <Box>Friday</Box>   <Text> {props.restaurantInfo?.day[5]?.substr(props.restaurantInfo?.day[5].indexOf(' ') + 1)}</Text>

                                </Box>
                                <Box d='flex'>
                                    <Box>Saturday</Box>  <Text> {props.restaurantInfo?.day[6]?.substr(props.restaurantInfo?.day[6].indexOf(' ') + 1)}</Text>
                                </Box> */}




                            </Box>


                        </Box>

                    </ModalBody>

                    {/* <ModalFooter pt="1">

                        <Button colorScheme="blue" onClick={() => { props.close() }

                        }>
                            <Box w='100%' d='flex' justifyContent='space-between'>

                                <Text fontSize='17px' >Close</Text>
                            </Box>
                        </Button>

                    </ModalFooter> */}
                </ModalContent>
            </Modal>
        </>
    )
}
export default RestaurantDetailsPopup;