import {
  Box,
  Spinner,
  HStack,
  Button,
  Text,
  Link,
  SimpleGrid,
  IconButton
} from "@chakra-ui/react";
import React, { useRef, useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import Header from '../../../components/Header'
import { Auth, Hub, API, graphqlOperation } from 'aws-amplify';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import UserSidebar from '../../../components/UserSidebar'
import { listUserTowns as ListUserTowns } from "../../../graphql/queries";
import { getRestMasterByTown as GetRestMasterByTown } from "../../../graphql/queries";
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faHome, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'
import HorizontalScroll from '../Home/HorizontalScroll'
import Neighbourtowns from './Neighbourtowns'
import StarRatings from 'react-star-ratings';
import { useToast, useDisclosure } from "@chakra-ui/react";
import MapPopup from './MapPopup'
import moment from 'moment'
import Dropdown from 'react-dropdown'
import 'react-dropdown/style.css';

const Towns = () => {
  const options = [
    "Today's Special",
    "Upcoming Special"
  ];
  const defaultOption = options[0];
  const cache = useRef({});
  const history = useHistory();
  const [togglebit, setTogglebit] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [cartData, setCartData] = useState([]);
  let [hometown, setHomeTown] = useState([]);
  const [htZipCode, sethtZipCode] = useState();
  let [selectedCity, setSelectedCity] = useState([]);
  let [cities, setCities] = useState([]);
  let [upcomingSpecials, setUpcomingSecials] = useState([]);
  let [todaysSpecials, setTodaysspecials] = useState([]);
  let [restaurantList, setRestaurantList] = useState([]);
  let [specialBit, setSpecialBit] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure()


  useEffect(() => {
    loadData();
    // navigation.addListener("focus", () => {
    //   // alert("test")
    // });
    let today = moment(new Date(), "YYYY-MM-DD");
    let formattedtoday = moment(today, "YYYY-MM-DD").format("YYYY-MM-DD");

    var json_cartitems = Cookies.get('cartitems');
    if (json_cartitems) {
      const items = JSON.parse(json_cartitems);
      setCartData(items);

      const cartlen = items.filter(
        ob => ob.cart_created_date >= formattedtoday
      );
      console.log(cartlen)
      if (cartlen == 0) {
        Cookies.remove('cartitems');
      }
      //const a = 10;
      console.log("cart", items);


    }
  }, []);

  function loadData() {

    cityList();

  }

  function swapElement(array, indexA, indexB) {
    var tmp = array[indexA];
    array[indexA] = array[indexB];
    array[indexB] = tmp;
  }

  async function cityList() {
    setIsLoading(true)
    ////debugger;
    await API.graphql(graphqlOperation(ListUserTowns))
      .then((result) => {
        console.log(result);
        result.data.listUSER_TOWNS.forEach((element) => {
          if (element.ht_ind == "HT") {
            setHomeTown(element.city);
            //  locationInfo(element.city);
            setSelectedCity(element.zip_code);
            listRestaurants(element.zip_code);
            sethtZipCode(element.zip_code);
          }
        });


        var index = result.data.listUSER_TOWNS.findIndex(
          (x) => x.ht_ind === "HT"
        );

        swapElement(result.data.listUSER_TOWNS, index, 0);
        setCities(result.data.listUSER_TOWNS);
      })
      .catch((error) => {
        console.log(" Error", error);
      });

  }


  // restaurant list, specials list fetching logic
  async function listRestaurants(zip_code) {

    // if (cache.current[zip_code + "todayspl"]) {
    //   setRestaurantList(cache.current[zip_code]);
    //   setTodaysspecials(cache.current[zip_code + "todayspl"]);
    //   setTodaysspecials(cache.current[zip_code + "upspl"]);
    // } 

    //else {
    setRestaurantList([]);

    const restaurants = await API.graphql(
      graphqlOperation(GetRestMasterByTown, { ZIPCODE: zip_code })
    );
    restaurants.data.getREST_MASTER_BY_TOWN?.length > 0
      ? setRestaurantList(restaurants.data.getREST_MASTER_BY_TOWN)
      : setRestaurantList([]);

    cache.current[zip_code] = restaurants.data.getREST_MASTER_BY_TOWN;

    let temp = [];

    restaurants.data.getREST_MASTER_BY_TOWN.forEach((element) => {
      element.relatedSplTodayByRest.forEach((row) => {
        temp.push(row);
      });
    });

    setTodaysspecials(temp);
    // cache.current[zip_code + "todayspl"] = temp;
    let temp1 = [];
    restaurants.data.getREST_MASTER_BY_TOWN.forEach((element) => {
      element.relatedSplUpcomingByRest.forEach((row) => {
        temp1.push(row);
      });
    });
    setUpcomingSecials(temp1);
    //cache.current[zip_code + "upspl"] = temp1;
    // }
    setIsLoading(false)
  }

  function toggle() {
    setTogglebit(current => !current);
  }


  function updatetowndata(zip) {
    listRestaurants(zip)
  }

  function dropdownChange(e) {
    if (e.value == "Upcoming Special") {
      setSpecialBit(true)
    }
    if (e.value == "Today's Special") {
      setSpecialBit(false)
    }
  }
  return (
    <Box bg='#f9f9f9'>
      <Helmet>
        <title>Squash | Towns</title>
      </Helmet>
      <MapPopup open={isOpen} close={() => { onClose(); }} />
      <Header isAdmin={false} toggle={toggle} />
      <Box pt='70px' position='relative' h='100vh' overflow='hidden' d='flex'>
        <Box zIndex='9' bg='#fff' position='absolute' top='0' pt='100px' left='0' h='100%' w={(togglebit ? "0px" : '200px')} display={(togglebit ? "none" : 'block')}>
          <UserSidebar />
        </Box>
        {isLoading ? (<Box className="rspinbox" > <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        /></Box >) :
          <Box
            //   ml={(togglebit ? "0px" : { base: "0", sm: "0", md: "0", lg: '200px' })}
            w='100%' p='20px' overflow='auto'>
            <Box mb={4}>
              <Box className="mapBox" d='flex' pb='3' justifyContent='space-between' alignItems='center'>
                <Text fontSize='20px' fontWeight='bold' className="user-title">Towns</Text>

                <Box d='flex' alignItems='center' className="town-header-right">
                  {/* <Button className="town-top-button">
                      abcd
                    </Button> */}
                  <Link onClick={() => { onOpen() }}>    <FontAwesomeIcon icon={faMapMarkerAlt} /></Link>
                </Box>

              </Box>
              {
                cities?.length == 0 ? '' :
                  <Neighbourtowns cities={cities} updatetowndata={updatetowndata} />
              }
            </Box>
            <Box className="card content-card">

              <Box className="table-padding towns-wrapper">
                <Dropdown
                  options={options}
                  value={defaultOption}
                  onChange={dropdownChange}
                  className='myClassName'
                  arrowClassName='myArrowClassName'
                  menuClassName='myMenuClassName'
                />
                <Box d='flex'>
                  <Box d='flex' className="towns-tab">
                    {/* <Link className={specialBit == false ? "active" : ""} onClick={() => setSpecialBit(false)}  >Today's Specials</Link>

                    <Link className={specialBit == true ? "active" : ""} onClick={() => setSpecialBit(true)} >Upcoming Specials</Link> */}
                  </Box>
                </Box>
                <hr className='user-divider user-divider-2' marginBottom="15px"></hr>
                <Box pt='40px'>
                  {todaysSpecials?.length == 0 || !specialBit ? '' :
                    <HorizontalScroll fromTown={true} todaySpecialsList={todaysSpecials} />
                  }
                </Box >

                <Box pt='5px'>
                  {upcomingSpecials?.length == 0 || specialBit ? '' :
                    <HorizontalScroll fromTown={true} todaySpecialsList={upcomingSpecials} />
                  }
                </Box >
                <Box>
                  {restaurantList?.length == 0 ? '' :
                    <HorizontalScroll fromTown={true} heading="Spotlight Restaurants" restaurantList={restaurantList} />
                  }
                </Box >
                <Box className="fixed-position">
                  {/* <Image src={CartImage} width={100} height={100} onClick={}/> */}
                  {(cartData.length == 0) ? (
                    ''
                  ) : (
                    <Button
                      w='auto'
                      minWidth={{ base: '30px', md: '34px' }}
                      h={{ base: '30px', md: '34px' }}
                      // h='auto'
                      ml='15px'
                      p='0px'
                      background='#001d56'
                      color='#ffffff'
                      as={IconButton}
                      aria-label="Options"
                      icon={<FontAwesomeIcon fontSize='22px' icon={faShoppingCart} />}
                      variant="outline"
                      onClick={() => {



                        history.push(
                          {
                            //pathname: `/Admin/${localStorage.getItem('Currentpage')}`,
                            pathname: '/RestaurantMenu/' + cartData[0]?.restId,


                            //  state: { email:props.userinfo?.email, password: props.userinfo?.pass }
                          }
                        );

                      }}
                    />)}

                </Box>
                <hr className='user-divider'></hr>
                <Text fontSize='17px' pb='10px' fontWeight='bold' >Restaurants</Text>

                <SimpleGrid columns={{ base: '1', sm: '1', md: '2', lg: '3' }} spacing="20px">
                  {restaurantList?.length == 0 ? '' :
                    restaurantList?.map((obj, ind) => (
                      <Box
                        cursor='pointer'
                        onClick={() => {
                          history.push(
                            {
                              //pathname: `/Admin/${localStorage.getItem('Currentpage')}`,
                              pathname: '/RestaurantMenu/' + obj.REST_ID,
                              from: 'SpotlightRestaurants'
                              //  state: { email:props.userinfo?.email, password: props.userinfo?.pass }
                            }
                          );

                          //setitemDetails(item)
                        }}
                        className="town-restaurant-box">
                        <Box d='flex' justifyContent='space-between' flexDirection={{ base: 'column', sm: 'row' }} alignItems={{ base: 'flex-start', sm: 'center' }} borderBottom='1px dashed #CADEF8' pb={2} mb={2}>
                          <Text fontSize='15px'
                            whiteSpace='nowrap'
                            textOverflow='ellipsis'
                            overflow='hidden'
                            pr={3}
                            fontWeight='500'>{obj.REST_NAME}</Text>
                          <Box d='flex' alignItems='center' className="town-restaurant-box-rating">
                            <StarRatings
                              starDimension='13px'
                              starSpacing='1px'
                              rating={obj.RATING}
                              starRatedColor="orange"
                              numberOfStars={5}
                              name='rating'
                            />
                            <Text fontWeight='bold' fontSize='12px'>({obj.RATING})</Text>
                          </Box>

                        </Box>
                        <Box display='flex' className="town-restaurant-box-in"> <FontAwesomeIcon icon={faMapMarkerAlt} color='#AFD0FC' />
                          <Box> <Text fontSize='14px' color='#747474' ml={3}>{obj.CITY_NAME}</Text>
                            <Text fontSize='14px' color='#747474' ml={3}>{obj.ADDRESS}</Text></Box>
                        </Box>

                        <Box display='flex' className="town-restaurant-box-in" > <FontAwesomeIcon icon={faPhoneAlt} marginRight='7px' color='#AFD0FC' />
                          <Text fontSize='14px' color='#747474' ml={3}>Ph: {obj.PHONE}</Text>
                        </Box>
                      </Box>
                    ))}
                  {/* <Box bg="tomato" height="80px"></Box>
                  <Box bg="tomato" height="80px"></Box>
                  <Box bg="tomato" height="80px"></Box>
                  <Box bg="tomato" height="80px"></Box>
                  <Box bg="tomato" height="80px"></Box>
                  <Box bg="tomato" height="80px"></Box> */}
                </SimpleGrid>
                {/* 
                <Box>
                  {restaurantList?.length == 0 ? '' :
                    restaurantList.map((obj, ind) => (
                      <Box>
                        <Text>{obj.REST_NAME}</Text>
                        <Text>{obj.CITY_NAME}</Text>
                        <Text>{obj.ADDRESS}</Text>
                        <Text>Ph: {obj.PHONE}</Text>
                      </Box>
                    ))}
                </Box > */}
              </Box >
            </Box >
          </Box>
        }
      </Box>
    </Box>
  );
}
export default Towns;