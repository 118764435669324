import {
  Box,
  Button,
  Text,
  Spinner,
  IconButton,
  Center,
  Square,
  Flex,
  List,
  ListItem,
  Spacer,
  Tab,
  Tabs,
  TabPanel,
  TabList,
  TabPanels,
  useDisclosure
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Sidebar from './sidebar';
import Header from './Header';
import AdminOrderHistoryPopup from './AdminOrderHistoryPopup'

import { useHistory } from 'react-router-dom';
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';

import Cookies from 'js-cookie';

import { Helmet } from 'react-helmet';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faConciergeBell } from '@fortawesome/free-solid-svg-icons'
import { getAllordersByRest } from '../graphql/queries';

const AdminOrderHistory = () => {
  const history = useHistory();

  const [togglebit, setTogglebit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [orderid, setorderid] = useState(null);

  useEffect(() => {
    getOrdersList();
  }, []);

  async function getOrdersList() {
    let res = null;
    setIsLoading(true);
    try {
      const res = await API.graphql(
        graphqlOperation(getAllordersByRest, {
          REST_ID: localStorage.getItem('Restaurantid'),
        })
      );
      if (res) {
        let OrderData = res.data.getALLORDERS_BY_REST;
        console.log(OrderData);
        setOrderList(OrderData);
        ////debugger;
        //setorderid(item.ORDER_ID)
      }
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  }

  function toggle() {
    setTogglebit(current => !current);
  }

  return (
    <Box bg="#f9f9f9">
      <Helmet>
        <title>Squash | Order History</title>
      </Helmet>
      <Header isAdmin={true} toggle={toggle} />
      <Box pt="85px" position="relative" h="100vh" overflow="hidden" d="flex">
        <Box
          //className="user-sidebar"
          zIndex="9"
          bg="#fff"
          position="absolute"
          top="0"
          pt="100px"
          left="0"
          h="100%"
         // w={togglebit ? '0px' : '200px'}
       //  display={togglebit ? 'none' : 'block'}
         w={{
          base: togglebit ? '200px' : '0',
          sm: togglebit ? '200px' : '0',
          md: togglebit ? '200px' : '0',
          lg: togglebit ? '0' : '200px',
        }}
     d={{
          base: togglebit ? 'block' : 'none',
          sm: togglebit ? 'block' : 'none',
          md: togglebit ? 'block' : 'none',
          lg: togglebit ? 'none' : 'block',
        }}
        >
          <Sidebar current="Home" togglebit={togglebit} />
        </Box>
        {isLoading ? (
          <Box className="rspinbox">
            {' '}
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Box>
        ) : (
          <Box
          ml={togglebit ? '0px' : { base: '0', sm: '0', md: '0', lg: '200px' }}
          w="100%"
          p="20px"
          overflow="auto"
        >
            <Box className="card   no-padding">
            <Box className="card-header" display='flex' alignItems='center' position='relative'>
                <Box className="title-heading" width='100%'><h2> Order History</h2></Box>

            </Box>
            <Box className="card-content table-padding" pt='0'>
              <Box justifyContent="space-between" alignItems="center" w="100%">
                <Box className="search-result-tab">
                  <Tabs m="5px" variant="soft-rounded" colorScheme="blue">
                    <Box>
                      
                      <TabPanels>
                        <TabPanel p="0" pt="0">
                          <Box className='notification-list'>
                          <AdminOrderHistoryPopup orderid={orderid}  open={isOpen} close={() => { onClose();  }} />
                            {orderList?.length == 0
                              ? ''
                              : orderList.map((obj, ind) => (
                                  <Box
                                    mb="15px"
                                    p="10px 15px"
                                    borderRadius="10px"
                                    border="1px solid #C3DDFF;"
                                    onClick={() => {onOpen(); {setorderid(obj.ORDER_ID)};}}
                                    cursor="pointer"
                                  >
                                    <Box d='flex' alignItems={{bas:'flex-start', sm:'center'}} flexDirection={{base:'column', sm:'row'}}>
                                    <Box className="fmenuicon" mr={3}>  <FontAwesomeIcon icon={faConciergeBell} /></Box>
                                    <Box>
                                   <Text fontWeight='bold' fontSize='16px' pb='1'>
                                      {obj.REST_NAME}
                                    </Text>
                                      <Box d="flex">
                                      <Text color='#747474' fontSize='14px' fontWeight='medium' pr='1'>
                                          Order ID :{' '}
                                        </Text>
                                        <Text color='#747474' fontSize='14px' fontWeight='light'>
                                          {obj.ORDER_ID}
                                          
                                        </Text>
                                      </Box>
                                      </Box>

                                      <Box d='flex' ml={{base:'0', sm:'auto'}} mt={{base:'3', sm:'0'}} alignItems='center'>
																	<Text  fontSize='14px' pr='1'>Order Status </Text>
                                        {obj.ORDER_STATUS ==="Submitted"  ? <Text className="notification-status">{obj.ORDER_STATUS}</Text>
																	 : obj.ORDER_STATUS ==="Completed" ? <Text className="notification-status" bg='#def3e7' color='#00B507'>{obj.ORDER_STATUS}</Text> 
                                                                     : obj.ORDER_STATUS ==="Rejected" ? <Text className="notification-status" bg='#f6e2e7' color='#EF0707'>{obj.ORDER_STATUS}</Text>
																	: obj.ORDER_STATUS ==="Accepted" ? <Text className="notification-status">{obj.ORDER_STATUS}</Text>
																	: obj.ORDER_STATUS ==="Ready for Pickup" ? <Text className="notification-status">{obj.ORDER_STATUS}</Text>
																	: obj.ORDER_STATUS ==="Checked-in" ? <Text className="notification-status">{obj.ORDER_STATUS}</Text>
																	:''}
                                        {/* <Text fontWeight="light">

                                          {obj.ORDER_STATUS}
                                        </Text> */}
                                      </Box>
                                    </Box>
                                  </Box>
                                ))}
                          </Box>
                        </TabPanel>
                      </TabPanels>
                    </Box>
                  </Tabs>
                </Box>
              </Box>
            </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default AdminOrderHistory;
