import {
  Box,
  Container,
  Button,
  Grid,
  GridItem,
  Image,
  Flex,
  Text,
  Spacer,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Table,
  Tbody,
  Tr,
  Td,
  Thead,
  Th,
  Spinner,
} from '@chakra-ui/react';

import React, { useEffect, useState } from 'react';

import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import Sidebar from '../../../components/sidebar';
import Header from '../../../components/Header';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { Hub, API, graphqlOperation } from 'aws-amplify';
import { getRestMaster as GetRestMaster } from '../../../graphql/queries';
import { getOrdersByRest as getOrdersByRest } from '../../../graphql/queries';
import { getPollsByRest as getPollsByRest } from '../../../graphql/queries';
import moment from 'moment';
import { EditIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useToast, useDisclosure } from '@chakra-ui/react';
import GenerateScriptPopup from './GenerateScriptPopup';


const cuisineList = [
  {
    CUISINE_ID: 'C1',
    CUISINE_TYPE_NAME: 'American',
    USER_FAVORITE_YN: 'N',
  },
  {
    CUISINE_ID: 'C2',
    CUISINE_TYPE_NAME: 'Indian',
    USER_FAVORITE_YN: 'N',
  },
  {
    CUISINE_ID: 'C3',
    CUISINE_TYPE_NAME: 'Mexican',
    USER_FAVORITE_YN: 'N',
  },
  {
    CUISINE_ID: 'C4',
    CUISINE_TYPE_NAME: 'Italian',
    USER_FAVORITE_YN: 'N',
  },
  {
    CUISINE_ID: 'C5',
    CUISINE_TYPE_NAME: 'Mediterranean',
    USER_FAVORITE_YN: 'N',
  },
  {
    CUISINE_ID: 'C6',
    CUISINE_TYPE_NAME: 'Greek',
    USER_FAVORITE_YN: 'N',
  },
  {
    CUISINE_ID: 'C7',
    CUISINE_TYPE_NAME: 'Thai',
    USER_FAVORITE_YN: 'N',
  },
  {
    CUISINE_ID: 'C8',
    CUISINE_TYPE_NAME: 'Chinese',
    USER_FAVORITE_YN: 'N',
  },
  {
    CUISINE_ID: 'C9',
    CUISINE_TYPE_NAME: 'Japanese',
    USER_FAVORITE_YN: 'N',
  },
  {
    CUISINE_ID: 'C10',
    CUISINE_TYPE_NAME: 'French',
    USER_FAVORITE_YN: 'N',
  },
  {
    CUISINE_ID: 'C11',
    CUISINE_TYPE_NAME: 'Turkish',
    USER_FAVORITE_YN: 'N',
  },
  {
    CUISINE_ID: 'C12',
    CUISINE_TYPE_NAME: 'Persian',
    USER_FAVORITE_YN: 'N',
  },
  {
    CUISINE_ID: 'C13',
    CUISINE_TYPE_NAME: 'Pizza',
    USER_FAVORITE_YN: 'N',
  },
  {
    CUISINE_ID: 'C14',
    CUISINE_TYPE_NAME: 'Vietnamese',
    USER_FAVORITE_YN: 'N',
  },
  {
    CUISINE_ID: 'C15',
    CUISINE_TYPE_NAME: 'Korean',
    USER_FAVORITE_YN: 'N',
  },
  {
    CUISINE_ID: 'C16',
    CUISINE_TYPE_NAME: 'Deli',
    USER_FAVORITE_YN: 'N',
  },
  {
    CUISINE_ID: 'C17',
    CUISINE_TYPE_NAME: 'Fruits-Smoothie',
    USER_FAVORITE_YN: 'N',
  },
  {
    CUISINE_ID: 'C18',
    CUISINE_TYPE_NAME: 'Pastry-Bakery-Ice Cream',
    USER_FAVORITE_YN: 'N',
  },
  {
    CUISINE_ID: 'C19',
    CUISINE_TYPE_NAME: 'Coffee-Bagel',
    USER_FAVORITE_YN: 'N',
  },
];

const Home = () => {
  const history = useHistory();
  const [togglebit, setTogglebit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resData, SetResData] = useState([]);
  const [refreshing, setRefreshing] = useState(false);
  const [inProgressItem, setInProgressItem] = useState([]);
  const [newOrderCount, setNewOrderCount] = useState('0');
  const [inProgressCount, setInProgressCount] = useState('0');
  const [completedCount, setcompletedCount] = useState('0');
  const [newReservCount, setNewReservCount] = useState('0');
  const [inProgressReservCount, setInProgressReservCount] = useState('0');
  const [completedReservCount, setcompletedReservCount] = useState('0');
  const [openPollsData, setOpenPollsData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoadingChoiceItem, setIsLoadingChoiceItem] = useState(false);
  const [editObject, setEditObject] = useState(null);
  let [restaurantsDetails, setRestaurantDetails] = useState('');
  const items = restaurantsDetails;
  var today = new Date();
  const OpeningTime =
    today.getDay() == 0
      ? items?.SUNDAY_HOURS
        ? items?.SUNDAY_HOURS + ' (Today)'
        : null
      : today.getDay() == 1
      ? items?.MONDAY_HOURS
        ? items?.MONDAY_HOURS + ' (Today)'
        : null
      : today.getDay() == 2
      ? items?.TUESDAY_HOURS
        ? items?.TUESDAY_HOURS + ' (Today)'
        : null
      : today.getDay() == 3
      ? items?.WEDNESDAY_HOURS
        ? items?.WEDNESDAY_HOURS + ' (Today)'
        : null
      : today.getDay() == 4
      ? items?.THURSDAY_HOURS
        ? items?.THURSDAY_HOURS + ' (Today)'
        : null
      : today.getDay() == 5
      ? items?.FRIDAY_HOURS
        ? items?.FRIDAY_HOURS + ' (Today)'
        : null
      : today.getDay() == 6
      ? items?.SATURDAY_HOURS
        ? items?.SATURDAY_HOURS + ' (Today)'
        : null
      : null;

  useEffect(() => {
    CheckLogstatus();
    RestaurantData();
    fetchInprogressOrders();
    fetchInprogressReservation();
    fetchPoll();
  }, []);

  async function RestaurantData() {
    try {
      setIsLoading(true);
      const result = await API.graphql(
        graphqlOperation(GetRestMaster, {
          REST_ID: localStorage.getItem('Restaurantid'),
        })
      );
      ////debugger;
      const data = result?.data.getREST_MASTER;
      setRestaurantDetails(result?.data?.getREST_MASTER);
      ////debugger;
      localStorage.setItem('Restaurantname', data?.REST_NAME);
      localStorage.setItem('RestaurantImage', data?.REST_PIC_LOCATION);

      // SetRestaurantName(data?.REST_NAME);
      // SetRestaurantAddress(data?.ADDRESS);
      // SetRestaurantAbout('web Site:' + data?.WEBSITE +'Phone :'+data?.PHONE)

      SetResData(data);
      // console.log(aa);
      const a = resData.MONDAY_HOURS?.substr(
        resData.MONDAY_HOURS.indexOf(' ') + 1
      );
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchInprogressOrders() {
    //let REST_ID_saved = await API.getItem("REST_ID");
    // let REST_ID_saved = "ChIJr_tsTfv-wokRtsfW4MS1s7E";
    try {
      const result = await API.graphql(
        graphqlOperation(getOrdersByRest, {
          REST_ID: localStorage.getItem('Restaurantid'),
        })
      );
      console.log('fetch Inprogress Orders');
      // console.log(result);
      let neworders = result.data.getORDERS_BY_REST
        ?.filter(
          x =>
            x.ORDER_STATUS == 'Submitted' &&
            x.ORDER_TYPE.toUpperCase() == 'PICK-UP'
        )
        .sort((a, b) => b.ORDER_ID - a.ORDER_ID);
      if (neworders?.length > 0) {
        setNewOrderCount(neworders?.length);
      }
      let inProgressOrders = result.data.getORDERS_BY_REST
        ?.filter(
          x =>
            x.ORDER_STATUS == 'Accepted' &&
            x.ORDER_TYPE.toUpperCase() == 'PICK-UP'
        )
        .sort((a, b) => b.ORDER_ID - a.ORDER_ID);

      if (inProgressOrders?.length > 0) {
        setInProgressCount(inProgressOrders?.length);
      }
      let completedOrders = result.data.getORDERS_BY_REST
        ?.filter(
          x =>
            x.ORDER_STATUS == 'Completed' &&
            x.ORDER_TYPE.toUpperCase() == 'PICK-UP'
        )
        .sort((a, b) => b.ORDER_ID - a.ORDER_ID);
      if (completedOrders?.length > 0) {
        setcompletedCount(completedOrders?.length);
      }

      //setInProgressItem(res);
      setIsLoading(false);
      setRefreshing(false);
    } catch (err) {
      console.log(err);
    }
  }

  async function fetchInprogressReservation() {
    ////debugger;
    //let REST_ID_saved = await API.getItem("REST_ID");
    // let REST_ID_saved = "ChIJr_tsTfv-wokRtsfW4MS1s7E";
    try {
      const result = await API.graphql(
        graphqlOperation(getOrdersByRest, {
          REST_ID: localStorage.getItem('Restaurantid'),
        })
      );

      let newReserv = result.data.getORDERS_BY_REST
        ?.filter(
          x =>
            x.ORDER_STATUS == 'Submitted' &&
            x.ORDER_TYPE.toUpperCase() == 'DINE-IN'
        )
        .sort((a, b) => b.ORDER_ID - a.ORDER_ID);
      // console.log("newReserv");
      // console.log(newReserv.length);

      if (newReserv?.length > 0) {
        setNewReservCount(newReserv?.length);
      }
      let inProgressReserv = result.data.getORDERS_BY_REST
        ?.filter(
          x =>
            x.ORDER_STATUS == 'Accepted' &&
            x.ORDER_TYPE.toUpperCase() == 'DINE-IN'
        )
        .sort((a, b) => b.ORDER_ID - a.ORDER_ID);
      if (inProgressReserv?.length > 0) {
        setInProgressReservCount(inProgressReserv?.length);
      }
      let completedReserv = result.data.getORDERS_BY_REST
        ?.filter(
          x =>
            x.ORDER_STATUS == 'Completed' &&
            x.ORDER_TYPE.toUpperCase() == 'DINE-IN'
        )
        .sort((a, b) => b.ORDER_ID - a.ORDER_ID);
      if (completedReserv?.length > 0) {
        setcompletedReservCount(completedReserv?.length);
      }

      //setInProgressItem(res);
      setIsLoading(false);
      setRefreshing(false);
    } catch (err) {
      console.log(err);
    }
  }
  async function GenerateScript() {
    try {
    } catch (err) {
      console.log('err', err);
    }
  }

  async function CheckLogstatus() {
    try {
      let currentSession = await Auth.currentSession();
      const a = 10;
      if (currentSession.idToken.payload.email == 'undefined') {
        //setisLoggedIn(false);
        // isLoggedIn=false;
        history.push({
          pathname: '/',
          //state: { email: values.email, password: values.password }
        });
      } else {
        //  setisLoggedIn(true);
        // isLoggedIn=true;
      }
    } catch (err) {
      // setisLoggedIn(false);
      if (err == 'No current user') {
        history.push({
          pathname: '/',
          //state: { email: values.email, password: values.password }
        });
      }
      console.log('err', err);
    }
  }

  async function fetchPoll() {
    setIsLoading(true);
    try {
      const result = await API.graphql(
        graphqlOperation(getPollsByRest, {
          REST_ID: localStorage.getItem('Restaurantid'),
        })
      );
      // console.log("Polls-->");
      // { console.log(result) }
      // //debugger;
      let getOpenPoll = result.data.getPOLLS_BY_REST
        ?.filter(x => x.POLL_STATUS.toUpperCase() == 'ACTIVE')
        .sort((a, b) => b.POLL_ID - a.POLL_ID);
      setOpenPollsData(getOpenPoll.slice(0, 3));
      console.log(getOpenPoll);
      // //debugger;
    } catch (err) {
      console.log(err);
    }
  }

  async function signOut() {
    try {
      await Auth.signOut();
      // let currentSession = await Auth.currentSession();
      history.push({
        pathname: '/',
        // state: { email: values.email, password: values.password }
      });
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  function toggle() {
    setTogglebit(current => !current);
  }

  const OrdersData = [
    { name: 'newOrder', Orders: newOrderCount },
    { name: 'inProgress', Orders: inProgressCount },
    { name: 'completed', Orders: completedCount },
  ];

  const ReservationData = [
    { name: 'newOrder', Reservation: newReservCount },
    { name: 'inProgress', Reservation: inProgressReservCount },
    { name: 'completed', Reservation: completedReservCount },
  ];

  const COLORS = ['#0fb2ff', '#f56f10', '#034aa6'];
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <Box bg="#F6FAFF">
      <Helmet>
                    <title>Squash | Home </title>         {' '}
      </Helmet>
      <Header isAdmin={true} toggle={toggle} />
      <Box pt="85px" position="relative" h="100vh" overflow="hidden" d="flex">
        
        <Box
          zIndex="9"
          bg="#fff"
          position="absolute"
          top="0"
          pt="100px"
          left="0"
          h="100%"
         // w={togglebit ? '0px' : '200px'}
         // display={togglebit ? 'none' : 'block'}
          w={{
               base: togglebit ? '200px' : '0',
               sm: togglebit ? '200px' : '0',
               md: togglebit ? '200px' : '0',
               lg: togglebit ? '0' : '200px',
             }}
          d={{
               base: togglebit ? 'block' : 'none',
               sm: togglebit ? 'block' : 'none',
               md: togglebit ? 'block' : 'none',
               lg: togglebit ? 'none' : 'block',
             }}
        >
          <Sidebar current="Home" togglebit={togglebit} />
        </Box>
        <Box
          ml={togglebit ? '0px' : { base: '0', sm: '0', md: '0', lg: '200px' }}
          w="100%"
          p="20px"
          overflow="auto"
        >
          <Box className="card no-padding" mb={4}>
            <Box
              className="card-content restaurant-admin-header"
            >
              <Image src={resData?.REST_PIC_LOCATION} />
              <Box align-items=" center">
                <Box className="title-heading" paddingBottom="5px">
                  <h2>{resData?.REST_NAME}</h2>
                </Box>
                <Text fontSize="16px">
                  {resData?.CUISINE_TYPE?.split(',')?.map(csn => cuisineList?.find(itm => itm.CUISINE_ID == csn).CUISINE_TYPE_NAME).join(', ')} &nbsp; <strong>{resData.PRICE_LEVEL_DOLLARS}</strong>
                </Text>
                <Box display="flex" paddingTop="25px" alignItems="center" className='home-restaurant-details'>
                  <Box display="flex" alignItems="center">
                    <Box className="restaurant-dash-icon" mr={2}>
                      {' '}
                      <FontAwesomeIcon m="3px" icon={faMapMarkerAlt} />{' '}
                    </Box>
                    <Text fontSize="14px" textAlign="left">
                      {resData?.ADDRESS}
                    </Text>
                  </Box>

                  <Box display="flex" alignItems="center" paddingLeft="30px">
                    <Box className="restaurant-dash-icon" mr={2} textAlign="left">
                      <FontAwesomeIcon m="3px" icon={faClock} />{' '}
                    </Box>
                  <Box>
                  {items?.OPEN_YN === 'Y' ?
                    <Text fontSize="14px" color="#00B507">
                     Open Now
                       </Text> 
                       :
                       <Text fontSize="14px" color="#EF0707">
                       Closed
                       </Text>
                       }

                      {/* <Box color="#034AA6" display="block">
                        {items?.OPEN_YN === 'Y' ? 'Open Now' : 'Closed'}
                      </Box> */}
                      <Box>
                        {' '}
                        {OpeningTime
                          ? OpeningTime.substring(
                              OpeningTime.indexOf(' : ') + 1
                            )
                          : ''}
                      </Box>
                  </Box>
                    
                  </Box>
                </Box>
              </Box>
              <Box pl="50" pr="10px" ml="auto" alignSelf="center" className='generate-script'>
                <Button
                  className="primary-blue-btn"
                  onClick={() => {
                    ////debugger;
                    onOpen();
                    // GenerateScript();
                  }}
                >
                  <Box w="100%" d="flex" justifyContent="space-between">
                    <GenerateScriptPopup onClose={onClose} isOpen={isOpen} />
                    <Text fontSize="17px" pl="20px" pr="20px">
                      Generate Script
                    </Text>
                  </Box>
                </Button>
              </Box>
            </Box>
          </Box>

          <Box className='dashboard-box-main' w="100%" display="flex" mb={4}>

            <Box className="dashboard-box-1">
              <Box className="card no-padding">
                <Box className="card-content">

                    {/* <Box className="title-heading" textAlign="center"><h2>Vegan Vegetarian Cuisine</h2></Box> */}

                    <Box
                      className="title-heading"
                      width="100%"
                    >
                      <h2>Order</h2>
                    </Box>
                    <PieChart width={400} height={400}>
                      <Pie
                        data={OrdersData}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        outerRadius={150}
                        fill="#8884d8"
                        dataKey="Orders"
                      >
                        {OrdersData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                    </PieChart>
                    <Box className='chart-deatils'>
                      <p>
                        <span></span>
                        {OrdersData[0].Orders} New
                      </p>
                      <p>
                        <span></span>
                        {OrdersData[1].Orders} In Progress
                      </p>
                      <p>
                        <span></span>
                        {OrdersData[2].Orders} Completed
                      </p>
                    </Box>
                </Box>
              </Box>
            </Box>

            <Box className="dashboard-box-2">
              <Box className="card no-padding">
                <Box className="card-content">
                    <Box
                      className="title-heading"
                    >
                      <h2>Reservation</h2>
                    </Box>
                    <PieChart width={400} height={400}>
                      <Pie
                        data={ReservationData}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        outerRadius={150}
                        fill="#8884d8"
                        dataKey="Reservation"
                      >
                        {ReservationData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                    </PieChart>
                    <Box className='chart-deatils'>
                      <p>
                        <span></span>
                        {ReservationData[0].Reservation} New
                      </p>
                      <p>
                        <span></span>
                        {ReservationData[1].Reservation} In Progress
                      </p>
                      <p>
                        <span></span>
                        {ReservationData[2].Reservation} Completed
                      </p>
                  </Box>
                </Box>
              </Box>
            </Box>

          </Box>

          <Box className="card no-padding dashboard-box-main">
            <Box className="card-content">
            <Box
                      className="title-heading"
                    >
                      <h2>Polls</h2>
                    </Box>
                <Box className=" dashboard-poll-box">
                  <Accordion>
                    {openPollsData
                      ? openPollsData.map((obj, index) => (
                          <AccordionItem>
                            <Table>
                              <Tbody>
                                <Tr
                                //	key={CH_ID}
                                >
                                  <AccordionButton
                                    fontSize="20px"
                                    className="polls-accbtn"
                                  >
                                    <Td w="27%">{obj.POLL_NAME}</Td>
                                    <Td w="16%" className="menuscuzmobile poll-status">
                                      {obj.POLL_STATUS == 'Y'
                                        ? <Text color="#00B507" bg="#def3e7">Open</Text>
                                        : <Text color="#EF0707" bg="#f6e2e7">Closed</Text>}
                                    </Td>
                                    <Td w="17%" className="menuscuzmobile">
                                      {moment(
                                        new Date(
                                          obj.POLL_START_DATE_TIME * 1000
                                        )
                                      ).format('MM/DD/YYYY')}
                                    </Td>
                                    <Td w="18%" className="menuscuzmobile">
                                      {moment(
                                        new Date(obj.POLL_END_DATE_TIME * 1000)
                                      ).format('MM/DD/YYYY')}
                                    </Td>
                                    <Td w="15%" className="menuscuzmobile">
                                      0
                                    </Td>

                                    {/* <AccordionButton fontSize='20px' mt='10px' w='30px' float='right' > */}
                                    <AccordionIcon
                                      mt="10px"
                                      w="30px"
                                      float="right"
                                      className='home-polls-icon'
                                    />
                                  </AccordionButton>
                                </Tr>
                              </Tbody>
                            </Table>

                            <AccordionPanel pb={4}>
                              <Table>
                                <Thead>
                                  <Tr>
                                    <Th>Choice</Th>
                                    <Th>No of Respondents </Th>
                                    <Th>Percentage of Respondents</Th>
                                  </Tr>
                                </Thead>
                                {isLoadingChoiceItem ? (
                                  <Box className="rspinbox">
                                    {' '}
                                    <Spinner
                                      thickness="4px"
                                      speed="0.65s"
                                      emptyColor="gray.200"
                                      color="blue.500"
                                      size="xl"
                                    />
                                  </Box>
                                ) : (
                                  ''
                                )}

                                <Tbody>
                                  {obj?.relatedPollChoices.map((ob, ind) =>
                                    ob.POLL_CHOICE_VAL == '' ||
                                    ob.POLL_CHOICE_VAL == null ? (
                                      ''
                                    ) : (
                                      <Tr>
                                        <Td>{ob.POLL_CHOICE_VAL}</Td>
                                        <Td>{ob.POLL_CHOICE_VOTES}</Td>
                                        <Td>0%</Td>
                                      </Tr>
                                    )
                                  )}
                                </Tbody>
                              </Table>
                            </AccordionPanel>
                          </AccordionItem>
                        ))
                      : null}
                  </Accordion>
                </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Home;
