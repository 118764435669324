import { Box, Spinner,Image,Button,IconButton,Text } from '@chakra-ui/react';
import React, { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { useHistory } from 'react-router-dom';
import Header from '../../../components/Header';
import { Auth, Hub, API, graphqlOperation } from 'aws-amplify';
import { listUserCuisineTypeMasterFavOnlyY as ListUserCuisineTypeMasterFavOnlyY } from '../../../graphql/queries';
import { listUserHomeFeedSpecialsToday as ListUserHomeFeedSpecialsToday } from '../../../graphql/queries';
import { listSpotlightRestMasteRs as ListSpotlightRestMasteRs } from '../../../graphql/queries';
import { listUserHomeFeedSpecialsUpcoming as ListUserHomeFeedSpecialsUpcoming } from '../../../graphql/queries';
import { createUserLikeItem as CreateUserLikeItem } from '../../../graphql/mutations';
import { listUserPopularMenuItems as listUserPopularMenuItems } from '../../../graphql/queries';
import { FloatingButton, Item } from 'react-floating-button';
import CartImage from '../../../assets/images/search/cartImage.jpg'
import { createUserMenuItemBkmrkMaster as CreateUserMenuItemBkmrkMaster } from '../../../graphql/mutations';
import { listBkmrkRestMasteRs as ListBkmrkRestMasteRs } from '../../../graphql/queries';
import { onCreateUserLikeItem } from '../../../graphql/subscriptions';
import {updateMenuItemMaster as UpdateMenuItemMaster } from '../../../graphql/mutations'
import Quickfilter from './Quickfilter';
import HorizontalScroll from './HorizontalScroll';
import UpcomingSpecials from './UpcomingSpecials';
import UserSidebar from '../../../components/UserSidebar';
import moment from 'moment';
//import { instanceOf } from 'prop-types';
//import { withCookies, Cookies } from 'react-cookie';

import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';

const Home = () => {
  const history = useHistory();
  const [togglebit, setTogglebit] = useState(true);
  const [item, setItem] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const[cartData,setCartData] = useState([]);
  let [cuisinesList, setCuisineList] = useState([]);
  let [filterCuisines, setFilterCuisines] = useState([]);

  // const [timevalue1,settimeValue1]=useState(moment().format("h:mm a")); 
  var now = moment();
  let [todaySpecialsList, updateTodaySpecials] = useState([]);
  let [todaySpecialsListAll, updateTodaySpecialsAll] = useState([]);

  let [restaurantList, setRestaurantList] = useState([]);
  let [restaurantListAll, setRestaurantListAll] = useState([]);

  let [upcomingSpecialsList, updateUpcomingSpecials] = useState([]);
  let [upcomingSpecialsListAll, updateUpcomingSpecialsAll] = useState([]);

  let [favoriteRestaurantList, setFavoriteRestaurantList] = useState([]);
  let [favoriteRestaurantListAll, setFavoriteRestaurantListAll] = useState([]);
  
  let [popularDishesList, setPopularDishesList] = useState([]);
  let subscription = [];
   const [dd,setDate]= useState("");
 
  // const propTypes = {
  //     cookies: instanceOf(Cookies).isRequired
  //   };

  useEffect(() => {
    listCuisines();
    listTodaySpecials();
    listRestaurants();
    listUpcomingSpecials();
    listFavRestaurants();
    popularItemsList();

    

    localStorage.setItem('specialItem', '');
    // console.log(timevalue1)

    //    var json_cartitems =cookies.get('cartitems')
   //debugger;
   let today = moment(new Date(), "YYYY-MM-DD");
    let formattedtoday = moment(today, "YYYY-MM-DD").format("YYYY-MM-DD");

    var json_cartitems = Cookies.get('cartitems');
    if (json_cartitems) {
      const items = JSON.parse(json_cartitems);
      setCartData(items);
      
      const cartlen = items.filter(
        ob => ob.cart_created_date >= formattedtoday
      );
      console.log(cartlen)
      if(cartlen==0){
        
        Cookies.remove('cartitems');
      }   
      //const a = 10;
      console.log("cart",items);
    }
    
  }, []);

   



//  async function setCart(){
//   var oldData1 = Cookies.get('cartitems');

    
//   let cart = [];
//     if(oldData1 != undefined){
//        cart = JSON.parse(oldData1);
//     }
//   setCartData(cart);
//  }
  async function listCuisines() {
   
    setIsLoading(true);
   
   
    try {
      const cuisines = await API.graphql(
        graphqlOperation(ListUserCuisineTypeMasterFavOnlyY)
      );
      setCuisineList(
        cuisines.data.listUSER_CUISINE_TYPE_MASTER_FAV_ONLY_Y?.map(elem => ({
          CUISINE_ID: elem.CUISINE_ID,
          CUISINE_TYPE_NAME: elem.CUISINE_TYPE_NAME,
        }))
      );
    } catch (err) {
      const d = err;
    }

    setIsLoading(false);
  }

  async function listTodaySpecials() {
    const todaySpecials = await API.graphql(
      graphqlOperation(ListUserHomeFeedSpecialsToday)
    );
    //debugger;
    updateTodaySpecialsAll(
      todaySpecials.data.listUSER_HOME_FEED_SPECIALS_TODAY
    );
    // const w=todaySpecials.data.listUSER_HOME_FEED_SPECIALS_TODAY.filter(ob=>ob.CUISINE_TYPE=='C1')
    //  updateTodaySpecials(w);
    updateTodaySpecials(todaySpecials.data.listUSER_HOME_FEED_SPECIALS_TODAY);
    ////debugger;

    // if (subscription.length > 0) {
    //   subscription.forEach(element => {
    //     element.unsubscribe();
    //   });
    //   subscription = [];
    // }

    // todaySpecials.data.listUSER_HOME_FEED_SPECIALS_TODAY.forEach(element => {
    //   subscription.push(API.graphql(graphqlOperation(onCreateUserLikeItem, { MENU_IT_ID: element.MENU_IT_ID }))
    //     .subscribe({
    //       next: subonCreateUserLike => {
    //         updateTodaysList(element.MENU_IT_ID, '', '', subonCreateUserLike.value.data.onCreateUSER_LIKE_ITEM)
    //       },
    //       error: error => console.warn(error)
    //     }));
    // });
  }

  function updateTodaysList(
    MENU_IT_ID,
    USER_FAVE_YN,
    USER_BOOKMARK_YN,
    LIKE_COUNT
  ) {
    updateTodaySpecials(oldData => {
      return [
        ...oldData.map(item => {
          if (MENU_IT_ID === item.MENU_IT_ID) {
            return {
              ...item,
              USER_LIKE_YN: USER_FAVE_YN,
              relatedMenuItemLikes:
                LIKE_COUNT != '' ? LIKE_COUNT : item.relatedMenuItemLikes,
              USER_BOOKMARK_YN: USER_BOOKMARK_YN,
            };
          }
          return item;
        }),
      ];
    });
    updateTodaySpecialsAll(oldData => {
      return [
        ...oldData.map(item => {
          if (MENU_IT_ID === item.MENU_IT_ID) {
            return {
              ...item,
              USER_LIKE_YN: USER_FAVE_YN,
              relatedMenuItemLikes:
                LIKE_COUNT != '' ? LIKE_COUNT : item.relatedMenuItemLikes,
              USER_BOOKMARK_YN: USER_BOOKMARK_YN,
            };
          }
          return item;
        }),
      ];
    });
  }

  async function listRestaurants() {
    ////debugger;
    const restaurants = await API.graphql(
      graphqlOperation(ListSpotlightRestMasteRs)
    );
    console.log(restaurants)
    ////debugger;
    setRestaurantList(restaurants.data.listSPOTLIGHT_REST_MASTERs);
    setRestaurantListAll(restaurants.data.listSPOTLIGHT_REST_MASTERs);
  }

  async function listFavRestaurants() {
    ////debugger
    const restaurants = await API.graphql(
      graphqlOperation(ListBkmrkRestMasteRs)
    );
    ////debugger;
    setFavoriteRestaurantList(restaurants.data.listBKMRK_REST_MASTERs);
    setFavoriteRestaurantListAll(restaurants.data.listBKMRK_REST_MASTERs);
  }

  async function mutateUpcomingSpecialFave(MENU_IT_ID, USER_FAVE_YN) {
    const inputData = {
      MENU_IT_ID: MENU_IT_ID,
      USER_LIKE_YN: USER_FAVE_YN,
    };
    try {
      API.graphql(
        graphqlOperation(CreateUserLikeItem, {
          createUSER_LIKE_ITEMInput: inputData,
        })
      );
    } catch (err) {
      console.log('error: ', err);
    }
  }

  async function popularItemsList() {

    const popularItems = await API.graphql(
      graphqlOperation(listUserPopularMenuItems)
    );
   
    console.log("popularItemsList-------------=======================>");
    console.log(popularItems?.data?.listUSER_POPULAR_MENU_ITEMS);
    console.log("popularItemsList-------------===endddddddddddddddddd====================>");
    setPopularDishesList(popularItems?.data?.listUSER_POPULAR_MENU_ITEMS);
    
    ////debugger;
  }

 
  


  function updateUpcomingList(
    MENU_IT_ID,
    USER_LIKE_YN,
    USER_BOOKMARK_YN,
    likeCountsObj
  ) {
    updateUpcomingSpecials(oldData => {
      return [
        ...oldData.map(item => {
          if (MENU_IT_ID === item.MENU_IT_ID) {
            return {
              ...item,
              USER_LIKE_YN:
                USER_LIKE_YN != '' ? USER_LIKE_YN : item.USER_LIKE_YN,
              relatedMenuItemLikes:
                likeCountsObj != '' ? likeCountsObj : item.relatedMenuItemLikes,
              USER_BOOKMARK_YN:
                USER_BOOKMARK_YN != ''
                  ? USER_BOOKMARK_YN
                  : item.USER_BOOKMARK_YN,
            };
          }
          return item;
        }),
      ];
    });
    updateUpcomingSpecialsAll(oldData => {
      return [
        ...oldData.map(item => {
          if (MENU_IT_ID === item.MENU_IT_ID) {
            return {
              ...item,
              USER_LIKE_YN:
                USER_LIKE_YN != '' ? USER_LIKE_YN : item.USER_LIKE_YN,
              relatedMenuItemLikes:
                likeCountsObj != '' ? likeCountsObj : item.relatedMenuItemLikes,
              USER_BOOKMARK_YN:
                USER_BOOKMARK_YN != ''
                  ? USER_BOOKMARK_YN
                  : item.USER_BOOKMARK_YN,
            };
          }
          return item;
        }),
      ];
    });
  }

  const onUpcomingSpecialHeart = (
    MENU_IT_ID,
    USER_LIKE_YN,
    USER_BOOKMARK_YN,
    LIKE_COUNT
  ) => {
    if (USER_LIKE_YN === 'Y') {
      USER_LIKE_YN = 'N';
      LIKE_COUNT--;
    } else {
      USER_LIKE_YN = 'Y';
      LIKE_COUNT++;
    }
    let likeObj = {
      MENU_IT_ID: MENU_IT_ID,
      LIKE_COUNT: LIKE_COUNT,
    };
    updateUpcomingList(MENU_IT_ID, USER_LIKE_YN, USER_BOOKMARK_YN, likeObj);
    updateTodaysList(MENU_IT_ID, USER_LIKE_YN, USER_BOOKMARK_YN, likeObj);
    mutateUpcomingSpecialFave(MENU_IT_ID, USER_LIKE_YN);
  };

  const onUpcomingSpecialBookMark = (
    MENU_IT_ID,
    USER_BOOKMARK_YN,
    USER_LIKE_YN
  ) => {
    USER_BOOKMARK_YN = USER_BOOKMARK_YN === 'Y' ? 'N' : 'Y';
    updateUpcomingList(MENU_IT_ID, USER_LIKE_YN, USER_BOOKMARK_YN, '');
    updateTodaysList(MENU_IT_ID, USER_LIKE_YN, USER_BOOKMARK_YN, '');
    mutateUpcomingSpecialBookMark(MENU_IT_ID, USER_BOOKMARK_YN);
  };

  async function mutateUpcomingSpecialBookMark(MENU_IT_ID, USER_BOOKMARK_YN) {
    const inputData = {
      MENU_IT_ID: MENU_IT_ID,
      USER_BOOKMARK_YN: USER_BOOKMARK_YN,
    };

    try {
      API.graphql(
        graphqlOperation(CreateUserMenuItemBkmrkMaster, {
          createUSER_MENU_ITEM_BKMRK_MASTERInput: inputData,
        })
      );
    } catch (err) {
      console.log('error: ', err);
    }
  }
  async function listUpcomingSpecials() {
    const upcomingSpecials = await API.graphql(
      graphqlOperation(ListUserHomeFeedSpecialsUpcoming)
    );
    //debugger;
    updateUpcomingSpecialsAll(
      upcomingSpecials.data.listUSER_HOME_FEED_SPECIALS_UPCOMING
    );
    updateUpcomingSpecials(
      upcomingSpecials.data.listUSER_HOME_FEED_SPECIALS_UPCOMING
    );

    if (subscription.length > 0) {
      for (const item of subscription) {
        item.unsubscribe();
      }
      subscription = [];
    }

    upcomingSpecials.data.listUSER_HOME_FEED_SPECIALS_UPCOMING?.forEach(
      element => {
        subscription.push(
          API.graphql(
            graphqlOperation(onCreateUserLikeItem, {
              MENU_IT_ID: element.MENU_IT_ID,
            })
          ).subscribe({
            next: subonCreateUserLike => {
              updateUpcomingList(
                element.MENU_IT_ID,
                '',
                '',
                subonCreateUserLike.value.data.onCreateUSER_LIKE_ITEM
              );
            },
            error: error => console.warn(error),
          })
        );
      }
    );
  }

  async function CheckLogstatus() {
    try {
      let currentSession = await Auth.currentSession();
      const a = 10;
      if (currentSession.idToken.payload.email == 'undefined') {
        //setisLoggedIn(false);
        // isLoggedIn=false;
        history.push({
          pathname: '/',
          //state: { email: values.email, password: values.password }
        });
      } else {
        //  setisLoggedIn(true);
        // isLoggedIn=true;
      }
    } catch (err) {
      // setisLoggedIn(false);
      if (err == 'No current user') {
        history.push({
          pathname: '/',
          //state: { email: values.email, password: values.password }
        });
      }
      console.log('err', err);
    }
  }
 

  
  async function signOut() {
    try {
      await Auth.signOut();
      Cookies.remove('cartitems');
      // let currentSession = await Auth.currentSession();
      history.push({
        pathname: '/',
        // state: { email: values.email, password: values.password }
      });
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  function toggle() {
    setTogglebit(current => !current);
  }

  function filterList(CUISINE_ID) {
    const newFilterCuisines = [...filterCuisines];
    if (newFilterCuisines.includes(CUISINE_ID)) {
      let id = newFilterCuisines.indexOf(CUISINE_ID);
      newFilterCuisines.splice(id, 1);
    } else {
      newFilterCuisines.push(CUISINE_ID);
    }
    setFilterCuisines(newFilterCuisines);

    if (newFilterCuisines.length == 0) {
      updateTodaySpecials(todaySpecialsListAll);

      setRestaurantList(restaurantListAll);
      //   updateFavoriteRestaurants(todayFavoriteRestaurantsAll)
      updateUpcomingSpecials(upcomingSpecialsListAll);
      return;
    }
    let filterArray = todaySpecialsListAll.filter(data =>
      newFilterCuisines.includes(data.CUISINE_TYPE)
    );
    filterArray.length > 0
      ? updateTodaySpecials(filterArray)
      : updateTodaySpecials([]);

    filterArray = restaurantListAll.filter(data =>
      newFilterCuisines.includes(data.CUISINE_TYPE)
    );

    filterArray.length > 0
      ? setRestaurantList(filterArray)
      : setRestaurantList([]);

    // filterArray = todayFavoriteRestaurantsAll.filter(
    //   data => newFilterCuisines.includes(data.CUISINE_TYPE)
    // )
    // filterArray.length > 0 ? updateFavoriteRestaurants(filterArray) : updateFavoriteRestaurants(false)

    filterArray = upcomingSpecialsListAll.filter(data =>
      newFilterCuisines.includes(data.CUISINE_TYPE)
    );
    filterArray.length > 0
      ? updateUpcomingSpecials(filterArray)
      : updateUpcomingSpecials([]);
  }

  return (
    <Box bg="#f9f9f9">
      <Helmet>
        <title>Squash | Home</title>
      </Helmet>

      <Header isAdmin={false} toggle={toggle}  />
      <Box pt="70px" position="relative" h="100vh" overflow="hidden" d="flex">
        <Box
         // className="user-sidebar"
          zIndex="999"
          bg="#ffffff"
          position="absolute"
          top="0"
          pt="100px"
          left="0"
          h="100%"
          w={togglebit ? '0px' : '200px'}
          display={togglebit ? 'none' : 'block'}
        >
          <UserSidebar />
        </Box>
        {isLoading ? (
          <Box className="rspinbox">
            {' '}
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Box>
        ) : (
          <Box
            className=" "
            //   ml={(togglebit ? "0px" : { base: "0", sm: "0", md: "0", lg: '200px' })}
            w="100%"
            p="20px"
            overflow="auto"
          >

<Box className='user-home-filter' mb={0}>
<Box>
                  {cuisinesList?.length > 0 ? (
                    <Quickfilter
                      cuisinesList={cuisinesList}
                      filterList={filterList}
                      filterCuisines={filterCuisines}
                    />
                  ) : (
                    ''
                  )}
                </Box>
  </Box>

            <Box className="card content-card">
              {/* <Box className="card-header">
                            <Box className="card-header-inner">
                                <Box className="title-heading"><h2>User Home</h2></Box>
                            </Box>
                        </Box> */}
              <Box className=" table-padding">
              
             
                <Box>
                  {todaySpecialsList?.length == 0 ? (
                    ''
                  ) : (
                    <HorizontalScroll
                      heading="Today's Specials"
                      todaySpecialsList={todaySpecialsList}
                      onUpcomingSpecialHeart={onUpcomingSpecialHeart}
                      onUpcomingSpecialBookMark={onUpcomingSpecialBookMark}
                    />
                  )}
                </Box>
                <hr className='user-divider'></hr>
                <Box>
                  {restaurantList?.length == 0 ? (
                    ''
                  ) : (
                    <HorizontalScroll
                      heading="Spotlight Restaurants"
                      restaurantList={restaurantList}
                    />
                  )}
                </Box>
                <hr className='user-divider'></hr>
                <Box>
                  {favoriteRestaurantList?.length == 0 ? (
                    ''
                  ) : (
                    <HorizontalScroll
                      heading="Favorite restaurants"
                      favoriteRestaurantList={favoriteRestaurantList}
                    />
                  )}
                </Box>
                <hr className='user-divider'></hr>
                <Box>
                  {popularDishesList?.length == 0 ? (
                    ''
                  ) : (
                    <HorizontalScroll
                      heading="Popular Items"
                      popularDishesList={popularDishesList}
                    />
                  )}
                </Box>
                <hr className='user-divider'></hr>
                <Box>
                  {upcomingSpecialsList?.length == 0 ? (
                    ''
                  ) : (
                    <UpcomingSpecials
                      upcomingSpecialsList={upcomingSpecialsList}
                      onUpcomingSpecialHeart={onUpcomingSpecialHeart}
                      onUpcomingSpecialBookMark={onUpcomingSpecialBookMark}
                    />
                  )}
                </Box>
                <Box className="fixed-position">
                  {/* <Image src={CartImage} width={100} height={100} onClick={}/> */}
                  {(cartData.length == 0) ? (
                    ''
                    ) : ( 
                  <Button
                        w='auto'
                        minWidth={{ base: '30px', md: '34px' }}
                        h={{ base: '30px', md: '34px' }}
                        // h='auto'
                        ml='15px'
                        p='0px'
                        background='#001d56'
                        color='#ffffff'
                        as={IconButton}
                        aria-label="Options"
                        icon={<FontAwesomeIcon fontSize='22px' icon={faShoppingCart} />}
                        variant="outline"
                        onClick={() => {
                          ////debugger;
                         

                          
                            history.push(
                              {
                                //pathname: `/Admin/${localStorage.getItem('Currentpage')}`,
                                pathname: '/RestaurantMenu/'+ cartData[0]?.restId,
                                    //  state: { email:props.userinfo?.email, password: props.userinfo?.pass }
                                  }
                                );
                          
                        }}
                    />)}
                                     
                </Box>

              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default Home;
