import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Spinner,
  Image,
  Spacer,
  Text,
  FormControl,
  InputGroup,
  InputLeftElement,
  Link,
} from '@chakra-ui/react';
import { Link as RouteLink } from 'react-router-dom';
import { EmailIcon, UnlockIcon } from '@chakra-ui/icons';
import React, { useRef, useState, useEffect } from 'react';
import Logo from '../../assets/images/Squash_FINALB.png';
import BackgroundImage from '../../assets/images/login-bg.jpg';

import style from '../../components/commonStyle.css';
import { useToast, useDisclosure } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import { Auth, Hub, API, graphqlOperation } from 'aws-amplify';
import * as yup from 'yup';
import { Formik, Form, Field, useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { listRestMasteRs as ListRestMasteRs } from '../../graphql/queries';
import { getUserProfileGroup as GetUserProfileGroup } from '../../graphql/queries';

import RestaurantSelectPopup from '../../components/RestaurantSelectPopup';
import { Helmet } from 'react-helmet';
import Signup from '../../components/Signup';
import OneSignal from 'react-onesignal';
import {
  createOnesignalUser as createOnesignalUser,
  updateRestOwnerContact,
} from '../../graphql/mutations';

const loginValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter valid email')
    .max(50, 'Email must be 50 characters or less')
    .required('Email address is required'),
  password: yup

    .string()
    .min(8, ({ min }) => `Password must be at least ${min} characters`)
    .max(30, 'Password must be 30 characters or less')
    .required('Password is required'),
});

function Login() {
  const password = useRef(null);
  const history = useHistory();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [resData, SetResData] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: LoginisOpen,
    onOpen: LoginonOpen,
    onClose: LoginonClose,
  } = useDisclosure();

  let userinfo = null;

  useEffect(() => {
    localStorage.setItem('Restaurantname', null);
    localStorage.setItem('Restaurantid', null);
    localStorage.setItem('RestaurantImage', null);
    

    localStorage.setItem('Currentpage', 'Home');
    // Hub.listen('auth', async data => {
    //   switch (data.payload.event) {
    //     case 'signIn':
    //       alert('signin here');

    //       break;

    //     case 'signOut':
    //       alert('signout here');

    //       break;

    //     default:
    //       break;
    //   }
    // });
    // return () => {
    //   Hub.remove('auth');
    // };
  }, []);
  // useEffect(() => {
  //   OneSignal.init({
  //     appId: 'bd1efceb-e5ea-46c9-b670-51f6914ab027',
  //   });
  //   ////debugger;
  // }, []);

  function modalClose() {
    console.log('a');
    setIsLoading(false);
    onClose();
  }

  //Amplify signin method
  async function signIn(values) {
    try {
      setIsLoading(true);
      ////debugger;
      await Auth.signIn(values.email, values.password);

      const creds = await Auth.currentCredentials();

      GetRestaurantList();
      let currentSession = null;
      try {
        const result = await API.graphql(graphqlOperation(GetUserProfileGroup));
        // console.log(result);

        const id = result.data?.getUserProfileGroup[0]?.REST_ID;
        const priority =
          result.data?.getUserProfileGroup[0]?.REST_OWNER_GROUP_PRIORITY;
        ////debugger;

        // const deviceState = await OneSignal.getUserId();
        ////debugger;
        // if (deviceState != null) {
          let Email = values.email;
          // let inputData = { USER_EMAIL: Email, PLAYER_ID: deviceState };
          // let adminInputData = { REST_ID: id, PLAYER_ID: deviceState };
          if (priority == '20' || priority == '10') {
            // await API.graphql(
            //   graphqlOperation(updateRestOwnerContact, {
            //     REST_OWNER_CONTACTInput: adminInputData,
            //   })
            // );
          } else if (priority == '0') {
            // await API.graphql(
            //   graphqlOperation(createOnesignalUser, {
            //     ONESIGNAL_USERInput: inputData,
            //   })
            // );
          } else {
            alert('Please Login With valid Credentials');
          }

          ////debugger;
        // } else {
        //   console.log('No Player ID retreived');
        // }

        // const userid = await OneSignal.getUserId();
        ////debugger;

        if (id === '0' && priority === 0) {
          history.push({
            //pathname: `/Admin/${localStorage.getItem('Currentpage')}`,
            pathname: '/Home',
            //  state: { email:props.userinfo?.email, password: props.userinfo?.pass }
          });
        } else if (id === '1' && priority === 1) {
        } else if (priority === 10) {
          //localStorage.setItem('Restaurantname',restaurantName);
          localStorage.setItem('Restaurantid', id);

          window.location.href = 'Admin/Home';
        } else if (priority === 20) {
          // localStorage.setItem('Restaurantname',restaurantName);
          localStorage.setItem('Restaurantid', id);
          // history.push(
          //   {
          //    // pathname: `/Admin/${localStorage.getItem('Currentpage')}`,
          //     pathname:'Admin/RestaurantDetails'
          //   }
          // );
          window.location.href = 'Admin/Home';
          // history.push(
          //   {

          //     pathname: '/Home',

          //   }
          // );
        }
      } catch (err) {
        console.log('err', err);
      }
    } catch (error) {
      if (error.code == 'UserNotConfirmedException') {
        history.push({
          pathname: '/ConfirmSignUp',
          state: { email: values.email, password: values.password },
        });

        console.log(' Error signing in...', error);
      } else {
        //   try {
        //  let  currentSession = await Auth.currentSession();
        //  setUsername(currentSession.idToken.payload.email)
        //  } catch(err) {
        //    console.log('err',err);

        //   }

        showValidationMsg(error.message, 'error');
        console.log(' Error signing in...', error);
      }
      setIsLoading(false);
    }
  }

  async function GetRestaurantList() {
    try {
      setIsLoading(true);

      const result = await API.graphql(graphqlOperation(ListRestMasteRs));
      console.log(result);

      const data = result?.data.listREST_MASTERs.map(
        ({ REST_ID, REST_NAME }) => ({ REST_ID, REST_NAME })
      );
      localStorage.setItem('RestaurantNames', JSON.stringify(data));
      SetResData(data);
      //setIsLoading(false)
    } catch (err) {
      console.log(err);
    }
  }

  //Show toast for validation message method
  function showValidationMsg(msg, st) {
    ////debugger;
    toast({
      title: '',
      description: msg,
      status: st,
      duration: 9000,
      isClosable: true,
      position: 'top',
    });
  }
  function loginhandle() {
    const a = 10;
  }

  return (
    <Flex className="LoginWrapper" h="100vh" w="100%">
      <Signup
        loginhandle={loginhandle}
        open={LoginisOpen}
        close={LoginonClose}
      />
      <Helmet>
        <title>Squash | Login</title>
      </Helmet>
      <Flex
        justifyContent="center"
        alignItems="center"
        direction={{ base: 'column', md: 'column', xl: 'row', lg: 'row' }}
        w="100%"
        bg="white"
        maxWidth="1300"
        marginLeft="auto"
        marginRight="auto"
        borderRadius="30px"
        boxShadow="0px 5px 50px rgba(3, 74, 166, 0.05);"
      >
        <Box
          w={{base:'100%', sm:'100%', md:'100%', lg:'60%'}}
          padding={{base:'30px', sm:'30px', md:'30px', lg:'0'}}
          className="LoginLeft"
          d='flex'
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          flex="1"
        >
          <Image maxWidth="150px" src={Logo} />
          <Flex width="100%" align="center" justifyContent="center">
            <Box p={2} w={['100%', '80%', '60%', '60%']}>
              <Box textAlign="center">
                <Heading className="LoginTitle">Login to Your Account</Heading>
              </Box>
              {resData.length > 0 ? (
                <RestaurantSelectPopup
                  fromlogin={true}
                  userinfo={userinfo}
                  resData={resData}
                  open={isOpen}
                  modalClose={modalClose}
                />
              ) : null}
              <Box pt="20px" my={4} textAlign="left">
                <Formik
                  initialValues={{
                    email: '',
                    password: '',
                  }}
                  validationSchema={loginValidationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    console.log(values);
                    signIn(values);
                    setSubmitting(false);
                  }}
                >
                  {formik => {
                    return (
                      <form onSubmit={formik.handleSubmit}>
                        <FormControl>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              children={<EmailIcon color="gray.300" />}
                            />
                            <Input
                              fontSize={['13px', '13px', '18px', '18px']}
                              type="email"
                              placeholder="Email"
                              onChange={formik.handleChange('email')}
                              onBlur={formik.handleBlur('email')}
                              value={formik.values.email}
                              error={formik.errors.email}
                              touched={formik.touched.email}
                            />
                          </InputGroup>
                        </FormControl>
                        {formik.errors.email && formik.touched.email && (
                          <Text className="validationColor">
                            {formik.errors.email}
                          </Text>
                        )}
                        <FormControl mt={6}>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              children={<UnlockIcon color="gray.300" />}
                            />
                            <Input
                              fontSize={['13px', '13px', '18px', '18px']}
                              ref={password}
                              type="password"
                              placeholder="Password"
                              onChange={formik.handleChange('password')}
                              value={formik.values.password}
                              error={formik.errors.password}
                              onBlur={formik.handleBlur('password')}
                              touched={formik.touched.password}
                            />
                          </InputGroup>
                        </FormControl>
                        {formik.errors.password && formik.touched.password && (
                          <Text className="validationColor">
                            {' '}
                            {formik.errors.password}
                          </Text>
                        )}
                        <Box>
                          <Flex mt={4} display={isLoading ? 'flex' : 'none'}>
                            <Box w="45%"></Box>
                            <Spacer />
                            <Box w="55%">
                              <Spinner
                                thickness="4px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="blue.500"
                                size="xl"
                              />
                            </Box>
                          </Flex>

                          <Flex
                            justifyContent="space-between"
                            alignItems={{base:'flex-start', sm:'center'}}
                            mt={4}
                            flexDirection={{base:'column', sm:'row'}}
                          >
                            <RouteLink
                              color="black"
                              className="ForgotPasword"
                              to="/ForgotPassword"
                            >
                              Forgot Password?
                            </RouteLink>
                            <Button
                              display={isLoading ? 'none' : 'block'}
                              className="primary-button"
                              bg="#034AA6"
                              width="150px"
                              type="submit"
                              ml="auto"
                              mt={{base:'3', sm:'0'}}
                            >
                              Login
                            </Button>
                          </Flex>
                        </Box>
                      </form>
                    );
                  }}
                </Formik>
              </Box>
            </Box>
          </Flex>
        </Box>

        {/* <Box
          w="70%"
          justifyContent="center"
          alignItems="flex-end"
          d={{ base: 'flex', md: 'flex', xl: 'none', lg: 'none' }}
          flex=".5"
          p={['20px', '40px', '40px', '0px']}
        >
          <Image src={Logo} />
        </Box> */}

        <Box
            w={{base:'100%', sm:'100%', md:'100%', lg:'40%'}}
            padding={{base:'30px', sm:'30px', md:'30px', lg:'0'}}
          d="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          className="LoginRight"
        >
          <Heading className="LoginTitle" mb={4}>
            New Here?
          </Heading>
          <Text fontSize={['sm', 'md', 'lg', 'lg']}>
            <Button
              color="blue"
              onClick={() => {
                LoginonOpen();
              }}
              //LoginonOpen();
              className="clickMe"
            >
              Sign Up
            </Button>
          </Text>
          {/* <Flex width="100%" align="center" justifyContent="center">
            <Box p={2} w={['80%', '80%', '60%', '60%']}>
              <Box textAlign="center">
                <Heading fontSize={['sm', 'md', 'lg', 'xl']}>
                  Login to Your Account
                </Heading>
              </Box>
              {resData.length > 0 ? (
                <RestaurantSelectPopup
                  fromlogin={true}
                  userinfo={userinfo}
                  resData={resData}
                  open={isOpen}
                  modalClose={modalClose}
                />
              ) : null}
              <Box pt="20px" my={4} textAlign="left">
                <Formik
                  initialValues={{
                    email: '',
                    password: '',
                  }}
                  validationSchema={loginValidationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    console.log(values);
                    signIn(values);
                    setSubmitting(false);
                  }}
                >
                  {formik => {
                    return (
                      <form onSubmit={formik.handleSubmit}>
                        <FormControl>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              children={<EmailIcon color="gray.300" />}
                            />
                            <Input
                              fontSize={['13px', '13px', '18px', '18px']}
                              type="email"
                              placeholder="Email"
                              onChange={formik.handleChange('email')}
                              onBlur={formik.handleBlur('email')}
                              value={formik.values.email}
                              error={formik.errors.email}
                              touched={formik.touched.email}
                            />
                          </InputGroup>
                        </FormControl>
                        {formik.errors.email && formik.touched.email && (
                          <Text className="validationColor">
                            {formik.errors.email}
                          </Text>
                        )}
                        <FormControl mt={6}>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              children={<UnlockIcon color="gray.300" />}
                            />
                            <Input
                              fontSize={['13px', '13px', '18px', '18px']}
                              ref={password}
                              type="password"
                              placeholder="Password"
                              onChange={formik.handleChange('password')}
                              value={formik.values.password}
                              error={formik.errors.password}
                              onBlur={formik.handleBlur('password')}
                              touched={formik.touched.password}
                            />
                          </InputGroup>
                        </FormControl>
                        {formik.errors.password && formik.touched.password && (
                          <Text className="validationColor">
                            {' '}
                            {formik.errors.password}
                          </Text>
                        )}
                        <Box>
                          <Flex mt={4} display={isLoading ? 'flex' : 'none'}>
                            <Box w="45%"></Box>
                            <Spacer />
                            <Box w="55%">
                              <Spinner
                                thickness="4px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="blue.500"
                                size="xl"
                              />
                            </Box>
                          </Flex>

                          <Button
                            display={isLoading ? 'none' : 'block'}
                            className="primary-button"
                            bg="#0554F2"
                            width="full"
                            mt={4}
                            type="submit"
                          >
                            LOGIN
                          </Button>
                        </Box>
                      </form>
                    );
                  }}
                </Formik>
              </Box>
              <Box textAlign="center">
                <Text fontSize={['sm', 'md', 'lg', 'lg']}>
                  Forgot Password?{' '}
                  <RouteLink className="clickMe" to="/ForgotPassword">
                    Click here
                  </RouteLink>
                </Text>
                <Text fontSize={['sm', 'md', 'lg', 'lg']}>
                  <Link
                    color="blue"
                    onClick={() => {
                      LoginonOpen();
                    }}
                    //LoginonOpen();
                    className="clickMe"
                  >
                    Signup?
                  </Link>
                </Text>
              </Box>
            </Box>
          </Flex> */}
        </Box>
      </Flex>
    </Flex>
  );
}

export default Login;
