import React, { Component, useEffect, useState } from "react";
import { Router, Switch, Route } from "react-router-dom";
import ConfirmSignUp from "./screens/ConfirmSignUp/ConfirmSignUp";
import Login from "./screens/Login/login";
import ForgotPassword from './screens/ForgotPassword/ForgotPassword';
import ResetPassword from './screens/ResetPassword/ResetPassword';
import Orders from './screens/AuthScreens/Orders/Orders';
import Home from './screens/AuthScreens/Home/Home'
import history from './History';
import PrivateRoute from './components/PrivateRoute'
//import AddSpecials from './screens/AuthScreens/Add Specials/AddSpecials'
import Specials from './screens/AuthScreens/Specials/Specials'
import RestaurantDetails from './screens/AuthScreens/RestaurantDetails/RestaurantDetails'
import Events from './screens/AuthScreens/Events/Events'
import Menu from './screens/AuthScreens/Menu/Menu'
import Customization from './screens/AuthScreens/Customization/Customization'
import Polls from './screens/AuthScreens/Polls/Polls'
import RestaurantMenu from './screens/RestaurantMenu/RestaurantMenu'
import Administration from './screens/AuthScreens/Administration/Administration'
import RewardPoint from "./components/RewardPoint";
import ChangePassword from "./components/ChangePassword";
import OrderHistory from "./components/OrderHistory";
import AdminOrderHistory from "./components/AdminOrderHistory"
import UserProfile from "./components/UserProfile"
import Favorite from "./components/Favorite";
import { useToast } from "@chakra-ui/react"
import UserHome from './screens/UserAuthScreen/Home/Home'
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import { onCreateUserOrders } from './graphql/subscriptions'
import Towns from './screens/UserAuthScreen/Towns/Towns'
import Notification from './screens/UserAuthScreen/Notification/Notification'
import Search from './screens/UserAuthScreen/Search/Search'
import Dashboard from "./screens/AuthScreens/Dashboard/Dashboard";
import Reports from "./screens/AuthScreens/Reports/Reports";
const Routes = () => {

    const [isLoggedIn, setisLoggedIn] = useState(false);
    const [subscriptionObj, setsubscriptionObj] = useState({});
    const toast = useToast();

    let subscription = [];

    useEffect(() => {
        loadSubscription();

    }, []);
    useEffect(() => {

        if (subscriptionObj.ORDER_ID) {

            toast({

                title: "",
                description: `Order #${subscriptionObj.ORDER_ID} is placed ,Please check the dashboard to view the details!`,
                status: 'success',
                duration: 4000,
                isClosable: true,
                position: "top"
            })
        }

    }, [subscriptionObj]);

    function loadSubscription() {

        if (subscription.length > 0) {
            for (const item of subscription) {
                item.unsubscribe();
            }
            subscription = [];
        }


        try {
            const subscriptions = API.graphql(
                graphqlOperation(onCreateUserOrders, { REST_ID: localStorage.getItem('Restaurantid') })
            ).subscribe({
                next: ({ provider, value }) => {

                    subscription.push(value);
                    setsubscriptionObj({...value.data.onCreateUSER_ORDERS})

                   
                    console.log({ provider, value });

                },
                error: error => console.warn(error)
            });


        }
        catch (err) {
            console.log(err);
        }
    }

    async function CheckLogstatus() {

        try {
            let currentSession = await Auth.currentSession();
            if (currentSession.idToken.payload.email == 'undefined') {
                setisLoggedIn(false);
                // isLoggedIn=false;
            }
            else {
                setisLoggedIn(true);
                // isLoggedIn=true;

            }
        } catch (err) {
            // setisLoggedIn(false);
            if (err == 'No current user') {
                setisLoggedIn(false);

            }
        };
    }

        //<PrivateRoute path="/Dashboard"  loggedIn={ isLoggedIn} component={Dashboard} />  
    //   <Route path="/Dashboard" component={Dashboard} />
    return (
        <Router history={history}>
            <Switch>
                <Route path="/" exact component={Login} />
                <Route path="/ConfirmSignUp" component={ConfirmSignUp} />
                <Route path="/ForgotPassword" component={ForgotPassword} />
                <Route path="/ResetPassword" component={ResetPassword} />

                <Route path="/Admin/Orders" component={Orders} />
                <Route path="/Admin/Reports" component={Reports} />
                <Route path="/Admin/Home" component={Home} />
                <Route path="/Admin/Specials" component={Specials} />
                <Route path="/Admin/Events" component={Events} />
                <Route path="/Admin/Menu" component={Menu} />
                <Route path="/Admin/Customization" component={Customization} />
                <Route path="/Admin/Polls" component={Polls} />
                <Route path="/RestaurantMenu/:id" component={RestaurantMenu} />
                <Route path="/Admin/Administration" component={Administration} />
                <Route path="/Admin/RestaurantDetails" component={RestaurantDetails} />

                <Route path="/Dashboard" component={Dashboard} />
                
                <Route path="/Home" component={UserHome} />
                <Route path="/Towns" component={Towns} />
                <Route path="/Notification" component={Notification} />
                <Route path="/Search" component={Search} />
                <Route path="/RewardPoint" component={RewardPoint} />
                <Route path="/ChangePassword" component={ChangePassword} />
                <Route path="/OrderHistory" component={OrderHistory} />
                <Route path="/AdminOrderHistory" component={AdminOrderHistory} />
                <Route path="/UserProfile" component={UserProfile} />
                <Route path="/Favorite" component={Favorite} />
                



                {/* <Route path="/AddSpecials" component={AddSpecials} /> */}path="/user/:id" NJGAR-R1


            </Switch>
        </Router>
    )
}
export default Routes;