
import {
    Box,
    Container,
    Button,
    Grid,
    GridItem,
    Image,
    Flex,
    Text,
    Spacer,
    HStack,
    SimpleGrid,
    Badge
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faBookmark, faShare } from '@fortawesome/free-solid-svg-icons'

//import "./style.css";
import style from './style.css'
import DetailsPopup from './DetailsPopup'
import { useToast, useDisclosure } from "@chakra-ui/react"

const UpcomingSpecials = (props) => {

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [itemDetails,setitemDetails]=useState(null)

    useEffect(() => {
        //debugger;

    }, []);




    return (
        <Box w='100%' >
            <Text mb='5px' fontWeight='bold' className="user-title">Upcoming Specials</Text>
            <Box >
              {itemDetails !=null && props?.upcomingSpecialsList.length>0?
            <DetailsPopup upcomingSpecialsList={props?.upcomingSpecialsList} onUpcomingSpecialHeart={props?.onUpcomingSpecialHeart} onUpcomingSpecialBookMark={props?.onUpcomingSpecialBookMark} itemDetails={itemDetails}  open={isOpen} close={onClose} />
            :''  }
             <SimpleGrid spacing={6}  columns={{ base: '1', md: '3', xl: '4' }}>
                    {/* <Box bg="tomato" height="80px"></Box> */}
                    {props?.upcomingSpecialsList?.map((item) =>
                        <Box className='upcomingspecial-box'  >
                            <Image className="upcomingSpecial-image" cursor='pointer' onClick={()=>{onOpen();setitemDetails(item)}}
                                src={item.MENU_IT_PIC_LOCATION=='' ||item.MENU_IT_PIC_LOCATION==null ? require('../../../assets/images/cuisineImagesNew/DemoDish.jpg').default:item.MENU_IT_PIC_LOCATION}


                                alt="Segun Adebayo" />
                            <Box className='upcomingicons'>
                                <Box className='upcomingicon1'
                                 cursor='pointer'
                                onClick={() => {
                                    props?.onUpcomingSpecialHeart(item.MENU_IT_ID, item.USER_LIKE_YN, item.USER_BOOKMARK_YN, item.relatedMenuItemLikes.LIKE_COUNT)

                                }}
                                ><FontAwesomeIcon 
                                 color={item.USER_LIKE_YN=='Y'?'#00acfe':'#555555'} 
                                m='5px'
                                    
                                    icon={faHeart} /> <Badge className='badge-like' >{item.relatedMenuItemLikes?.LIKE_COUNT}</Badge></Box>

                                <Box className='upcomingicon1' cursor='pointer'><FontAwesomeIcon color='black'  icon={faShare} /></Box>
                                <Box className='upcomingicon1'  cursor='pointer' 
                                onClick={() => {
                                    props?.onUpcomingSpecialBookMark(item.MENU_IT_ID, item.USER_BOOKMARK_YN, item.USER_LIKE_YN)

                                }}
                                
                                ><FontAwesomeIcon
                                 color={item.USER_BOOKMARK_YN=='Y'?'#00acfe':'#555555'}icon={faBookmark} 
                                 
                                
                                 /></Box>

                            </Box>
                            <Box pt='15px'>
                            <Text fontSize="16px" mb="5px" fontWeight="500" color="#3D3D3D">{item.MENU_IT_NAME}</Text>
                            <Text fontSize="14px" color="#747474">{item.REST_NAME}, {item.CITY_NAME}</Text>
                            </Box>
                        </Box>

                    )}
                </SimpleGrid>


            </Box>
        </Box>

    );
}
export default UpcomingSpecials;