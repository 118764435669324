/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const deleteCityMaster = /* GraphQL */ `
  mutation DeleteCityMaster($id: Int!) {
    deleteCITY_MASTER(id: $id) {
      id
      CITY_NAME
      STATE
      LATITUDE
      LONGITUDE
      DISPLAY_INDICATOR_YN
    }
  }
`;
export const createCityMaster = /* GraphQL */ `
  mutation CreateCityMaster($createCITY_MASTERInput: CreateCITY_MASTERInput!) {
    createCITY_MASTER(createCITY_MASTERInput: $createCITY_MASTERInput) {
      id
      CITY_NAME
      STATE
      LATITUDE
      LONGITUDE
      DISPLAY_INDICATOR_YN
    }
  }
`;
export const updateCityMaster = /* GraphQL */ `
  mutation UpdateCityMaster($updateCITY_MASTERInput: UpdateCITY_MASTERInput!) {
    updateCITY_MASTER(updateCITY_MASTERInput: $updateCITY_MASTERInput) {
      id
      CITY_NAME
      STATE
      LATITUDE
      LONGITUDE
      DISPLAY_INDICATOR_YN
    }
  }
`;
export const deleteCuisineTypeMaster = /* GraphQL */ `
  mutation DeleteCuisineTypeMaster($CUISINE_ID: String!) {
    deleteCUISINE_TYPE_MASTER(CUISINE_ID: $CUISINE_ID) {
      CUISINE_ID
      CUISINE_TYPE_NAME
      CUISINE_PIC_LOACTION
      relatedRestaurants {
        REST_ID
        REST_NAME
        ADDRESS
        WEBSITE
        PHONE
        LATITUDE
        LONGITUDE
        RATING
        REVIEW_NUMBERS
        PRICE_LEVEL
        SUNDAY_HOURS
        MONDAY_HOURS
        TUESDAY_HOURS
        WEDNESDAY_HOURS
        THURSDAY_HOURS
        FRIDAY_HOURS
        SATURDAY_HOURS
        CUISINE_TYPE
        CITY_NAME
        TAKEOUT_YN
        DELIVERY_YN
        PRICE_LEVEL_DOLLARS
        REST_OWNER
        CURBSIDE_YN
        DISPLAY_REST_YN
        OPEN_YN
        REST_PIC_LOCATION
        REST_ABOUT
        YELP_ID
        PICKUP_ENABLED_YN
        DINEIN_ENABLED_YN
        RESERVE_ENABLED_YN
        RDELIVERY_ENABLED_YN
        ORDER_PREPTIMEMOD_YN
        MENU_ITEM_INSTR_ENABLED_YN
        RDELIVERY_COMMENT
        APPLY_PROCESS_FEE
        PROCESS_FEE_PCT
        PROCESS_FEE_UNIT
        PROCESS_FEE_AMT
        PROCESS_AMT_UNIT
        PAY_NOW_FEE_MSG
        PAY_REST_FEE_MSG
        relatedMenuGroups {
          REST_ID
          MENU_ID
          MENU_NAME
          MENU_DESC
          MENU_GR_ID
          MENU_GR_NAME
          MENU_GR_DESC
          MENU_GR_ORDER
        }
        relatedSplUpcomingByRest {
          MENU_IT_ID
          MENU_IT_NAME
          REST_ID
          REST_NAME
          CITY_NAME
          CUISINE_TYPE
          MENU_IT_PIC_LOCATION
          DATE_FROM_DATE
          DATE_TO_DATE
          MENU_IT_DESC
          MENU_IT_PRICE
          MENU_ID
          MENU_NAME
          MENU_DESC
          MENU_TYPE
          MENU_GR_ID
          MENU_GR_NAME
          MENU_GR_ORDER
          MENU_ORDER
          MENU_IT_ORDER
          USER_LIKE_YN
          USER_BOOKMARK_YN
          EVERYDAY_YN
          SUNDAY_YN
          MONDAY_YN
          TUESDAY_YN
          WEDNESAY_YN
          THURSDAY_YN
          FRIDAY_YN
          SATURDAY_YN
          EVENT_ALLDAY_YN
          EVENT_TIME
          MENU_IT_TAXABLE_YN
        }
        relatedSplTodayByRest {
          MENU_IT_ID
          MENU_IT_NAME
          REST_ID
          REST_NAME
          CITY_NAME
          CUISINE_TYPE
          MENU_IT_PIC_LOCATION
          DATE_FROM_DATE
          DATE_TO_DATE
          MENU_IT_DESC
          MENU_IT_PRICE
          MENU_ID
          MENU_NAME
          MENU_DESC
          MENU_TYPE
          MENU_GR_ID
          MENU_GR_NAME
          MENU_GR_ORDER
          MENU_ORDER
          MENU_IT_ORDER
          USER_LIKE_YN
          USER_BOOKMARK_YN
          EVERYDAY_YN
          SUNDAY_YN
          MONDAY_YN
          TUESDAY_YN
          WEDNESAY_YN
          THURSDAY_YN
          FRIDAY_YN
          SATURDAY_YN
          EVENT_ALLDAY_YN
          EVENT_TIME
          MENU_IT_TAXABLE_YN
        }
        relatedRestBkmrk {
          REST_ID
          USER_BOOKMARK_YN
          USER_EMAIL
        }
        relatedRestPolls {
          REST_ID
          POLL_ID
          POLL_NAME
          POLL_QUESTION
          POLL_START_DATE_TIME
          POLL_END_DATE_TIME
          POLL_CREATE_DATE_TIME
          POLL_STATUS
          POLL_CHOICE_ID_1
          POLL_CHOICE_1
          POLL_CHOICE_ID_2
          POLL_CHOICE_2
          POLL_CHOICE_ID_3
          POLL_CHOICE_3
          POLL_CHOICE_ID_4
          POLL_CHOICE_4
        }
        relatedTaxRewards {
          REST_ID
          TAX_RATE
          REWARD_PCT
          REWARD_BAL
          DISCOUNT_ID
          DISCOUNT_TYPE
          DISCOUNT_CODE
          DISCOUNT_RATE
          DISCOUNT_AMT
          ORDER_AMT_MIN
          DISCOUNT_MAX_USAGE_COUNT
          DISCOUNT_USE_COUNT
          APPLY_DEFAULT
        }
      }
    }
  }
`;
export const createUserCuisineTypeMaster = /* GraphQL */ `
  mutation CreateUserCuisineTypeMaster(
    $createUSER_CUISINE_TYPE_MASTERInput: CreateUSER_CUISINE_TYPE_MASTERInput!
  ) {
    createUSER_CUISINE_TYPE_MASTER(
      createUSER_CUISINE_TYPE_MASTERInput: $createUSER_CUISINE_TYPE_MASTERInput
    ) {
      CUISINE_ID
      USER_FAVORITE_YN
    }
  }
`;
export const createUserMenuItemBkmrkMaster = /* GraphQL */ `
  mutation CreateUserMenuItemBkmrkMaster(
    $createUSER_MENU_ITEM_BKMRK_MASTERInput: CreateUSER_MENU_ITEM_BKMRK_MASTERInput!
  ) {
    createUSER_MENU_ITEM_BKMRK_MASTER(
      createUSER_MENU_ITEM_BKMRK_MASTERInput: $createUSER_MENU_ITEM_BKMRK_MASTERInput
    ) {
      MENU_IT_ID
      USER_BOOKMARK_YN
    }
  }
`;
export const createUserRestBkmrkMaster = /* GraphQL */ `
  mutation CreateUserRestBkmrkMaster(
    $createUSER_REST_BKMRK_MASTERInput: CreateUSER_REST_BKMRK_MASTERInput!
  ) {
    createUSER_REST_BKMRK_MASTER(
      createUSER_REST_BKMRK_MASTERInput: $createUSER_REST_BKMRK_MASTERInput
    ) {
      REST_ID
      USER_BOOKMARK_YN
      USER_EMAIL
    }
  }
`;
export const createUserHomeTownMaster = /* GraphQL */ `
  mutation CreateUserHomeTownMaster(
    $createUSER_HOME_TOWN_MASTERInput: CreateUSER_HOME_TOWN_MASTERInput!
  ) {
    createUSER_HOME_TOWN_MASTER(
      createUSER_HOME_TOWN_MASTERInput: $createUSER_HOME_TOWN_MASTERInput
    ) {
      ZIP_CODE
      USER_HOME_TOWN_YN
    }
  }
`;
export const createUserNearbyTownMaster = /* GraphQL */ `
  mutation CreateUserNearbyTownMaster(
    $createUSER_NEARBY_TOWN_MASTERInput: CreateUSER_NEARBY_TOWN_MASTERInput!
  ) {
    createUSER_NEARBY_TOWN_MASTER(
      createUSER_NEARBY_TOWN_MASTERInput: $createUSER_NEARBY_TOWN_MASTERInput
    ) {
      ZIP_CODE
      USER_FAVORITE_YN
    }
  }
`;
export const createUserLikeItem = /* GraphQL */ `
  mutation CreateUserLikeItem(
    $createUSER_LIKE_ITEMInput: CreateUSER_LIKE_ITEMInput!
  ) {
    createUSER_LIKE_ITEM(
      createUSER_LIKE_ITEMInput: $createUSER_LIKE_ITEMInput
    ) {
      MENU_IT_ID
      LIKE_COUNT
    }
  }
`;
export const createCuisineTypeMaster = /* GraphQL */ `
  mutation CreateCuisineTypeMaster(
    $createCUISINE_TYPE_MASTERInput: CreateCUISINE_TYPE_MASTERInput!
  ) {
    createCUISINE_TYPE_MASTER(
      createCUISINE_TYPE_MASTERInput: $createCUISINE_TYPE_MASTERInput
    ) {
      CUISINE_ID
      CUISINE_TYPE_NAME
      CUISINE_PIC_LOACTION
      relatedRestaurants {
        REST_ID
        REST_NAME
        ADDRESS
        WEBSITE
        PHONE
        LATITUDE
        LONGITUDE
        RATING
        REVIEW_NUMBERS
        PRICE_LEVEL
        SUNDAY_HOURS
        MONDAY_HOURS
        TUESDAY_HOURS
        WEDNESDAY_HOURS
        THURSDAY_HOURS
        FRIDAY_HOURS
        SATURDAY_HOURS
        CUISINE_TYPE
        CITY_NAME
        TAKEOUT_YN
        DELIVERY_YN
        PRICE_LEVEL_DOLLARS
        REST_OWNER
        CURBSIDE_YN
        DISPLAY_REST_YN
        OPEN_YN
        REST_PIC_LOCATION
        REST_ABOUT
        YELP_ID
        PICKUP_ENABLED_YN
        DINEIN_ENABLED_YN
        RESERVE_ENABLED_YN
        RDELIVERY_ENABLED_YN
        ORDER_PREPTIMEMOD_YN
        MENU_ITEM_INSTR_ENABLED_YN
        RDELIVERY_COMMENT
        APPLY_PROCESS_FEE
        PROCESS_FEE_PCT
        PROCESS_FEE_UNIT
        PROCESS_FEE_AMT
        PROCESS_AMT_UNIT
        PAY_NOW_FEE_MSG
        PAY_REST_FEE_MSG
        relatedMenuGroups {
          REST_ID
          MENU_ID
          MENU_NAME
          MENU_DESC
          MENU_GR_ID
          MENU_GR_NAME
          MENU_GR_DESC
          MENU_GR_ORDER
        }
        relatedSplUpcomingByRest {
          MENU_IT_ID
          MENU_IT_NAME
          REST_ID
          REST_NAME
          CITY_NAME
          CUISINE_TYPE
          MENU_IT_PIC_LOCATION
          DATE_FROM_DATE
          DATE_TO_DATE
          MENU_IT_DESC
          MENU_IT_PRICE
          MENU_ID
          MENU_NAME
          MENU_DESC
          MENU_TYPE
          MENU_GR_ID
          MENU_GR_NAME
          MENU_GR_ORDER
          MENU_ORDER
          MENU_IT_ORDER
          USER_LIKE_YN
          USER_BOOKMARK_YN
          EVERYDAY_YN
          SUNDAY_YN
          MONDAY_YN
          TUESDAY_YN
          WEDNESAY_YN
          THURSDAY_YN
          FRIDAY_YN
          SATURDAY_YN
          EVENT_ALLDAY_YN
          EVENT_TIME
          MENU_IT_TAXABLE_YN
        }
        relatedSplTodayByRest {
          MENU_IT_ID
          MENU_IT_NAME
          REST_ID
          REST_NAME
          CITY_NAME
          CUISINE_TYPE
          MENU_IT_PIC_LOCATION
          DATE_FROM_DATE
          DATE_TO_DATE
          MENU_IT_DESC
          MENU_IT_PRICE
          MENU_ID
          MENU_NAME
          MENU_DESC
          MENU_TYPE
          MENU_GR_ID
          MENU_GR_NAME
          MENU_GR_ORDER
          MENU_ORDER
          MENU_IT_ORDER
          USER_LIKE_YN
          USER_BOOKMARK_YN
          EVERYDAY_YN
          SUNDAY_YN
          MONDAY_YN
          TUESDAY_YN
          WEDNESAY_YN
          THURSDAY_YN
          FRIDAY_YN
          SATURDAY_YN
          EVENT_ALLDAY_YN
          EVENT_TIME
          MENU_IT_TAXABLE_YN
        }
        relatedRestBkmrk {
          REST_ID
          USER_BOOKMARK_YN
          USER_EMAIL
        }
        relatedRestPolls {
          REST_ID
          POLL_ID
          POLL_NAME
          POLL_QUESTION
          POLL_START_DATE_TIME
          POLL_END_DATE_TIME
          POLL_CREATE_DATE_TIME
          POLL_STATUS
          POLL_CHOICE_ID_1
          POLL_CHOICE_1
          POLL_CHOICE_ID_2
          POLL_CHOICE_2
          POLL_CHOICE_ID_3
          POLL_CHOICE_3
          POLL_CHOICE_ID_4
          POLL_CHOICE_4
        }
        relatedTaxRewards {
          REST_ID
          TAX_RATE
          REWARD_PCT
          REWARD_BAL
          DISCOUNT_ID
          DISCOUNT_TYPE
          DISCOUNT_CODE
          DISCOUNT_RATE
          DISCOUNT_AMT
          ORDER_AMT_MIN
          DISCOUNT_MAX_USAGE_COUNT
          DISCOUNT_USE_COUNT
          APPLY_DEFAULT
        }
      }
    }
  }
`;
export const updateCuisineTypeMaster = /* GraphQL */ `
  mutation UpdateCuisineTypeMaster(
    $updateCUISINE_TYPE_MASTERInput: UpdateCUISINE_TYPE_MASTERInput!
  ) {
    updateCUISINE_TYPE_MASTER(
      updateCUISINE_TYPE_MASTERInput: $updateCUISINE_TYPE_MASTERInput
    ) {
      CUISINE_ID
      CUISINE_TYPE_NAME
      CUISINE_PIC_LOACTION
      relatedRestaurants {
        REST_ID
        REST_NAME
        ADDRESS
        WEBSITE
        PHONE
        LATITUDE
        LONGITUDE
        RATING
        REVIEW_NUMBERS
        PRICE_LEVEL
        SUNDAY_HOURS
        MONDAY_HOURS
        TUESDAY_HOURS
        WEDNESDAY_HOURS
        THURSDAY_HOURS
        FRIDAY_HOURS
        SATURDAY_HOURS
        CUISINE_TYPE
        CITY_NAME
        TAKEOUT_YN
        DELIVERY_YN
        PRICE_LEVEL_DOLLARS
        REST_OWNER
        CURBSIDE_YN
        DISPLAY_REST_YN
        OPEN_YN
        REST_PIC_LOCATION
        REST_ABOUT
        YELP_ID
        PICKUP_ENABLED_YN
        DINEIN_ENABLED_YN
        RESERVE_ENABLED_YN
        RDELIVERY_ENABLED_YN
        ORDER_PREPTIMEMOD_YN
        MENU_ITEM_INSTR_ENABLED_YN
        RDELIVERY_COMMENT
        APPLY_PROCESS_FEE
        PROCESS_FEE_PCT
        PROCESS_FEE_UNIT
        PROCESS_FEE_AMT
        PROCESS_AMT_UNIT
        PAY_NOW_FEE_MSG
        PAY_REST_FEE_MSG
        relatedMenuGroups {
          REST_ID
          MENU_ID
          MENU_NAME
          MENU_DESC
          MENU_GR_ID
          MENU_GR_NAME
          MENU_GR_DESC
          MENU_GR_ORDER
        }
        relatedSplUpcomingByRest {
          MENU_IT_ID
          MENU_IT_NAME
          REST_ID
          REST_NAME
          CITY_NAME
          CUISINE_TYPE
          MENU_IT_PIC_LOCATION
          DATE_FROM_DATE
          DATE_TO_DATE
          MENU_IT_DESC
          MENU_IT_PRICE
          MENU_ID
          MENU_NAME
          MENU_DESC
          MENU_TYPE
          MENU_GR_ID
          MENU_GR_NAME
          MENU_GR_ORDER
          MENU_ORDER
          MENU_IT_ORDER
          USER_LIKE_YN
          USER_BOOKMARK_YN
          EVERYDAY_YN
          SUNDAY_YN
          MONDAY_YN
          TUESDAY_YN
          WEDNESAY_YN
          THURSDAY_YN
          FRIDAY_YN
          SATURDAY_YN
          EVENT_ALLDAY_YN
          EVENT_TIME
          MENU_IT_TAXABLE_YN
        }
        relatedSplTodayByRest {
          MENU_IT_ID
          MENU_IT_NAME
          REST_ID
          REST_NAME
          CITY_NAME
          CUISINE_TYPE
          MENU_IT_PIC_LOCATION
          DATE_FROM_DATE
          DATE_TO_DATE
          MENU_IT_DESC
          MENU_IT_PRICE
          MENU_ID
          MENU_NAME
          MENU_DESC
          MENU_TYPE
          MENU_GR_ID
          MENU_GR_NAME
          MENU_GR_ORDER
          MENU_ORDER
          MENU_IT_ORDER
          USER_LIKE_YN
          USER_BOOKMARK_YN
          EVERYDAY_YN
          SUNDAY_YN
          MONDAY_YN
          TUESDAY_YN
          WEDNESAY_YN
          THURSDAY_YN
          FRIDAY_YN
          SATURDAY_YN
          EVENT_ALLDAY_YN
          EVENT_TIME
          MENU_IT_TAXABLE_YN
        }
        relatedRestBkmrk {
          REST_ID
          USER_BOOKMARK_YN
          USER_EMAIL
        }
        relatedRestPolls {
          REST_ID
          POLL_ID
          POLL_NAME
          POLL_QUESTION
          POLL_START_DATE_TIME
          POLL_END_DATE_TIME
          POLL_CREATE_DATE_TIME
          POLL_STATUS
          POLL_CHOICE_ID_1
          POLL_CHOICE_1
          POLL_CHOICE_ID_2
          POLL_CHOICE_2
          POLL_CHOICE_ID_3
          POLL_CHOICE_3
          POLL_CHOICE_ID_4
          POLL_CHOICE_4
        }
        relatedTaxRewards {
          REST_ID
          TAX_RATE
          REWARD_PCT
          REWARD_BAL
          DISCOUNT_ID
          DISCOUNT_TYPE
          DISCOUNT_CODE
          DISCOUNT_RATE
          DISCOUNT_AMT
          ORDER_AMT_MIN
          DISCOUNT_MAX_USAGE_COUNT
          DISCOUNT_USE_COUNT
          APPLY_DEFAULT
        }
      }
    }
  }
`;
export const deleteCustomers = /* GraphQL */ `
  mutation DeleteCustomers($id: Int!) {
    deleteCustomers(id: $id) {
      id
      name
      phone
      email
    }
  }
`;
export const createCustomers = /* GraphQL */ `
  mutation CreateCustomers($createCustomersInput: CreateCustomersInput!) {
    createCustomers(createCustomersInput: $createCustomersInput) {
      id
      name
      phone
      email
    }
  }
`;
export const updateCustomers = /* GraphQL */ `
  mutation UpdateCustomers($updateCustomersInput: UpdateCustomersInput!) {
    updateCustomers(updateCustomersInput: $updateCustomersInput) {
      id
      name
      phone
      email
    }
  }
`;
export const deleteRestMaster = /* GraphQL */ `
  mutation DeleteRestMaster($REST_ID: String!) {
    deleteREST_MASTER(REST_ID: $REST_ID) {
      REST_ID
      REST_NAME
      ADDRESS
      WEBSITE
      PHONE
      LATITUDE
      LONGITUDE
      RATING
      REVIEW_NUMBERS
      PRICE_LEVEL
      SUNDAY_HOURS
      MONDAY_HOURS
      TUESDAY_HOURS
      WEDNESDAY_HOURS
      THURSDAY_HOURS
      FRIDAY_HOURS
      SATURDAY_HOURS
      CUISINE_TYPE
      CITY_NAME
      TAKEOUT_YN
      DELIVERY_YN
      PRICE_LEVEL_DOLLARS
      REST_OWNER
      CURBSIDE_YN
      DISPLAY_REST_YN
      OPEN_YN
      REST_PIC_LOCATION
      REST_ABOUT
      YELP_ID
      PICKUP_ENABLED_YN
      DINEIN_ENABLED_YN
      RESERVE_ENABLED_YN
      RDELIVERY_ENABLED_YN
      ORDER_PREPTIMEMOD_YN
      MENU_ITEM_INSTR_ENABLED_YN
      RDELIVERY_COMMENT
      APPLY_PROCESS_FEE
      PROCESS_FEE_PCT
      PROCESS_FEE_UNIT
      PROCESS_FEE_AMT
      PROCESS_AMT_UNIT
      PAY_NOW_FEE_MSG
      PAY_REST_FEE_MSG
      relatedMenuGroups {
        REST_ID
        MENU_ID
        MENU_NAME
        MENU_DESC
        MENU_GR_ID
        MENU_GR_NAME
        MENU_GR_DESC
        MENU_GR_ORDER
        relatedMenuItems {
          REST_ID
          MENU_IT_ID
          MENU_IT_NAME
          MENU_IT_DESC
          MENU_IT_PRICE
          MENU_IT_PIC_LOCATION
          POPULAR_YN
          MENU_IT_STATUS
          MENU_IT_TAXABLE_YN
        }
      }
      relatedSplUpcomingByRest {
        MENU_IT_ID
        MENU_IT_NAME
        REST_ID
        REST_NAME
        CITY_NAME
        CUISINE_TYPE
        MENU_IT_PIC_LOCATION
        DATE_FROM_DATE
        DATE_TO_DATE
        MENU_IT_DESC
        MENU_IT_PRICE
        MENU_ID
        MENU_NAME
        MENU_DESC
        MENU_TYPE
        MENU_GR_ID
        MENU_GR_NAME
        MENU_GR_ORDER
        MENU_ORDER
        MENU_IT_ORDER
        USER_LIKE_YN
        USER_BOOKMARK_YN
        EVERYDAY_YN
        SUNDAY_YN
        MONDAY_YN
        TUESDAY_YN
        WEDNESAY_YN
        THURSDAY_YN
        FRIDAY_YN
        SATURDAY_YN
        EVENT_ALLDAY_YN
        EVENT_TIME
        MENU_IT_TAXABLE_YN
        relatedMenuItemLikes {
          MENU_IT_ID
          LIKE_COUNT
        }
      }
      relatedSplTodayByRest {
        MENU_IT_ID
        MENU_IT_NAME
        REST_ID
        REST_NAME
        CITY_NAME
        CUISINE_TYPE
        MENU_IT_PIC_LOCATION
        DATE_FROM_DATE
        DATE_TO_DATE
        MENU_IT_DESC
        MENU_IT_PRICE
        MENU_ID
        MENU_NAME
        MENU_DESC
        MENU_TYPE
        MENU_GR_ID
        MENU_GR_NAME
        MENU_GR_ORDER
        MENU_ORDER
        MENU_IT_ORDER
        USER_LIKE_YN
        USER_BOOKMARK_YN
        EVERYDAY_YN
        SUNDAY_YN
        MONDAY_YN
        TUESDAY_YN
        WEDNESAY_YN
        THURSDAY_YN
        FRIDAY_YN
        SATURDAY_YN
        EVENT_ALLDAY_YN
        EVENT_TIME
        MENU_IT_TAXABLE_YN
        relatedMenuItemLikes {
          MENU_IT_ID
          LIKE_COUNT
        }
      }
      relatedRestBkmrk {
        REST_ID
        USER_BOOKMARK_YN
        USER_EMAIL
      }
      relatedRestPolls {
        REST_ID
        POLL_ID
        POLL_NAME
        POLL_QUESTION
        POLL_START_DATE_TIME
        POLL_END_DATE_TIME
        POLL_CREATE_DATE_TIME
        POLL_STATUS
        POLL_CHOICE_ID_1
        POLL_CHOICE_1
        POLL_CHOICE_ID_2
        POLL_CHOICE_2
        POLL_CHOICE_ID_3
        POLL_CHOICE_3
        POLL_CHOICE_ID_4
        POLL_CHOICE_4
      }
      relatedTaxRewards {
        REST_ID
        TAX_RATE
        REWARD_PCT
        REWARD_BAL
        DISCOUNT_ID
        DISCOUNT_TYPE
        DISCOUNT_CODE
        DISCOUNT_RATE
        DISCOUNT_AMT
        ORDER_AMT_MIN
        DISCOUNT_MAX_USAGE_COUNT
        DISCOUNT_USE_COUNT
        APPLY_DEFAULT
      }
    }
  }
`;
export const createRestMaster = /* GraphQL */ `
  mutation CreateRestMaster($createREST_MASTERInput: CreateREST_MASTERInput!) {
    createREST_MASTER(createREST_MASTERInput: $createREST_MASTERInput) {
      REST_ID
      REST_NAME
      ADDRESS
      WEBSITE
      PHONE
      LATITUDE
      LONGITUDE
      RATING
      REVIEW_NUMBERS
      PRICE_LEVEL
      SUNDAY_HOURS
      MONDAY_HOURS
      TUESDAY_HOURS
      WEDNESDAY_HOURS
      THURSDAY_HOURS
      FRIDAY_HOURS
      SATURDAY_HOURS
      CUISINE_TYPE
      CITY_NAME
      TAKEOUT_YN
      DELIVERY_YN
      PRICE_LEVEL_DOLLARS
      REST_OWNER
      CURBSIDE_YN
      DISPLAY_REST_YN
      OPEN_YN
      REST_PIC_LOCATION
      REST_ABOUT
      YELP_ID
      PICKUP_ENABLED_YN
      DINEIN_ENABLED_YN
      RESERVE_ENABLED_YN
      RDELIVERY_ENABLED_YN
      ORDER_PREPTIMEMOD_YN
      MENU_ITEM_INSTR_ENABLED_YN
      RDELIVERY_COMMENT
      APPLY_PROCESS_FEE
      PROCESS_FEE_PCT
      PROCESS_FEE_UNIT
      PROCESS_FEE_AMT
      PROCESS_AMT_UNIT
      PAY_NOW_FEE_MSG
      PAY_REST_FEE_MSG
      relatedMenuGroups {
        REST_ID
        MENU_ID
        MENU_NAME
        MENU_DESC
        MENU_GR_ID
        MENU_GR_NAME
        MENU_GR_DESC
        MENU_GR_ORDER
        relatedMenuItems {
          REST_ID
          MENU_IT_ID
          MENU_IT_NAME
          MENU_IT_DESC
          MENU_IT_PRICE
          MENU_IT_PIC_LOCATION
          POPULAR_YN
          MENU_IT_STATUS
          MENU_IT_TAXABLE_YN
        }
      }
      relatedSplUpcomingByRest {
        MENU_IT_ID
        MENU_IT_NAME
        REST_ID
        REST_NAME
        CITY_NAME
        CUISINE_TYPE
        MENU_IT_PIC_LOCATION
        DATE_FROM_DATE
        DATE_TO_DATE
        MENU_IT_DESC
        MENU_IT_PRICE
        MENU_ID
        MENU_NAME
        MENU_DESC
        MENU_TYPE
        MENU_GR_ID
        MENU_GR_NAME
        MENU_GR_ORDER
        MENU_ORDER
        MENU_IT_ORDER
        USER_LIKE_YN
        USER_BOOKMARK_YN
        EVERYDAY_YN
        SUNDAY_YN
        MONDAY_YN
        TUESDAY_YN
        WEDNESAY_YN
        THURSDAY_YN
        FRIDAY_YN
        SATURDAY_YN
        EVENT_ALLDAY_YN
        EVENT_TIME
        MENU_IT_TAXABLE_YN
        relatedMenuItemLikes {
          MENU_IT_ID
          LIKE_COUNT
        }
      }
      relatedSplTodayByRest {
        MENU_IT_ID
        MENU_IT_NAME
        REST_ID
        REST_NAME
        CITY_NAME
        CUISINE_TYPE
        MENU_IT_PIC_LOCATION
        DATE_FROM_DATE
        DATE_TO_DATE
        MENU_IT_DESC
        MENU_IT_PRICE
        MENU_ID
        MENU_NAME
        MENU_DESC
        MENU_TYPE
        MENU_GR_ID
        MENU_GR_NAME
        MENU_GR_ORDER
        MENU_ORDER
        MENU_IT_ORDER
        USER_LIKE_YN
        USER_BOOKMARK_YN
        EVERYDAY_YN
        SUNDAY_YN
        MONDAY_YN
        TUESDAY_YN
        WEDNESAY_YN
        THURSDAY_YN
        FRIDAY_YN
        SATURDAY_YN
        EVENT_ALLDAY_YN
        EVENT_TIME
        MENU_IT_TAXABLE_YN
        relatedMenuItemLikes {
          MENU_IT_ID
          LIKE_COUNT
        }
      }
      relatedRestBkmrk {
        REST_ID
        USER_BOOKMARK_YN
        USER_EMAIL
      }
      relatedRestPolls {
        REST_ID
        POLL_ID
        POLL_NAME
        POLL_QUESTION
        POLL_START_DATE_TIME
        POLL_END_DATE_TIME
        POLL_CREATE_DATE_TIME
        POLL_STATUS
        POLL_CHOICE_ID_1
        POLL_CHOICE_1
        POLL_CHOICE_ID_2
        POLL_CHOICE_2
        POLL_CHOICE_ID_3
        POLL_CHOICE_3
        POLL_CHOICE_ID_4
        POLL_CHOICE_4
      }
      relatedTaxRewards {
        REST_ID
        TAX_RATE
        REWARD_PCT
        REWARD_BAL
        DISCOUNT_ID
        DISCOUNT_TYPE
        DISCOUNT_CODE
        DISCOUNT_RATE
        DISCOUNT_AMT
        ORDER_AMT_MIN
        DISCOUNT_MAX_USAGE_COUNT
        DISCOUNT_USE_COUNT
        APPLY_DEFAULT
      }
    }
  }
`;
export const createSpecialMenuMaster = /* GraphQL */ `
  mutation CreateSpecialMenuMaster(
    $createSPECIAL_MENU_MASTERInput: CreateSPECIAL_MENU_MASTERInput!
  ) {
    createSPECIAL_MENU_MASTER(
      createSPECIAL_MENU_MASTERInput: $createSPECIAL_MENU_MASTERInput
    ) {
      MENU_IT_ID
      MENU_IT_NAME
      MENU_IT_DESC
      MENU_IT_PRICE
      MENU_IT_PIC_LOCATION
      DATE_FROM
      DATE_TO
      DATE_FROM_DATE
      DATE_TO_DATE
      REST_ID
      MENU_ID
      MENU_NAME
      MENU_DESC
      MENU_IT_ORDER
      MENU_TYPE
      MENU_GR_ID
      MENU_GR_NAME
      MENU_GR_ORDER
    }
  }
`;
export const createSpecialMenuMasterWeb = /* GraphQL */ `
  mutation CreateSpecialMenuMasterWeb(
    $CreateSPECIAL_MENU_MASTER_WEBInput: CreateSPECIAL_MENU_MASTER_WEBInput!
  ) {
    createSPECIAL_MENU_MASTER_WEB(
      CreateSPECIAL_MENU_MASTER_WEBInput: $CreateSPECIAL_MENU_MASTER_WEBInput
    ) {
      MENU_IT_ID
      MENU_IT_NAME
      REST_ID
      REST_NAME
      CITY_NAME
      CUISINE_TYPE
      MENU_IT_PIC_LOCATION
      DATE_FROM_DATE
      DATE_TO_DATE
      MENU_IT_DESC
      MENU_IT_PRICE
      MENU_ID
      MENU_NAME
      MENU_DESC
      MENU_TYPE
      MENU_GR_ID
      MENU_GR_NAME
      MENU_GR_ORDER
      MENU_ORDER
      MENU_IT_ORDER
      USER_LIKE_YN
      USER_BOOKMARK_YN
      EVERYDAY_YN
      SUNDAY_YN
      MONDAY_YN
      TUESDAY_YN
      WEDNESAY_YN
      THURSDAY_YN
      FRIDAY_YN
      SATURDAY_YN
      EVENT_ALLDAY_YN
      EVENT_TIME
      MENU_IT_TAXABLE_YN
      relatedMenuItemLikes {
        MENU_IT_ID
        LIKE_COUNT
      }
    }
  }
`;
export const updateSpecialMenuMasterWeb = /* GraphQL */ `
  mutation UpdateSpecialMenuMasterWeb(
    $UpdateSPECIAL_MENU_MASTER_WEBInput: UpdateSPECIAL_MENU_MASTER_WEBInput!
  ) {
    updateSPECIAL_MENU_MASTER_WEB(
      UpdateSPECIAL_MENU_MASTER_WEBInput: $UpdateSPECIAL_MENU_MASTER_WEBInput
    ) {
      MENU_IT_ID
      MENU_IT_NAME
      REST_ID
      REST_NAME
      CITY_NAME
      CUISINE_TYPE
      MENU_IT_PIC_LOCATION
      DATE_FROM_DATE
      DATE_TO_DATE
      MENU_IT_DESC
      MENU_IT_PRICE
      MENU_ID
      MENU_NAME
      MENU_DESC
      MENU_TYPE
      MENU_GR_ID
      MENU_GR_NAME
      MENU_GR_ORDER
      MENU_ORDER
      MENU_IT_ORDER
      USER_LIKE_YN
      USER_BOOKMARK_YN
      EVERYDAY_YN
      SUNDAY_YN
      MONDAY_YN
      TUESDAY_YN
      WEDNESAY_YN
      THURSDAY_YN
      FRIDAY_YN
      SATURDAY_YN
      EVENT_ALLDAY_YN
      EVENT_TIME
      MENU_IT_TAXABLE_YN
      relatedMenuItemLikes {
        MENU_IT_ID
        LIKE_COUNT
      }
    }
  }
`;
export const createSpecialMenuMasterAudit = /* GraphQL */ `
  mutation CreateSpecialMenuMasterAudit(
    $CreateSPECIAL_MENU_MASTER_AUDITInput: CreateSPECIAL_MENU_MASTER_AUDITInput!
  ) {
    CreateSPECIAL_MENU_MASTER_AUDIT(
      CreateSPECIAL_MENU_MASTER_AUDITInput: $CreateSPECIAL_MENU_MASTER_AUDITInput
    ) {
      MENU_IT_ID
      MENU_IT_NAME
      REST_ID
      REST_NAME
      CITY_NAME
      CUISINE_TYPE
      MENU_IT_PIC_LOCATION
      DATE_FROM_DATE
      DATE_TO_DATE
      MENU_IT_DESC
      MENU_IT_PRICE
      MENU_ID
      MENU_NAME
      MENU_DESC
      MENU_TYPE
      MENU_GR_ID
      MENU_GR_NAME
      MENU_GR_ORDER
      USER_LIKE_YN
      USER_BOOKMARK_YN
      EVERYDAY_YN
      SUNDAY_YN
      MONDAY_YN
      TUESDAY_YN
      WEDNESAY_YN
      THURSDAY_YN
      FRIDAY_YN
      SATURDAY_YN
      UPDATED_AT
      UPDATED_BY
    }
  }
`;
export const createRestSpecialMenuChoices = /* GraphQL */ `
  mutation CreateRestSpecialMenuChoices(
    $CreateREST_SPECIAL_MENU_CHOICESInput: CreateREST_SPECIAL_MENU_CHOICESInput!
  ) {
    CreateREST_SPECIAL_MENU_CHOICES(
      CreateREST_SPECIAL_MENU_CHOICESInput: $CreateREST_SPECIAL_MENU_CHOICESInput
    ) {
      REST_ID
      MENU_IT_ID
      MENU_IT_NAME
      MENU_IT_PRICE
      CH_ID
      CH_DESC
      data {
        CH_ITEM_ID
        CH_ITEM_DESC
        CH_PRICE
        CH_DEFAULT
        MUST_CHOOSE_FLAG
        BASE_MODIFIER_IND
        MULTI_SELECT_IND
        MIN_QTY
        MAX_QTY
      }
    }
  }
`;
export const updateRestSpecialMenuChoices = /* GraphQL */ `
  mutation UpdateRestSpecialMenuChoices(
    $CreateREST_SPECIAL_MENU_CHOICESInput: CreateREST_SPECIAL_MENU_CHOICESInput!
  ) {
    UpdateREST_SPECIAL_MENU_CHOICES(
      CreateREST_SPECIAL_MENU_CHOICESInput: $CreateREST_SPECIAL_MENU_CHOICESInput
    ) {
      REST_ID
      MENU_IT_ID
      MENU_IT_NAME
      MENU_IT_PRICE
      CH_ID
      CH_DESC
      UPDATED_AT
      UPDATED_BY
      data {
        CH_ITEM_ID
        CH_ITEM_DESC
        CH_PRICE
        CH_DEFAULT
        MUST_CHOOSE_FLAG
        BASE_MODIFIER_IND
        MULTI_SELECT_IND
        MIN_QTY
        MAX_QTY
      }
    }
  }
`;
export const deleteRestSpecialMenuChoices = /* GraphQL */ `
  mutation DeleteRestSpecialMenuChoices(
    $CreateREST_SPECIAL_MENU_CHOICESInput: CreateREST_SPECIAL_MENU_CHOICESInput!
  ) {
    DeleteREST_SPECIAL_MENU_CHOICES(
      CreateREST_SPECIAL_MENU_CHOICESInput: $CreateREST_SPECIAL_MENU_CHOICESInput
    ) {
      REST_ID
      MENU_IT_ID
      MENU_IT_NAME
      MENU_IT_PRICE
      CH_ID
      CH_DESC
      UPDATED_AT
      UPDATED_BY
      data {
        CH_ITEM_ID
        CH_ITEM_DESC
        CH_PRICE
        CH_DEFAULT
        MUST_CHOOSE_FLAG
        BASE_MODIFIER_IND
        MULTI_SELECT_IND
        MIN_QTY
        MAX_QTY
      }
    }
  }
`;
export const createUserRestRewardTrans = /* GraphQL */ `
  mutation CreateUserRestRewardTrans(
    $createUSER_REST_REWARD_TRANSInput: CreateUSER_REST_REWARD_TRANSInput!
  ) {
    createUSER_REST_REWARD_TRANS(
      createUSER_REST_REWARD_TRANSInput: $createUSER_REST_REWARD_TRANSInput
    ) {
      REST_ID
      ORDER_ID
      ORDER_AMT
      REWARD_TRANS_TYPE
      REWARD_AMT
    }
  }
`;
export const updateRestMaster = /* GraphQL */ `
  mutation UpdateRestMaster($updateREST_MASTERInput: UpdateREST_MASTERInput!) {
    updateREST_MASTER(updateREST_MASTERInput: $updateREST_MASTERInput) {
      REST_ID
      REST_NAME
      ADDRESS
      WEBSITE
      PHONE
      LATITUDE
      LONGITUDE
      RATING
      REVIEW_NUMBERS
      PRICE_LEVEL
      SUNDAY_HOURS
      MONDAY_HOURS
      TUESDAY_HOURS
      WEDNESDAY_HOURS
      THURSDAY_HOURS
      FRIDAY_HOURS
      SATURDAY_HOURS
      CUISINE_TYPE
      CITY_NAME
      TAKEOUT_YN
      DELIVERY_YN
      PRICE_LEVEL_DOLLARS
      REST_OWNER
      CURBSIDE_YN
      DISPLAY_REST_YN
      OPEN_YN
      REST_PIC_LOCATION
      REST_ABOUT
      YELP_ID
      PICKUP_ENABLED_YN
      DINEIN_ENABLED_YN
      RESERVE_ENABLED_YN
      RDELIVERY_ENABLED_YN
      ORDER_PREPTIMEMOD_YN
      MENU_ITEM_INSTR_ENABLED_YN
      RDELIVERY_COMMENT
      APPLY_PROCESS_FEE
      PROCESS_FEE_PCT
      PROCESS_FEE_UNIT
      PROCESS_FEE_AMT
      PROCESS_AMT_UNIT
      PAY_NOW_FEE_MSG
      PAY_REST_FEE_MSG
      relatedMenuGroups {
        REST_ID
        MENU_ID
        MENU_NAME
        MENU_DESC
        MENU_GR_ID
        MENU_GR_NAME
        MENU_GR_DESC
        MENU_GR_ORDER
        relatedMenuItems {
          REST_ID
          MENU_IT_ID
          MENU_IT_NAME
          MENU_IT_DESC
          MENU_IT_PRICE
          MENU_IT_PIC_LOCATION
          POPULAR_YN
          MENU_IT_STATUS
          MENU_IT_TAXABLE_YN
        }
      }
      relatedSplUpcomingByRest {
        MENU_IT_ID
        MENU_IT_NAME
        REST_ID
        REST_NAME
        CITY_NAME
        CUISINE_TYPE
        MENU_IT_PIC_LOCATION
        DATE_FROM_DATE
        DATE_TO_DATE
        MENU_IT_DESC
        MENU_IT_PRICE
        MENU_ID
        MENU_NAME
        MENU_DESC
        MENU_TYPE
        MENU_GR_ID
        MENU_GR_NAME
        MENU_GR_ORDER
        MENU_ORDER
        MENU_IT_ORDER
        USER_LIKE_YN
        USER_BOOKMARK_YN
        EVERYDAY_YN
        SUNDAY_YN
        MONDAY_YN
        TUESDAY_YN
        WEDNESAY_YN
        THURSDAY_YN
        FRIDAY_YN
        SATURDAY_YN
        EVENT_ALLDAY_YN
        EVENT_TIME
        MENU_IT_TAXABLE_YN
        relatedMenuItemLikes {
          MENU_IT_ID
          LIKE_COUNT
        }
      }
      relatedSplTodayByRest {
        MENU_IT_ID
        MENU_IT_NAME
        REST_ID
        REST_NAME
        CITY_NAME
        CUISINE_TYPE
        MENU_IT_PIC_LOCATION
        DATE_FROM_DATE
        DATE_TO_DATE
        MENU_IT_DESC
        MENU_IT_PRICE
        MENU_ID
        MENU_NAME
        MENU_DESC
        MENU_TYPE
        MENU_GR_ID
        MENU_GR_NAME
        MENU_GR_ORDER
        MENU_ORDER
        MENU_IT_ORDER
        USER_LIKE_YN
        USER_BOOKMARK_YN
        EVERYDAY_YN
        SUNDAY_YN
        MONDAY_YN
        TUESDAY_YN
        WEDNESAY_YN
        THURSDAY_YN
        FRIDAY_YN
        SATURDAY_YN
        EVENT_ALLDAY_YN
        EVENT_TIME
        MENU_IT_TAXABLE_YN
        relatedMenuItemLikes {
          MENU_IT_ID
          LIKE_COUNT
        }
      }
      relatedRestBkmrk {
        REST_ID
        USER_BOOKMARK_YN
        USER_EMAIL
      }
      relatedRestPolls {
        REST_ID
        POLL_ID
        POLL_NAME
        POLL_QUESTION
        POLL_START_DATE_TIME
        POLL_END_DATE_TIME
        POLL_CREATE_DATE_TIME
        POLL_STATUS
        POLL_CHOICE_ID_1
        POLL_CHOICE_1
        POLL_CHOICE_ID_2
        POLL_CHOICE_2
        POLL_CHOICE_ID_3
        POLL_CHOICE_3
        POLL_CHOICE_ID_4
        POLL_CHOICE_4
      }
      relatedTaxRewards {
        REST_ID
        TAX_RATE
        REWARD_PCT
        REWARD_BAL
        DISCOUNT_ID
        DISCOUNT_TYPE
        DISCOUNT_CODE
        DISCOUNT_RATE
        DISCOUNT_AMT
        ORDER_AMT_MIN
        DISCOUNT_MAX_USAGE_COUNT
        DISCOUNT_USE_COUNT
        APPLY_DEFAULT
      }
    }
  }
`;
export const updateMasterMenuChoices = /* GraphQL */ `
  mutation UpdateMasterMenuChoices(
    $updateMASTER_MENU_CHOICESInput: UpdateMASTER_MENU_CHOICESInput!
  ) {
    updateMASTER_MENU_CHOICES(
      updateMASTER_MENU_CHOICESInput: $updateMASTER_MENU_CHOICESInput
    ) {
      REST_ID
      CH_GRP_ID
      CH_ID
      CH_DESC
      CH_LABEL
      CH_ID_ORDER
      MUST_CHOOSE_FLAG
      MULTI_SELECT_IND
      BASE_MODIFIER_IND
      MIN_QTY
      MAX_QTY
      relatedMasterChoiceItems {
        CH_ITEM_ID
        CH_ITEM_DESC
        CH_ITEM_ID_ORDER
        CH_DEFAULT
        CH_PRICE
        CH_ITEM_STATUS
      }
    }
  }
`;
export const updateMasterMenuChoiceItems = /* GraphQL */ `
  mutation UpdateMasterMenuChoiceItems(
    $updateMASTER_MENU_CHOICE_ITEMSInput: UpdateMASTER_MENU_CHOICE_ITEMSInput!
  ) {
    updateMASTER_MENU_CHOICE_ITEMS(
      updateMASTER_MENU_CHOICE_ITEMSInput: $updateMASTER_MENU_CHOICE_ITEMSInput
    ) {
      CH_ITEM_ID
      CH_ITEM_DESC
      CH_ITEM_ID_ORDER
      CH_DEFAULT
      CH_PRICE
      CH_ITEM_STATUS
    }
  }
`;
export const updateMenuItemMaster = /* GraphQL */ `
  mutation UpdateMenuItemMaster(
    $updateMENU_ITEM_MASTERInput: UpdateMENU_ITEM_MASTERInput!
  ) {
    updateMENU_ITEM_MASTER(
      updateMENU_ITEM_MASTERInput: $updateMENU_ITEM_MASTERInput
    ) {
      REST_ID
      MENU_IT_ID
      MENU_IT_NAME
      MENU_IT_DESC
      MENU_IT_PRICE
      MENU_IT_PIC_LOCATION
      POPULAR_YN
      MENU_IT_STATUS
      MENU_IT_TAXABLE_YN
    }
  }
`;
export const createUserOrderMaster = /* GraphQL */ `
  mutation CreateUserOrderMaster(
    $createUSER_ORDERInput: CreateUSER_ORDERInput!
  ) {
    createUSER_ORDER_MASTER(createUSER_ORDERInput: $createUSER_ORDERInput) {
      REST_ID
      CART_ID
      CART_TOTAL_ITEM_QUANTITY
      CART_TOTAL_COST
      CART_CREATE_DATE
      CART_STATUS
    }
  }
`;
export const createUserOrderItem = /* GraphQL */ `
  mutation CreateUserOrderItem(
    $createUSER_ORDER_ITEMInput: CreateUSER_ORDER_ITEMInput!
  ) {
    createUSER_ORDER_ITEM(
      createUSER_ORDER_ITEMInput: $createUSER_ORDER_ITEMInput
    ) {
      CART_ID
      CART_ITEM_NUM
      CART_ITEM_QUANTITY
      CART_ITEM_TOTAL_PRICE
      MENU_IT_ID
      CH_ITEM_ID
      CH_ITEM_DESC
      CH_ITEM_ID_ORDER
      CH_PRICE
      MENU_ITEM_INSTRUCTION
    }
  }
`;
export const createRestOrderGenerate = /* GraphQL */ `
  mutation CreateRestOrderGenerate(
    $createREST_ORDER_GENERATEInput: CreateREST_ORDER_GENERATEInput
  ) {
    createREST_ORDER_GENERATE(
      createREST_ORDER_GENERATEInput: $createREST_ORDER_GENERATEInput
    ) {
      REST_ID
      ORDER_ID
    }
  }
`;
export const createUserRestOrderWkflw = /* GraphQL */ `
  mutation CreateUserRestOrderWkflw(
    $createUSER_REST_ORDER_WKFLWInput: CreateUSER_REST_ORDER_WKFLWInput
  ) {
    createUSER_REST_ORDER_WKFLW(
      createUSER_REST_ORDER_WKFLWInput: $createUSER_REST_ORDER_WKFLWInput
    ) {
      REST_ID
      ORDER_ID
      CART_ID
      ORDER_STATUS
      ORDER_PICKUP_DATE_TIME
      ORDER_PAYMENT_ID
      DISCOUNT_ID
      DISCOUNT_APPLIED
      TAX_APPLIED
      ORDER_TOTAL_COST_BFR_TAX_BFR_DISC
      ORDER_TOTAL_FINAL_COST
      ORDER_COMMENTS
      ORDER_COMMENTS_BY
      USER_EMAIL
      ORDER_STATUS_DATE_TIME
      ORDER_TYPE
      PARTY_SIZE
      PAYMENT_STATUS
      USER_PHONE
      REWARDS_APPLIED
      CUSTOMER_NAME
      USER_OWNER
      REST_NAME
      TIP_AMOUNT
      PROCESSING_FEE
    }
  }
`;
export const createUserPaymentDetails = /* GraphQL */ `
  mutation CreateUserPaymentDetails(
    $createUSER_PAYMENT_DETAILSInput: CreateUSER_PAYMENT_DETAILSInput
  ) {
    createUSER_PAYMENT_DETAILS(
      createUSER_PAYMENT_DETAILSInput: $createUSER_PAYMENT_DETAILSInput
    ) {
      REST_ID
      ORDER_ID
      CART_ID
      ORDER_PAYMENT_ID
    }
  }
`;
export const createPaymentIntent = /* GraphQL */ `
  mutation CreatePaymentIntent(
    $amount: Int!
    $customerId: String
    $SaveCard: String
  ) {
    createPaymentIntent(
      amount: $amount
      customerId: $customerId
      SaveCard: $SaveCard
    ) {
      clientSecret
      customerId
    }
  }
`;
export const createPaymentConfirm = /* GraphQL */ `
  mutation CreatePaymentConfirm(
    $amount: Int!
    $customerId: String
    $billingInfo: String
  ) {
    createPaymentConfirm(
      amount: $amount
      customerId: $customerId
      billingInfo: $billingInfo
    ) {
      STRIPE_PAYMENT_STATUS
    }
  }
`;
export const createRestPollMaster = /* GraphQL */ `
  mutation CreateRestPollMaster(
    $createREST_POLL_MASTERInput: CreateREST_POLL_MASTER!
  ) {
    createREST_POLL_MASTER(
      createREST_POLL_MASTERInput: $createREST_POLL_MASTERInput
    ) {
      REST_ID
      POLL_ID
      POLL_NAME
      POLL_QUESTION
      POLL_START_DATE_TIME
      POLL_END_DATE_TIME
      POLL_CREATE_DATE_TIME
      POLL_STATUS
      POLL_CHOICE_ID_1
      POLL_CHOICE_1
      POLL_CHOICE_ID_2
      POLL_CHOICE_2
      POLL_CHOICE_ID_3
      POLL_CHOICE_3
      POLL_CHOICE_ID_4
      POLL_CHOICE_4
    }
  }
`;
export const updateRestPollMaster = /* GraphQL */ `
  mutation UpdateRestPollMaster(
    $UpdateREST_POLL_MASTER: UpdateREST_POLL_MASTER!
  ) {
    updateREST_POLL_MASTER(UpdateREST_POLL_MASTER: $UpdateREST_POLL_MASTER) {
      REST_ID
      POLL_ID
      POLL_NAME
      POLL_QUESTION
      POLL_START_DATE_TIME
      POLL_END_DATE_TIME
      POLL_CREATE_DATE_TIME
      POLL_STATUS
      POLL_CHOICE_ID_1
      POLL_CHOICE_1
      POLL_CHOICE_ID_2
      POLL_CHOICE_2
      POLL_CHOICE_ID_3
      POLL_CHOICE_3
      POLL_CHOICE_ID_4
      POLL_CHOICE_4
    }
  }
`;
export const createRestPollMasterAudit = /* GraphQL */ `
  mutation CreateRestPollMasterAudit(
    $CreateREST_POLL_MASTER_AUDIT: CreateREST_POLL_MASTER_AUDIT!
  ) {
    createREST_POLL_MASTER_AUDIT(
      CreateREST_POLL_MASTER_AUDIT: $CreateREST_POLL_MASTER_AUDIT
    ) {
      REST_ID
      POLL_ID
      POLL_NAME
      POLL_QUESTION
      POLL_START_DATE_TIME
      POLL_END_DATE_TIME
      POLL_CREATE_DATE_TIME
      POLL_STATUS
      POLL_CHOICE_ID_1
      POLL_CHOICE_1
      POLL_CHOICE_ID_2
      POLL_CHOICE_2
      POLL_CHOICE_ID_3
      POLL_CHOICE_3
      POLL_CHOICE_ID_4
      POLL_CHOICE_4
      UPDATED_AT
      UPDATED_BY
    }
  }
`;
export const createUserPollResponse = /* GraphQL */ `
  mutation CreateUserPollResponse(
    $createUSER_POLL_RESInput: CreateUSER_POLL_RESInput
  ) {
    createUSER_POLL_RESPONSE(
      createUSER_POLL_RESInput: $createUSER_POLL_RESInput
    ) {
      REST_ID
      POLL_ID
      POLL_CHOICE1_ID
      POLL_CHOICE1_VAL
      POLL_CHOICE2_ID
      POLL_CHOICE2_VAL
      POLL_CHOICE3_ID
      POLL_CHOICE3_VAL
      POLL_CHOICE4_ID
      POLL_CHOICE4_VAL
      POLL_CHOICE1_VOTES
      POLL_CHOICE2_VOTES
      POLL_CHOICE3_VOTES
      POLL_CHOICE4_VOTES
      POLL_TOTAL_RESP
      POLL_CHOICE1_PERC
      POLL_CHOICE2_PERC
      POLL_CHOICE3_PERC
      POLL_CHOICE4_PERC
      USER_PREF_POLL_CHOICE_VAL
    }
  }
`;
export const createOnesignalUser = /* GraphQL */ `
  mutation CreateOnesignalUser($ONESIGNAL_USERInput: ONESIGNAL_USERInput!) {
    createONESIGNAL_USER(ONESIGNAL_USERInput: $ONESIGNAL_USERInput) {
      USER_EMAIL
      PLAYER_ID
    }
  }
`;
export const updateOnesignalUser = /* GraphQL */ `
  mutation UpdateOnesignalUser($ONESIGNAL_USERInput: ONESIGNAL_USERInput!) {
    updateONESIGNAL_USER(ONESIGNAL_USERInput: $ONESIGNAL_USERInput) {
      USER_EMAIL
      PLAYER_ID
    }
  }
`;
export const updateRestOwnerContact = /* GraphQL */ `
  mutation UpdateRestOwnerContact(
    $REST_OWNER_CONTACTInput: REST_OWNER_CONTACTInput!
  ) {
    updateREST_OWNER_CONTACT(
      REST_OWNER_CONTACTInput: $REST_OWNER_CONTACTInput
    ) {
      REST_ID
      USER_PHONE
      PLAYER_ID
    }
  }
`;
export const createCartIdGenerate = /* GraphQL */ `
  mutation CreateCartIdGenerate {
    createCART_ID_GENERATE {
      CART_ID
    }
  }
`;
export const updateRestOrderPrepTime = /* GraphQL */ `
  mutation UpdateRestOrderPrepTime(
    $REST_ORDER_PREP_TIMEInput: REST_ORDER_PREP_TIMEInput!
  ) {
    updateREST_ORDER_PREP_TIME(
      REST_ORDER_PREP_TIMEInput: $REST_ORDER_PREP_TIMEInput
    ) {
      REST_ID
      ORDER_PREP_MIN_TIME
      ORDER_PREP_MAX_TIME
      REST_OPERATION_STATUS
    }
  }
`;
export const createReferenceInfo = /* GraphQL */ `
  mutation CreateReferenceInfo($REFERENCE_INFOInput: REFERENCE_INFOInput!) {
    createREFERENCE_INFO(REFERENCE_INFOInput: $REFERENCE_INFOInput) {
      USER_OWNER
      REF_USER_EMAIL
      REST_ID
      REST_NAME
      REWARDED_YN
      USER_REWARD_AMT
      CREATED_AT
    }
  }
`;
export const createStripeCustomer = /* GraphQL */ `
  mutation CreateStripeCustomer($STRIPE_CUSTOMERInput: STRIPE_CUSTOMERInput!) {
    createSTRIPE_CUSTOMER(STRIPE_CUSTOMERInput: $STRIPE_CUSTOMERInput) {
      STRIPE_CUSTOMER_ID
    }
  }
`;
export const deleteStripePayMethod = /* GraphQL */ `
  mutation DeleteStripePayMethod($STRIPE_CUSTOMERInput: STRIPE_CUSTOMERInput!) {
    DeleteSTRIPE_PAY_METHOD(STRIPE_CUSTOMERInput: $STRIPE_CUSTOMERInput) {
      STRIPE_PAYMENT_STATUS
    }
  }
`;
export const createMasterMenuChoiceItems = /* GraphQL */ `
  mutation CreateMasterMenuChoiceItems(
    $CreateMASTER_MENU_CHOICE_ITEMSInput: CreateMASTER_MENU_CHOICE_ITEMSInput!
  ) {
    CreateMASTER_MENU_CHOICE_ITEMS(
      CreateMASTER_MENU_CHOICE_ITEMSInput: $CreateMASTER_MENU_CHOICE_ITEMSInput
    ) {
      CH_ITEM_ID
      CH_ITEM_DESC
      CH_ITEM_ID_ORDER
      CH_DEFAULT
      CH_PRICE
      CH_ITEM_STATUS
    }
  }
`;
export const createAndSyncRestMenuChoices = /* GraphQL */ `
  mutation CreateAndSyncRestMenuChoices($REST_ID: String!) {
    CreateAndSyncREST_MENU_CHOICES(REST_ID: $REST_ID) {
      RETURN_STATUS
    }
  }
`;
export const updateAndSyncRestMenuChoices = /* GraphQL */ `
  mutation UpdateAndSyncRestMenuChoices($REST_ID: String!) {
    UpdateAndSyncREST_MENU_CHOICES(REST_ID: $REST_ID) {
      RETURN_STATUS
    }
  }
`;
export const createMyType = /* GraphQL */ `
  mutation CreateMyType(
    $input: CreateMyTypeInput!
    $condition: ModelMyTypeConditionInput
  ) {
    createMyType(input: $input, condition: $condition) {
      id
      title
      content
      price
      rating
    }
  }
`;
export const updateMyType = /* GraphQL */ `
  mutation UpdateMyType(
    $input: UpdateMyTypeInput!
    $condition: ModelMyTypeConditionInput
  ) {
    updateMyType(input: $input, condition: $condition) {
      id
      title
      content
      price
      rating
    }
  }
`;
export const deleteMyType = /* GraphQL */ `
  mutation DeleteMyType(
    $input: DeleteMyTypeInput!
    $condition: ModelMyTypeConditionInput
  ) {
    deleteMyType(input: $input, condition: $condition) {
      id
      title
      content
      price
      rating
    }
  }
`;
