import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Table,
  Tbody,
  Tr,
  Td,
  Thead,
  Th,
  Input,
  Image,
  Spinner,
  Checkbox,
  Text,
  Select
} from '@chakra-ui/react';
import React, { useRef, useState, useEffect } from 'react';
import { Auth, Hub, API, graphqlOperation, formContainer } from 'aws-amplify';
import { useToast, useDisclosure } from '@chakra-ui/react';
import Sidebar from '../../../components/sidebar';
import Header from '../../../components/Header';
import { EditIcon, CheckIcon, CloseIcon, AddIcon } from '@chakra-ui/icons';
import ExtrafoddIcon from '../../../assets/images/extrafood.png';
import { listMasterMenuChoices as ListMasterMenuChoices } from '../../../graphql/queries';
import { createAndSyncRestMenuChoices, createMasterMenuChoiceItems, updateAndSyncRestMenuChoices, updateMasterMenuChoiceItems as UpdateMasterMenuChoiceItems } from '../../../graphql/mutations';
import { updateMasterMenuChoices as UpdateMasterMenuChoices } from '../../../graphql/mutations';
import MenuCustomizePopup from '../../../components/MenuCustomizePopup';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faConciergeBell, faPlus } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';

function Customization() {
  const [togglebit, setTogglebit] = useState(false);
  const [item, setItem] = useState([]);
  const [itemInner, setIteminner] = useState([]);

  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: null,
  });
  const [Price, setPrice] = useState(null);
  const [label, setLabel] = useState('');
  const [itemStatus, setItemStatus] = useState('Active');
  
  const [itemDefault, setItemDefault] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingChoice, setIsLoadingChoice] = useState(false);
  const [isLoadingChoiceItem, setIsLoadingChoiceItem] = useState(false);
  const [newCustomizationRow, setNewCustomizationRow] = useState({
    custom_id: '',
    editable: false,
    label: '',
    price: '',
    status: 'Active',
    errorStatus: {
      label: false,
      price: false,
      status: false,
    },
    errrorField: {
      label: '',
      price: '',
      status: '',
    }
  })

  const [editObject, setEditObject] = useState(null);
  const toast = useToast();

  useEffect(() => {
    CustomizeData();
  }, []);

  function toggle() {
    setTogglebit(current => !current);
  }

  async function UpdateMenuChoiceItem(rowID, data) {
    try {
      setIsLoadingChoiceItem(true);
      const result = await API.graphql(
        graphqlOperation(UpdateMasterMenuChoiceItems, {
          updateMASTER_MENU_CHOICE_ITEMSInput: data,
        })
      );
      //	.then(()=>{showValidationMsg("Updated Successfully ",'success')})

      console.log('result:::::::::', result);

      setIsLoadingChoiceItem(false);

      let tmpArry = [...item];

      let pindex = tmpArry.findIndex(obj => obj.CH_ID === rowID);
      let cindex = tmpArry[pindex].relatedMasterChoiceItems.findIndex(
        obj => obj.CH_ITEM_ID === data.CH_ITEM_ID
      );
      tmpArry[pindex].relatedMasterChoiceItems[cindex] =
        result?.data.updateMASTER_MENU_CHOICE_ITEMS;
      console.log('tmpArry:::', tmpArry);
      setItem(tmpArry);
      showValidationMsg('Updated Successfully ', 'success');
    } catch (err) {
      console.log('error: ', err);
      showValidationMsg('Error occured', 'error');
    }
  }

  async function SaveManuChoice(value) {
    try {
      const obj = {
        CH_ID: value.CH_ID,
        CH_DESC: value.CH_DESC,
        // CH_ID_ORDER: value.CH_ID_ORDER,
        // MUST_CHOOSE_FLAG: value.MUST_CHOOSE_FLAG,
        // MULTI_SELECT_IND: value.MULTI_SELECT_IND,
        MIN_QTY: value.MIN_QTY,
        MAX_QTY: value.MAX_QTY,
        BASE_MODIFIER_IND: value.BASE_MODIFIER_IND,
        CH_LABEL: value.CH_LABEL,
        REST_ID: localStorage.getItem('Restaurantid'),
        //	relatedMasterChoiceItems:value.relatedMasterChoiceItems
      };
      setIsLoadingChoice(true);
      onClose();

      const result = await API.graphql(
        graphqlOperation(UpdateMasterMenuChoices, {
          updateMASTER_MENU_CHOICESInput: obj,
        })
      );
      //	.then(()=>{showValidationMsg("Updated Successfully ",'success')})

      setIsLoadingChoice(false);

      CustomizeData();
      showValidationMsg('Updated Successfully ', 'success');
    } catch (err) {
      console.log('error: ', err);
      showValidationMsg('Error occured', 'error');
      onClose();
    }
  }

  async function CustomizeData() {
    try {
      //debugger;

      setIsLoading(true);
      const result = await API.graphql(graphqlOperation(ListMasterMenuChoices));
      const cstm = result.data.listMASTER_MENU_CHOICES.filter(
        ob => ob.REST_ID == localStorage.getItem('Restaurantid')
      );
      console.log('cstm:::', cstm);
      //   setItem(result?.data.listMASTER_MENU_CHOICES);
      setItem(cstm);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  const onSave = ({ rowID, id, ordr, newPrice, newLabel, newStatus, newDefault }) => {
    //setIsSave(true)
    const data = {
      CH_ITEM_ID: id,
      CH_ITEM_ID_ORDER: ordr,
      CH_ITEM_DESC: newLabel,
      CH_PRICE: newPrice,
      CH_ITEM_STATUS: newStatus != null ? newStatus : 'Active',
      REST_ID: localStorage.getItem('Restaurantid'),
      CH_DEFAULT: newDefault,
    };
    console.log(data);
    UpdateMenuChoiceItem(rowID, data);

    //setSaveId(id);
    setPrice(newPrice);
    setLabel(newLabel);
    setInEditMode({
      status: false,
      rowKey: null,
    });
    //onCancel();
    //showValidationMsg("Menu item updated successfully", 'success')
  };

  const onEdit = ({ id, currentPrice, currentLabel, currentStatus, currentDefault }) => {
    console.log('currentDefault', currentDefault);
    setInEditMode({
      status: true,
      rowKey: id,
    });
    setPrice(currentPrice);
    setLabel(currentLabel);
    setItemStatus(currentStatus);
    setItemDefault(currentDefault);
    //setIsSave(false)
    //setSaveId('');
  };
  const onCancel = () => {
    // reset the inEditMode state value
    setInEditMode({
      status: false,
      rowKey: null,
    });
    // reset the unit price state value
    setPrice(null);
    setLabel('');
    setItemStatus('Active');
    setItemDefault(false);
  };

  //Show toast for validation message method
  function showValidationMsg(msg, st) {
    toast({
      title: '',
      description: msg,
      status: st,
      duration: 4000,
      isClosable: true,
      position: 'top',
    });
  }
  const { isOpen, onOpen, onClose } = useDisclosure();

  async function syncMenuFun() {
    try {
      setIsLoading(true);
      const creatsyncres = await API.graphql(graphqlOperation(createAndSyncRestMenuChoices, {
        REST_ID: localStorage.getItem('Restaurantid')
      }))

      const updatesyncres = await API.graphql(graphqlOperation(updateAndSyncRestMenuChoices, {
        REST_ID: localStorage.getItem('Restaurantid')
      }))
      console.log(creatsyncres, updatesyncres);

      showValidationMsg('Synced Successfully ', 'success');
      setIsLoading(false);
    } catch {
      showValidationMsg('Error occured', 'error');
      setIsLoading(false);
    }
  }

  function addCustomization(id) {
    setNewCustomizationRow((prev) => ({
      ...prev,
      custom_id: id,
      editable: true,
      label: '',
      price: '',
      errorStatus: {
        label: false,
        price: false,
      },
      errrorField: {
        label: '',
        price: '',
      }
    }))
  }

  function newValidation() {
    let isvalid = true;
    let obj = { ...newCustomizationRow }

    if (newCustomizationRow.label == '') {
      isvalid = false;
      obj.errorStatus.label = true;
      obj.errrorField.label = 'Label is required'
    } else {
      obj.errorStatus.label = false;
      obj.errrorField.label = ''
    }


    if (newCustomizationRow.price == '') {
      isvalid = false;
      obj.errorStatus.price = true;
      obj.errrorField.price = 'Price is required'
    } else {
      obj.errorStatus.price = false;
      obj.errrorField.price = ''
    }

    if (newCustomizationRow.status == '') {
      isvalid = false;
      obj.errorStatus.status = true;
      obj.errrorField.status = 'status is required'
    } else {
      obj.errorStatus.status = false;
      obj.errrorField.status = ''
    }

    setNewCustomizationRow(obj);

    return isvalid;
  }

  async function addCustomizationFun(data) {
    if (newValidation()) {
      try {
        setIsLoading(true);
        // let arr = data.relatedMasterChoiceItems.map(ele => {
        //   let splitarr = ele.CH_ITEM_ID.split('-')
        //   console.log(splitarr);
        //   return splitarr[2].slice(1,)
        // })
        // console.log(arr.sort());


        let obj = {
          REST_ID: localStorage.getItem('Restaurantid'),
          CH_GRP_ID: data.CH_GRP_ID,
          CH_ID: data.CH_ID,
          CH_DESC: data.CH_DESC,
          CH_ID_ORDER: data.CH_ID_ORDER,
          MUST_CHOOSE_FLAG: data.MUST_CHOOSE_FLAG,
          MULTI_SELECT_IND: data.MULTI_SELECT_IND,
          MIN_QTY: data.MIN_QTY,
          MAX_QTY: data.MAX_QTY,
          CH_ITEM_ID: uuidv4(),
          CH_ITEM_DESC: newCustomizationRow.label,
          CH_ITEM_ID_ORDER: data.relatedMasterChoiceItems.length + 1,
          CH_DEFAULT: false,
          CH_PRICE: Number(newCustomizationRow.price),
          CH_ITEM_STATUS: newCustomizationRow.status,
        }
        if (data.BASE_MODIFIER_IND) {
          obj.BASE_MODIFIER_IND = data.BASE_MODIFIER_IND;
        }

        if (data.CH_LABEL) {
          obj.CH_LABEL = data.CH_LABEL;
        }
        console.log(obj);
        const result = await API.graphql(graphqlOperation(createMasterMenuChoiceItems, { CreateMASTER_MENU_CHOICE_ITEMSInput: obj }))
        console.log(result);

        if (result.data?.CreateMASTER_MENU_CHOICE_ITEMS != null) {
          CustomizeData()

          setNewCustomizationRow({
            custom_id: '',
            editable: false,
            label: '',
            price: '',
            errorStatus: {
              label: false,
              price: false,
            },
            errrorField: {
              label: '',
              price: '',
            }
          })
          showValidationMsg('Added Successfully ', 'success');
          setIsLoading(false);
        } else {
          showValidationMsg('Error occured', 'error');
          setIsLoading(false);
        }

      } catch {
        showValidationMsg('Error occured', 'error');
        setIsLoading(false);
      }
    }

  }

  return (
    <Box bg="#f9f9f9">
      <Helmet>
        <title>Squash | Modifier Group</title>         {' '}
      </Helmet>

      <Header isAdmin={true} toggle={toggle} />
      <Box pt="70px" position="relative" h="100vh" overflow="hidden" d="flex">
        <Box
          zIndex="9"
          bg="#fff"
          position="absolute"
          top="0"
          pt="100px"
          left="0"
          h="100%"
          w={{
            base: togglebit ? '200px' : '0px',
            md: togglebit ? '200px' : '0px',
            lg: togglebit ? '0px' : '200px',
          }}
          display={{
            base: togglebit ? 'block' : 'none',
            md: togglebit ? 'block' : 'none',
            lg: togglebit ? 'none' : 'block',
          }}
        >
          <Sidebar current="Customization" togglebit={togglebit} />
        </Box>
        <Box
          ml={togglebit ? '0px' : { base: '0', sm: '0', md: '0', lg: '200px' }}
          w="100%"
          p="20px"
          overflow="auto"
        >
          <Box className="card no-padding card-flex ">
            {/* <Box className="card-header" position="relative">
              <Box className="title-heading"><h2>Menu Customize</h2></Box>
              <Box className="icons-wrapper  specials-head">
                <Box>
                  <Button className='primary-button  primary-btn  addspecialbtn'> <Text fontSize='14px'>Sync Menu</Text> </Button>
                </Box>
              </Box>
            </Box> */}
            <Box className="card-header">
              <Box className="title-heading" style={{ borderBottom: '1px solid #e8e8e8', display: 'flex', alignItems: 'stretch' }}>
                <h2 style={{ borderBottom: 'none', padding: '0px' }}>Menu Modifier Group</h2>
                <Button onClick={syncMenuFun} className='primary-button  primary-btn  addspecialbtn' style={{ margin: '0px 0px 10px 30px' }}> <Text fontSize='14px'>Sync Menu</Text> </Button>
              </Box>
            </Box>
            <Box className="menucustomtablemain card-content">
              <Box className="menucustomtablemaininr ">
                {isLoading ? (
                  <Box className="rspinbox">
                    {' '}
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="blue.500"
                      size="xl"
                    />
                  </Box>
                ) : (
                  <Table className="mc-table">
                    <Thead>
                      <Tr>
                        <Th w="27%" className="tablepadding sticky">
                          Label{' '}
                        </Th>
                        <Th w="9%" className="sticky menuscuzmobile">
                          Required{' '}
                        </Th>
                        <Th w="9%" className="sticky menuscuzmobile">
                          Min{' '}
                        </Th>
                        <Th w="9%" className="sticky menuscuzmobile">
                          Max{' '}
                        </Th>
                        <Th w="12%" className="sticky menuscuzmobile">
                          Flex Price{' '}
                        </Th>
                        <Th w="20%" className="sticky menuscuzmobile">
                          Menu Label{' '}
                        </Th>
                        <Th w="10%" className="sticky">
                          Action
                        </Th>
                      </Tr>
                    </Thead>
                    {isLoadingChoice ? (
                      <Box className="rspinbox">
                        {' '}
                        <Spinner
                          thickness="4px"
                          speed="0.65s"
                          emptyColor="gray.200"
                          color="blue.500"
                          size="xl"
                        />
                      </Box>
                    ) : (
                      ''
                    )}
                    <Tbody>
                      <Tr>
                        <Td colspan="7" className="mc-tablep0">
                          <Accordion
                            allowToggle
                            className="accmenucusbox"
                            padding="0"
                          >
                            {item
                              ? item.map(
                                (
                                  element,
                                  index
                                ) => {
                                  return (
                                    <AccordionItem>
                                      <Table>
                                        <Tbody>
                                          <Tr key={element.CH_ID}>
                                            <Td w="30%">
                                              <Box
                                                display="flex"
                                                alignItems="center"
                                              >
                                                <Box
                                                  className="fmenuicon"
                                                  mr={3}
                                                >
                                                  {' '}
                                                  <FontAwesomeIcon
                                                    icon={faConciergeBell}
                                                  />
                                                </Box>
                                                {element.CH_DESC}
                                              </Box>
                                            </Td>
                                            <Td
                                              w="10%"
                                              className="menuscuzmobile"
                                            >
                                              {element.MUST_CHOOSE_FLAG
                                                ? 'True'
                                                : 'False'}
                                            </Td>
                                            <Td
                                              w="10%"
                                              className="menuscuzmobile"
                                            >
                                              {element.MIN_QTY}
                                            </Td>                                            
                                            <Td
                                              w="10%"
                                              className="menuscuzmobile"
                                            >
                                              {element.MAX_QTY}
                                            </Td>
                                            <Td
                                              w="10%"
                                              className="menuscuzmobile"
                                            >
                                              {element.BASE_MODIFIER_IND ? 'True' : 'False'}
                                            </Td>
                                            <Td
                                              w="20%"
                                              className="menuscuzmobile"
                                            >
                                              {element.CH_LABEL}
                                            </Td>
                                            <Td w="10%">
                                              <Link>
                                                <EditIcon
                                                  mt="10px"
                                                  mr="10px"
                                                  color="blue.500"
                                                  onClick={() => {
                                                    onOpen();
                                                    setEditObject(item[index]);
                                                  }}
                                                />
                                              </Link>
                                            </Td>
                                            <AccordionButton
                                              fontSize="20px"
                                              mt="10px"
                                              w="30px"
                                              float="right"
                                            >
                                              <AccordionIcon />
                                            </AccordionButton>
                                          </Tr>
                                        </Tbody>
                                      </Table>

                                      <AccordionPanel pb={4}>
                                        <Box display="flex" alignItems="center" justifyContent="end" mb={3}>
                                          <Button onClick={() => { addCustomization(element.CH_ID); console.log(element); }} className='primary-button primary-btn addspecialbtn' style={{ minWidth: '190px' }}><FontAwesomeIcon m='3px' icon={faPlus} /> <Text fontSize='14px'>Add Modifier</Text> </Button>
                                        </Box>
                                        <Table>
                                          <Thead>
                                            <Tr>
                                              <Th>Label</Th>
                                              <Th>Price </Th>
                                              <Th>Default </Th>
                                              <Th>Status </Th>
                                              <Th>Action</Th>
                                            </Tr>
                                          </Thead>
                                          {isLoadingChoiceItem ? (
                                            <Box className="rspinbox">
                                              {' '}
                                              <Spinner
                                                thickness="4px"
                                                speed="0.65s"
                                                emptyColor="gray.200"
                                                color="blue.500"
                                                size="xl"
                                              />
                                            </Box>
                                          ) : (
                                            ''
                                          )}

                                          <Tbody>
                                            {element.relatedMasterChoiceItems?.map(
                                              obj => (
                                                <Tr key={obj.CH_ITEM_ID}>
                                                  <Td>
                                                    {inEditMode.status &&
                                                      inEditMode.rowKey ===
                                                      obj.CH_ITEM_ID ? (
                                                      <Input
                                                        value={label}
                                                        autoFocus={true}
                                                        onChange={event =>
                                                          setLabel(
                                                            event.target.value
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      //updatedObject?.CH_ITEM_ID === item.CH_ITEM_ID? updatedObject.CH_ITEM_DESC :
                                                      obj.CH_ITEM_DESC
                                                    )}
                                                  </Td>

                                                  <Td>
                                                    {inEditMode.status &&
                                                      inEditMode.rowKey ===
                                                      obj.CH_ITEM_ID ? (
                                                      <Input
                                                        value={Price}
                                                        onChange={event =>
                                                          setPrice(
                                                            event.target.value
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      //item.CH_PRICE
                                                      //updatedObject?.CH_ITEM_ID === item.CH_ITEM_ID? updatedObject.CH_PRICE :
                                                      obj.CH_PRICE
                                                    )}
                                                  </Td>

                                                  <Td>
                                                    {inEditMode.status &&
                                                      inEditMode.rowKey ===
                                                      obj.CH_ITEM_ID ? (
                                                      <Checkbox isChecked={itemDefault} onChange={(event) => {
                                                        setItemDefault(
                                                          event.target.checked
                                                        )
                                                      }}></Checkbox>
                                                    ) : (
                                                      obj.CH_DEFAULT ? 'Yes' : 'No'
                                                    )}
                                                  </Td>

                                                  <Td>
                                                    {inEditMode.status &&
                                                      inEditMode.rowKey ===
                                                      obj.CH_ITEM_ID ? (
                                                      <Select value={itemStatus} onChange={(event) => {
                                                        setItemStatus(
                                                          event.target.value
                                                        )
                                                      }}>
                                                        <option value='ACTIVE'>ACTIVE</option>
                                                        <option value='Not Available'>Not Available</option>
                                                      </Select>
                                                    ) : (
                                                      obj.CH_ITEM_STATUS
                                                    )}
                                                  </Td>

                                                  <Td>
                                                    {inEditMode.status &&
                                                      inEditMode.rowKey ===
                                                      obj.CH_ITEM_ID ? (
                                                      <Box display="flex">
                                                        <Box>
                                                          {' '}
                                                          <Link>
                                                            {' '}
                                                            <CheckIcon
                                                              className="ticongreen"
                                                              color="green.500"
                                                              onClick={() =>
                                                                onSave({
                                                                  rowID: element.CH_ID,
                                                                  id: obj.CH_ITEM_ID,
                                                                  ordr: obj.CH_ITEM_ID_ORDER,
                                                                  newPrice:
                                                                    Price,
                                                                  newLabel:
                                                                    label,
                                                                  newStatus: itemStatus,
                                                                  newDefault: itemDefault,
                                                                })
                                                              }
                                                            />
                                                          </Link>
                                                        </Box>

                                                        <Box ml="25px">
                                                          {' '}
                                                          <Link>
                                                            {' '}
                                                            <CloseIcon
                                                              color="red.500"
                                                              className="ticonred"
                                                              onClick={() =>
                                                                onCancel()
                                                              }
                                                            />
                                                          </Link>
                                                        </Box>
                                                      </Box>
                                                    ) : (
                                                      <Link>
                                                        <EditIcon
                                                          color="blue.500"
                                                          onClick={() =>
                                                            onEdit({
                                                              id: obj.CH_ITEM_ID,
                                                              currentPrice:
                                                                obj.CH_PRICE,
                                                              currentLabel:
                                                                obj.CH_ITEM_DESC,
                                                              currentStatus: obj.CH_ITEM_STATUS,
                                                              currentDefault: obj.CH_DEFAULT,
                                                            })
                                                          }
                                                        />
                                                      </Link>
                                                    )}
                                                  </Td>
                                                </Tr>
                                              )
                                            )}

                                            {
                                              newCustomizationRow.custom_id === element.CH_ID &&
                                              newCustomizationRow.editable &&
                                              <Tr>
                                                <Td>
                                                  <Input
                                                    isInvalid={newCustomizationRow.errorStatus.label}
                                                    value={newCustomizationRow.label}
                                                    autoFocus={true}
                                                    onChange={event => {
                                                      if (event.target.value == '') {
                                                        setNewCustomizationRow((prev) => ({
                                                          ...prev,
                                                          label: event.target.value,
                                                          errorStatus: {
                                                            ...prev.errorStatus,
                                                            label: true
                                                          },
                                                          errrorField: {
                                                            ...prev.errrorField,
                                                            label: 'label is required'
                                                          },
                                                        }))
                                                      } else {
                                                        setNewCustomizationRow((prev) => ({
                                                          ...prev,
                                                          label: event.target.value,
                                                          errorStatus: {
                                                            ...prev.errorStatus,
                                                            label: false
                                                          },
                                                          errrorField: {
                                                            ...prev.errrorField,
                                                            label: ''
                                                          },
                                                        }))
                                                      }

                                                    }
                                                    }
                                                  />
                                                  <label style={{ color: 'crimson', fontSize: '13px', paddingLeft: '15px' }}>{newCustomizationRow.errrorField.label}</label>
                                                </Td>

                                                <Td>
                                                  <Input
                                                    isInvalid={newCustomizationRow.errorStatus.price}
                                                    value={newCustomizationRow.price}
                                                    type='number'
                                                    onChange={event => {
                                                      if (event.target.value == '') {
                                                        setNewCustomizationRow((prev) => ({
                                                          ...prev,
                                                          price: event.target.value,
                                                          errorStatus: {
                                                            ...prev.errorStatus,
                                                            price: true
                                                          },
                                                          errrorField: {
                                                            ...prev.errrorField,
                                                            price: 'price is required'
                                                          },
                                                        }))
                                                      } else {
                                                        setNewCustomizationRow((prev) => ({
                                                          ...prev,
                                                          price: event.target.value,
                                                          errorStatus: {
                                                            ...prev.errorStatus,
                                                            price: false
                                                          },
                                                          errrorField: {
                                                            ...prev.errrorField,
                                                            price: ''
                                                          },
                                                        }))
                                                      }

                                                    }
                                                    }
                                                  />
                                                  <label style={{ color: 'crimson', fontSize: '13px', paddingLeft: '15px' }}>{newCustomizationRow.errrorField.price}</label>
                                                </Td>

                                                <Td>
                                                  <Select value={newCustomizationRow.status} onChange={(event) => {
                                                    if (event.target.value == '') {
                                                      setNewCustomizationRow((prev) => ({
                                                        ...prev,
                                                        status: event.target.value,
                                                        errorStatus: {
                                                          ...prev.errorStatus,
                                                          status: true
                                                        },
                                                        errrorField: {
                                                          ...prev.errrorField,
                                                          status: 'Status is required'
                                                        },
                                                      }))
                                                    } else {
                                                      setNewCustomizationRow((prev) => ({
                                                        ...prev,
                                                        status: event.target.value,
                                                        errorStatus: {
                                                          ...prev.errorStatus,
                                                          status: false
                                                        },
                                                        errrorField: {
                                                          ...prev.errrorField,
                                                          status: ''
                                                        },
                                                      }))
                                                    }
                                                  }}>
                                                    <option value='Active'>Active</option>
                                                    <option value='Not Available'>Not Available</option>
                                                  </Select>
                                                  {
                                                    newCustomizationRow.errorStatus.status &&
                                                    <label style={{ color: 'crimson', fontSize: '13px', paddingLeft: '15px' }}>{newCustomizationRow.errrorField.status}</label>
                                                  }
                                                </Td>

                                                <Td>
                                                  <Box display="flex">
                                                    <Box>
                                                      {' '}
                                                      <Link>
                                                        {' '}
                                                        <CheckIcon
                                                          className="ticongreen"
                                                          color="green.500"
                                                          onClick={() => addCustomizationFun(element)}
                                                        />
                                                      </Link>
                                                    </Box>

                                                    <Box ml="25px">
                                                      {' '}
                                                      <Link>
                                                        {' '}
                                                        <CloseIcon
                                                          color="red.500"
                                                          className="ticonred"
                                                          onClick={() =>
                                                            setNewCustomizationRow({
                                                              custom_id: '',
                                                              editable: false,
                                                              label: '',
                                                              price: '',
                                                              status: 'Active',
                                                              errorStatus: {
                                                                label: false,
                                                                price: false,
                                                                status: false
                                                              },
                                                              errrorField: {
                                                                label: '',
                                                                price: '',
                                                                status: ''
                                                              }
                                                            })
                                                          }
                                                        />
                                                      </Link>
                                                    </Box>
                                                  </Box>
                                                </Td>
                                              </Tr>
                                            }
                                          </Tbody>
                                        </Table>
                                      </AccordionPanel>
                                    </AccordionItem>
                                  )
                                }
                              )
                              : null}
                          </Accordion>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <MenuCustomizePopup
        SaveManuChoice={SaveManuChoice}
        object={editObject}
        open={isOpen}
        close={onClose}
      />
    </Box>
  );
}
export default Customization;
