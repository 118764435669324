import {
  Box,
  Button,
  Text,
  Spinner,
  IconButton,
  Center,
  Square,
  Flex,
  List,
  ListItem,
  Spacer

} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import UserSidebar from '../../src/components/UserSidebar'
import Header from './Header';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import Cookies from 'js-cookie';

import { Helmet } from 'react-helmet';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { listUserRestRewardBaLs, listRestMasteRs } from "../graphql/queries";


const RewardPoint = () => {
    const history = useHistory();
    const[cartData,setCartData] = useState([]);
    const [togglebit, setTogglebit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    let rewardSum = 0;
    const [rewrds, setRewards] = useState(null);

    useEffect(() => {
        getUserRewardsBalance();
		let today = moment(new Date(), "YYYY-MM-DD");
    let formattedtoday = moment(today, "YYYY-MM-DD").format("YYYY-MM-DD");

    var json_cartitems = Cookies.get('cartitems');
    if (json_cartitems) {
      const items = JSON.parse(json_cartitems);
      setCartData(items);
      
      const cartlen = items.filter(
        ob => ob.cart_created_date >= formattedtoday
      );
      console.log(cartlen)
      if(cartlen==0){
        Cookies.remove('cartitems');
      }   
      //const a = 10;
      console.log("cart",items);
    
    
    }
        }, []);

        async function getUserRewardsBalance() {
            setIsLoading(true)
            let restList = [];
            let rewardList = [];
            //let REST_ID_saved = await AsyncStorage.getItem("REST_ID");
            ////debugger;
            const resBal = await API.graphql(graphqlOperation(listUserRestRewardBaLs));
            restList = resBal.data.listUSER_REST_REWARD_BALs;
            restList?.forEach(item => {
                // Get Restaurent names
                rewardList.push({REST_ID: item.REST_ID, item: item.REWARD_BAL});
            });
            const getResName = await API.graphql(graphqlOperation(listRestMasteRs));
            rewardList?.forEach(item => {
                let getData = getResName?.data.listREST_MASTERs.find(x => x.REST_ID === item.REST_ID);
                    if (getData?.REST_ID !== undefined) {
                        item.Name = getData.REST_NAME;
                    }
            });
            setRewards(rewardList);
            setIsLoading(false)
        
          }

        function toggle() {
            setTogglebit(current => !current);
        }
	
  return (
        <Box bg='#f9f9f9'>
			<Helmet>
                <title>Squash | Reward Point</title>
			</Helmet>
			<Header isAdmin={false} toggle={toggle} />
			<Box pt='70px' position='relative' h='100vh' overflow='hidden' d='flex'>
				<Box  zIndex='9' bg='#fff' position='absolute' top='0' pt='100px' left='0' h='100%' w={(togglebit ? "200px" : '0px')} display={(togglebit ? 'block' : "none")}>
					<UserSidebar />
				</Box>
                {isLoading ? (<Box className="rspinbox" > <Spinner
					thickness="4px"
					speed="0.65s"
					emptyColor="gray.200"
					color="blue.500"
					size="xl"
				/></Box >) :			
				<Box w='100%' p='20px' overflow='auto'>
                {rewrds == null ?  null : (
					<Box className="card content-card"> 
                    
                        <Box className="mapBox" d='flex' pb='5' justifyContent='space-between' alignItems='center'>
                            <Text className='user-title'>Reward Points</Text>
                        </Box>
                                       
                        <Box justifyContent='space-between' alignItems='center' w='100%'>
                     
                            <Flex color='#3D3D3D' bg='#F6FAFF'>
                                <Box  flex='0.7' px={{base:'5px',sm:'15px'}} py='10px' alignItems='center' >    
                                    <Text fontWeight='700'>Restaurant Name</Text>
                                </Box>
                                
                                <Box  flex='0.3' px={{base:'5px',sm:'15px'}} py='10px' alignItems='center' >
                                    <Text fontWeight='700'>Rewards</Text>
                                </Box>                                
                            </Flex>

                            
                            {rewrds != null
                                ? rewrds.map((item) => {
                                    ////debugger;
                                    rewardSum += item.item;
                                    
                                    return (
                                        
                            <Flex borderBottom='1px solid #f0f0f0'> 
                                <Box flex='0.7' px={{base:'5px',sm:'15px'}} py='7px' alignItems='center'> 
                                

                                    <Text>{item.Name}</Text>
                                </Box>
                                <Box flex='0.3'  px={{base:'5px',sm:'15px'}} py='7px' alignItems='center'>
                                    <Text> {item?.item && item.item >= 0 ? item?.item : '0.00'}</Text>
                                </Box>                              
                                                             
                            </Flex>
                           
                            )})
                        :  null } 
                        </Box>
                       
                        <Box p='15px'>
                     <Text textAlign='right'>Total Rewards : <strong>{rewardSum >= 0 ? rewardSum : '0.00'}</strong></Text>
                     
                     <Text></Text>
                </Box>
                        
                    </Box>
                 )} 
                
                    <Box className="fixed-position">
                        {(cartData.length == 0) ? ('') : (
                            <Button w='auto' minWidth={{ base: '30px', md: '34px' }} h={{ base: '30px', md: '34px' }} 
                                p='0px' background='#001d56' color='#ffffff' as={IconButton} aria-label="Options"
                                icon={<FontAwesomeIcon fontSize='22px' icon={faShoppingCart} />} variant="outline"
                                onClick={() => {
                                          history.push(
                                              {
                                                  pathname: '/RestaurantMenu/'+ cartData[0]?.restId
                                              }
                                            );
                                        }}
                                />)}
                    </Box>
                </Box>
}
			</Box >
        </Box>
					

		
	);
    
};
export default RewardPoint;
