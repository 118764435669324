import {
   
    Box,
    Input,
    Button,
    Text,
    Checkbox,
    Modal,
    ModalHeader,
    ModalOverlay,
    ModalCloseButton,
    ModalBody,
    ModalContent,
    ModalFooter,
    List,
    ListItem,
    ListIcon,
    Heading

} from "@chakra-ui/react";
import React, { useRef, useState, useEffect } from "react";
import { EditIcon, StarIcon } from '@chakra-ui/icons'
import { useHistory } from 'react-router-dom';
import { CUIAutoComplete } from 'chakra-ui-autocomplete'
import Select from 'react-select'
import jwt_decode from "jwt-decode";
function RestaurantSelectPopup(props) {

    const [restaurantName ,SetRestaurantname]=useState('')
    const [restaurantId ,SetRestaurantId]=useState('')
    const [selected,SetSelected]=useState(localStorage.getItem('Restaurantid')? {value:localStorage.getItem('Restaurantid'),label:localStorage.getItem('Restaurantname')}:'please select')
    const [listData,SetListData]=useState([])

    const history = useHistory();

    useEffect(() => {
       // SetSelected( localStorage.getItem('Restaurantid'))
        LoadData();
       
      
      }, []);
     
 

      function LoadData()
      {

        const data = props.resData?.map(({ REST_ID, REST_NAME }) =>
        ({value: REST_ID,label: REST_NAME }))
        SetListData(data);

      }
    function handlechange(event)
    {
        
        SetRestaurantId(event.value)
        SetSelected(event.value)

        // var selectedOption = event.target[event.target.selectedIndex]
        // var name = selectedOption.getAttribute('label');
        
        SetRestaurantname(event.label)
        localStorage.setItem('Restaurantname',restaurantName);
        localStorage.setItem('Restaurantid',restaurantId);
        //console.log(name);
       
    }
    function signprocess()
    {
        localStorage.setItem('Restaurantname',restaurantName);
        localStorage.setItem('Restaurantid',restaurantId);

        // const userToken = localStorage?.getItem('jwtToken');
        // const User = userToken ? jwt_decode(userToken) : null;

        // //const token=localStorage.getItem('jwtToken');
        // const group = jwt_decode(userToken)['cognito:groups']

        // if (User &&group === undefined || group.includes('sqaush_user')) {
         
        //   history.push(
        //     {
        //       //pathname: `/Admin/${localStorage.getItem('Currentpage')}`,
        //        pathname: '/Home',
        //     //  state: { email:props.userinfo?.email, password: props.userinfo?.pass }
        //     }
        //   );
        // }
        // else if (group?.includes('squash_super_admin') ||
        //   group?.includes('rest_super_admin') ||
        //   group?.includes('rest_frontdesk_users')) {

        // //  userinfo = { email: values.email, pass: values.password }
        //  // console.log(isLoading);
        //  // onOpen();

        //  history.push(
        //   {
        //     pathname: `/Admin/${localStorage.getItem('Currentpage')}`,
           
        //   }
        // );

        // }

        history.push(
          {
            pathname: `/Admin/${localStorage.getItem('Currentpage')}`,
           
          }
        );
        
      props.modalClose();
      if(!props.fromlogin)
      {
        window.location.reload()
      } 
    }

const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'canillv', label: 'canillv' },
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'canilla', label: 'canilla' },
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'venilla', label: 'Venilla' },
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'canilla', label: 'canilla' },
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'canilla', label: 'canilla' },
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'canilla', label: 'canilla' },
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
  ]

    return (
        <>


            <Modal blockScrollOnMount={false}
                isOpen={props.open}
                onClose={props.modalClose}
                size={'sx', 'md', 'xl', 'xl'}

            >
                <ModalOverlay />
                <ModalContent>
                    {/* <ModalHeader>Choose Restaurant</ModalHeader>
                    <ModalCloseButton /> */}
                    <ModalBody>

                  

                        <Heading pt='10px'  pb='10px'  fontSize='18px'>Choose restaurant</Heading>



                        <Select 
                      
                         placeholder='select'
                        // defaultValue={selected}
                         onChange={handlechange}
                         options={listData} />


                        {/* <Select value={selected}  placeholder='--------------'  onChange={handlechange}>
                            {props.resData?.map((item) =>
                                <option label={item.REST_NAME}  value={item.REST_ID}></option>
                            )
                            }

                        </Select> */}

                        {/* <CUIAutoComplete
                    
          label="Choose a restaurant"
          placeholder="Type a Country"
          //onCreateItem={handleCreateItem}
          items={listData}
          selectedItems={selectedItems}
          onSelectedItemsChange={(changes) =>
            handleSelectedItemsChange(changes.selectedItems)
          }
        /> */}


                    </ModalBody>

                    <ModalFooter>
                        <Box className="popup-buttons">
                            <Button  className="secondary-btn"  mr={2} onClick={props.modalClose}>Cancel</Button>


                            <Button 
                            
                            className="primary-btn" 
                              onClick={signprocess}
                             color='#fff' bg="#0554F2" ml={2}   >
                                Next
                      </Button>


                        </Box>

                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
export default RestaurantSelectPopup;