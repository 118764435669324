import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { IoTEvents } from 'aws-sdk'
import React from 'react'
import moment from 'moment-js'

function ReportsData(props) {
    return (
        <TableContainer>
            <Table variant={'striped'} colorScheme={'blue'} width={'100%'}>
                <Thead>
                    <Tr>
                        <Th className="sticky special-data-column">
                            ORDER ID
                        </Th>
                        <Th className="sticky special-data-column">
                            ORDER DATE
                        </Th>
                        <Th className="sticky special-data-column">
                            CUSTOMER NAME
                        </Th>
                        <Th className="sticky special-data-column">
                            ORDER TYPE
                        </Th>
                        <Th className="sticky special-data-column">
                            ORDER STATUS
                        </Th>
                        <Th className="sticky special-data-column">
                            PAYMENT MODE
                        </Th>
                        <Th className="sticky special-data-column">
                            ORDER TOTAL
                        </Th>
                        <Th className="sticky special-data-column">
                            REWARDS
                        </Th>
                        <Th className="sticky special-data-column">
                            TAX
                        </Th>
                        <Th className="sticky special-data-column">
                            DISCOUNT
                        </Th>
                        <Th className="sticky special-data-column">
                            FINAL TOTAL
                        </Th>
                        {
                            props?.showMenuItems == true ?
                                <Th className="sticky special-data-column" W="30%">
                                    MENU ITEMS
                                </Th>
                                :
                                null
                        }
                    </Tr>
                </Thead>
                <Tbody>
                    {/* <Tr>
                    <Td colspan="7">
                        <Table> */}
                    {props.data.map(item => (
                        <Tr key={item.ORDER_ID}>
                            <Td>
                                {item.ORDER_ID}
                            </Td>
                            <Td>
                                {moment(item.ORDER_DATE).format('MM/DD/YYYY HH:mm:ss')}
                            </Td>
                            <Td>
                                {item.CUSTOMER_NAME}
                            </Td>
                            <Td>
                                {item.ORDER_TYPE}
                            </Td>
                            <Td>
                                {item.ORDER_STATUS}
                            </Td>
                            <Td>
                                {item.PAYMENT_STATUS}
                            </Td>
                            <Td>
                                ${item.ORDER_TOTAL_COST_BFR_TAX_BFR_DISC?.toFixed(2)}
                            </Td>
                            <Td>
                                ${item.REWARDS_APPLIED?.toFixed(2)}
                            </Td>
                            <Td>
                                ${item.TAX_APPLIED?.toFixed(2)}
                            </Td>
                            <Td>
                                ${item.DISCOUNT_APPLIED?.toFixed(2)}
                            </Td>
                            <Td>
                                ${item.ORDER_TOTAL?.toFixed(2)}
                            </Td>
                            {
                                props?.showMenuItems == true ?
                                    <Td>
                                        {item.ORDER_ITEMS}
                                    </Td>
                                    :
                                    null
                            }
                        </Tr>
                    ))}
                    {/* </Table>
                    </Td>
                </Tr> */}
                </Tbody>
            </Table>
        </TableContainer>
    )
}

export default ReportsData