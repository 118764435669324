import {
    Box,
    Button,
    Flex,
    Heading,
    Input,
    Image,
    Spinner,
    Spacer,
    Text,
    FormControl,
    InputGroup,
    InputLeftElement,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { EmailIcon, UnlockIcon } from '@chakra-ui/icons'
import React, { useRef, useState, useEffect } from "react";
import Logo from '../../assets/images/Squash_FINALB.png'

import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import * as yup from 'yup';
import { Formik, Form, Field, useFormik } from 'formik';
import { useLocation } from "react-router-dom";
import { useToast } from "@chakra-ui/react"
import { Helmet } from 'react-helmet';

const resetPasswordValidationSchema = yup.object().shape({
    code: yup
        .string()
        .max(6, 'Validation code must be 6 characters')
        .matches(/^\d+$/, 'Invalid validation code')
        .required('Validation code is required'),
    password: yup
    
        .string()
        .min(8, ({ min }) => `Password must be at least ${min} characters`)
        .max(30, "Password must be 30 characters or less")
        .required("Password is required"),
    confirmPassword: yup
        .string()
        .equals([yup.ref("password")], "Passwords don't match")
        .required("Re-enter password is required"),
})



const ResetPassword = () => {

    const password = useRef(null);
    const confirmPassword = useRef(null);
    const history = useHistory();
    const toast = useToast();
    let location = useLocation();
    const [isLoading, setIsLoading] = useState(false);


    async function changePassword(values) {
        setIsLoading(true)
        let username = location.state.email;
        await Auth.forgotPasswordSubmit(username, values.code, values.password)
            .then(data => {
                showValidationMsg('Password changed successfully ','success')
                console.log(data)
                setIsLoading(false)
                history.push(
                    {
                        pathname: '/',

                    }
                );
            }).catch(error => {
                setIsLoading(false)
                showValidationMsg(error.message,'error')
            });
    }


    //Show toast for validation message method
    function showValidationMsg(msg,type) {
        toast({
            title: "",
            description: msg,
            status: type,
            duration: 9000,
            isClosable: true,
            position: "top"
        })

    }



    return (
        <Flex h="100vh" w="100%" p={["50px", "60px", "60px", "100px"]} bgGradient="linear(to-r, #0554F2, #00B2FF)"  >
            <Helmet>
                <title>Squash | Reset Pssword</title>
            </Helmet>
            <Flex justifyContent="center" alignItems="center" direction={{ base: "column", md: "column", xl: "row", lg: "row" }} maxWidth="1300" marginLeft="auto" marginRight="auto" w="100%" bg="white" borderRadius="25px" boxShadow='10px 10px 35px #037bf8' >

                <Box w="50%" d={{ base: "none", md: "none", xl: "flex", lg: "flex" }} justifyContent="center" alignItems="center" flex="1"  >
                    <Image maxWidth="350px" src={Logo} />

                </Box>

                <Box w="70%" justifyContent="center" alignItems="flex-end" d={{ base: "flex", md: "flex", xl: "none", lg: "none" }} flex=".5" p={["20px", "40px", "40px", "0px"]}>
                    <Image src={Logo} />

                </Box>

                <Box w="100%" d="flex" justifyContent="center" alignItems={{ base: "flex-start", md: "flex-start", xl: "center", lg: "center" }} flex="1"  >

                    <Flex width="full" align="center" justifyContent="center">


                        <Box p={2} w={["80%", "80%", "60%", "60%"]}>
                            <Box textAlign="center">
                                <Heading fontSize={["sm", "md", "lg", "xl"]} >Reset Password</Heading>
                            </Box>
                            <Box pt="20px" my={4} textAlign="left">

                                <Formik
                                    initialValues={{
                                        code: "",
                                        password: "",
                                        confirmPassword: ""
                                    }}

                                    validationSchema={resetPasswordValidationSchema}
                                    onSubmit={(values, { setSubmitting }) => {
                                        if(values.password.trim()=='')
                                        return;

                                        changePassword(values)
                                        setSubmitting(false)
                                    }}
                                >
                                    {(formik) => {
                                        const { errors, touched, isValid, dirty } = formik;
                                        return (
                                            <form onSubmit={formik.handleSubmit} >
                                                <FormControl>
                                                    <InputGroup>
                                                        <InputLeftElement
                                                            pointerEvents="none"
                                                            children={<EmailIcon color="gray.300" />}
                                                        />
                                                        <Input
                                                            fontSize={["13px", "13px", "18px", "18px"]}
                                                            type="code"
                                                            placeholder="Enter Validation Code"
                                                            onChange={formik.handleChange('code')}
                                                            onBlur={formik.handleBlur('code')}
                                                            value={formik.values.code}
                                                            error={formik.errors.code}
                                                            touched={formik.touched.code}

                                                        />
                                                    </InputGroup>

                                                </FormControl>
                                                {formik.errors.code && formik.touched.code && <Text className="validationColor">{" "} {formik.errors.code}</Text>}
                                                <FormControl mt={6}>
                                                    <InputGroup>
                                                        <InputLeftElement
                                                            pointerEvents="none"
                                                            children={<UnlockIcon color="gray.300" />}
                                                        />
                                                        <Input
                                                            fontSize={["13px", "13px", "18px", "18px"]}
                                                            ref={password}
                                                            type="password"
                                                            placeholder="Enter New Password"
                                                            onChange={formik.handleChange('password')}
                                                            value={formik.values.password.trim()}
                                                            error={formik.errors.password}
                                                            onBlur={formik.handleBlur('password')}
                                                            touched={formik.touched.password}

                                                        />
                                                    </InputGroup>

                                                </FormControl>
                                                {formik.errors.password && formik.touched.password && <Text className="validationColor">{" "} {formik.errors.password}</Text>}

                                                <FormControl mt={6}>
                                                    <InputGroup>
                                                        <InputLeftElement
                                                            pointerEvents="none"
                                                            children={<UnlockIcon color="gray.300" />}
                                                        />
                                                        <Input
                                                            fontSize={["13px", "13px", "18px", "18px"]}
                                                            ref={confirmPassword}
                                                            type="password"
                                                            placeholder="Re-Enter New Password"
                                                            onChange={formik.handleChange('confirmPassword')}
                                                            value={formik.values.confirmPassword.trim()}
                                                            error={formik.errors.confirmPassword}
                                                            onBlur={formik.handleBlur('confirmPassword')}
                                                            touched={formik.touched.confirmPassword}

                                                        />
                                                    </InputGroup>

                                                </FormControl>
                                                {formik.errors.confirmPassword && formik.touched.confirmPassword && <Text className="validationColor">{" "} {formik.errors.confirmPassword}</Text>}
                                                <Box>
                                                    <Flex mt={4} display={isLoading ? 'flex' : 'none'} >
                                                        <Box w='45%'></Box>
                                                        <Spacer />
                                                        <Box w='55%' >
                                                            <Spinner
                                                                thickness="4px"
                                                                speed="0.65s"
                                                                emptyColor="gray.200"
                                                                color="blue.500"
                                                                size="xl"
                                                            />
                                                        </Box>

                                                    </Flex>

                                                    <Button d={isLoading ? 'none' : 'block'} className='primary-button' bg="#0554F2" width="full" mt={4} type="submit">
                                                        Reset Password
                                                    </Button>
                                                </Box>
                                            </form>
                                        );
                                    }}
                                </Formik>



                            </Box>
                        </Box>
                    </Flex>

                </Box>

            </Flex>

        </Flex>
    );
}

export default ResetPassword;
