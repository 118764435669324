import {
  Box,
  Button,
  Text,
  Spinner,
  IconButton,
  Center,
  Square,
  Flex,
  List,
  ListItem,
  Spacer,
  Tab,
  Tabs,
  TabPanel,
  TabList,
  TabPanels,
  useDisclosure
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import UserSidebar from '../../src/components/UserSidebar';
import Header from './Header';
import { faShoppingCart, faConciergeBell } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';

import Cookies from 'js-cookie';
import UserOrderHistoryPopup from './UserOrderHistoryPopup'

import { Helmet } from 'react-helmet';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getAllordersByUser as ListUserOrders } from '../graphql/queries';

const OrderHistory = () => {
  const history = useHistory();
  const [cartData, setCartData] = useState([]);
  const [togglebit, setTogglebit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [orderid, setorderid] = useState(null);
  const [restName,setRestName] = useState(null)
  const { isOpen, onOpen, onClose } = useDisclosure();


  useEffect(() => {
    getOrdersList();

    let today = moment(new Date(), "YYYY-MM-DD");
    let formattedtoday = moment(today, "YYYY-MM-DD").format("YYYY-MM-DD");

    var json_cartitems = Cookies.get('cartitems');
    if (json_cartitems) {
      const items = JSON.parse(json_cartitems);
      setCartData(items);
      
      const cartlen = items.filter(
        ob => ob.cart_created_date >= formattedtoday
      );
      console.log(cartlen)
      if(cartlen==0){
        Cookies.remove('cartitems');
      }   
      //const a = 10;
      console.log("cart",items);
    
    
    }
  }, []);

  async function getOrdersList() {
    let res = null;
    setIsLoading(true);
    try {
      res = await API.graphql(graphqlOperation(ListUserOrders));
      setOrderList(res.data.getALLORDERS_BY_USER);
    } catch (e) {
      console.log(e);
    }
    setIsLoading(false);
  }

  function toggle() {
    setTogglebit(current => !current);
  }

  return (
    <Box bg="#f9f9f9">
      <Helmet>
        <title>Squash | Order History</title>
      </Helmet>
      <Header isAdmin={false} toggle={toggle} />
      <Box pt="70px" position="relative" h="100vh" overflow="hidden" d="flex">
        <Box
          zIndex="9"
          bg="#ffffff"
          position="absolute"
          top="0"
          pt="100px"
          left="0"
          h="100%"
          w={togglebit ? '200px' : '0px'}
          display={togglebit ? 'block' : 'none'}
        >
          <UserSidebar />
        </Box>
        {isLoading ? (
          <Box className="rspinbox">
            {' '}
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Box>
        ) : (
          <Box w="100%" p="20px" overflow="auto">
            <Box className="card content-card">
              <Box
                className="mapBox"
                d="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text className='user-title'>
                  Order History
                </Text>
              </Box>

              <Box justifyContent="space-between" alignItems="center" w="100%">
                <Box className="search-result-tab">
                  <Tabs m="5px" variant="soft-rounded" colorScheme="blue">
                    <Box>
                      <TabPanels>
                        <TabPanel p="0" pt="1">
                          <Box className="notification-list">
                          <UserOrderHistoryPopup orderid={orderid} restName={restName}  open={isOpen} close={() => { onClose();  }} />
                            {orderList?.length == 0
                              ? ''
                              : orderList.map((obj, ind) => (
                                  <Box
                                    mb="15px"
                                    p="10px 15px"
                                    borderRadius="10px"
                                    border="1px solid #C3DDFF;"
                                 
                                    cursor="pointer"
                                    onClick={() => {onOpen();
                                         {setorderid(obj.ORDER_ID)};
                                         {setRestName(obj.REST_ID)};
                                       }}
                                  >
                                    
                                    <Box d='flex' alignItems={{base:'flex-start', sm:'center'}} flexDirection={{base:'column', sm:'row'}} >
                                    <Box className="fmenuicon" mr={3}>  <FontAwesomeIcon icon={faConciergeBell} /></Box>
                                    <Box>
                                    <Text fontWeight="bold"  fontSize='16px' pb="1">
                                      {obj.REST_NAME}
                                    </Text>
                                      <Box d="flex">
                                        <Text color='#747474' fontSize='14px' fontWeight="medium" pr="1">
                                          Order ID :{' '}
                                        </Text>
                                        <Text color='#747474' fontSize='14px' fontWeight="light">
                                          {obj.ORDER_ID}
                                        </Text>
                                      </Box>
                                      </Box>

                                      <Box d='flex' ml={{base:'0', sm:'auto'}} mt={{base:'3', sm:'0'}} alignItems='center'>
																	<Text  fontSize='14px' pr='1'>Order Status </Text>
													
																	{/* <Text className="notification-status">{obj.ORDER_STATUS}</Text> */}
																	 {obj.ORDER_STATUS ==="Submitted"  ? <Text className="notification-status">{obj.ORDER_STATUS}</Text>
																	 : obj.ORDER_STATUS ==="Completed" ? <Text className="notification-status" bg='#def3e7' color='#00B507'>{obj.ORDER_STATUS}</Text> 
                                                                     : obj.ORDER_STATUS ==="Rejected" ? <Text className="notification-status" bg='#f6e2e7' color='#EF0707'>{obj.ORDER_STATUS}</Text>
																	: obj.ORDER_STATUS ==="Accepted" ? <Text className="notification-status">{obj.ORDER_STATUS}</Text>
																	: obj.ORDER_STATUS ==="Ready for Pickup" ? <Text className="notification-status">{obj.ORDER_STATUS}</Text>
																	: obj.ORDER_STATUS ==="Checked-in" ? <Text className="notification-status">{obj.ORDER_STATUS}</Text>
																	:''}	
																	 
																	
																</Box>
                                    </Box>
                                  </Box>
                                ))}
                          </Box>
                        </TabPanel>
                      </TabPanels>
                    </Box>
                  </Tabs>
                </Box>
              </Box>
            </Box>

            <Box className="fixed-position">
              {cartData.length == 0 ? (
                ''
              ) : (
                <Button
                  w="auto"
                  minWidth={{ base: '30px', md: '34px' }}
                  h={{ base: '30px', md: '34px' }}
                  p="0px"
                  background="#001d56"
                  color="#ffffff"
                  as={IconButton}
                  aria-label="Options"
                  icon={
                    <FontAwesomeIcon fontSize="22px" icon={faShoppingCart} />
                  }
                  variant="outline"
                  onClick={() => {
                    history.push({
                      pathname: '/RestaurantMenu/' + cartData[0]?.restId,
                    });
                  }}
                />
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default OrderHistory;
