import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Link,
  Image,
  Text,
  FormControl,
  InputGroup,
  InputLeftElement
} from "@chakra-ui/react";
import React from "react";
import { EmailIcon } from '@chakra-ui/icons'
import Logo from '../../assets/images/Squash_FINALB.png'
import { useHistory, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Formik, Form, Field, useFormik } from 'formik';
import * as yup from 'yup';
import { useToast } from "@chakra-ui/react"


const ConfirmSignUp = () => {

  const history = useHistory();
  let location = useLocation();
  const toast = useToast();


  // validations for form submit
  const ConfirmSignUpValidationSchema = yup.object().shape({
    confirmationcode: yup
      .string()
      .max(6, "Invalid confirmation code")
      .matches(/^\d+$/, "Invalid confirmation code")
      .required("Confirmation code is required")

  });

  //Show toast for validation message method
  function showValidationMsg(msg) {
    toast({
      title: "",
      description: msg,
      status: "error",
      duration: 9000,
      isClosable: true,
      position: "top"
    })

  }


  //function to resend confirmation code
  async function resentCode() {
    try {
      let username = location.state.email;
      await Auth.resendSignUp(username);
      //   showSuccessMsg("Confirmation code resent successfully");
    } catch (error) {
      showValidationMsg(error.message);
      console.log("Failed to resent confirmation code", error);
    }
  }



  // function to validate the confirmation code sign up
  async function confirmSignUp(values) {
    try {
      // setIsLoading(true);
      let code = values.confirmationcode;
      let username = location.state.email;

      const email = location.state.email;
      const password = location.state.password;

      //Validate confirmation code
      await Auth.confirmSignUp(username, code);

      //Sign in the user after verification of code
      await Auth.signIn(email, password).then((err) =>
        history.goBack()
      );

      //  setIsLoading(false);
    } catch (error) {
      showValidationMsg(error.message);
      console.log(" Error confirm sign up...", error);
      //setIsLoading(false);
    }
  }



  return (
    <Flex h="100vh" w="100%" p={["50px", "60px", "60px", "100px"]} bgGradient="linear(to-r,  #0554F2, #00B2FF)"  >

      <Flex justifyContent="center" alignItems="center" direction={{ base: "column", md: "column", xl: "row", lg: "row" }} w="100%" bg="white" borderRadius="25px" boxShadow='10px 10px 35px #037bf8' >

        <Box w="50%" d={{ base: "none", md: "none", xl: "flex", lg: "flex" }} justifyContent="center" alignItems="center" flex="1"  >
          <Image maxWidth="350px" src={Logo} />

        </Box>

        <Box w="70%" justifyContent="center" alignItems="flex-end" d={{ base: "flex", md: "flex", xl: "none", lg: "none" }} flex=".5" p={["20px", "40px", "40px", "0px"]}>
          <Image src={Logo} />

        </Box>

        <Box w="100%" d="flex" justifyContent="center" alignItems={{ base: "flex-start", md: "flex-start", xl: "center", lg: "center" }} flex="1"  >

          <Flex width="full" align="center" justifyContent="center">


            <Box p={2} w={["80%", "80%", "60%", "60%"]} >
              <Box textAlign="center">

                <Heading fontSize={["sm", "md", "lg", "xl"]} >Activation Code</Heading>
              </Box>
              <Box my={4} textAlign="left">


                <Formik
                  initialValues={{
                    confirmationcode: ""
                  }}
                  validationSchema={ConfirmSignUpValidationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    confirmSignUp(values);
                    setSubmitting(false)
                  }}
                >
                  {(formik) => {
                    const { errors, touched, isValid, dirty } = formik;
                    return (
                      <form onSubmit={formik.handleSubmit} >
                        <FormControl my={5}>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              children={<EmailIcon color="gray.300" />}
                            />

                            <Input
                              type="text"
                              placeholder="Activation code"
                              onChange={formik.handleChange('confirmationcode')}
                              onBlur={formik.handleBlur('confirmationcode')}
                              touched={formik.touched.email}
                              error={errors.confirmationcode}
                            />

                          </InputGroup>
                        </FormControl>
                        {errors.confirmationcode && touched.confirmationcode && (
                          <Text className="validationColor" >
                            {" "}
                            {errors.confirmationcode}
                          </Text>
                        )}
                        <Button className='primary-button' bg="#0554F2" width="full" mt={4} type="submit">
                          NEXT
                </Button>
                      </form>

                    );
                  }}
                </Formik>

              </Box>
              <Box w='100%' textAlign='center'>
                <Text fontSize={["8px", "sm", "md", "md"]}  >
                  Didn't get Activation code ..? {" "}<br />
                  <Link onClick={() => resentCode()} color="teal.500" justifyContent="center" alignItems="center">
                    Click here
                    </Link>
                  {" "} to resend it again.
                </Text>
              </Box>

            </Box>

          </Flex>
        </Box>

      </Flex>

    </Flex>
  );
}

export default ConfirmSignUp;
