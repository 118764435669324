import {
    Box,
    Button,
    Text,
    Spinner,
    IconButton,
    Input,
    FormControl,
    InputGroup,
    InputLeftElement,
    Link,
  } from '@chakra-ui/react';
  import React, { useEffect, useState, useRef } from 'react';
  import UserSidebar from '../../src/components/UserSidebar';
  import Header from './Header';
  import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
  import { useHistory } from 'react-router-dom';
  import { Formik, Form, Field, useFormik } from 'formik';
  import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
  import { EmailIcon, UnlockIcon } from '@chakra-ui/icons';
  import { useToast, useDisclosure } from "@chakra-ui/react"
  import moment from 'moment';
  import * as yup from 'yup';
  import Cookies from 'js-cookie';
  
  import { Helmet } from 'react-helmet';
  
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { text } from '@fortawesome/fontawesome-svg-core';
  
  const ValidationSchema = yup.object().shape({
  
    userName: yup.string().required('user name is required'),
    emailId: yup.string().required('email id is required'),
    phoneNumber: yup.string().required('phone number is required'),
  
  });
  
  const UserProfile = () => {
    const history = useHistory();
    const [cartData, setCartData] = useState([]);
    const [togglebit, setTogglebit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const toast = useToast()
    //var username;
    
  
    useEffect(() => {
        
        
      let today = moment(new Date(), "YYYY-MM-DD");
    let formattedtoday = moment(today, "YYYY-MM-DD").format("YYYY-MM-DD");

    var json_cartitems = Cookies.get('cartitems');
    if (json_cartitems) {
      const items = JSON.parse(json_cartitems);
      setCartData(items);
      
      const cartlen = items.filter(
        ob => ob.cart_created_date >= formattedtoday
      );
      console.log(cartlen)
      if(cartlen==0){
        Cookies.remove('cartitems');
      }   
      //const a = 10;
      console.log("cart",items);
    
    
    }
      checkIfAuthenticated();
      
        
      ////debugger
    }, []);  

    
    async function  checkIfAuthenticated() {
        try{
        let user = await Auth.currentAuthenticatedUser();
        const { attributes } = user;
        ////debugger;
        setName(attributes.name);
        setEmail(attributes.email);
        setPhoneNumber(attributes.phone_number);
        console.log("attributes", attributes);
        //console.log('hiiiiiiii');
        
        ////debugger;
        }
        catch(err){
            console.log(err)
        }
      };
  
    function toggle() {
      setTogglebit(current => !current);
    }
    
   async function update(data) {
      //debugger;
      console.log(data);
    //   setOldPassword(data.userName);
    //   setNewPassword(data.newpassword);
    //   setConfirmPassword(data.confirmPassword);
      
    let User = await Auth.currentAuthenticatedUser();
    let result = await Auth.updateUserAttributes(User, {
      name: data.userName,
      phone_number: data.phoneNumber,
    }).then(() => { 
          // //debugger;
            console.log(data)
              toast({
                title: "",
                description: "Profile Updated Successfully",
                status: "success",
                duration: 9000,
                isClosable: true,
                position: "top"
              })
          })
          .catch((err) => {
            // showValidationMsg("incorrect old passowrd");
            console.log(err);
            toast({
              title: "",
              description: "Invalid Format.Add Phone number with country code",
              status: "error",
              duration: 9000,
              isClosable: true,
              position: "top"
            })
           
          });
          console.log("nnnnnnnnnnnnnnnn",result)
         
    }
    
    return (
      <Box bg="#f9f9f9">
        <Helmet>
          <title>Squash | User Profile</title>
        </Helmet>
        <Header isAdmin={false} toggle={toggle} />
        <Box pt="70px" position="relative" h="100vh" overflow="hidden" d="flex">
          <Box
           // className="user-sidebar"
            zIndex="9"
            bg="#ffffff"
            position="absolute"
            top="0"
            pt="100px"
            left="0"
            h="100%"
            w={togglebit ? '200px' : '0px'}
            display={togglebit ? 'block' : 'none'}
          >
            <UserSidebar />
          </Box>
          {isLoading ? (
            <Box className="rspinbox">
              {' '}
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Box>
          ) : (
            <Box  w="100%" p="20px" overflow="auto">
              <Box className="card content-card">
                <Box
                  className="mapBox"
                  d="flex"
                  pb="3"
                  justifyContent="space-between"
                  alignItems="center" >
                  <Text className='user-title'>
                   User Profile
                  </Text>
                </Box>
                <Box>
                    
                  <Formik

                  enableReinitialize
                  
                  initialValues={{
                    userName: name,
                    emailId: email,
                    phoneNumber: phoneNumber,
                  }}
                    onSubmit={(values, { setSubmitting }) => {
                      // alert(JSON.stringify(values, null, 2));
                      //alert(name);
                      ////debugger;
                      setSubmitting(false);
                      update(values)
                    }}
                   validationSchema={ValidationSchema}
                  >

                    {formik => {
                      return (
                        <form onSubmit={formik.handleSubmit}>
                           <Box className='user-profile'>
                          <FormControl mt={6}>
                            <InputGroup>
                              {/* <InputLeftElement
                                pointerEvents="none"
                                children={<UnlockIcon color="gray.300" />}
                              /> */}
                              <Input
                                fontSize={['13px', '13px', '18px', '18px']}
                                type="text"
                                
                                onChange={formik.handleChange('userName')}
                                value={formik.values.userName}
                                //value={name}
                                error={formik.errors.userName}
                                onBlur={formik.handleBlur('userName')}
                                touched={formik.touched.userName}
                              />
                            </InputGroup>
                            {formik.errors.userName &&
                            formik.touched.userName && (
                              <Text className="validationColor">
                                {' '}
                                {formik.errors.userName}
                              </Text>
                            )}
                             
                          </FormControl>
                       
              
                            
  
                          <FormControl mt={6}>
                            <InputGroup>
                              {/* <InputLeftElement
                                pointerEvents="none"
                                children={<UnlockIcon color="gray.300" />}
                              /> */}
                              <Input
                                fontSize={['13px', '13px', '18px', '18px']}
                                type="text"
                                readOnly= {true}
                                onChange={formik.handleChange('emailId')}
                                 value={formik.values.emailId}
                                // value={email}
                                error={formik.errors.emailId}
                                onBlur={formik.handleBlur('emailId')}
                                touched={formik.touched.emailId}
                              />
                            </InputGroup>
                            {formik.errors.emailId &&
                            formik.touched.emailId && (
                              <Text className="validationColor">
                                {' '}
                                {formik.errors.emailId}
                              </Text>
                            )}
                          </FormControl>
                        
                           
                          <FormControl mt={6}>
                            <InputGroup>
                              {/* <InputLeftElement
                                pointerEvents="none"
                                children={<UnlockIcon color="gray.300" />}
                              /> */}
                              <Input
                                fontSize={['13px', '13px', '18px', '18px']}
                                type="text"
                                
                                onChange={formik.handleChange('phoneNumber')}
                                value={formik.values.phoneNumber}
                                //value={phoneNumber}
                                error={formik.errors.phoneNumber}
                                onBlur={formik.handleBlur('phoneNumber')}
                                touched={formik.touched.phoneNumber}
                              />
                            </InputGroup>
                            {formik.errors.phoneNumber &&
                            formik.touched.phoneNumber && (
                              <Text className="validationColor">
                                {' '}
                                {formik.errors.phoneNumber}
                              </Text>
                              
                            )}
                          </FormControl>
                         
                         
                         </Box>
                          <Box textAlign='right'>
                            <Button
                              disabled={formik.isSubmitting}
                              display={isLoading ? 'none' : 'inline-block'}
                              className="primary-btn"
                              mt={4}
                              px={4}
                              type="submit"
                            >
                              Update Profile
                            </Button>
                          </Box>
                        </form>
                      );
                    }}
                  </Formik>
  
                </Box>
              </Box>
  
              <Box className="fixed-position">
                {cartData.length == 0 ? (
                  ''
                ) : (
                  <Button
                    w="auto"
                    minWidth={{ base: '30px', md: '34px' }}
                    h={{ base: '30px', md: '34px' }}
                    p="0px"
                    background="#001d56"
                    color="#ffffff"
                    as={IconButton}
                    aria-label="Options"
                    icon={
                      <FontAwesomeIcon fontSize="22px" icon={faShoppingCart} />
                    }
                    variant="outline"
                    onClick={() => {
                      history.push({
                        pathname: '/RestaurantMenu/' + cartData[0]?.restId,
                      });
                    }}
                  />
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    );
  };
  export default UserProfile;
  