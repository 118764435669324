import {
  Box,
  Button,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  FormLabel,
  Input,
  FormControl,
  Select,
  HStack,
  Text,
  Stack,
  EditablePreview,
  Badge,
} from '@chakra-ui/react';
import { Multiselect } from 'multiselect-react-dropdown';
import React, { useRef, useState, useEffect } from 'react';
import * as yup from 'yup';
import { Formik, Form, Field, FieldArray, useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { Storage, Auth, Hub, API, graphqlOperation } from 'aws-amplify';
import PreviewPopup from './PreviewPopup';
import { useToast, useDisclosure } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

let ValidationSchema = yup.object().shape({
  fromDate: yup.date().required('Start date is required'),
  toDate: yup.date().required('End date is required'),
  promoPrice: yup.number().required('price is required'),
});

function MenuPromoPopup(props) {
  const initialRef = React.useRef();
  const finalRef = React.useRef();
  const toast = useToast();
  
  let today = moment(new Date(), 'YYYY-MM-DD');
  let formattedtoday = moment(today, 'YYYY-MM-DD').format('YYYY-MM-DD');

  const cancelPromo = () => {
    const inputData = {
      REST_ID: localStorage.getItem('Restaurantid'),
      MENU_IT_ID: props.object?.MENU_IT_ID,
      SPECIAL_PROMOTION_YN: 'N',
    };
    props.UpdatePromo(inputData);
  };

  function showValidationMsg(msg, st) {
    toast({
      title: '',
      description: msg,
      status: st,
      duration: 9000,
      isClosable: true,
      position: 'top',
    });
  }

  return (
    <>
      <Modal
        size={('sx', 'md', 'xl', 'xl')}
        isOpen={props.open}
        onClose={props.close}
        scrollBehavior="inside"
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
      >
        <ModalOverlay />
        <ModalContent className="mpopup">
          <ModalHeader>Update Menu</ModalHeader>
          <ModalCloseButton className="popup-btn" />
          <ModalBody pb={6}>
            <Formik
              initialValues={{
                fromDate: props.object?.MENU_IT_NAME,
                toDate: props.object?.MENU_IT_DESC,
                promoPrice: props.object?.MENU_IT_PRICE,
              }}
              validationSchema={ValidationSchema}
              onSubmit={(value, { setSubmitting }) => {
                //debugger;
                if (
                  !props?.isupdate &&
                  (value.startdate <= formattedtoday ||
                    value.enddate <= formattedtoday)
                ) {
                  //debugger;
                  showValidationMsg(
                    'Error occured  :Dates should be greater or equal to today',
                    'error'
                  );
                  setSubmitting(false);
                } else if (value.startdate > value.enddate) {
                  //debugger;
                  showValidationMsg(
                    'Error occured: Start date should be smaller than End date',
                    'error'
                  );
                  setSubmitting(false);
                } else {
                  const inputData = {
                    REST_ID: localStorage.getItem('Restaurantid'),
                    MENU_IT_ID: props.object?.MENU_IT_ID,
                    SPECIAL_PROMOTION_YN: 'Y',
                    PROMOTIONAL_PRICE: value.promoPrice,
                    DATE_FROM_DATE: value.fromDate,
                    DATE_TO_DATE: value.toDate,
                  };
                  props.UpdatePromo(inputData);
                  setSubmitting(false);
                }
              }}
            >
              {formik => {
                return (
                  <form onSubmit={formik.handleSubmit}>
                    <Box display="flex" className="mspecialbox">
                      <FormControl mt={4} pr="10px">
                        <FormLabel>Start Date</FormLabel>
                        <Input
                          type="date"
                          onChange={formik.handleChange('fromDate')}
                          onBlur={formik.handleBlur('fromDate')}
                          value={formik.values.fromDate}
                          error={formik.errors.fromDate}
                          touched={formik.touched.fromDate}
                        />
                        {formik.errors.fromDate && formik.touched.fromDate && (
                          <Text className="validationColor">
                            {' '}
                            {formik.errors.fromDate}
                          </Text>
                        )}
                      </FormControl>

                      <FormControl mt={4}>
                        <FormLabel>End Date</FormLabel>
                        <Input
                          type="date"
                          onChange={formik.handleChange('toDate')}
                          onBlur={formik.handleBlur('toDate')}
                          value={formik.values.toDate}
                          error={formik.errors.toDate}
                          touched={formik.touched.toDate}
                        />
                        {formik.errors.toDate && formik.touched.toDate && (
                          <Text className="validationColor">
                            {' '}
                            {formik.errors.toDate}
                          </Text>
                        )}
                      </FormControl>
                    </Box>

                    <Box display="flex">
                      <FormControl mt={4} mr={4}>
                        <FormLabel>Promo Price ($)</FormLabel>
                        <Input
                          type="number"
                          onChange={formik.handleChange('promoPrice')}
                          onBlur={formik.handleBlur('promoPrice')}
                          value={formik.values.promoPrice}
                          error={formik.errors.promoPrice}
                          touched={formik.touched.promoPrice}
                        />
                        {formik.errors.promoPrice &&
                          formik.touched.promoPrice && (
                            <Text className="validationColor">
                              {' '}
                              {formik.errors.promoPrice}
                            </Text>
                          )}
                      </FormControl>
                    </Box>

                    <Box className="popup-buttons">
                      {/* <Button w='90px' bg="#00B2FF" className="secondary-btn" color='#fff' mr={2} onClick={props.close}>Cancel</Button> */}

                      <Button
                        className="secondary-btn"
                        mr={2}
                        onClick={cancelPromo}
                      >
                        Cancel Promotion
                      </Button>

                      <Button className="primary-btn" type="submit" ml={2}>
                        Promote as Special
                      </Button>
                    </Box>
                  </form>
                );
              }}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default MenuPromoPopup;
