import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Link,
  Image,
  Text,
  FormControl,
  InputGroup,
  Spacer,
  Spinner,
  InputLeftElement,
} from '@chakra-ui/react';
import { Router, Switch, Route } from 'react-router-dom';
import { EmailIcon, UnlockIcon } from '@chakra-ui/icons';
import React, { useRef, useState, useEffect } from 'react';
import Logo from '../../assets/images/Squash_FINALB.png';

import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import * as yup from 'yup';
import { Formik, Form, Field, useFormik } from 'formik';
import { useLocation } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';

const forgotPasswordValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter valid email')
    .max(50, 'Email must be 50 characters or less')
    .required('Email address is required'),
});

function ForgotPassword() {
  const history = useHistory();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  //method to call amplify forgotPassword method
  async function forgotPassword(values) {
    try {
      setIsLoading(true);
      await Auth.forgotPassword(values.email);
      setIsLoading(false);
      showValidationMsg('Verification code is sent to the mail', 'success');
      history.push({
        pathname: '/ResetPassword',
        state: { email: values.email },
      });

      //navigation.navigate("ResetPassword", { email: values.email })
    } catch (error) {
      if (error.code == 'UserNotFoundException') {
        showValidationMsg('Email not found', 'error');
      } else {
        showValidationMsg(error.message, 'error');
      }
      setIsLoading(false);
      console.log(' Error signing in...', error);
    }
  }

  //Show toast for validation message method
  function showValidationMsg(msg, type) {
    toast({
      title: '',
      description: msg,
      status: type,
      duration: 9000,
      isClosable: true,
      position: 'top',
    });
  }

  return (
    <Flex Flex className="LoginWrapper" h="100vh" w="100%">
      <Helmet>
        <title>Squash | Forgot Password</title>
      </Helmet>
      <Flex
         justifyContent="center"
         alignItems="center"
         direction={{ base: 'column', md: 'column', xl: 'row', lg: 'row' }}
         w="100%"
         bg="white"
         maxWidth="1300"
         marginLeft="auto"
         marginRight="auto"
         borderRadius="30px"
         boxShadow="0px 5px 50px rgba(3, 74, 166, 0.05);"
      >
        <Box
          w="50%"
          d={{ base: 'none', md: 'none', xl: 'flex', lg: 'flex' }}
          justifyContent="center"
          alignItems="center"
          flex="1"
        >
          <Image maxWidth="350px" src={Logo} />
        </Box>

        <Box
          w="70%"
          justifyContent="center"
          alignItems="flex-end"
          d={{ base: 'flex', md: 'flex', xl: 'none', lg: 'none' }}
          flex=".5"
          p={['20px', '40px', '40px', '0px']}
        >
          <Image src={Logo} />
        </Box>

        <Box
          w="100%"
          d="flex"
          justifyContent="center"
          alignItems={{
            base: 'flex-start',
            md: 'flex-start',
            xl: 'center',
            lg: 'center',
          }}
          flex="1"
        >
          <Flex width="full" align="center" justifyContent="center">
            <Box p={2} w={['80%', '80%', '60%', '60%']}>
              <Box textAlign="center">
                <Heading fontSize={['sm', 'md', 'lg', 'xl']}>
                  Forgot Password
                </Heading>
              </Box>
              <Box pt="20px" my={4} textAlign="left">
                <Formik
                  initialValues={{
                    email: '',
                  }}
                  validationSchema={forgotPasswordValidationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    forgotPassword(values);
                    setSubmitting(false);
                  }}
                >
                  {formik => {
                    const { errors, touched, isValid, dirty } = formik;
                    return (
                      <form onSubmit={formik.handleSubmit}>
                        <FormControl>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              children={<EmailIcon color="gray.300" />}
                            />
                            <Input
                              type="email"
                              placeholder="Email"
                              onChange={formik.handleChange('email')}
                              onBlur={formik.handleBlur('email')}
                              value={formik.values.email}
                              error={formik.errors.email}
                              touched={formik.touched.email}
                            />
                          </InputGroup>
                        </FormControl>
                        {formik.errors.email && formik.touched.email && (
                          <Text className="validationColor">
                            {' '}
                            {formik.errors.email}
                          </Text>
                        )}
                        <Box>
                          <Flex mt={4} display={isLoading ? 'flex' : 'none'}>
                            <Box w="45%"></Box>
                            <Spacer />
                            <Box w="55%">
                              <Spinner
                                thickness="4px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="blue.500"
                                size="xl"
                              />
                            </Box>
                          </Flex>

                          <Button
                            display={isLoading ? 'none' : 'block'}
                            className="primary-btn"
                        
                            width="full"
                            mt={4}
                            type="submit"
                          >
                            Send Me Code
                          </Button>
                        </Box>
                      </form>
                    );
                  }}
                </Formik>
              </Box>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
}

export default ForgotPassword;
