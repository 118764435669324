import React, { useEffect, useState } from "react";
import {
  Button,
  ChakraProvider,
  Box,
  CSSReset,
  theme,
} from '@chakra-ui/react';

import Routes from './Routes';
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
//import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import '../node_modules/react-toastify/dist/ReactToastify.css';
import config from  './aws-exports'
Amplify.configure(config)



function App() {

  useEffect(() => {
  }, []);
  

  return (
    <ChakraProvider theme={theme}>
      <CSSReset />
      <Box >
        <Routes />
      </Box>
    </ChakraProvider>

  );
}

export default App;
