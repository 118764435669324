import {
  Box,
  Input,
  Button,
  Text,
  Checkbox,
  Modal,
  Spinner,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  ModalFooter,
  List,
  ListItem,
  ListIcon,
  Span,
} from '@chakra-ui/react';
import React, { useRef, useState, useEffect } from 'react';
import { EditIcon, StarIcon } from '@chakra-ui/icons';
import TimePicker from 'rc-time-picker';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import { getOrdersByRestAndOrder as getOrdersByRestAndOrder } from '../graphql/queries';
import { createUserRestOrderWkflw as createUserRestOrderWkflw } from '../graphql/mutations';
import moment from 'moment';

function AdminOrderHistoryPopup(props) {
  // const orderDetails = props.orderDetails;
  // const orderDetailsSplitt = orderDetails.orderDetails.split("|");
  // const order_id = orderDetailsSplitt[0];
  // const rest_id = orderDetailsSplitt[1];
  const [orderDate, setOrderDate] = useState();
  const [data, setData] = useState();
  const [restName, setRestName] = useState(
    localStorage.getItem('Restaurantid')
  );
  const [customerName, setCustomerName] = useState();
  const [comments, setComments] = useState();
  const [paymentStatus, setPaymentStatus] = useState('');

  useEffect(() => {
    getOrderDetails();
    userDetails();
  }, [props.orderid]);
  // useEffect(() => {
  //   try {
  //     getOrderDetails();
  //     userDetails();
  //   } catch (error) {
  //     alert("Network Error");
  //   }
  // }, []);

  async function getOrderDetails() {
    let res = null;
    setData('');
    setOrderDate('')
    ////debugger;

    try {
      const res = await API.graphql(
        graphqlOperation(getOrdersByRestAndOrder, {
          REST_ID: localStorage.getItem('Restaurantid'),
          ORDER_ID: props.orderid,
        })
      );
      ////debugger;
      if (res) {
        console.log(res.data.getORDERS_BY_REST_AND_ORDER);
        setData(res.data.getORDERS_BY_REST_AND_ORDER);
        let OrderData = res.data.getORDERS_BY_REST_AND_ORDER;
        console.log(OrderData);
        console.log(res.data.getORDERS_BY_REST_AND_ORDER);
        setData(res.data.getORDERS_BY_REST_AND_ORDER);
        let orderData = res.data.getORDERS_BY_REST_AND_ORDER;
        setRestName(res.data.getORDERS_BY_REST_AND_ORDER.REST_NAME);
        setComments(res.data.getORDERS_BY_REST_AND_ORDER.ORDER_COMMENTS);
        if (res.data.getORDERS_BY_REST_AND_ORDER.PAYMENT_STATUS === 'Pay Now')
          setPaymentStatus('Paid');
        if (
          res.data.getORDERS_BY_REST_AND_ORDER.PAYMENT_STATUS ===
          'Pay at Restaurant'
        )
          setPaymentStatus(res.data.getORDERS_BY_REST_AND_ORDER.PAYMENT_STATUS);
        if (res.data.getORDERS_BY_REST_AND_ORDER.PAYMENT_STATUS === null)
          setPaymentStatus('Payment Status NULL');
        //setPaymentStatus('Paid');

        const unixTime =
          res.data.getORDERS_BY_REST_AND_ORDER.ORDER_PICKUP_DATE_TIME;
        const date = new Date(unixTime * 1000);
        // setOrderDate(moment(date).format("YYYY-MM-DD HH:MM:SS"));
        setOrderDate(moment(date).format('MM-DD-YYYY h:mm a'));

        ////debugger;
        //setorderid(item.ORDER_ID)
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function updateOrderStatus(item) {
    ////debugger;
    try {
      const orderData = {
        REST_ID: item.REST_ID,
        ORDER_ID: item.ORDER_ID,
        CART_ID: item.CART_ID,
        ORDER_PAYMENT_ID: 1,
        TAX_APPLIED: item.TAX_APPLIED,
        ORDER_TOTAL_COST_BFR_TAX_BFR_DISC:
          item.ORDER_TOTAL_COST_BFR_TAX_BFR_DISC,
        ORDER_TOTAL_FINAL_COST: item.ORDER_TOTAL_FINAL_COST,
        ORDER_COMMENTS: item.ORDER_COMMENTS,
        ORDER_COMMENTS_BY: item.ORDER_COMMENTS_BY,
        USER_EMAIL: item.USER_EMAIL,
        ORDER_PICKUP_DATE_TIME: item.ORDER_PICKUP_DATE_TIME,
        ORDER_TYPE: 'DINE-IN',
        DISCOUNT_ID: item.DISCOUNT_ID,
        DISCOUNT_APPLIED: item.DISCOUNT_APPLIED,
        PARTY_SIZE: item.PARTY_SIZE,
        PAYMENT_STATUS: item.PAYMENT_STATUS,
        USER_PHONE: item.USER_PHONE,
        REWARDS_APPLIED: item.REWARDS_APPLIED,
        CUSTOMER_NAME: item.CUSTOMER_NAME,
      };

      orderData.ORDER_STATUS = 'Checked-in';
      const result = await API.graphql(
        graphqlOperation(createUserRestOrderWkflw, {
          createUSER_REST_ORDER_WKFLWInput: orderData,
        })
      );
      // console.log(result);
      if (
        result.data.createUSER_REST_ORDER_WKFLW.ORDER_STATUS === 'Checked-in'
      ) {
        setData(prevState => ({
          ...prevState,
          ORDER_STATUS: 'Checked-in',
        }));
      }
    } catch (err) {
      console.log('error: ', err);
    }
  }
  async function userDetails() {
    const { attributes } = await Auth.currentAuthenticatedUser();
    console.log(attributes);
    console.log('------------------------------');
    setCustomerName(attributes.name);
    ////debugger;
  }
  
  return (
    <>
      <Modal
        blockScrollOnMount={false}
        isOpen={props.open}
        onClose = {props.close}
        size={('sx', 'md', 'xl', 'xl')}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{restName}</ModalHeader>
          <ModalCloseButton />
          <ModalBody p="20px">
            {data == '' ? (
              <Box className="popuploader">
                {' '}
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              </Box>
            ) : (
              <Box>
                {paymentStatus === 'Paid' ? (
                  <Text
                    width={'50%'}
                    style={{
                      backgroundColor: 'lightgreen',
                      paddingHorizontal: '4%',
                      paddingVertical: '1%',
                      borderRadius: 20,
                      fontWeight: 'bold',
                      textAlign: 'center',
                      marginLeft: 'auto',
                    }}
                  >
                    {paymentStatus}
                  </Text>
                ) : paymentStatus === 'Pay at Restaurant' ? (
                  <Text
                    width={'50%'}
                    style={{
                      backgroundColor: 'orangered',
                      paddingHorizontal: '4%',
                      paddingVertical: '1%',
                      borderRadius: 20,
                      fontWeight: 'bold',
                      textAlign: 'center',
                      color: 'white',
                      marginLeft: 'auto',
                    }}
                  >
                    {paymentStatus}
                  </Text>
                ) : paymentStatus === 'Payment Status NULL' ? (
                  <Text
                    width={'50%'}
                    style={{
                      backgroundColor: 'lavender',
                      paddingHorizontal: '4%',
                      paddingVertical: '1%',
                      borderRadius: 20,
                      fontWeight: 'bold',
                      textAlign: 'center',
                      marginLeft: 'auto',
                    }}
                  >
                    {paymentStatus}
                  </Text>
                ) : null}

                <Box style={{ display: 'flex', marginTop: '10px' }}>
                  <Box>
                    <Text
                      style={{
                        fontSize: 15,
                        flex: 1.6,
                        alignItems: 'flex-start',
                        fontWeight: 'bold',
                      }}
                    >
                      Customer :
                    </Text>
                    <Text
                      style={{
                        opacity: 0.8,
                        flex: 3,
                        alignItems: 'flex-start',
                        paddingLeft: 10,
                      }}
                    >
                      {data?.CUSTOMER_NAME}
                    </Text>
                  </Box>
                  <Box style={{ marginLeft: 'auto', display: 'flex' }}>
                    <Text
                      style={{
                        fontSize: 15,
                        fontWeight: 'bold',
                      }}
                    >
                      Order# :
                    </Text>
                    <Text
                      style={{
                        opacity: 0.8,
                        flex: 1,
                        alignItems: 'flex-end',
                      }}
                    >
                      {data != null ? data.ORDER_ID : null}
                    </Text>
                  </Box>
                </Box>
                <Box style={{ display: 'flex', marginTop: '5px' }}>
                  <Text style={{ fontSize: 15, fontWeight: 'bold' }}>
                    PickUp Time :
                  </Text>
                  <Text style={{ opacity: 0.8 }}>
                    {data != null ? orderDate : null}
                  </Text>
                </Box>
                <Box style={{ display: 'flex', marginTop: '5px' }}>
                  <Text style={{ fontSize: 15, fontWeight: 'bold' }}>
                    User Phone :
                  </Text>
                  <Text style={{ opacity: 0.8 }}>
                    {data != null ? data.USER_PHONE : null}
                  </Text>
                </Box>
                <Box style={{ display: 'flex', marginTop: '5px' }}>
                  <Text style={{ fontSize: 15, fontWeight: 'bold' }}>
                    Total Amount :
                  </Text>
                  <Text style={{ opacity: 0.8, display: 'flex' }}>
                    ${data != null ? data.ORDER_TOTAL_FINAL_COST : null}
                    <Text
                      style={{
                        fontSize: 14,
                        color: '#a60a0f',
                        fontWeight: 'bold',
                      }}
                    >
                      {data?.PAYMENT_STATUS === null
                        ? ' (Pay At Restaurant)'
                        : null}
                    </Text>
                  </Text>
                </Box>
                <Box style={{ display: 'flex', marginTop: '5px' }}>
                  <Text style={{ fontSize: 15, fontWeight: 'bold' }}>
                    Comments :
                  </Text>
                  <Text style={{ opacity: 0.8 }}>{comments}</Text>
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    flex: 1,
                    flexDirection: 'row',
                    borderBottomColor: '#F0F0F0',
                    borderBottomWidth: 1,
                    borderTopColor: '#F0F0F0',
                    borderTopWidth: 1,
                    paddingBottom: 15,
                    paddingTop: 15,
                    marginTop: 5,
                    marginBottom: 5,
                  }}
                >
                  <>
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Box className="circleGreen" />
                      <Text
                        style={{
                          fontSize: 10,
                          fontWeight: 'bold',
                          color: '#000',
                          marginTop: 5,
                        }}
                      >
                        Submitted
                      </Text>
                    </Box>

                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        className={
                          data?.ORDER_STATUS !== 'Submitted' &&
                          data?.ORDER_STATUS !== 'Rejected'
                            ? 'circleGreen'
                            : data?.ORDER_STATUS === 'Rejected'
                            ? 'circleRed'
                            : 'circleBorder'
                        }
                      />
                      <Text
                        style={{
                          fontSize: 10,
                          fontWeight: 'bold',
                          color: '#000',
                          marginTop: 5,
                        }}
                      >
                        {data?.ORDER_STATUS === 'Rejected'
                          ? 'Rejected'
                          : 'Accepted'}
                      </Text>
                    </Box>

                    {data?.ORDER_TYPE === 'DINE-IN' &&
                    data?.ORDER_STATUS === 'Accepted' ? (
                      <Box onClick={() => updateOrderStatus(data)}>
                        <Box
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Box
                            style={{
                              borderColor: 'green',
                              borderWidth: 2,
                              paddingLeft: 5,
                              paddingTop: 2,
                              paddingBottom: 2,
                              paddingRight: 5,
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 10,
                                fontWeight: 'bold',
                                color: '#000',
                                marginTop: 5,
                              }}
                            >
                              Check-In
                            </Text>
                          </Box>
                        </Box>
                      </Box>
                    ) : data?.ORDER_TYPE === 'DINE-IN' &&
                      data?.ORDER_STATUS === 'Submitted' ? (
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          flex: 1,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Box
                          style={{
                            borderColor: 'grey',
                            borderWidth: 2,
                            paddingLeft: 5,
                            paddingTop: 2,
                            paddingBottom: 2,
                            paddingRight: 5,
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              fontWeight: 'bold',
                              color: 'grey',
                              marginTop: 5,
                            }}
                          >
                            Check-In
                          </Text>
                        </Box>
                      </Box>
                    ) : data?.ORDER_TYPE === 'DINE-IN' &&
                      (data?.ORDER_STATUS === 'Checked-in' ||
                        data?.ORDER_STATUS === 'Table Ready' ||
                        data?.ORDER_STATUS === 'Completed') ? (
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          flex: 1,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Box
                          style={{
                            borderColor: 'green',
                            borderWidth: '2px',
                            backgroundColor: 'green',
                            width: '50px',
                            height: '25px',
                          }}
                        >
                          <Box
                            style={{
                              fontSize: 10,
                              fontWeight: 'bold',
                              color: '#fff',
                              marginTop: 5,
                            }}
                          >
                            Check-In
                          </Box>
                        </Box>
                      </Box>
                    ) : null}

                    {data?.ORDER_TYPE === 'DINE-IN' ? (
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          flex: 1,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Box
                          className={
                            data?.ORDER_STATUS === 'Table Ready' ||
                            data?.ORDER_STATUS === 'Completed'
                              ? 'circleGreen'
                              : data?.ORDER_STATUS === 'Rejected'
                              ? 'greyBorder'
                              : 'circleBorder'
                          }
                        />
                        <Text
                          style={{
                            fontSize: 10,
                            fontWeight: 'bold',
                            color: '#000',
                            marginTop: 5,
                          }}
                        >
                          Table Ready
                        </Text>
                      </Box>
                    ) : (
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          flex: 1,
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Box
                          className={
                            data?.ORDER_STATUS === 'Ready for Pickup' ||
                            data?.ORDER_STATUS === 'Completed'
                              ? 'circleGreen'
                              : data?.ORDER_STATUS === 'Rejected'
                              ? 'greyBorder'
                              : 'circleBorder'
                          }
                        />
                        <Text
                          style={{
                            fontSize: 10,
                            fontWeight: 'bold',
                            color: '#000',
                            marginTop: 5,
                          }}
                        >
                          Ready for Pickup
                        </Text>
                      </Box>
                    )}

                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        className={
                          data?.ORDER_STATUS === 'Completed'
                            ? 'circleGreen'
                            : data?.ORDER_STATUS === 'Rejected'
                            ? 'greyBorder'
                            : 'circleBorder'
                        }
                      />
                      <Text
                        style={{
                          fontSize: 10,
                          fontWeight: 'bold',
                          color: '#000',
                          marginTop: 5,
                        }}
                      >
                        Completed
                      </Text>
                    </Box>
                  </>
                </Box>
             
            

            
            
              
              <Box
                style={{
                  flex: 2,
                }}
              >
                <Box
              style={{
                marginTop: 10,
                justifyContent: 'center',
              }}
            >
              <Text
                style={{
                  fontSize: 18,
                  fontWeight: 'bold',
                  textAlign: 'center',

                }}
              >
                CART ITEMS
              </Text>
            </Box>
                <Box
                  style={{
                    flex: 0.1,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginLeft: 10,
                    marginRight: 10,
                    display: 'flex'
                  }}
                >
                  <Box style={{ flex: 2 }}>
                    <Text
                      style={{
                        fontWeight: 'bold',
                        fontSize: 15,
                        textDecorationLine: 'underline',
                      }}
                    >
                      Qty
                    </Text>
                  </Box>
                  <Box style={{ flex: 4 }}>
                    <Text
                      style={{
                        fontWeight: 'bold',
                        fontSize: 15,
                        textDecorationLine: 'underline',
                      }}
                    >
                      Description
                    </Text>
                  </Box>
                  <Box style={{ flex: 2 }}>
                    <Text
                      style={{
                        fontWeight: 'bold',
                        fontSize: 15,
                        textDecorationLine: 'underline',
                      }}
                    >
                      Price
                    </Text>
                  </Box>
                </Box>

                <Box
                  style={{
                    marginLeft: 10,
                    marginRight: 10,

                    flex: 0.9,
                  }}
                >
                  {data != null
                    ? data?.relatedCartMenuItms?.map(item => {
                        let arr = [];
                        item?.relatedCartMenuChItms?.map((ch_items, index) => {
                          if (item.MENU_IT_NAME !== ch_items.CH_ITEM_DESC) {
                            arr.push(
                              (index ? ', ' : '') + ch_items.CH_ITEM_DESC
                            );
                          }
                        });
                        return (
                          <>
                            <Box
                              style={{
                                flex: 1,
                                flexDirection: 'row',
                                marginTop: 10,
                                display: 'flex'
                              }}
                            >
                              <Box style={{ flex: 2 }}>
                                <Text style={{ fontSize: 15 }}>
                                  {item.CART_ITEM_QUANTITY}
                                </Text>
                              </Box>
                              <Box style={{ flex: 4 }}>
                                <Text
                                  style={{ fontWeight: 'bold', fontSize: 15 }}
                                >
                                  {item.MENU_IT_NAME}
                                </Text>
                                <Text style={{ opacity: 0.6 }}>
                                  {arr?.map(i => {
                                    return <Text>{i}</Text>;
                                  })}
                                </Text>
                              </Box>
                              <Box style={{ flex: 2 }}>
                                <Text
                                  style={{
                                    fontWeight: 'bold',
                                    paddingRight: 5,
                                  }}
                                >
                                  $ {item.CART_ITEM_TOTAL_PRICE.toFixed(2)}
                                </Text>
                              </Box>
                            </Box>
                          </>
                        );
                      })
                    : null}
                </Box>
                <Box
                style={{
                  marginBottom: 20,
                  marginTop: 10,
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 13,
                      fontWeight: "bold",
                      color: "#000",
                      paddingRight: 10,
                    }}
                  >
                    Tax:
                  </Text>
                  <Text
                    style={{
                      fontSize: 13,
                      fontWeight: "bold",
                      color: "#000",
                      marginRight: 10,
                    }}
                  >
                    {data?.TAX_APPLIED ? data?.TAX_APPLIED.toFixed(2) : '0.00'}
                  </Text>
                </Box>

                <Box
                  style={{
                    display: 'flex',
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 13,
                      fontWeight: "bold",
                      color: "#000",
                      paddingRight: 10,
                    }}
                  >
                    Discount:
                  </Text>
                  <Text
                    style={{
                      fontSize: 13,
                      fontWeight: "bold",
                      color: "#000",
                      marginRight: 10,
                    }}
                  >
                    {data?.DISCOUNT_APPLIED ? data?.DISCOUNT_APPLIED.toFixed(2) : '0.00'}
                  </Text>
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 13,
                      fontWeight: "bold",
                      color: "#000",
                      paddingRight: 10,
                    }}
                  >
                    Rewards:
                  </Text>
                  <Text
                    style={{
                      fontSize: 13,
                      fontWeight: "bold",
                      color: "#000",
                      marginRight: 10,
                    }}
                  >
                    {data?.REWARDS_APPLIED ? data?.REWARDS_APPLIED.toFixed(2) : '0.00'}
                  </Text>
                </Box>
               
                </Box>

              </Box>

              </Box>
              
            )}
            

            <Box></Box>
          </ModalBody>

          <ModalFooter>
          {data !== '' &&  (
            <Button colorScheme="blue" mr={3} onClick={props.close}>
              Close
            </Button>
          )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default AdminOrderHistoryPopup;
