import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Spinner,
    Text

} from "@chakra-ui/react";
import React, { useRef, useState, useEffect } from "react";
import { Switch } from "@chakra-ui/react"
import { Auth, Hub, API, graphqlOperation } from 'aws-amplify';
import { useToast, useDisclosure } from "@chakra-ui/react"
import Sidebar from '../../../components/sidebar'
import Header from '../../../components/Header'
import EventsData from '../../../components/EventsData'
import AddEventPopup from '../../../components/AddEventPopup'
import { getRestMaster as GetRestMaster } from '../../../../src/graphql/queries'
import { createSpecialMenuMaster as CreateSpecialMenuMaster } from '../../../graphql/mutations'
import { createSpecialMenuMasterWeb as CreateSpecialMenuMasterWeb } from '../../../graphql/mutations'
import { updateSpecialMenuMasterWeb } from '../../../graphql/mutations'
import { createRestSpecialMenuChoices as CreateRestSpecialMenuChoices } from '../../../graphql/mutations'
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

function Events() {

    const [togglebit, setTogglebit] = useState(false);
    const [switchbit, setSwitchbit] = useState(true);
    const [specialData, setspecialData] = useState([]);
    const [specialFilteredData, setSpecialFilteredData] = useState([]);
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false);
    let today = new Date().toISOString().slice(0, 10)

    //Show toast for validation message method
    function showValidationMsg(msg, st) {
        toast({
            title: "",
            description: msg,
            status: st,
            duration: 9000,
            isClosable: true,
            position: "top"
        })

    }


    async function SpecialData() {

        try {

            let data = [];
            let filtereddata = []
            setIsLoading(true)

            const result = await API.graphql(graphqlOperation(GetRestMaster, { REST_ID: localStorage.getItem('Restaurantid') }))

            console.log(result);
            const Upcomingdata = result.data.getREST_MASTER.relatedSplUpcomingByRest?.filter((obj) =>
                obj.MENU_ID == 'ME1' && today <= obj.DATE_TO_DATE);

            Upcomingdata?.map((obj) => {
                obj.active = 'true'
            });
            data.push(...Upcomingdata);
            // data.push(...second);

            const Todaydata = result.data.getREST_MASTER.relatedSplTodayByRest?.filter((obj) =>
                obj.MENU_GR_ID == 'MS2-MG02');

            Todaydata?.map((obj) => {
                obj.active = 'true'
            });
            data.push(...Todaydata);

            setspecialData(data);
            setSpecialFilteredData(data);
            setIsLoading(false)


        }
        catch (err) {

            console.log(err)
        }
    }

    async function Save(data, isUpdate) {

        try {


            const a = 0;
            let res = null;
            if (isUpdate === true) {
                res = await API.graphql(graphqlOperation(updateSpecialMenuMasterWeb, { updateSPECIAL_MENU_MASTER_WEBInput: data }));
                console.log(res);

            }
            else {

                res = await API.graphql(graphqlOperation(CreateSpecialMenuMasterWeb, { CreateSPECIAL_MENU_MASTER_WEBInput: data }));
                console.log(res);
                ////debugger;



            }

            onClose();
            SpecialData();
            showValidationMsg("Event Successfully Added", 'success')
        }
        catch (err) {
            //////debugger;
            console.log('error: ', err)
            showValidationMsg("Error occured", 'error')

        }
    }

    // async function callMenuCustomizationMutation(customizationinput) {
    //     try{
    //         const result = await API.graphql(graphqlOperation(CreateRestSpecialMenuChoices, { CreateREST_SPECIAL_MENU_CHOICESInput: customizationinput }));

    //         const a=10;
    //     }
    //         catch(error)
    //         {
    //             console.log(error)
    //         }


    // }



    useEffect(() => {
        SpecialData();
    }, []);

    function toggle() {
        setTogglebit(current => !current);
    }
    function LoadAllData() {
        setSwitchbit(current => !current);

    }

    const { isOpen, onOpen, onClose } = useDisclosure()
    return (

        <Box bg='#f9f9f9'>
            <Helmet>
                <title>Squash | Events</title>
            </Helmet>
            <Header isAdmin={true} toggle={toggle} />
            <Box pt='70px' position='relative' h='100vh' overflow='hidden' d='flex'>
                <Box zIndex='9' bg='#fff' position='absolute' top='0' pt='100px' left='0' h='100%'
                    // w={togglebit ? '0px' : '200px'}
                    //  display={togglebit ? 'none' : 'block'}
                    w={{
                        base: togglebit ? '200px' : '0',
                        sm: togglebit ? '200px' : '0',
                        md: togglebit ? '200px' : '0',
                        lg: togglebit ? '0' : '200px',
                    }}
                    d={{
                        base: togglebit ? 'block' : 'none',
                        sm: togglebit ? 'block' : 'none',
                        md: togglebit ? 'block' : 'none',
                        lg: togglebit ? 'none' : 'block',
                    }}
                >
                    <Sidebar current='Events' togglebit={togglebit} />
                </Box>
                <Box ml={(togglebit ? "0px" : { base: "0", sm: "0", md: "0", lg: '200px' })} w='100%' p='20px' overflow='auto'>
                    <Box className="card no-padding card-flex">
                        <Box className="card-header" position="relative">
                            <Box className="title-heading"><h2>Events</h2></Box>
                            <Box className="icons-wrapper  specials-head">
                                {/* <Box mr='15px'>
                                        <FormControl display="flex" justifyContent='flex-end' >
                                            <FormLabel htmlFor="status-switch" mb="0">
                                                Active
                                            </FormLabel>
                                            <Switch id="status-switch" isChecked={switchbit} onChange={() => LoadAllData()} />
                                        </FormControl>
                                    </Box> */}
                                <Box>
                                    <Button onClick={onOpen} className='primary-button  primary-btn  addspecialbtn'><FontAwesomeIcon m='3px' icon={faPlus} /> <Text fontSize='14px'>Add Event</Text> </Button>
                                </Box>
                            </Box>
                        </Box>
                        <Box className="card-content table-padding specialbox">
                            <Box className="scrollable">
                                {isLoading ? (<Box className="rspinbox" > <Spinner
                                    thickness="4px"
                                    speed="0.65s"
                                    emptyColor="gray.200"
                                    color="blue.500"
                                    size="xl"
                                /></Box >) :
                                    specialFilteredData?.length > 0 || (switchbit && specialData?.length > 0) ?
                                        <EventsData SpecialData={SpecialData} special={false} data={switchbit ? specialFilteredData : specialData} />
                                        : <Box className="rspinbox" > <Text>No Data Available</Text></Box >}
                            </Box >
                        </Box >
                    </Box >
                </Box>
            </Box>
            <AddEventPopup isCreate={true} Save={Save} IsSpecial={false} Count={specialData?.length} open={isOpen} close={onClose} />
        </Box>

    )
}
export default Events;
