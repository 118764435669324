import {
    Box,
    Button,
    Modal,
    ModalHeader,
    ModalOverlay,
    ModalCloseButton,
    ModalBody,
    ModalContent,
    ModalFooter,

} from "@chakra-ui/react";
import React, { useRef, useState, useEffect } from "react";
import { Auth, Hub, API, graphqlOperation } from 'aws-amplify';
import { useToast } from "@chakra-ui/react"
import HourComponent from '../components/HourComponent'
import moment from "moment";



function HoursPopup(props) {


    const [checkedSunday, setCheckedSunday] = useState(props.hours?.SundayStartTime ? true : false)
    const [checkedMonday, setCheckedMonday] = useState(props.hours?.MondayStartTime ? true : false)
    const [checkedTuesday, setCheckedTuesday] = useState(props.hours?.TuesdayStartTime ? true : false)
    const [checkedWednesday, setCheckedWednesday] = useState(props.hours?.WednesdayStartTime ? true : false)
    const [checkedThursday, setCheckedThursday] = useState(props.hours?.ThursdayStartTime ? true : false)
    const [checkedFriday, setCheckedFriday] = useState(props.hours?.FridayStartTime ? true : false)
    const [checkedSaturday, setCheckedSaturday] = useState(props.hours?.SaturdayStartTime ? true : false)

    const tempData = { ...props.hours };

    const [sundayTime, setSundayTime] = useState({
        startTime: props.hours?.SundayStartTime ? moment(props.hours?.SundayStartTime, 'HH:mm A') : '',
        endTime: props.hours?.SundayEndTime ? moment(props.hours?.SundayEndTime, 'HH:mm A') : '',
        startTime1: props.hours?.SundayStartTime1 ? moment(props.hours?.SundayStartTime1, 'HH:mm A') : '',
        endTime1: props.hours?.SundayEndTime1 ? moment(props.hours?.SundayEndTime1, 'HH:mm A') : '',
    });
    const [mondayTime, setMondayTime] = useState({
        startTime: props.hours?.MondayStartTime ? moment(props.hours?.MondayStartTime, 'HH:mm A') : '',
        endTime: props.hours?.MondayEndTime ? moment(props.hours?.MondayEndTime, 'HH:mm A') : '',
        startTime1: props.hours?.MondayStartTime1 ? moment(props.hours?.MondayStartTime1, 'HH:mm A') : '',
        endTime1: props.hours?.MondayEndTime1 ? moment(props.hours?.MondayEndTime1, 'HH:mm A') : '',
    });
    const [tuesdayTime, setTuesdayTime] = useState({
        startTime: props.hours?.TuesdayStartTime ? moment(props.hours?.TuesdayStartTime, 'HH:mm A') : '',
        endTime: props.hours?.TuesdayEndTime ? moment(props.hours?.TuesdayEndTime, 'HH:mm A') : '',
        startTime1: props.hours?.TuesdayStartTime1 ? moment(props.hours?.TuesdayStartTime1, 'HH:mm A') : '',
        endTime1: props.hours?.TuesdayEndTime1 ? moment(props.hours?.TuesdayEndTime1, 'HH:mm A') : '',
    });
    const [wednesdayTime, setWednesdayTime] = useState({
        startTime: props.hours?.WednesdayStartTime ? moment(props.hours?.WednesdayStartTime, 'HH:mm A') : '',
        endTime: props.hours?.WednesdayEndTime ? moment(props.hours?.WednesdayEndTime, 'HH:mm A') : '',
        startTime1: props.hours?.WednesdayStartTime1 ? moment(props.hours?.WednesdayStartTime1, 'HH:mm A') : '',
        endTime1: props.hours?.WednesdayEndTime1 ? moment(props.hours?.WednesdayEndTime1, 'HH:mm A') : '',
    });
    const [thursdayTime, setThursdayTime] = useState({
        startTime: props.hours?.ThursdayStartTime ? moment(props.hours?.ThursdayStartTime, 'HH:mm A') : '',
        endTime: props.hours?.ThursdayEndTime ? moment(props.hours?.ThursdayEndTime, 'HH:mm A') : '',
        startTime1: props.hours?.ThursdayStartTime1 ? moment(props.hours?.ThursdayStartTime1, 'HH:mm A') : '',
        endTime1: props.hours?.ThursdayEndTime1 ? moment(props.hours?.ThursdayEndTime1, 'HH:mm A') : '',
    });
    const [fridayTime, setFridayTime] = useState({
        startTime: props.hours?.FridayStartTime ? moment(props.hours?.FridayStartTime, 'HH:mm A') : '',
        endTime: props.hours?.FridayEndTime ? moment(props.hours?.FridayEndTime, 'HH:mm A') : '',
        startTime1: props.hours?.FridayStartTime1 ? moment(props.hours?.FridayStartTime1, 'HH:mm A') : '',
        endTime1: props.hours?.FridayEndTime1 ? moment(props.hours?.FridayEndTime1, 'HH:mm A') : '',
    });
    const [saturdayTime, setSaturdayTime] = useState({
        startTime: props.hours?.SaturdayStartTime ? moment(props.hours?.SaturdayStartTime, 'HH:mm A') : '',
        endTime: props.hours?.SaturdayEndTime ? moment(props.hours?.SaturdayEndTime, 'HH:mm A') : '',
        startTime1: props.hours?.SaturdayStartTime1 ? moment(props.hours?.SaturdayStartTime1, 'HH:mm A') : '',
        endTime1: props.hours?.SaturdayEndTime1 ? moment(props.hours?.SaturdayEndTime1, 'HH:mm A') : '',
    });


    function resetHour() {
        setSundayTime({
            startTime: tempData.SundayStartTime ? moment(tempData.SundayStartTime, 'HH:mm A') : '',
            endTime: tempData.SundayEndTime ? moment(tempData.SundayEndTime, 'HH:mm A') : '',
            startTime1: tempData.SundayStartTime1 ? moment(tempData.SundayStartTime1, 'HH:mm A') : '',
            endTime1: tempData.SundayEndTime1 ? moment(tempData.SundayEndTime1, 'HH:mm A') : ''
        })
        setMondayTime({
            startTime: tempData.MondayStartTime ? moment(tempData.MondayStartTime, 'HH:mm A') : '',
            endTime: tempData.MondayEndTime ? moment(tempData.MondayEndTime, 'HH:mm A') : '',
            startTime1: tempData.MondayStartTime1 ? moment(tempData.MondayStartTime1, 'HH:mm A') : '',
            endTime1: tempData.MondayEndTime1 ? moment(tempData.MondayEndTime1, 'HH:mm A') : ''
        })
        setTuesdayTime({
            startTime: tempData.TuesdayStartTime ? moment(tempData.TuesdayStartTime, 'HH:mm A') : '',
            endTime: tempData.TuesdayEndTime ? moment(tempData.TuesdayEndTime, 'HH:mm A') : '',
            startTime1: tempData.TuesdayStartTime1 ? moment(tempData.TuesdayStartTime1, 'HH:mm A') : '',
            endTime1: tempData.TuesdayEndTime1 ? moment(tempData.TuesdayEndTime1, 'HH:mm A') : ''
        })
        setWednesdayTime({
            startTime: tempData.WednesdayStartTime ? moment(tempData.WednesdayStartTime, 'HH:mm A') : '',
            endTime: tempData.WednesdayEndTime ? moment(tempData.WednesdayEndTime, 'HH:mm A') : '',
            startTime1: tempData.WednesdayStartTime1 ? moment(tempData.WednesdayStartTime1, 'HH:mm A') : '',
            endTime1: tempData.WednesdayEndTime1 ? moment(tempData.WednesdayEndTime1, 'HH:mm A') : ''
        })
        setThursdayTime({
            startTime: tempData.ThursdayStartTime ? moment(tempData.ThursdayStartTime, 'HH:mm A') : '',
            endTime: tempData.TuesdayEndTime ? moment(tempData.TuesdayEndTime, 'HH:mm A') : '',
            startTime1: tempData.ThursdayStartTime1 ? moment(tempData.ThursdayStartTime1, 'HH:mm A') : '',
            endTime1: tempData.TuesdayEndTime1 ? moment(tempData.TuesdayEndTime1, 'HH:mm A') : ''
        })
        setFridayTime({
            startTime: tempData.FridayStartTime ? moment(tempData.FridayStartTime, 'HH:mm A') : '',
            endTime: tempData.FridayEndTime ? moment(tempData.FridayEndTime, 'HH:mm A') : '',
            startTime1: tempData.FridayStartTime1 ? moment(tempData.FridayStartTime1, 'HH:mm A') : '',
            endTime1: tempData.FridayEndTime1 ? moment(tempData.FridayEndTime1, 'HH:mm A') : ''
        })
        setSaturdayTime({
            startTime: tempData.SaturdayStartTime ? moment(tempData.SaturdayStartTime, 'HH:mm A') : '',
            endTime: tempData.SaturdayEndTime ? moment(tempData.SaturdayEndTime, 'HH:mm A') : '',
            startTime1: tempData.SaturdayStartTime1 ? moment(tempData.SaturdayStartTime1, 'HH:mm A') : '',
            endTime1: tempData.SaturdayEndTime1 ? moment(tempData.SaturdayEndTime1, 'HH:mm A') : ''
        })

    }

    const handleChangeSunday = () => {
        setCheckedSunday(!checkedSunday)

    }
    const handleChangeMonday = () => {
        setCheckedMonday(!checkedMonday)

    }
    const handleChangeTuesday = () => {
        setCheckedTuesday(!checkedTuesday)

    }
    const handleChangeWednesday = () => {
        console.log('a')
        setCheckedWednesday(!checkedWednesday)

    }
    const handleChangeThursday = () => {
        setCheckedThursday(!checkedThursday)

    }
    const handleChangeFriday = () => {
        setCheckedFriday(!checkedFriday)

    }
    const handleChangeSaturday = () => {
        setCheckedSaturday(!checkedSaturday)

    }


    const updateHour = () => {

        // const data = {
        //     SUNDAY_HOURS: `Sunday: ${parseInt(sundayTime.startTime.format('HH'), 10) > 12 ? parseInt(sundayTime.startTime.format('HH'), 10) - 12 +
        //         ':' + parseInt(sundayTime.startTime.format('mm')) + ' PM - '
        //         : parseInt(sundayTime.startTime.format('HH'), 10) + ':' + parseInt(sundayTime.startTime.format('mm')) + ' AM - '} ${parseInt(sundayTime.endTime.format('HH'), 10) > 12 ?
        //             parseInt(sundayTime.endTime.format('HH'), 10) - 12 + ':' + parseInt(sundayTime.endTime.format('mm')) + ' PM'
        //             : parseInt(sundayTime.endTime.format('HH'), 10) + ':' + parseInt(sundayTime.endTime.format('mm')) + ' AM'}`,


        //     MONDAY_HOURS: `Monday: ${parseInt(mondayTime.startTime.format('HH'), 10) > 12 ? parseInt(mondayTime.startTime.format('HH'), 10) - 12 +
        //         ':' + parseInt(mondayTime.startTime.format('mm')) + ' PM - '
        //         : parseInt(mondayTime.startTime.format('HH'), 10) + ':' + parseInt(mondayTime.startTime.format('mm')) + ' AM - '} ${parseInt(mondayTime.endTime.format('HH'), 10) > 12 ?
        //             parseInt(mondayTime.endTime.format('HH'), 10) - 12 + ':' + parseInt(mondayTime.endTime.format('mm')) + ' PM'
        //             : parseInt(mondayTime.endTime.format('HH'), 10) + ':' + parseInt(mondayTime.endTime.format('mm')) + ' AM'}`,

        //     TUESDAY_HOURS: `Tuesday: ${parseInt(tuesdayTime.startTime.format('HH'), 10) > 12 ? parseInt(tuesdayTime.startTime.format('HH'), 10) - 12 +
        //         ':' + parseInt(tuesdayTime.startTime.format('mm')) + ' PM - '
        //         : parseInt(tuesdayTime.startTime.format('HH'), 10) + ':' + parseInt(tuesdayTime.startTime.format('mm')) + ' AM - '} ${parseInt(tuesdayTime.endTime.format('HH'), 10) > 12 ?
        //             parseInt(tuesdayTime.endTime.format('HH'), 10) - 12 + ':' + parseInt(tuesdayTime.endTime.format('mm')) + ' PM'
        //             : parseInt(tuesdayTime.endTime.format('HH'), 10) + ':' + parseInt(tuesdayTime.endTime.format('mm')) + ' AM'}`,

        //     WEDNESDAY_HOURS: `Wednesday: ${parseInt(wednesdayTime.startTime.format('HH'), 10) > 12 ? parseInt(wednesdayTime.startTime.format('HH'), 10) - 12 +
        //         ':' + parseInt(wednesdayTime.startTime.format('mm')) + ' PM - '
        //         : parseInt(wednesdayTime.startTime.format('HH'), 10) + ':' + parseInt(wednesdayTime.startTime.format('mm')) + ' AM - '} ${parseInt(wednesdayTime.endTime.format('HH'), 10) > 12 ?
        //             parseInt(wednesdayTime.endTime.format('HH'), 10) - 12 + ':' + parseInt(wednesdayTime.endTime.format('mm')) + ' PM'
        //             : parseInt(wednesdayTime.endTime.format('HH'), 10) + ':' + parseInt(wednesdayTime.endTime.format('mm')) + ' AM'}`,

        //     THURSDAY_HOURS: `Wednesday: ${parseInt(thursdayTime.startTime.format('HH'), 10) > 12 ? parseInt(thursdayTime.startTime.format('HH'), 10) - 12 +
        //         ':' + parseInt(thursdayTime.startTime.format('mm')) + ' PM - '
        //         : parseInt(thursdayTime.startTime.format('HH'), 10) + ':' + parseInt(thursdayTime.startTime.format('mm')) + ' AM - '} ${parseInt(thursdayTime.endTime.format('HH'), 10) > 12 ?
        //             parseInt(thursdayTime.endTime.format('HH'), 10) - 12 + ':' + parseInt(thursdayTime.endTime.format('mm')) + ' PM'
        //             : parseInt(thursdayTime.endTime.format('HH'), 10) + ':' + parseInt(thursdayTime.endTime.format('mm')) + ' AM'}`,

        //     FRIDAY_HOURS: `Friday: ${parseInt(fridayTime.startTime.format('HH'), 10) > 12 ? parseInt(fridayTime.startTime.format('HH'), 10) - 12 +
        //         ':' + parseInt(fridayTime.startTime.format('mm')) + ' PM - '
        //         : parseInt(fridayTime.startTime.format('HH'), 10) + ':' + parseInt(fridayTime.startTime.format('mm')) + ' AM - '} ${parseInt(fridayTime.endTime.format('HH'), 10) > 12 ?
        //             parseInt(fridayTime.endTime.format('HH'), 10) - 12 + ':' + parseInt(fridayTime.endTime.format('mm')) + ' PM'
        //             : parseInt(fridayTime.endTime.format('HH'), 10) + ':' + parseInt(fridayTime.endTime.format('mm')) + ' AM'}`,

        //     SATURDAY_HOURS: `Saturday: ${parseInt(saturdayTime.startTime.format('HH'), 10) > 12 ? parseInt(saturdayTime.startTime.format('HH'), 10) - 12 +
        //         ':' + parseInt(saturdayTime.startTime.format('mm')) + ' PM - '
        //         : parseInt(saturdayTime.startTime.format('HH'), 10) + ':' + parseInt(saturdayTime.startTime.format('mm')) + ' AM - '} ${parseInt(saturdayTime.endTime.format('HH'), 10) > 12 ?
        //             parseInt(saturdayTime.endTime.format('HH'), 10) - 12 + ':' + parseInt(saturdayTime.endTime.format('mm')) + ' PM'
        //             : parseInt(saturdayTime.endTime.format('HH'), 10) + ':' + parseInt(saturdayTime.endTime.format('mm')) + ' AM'}`
        // }


        const data = {
            SUNDAY_HOURS: sundayTime?.startTime1 && sundayTime.endTime1 ?
                `${moment(sundayTime?.startTime).format('h:mmA')}–${moment(sundayTime?.endTime).format('h:mmA')}, ${moment(sundayTime?.startTime1).format('h:mmA')}–${moment(sundayTime?.endTime1).format('h:mmA')}`
                :
                sundayTime?.startTime && sundayTime.endTime ?
                    `${moment(sundayTime?.startTime).format('h:mmA')}–${moment(sundayTime?.endTime).format('h:mmA')}` : 'CLOSED',
            MONDAY_HOURS: mondayTime?.startTime1 && mondayTime.endTime1 ?
                `${moment(mondayTime?.startTime).format('h:mmA')}–${moment(mondayTime?.endTime).format('h:mmA')}, ${moment(mondayTime?.startTime1).format('h:mmA')}–${moment(mondayTime?.endTime1).format('h:mmA')}`
                :
                mondayTime?.startTime && mondayTime.endTime ?
                    `${moment(mondayTime?.startTime).format('h:mmA')}–${moment(mondayTime?.endTime).format('h:mmA')}` : 'CLOSED',
            TUESDAY_HOURS: tuesdayTime?.startTime1 && tuesdayTime.endTime1 ?
                `${moment(tuesdayTime?.startTime).format('h:mmA')}–${moment(tuesdayTime?.endTime).format('h:mmA')}, ${moment(tuesdayTime?.startTime1).format('h:mmA')}–${moment(tuesdayTime?.endTime1).format('h:mmA')}`
                :
                tuesdayTime?.startTime && tuesdayTime.endTime ?
                    `${moment(tuesdayTime?.startTime).format('h:mmA')}–${moment(tuesdayTime?.endTime).format('h:mmA')}` : 'CLOSED',
            WEDNESDAY_HOURS: wednesdayTime?.startTime1 && wednesdayTime.endTime1 ?
                `${moment(wednesdayTime?.startTime).format('h:mmA')}–${moment(wednesdayTime?.endTime).format('h:mmA')}, ${moment(wednesdayTime?.startTime1).format('h:mmA')}–${moment(wednesdayTime?.endTime1).format('h:mmA')}`
                :
                wednesdayTime?.startTime && wednesdayTime.endTime ?
                    `${moment(wednesdayTime?.startTime).format('h:mmA')}–${moment(wednesdayTime?.endTime).format('h:mmA')}` : 'CLOSED',
            THURSDAY_HOURS: thursdayTime?.startTime1 && thursdayTime.endTime1 ?
                `${moment(thursdayTime?.startTime).format('h:mmA')}–${moment(thursdayTime?.endTime).format('h:mmA')}, ${moment(thursdayTime?.startTime1).format('h:mmA')}–${moment(thursdayTime?.endTime1).format('h:mmA')}`
                :
                thursdayTime?.startTime && thursdayTime.endTime ?
                    `${moment(thursdayTime?.startTime).format('h:mmA')}–${moment(thursdayTime?.endTime).format('h:mmA')}` : 'CLOSED',
            FRIDAY_HOURS: fridayTime?.startTime1 && fridayTime.endTime1 ?
                `${moment(fridayTime?.startTime).format('h:mmA')}–${moment(fridayTime?.endTime).format('h:mmA')}, ${moment(fridayTime?.startTime1).format('h:mmA')}–${moment(fridayTime?.endTime1).format('h:mmA')}`
                :
                fridayTime?.startTime && fridayTime.endTime ?
                    `${moment(fridayTime?.startTime).format('h:mmA')}–${moment(fridayTime?.endTime).format('h:mmA')}` : 'CLOSED',
            SATURDAY_HOURS: saturdayTime?.startTime1 && saturdayTime.endTime1 ?
                `${moment(saturdayTime?.startTime).format('h:mmA')}–${moment(saturdayTime?.endTime).format('h:mmA')}, ${moment(saturdayTime?.startTime1).format('h:mmA')}–${moment(saturdayTime?.endTime1).format('h:mmA')}`
                :
                saturdayTime?.startTime && saturdayTime.endTime ?
                    `${moment(saturdayTime?.startTime).format('h:mmA')}–${moment(saturdayTime?.endTime).format('h:mmA')}` : 'CLOSED',
        }
        console.log('----------------Save Data------------------', data)
        // console.log( mondayTime.startTime.minutes)

        props.UpdateHourDetails(data)
        // props.close();


    }

    return (
        <>

            <Modal
                isOpen={props.open}
                onClose={() => { props.close(); resetHour() }}
                size={'sx', 'md', 'xl', 'xl'}
            >
                <ModalOverlay />
                <ModalContent className="hourspopbox">
                    <ModalHeader>Update Hours</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <HourComponent id='Sunday'
                            checked={checkedSunday}
                            handleChange={handleChangeSunday}
                            setValue={setSundayTime} value={sundayTime} />

                        <HourComponent id='Monday'
                            checked={checkedMonday}
                            handleChange={handleChangeMonday}
                            setValue={setMondayTime} value={mondayTime} />

                        <HourComponent id='Tuesday'
                            checked={checkedTuesday}
                            handleChange={handleChangeTuesday}
                            setValue={setTuesdayTime} value={tuesdayTime} />

                        <HourComponent id='Wednesday'
                            checked={checkedWednesday}
                            handleChange={handleChangeWednesday}
                            setValue={setWednesdayTime} value={wednesdayTime} />

                        <HourComponent id='Thursday'
                            checked={checkedThursday}
                            handleChange={handleChangeThursday}
                            setValue={setThursdayTime} value={thursdayTime} />

                        <HourComponent id='Friday'
                            checked={checkedFriday}
                            handleChange={handleChangeFriday}
                            setValue={setFridayTime} value={fridayTime} />

                        <HourComponent id='Saturday'
                            checked={checkedSaturday}
                            handleChange={handleChangeSaturday}
                            setValue={setSaturdayTime} value={saturdayTime} />

                    </ModalBody>

                    <ModalFooter>
                        <Button className="secondary-btn" mr={3} onClick={() => { props.close(); resetHour() }}>
                            Cancel
                        </Button>
                        <Button className="primary-btn" onClick={() => { updateHour() }} color='#fff' bg="#0554F2">Save</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>

    )
}

export default HoursPopup;
