import {
    Box,
    Button,
    Modal,
    ModalHeader,
    ModalOverlay,
    ModalCloseButton,
    ModalBody,
    ModalContent,  
    Input,
  Flex,
  Spinner,
  Image,
  Spacer,
  Text,
  FormControl,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { Multiselect } from 'multiselect-react-dropdown';
import React, { useRef, useState, useEffect } from "react";
import * as yup from 'yup';
import { Formik, Form, Field, FieldArray, useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { Auth, Hub, API, graphqlOperation } from 'aws-amplify';
import PreviewPopup from '../components/PreviewPopup'
import { useToast, useDisclosure } from "@chakra-ui/react"
import { EmailIcon, UnlockIcon } from '@chakra-ui/icons'
import { Link as RouteLink } from "react-router-dom";
import { useHistory } from 'react-router-dom';

const loginValidationSchema = yup.object().shape({
    email: yup
        .string()
        .email("Please enter valid email")
        .max(50, "Email must be 50 characters or less")
        .required('Email address is required'),
    password: yup

        .string()
        .min(8, ({ min }) => `Password must be at least ${min} characters`)
        .max(30, "Password must be 30 characters or less")
        .required('Password is required'),
})

function EditPreview() {
    const a = 10;
}

function LoginPopup(props) {

    const password = useRef(null)
    const [menuChoice, SetMenuChoice] = useState([])

    const data = props.customizeData?.map((item) => { return { id: item.CH_ID, name: item.CH_DESC } })
    const initialRef = React.useRef()
    const finalRef = React.useRef()
    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory();
    const toast = useToast()


    useEffect(() => {

        //MenuDetails()
    }, []);


    const {
        isOpen: isOpenPreviewModal,
        onOpen: onOpenPreviewModal,
        onClose: onClosePreviewModal
    } = useDisclosure()

    //Show toast for validation message method
  function showValidationMsg(msg, st) {
    toast({
      title: "",
      description: msg,
      status: st,
      duration: 9000,
      isClosable: true,
      position: "top"
    })
  }

  //Amplify signin method
  async function signIn(values) {

     try {
       setIsLoading(true)

       await Auth.signIn(values.email, values.password);
      // GetRestaurantList();
       let currentSession = null;
      try {
        currentSession = await Auth.currentSession();
        console.log(currentSession)

        const group = currentSession.accessToken.payload['cognito:groups']
        localStorage.setItem('guest_jwt', currentSession.accessToken.jwtToken)
        // var decoded = jwt_decode(currentSession.accessToken.jwtToken);
        // const group = jwt_decode(localStorage.getItem('guest_jwt'))['cognito:groups']

        if (group === undefined || group?.includes('sqaush_user')) {
         
         // props.status=true;
          showValidationMsg("Login Successfull ", 'success')
          props.close()
          props.loginhandle()
        }
        // else if (group?.includes('squash_super_admin') ||
        //   group?.includes('rest_super_admin') ||
        //   group?.includes('rest_frontdesk_users')) {

        //   userinfo = { email: values.email, pass: values.password }
        //   console.log(isLoading);
        //   onOpen();

        //  }


      } catch (err) {
        console.log('err', err);

      }
      setIsLoading(false)

    } catch (error) {
      if (error.code == "UserNotConfirmedException") {

        history.push(
          {
            pathname: '/ConfirmSignUp',
            state: { email: values.email, password: values.password }
          }
        );

        console.log(' Error signing in...', error);
      }
      else {


        //   try {
        //  let  currentSession = await Auth.currentSession();
        //  setUsername(currentSession.idToken.payload.email)
        //  } catch(err) {
        //    console.log('err',err);

        //   }

        showValidationMsg(error.message, 'error')
        console.log(' Error signing in...', error);

      }
      setIsLoading(false)
    }
  }



    return (
        <>
            <Modal
                size={'sx', 'md', 'xl', 'xl'}
                isOpen={props.open}
                onClose={props.close}
                scrollBehavior="inside"
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}

            >

                <ModalOverlay />
                <ModalContent className="mpopup">
                    <ModalHeader>Log In</ModalHeader>
                    <ModalCloseButton className="popup-btn" />
                    <ModalBody pb={6}>

                    <Formik
                  initialValues={{
                    email: "",
                    password: ''
                  }}
                  validationSchema={loginValidationSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    signIn(values)
                    setSubmitting(false)
                  }}
                >
                  {(formik) => {

                    return (
                      <form onSubmit={formik.handleSubmit}  >
                        <Box m='20px'>
                        <FormControl mt={6}>
                          <InputGroup >
                            <InputLeftElement
                              pointerEvents="none"
                              children={<EmailIcon color="gray.300" />}
                            />
                            <Input
                              fontSize={["13px", "13px", "18px", "18px"]}
                              type="email"
                              placeholder="Email"
                              onChange={formik.handleChange('email')}
                              onBlur={formik.handleBlur('email')}
                              value={formik.values.email}
                              error={formik.errors.email}
                              touched={formik.touched.email}
                            />
                          </InputGroup>
                        </FormControl>
                        {formik.errors.email && formik.touched.email && <Text className="validationColor" >{formik.errors.email}</Text>}
                        <FormControl mt={6}>
                          <InputGroup >
                            <InputLeftElement
                              pointerEvents="none"
                              children={<UnlockIcon color="gray.300" />}
                            />
                            <Input
                              fontSize={["13px", "13px", "18px", "18px"]}
                              ref={password}
                              type="password"
                              placeholder="Password"
                              onChange={formik.handleChange('password')}
                              value={formik.values.password}
                              error={formik.errors.password}
                              onBlur={formik.handleBlur('password')}
                              touched={formik.touched.password}
                            />
                          </InputGroup>

                        </FormControl>
                        {formik.errors.password && formik.touched.password && <Text className="validationColor">{" "} {formik.errors.password}</Text>}
                        <Box >
                          <Flex mt={4} display={isLoading ? 'flex' : 'none'} >
                            <Box w='45%'></Box>
                            <Spacer />
                            <Box w='55%' >
                              <Spinner
                                thickness="4px"
                                speed="0.65s"
                                emptyColor="gray.200"
                                color="blue.500"
                                size="xl"
                              />
                            </Box>

                          </Flex>

                          <Button display={isLoading ? 'none' : 'block'} className='primary-button' bg="#0554F2" width="full" mt={4} type="submit">
                            LOGIN
                          </Button>
                        </Box>
                        </Box>
                          </form>
                    );
                  }}
                </Formik>
                    </ModalBody>
                    <Box mb='25px' textAlign="center" >
                <Text fontSize={["sm", "md", "lg", "lg"]} >
                  Forgot  Password?{" "}
                  <RouteLink className="clickMe" to='/ForgotPassword'   >
                    ClickHere
                  </RouteLink>
                </Text>
              </Box>
            
                </ModalContent>

            </Modal>
            
        </>
    )

}

export default LoginPopup;
