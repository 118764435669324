
import {
	Box,
	Input,
	Button,
	Text,
	Checkbox,
	Modal,
	ModalHeader,
	ModalOverlay,
	ModalCloseButton,
	ModalBody,
	ModalContent,
	ModalFooter,
	List,
	ListItem,
	ListIcon,
	Heading,
	UnorderedList,
	Radio,
	RadioGroup


} from "@chakra-ui/react";
import React, { useRef, useState, useEffect } from "react";
import { EditIcon, StarIcon } from '@chakra-ui/icons'


function PreviewPopup(props) {
	let val = 1;

	return (
		<>


			<Modal blockScrollOnMount={false}
				isOpen={props.open}
				onClose={props.close}
				size={'sx', 'md', 'sm', 'sm'}
			>
				<ModalOverlay />
				<ModalContent>
					{/* <ModalHeader></ModalHeader> */}
					{/* <ModalCloseButton color='blue.500' top='0px'/> */}
					<ModalBody p='20px'>
						<Box d='flex'>
							<Text color='blue.500' fontWeight='bold' fontSize='15px'>{props.menu.MENU_IT_NAME}</Text>
							<Text pl='5px' fontWeight='bold' fontSize='15px' color='black'>${props.menu.MENU_IT_PRICE}</Text>
						</Box>
						<Text fontSize='13px' pt='4px'>{props.menu.MENU_IT_DESC}</Text>
						<Box>

							{props.menuChoice?.map((item) =>
								<Box p='15px'>
									<Text fontWeight='bold' fontSize='14px'>{item.CH_DESC}</Text>

									{


										item.data?.map((obj) =>
											obj.MULTI_SELECT_IND ?
												<Box d="flex" pt='10px' pl='10px'>
													<Checkbox pr='10px'  ></Checkbox>
													<Text flex='1' fontSize='12px' fontWeight='bold'>{obj.CH_ITEM_DESC}</Text>
													<Text fontSize='12px' fontWeight='bold'>${obj.CH_PRICE}</Text>
												</Box> : ''

										)}


									<Box d="flex" pt='7px' pl='10px'>
										<RadioGroup w='100%'>
											{
												item.data?.map((obj, index) =>
													!obj.MULTI_SELECT_IND ?
														<Box d='flex' pt='10px' pl='10px'>
															<Radio value={`${index}`} pr='10px' ></Radio>
															<Text flex='1' fontSize='12px' fontWeight='bold'>{obj.CH_ITEM_DESC}</Text>
															<Text fontSize='12px' fontWeight='bold'>${obj.CH_PRICE}</Text>
														</Box>


														: ''
												)
											}
										</RadioGroup>
									</Box>

								</Box>


							)

							}
						</Box>

					</ModalBody>

					<ModalFooter>
						<Button colorScheme="blue" mr={3} onClick={props.close}>
							Close
            </Button>

					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	)
}
export default PreviewPopup;