import {
    Box,
    ListItem,
    Text,
    List
} from "@chakra-ui/react"
import {
    BrowserRouter as Router,
    Link,
} from 'react-router-dom';
import React, { useRef, useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faUsersCog, faHome, faList, faEdit, faHamburger, faUtensils,faTachometerAlt, faChartBar, faCalendar, faCalendarCheck, faVoteYea, faHotel, faConciergeBell, faUserCog, faColumns, faTable} from '@fortawesome/free-solid-svg-icons'
import jwt_decode from "jwt-decode";


const Sidebar = ({ current }) => {

    const [currentmenu, setcurrentmenu] = useState(current);

    useEffect(() => {
       
          localStorage.setItem('Currentpage',currentmenu);
    
      }, [currentmenu]);
    return (
        <Box className='scroller-sidebar menu-bar' h='100%' overflow='auto' padding='20px' >
            <List spacing={6} h='100%'>
                <ListItem className={currentmenu == 'Home' ? 'active' : ''} >
                    <Link color="teal.500" to="/Admin/Home" onClick={() => setcurrentmenu('Home')} >
                        <Box display='flex' alignItems='center'>

                            <FontAwesomeIcon fontSize='22px' icon={faHome} />
                            <Text fontSize='xl' pl="10px">Home</Text>

                        </Box>
                    </Link>
                </ListItem>
                <ListItem className={currentmenu == 'Orders' ? 'active' : ''}>
                    <Link color="teal.500" to="/Admin/Orders" onClick={() => setcurrentmenu('Orders')} >
                        <Box display='flex' alignItems='center' >

                            <FontAwesomeIcon fontSize='22px' icon={faConciergeBell} />
                            <Text fontSize='xl' pl="10px">Orders</Text>

                        </Box>
                    </Link>
                </ListItem>
                {/* <ListItem className={currentmenu == 'Orders' ? 'active' : ''}>
                    <Link color="teal.500" to="/Dashboard" onClick={() => setcurrentmenu('Dashboard')} >
                        <Box display='flex' alignItems='center' >

                            <FontAwesomeIcon fontSize='22px' icon={faTachometerAlt} />
                            <Text fontSize='xl' pl="10px">Orders</Text>

                        </Box>
                    </Link>
                </ListItem> */}
                
                <ListItem className={currentmenu == 'Specials' ? 'active' : ''}>
                    <Link color="teal.500" to="/Admin/Specials" onClick={() => setcurrentmenu('Specials')} >
                        <Box display='flex' alignItems='center' >

                            <FontAwesomeIcon fontSize='22px' icon={faHamburger} />
                            <Text fontSize='xl' pl="10px">Specials</Text>

                        </Box>
                    </Link>
                </ListItem> 
                <ListItem className={currentmenu == 'Events' ? 'active' : ''}>
                    <Link color="teal.500" to="/Admin/Events" onClick={() => setcurrentmenu('Events')} >
                        <Box display='flex' alignItems='center' >

                            <FontAwesomeIcon fontSize='22px' icon={faCalendarCheck} />
                            <Text fontSize='xl' pl="10px">Events</Text>

                        </Box>
                    </Link>
                </ListItem>
                <ListItem className={currentmenu == 'Polls' ? 'active' : ''}>
                    <Link color="teal.500" to="/Admin/Polls" onClick={() => setcurrentmenu('Polls')} >
                        <Box display='flex' alignItems='center' >

                            <FontAwesomeIcon fontSize='22px' icon={faVoteYea} />
                            <Text fontSize='xl' pl="10px">Polls</Text>

                        </Box>
                    </Link>
                </ListItem>
                <ListItem className={currentmenu == 'Menu' ? 'active' : ''}>
                    <Link color="teal.500" to="/Admin/Menu" onClick={() => setcurrentmenu('Menu')} >
                        <Box display='flex' alignItems='center' >
                            <FontAwesomeIcon fontSize='22px' icon={faList} />
                            <Text fontSize='xl' pl="10px">Menu</Text>

                        </Box>
                    </Link>
                </ListItem>
                <ListItem className={currentmenu == 'Customization' ? 'active' : ''}>
                    <Link color="teal.500"  to="/Admin/Customization" onClick={() => setcurrentmenu('Customization')} >
                        <Box display='flex' alignItems='center' >

                            <FontAwesomeIcon fontSize='22px' icon={faEdit} />
                            <Text fontSize='xl' pl="10px">Modifier Group</Text>

                        </Box>
                    </Link>
                </ListItem>
                <ListItem className={currentmenu == 'Reports' ? 'active' : ''}>
                    <Link color="teal.500" to="/Admin/Reports" onClick={() => setcurrentmenu('Reports')} >
                        <Box display='flex' alignItems='center' >

                            <FontAwesomeIcon fontSize='22px' icon={faTable} />
                            <Text fontSize='xl' pl="10px">Reports</Text>

                        </Box>
                    </Link>
                </ListItem>
                <ListItem className={currentmenu == 'RestaurantDetails' ? 'active' : ''}>
                    <Link color="teal.500" to="/Admin/RestaurantDetails" onClick={() => setcurrentmenu('RestaurantDetails')} >
                        <Box display='flex' alignItems='center'>

                            <FontAwesomeIcon fontSize='22px' icon={faHotel} />
                            <Text fontSize='xl' pl="10px">RestaurantDetails</Text>

                        </Box>
                    </Link>
                </ListItem>
                
                
                {/* <ListItem className={currentmenu == 'Dashboard' ? 'active' : ''}>
                    <Link color="teal.500" to="/Dashboard" onClick={() => setcurrentmenu('Dashboard')} >
                        <Box display='flex' alignItems='center' >

                            <FontAwesomeIcon fontSize='22px' icon={faTachometerAlt} />
                            <Text fontSize='xl' pl="10px">Dashboard</Text>

                        </Box>
                    </Link>
                </ListItem>
                <ListItem className={currentmenu == 'Matrics' ? 'active' : ''}>
                    <Link color="teal.500" to="#" onClick={() => setcurrentmenu('Matrics')} >
                        <Box display='flex' alignItems='center' >

                            <FontAwesomeIcon fontSize='22px' icon={faChartBar} />
                            <Text fontSize='xl' pl="10px">Matrics</Text>

                        </Box>
                    </Link>
                </ListItem>
               
                <ListItem 
              //  d={jwt_decode(localStorage.getItem('jwtToken'))['cognito:groups']?.includes('squash_super_admin') ? 'block' : 'none'}
                 className={currentmenu == 'Administration' ? 'active' : ''}>
                    <Link color="teal.500" to="/Admin/Administration" onClick={() => setcurrentmenu('Administration')} >
                        <Box display='flex' alignItems='center' >

                            <FontAwesomeIcon fontSize='22px' icon={faUserCog} />
                            <Text fontSize='xl' pl="10px">Administration</Text>

                        </Box>
                    </Link>
                </ListItem> */}
            </List>

        </Box >
    );

}
export default Sidebar;