import {
  Button,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
  } from "@chakra-ui/react"
  import React, { useRef, useState, useEffect } from "react";

function DeleteAlertDialog(props) {
    const [isOpen, setIsOpen] = React.useState(false)
    const onClose = () => setIsOpen(false)
    const cancelRef = React.useRef()
  
    return (
      <>
        {/* <Button colorScheme="red" onClick={() => setIsOpen(true)}>
          Delete Customer
        </Button> */}
  
        <AlertDialog
          isOpen={props.isOpen}
          leastDestructiveRef={cancelRef}
          onClose={props.onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Remove Item  
              </AlertDialogHeader>
  
              <AlertDialogBody>
              Are you sure you want to remove this item from order?
              </AlertDialogBody>
  
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={props.onClose}>
                  Cancel
                </Button>
                <Button colorScheme="red"  ml={3}
                onClick={()=>{
                  props.onClose();
                  props.deletefromcart() 
                }}
                >
                  Remove
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </>
    )
  }

  export default  DeleteAlertDialog;
    