import {
	Box,
	Container,
	Button,
	Grid,
	GridItem,
	Image,
	Flex,
	Text,
	Spacer,
	List,
	ListItem,
	Switch,
	Spinner,
	FormControl,
	FormLabel,
	Accordion,
	AccordionButton,
	AccordionItem,
	AccordionIcon,
	AccordionPanel,
	IconButton

} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import UserSidebar from '../../../components/UserSidebar'
import Header from '../../../components/Header'
import { Tabs, TabList, TabPanels, Tab, TabPanel, useDisclosure } from "@chakra-ui/react"
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan, faConciergeBell } from '@fortawesome/free-solid-svg-icons'
import Cookies from 'js-cookie'
import PollRespondsPopup from './PollRespondsPopup'
import { getAllordersByUser as ListUserOrders } from '../../../graphql/queries';
import { listUserRestPolls as listUserRestPollsDetails } from "../../../graphql/queries";
import { listUserSpecials as ListSpecialDetails} from "../../../graphql/queries"
import { oncreateRestPollMaster } from "../../../graphql/subscriptions";
import { oncreateSpecialMenuMasterWeb as oncreateSpecialMenuMasterWeb } from  "../../../graphql/subscriptions";
import moment from 'moment'

const Notification = () => {

	const history = useHistory();
	const [togglebit, setTogglebit] = useState(false);

	const { isOpen, onOpen, onClose } = useDisclosure();

	const [isLoading, setIsLoading] = useState(false);

	const [notifications, setnotifications] = useState([]);

	const [pollRespond, setPollRespond] = useState([])

	const [questions, setQuestions] = useState([]);
	const [orderList, setOrderList] = useState([]);
	const [specialList,setSpecialList] = useState([]);
	const [updatedPollList, SetUpdatedPollList] = useState([])
	const[cartData,setCartData] = useState([]);
	let subscription = [];
	let orders = [];
	const {
		isOpen: isOpenReviewModal,
		onOpen: onOpenReviewModal,
		onClose: onCloseReviewModal
	} = useDisclosure()


	useEffect(() => {
		const abortController = new AbortController();
		let mounted = true;

		
		getOrdersList();
		listUserRestPolls();
		getSpecialsList();
		//orderSubscription();
		let today = moment(new Date(), "YYYY-MM-DD");
    let formattedtoday = moment(today, "YYYY-MM-DD").format("YYYY-MM-DD");

    var json_cartitems = Cookies.get('cartitems');
    if (json_cartitems) {
      const items = JSON.parse(json_cartitems);
      setCartData(items);
      
      const cartlen = items.filter(
        ob => ob.cart_created_date >= formattedtoday
      );
      console.log(cartlen)
      if(cartlen==0){
        Cookies.remove('cartitems');
      }   
      //const a = 10;
      console.log("cart",items);
    
    
    }


		return () => {
			abortController.abort();
			mounted = false;
			if (subscription.length > 0) {
				subscription.forEach(element => {
				  element.unsubscribe();
				});
				subscription = [];
			  }
		};


	}, []);


	const listUserRestPolls = async () => {
		try{
			let subscription = [];
     let restIdArray = [];
    let mainData = [];

			const result = await API.graphql(
				graphqlOperation(listUserRestPollsDetails)
			);
			console.log('test')
			console.log(result.data?.listUserRestPolls[0]?.relatedPollChoices)
			//setQuestions(result.data?.listUserRestPolls)
//////debugger;
			mainData = result.data.listUserRestPolls;
    setQuestions(mainData);
    mainData?.forEach(element => {
      restIdArray.push({REST_ID: element.REST_ID, CITY_NAME: element.CITY_NAME, REST_NAME: element.REST_NAME});
    });

    
    const uniq = restIdArray.map(JSON.stringify).reverse().filter(function(item, index, arr){ return arr.indexOf(item, index + 1) === -1; }).reverse().map(JSON.parse);
    console.log(uniq);
	console.log("testttt");
    uniq?.forEach(function(restData) {
      
        subscription.push(API.graphql(graphqlOperation(oncreateRestPollMaster, { REST_ID: restData.REST_ID}))
      .subscribe({next: subonUserPollResponse => {
			////////debugger
          pollSubscription(restData,subonUserPollResponse.value.data.oncreateREST_POLL_MASTER, mainData)   
        },
        error: error => {
          console.warn(error)
        },
      }));
    });
		}
		catch(e){
			console.log(e);
		}
		
	}

	function pollSubscription (item, response, mainData) {
		let itemObject = {};
		itemObject = {
		  "CITY_NAME": item.CITY_NAME,
		  "POLL_END_DATE_TIME": response.POLL_END_DATE_TIME,
		  "POLL_ID": response.POLL_ID,
		  "POLL_NAME": response.POLL_NAME,
		  "POLL_QUESTION": response.POLL_QUESTION,
		  "POLL_START_DATE_TIME": response.POLL_START_DATE_TIME,
		  "POLL_STATUS": response.POLL_STATUS,
		  "REST_ID": response.REST_ID,
		  "REST_NAME": item.REST_NAME,
		  "USER_POLL_STATUS": 'NOT ANSWERED',
		  "relatedPollChoices":[
			{
			  "POLL_CHOICE_ID": response?.POLL_CHOICE_ID_1,
			  "POLL_CHOICE_VAL": response?.POLL_CHOICE_1,
			  "POLL_CHOICE_VOTES": 0,
			  "POLL_ID": response?.POLL_ID,
			  "REST_ID": response?.REST_ID,
			  "USER_PREF_POLL_CHOICE_VAL": null,
			},
			{
			  "POLL_CHOICE_ID": response?.POLL_CHOICE_ID_2,
			  "POLL_CHOICE_VAL": response?.POLL_CHOICE_2,
			  "POLL_CHOICE_VOTES": 0,
			  "POLL_ID": response?.POLL_ID,
			  "REST_ID": response?.REST_ID,
			  "USER_PREF_POLL_CHOICE_VAL": null,
			},
			{
			  "POLL_CHOICE_ID": response?.POLL_CHOICE_ID_3,
			  "POLL_CHOICE_VAL": response?.POLL_CHOICE_3,
			  "POLL_CHOICE_VOTES": 0,
			  "POLL_ID": response?.POLL_ID,
			  "REST_ID": response?.REST_ID,
			  "USER_PREF_POLL_CHOICE_VAL": null,
			},
			{
			  "POLL_CHOICE_ID": response?.POLL_CHOICE_ID_4,
			  "POLL_CHOICE_VAL": response?.POLL_CHOICE_4,
			  "POLL_CHOICE_VOTES": 0,
			  "POLL_ID": response?.POLL_ID,
			  "REST_ID": response?.REST_ID,
			  "USER_PREF_POLL_CHOICE_VAL": null,
			},
		  ],
		};
		mainData.unshift(itemObject); 
		console.log('----------check again----------------');
		console.log(itemObject);
		setQuestions([]);
		SetUpdatedPollList(mainData);
	  }


	 async function getSpecialsList(){
	 	let res=null;
		let restIdArray = [];
        let mainData = [];
	 	try
	 	{
	 		res = await API.graphql(graphqlOperation(ListSpecialDetails));
			 setSpecialList(res.data.listUserSpecials)

			 mainData = res.data.listUserSpecials;
    //setSpecials(mainData); 
    mainData?.forEach(element => {
      restIdArray.push({REST_ID: element.REST_ID, REST_NAME: element.REST_NAME});
    });

    const uniq = restIdArray.map(JSON.stringify).reverse().filter(function(item, index, arr){ return arr.indexOf(item, index + 1) === -1; }).reverse().map(JSON.parse);
    uniq?.forEach(element => {
      subscription.push(API.graphql(graphqlOperation(oncreateSpecialMenuMasterWeb, { REST_ID: element.REST_ID}))
        .subscribe({
          next: subonUserSpecialResponse => {
			  ////////debugger;
            console.log("----------Menu item id print----------");
            console.log(subonUserSpecialResponse.value.data.MENU_IT_ID);
            console.log(subonUserSpecialResponse.value.data);
           // specialSubscription(subonUserSpecialResponse.value.data.oncreateSPECIAL_MENU_MASTER_WEB, mainData)   
          },
          error: error => {
            console.log("----------inside special error----------");
            console.warn(error)
          },
        })
      );
    });

	 	}
	 	catch(e)
	 	{
	 		console.log(e)
	 	}
	 	console.log(res);
   

	 }

	async function getOrdersList() {
		let res=null;
		try
		{
			////////debugger;
			res = await API.graphql(graphqlOperation(ListUserOrders));
			setOrderList(res.data.getALLORDERS_BY_USER);

		}
		catch(e)
		{
			console.log(e)
		}
   
		// if (subscription.length > 0) {
		//   subscription.forEach(element => {
		//     element.unsubscribe();
		//   });
		//   subscription = [];
		// }

		console.log(res);
		// if (res) {
		// 	let temp = [];

		// 	res.data.getORDERS_BY_USER.map((item) => {

				///////////////////////////////
				// subscription.push(API.graphql(graphqlOperation(onCreateUserOrdersForUser, { ORDER_ID: item.ORDER_ID }))
				//   .subscribe({
				//     next: subonCreateUserOrders => {
				//       alert(subonCreateUserOrders.value.data.ORDER_STATUS)
				//       setOrderStatus(subonCreateUserOrders.value.data.ORDER_STATUS)
				//     },
				//     error: error => console.warn(error)
				//   }));
				//////////////////////////////////////

				// if (orderStatus !== "") {
				//   temp = {
				//     order_id: item.ORDER_ID,
				//     order_status: orderStatus,
				//     restaurant: item.REST_NAME,
				//     restaurant_id: item.REST_ID,
				//   };
				//   orders.push(temp);
				// }

				// else {
		// 		temp = {
		// 			order_id: item.ORDER_ID,
		// 			order_status: item.ORDER_STATUS,
		// 			restaurant: item.REST_NAME,
		// 			restaurant_id: item.REST_ID,
		// 		};
		// 		orders.push(temp);
		// 		//  }


		// 	});
		// 	setOrderList(orders);



		// }


	}

	// async function getList() {
	// 	let res=null;
	// 	try
	// 	{
	// 		//////debugger;
			
	// 		res = await API.graphql(graphqlOperation(ListAllOrders));
	// 		// setOrderList(res.data.getORDERS_BY_USER);
	// 		console.log(res);
	// 		//////debugger;

	// 	}
	// 	catch(e)
	// 	{
	// 		console.log(e)
	// 	}
	// }

	function toggle() {
		setTogglebit(current => !current);
	}




	return (

		<Box bg='#f9f9f9'>
			<Helmet>
				<title>Squash | Notification</title>
			</Helmet>
			<PollRespondsPopup data={pollRespond} open={isOpenReviewModal} close={onCloseReviewModal} />
			<Header isAdmin={false} toggle={toggle} />
			<Box pt='70px' position='relative' h='100vh' overflow='hidden' d='flex'>
			<Box
              zIndex="9"
              bg="#fff"
              position="absolute"
              top="0"
              pt="100px"
              left="0"
              h="100%"
              w={togglebit ? '200px' : '0px'}
         display={togglebit ? 'block' : 'none'}
            >
          <UserSidebar />
        </Box>
				{isLoading ? (<Box className="rspinbox" > <Spinner
					thickness="4px"
					speed="0.65s"
					emptyColor="gray.200"
					color="blue.500"
					size="xl"
				/></Box >) :
				<Box
              
              w="100%"
              p={{ base: '8px', md: '20px' }}
              overflow="auto"
            >
						<Box className="card content-card">

							<Box className=" table-padding">
								

								<Tabs m='5px' variant="soft-rounded" colorScheme="blue" >
									<Box display='flex' className="notification-header">
									<Text className="user-title">Notifications</Text>
									<Box className="custom-tab">
									<TabList>
										<Tab>Orders</Tab>
										<Tab>Specials</Tab>
										<Tab>Polls</Tab>
									</TabList>
									</Box>

									</Box>

									<Box className="scrollable">

										<TabPanels>
											<TabPanel p='0' pt="5">


												<Box className="notification-list">
													{orderList?.length == 0 ? '' :
														orderList.map((obj, ind) => (
															<Box mb='15px' p='10px 15px' borderRadius='10px' border='1px solid #C3DDFF;'>
															
																<Box d='flex' alignItems={{bas:'flex-start', sm:'center'}} flexDirection={{base:'column', sm:'row'}}>
																<Box className="fmenuicon" mr={3}>  <FontAwesomeIcon icon={faConciergeBell} /></Box>
																<Box><Text fontWeight='bold' fontSize='16px' pb='1'>{obj.REST_NAME}</Text>
																<Box  d='flex'>
																	<Text color='#747474' fontSize='14px' fontWeight='medium' pr='1'>Order ID : </Text>
																	<Text color='#747474' fontSize='14px' fontWeight='light'>{obj.ORDER_ID}</Text>
																</Box>
																</Box>
														
																<Box d='flex' ml={{base:'0', sm:'auto'}} mt={{base:'3', sm:'0'}} alignItems='center'>
																	<Text  fontSize='14px' pr='1'>Order Status </Text>
													
																	{/* <Text className="notification-status">{obj.ORDER_STATUS}</Text> */}
																	 {obj.ORDER_STATUS ==="Submitted"  ? <Text className="notification-status">{obj.ORDER_STATUS}</Text>
																	 : obj.ORDER_STATUS ==="Completed" ? <Text className="notification-status" bg='#def3e7' color='#00B507'>{obj.ORDER_STATUS}</Text> 
                                                                     : obj.ORDER_STATUS ==="Rejected" ? <Text className="notification-status" bg='#f6e2e7' color='#EF0707'>{obj.ORDER_STATUS}</Text>
																	: obj.ORDER_STATUS ==="Accepted" ? <Text className="notification-status">{obj.ORDER_STATUS}</Text>
																	: obj.ORDER_STATUS ==="Ready for Pickup" ? <Text className="notification-status">{obj.ORDER_STATUS}</Text>
																	: obj.ORDER_STATUS ==="Checked-in" ? <Text className="notification-status">{obj.ORDER_STATUS}</Text>
																	:''}	
																	 
																	
																</Box>
																	</Box>
															</Box>
														))}
												</Box >


											</TabPanel>

											{/* <TabPanel  p='0' pt="5"><Text textAlign='center' py='3'>No specials</Text> */}
											{/* </TabPanel> */}

											<TabPanel p='0' pt="5">


												<Box>
													{specialList?.length == 0 ? '' :
														specialList.map((obj, ind) => (
															<Box mb='15px' p='10px 15px' borderRadius='10px' border='1px solid #ccc;'>
																<Text fontWeight='bold' pb='1'>{obj.MENU_IT_NAME}</Text>
																<Box d='flex'>
																<Box  d='flex' flexDirection={{base:'column', sm:'row'}}>
																	<Text fontWeight='medium' pr='1'>Restaurant Name : </Text>
																	<Text fontWeight='light'>{obj.REST_NAME}</Text>
																</Box>
														
																{/* <Box d='flex' ml='auto'>
																	<Text fontWeight='medium' pr='1'>Order Status : </Text>
																	<Text fontWeight='light'>{obj.ORDER_STATUS}</Text>
																</Box> */}
																	</Box>
															</Box>
														))}
												</Box >


											</TabPanel>



											<TabPanel  p='0' pt="5">
												<Accordion allowToggle className="accmenucusbox">
													{questions ? (
														questions.map((obj, index) => (
															<AccordionItem >
																<Box d='flex' justifyContent='space-between' className="accordion-question">
																	<Box cursor='pointer'
																		onClick={() => {
																			onOpenReviewModal();
																			setPollRespond(obj);
																		}}
																		m='5px' fontWeight='bold'>{obj.POLL_QUESTION}?</Box>
																	<AccordionButton fontSize='20px' mt='10px' w='30px' float='right' >
																		<AccordionIcon className="accordion-question-icon"/>
																	</AccordionButton>
																</Box>
																<AccordionPanel pb={4} >

																	<Box>
																		<Box pb='10px' 

																			// onClick={() => {
																			// 	onOpenReviewModal();
																			// 	setPollRespond(obj);
																			// }}

																		>{obj.POLL_NAME}</Box>

																		{
																			obj?.relatedPollChoices?.map((ob, ind) => (
																				<Box cursor='pointer' p='10px' m='10px' borderRadius='10px' border='2px solid #ccc;' onClick={onOpenReviewModal}>{ob.POLL_CHOICE_VAL}</Box>

																			))
																		}
																	</Box>

																</AccordionPanel>


															</AccordionItem>

														))) : null}
												</Accordion>

											</TabPanel>
										</TabPanels>
									</Box >
								</Tabs>
							</Box >
						</Box >
						<Box className="fixed-position">
                  {/* <Image src={CartImage} width={100} height={100} onClick={}/> */}
                  {(cartData.length == 0) ? (
                    ''
                    ) : ( 
                  <Button
                        w='auto'
                        minWidth={{ base: '30px', md: '34px' }}
                        h={{ base: '30px', md: '34px' }}
                        // h='auto'
                        ml='15px'
                        p='0px'
                        background='#001d56'
                        color='#ffffff'
                        as={IconButton}
                        aria-label="Options"
                        icon={<FontAwesomeIcon fontSize='22px' icon={faShoppingCart} />}
                        variant="outline"
                        onClick={() => {
                         

                          
                            history.push(
                              {
                                //pathname: `/Admin/${localStorage.getItem('Currentpage')}`,
                                pathname: '/RestaurantMenu/'+ cartData[0]?.restId,
                            
                                
                                    //  state: { email:props.userinfo?.email, password: props.userinfo?.pass }
                                  }
                                );
                          
                        }}
                    />)}
                                     
                </Box>
					</Box>
				}
			</Box>
		</Box>








		//   <Box bg='#f9f9f9'>
		//         <Helmet>
		//                       <title>Squash | Dashboard</title>
		//                   </Helmet>
		//                   <Header isAdmin={false} toggle={toggle} />
		//     {/* <Header 
		//     isAdmin={true}
		//     data={notifications} 
		//       toggle={toggle} /> */}
		//     <Box pt='70px' position='relative' h='100vh' overflow='hidden' d='flex'>
		//       <Box zIndex='9' bg='#001d56' position='absolute' top='0' pt='100px' left='0' h='100%' w={(togglebit ? "0px" : '200px')} display={(togglebit ? "none" : 'block')}>
		//         <Box className="user-sidebar" zIndex='9' bg='#001d56' position='absolute' top='0' pt='100px' left='0' h='100%' w={(togglebit ? "0px" : '200px')} display={(togglebit ? "none" : 'block')}>
		//       <UserSidebar />
		//     </Box>
		//       </Box>
		//       <Box ml={(togglebit ? "0px" : { base: "0", sm: "0", md: "0", lg: '200px' })} w='100%' p='20px' overflow='auto'>
		//         <Box ml='200px' mr='200px' className="card no-padding card-flex">
		//           <Box className="card-header">
		//             <Box>
		//               <Box className="title-heading"><h2>Dashboard</h2></Box>

		//             </Box>
		//           </Box>
		//           <Box className="card-content table-padding">
		//             {isLoading ? (<Box className='orderSpinbox'> <Spinner
		//               thickness="4px"
		//               speed="0.65s"
		//               emptyColor="gray.200"
		//               color="blue.500"
		//               size="xl"
		//             /></Box >) :

		//                 <Tabs m='5px' variant="soft-rounded" colorScheme="green"  className="cardouter">
		//                   <TabList>
		//                     <Tab  w='33%'>Orders</Tab>
		//                     <Tab  w='33%'>Specials</Tab>
		//                     <Tab   w='33%'>Polls</Tab>
		//                   </TabList>

		//                   <Box className="scrollable">

		//                   <TabPanels>
		//                     <TabPanel>


		//                      first


		//                       {/* <List>
		//                         {orderList?.map((item) =>

		//                           <Box cursor='pointer' >
		//                             <Box >
		//                               <ListItem >{item.ORDER_ID}</ListItem>

		//                             </Box>

		//                             {loading ? (<Box className='orderSpinbox'> <Spinner
		//                               thickness="4px"
		//                               speed="0.65s"
		//                               emptyColor="gray.200"
		//                               color="blue.500"
		//                               size="xl"
		//                             /></Box >) : ''}
		//                           </Box>


		//                         )

		//                         }
		//                       </List> */}
		//                     </TabPanel>
		//                     <TabPanel>second</TabPanel>
		//                     <TabPanel>3rd</TabPanel>
		//                   </TabPanels>
		//                   </Box >
		//                 </Tabs>


		//             }
		//           </Box >
		//         </Box >
		//       </Box>
		//     </Box>
		//   </Box>
	);
}
export default Notification;