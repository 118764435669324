import {
  Box,
  Input,
  Text,
  Checkbox

} from "@chakra-ui/react";
import React, { useRef, useState, useEffect } from "react";
import TimePicker from 'rc-time-picker';
import moment from "moment";



function HourComponent(props) {

  return (
    <Box className="resttimeboxmain" >
      <Box d='flex'>
        <Text>{props.id} </Text> <Checkbox pl="8px"
          id={props.id}
          type="checkbox"
          isChecked={props.checked}
          onChange={props.handleChange}
        /></Box>

      {props.checked && (
        <>
          <Box display='flex' className="resttimebox">
            <Box>
              <Text>Starting hour:</Text>
              <TimePicker
                value={props.value.startTime}
                onChange={(value) => props.setValue({
                  startTime: value, endTime: props.value.endTime, startTime1: props.value.startTime1, endTime1: props.value.endTime1
                })}
                showSecond={false}
                use12Hours
                allowEmpty
                format="hh:mm A"
              />
            </Box>
            <Box pl='20px'>
              <Text>Ending hour:</Text>
              <TimePicker
                value={props.value.endTime}
                onChange={(value) => props.setValue({
                  startTime: props.value.startTime, endTime: value, startTime1: props.value.startTime1, endTime1: props.value.endTime1
                })}
                showSecond={false}
                use12Hours
                allowEmpty
                format="hh:mm A"
              />
            </Box>
          </Box>
          <Box display='flex' className="resttimebox">
            <Box>
              <Text>Starting hour:</Text>
              <TimePicker
                value={props.value.startTime1}
                onChange={(value) => props.setValue({
                  startTime: props.value.startTime, endTime: props.value.endTime, startTime1: value, endTime1: props.value.endTime1
                })}
                showSecond={false}
                use12Hours
                allowEmpty
                format="hh:mm A"
              />
            </Box>
            <Box pl='20px'>
              <Text>Ending hour:</Text>
              <TimePicker
                value={props.value.endTime1}
                onChange={(value) => props.setValue({
                  startTime: props.value.startTime, endTime: props.value.endTime, startTime1: props.value.startTime1, endTime1: value
                })}
                showSecond={false}
                use12Hours
                allowEmpty
                format="hh:mm A"
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}
export default HourComponent;