import {
    Box,
    Container,
    Button,
    Grid,
    GridItem,
    Image,
    Flex,
    Text,
    Spacer
  } from "@chakra-ui/react";
  import React, { useEffect, useState } from "react";
  import { Auth } from 'aws-amplify';
  import { useHistory } from 'react-router-dom';
  import Sidebar from '../../../components/sidebar'
  import Header from '../../../components/Header'
  import { Helmet } from 'react-helmet';
  
  
  const Administration = () => {
  
    const history = useHistory();
    const [togglebit, setTogglebit] = useState(false);
  
    useEffect(() => {
  
      CheckLogstatus()
  
    });
  
    async function CheckLogstatus() {
  
      try {
        let currentSession = await Auth.currentSession();
        const a=10;
        if (currentSession.idToken.payload.email == 'undefined') {
          //setisLoggedIn(false);
          // isLoggedIn=false;
          history.push(
            {
              pathname: '/',
              //state: { email: values.email, password: values.password }
            }
          );
        }
        else {
          //  setisLoggedIn(true);
          // isLoggedIn=true;
        
        }
      } catch (err) {
        // setisLoggedIn(false);
        if(err=='No current user')
        {
          history.push(
            {
              pathname: '/',
              //state: { email: values.email, password: values.password }
            }
          );
        }
        console.log('err', err);
  
      }
    };
  
    async function signOut() {
      try {
        await Auth.signOut();
       // let currentSession = await Auth.currentSession();
        history.push(
          {
            pathname: '/',
            // state: { email: values.email, password: values.password }
          }
        );
      } catch (error) {
        console.log('error signing out: ', error);
      }
    }
  
    function toggle() {
      setTogglebit(current => !current);
    }
  
  
    return (
     
      <Box bg='#f9f9f9'>
         <Helmet>
						<title>Squash | Administration</title>
					</Helmet>
        <Header isAdmin={true} toggle={toggle} />
        <Box pt='70px' position='relative' h='100vh' overflow='hidden' d='flex'>
            <Box zIndex='9' bg='#fff' position='absolute' top='0' pt='100px' left='0' h='100%' w={(togglebit ? "0px" : '200px')} display={(togglebit ? "none" : 'block')}>
                <Sidebar current='Administration' togglebit={togglebit} />
            </Box>
            <Box ml={(togglebit ? "0px" : { base: "0", sm: "0", md: "0", lg: '200px' })} w='100%' p='20px' overflow='auto'>
                <Box className="card no-padding card-flex">
                    <Box className="card-header">
                        <Box className="card-header-inner">
                            <Box className="title-heading"><h2>Administration</h2></Box>
                        </Box>
                    </Box>
                    <Box className="card-content table-padding">
                        <Box className="scrollable">
                          <Text  fontSize="20px">working progress...!!</Text>
                        </Box >
                    </Box >
                </Box >
            </Box>
        </Box>
    </Box>
    );
  }
  export default Administration;