import {
  Box,
  Input,
  Button,
  Text,
  Checkbox,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  ModalFooter,
  Link,
  ListItem,
  ListIcon,
  Heading,
  UnorderedList,
  Radio,
  RadioGroup,
  Stack,
  OrderedList,
  AspectRatio,
  useToast
} from '@chakra-ui/react';
import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Hub, API, graphqlOperation } from 'aws-amplify';
import { getRestMaster as GetRestMaster } from '../../../graphql/queries';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { CopyToClipboard } from 'react-copy-to-clipboard'

function GenerateScriptPopup(props) {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const history = useHistory();
  const [resData, SetResData] = useState([]);
  const toast = useToast()
  useEffect(() => {
    RestaurantData();
    
  }, []);

  function copy(){
    toast({

      title: "",
      description: 'Copied to Clipboard!',
      status: 'success',
      duration: 3000,
      isClosable: true,
      position: "top"
  })
  }

  async function RestaurantData() {
    ////debugger;
    try {

      const result = await API.graphql(
        graphqlOperation(GetRestMaster, {
          REST_ID: localStorage.getItem('Restaurantid'),
        })
      );

      const data = result?.data.getREST_MASTER;
      console.log(data);

      ////debugger;

      // SetRestaurantName(data?.REST_NAME);
      // SetRestaurantAddress(data?.ADDRESS);
      // SetRestaurantAbout('web Site:' + data?.WEBSITE +'Phone :'+data?.PHONE)

      SetResData(data);
      // console.log(aa);

    } catch (err) {
      console.log(err);
    }
  }


  return (
    <>
      <Modal isOpen={props.isOpen} onClose={props.onClose} size={'xl'} isCentered>

        <ModalOverlay />
        <ModalContent className="restaurantPopup" >
          <ModalHeader>Restaurant Data</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* <iframe src="http://localhost:3000/RestaurantMenu/ChIJ-31cQeb_wokRBkrXYIedkCE"></iframe>
            <Text>"`http://localhost:3000/RestaurantMenu/{resData?.REST_ID}`"</Text> */}
            <div style={{ padding: 20, borderColor: '#0C35D8', borderBottomWidth: 2, flexDirection: 'row' }}>
              <Text>
              {/* &lt;iframe src="http://localhost:3000/RestaurantMenu/{resData?.REST_ID}"&gt;&lt;/iframe&gt; */}
              &lt;iframe src="https://web.squash.app/RestaurantMenu/{resData?.REST_ID}"&gt;&lt;/iframe&gt;
              </Text>
              <CopyToClipboard text={`<iframe src="https://web.squash.app/RestaurantMenu/${resData?.REST_ID}"></iframe>`} style={{ float: 'right', marginTop: -15 }} onCopy={copy}>
                <FontAwesomeIcon icon={faCopy} color={'#0C35D8'} />
              </CopyToClipboard>
            </div>
            <AspectRatio>
              {/* <iframe allowFullScreen title='Restaurant Menu' src={`https://web.squash.app/${resData?.REST_ID}`}></iframe> */}
              <iframe allowFullScreen title='Restaurant Menu' src={`http://web.squash.app/RestaurantMenu/${resData?.REST_ID}`}></iframe>
            </AspectRatio>
          </ModalBody>
          {/*   
            <ModalFooter>
              <Button onClick={props.onClose}>Close</Button>
              
            </ModalFooter> */}
        </ModalContent>
      </Modal>
    </>
  );
}

export default GenerateScriptPopup;
