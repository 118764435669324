import {
  Box,
  Image,
  Button,
  Flex,
  MenuButton,
  MenuList,
  MenuItem,
  Menu,
  IconButton,
  Text,
  Heading,
  Portal,
  Badge,
  ListItem,
  List,
} from '@chakra-ui/react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import {
  BellIcon,
  HamburgerIcon,
  EditIcon,
  SettingsIcon,
} from '@chakra-ui/icons';
import Logo from '../assets/images/Squash_FINALB.png';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useToast, useDisclosure } from '@chakra-ui/react';

import RestaurantSelectPopup from './RestaurantSelectPopup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHotel,
  faUser,
  faSignOutAlt,
  faKey,
  faAward,
  faHistory,
  faHeart,
  faEdit,
  faHome,
  faCity,
  faSearch,
  faBell,
} from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';

//import Notifications from "react-notifications-menu";
// import "../../node_modules/react-notifications/src/notifications.scss";
// squashweb\node_modules\react-notifications\src\notifications.scss

const Header = props => {
  const history = useHistory();
  const [name, setName] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [notificationCount, setnotificationCount] = useState(
    props?.data?.length
  );

  useEffect(() => {
    CheckLogstatus();
    //setnotificationCount(props?.data?.length)
  });

  async function signOut() {
    try {
      await Auth.signOut();
      Cookies.remove('cartitems');
      history.push({
        pathname: '/',
        // state: { email: values.email, password: values.password }
      });
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }
  async function RewardPoints() {
    try {
      history.push({
        pathname: '/RewardPoint',
        // state: { email: values.email, password: values.password }
      });
    } catch (error) {
      console.log('error loading page: ', error);
    }
  }
  async function OrderHistory() {
    try {
      history.push({
        pathname: '/OrderHistory',
        // state: { email: values.email, password: values.password }
      });
    } catch (error) {
      console.log('error loading page: ', error);
    }
  }
  async function ChangePassword() {
    try {
      history.push({
        pathname: '/ChangePassword',
        // state: { email: values.email, password: values.password }
      });
    } catch (error) {
      console.log('error loading page: ', error);
    }
  }

  async function UserProfile() {
    try {
      history.push({
        pathname: '/UserProfile',
      });
    } catch (error) {
      console.log('error loading page: ', error);
    }
  }
  async function Favorite() {
    try {
      history.push({
        pathname: '/Favorite',
      });
    } catch (error) {
      console.log('error loading page: ', error);
    }
  }


  async function CheckLogstatus() {
    let currentSession = null;
    try {
      currentSession = await Auth.currentSession();
      // console.log(currentSession)
      setName(currentSession.idToken.payload.email);
    } catch (error) {
      console.log('err', error);
    }
  }

  function modalClose() {
    console.log('a');
    // setIsLoading(false);
    onClose();
  }
  function AdminOrderHistory() {
    try {
      history.push({
        pathname: '/AdminOrderHistory',
        // state: { email: values.email, password: values.password }
      });
    } catch (error) {
      console.log('error loading page: ', error);
    }
  }

  return (
    <Flex
      //  pl={{
      //    md: props.isAdmin === false ? '20px !important' : '50px',
      //    lg: props.isAdmin === false ? '240px !important' : '50px',
      //  }}
      // pr={{
      //    md: props.isAdmin === false ? '30px !important' : '',
      //     lg: props.isAdmin === false ? '255px !important' : '',
      //   }}
      bg="#fff"
      borderBottom="1px solid #e6e6e6"
      position="absolute"
      top="0"
      left="0"
      right="0"
      margin="auto"
      zIndex="1000"
      p="15px"
      justifyContent="space-between"
      alignItems="center"
      direction="row"
    >
      <Box alignItems="center" flex="1" d="flex">
        <Image maxWidth={{ base: '100px', md: '130px' }} src={Logo} />
        {props.isAdmin ? (
          <Link className="">
            <HamburgerIcon
              onClick={() => {
                props.toggle();
              }}
              fontSize="22px"
              ml="20px"
            />
          </Link>
        ) : (
          <Link className="user-toggle">
            <HamburgerIcon
              onClick={() => {
                props.toggle();
              }}
              fontSize="22px"
              ml="20px"
            />
          </Link>
        )}

        {props.isAdmin ? (
          <Box>
            <RestaurantSelectPopup
              fromlogin={false}
              resData={JSON.parse(localStorage.getItem('RestaurantNames'))}
              open={isOpen}
              modalClose={modalClose}
            />

            <Box d={{ base: 'none', md: 'flex' }} className="restopname">
              <Image src={localStorage.getItem('RestaurantImage')}></Image>
              <Heading>{localStorage.getItem('Restaurantname')}</Heading>
              <Link>
                <EditIcon onClick={() => onOpen()} />
              </Link>
            </Box>
            <Box pl="20px" d={{ base: 'flex', md: 'none' }}>
              <Link>
                <FontAwesomeIcon onClick={() => onOpen()} icon={faHotel} />
              </Link>
            </Box>
          </Box>
        ) : (
          ''
        )}
      </Box>

      {props.isAdmin ? (
        <Box pr="10px">
          {/* <Link>
                    <BellIcon fontSize='25px'
                    
                    /></Link>  */}
          <Menu>
            {notificationCount != 0 ? (
              <Badge left="30px" top="-10px" className="badge-like">
                {notificationCount}
              </Badge>
            ) : (
              ''
            )}
            <MenuButton onClick={() => setnotificationCount(0)}>
              <BellIcon fontSize="25px" />
            </MenuButton>
            <Portal>
              <MenuList>
                {props.data?.map((obj, ind) => (
                  <MenuItem>{obj.message}</MenuItem>
                ))}
              </MenuList>
            </Portal>
          </Menu>
        </Box>
      ) : (
        ''
      )}
      {props.isAdmin ? (
        <Box d="flex" alignItems="center">
          <Box className="user-name-mob">
            {' '}
            Hi, <Box className="headerusername">{'  ' + name}</Box> <br />
          </Box>
          <Menu>
            <MenuButton
              w="auto"
              minWidth={{ base: '30px', md: '34px' }}
              h={{ base: '30px', md: '34px' }}
              // h='auto'
              ml="15px"
              p="0px"
              background="#001d56"
              borderRadius="30px"
              color="#ffffff"
              as={IconButton}
              aria-label="Options"
              className='primary-btn'
              icon={<FontAwesomeIcon fontSize="22px" icon={faUser} />}
              variant="outline"
            />
            <MenuList className="edit-profile-dropdown">
              {/* <MenuItem
                            icon={<EditIcon />}
                        >
                            Edit Profile
                        </MenuItem>
                        <MenuItem
                            icon={<SettingsIcon />}
                        >
                            Account settings
                        </MenuItem>
                        <MenuItem
                            icon={<FontAwesomeIcon fontSize='22px' icon={faHeart} />}
                        >
                            Favourites
                        </MenuItem>
                        <MenuItem
                            icon={<FontAwesomeIcon fontSize='22px' icon={faHistory} />}
                        >
                            Order History
                        </MenuItem>
                        <MenuItem
                            icon={<FontAwesomeIcon fontSize='22px' icon={faAward} />}
                            onClick={() => RewardPoints()}
                        >
                            Rewards
                        </MenuItem>
                        <MenuItem
                            icon={<SettingsIcon />}
                        >
                            Notification settings
                        </MenuItem>
                        <MenuItem
                            icon={<FontAwesomeIcon fontSize='22px' icon={faKey} />}
                            onClick={() => ChangePassword()}
                        >
                            Change Password
                        </MenuItem> */}

              <MenuItem
                icon={<FontAwesomeIcon fontSize="22px" icon={faHistory} />}
                onClick={() => AdminOrderHistory()}
              >
                Order History
              </MenuItem>

              <MenuItem
                icon={<FontAwesomeIcon fontSize="22px" icon={faSignOutAlt} />}
                onClick={() => signOut()}
              >
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      ) : (
        <Box d="flex" alignItems="center">
          <Box className="user-desktop-menu">
            <List h="100%" d="flex">
              <ListItem>
                <Link color="teal.500" to="/Home">
                  <Box display="flex" alignItems="center">
                    <FontAwesomeIcon fontSize="22px" icon={faHome} />
                    <Text fontSize="xl" pl="10px">
                      Home
                    </Text>
                  </Box>
                </Link>
              </ListItem>

              <ListItem>
                <Link color="teal.500" to="/Towns">
                  <Box display="flex" alignItems="center">
                    <FontAwesomeIcon fontSize="22px" icon={faCity} />
                    <Text fontSize="xl" pl="10px">
                      Towns
                    </Text>
                  </Box>
                </Link>
              </ListItem>

              <ListItem>
                <Link color="teal.500" to="/Search">
                  <Box display="flex" alignItems="center">
                    <FontAwesomeIcon fontSize="22px" icon={faSearch} />
                    <Text fontSize="xl" pl="10px">
                      Search
                    </Text>
                  </Box>
                </Link>
              </ListItem>

              <ListItem>
                <Link color="teal.500" to="/Notification">
                  <Box display="flex" alignItems="center">
                    <FontAwesomeIcon fontSize="22px" icon={faBell} />
                    <Text fontSize="xl" pl="10px">
                      Notification
                    </Text>
                  </Box>
                </Link>
              </ListItem>

              {/* <ListItem>
                <Link color="teal.500" to="#">
                  <Box display="flex" alignItems="center">
                    <FontAwesomeIcon fontSize="22px" icon={faUser} />
                    <Text fontSize="xl" pl="10px">
                      User Account
                    </Text>
                  </Box>
                </Link>
              </ListItem> */}
            </List>
          </Box>
          {/* <Menu>
            <MenuButton mr={3}>
              <BellIcon fontSize="25px" onClick={() => "/Notification" } />
            </MenuButton>
            </Menu> */}
          {/* <List>
            <ListItem  mr={3}>
              <Link color="teal.500" to="/Notification">
                <Box display="flex" alignItems="center" fontSize="19px" >
                  <FontAwesomeIcon icon={faBell} />
                  <Text fontSize="xl" pl="10px">
                      Notification
                    </Text>
                </Box>
              </Link>
            </ListItem>
          </List> */}
          {/* <Box className='user-search'>
              <form>
                <input placeholder='Search'></input>
                <button><FontAwesomeIcon icon={faSearch} /></button>
              </form>
            </Box> */}

          <Box className="user-name-mob">
            {' '}
            Hi, <Box className="headerusername">{'  ' + name}</Box> <br />
          </Box>
          <Menu>
            <MenuButton
              w="auto"
              minWidth={{ base: '30px', md: '34px' }}
              h={{ base: '30px', md: '34px' }}
              // h='auto'
              ml="15px"
              p="0px"
              background="#001d56"
              color="#ffffff"
              borderRadius="30px"
              as={IconButton}
              aria-label="Options"
              className='primary-btn'
              icon={<FontAwesomeIcon fontSize="22px" icon={faUser} />}
              variant="outline"
            />
            <MenuList className="edit-profile-dropdown">
              <MenuItem
                icon={<FontAwesomeIcon fontSize="22px" icon={faEdit} />}
                onClick={() => UserProfile()}
              >
                Profile
              </MenuItem>
              {/* <MenuItem icon={<SettingsIcon />}>Account settings</MenuItem> */}
              <MenuItem
                icon={<FontAwesomeIcon fontSize="22px" icon={faHeart} />}
                onClick={() => Favorite()}
              >
                Favorites
              </MenuItem>
              <MenuItem
                icon={<FontAwesomeIcon fontSize="22px" icon={faHistory} />}
                onClick={() => OrderHistory()}
              >
                Order History
              </MenuItem>
              <MenuItem
                icon={<FontAwesomeIcon fontSize="22px" icon={faAward} />}
                onClick={() => RewardPoints()}
              >
                Rewards
              </MenuItem>
              {/* <MenuItem icon={<SettingsIcon />}>Notification settings</MenuItem> */}
              <MenuItem
                icon={<FontAwesomeIcon fontSize="22px" icon={faKey} />}
                onClick={() => ChangePassword()}
              >
                Change Password
              </MenuItem>

              <MenuItem
                icon={<FontAwesomeIcon fontSize="22px" icon={faSignOutAlt} />}
                onClick={() => signOut()}
              >
                Logout
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      )}
    </Flex>
  );
};
export default Header;
