import {
  Box,
  Container,
  Button,
  Grid,
  GridItem,
  Image,
  Flex,
  Text,
  Spacer,
  List,
  ListItem,
  Switch,
  Spinner,
  FormControl,
  FormLabel,
  Link
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import Sidebar from '../../../components/sidebar'
import Header from '../../../components/Header'
import { Tabs, TabList, TabPanels, Tab, TabPanel, useDisclosure } from "@chakra-ui/react"
import { onCreateUserOrders } from '../../../graphql/subscriptions'
import Cookies from 'js-cookie'
import moment from 'moment'
import OrderDetailsPopup from '../../../components/OrderDetailsPopup'
import { getOrdersByRest as GetOrdersByRest } from '../../../graphql/queries'
import { getOrdersByRestAndOrder as GetOrdersByRestAndOrder } from '../../../graphql/queries'
import { createUserRestOrderWkflw as CreateUserRestOrderWkflw } from '../../../graphql/mutations'
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@fortawesome/free-solid-svg-icons'

const Orders = () => {

  const history = useHistory();
  const [togglebit, setTogglebit] = useState(false);
  const [orderes, setorders] = useState([]);

  const [subscriptionObj, setsubscriptionObj] = useState([]);

  const [acceptedorderes, setacceptedorders] = useState([]);
  const [finishedorderes, setfinishedorderes] = useState([]);
  const [allfinishedorderes, setallfinishedorderes] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();


  const [orderid, setorderid] = useState(null);
  let subscription = [];
  const [orderDetails, setorderDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setloading] = useState(false);
  const [accepted, setaccepted] = useState(false);
  const [finished, setfinished] = useState(false);
  const [notifications, setnotifications] = useState([]);
  const [switchbit, setSwitchbit] = useState(true);
  const [dininbit, setdinebit] = useState(false);

  let [specialBit, setSpecialBit] = useState(false);


  const ddata = [
    {
      image: 'https://synergi-dev.s3.ap-southeast-1.amazonaws.com/profile-pictures/6b9.png',
      message: 'Lorem ipsum dolor sit amet.',
      detailPage: '/events',
      receivedTime: '12h ago'
    },
    {
      image: 'https://synergi-dev.s3.ap-southeast-1.amazonaws.com/profile-pictures/6b9.png',
      message: 'Lorem ipsum dolor sit amet.',
      detailPage: '/events',
      receivedTime: '12h ago'
    }
  ];

  useEffect(() => {
    CheckLogstatus();
    fetchOrders();
    // toast('hhhhhhhhhhh');
    //   loadSubscription();

  }, []);

  useEffect(() => {
    if (orderid != null) {
      fetchOrderdetails();
    }
  }, [orderid]);

  useEffect(() => {

    if (subscriptionObj.ORDER_STATUS == 'Submitted') {
      let arr = [...orderes];
      arr.push(subscriptionObj)
      setorders(arr.sort((a, b) => b.ORDER_ID - a.ORDER_ID))

      let noti = [...notifications]
      noti.push({ message: 'Order #' + subscriptionObj.ORDER_ID + ' has been placed ' })
      setnotifications(noti)
    }

  }, [subscriptionObj]);


  async function fetchOrderdetails() {

    try {
      setloading(true);
      const result = await API.graphql(graphqlOperation(GetOrdersByRestAndOrder, { REST_ID: localStorage.getItem('Restaurantid'), ORDER_ID: orderid }))
      setorderDetails(result.data.getORDERS_BY_REST_AND_ORDER)
      if (result.data.getORDERS_BY_REST_AND_ORDER.ORDER_STATUS == 'Accepted') {
        setaccepted(true);
        setfinished(false);

        // setfinished(true);
      }
      else if (result.data.getORDERS_BY_REST_AND_ORDER.ORDER_STATUS == 'Completed') {
        setfinished(true);
        setaccepted(false);
      }
      else {
        setfinished(false);
        setaccepted(false);
      }


      setloading(false);
    }
    catch (err) {

      console.log(err)
    }
  }

  async function fetchOrders() {

    try {
      setIsLoading(true);
      const result = await API.graphql(graphqlOperation(GetOrdersByRest, { REST_ID: localStorage.getItem('Restaurantid') }))

      // result.data.getORDERS_BY_REST.sort((a, b) => a.ORDER_ID - b.ORDER_ID);
      const aaa = result.data.getORDERS_BY_REST?.filter((x) => x.ORDER_STATUS == 'Submitted').sort((a, b) => b.ORDER_ID - a.ORDER_ID)
      console.log(aaa)
      setorders(result.data.getORDERS_BY_REST?.filter((x) => x.ORDER_STATUS == 'Submitted').sort((a, b) => b.ORDER_ID - a.ORDER_ID))

      setacceptedorders(result.data.getORDERS_BY_REST?.filter((x) => x.ORDER_STATUS == 'Accepted').sort((a, b) => b.ORDER_ID - a.ORDER_ID))

      setfinishedorderes(result.data.getORDERS_BY_REST?.filter((x) => x.ORDER_STATUS == 'Completed').sort((a, b) => b.ORDER_ID - a.ORDER_ID))

      setallfinishedorderes(result.data.getORDERS_BY_REST?.filter((x) => x.ORDER_STATUS == 'Completed' || x.ORDER_STATUS == 'Rejected').sort((a, b) => b.ORDER_ID - a.ORDER_ID))


      setIsLoading(false);
    }
    catch (err) {

      console.log(err)
    }
  }

  async function CheckLogstatus() {

    try {
      let currentSession = await Auth.currentSession();
      const a = 10;
      if (currentSession.idToken.payload.email == 'undefined') {
        //setisLoggedIn(false);
        // isLoggedIn=false;
        history.push(
          {
            pathname: '/',
            //state: { email: values.email, password: values.password }
          }
        );
      }
      else {
        //  setisLoggedIn(true);
        // isLoggedIn=true;

      }
    } catch (err) {
      // setisLoggedIn(false);
      if (err == 'No current user') {
        history.push(
          {
            pathname: '/',
            //state: { email: values.email, password: values.password }
          }
        );
      }
      console.log('err', err);

    }
  };

  async function signOut() {
    try {
      await Auth.signOut();
      // let currentSession = await Auth.currentSession();
      history.push(
        {
          pathname: '/',
          // state: { email: values.email, password: values.password }
        }
      );
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  function toggle() {
    setTogglebit(current => !current);
  }

  function loadSubscription() {
    try {

      //   if (subscription.length > 0) {
      //     for (const item of subscription) {
      //         item.unsubscribe();
      //     }
      //     subscription = [];
      // }s


      const subscriptions = API.graphql(
        graphqlOperation(onCreateUserOrders, { REST_ID: localStorage.getItem('Restaurantid') })
      ).subscribe({
        next: ({ provider, value }) => {
          subscription.push(value);

          setsubscriptionObj(value.data.onCreateUSER_ORDERS)
          // let arr = [...orderes];
          // arr.push(value.data.onCreateUSER_ORDERS)
          // setorders(arr)
          // var date= new Date(arr[0].ORDER_PICKUP_DATE_TIME);
          // const todaydateformate = moment(date).format("YYYY-MM-DD")
          console.log({ provider, value });

        },
        error: error => console.warn(error)
      });

      //   subscription.push(API.graphql(graphqlOperation(onCreateUserOrders, { REST_ID: cartitems[0]?.restId }))
      //     .subscribe({
      //       next: subonCreateUserOrders => {

      //         updateUpcomingList(element.MENU_IT_ID, '', '', subonCreateUserOrders.value.data.onCreateUSER_LIKE_ITEM)
      //       },
      //       error: error => console.warn(error)
      //     }));
    }
    catch (err) {
      console.log(err);
    }
  }


  async function finishOrderStatus(val) {

  }

  async function updateOrderStatus(value) {

    try {
      const data = {
        REST_ID: orderDetails.REST_ID,
        ORDER_ID: orderDetails.ORDER_ID,
        CART_ID: orderDetails.CART_ID,
        // ORDER_STATUS:val==true?'Accepted':'Rejected' ,
        ORDER_PAYMENT_ID: 1,
        // DISCOUNT_APPLIED: parseFloat(discountTotal.toFixed(2)),
        // TAX_APPLIED: parseFloat((taxFactor * (subTotal - discountTotal)).toFixed(2)),
        TAX_APPLIED: orderDetails.TAX_APPLIED,
        ORDER_TOTAL_COST_BFR_TAX_BFR_DISC: orderDetails.ORDER_TOTAL_COST_BFR_TAX_BFR_DISC,
        ORDER_TOTAL_FINAL_COST: orderDetails.ORDER_TOTAL_FINAL_COST,
        ORDER_COMMENTS: orderDetails.ORDER_COMMENTS,
        ORDER_COMMENTS_BY: orderDetails.ORDER_COMMENTS_BY,
        USER_EMAIL: orderDetails.USER_EMAIL,
        ORDER_PICKUP_DATE_TIME: orderDetails.ORDER_PICKUP_DATE_TIME,
        CUSTOMER_NAME: orderDetails.CUSTOMER_NAME,
        PAYMENT_STATUS: orderDetails.PAYMENT_STATUS,
        ORDER_TYPE: orderDetails.ORDER_TYPE
      }

      if (value == 'Completed') {
        data.ORDER_STATUS = 'Completed'

      }
      else if (value == 'Accepted') {
        data.ORDER_STATUS = 'Accepted'
      }
      else {

        data.ORDER_STATUS = 'Rejected'
      }


      const result = await API.graphql(graphqlOperation(CreateUserRestOrderWkflw, { createUSER_REST_ORDER_WKFLWInput: data }))
      let list = [...orderes]
      let list2 = [...acceptedorderes]
      let list3 = [...finishedorderes]

      if (result?.data.createUSER_REST_ORDER_WKFLW?.ORDER_STATUS == 'Accepted') {
        const ind = list?.findIndex(x => x?.ORDER_ID === result?.data.createUSER_REST_ORDER_WKFLW?.ORDER_ID)
        list.splice(ind, 1);
        list2.push(result.data.createUSER_REST_ORDER_WKFLW)
        setorders(list.sort((a, b) => b.ORDER_ID - a.ORDER_ID))
        setacceptedorders(list2.sort((a, b) => b.ORDER_ID - a.ORDER_ID))
        setaccepted(true);

      }

      if (result?.data.createUSER_REST_ORDER_WKFLW?.ORDER_STATUS == 'Completed') {
        const ind = list2?.findIndex(x => x?.ORDER_ID === result?.data.createUSER_REST_ORDER_WKFLW?.ORDER_ID)
        list2.splice(ind, 1);
        list3.push(result.data.createUSER_REST_ORDER_WKFLW)
        setacceptedorders(list2.sort((a, b) => b.ORDER_ID - a.ORDER_ID))
        setfinishedorderes(list3.sort((a, b) => b.ORDER_ID - a.ORDER_ID))
        setallfinishedorderes(list3.sort((a, b) => b.ORDER_ID - a.ORDER_ID))
        setfinished(true);

      }
      if (result?.data.createUSER_REST_ORDER_WKFLW?.ORDER_STATUS == 'Rejected') {
        const ind = list?.findIndex(x => x?.ORDER_ID === result?.data.createUSER_REST_ORDER_WKFLW?.ORDER_ID)
        list.splice(ind, 1);
        setorders(list.sort((a, b) => b.ORDER_ID - a.ORDER_ID))
        list3.push(result.data.createUSER_REST_ORDER_WKFLW)
        setallfinishedorderes(list3.sort((a, b) => b.ORDER_ID - a.ORDER_ID))
      }

      console.log(result);
      console.log(JSON.stringify(result));
      onClose();

    }
    catch (err) {
      console.log('error: ', err)
      //	showValidationMsg("Error occured", 'error')
    }



  }

  function LoadAllData() {

    // if(switchbit===true)
    // setfinishedorderes(allfinishedorderes);
    // else
    // setfinishedorderes(finishedorderes)

    setSwitchbit(current => !current);
  }

  return (
    <Box bg='#f9f9f9'>
      <Helmet>
        <title>Squash | Orders</title>
      </Helmet>
      {orderDetails != null && orderid != null ?
        <OrderDetailsPopup finished={finished} finishOrderStatus={finishOrderStatus} accepted={accepted} updateOrderStatus={updateOrderStatus} orderDetails={orderDetails} open={isOpen} close={() => { onClose(); setorderid(null); setorderDetails(null); }} />
        : ''
      }
      <Header
        isAdmin={true}
        data={notifications}
        toggle={toggle} />
      <Box pt='85px' position='relative' h='100vh' overflow='hidden' d='flex'>
        <Box zIndex='9' bg='#fff' position='absolute' top='0' pt='100px' left='0' h='100%'
          // w={togglebit ? '0px' : '200px'}
          //  display={togglebit ? 'none' : 'block'}
          w={{
            base: togglebit ? '200px' : '0',
            sm: togglebit ? '200px' : '0',
            md: togglebit ? '200px' : '0',
            lg: togglebit ? '0' : '200px',
          }}
          d={{
            base: togglebit ? 'block' : 'none',
            sm: togglebit ? 'block' : 'none',
            md: togglebit ? 'block' : 'none',
            lg: togglebit ? 'none' : 'block',
          }}
        >
          <Sidebar current='Orders' togglebit={togglebit} />
        </Box>
        <Box ml={(togglebit ? "0px" : { base: "0", sm: "0", md: "0", lg: '200px' })} w='100%' p='20px' overflow='auto'>
          <Box className="card no-padding card-flex">
            <Box className="card-header" display='flex' alignItems='center' position='relative'>
              <Box className="title-heading" width='100%'><h2>Orders</h2></Box>


              <Box d='flex' m='0' ml='auto' className="today-special-tab order-pickup-tab">
                <Link className={dininbit == false ? "active" : ""} onClick={() => setdinebit(false)}  >PICK UP</Link>

                <Link className={dininbit == true ? "active" : ""} onClick={() => setdinebit(true)} >DINE-IN </Link>
              </Box>
            </Box>
            <Box className="card-content table-padding" pt='10px'>
              {isLoading ? (<Box className='orderSpinbox'> <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              /></Box >) :

                <Tabs m='5px' variant="soft-rounded" colorScheme="green" className="dashboard-tab orders-tab">
                  <Box display='flex' alignItems='center' className="orders-tab-in">
                    <TabList>
                      <Tab >New</Tab>
                      <Tab >InProgress</Tab>
                      <Tab >Completed</Tab>
                    </TabList>
                    <Box pt='10px' pb='15px' className=" orders-tab-switch">
                      <FormControl display="flex" justifyContent='flex-end' >
                        <FormLabel htmlFor="status-switch" mb="0">
                          Show Rejected Orders
                        </FormLabel>
                        <Switch id="status-switch" isChecked={switchbit} onChange={() => LoadAllData()} />
                      </FormControl>
                    </Box>
                  </Box>

                  <Box className="scrollable">

                    <TabPanels>
                      <TabPanel>


                        <Box d='flex' className="mc-table">
                          <Text w='20%' p={2} bg='#F6FAFF' color='#3D3D3D' className=" sticky" fontWeight='bold'>Order Id</Text>
                          <Text w='40%' p={2} bg='#F6FAFF' color='#3D3D3D' className=" sticky" fontWeight='bold'>Customer Email</Text>
                          <Text w='20%' p={2} bg='#F6FAFF' color='#3D3D3D' className=" sticky" fontWeight='bold'>Order Total</Text>
                          <Text w='20%' p={2} bg='#F6FAFF' color='#3D3D3D' className=" sticky" fontWeight='bold'>Order Date </Text>

                        </Box>


                        <List>

                          {(dininbit === true ? orderes.filter(x => (x.ORDER_TYPE).toUpperCase() == 'DINE-IN') :
                            orderes.filter(x => (x.ORDER_TYPE).toUpperCase() != 'DINE-IN')).map((item) =>

                              <Box cursor='pointer' className="dashboard-table-list" d='flex' onClick={() => { onOpen(); setorderid(item.ORDER_ID); }} >
                                <Box w='20%'  >
                                  <ListItem >{item.ORDER_ID}</ListItem>

                                </Box>
                                <Box w='40%'  >
                                  <ListItem >{item.USER_EMAIL}</ListItem>

                                </Box>
                                <Box w='20%'  >
                                  <ListItem>{item.ORDER_TOTAL_FINAL_COST}</ListItem>

                                </Box>
                                <Box w='20%'  >
                                  <ListItem>{moment(new Date(item.ORDER_PICKUP_DATE_TIME * 1000)).format("DD-MMM-YYYY")}</ListItem>

                                </Box>

                                {loading ? (<Box className='orderSpinbox'> <Spinner
                                  thickness="4px"
                                  speed="0.65s"
                                  emptyColor="gray.200"
                                  color="blue.500"
                                  size="xl"
                                /></Box >) : ''}
                              </Box>


                            )

                          }
                        </List>
                      </TabPanel>
                      <TabPanel>

                        <Box d='flex' className="mc-table">
                          <Text w='20%' p={2} bg='#F6FAFF' color='#3D3D3D' className=" sticky" fontWeight='bold'>Order Id</Text>
                          <Text w='40%' p={2} bg='#F6FAFF' color='#3D3D3D' className=" sticky" fontWeight='bold'>Customer Email</Text>
                          <Text w='20%' p={2} bg='#F6FAFF' color='#3D3D3D' className=" sticky" fontWeight='bold'>Order Total</Text>
                          <Text w='20%' p={2} bg='#F6FAFF' color='#3D3D3D' className=" sticky" fontWeight='bold'>Order Date </Text>

                        </Box>


                        <List>

                          {
                            (dininbit === true ? acceptedorderes.filter(x => (x.ORDER_TYPE).toUpperCase() == 'DINE-IN') :
                              acceptedorderes.filter(x => (x.ORDER_TYPE).toUpperCase() != 'DINE-IN'))?.map((item) =>

                                <Box cursor='pointer' className="dashboard-table-list" d='flex'
                                  onClick={() => { onOpen(); setorderid(item?.ORDER_ID); }}
                                >
                                  <Box w='20%'>
                                    <ListItem >{item?.ORDER_ID}</ListItem>

                                  </Box>
                                  <Box w='40%'>
                                    <ListItem >{item?.USER_EMAIL}</ListItem>

                                  </Box>
                                  <Box w='20%'>
                                    <ListItem>{item?.ORDER_TOTAL_FINAL_COST}</ListItem>

                                  </Box>
                                  <Box w='20%'>
                                    <ListItem>{moment(new Date(item?.ORDER_PICKUP_DATE_TIME * 1000)).format("DD-MMM-YYYY")}</ListItem>

                                  </Box>

                                  {loading ? (<Box className='orderSpinbox'> <Spinner
                                    thickness="4px"
                                    speed="0.65s"
                                    emptyColor="gray.200"
                                    color="blue.500"
                                    size="xl"
                                  /></Box >) : ''}
                                </Box>


                              )

                          }
                        </List>
                      </TabPanel>
                      <TabPanel>

                        <Box d='flex' className="mc-table">
                          <Text w='20%' p={2} bg='#F6FAFF' color='#3D3D3D' className=" sticky" fontWeight='bold'>Order Id</Text>
                          <Text w='40%' p={2} bg='#F6FAFF' color='#3D3D3D' className=" sticky" fontWeight='bold'>Customer Email</Text>
                          <Text w='20%' p={2} bg='#F6FAFF' color='#3D3D3D' className=" sticky" fontWeight='bold'>Order Total</Text>
                          <Text w='20%' p={2} bg='#F6FAFF' color='#3D3D3D' className=" sticky" fontWeight='bold'>Order Date </Text>

                        </Box>


                        <List>
                          {(switchbit === false ? (dininbit === true ? allfinishedorderes.filter(x => (x.ORDER_TYPE).toUpperCase() == 'DINE-IN' && x.ORDER_STATUS == 'Completed') :
                            allfinishedorderes.filter(x => (x.ORDER_TYPE).toUpperCase() != 'DINE-IN' && x.ORDER_STATUS == 'Completed'))
                            :
                            (dininbit === true ? allfinishedorderes.filter(x => (x.ORDER_TYPE).toUpperCase() == 'DINE-IN') :
                              allfinishedorderes.filter(x => (x.ORDER_TYPE).toUpperCase() != 'DINE-IN'))).map((item) =>
                                <Box cursor='pointer' className="dashboard-table-list" d='flex'
                                  onClick={() => { onOpen(); setorderid(item.ORDER_ID); }}
                                >
                                  <Box w='15%'>
                                    <ListItem >{item.ORDER_ID}</ListItem>

                                  </Box>
                                  <Box w='40%'>
                                    <ListItem >{item.USER_EMAIL}</ListItem>

                                  </Box>
                                  <Box w='20%'>
                                    <ListItem>{item.ORDER_TOTAL_FINAL_COST}</ListItem>

                                  </Box>
                                  <Box d='flex' w='25%'>
                                    <ListItem>{moment(new Date(item.ORDER_PICKUP_DATE_TIME * 1000)).format("DD-MMM-YYYY")}</ListItem>
                                    {item.ORDER_STATUS == 'Rejected' ? <Box m='15px' ><FontAwesomeIcon color='red' icon={faBan} />   </Box> : ''}
                                  </Box>

                                  {loading ? (<Box className='orderSpinbox'> <Spinner
                                    thickness="4px"
                                    speed="0.65s"
                                    emptyColor="gray.200"
                                    color="blue.500"
                                    size="xl"
                                  /></Box >) : ''}
                                </Box>


                              )

                          }
                        </List>
                      </TabPanel>
                    </TabPanels>
                  </Box >
                </Tabs>


              }
            </Box >
          </Box >
        </Box>
      </Box>
    </Box>
  );
}
export default Orders;