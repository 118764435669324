
import {
    Box,
    Container,
    Button,
    Grid,
    GridItem,
    Image,
    Flex,
    Text,
    Spacer,
    HStack
  } from "@chakra-ui/react";
  import React, { useEffect, useState } from "react";
  import { useHistory } from 'react-router-dom';
  import Sidebar from '../../../components/sidebar'
  import Header from '../../../components/Header'
  import { Auth, Hub, API, graphqlOperation } from 'aws-amplify';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faHome } from '@fortawesome/free-solid-svg-icons'

  import 'react-multi-carousel/lib/styles.css';
  
  import Slider from "react-slick";
 // import "./style.css";
 
  const Neighbourtowns = (props) => {
  
    useEffect(() => {
     
    }, []);

    const settings = {
      // centerMode: true,
      arrows: true,
      infinite: false,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 2,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 4,
          }
        },
        {
          breakpoint: 768,
          settings: {
            arrows: true,
            infinite: false,
            //centerMode: true,
            centerPadding: '40px',
            slidesToShow: 4,
          }
        },
        {
          breakpoint: 480,
          settings: {
            arrows: true,
            infinite: false,
            // centerMode: true,
            centerPadding: '40px',
            slidesToShow: 2,
          }
        }
      ]
  
    };
  
    return (
  
      <Box className="town-slider">


  
        <Slider  {...settings}>
  
          {props.cities?.map((obj,ind) =>
        
          <Button className="town-button"
           onClick={()=>props.updatetowndata(obj.zip_code)}
          >
            
              {ind==0? <FontAwesomeIcon icon={faHome} />:""}
          {obj?.city}
        </Button>
             
          )}
  
        </Slider>
  
      </Box>
    );
  }
  export default Neighbourtowns;