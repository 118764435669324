import {
  Box,
  Input,
  Button,
  Text,
  Checkbox,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  ModalFooter,
  Link,
  ListItem,
  ListIcon,
  Heading,
  UnorderedList,
  Radio,
  RadioGroup,
  Stack,
  OrderedList,
} from '@chakra-ui/react';
import React, { useRef, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useToast, useDisclosure } from "@chakra-ui/react"
import { Auth, Hub, API, graphqlOperation } from 'aws-amplify';

import TimePicker from 'rc-time-picker';

function OrderTypeConfirmPopup(props) {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const history = useHistory();
  const [pickupbit, setpickupbit] = useState(true);
  const [datevalidation, setdatevalidation] = useState(false);
  const [Radiovalue, setRadioValue] = useState('1');
  const [datevalue, setdateValue] = useState(
    moment(new Date()).format('YYYY-MM-DD')
  );
  const [value, onChange] = useState('10:00');
  //const [timevalue, settimeValue] = React.useState(moment('00:00', 'HH:mm'));
  const [timevalue, settimeValue] = useState(moment(moment().format('LT'), 'HH:mm a'));
  const [partysize, setpartysize] = React.useState(1);
  
  const handleChangepartysize = event => setpartysize(event.target.value);
  const toast = useToast()
  //const dd;

  function handleChangedate(event) {
    ////debugger;
    setdateValue(event.target.value);
    console.log(datevalue);
  }
  // function CheckDateAvailabity()
  // {
  //   if(((props.itemDetails?.DATE_FROM_DATE)< datevalue) && ((props.itemDetails?.DATE_TO_DATE) > datevalue))
  //   {
  //     alert("Ok")
  //   }
  //   else
  //   {
  //     alert("No")
  //   }

  // }
  // function AddToCart(){

  // }
  function showValidationMsg(msg, st) {
    ////debugger;
    toast({
      title: "",
      description: msg,
      status: st,
      duration: 9000,
      isClosable: true,
      position: "top"
    })
  }

  return (
    <>
      <Modal isOpen={props.isOpen} onClose={props.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Order Context</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Box
                className="order-tab"
                justifyContent="space-between"
                w="100%"
                flex="1"
                d="flex"
              >
                <Link
                  textDecoration="none"
                  w="100%"
                  onClick={() => {
                    ////debugger;
                    setpickupbit(true);
                    setdatevalidation(false);
                  }}
                >
                  {' '}
                  <Box
                    textAlign="center"
                    backgroundColor={pickupbit == true ? '#1255ac' : ''}
                    color={pickupbit == true ? '#ffffff' : '#1255ac'}
                  >
                    Pick-Up
                  </Box>
                </Link>

                <Link
                  textDecoration="none"
                  w="100%"
                  onClick={() => {
                    ////debugger;
                    setpickupbit(false);
                    setdatevalidation(false);
                  }}
                >
                  {' '}
                  <Box
                    textAlign="center"
                    backgroundColor={pickupbit == false ? '#1255ac' : ''}
                    color={pickupbit == false ? '#ffffff' : '#1255ac'}
                  >
                    Dine-In
                  </Box>
                </Link>
              </Box>
              {pickupbit == true ? (
                <RadioGroup
                  onChange={setRadioValue}
                  value={Radiovalue}
                  m="px"
                  defaultValue="2"
                >
                  <Stack spacing={5} direction="row">
                    <Box w="100%" flex="1" d="flex">
                      <Radio w="50%" colorScheme="red" value="1">
                        ASAP
                      </Radio>
                      <Radio colorScheme="green" value="2">
                        Schedule
                      </Radio>
                    </Box>
                  </Stack>
                </RadioGroup>
              ) : (
                <Box>
                  <Box className="order-date-time-dinein">
                    <Text>Date & Time</Text>
                    <Box d="flex" className="order-date-time">
                      <Input
                        type="date"
                        value={datevalue}
                        onChange={
                          e => handleChangedate(e)
                          //props.onChange
                        }
                        size="sm"
                      />
                      {/* <TimePicker
                        value={timevalue}
                        
                        onChange={value => {
                          const aa = value.format('hh:mm A');
                          console.log(aa);
                          settimeValue(moment(aa, 'HH:mm A'));
                          
                          ////debugger;
                        }}
                        showSecond={false}
                        use12Hours
                        allowEmpty
                        format="hh:mm A"
                      /> */}
                      <TimePicker
                      value={timevalue}
                       onChange={value => {
                          const aa = value.format('hh:mm A');
                          console.log(aa);
                          settimeValue(moment(aa, 'HH:mm A'));
                          
                          ////debugger;
                        }}
                        showSecond={false}
                        use12Hours
                        allowEmpty
                        format="hh:mm A"
                      />  
                    </Box>
                    <Box
                      d="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      className="party-size"
                    >
                      <Text>Party Size</Text>
                      <Input
                        w="50%"
                        type="number"
                        value={partysize}
                        onChange={handleChangepartysize}
                        size="sm"
                        min="1"
                      />
                    </Box>
                  </Box>
                  <Text
                    fontSize="13px"
                    d={
                      datevalidation == true && pickupbit == false
                        ? 'block'
                        : 'none'
                    }
                    color="red"
                  >
                    Please enter the valid dine-in details
                  </Text>
                </Box>
              )}

              {Radiovalue == '2' && pickupbit == true ? (
                <Box>
                  <Box d="flex" mb="0px !important" className="order-date-time">
                    <Input
                      type="date"
                      value={datevalue}
                      onChange={e => handleChangedate(e)}
                      size="sm"
                    />
                    <TimePicker
                      value={timevalue}
                      
                      onChange={value => {
                        const aa = value.format('hh:mm A');
                        
                        console.log(aa);
                        settimeValue(moment(aa, 'HH:mm A'));
                      
                      }}
                      showSecond={false}
                      use12Hours
                      allowEmpty
                      format="hh:mm A"
                    />
                  </Box>
                  <Text
                    fontSize="13px"
                    d={
                      datevalidation == true &&
                      Radiovalue == '2' &&
                      pickupbit == true
                        ? 'block'
                        : 'none'
                    }
                    color="red"
                  >
                    Please enter the valid date and time for pickup
                  </Text>
                </Box>
              ) : (
                ''
              )}

              {/* <Box d="flex">
                <Input
                  mt={3}
                  type="number"
                  name="phonenumber"
                  placeholder="phone"
                />
                {/* <Button ml={3} mt={3} icon={<FontAwesomeIcon fontSize='22px' icon={faPhone} />}/> */}
              {/* </Box> */}
              </Box>
          </ModalBody>

          <ModalFooter>
            <Button onClick={props.onClose}>Cancel</Button>
            <Button
              className='primary-btn'
              px={4}
              ml={3}
              onClick={() => {
                ////debugger;
                var json_cartitems = Cookies.get('cartitems')
                props.itemDetails.orderdate = datevalue;
                props.itemDetails.ordertime = timevalue.format('HH:mm');
                props.itemDetails.partysize = partysize;
                props.itemDetails.orderPickupbit = pickupbit;
                ////debugger;
                if (
                  (props.itemDetails?.DATE_FROM_DATE <= datevalue &&
                  props.itemDetails?.DATE_TO_DATE >= datevalue) || ( props.itemDetails?.DATE_FROM_DATE == undefined)
                ) {
                  props.onClose();
                  var specialitem = JSON.stringify(props.itemDetails);

                  localStorage.setItem('restID', props.itemDetails?.REST_ID);
                  // var orderDetails = {'orderdate':datevalue,'ordertime':timevalue.format('HH:mm'),'partysize':partysize};
                  // localStorage.setItem('orderDetails', orderDetails);
                  localStorage. setItem('orderDetails', JSON. stringify({'orderdate':datevalue,'ordertime':timevalue.format('HH:mm'),'partysize':partysize, 'orderPickupbit' : pickupbit }));

                  ////debugger;
                  localStorage.setItem('specialItem', specialitem);
                  history.push({
                    pathname: '/RestaurantMenu/' + props.itemDetails?.REST_ID,
                    prevouspage: 'Home',
                    quantity: props.quantity,
                  });
                  console.log(datevalue);
                  console.log(props.itemDetails);
                } 
                else 
                {
                  showValidationMsg("Special Not Available", 'error')
                }
                console.log(pickupbit);
                ////debugger;
              }}
            >
              Go To Cart
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default OrderTypeConfirmPopup;
