import {
  Box,
  Button,
  RadioGroup,
  Radio,
  Stack,
  Input,
  NumberInput,
  Link,
  Spinner,
  Image,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  ListItem,
  List,
  Flex,
  Heading,
  OrderedList,
  MenuButton,
  MenuList,
  MenuItem,
  Menu,
  IconButton,
  Progress,
  Checkbox,
  Select,
  Show,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react';

import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useToast, useDisclosure } from '@chakra-ui/react';
import Sidebar from '../../components/sidebar';
import Header from '../../components/Header';
import FoodIcon from '../../assets/images/dish.png';
import OrderSuccess from '../../assets/images/order-success.png';
import { getRestMaster as GetRestMaster, getRestMasterGuest as GetRestMasterGuest } from '../../graphql/queries';
import MenuUpdatePopup from '../../components/MenuUpdatePopup';
import { listMasterMenuChoices as ListMasterMenuChoices } from '../../graphql/queries';
import { getRestOwnerContact } from '../../graphql/queries';
import {
  EditIcon,
  CloseIcon,
  SettingsIcon,
  HamburgerIcon,
  TriangleDownIcon,
  TriangleUpIcon
} from '@chakra-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faShoppingCart,
  faUser,
  faSignOutAlt,
  faConciergeBell,
  faSignInAlt,
  faKey,
  faAward,
  faHistory,
  faHeart,
  faInfoCircle,
  faSwatchbook,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { getMenuChoices as GetMenuChoices } from '../../graphql/queries';
import Logo from '../../assets/images/Squash_FINALB.png';
import { createCartIdGenerate, updateMenuItemMaster as UpdateMenuItemMaster } from '../../graphql/mutations';
import { useParams } from 'react-router-dom';
import PreviewPopup from '../../components/PreviewPopup';
import CartPopup from '../../components/CartPopup';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import RestaurantSelectPopup from '../../components/RestaurantSelectPopup';
import { listRestMasteRs as ListRestMasteRs } from '../../graphql/queries';
import DeleteAlertDialog from '../../components/DeleteAlertDialog';
import LoginPopup from '../../components/LoginPopup';
import { useCookies, withCookies } from 'react-cookie';
import Cookies from 'js-cookie';
//import Cookies from 'js-cookie'
import TimePicker from 'rc-time-picker';
import { createUserOrderMaster as CreateUserOrderMaster } from '../../graphql/mutations';
import { createUserOrderItem as CreateUserOrderItem } from '../../graphql/mutations';
import { createRestOrderGenerate as CreateRestOrderGenerate } from '../../graphql/mutations';
import { createUserRestOrderWkflw as CreateUserRestOrderWkflw } from '../../graphql/mutations';
import { createUserPaymentDetails as CreateUserPaymentDetails } from '../../graphql/mutations';

import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import RestaurantDetailsPopup from '../../components/RestaurantDetailsPopup';
import { checkRestDiscValid as CheckRestDiscValid } from '../../graphql/queries';

import { getRestRewardPct as GetRestRewardPct } from '../../graphql/queries';
import { getUserRestRewardBal as GetUserRestRewardBal } from '../../graphql/queries';

import { getRestTaxRate as GetRestTaxRate } from '../../graphql/queries';
import UserSidebar from '../../components/UserSidebar';
import { onCreateUserOrders } from '../../graphql/subscriptions';
import { loadStripe } from '@stripe/stripe-js';
import { createUserRestBkmrkMaster as createUserRestBkmrkMaster } from '../../graphql/mutations';

import { createPaymentIntent as CreatePaymentIntent } from '../../graphql/mutations';

import { createUserRestRewardTrans as createUserRestRewardTrans } from '../../graphql/mutations';
import { getUserprofile as getUserprofile } from '../../graphql/queries';

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  CardElement,
  Elements,
} from '@stripe/react-stripe-js';
import axios from 'axios';
import { json } from 'body-parser';
// import OneSignal from 'react-onesignal';
import TimeKeeper from 'react-timekeeper';
import { NIL } from 'uuid';

function RestaurantMenu() {
  const [togglebit, setTogglebit] = useState(true);
  const [item, setItem] = useState([]);
  const [itemMenu, setItemMenu] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCartLoading, setCartIsLoading] = useState(false);
  const [isMenuChoiceLoading, setIsMenuChoiceLoading] = useState(false);

  const toast = useToast();
  const [customizeData, setCustomizeData] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editObject, setEditObject] = useState(null);

  const [tabIndex, SetTabIndex] = useState(0);
  const [subMenu, SetSubMenu] = useState(null);

  const [currentmenu, setcurrentmenu] = useState('');
  // const [oldData,setOldData] = useState([{}]);

  const [menuChoice, SetMenuChoice] = useState([]);
  const [inEditMode, setInEditMode] = useState({
    status: false,
    rowKey: null,
  });
  const [Price, setPrice] = useState(0);
  const params = useParams();
  // const [cartid, setcartid] = useState(null);
  const [cartitems, setcartitems] = useState([]);
  const [subTotal, setSubTotal] = useState(0);
  const history = useHistory();

  const {
    isOpen: isOpenDetails,
    onOpen: onOpenDetails,
    onClose: onCloseDetails,
  } = useDisclosure();
  const [resData, SetResData] = useState([]);

  const {
    isOpen: alertisOpen,
    onOpen: alertonOpen,
    onClose: alertonClose,
  } = useDisclosure();
  const {
    isOpen: LoginisOpen,
    onOpen: LoginonOpen,
    onClose: LoginonClose,
  } = useDisclosure();
  const [CurrentcartitemID, setcurrentcartitemID] = useState(0);
  const [editcartitem, seteditcartitem] = useState([]);
  const [isLogin, setisLogin] = useState(false);

  const [cartitemscookies, setcartitemsCookie, removecartitemsCookie] =
    useCookies(['cartitems']);

  const location = useLocation();
  const [restaurantInfo, setrestaurantInfo] = useState(null);

  const [isCheckout, setisCheckout] = useState(false);
  const [value, setValue] = React.useState('');

  //	const [discountmessage, setdiscountmessage] = React.useState(false)

  const [discountmessage, setdiscountmessage] = useState({
    status: false,
    valid: false,
    message: '',
  });

  const [discountTotal, setdiscountTotal] = React.useState(0);
  const [taxFactor, settaxFactor] = React.useState(0);

  const handleChange = event => setValue(event.target.value);

  const [pickupbit, setpickupbit] = useState(true);

  const [Radiovalue, setRadioValue] = React.useState('1');
  const [Radiopayment, setRadiopayment] = React.useState('1');
  const [discountId, setdiscountId] = useState(null);

  const [datevalue, setdateValue] = useState(
    moment(new Date()).format('YYYY-MM-DD')
  );

  const [specialDateValue, setSpecialDateValue] = useState(
    moment(new Date()).format('YYYY-MM-DD')
  );
  const handleChangedate = event =>
    Json_specialitem == '' && cartNumber == 0
      ? setdateValue(event.target.value)
      : setSpecialDateValue(event.target.value);

  const [partysize, setpartysize] = React.useState(1);
  const [specialPartysize, setSpecialPartysize] = useState(1);
  const handleChangepartysize = event =>
    Json_specialitem == '' && cartNumber == 0
      ? setpartysize(event.target.value)
      : setSpecialPartysize(event.target.value);

  //const [timevalue, settimeValue] = useState(moment().format("HH:mm"));

  // var now = moment.format('HH:mm');
  // var tm = now.format('HH:mm');

  // const [timevalue, settimeValue] = useState(moment('00:00', 'HH:mm'));

  const [timevalue, settimeValue] = useState(
    moment(moment().format('LT'), 'HH:mm a')
  );
  const [timevalue1, settimeValue1] = useState(
    moment(moment().format('LT'), 'HH:mm')
  );
  const [specialTimevalue, setSpecialTimevalue] = useState(
    moment(moment().format('LT'), 'HH:mm')
  );
  const [time, setTime] = useState(new Date(Date.now()));
  //const [timevalue, settimeValue] = React.useState('moment(null)');
  const [value1, onChange1] = useState(moment().format('LT'));

  const [commentvalue, setcommentvalue] = React.useState('');
  const handlecommentvalue = event => setcommentvalue(event.target.value);

  const [name, setName] = useState('');
  const [datevalidation, setdatevalidation] = useState(false);

  const [paymentbit, setpaymentbit] = useState('');
  const [orederStatus, SetOrederStatus] = useState(false);
  const [paymentLoader, SetpaymentLoader] = useState(false);
  const [cardValidation, SetcardValidation] = useState(false);
  const [checkedItem, setCheckedItem] = useState(false);

  const [orderid, setorderid] = useState(null);

  const [dateState, useDateState] = useState(new Date());

  const [rewardPercentage, setRewardPercentage] = useState(0);
  const [rewardPoint, setRewardPoint] = useState(0);
  const [cartNumber, setCartNumber] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState('');

  const [isEdit, setIsEdit] = useState(true);
  const [specialList, setSpecialList] = useState([]);
  const [specialLength, setSpecialLength] = useState(0);
  const [upcomingSpecialList, setUpcomingSpecialList] = useState([]);
  const [upcomingSpecialLength, setUpcomingSpecialLength] = useState(0);
  const [onlineCouponStatus, setOnlineCouponStatus] = useState(false);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [percent, setPercent] = useState(0);
  const [onlinediscountAmnt, setOnlinediscountAmnt] = useState(0);
  const [discID, setDkiscID] = useState(0);
  const [coupon, setCoupon] = useState('');
  const [code, setCode] = useState('');
  const [total, setTotal] = useState(0);
  
  // var orderDetails = '';
  const Json_specialitem = localStorage.getItem('specialItem');

  const onSelectMenuItemFromDropDown = (selectedValue) => {
    switch(selectedValue) {
      case 'special':
        SetSubMenu(selectedValue);
        setcurrentmenu('special');
      break;

      case 'upcomingspecial':
        setcurrentmenu('upcomingspecial');
      break;

      default:
        SetSubMenu(selectedValue);
        setcurrentmenu(selectedValue);
    }    
  }

  useEffect(() => {
    CheckLogstatus();

    ManuData();

    userDetails();
    //settimeValue(now.format('HH:mm'));
    console.log(timevalue);

    ////

    const page = location.prevouspage;
    const from = location.from;
    const Json_specialitem = localStorage.getItem('specialItem');

    const orderDetails = JSON.parse(localStorage.getItem('orderDetails'));
    //
    console.log(orderDetails);

    var firstData = Cookies.get('cartitems');

    setSpecialDateValue(orderDetails?.orderdate);
    // setSpecialTimevalue(moment(orderDetails.ordertime, 'HH:mm'));
    setSpecialTimevalue(moment(orderDetails?.ordertime, 'HH:mm A'));
    setSpecialPartysize(orderDetails?.partysize);

    //// //
    if (from == 'SpotlightRestaurants') {
      const v = 10;
    } else if (page == 'Home') {
      const Json_specialitem = localStorage.getItem('specialItem');
      //// //
      const specialitem = JSON.parse(Json_specialitem);
      if (orderDetails?.orderPickupbit == true) {
        setpickupbit(true);
      } else {
        setpickupbit(false);
      }

      if (firstData == undefined || firstData == '') {
        // // //
        setSpecialDateValue(orderDetails?.orderdate);
        setSpecialTimevalue(moment(orderDetails?.ordertime, 'HH:mm A'));
        setSpecialPartysize(orderDetails?.partysize);
      } else {
        //// //
      }

      // // //
      const a = 10;
      const quantity = location.quantity;
      setisLogin(true);
      let today = moment(new Date(), 'YYYY-MM-DD');
      let formattedtoday = moment(today, 'YYYY-MM-DD').format('YYYY-MM-DD');

      const data = {
        totalprice: specialitem.MENU_IT_PRICE,
        quantity: quantity,
        restId: specialitem.REST_ID,
        menu: {
          MENU_IT_NAME: specialitem.MENU_IT_NAME,
          MENU_IT_ID: specialitem.MENU_IT_ID,
        },
        cart_created_date: formattedtoday,
        index: 1,
      };
      //// //

      addtocart(data);

      return;
    } else if (page == 'searchresult') {
      //
      const Json_specialitem = localStorage.getItem('specialItem');

      const specialitem = JSON.parse(Json_specialitem);
      const a = 10;
      // const quantity = location.quantity
      // setisLogin(true);

      // const data = {

      // 	totalprice: specialitem.MENU_IT_PRICE,
      // 	quantity: quantity,
      // 	restId: specialitem.REST_ID,
      // 	menu: { MENU_IT_NAME: specialitem.MENU_IT_NAME, MENU_IT_ID: specialitem.MENU_IT_ID },

      // }
      //

      addtocart(specialitem);

      return;
    }
    // else if(page == 'abc'){
    //   setCartNumber(1);
    // }

    async function checklogin() {
      const user = await Auth.currentCredentials();
      if (user.authenticated == true) {
        setisLogin(true);
      }
    }
    checklogin();

    var json_cartitems = cartitemscookies.cartitems;
    //var json_cartitems = 	Cookies.get('cartitems')
    //	const cartitems = JSON.parse(json_cartitems);
    if (json_cartitems?.length > 0) {
      setCartNumber(1);
      console.log(cartNumber);
      setcartitems(json_cartitems);
      let total = 0;
      //
      setSpecialDateValue(orderDetails?.orderdate);
      setSpecialTimevalue(moment(orderDetails?.ordertime, 'HH:mm A'));
      setSpecialPartysize(orderDetails?.partysize);
      if (orderDetails?.orderPickupbit == true) {
        setpickupbit(true);
      } else {
        setpickupbit(false);
      }
      json_cartitems?.map((item, index) => {
        total = total + parseFloat(item.totalprice) * parseInt(item.quantity);
        setSubTotal(total);
      });
    }
  }, [Json_specialitem]);

  useEffect(() => {
    functiononlinecoupons();
  }, []);

  async function CheckLogstatus() {
    let currentSession = null;
    try {
      currentSession = await Auth.currentSession();
      // console.log(currentSession)
      setName(currentSession.idToken.payload.email);
    } catch (error) {
      console.log('err', error);
    }
  }

  async function savePopup(RestId, UserBookmark) {
    console.log(localStorage.getItem('restID') + '--' + UserBookmark);
    const data = {
      REST_ID: localStorage.getItem('restID'),
      USER_BOOKMARK_YN: UserBookmark,
    };

    const result = await API.graphql(
      graphqlOperation(createUserRestBkmrkMaster, {
        createUSER_REST_BKMRK_MASTERInput: data,
      })
    );
    //// //
  }

  function onScroll() {
    const scrollY = window.scrollY; //Don't get confused by what's scrolling - It's not the window
    const scrollTop = this?.myRef.current.scrollTop;
    console.log(
      `onScroll, window.scrollY: ${scrollY} myRef.scrollTop: ${scrollTop}`
    );
    // this.setState({
    //   scrollTop: scrollTop
    // })
  }

  const handleScroll = e => {
    console.log('scolled');
    let element = e?.target;

    var list = document.querySelector("div[id='0'] ul ")?.children;
    for (var i = 0, len = list?.length; i < len; i++) {
      const t = list[i].offsetTop;
      if (
        element.scrollTop + 278 - list[i].offsetTop < 103 &&
        element.scrollTop + 278 - list[i].offsetTop > 0
      ) {
        const obj = list[i].offsetTop;
        const submenu = list[i].attributes['id']?.nodeValue;
        const a = 10;

        setcurrentmenu(submenu);
      }
      var d = list[i].offsetTop;
    }

    var listsidebar = document.querySelector('ul.inner-menu')?.children;
    var flag = true;
    for (var i = 0, len = listsidebar?.length; i < len; i++) {
      const t = listsidebar[i].offsetTop;
      const p = listsidebar[i].offsetTop;
      if (listsidebar[i]?.attributes['id']?.nodeValue == currentmenu) {
        const t = listsidebar[i].offsetTop;
        const w = document.getElementById('scroll-div-sidebar').scrollTop;
        const e = 10;
        //	if(i % 3==0 )
        //	{
        document.getElementById('scroll-div-sidebar').scrollTop = (i - 2) * 83;
        //}
      }
      //  if(list[i].attributes['id'].nodeValue==data.MENU_GR_NAME &&flag)
      //  {
      // 	 flag=false
      // 	//  const obj=list[i].offsetTop;
      // 	//  const submenu=list[i].attributes['id'].nodeValue;
      // 	//  const a=10
      // 	 document.getElementById("scroll-div-sidebar").scrollTop =list[i].offsetTop-278
      //  }
    }

    const scrollY = window.scrollY; //Don't get confused by what's scrolling - It's not the window
    //const scrollTop = this.myRef.current.scrollTop

    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      // do something at end of scroll
    }
  };

  useEffect(() => {
    MenuDetails();
  }, [editObject]);

  function loginhandle() {
    setisLogin(true);
  }

  // async function signOut() {
  //   try {
  //     await Auth.signOut();
  //     setisLogin(false);
  //     //removecartitemsCookie('cartitems-cookie');
  //     setcartitemsCookie('cartitems', '', { path: '/' });
  //     //Cookies.set('cartitems', [])
  //     // Cookies.remove('cartitems')
  //     setcartitems([]);
  //     const page = location.prevouspage;
  //     if (page == 'Home') {
  //       history.push({
  //         pathname: '/',
  //         //state: { email: values.email, password: values.password }
  //       });
  //     }
  //   } catch (error) {
  //     console.log('error signing out: ', error);
  //   }

  // }

  async function signOut() {
    try {
      await Auth.signOut();
      Cookies.remove('cartitems');
      history.push({
        pathname: '/',
        // state: { email: values.email, password: values.password }
      });
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  async function addtocart(data) {
    let ID = null
    let idx = null
    if (data.clearcart == true) {
      //
      setcartitems([]);
      // ID = getRandomInt(1, 999999);
      // setcartid(ID);
      let cartidresult = await API.graphql(
        graphqlOperation(createCartIdGenerate)
      );
      ID = cartidresult.data?.createCART_ID_GENERATE?.CART_ID;
      // let today = moment(new Date(), "YYYY-MM-DD");
      // let formattedtoday = moment(today, "YYYY-MM-DD").format("YYYY-MM-DD");

      let array = [];
      const newdata = { ...data, cartID: ID };
      console.log('............new dataa', newdata);
      array.push(newdata);
      setcartitems(array);
      var json_cartitems = JSON.stringify(array);
      // createCookie('cartitems', json_cartitems);
      setcartitemsCookie('cartitems', json_cartitems, { path: '/' });

      let total = 0;
      array.map((item, index) => {
        total = total + parseFloat(item.totalprice) * parseInt(item.quantity);
      });
      setSubTotal(total);
      const b = 10;
      setCartIsLoading(false);
    }
    else {
      const items = [...cartitems];
      if (items.length == 0) {
        // ID = getRandomInt(1, 999999);
        // setcartid(ID);
        let cartidresult = await API.graphql(
          graphqlOperation(createCartIdGenerate)
        );
        ID = cartidresult.data?.createCART_ID_GENERATE?.CART_ID;
      }
      else {
        ID = items[0].cartID;
      }

      idx = items.findIndex(x => x.index === CurrentcartitemID);
      console.log('----------------------', data.index)
      if (idx >= 0) {
        items[idx].quantity = data.quantity
        setcurrentcartitemID(null)
      } else {
        const newdata = { ...data, cartID: ID };
        items.push(newdata);
      }
      console.log(items)

      var json_cartitems = items;

      var oldData1 = Cookies.get('cartitems');
      let oldData = [];
      if (oldData1 != undefined && oldData1 != '') {
        oldData = JSON.parse(oldData1);
      }
      var temp = [];
      if (oldData != undefined || oldData != '') {
        oldData.map(item => {
          temp.push(item);
        });
      }

      // var temp =[];
      // if(oldData != undefined){
      //   temp.push(oldData[1]);
      // }
      // const len = json_cartitems.length - 1;
      // temp.push(json_cartitems[len]);

      setcartitems(items);
      var cartData = JSON.stringify(items);

      setcartitemsCookie('cartitems', cartData, { path: '/' });

      let total = 0;
      items.map((item, index) => {
        total = total + parseFloat(item.totalprice) * parseInt(item.quantity);
      });
      setSubTotal(total);
      setCartIsLoading(false);
    }
  }

  function deletefromcart() {
    const list = [...cartitems];
    const idx = list.findIndex(x => x.index === CurrentcartitemID);
    console.log('delete idx', idx)
    list.splice(idx, 1);
    setcartitems(list);

    let total = 0;
    list.map((item, index) => {
      total = total + parseFloat(item.totalprice) * parseInt(item.quantity);
    });
    setSubTotal(total);

    var json_cartitems = JSON.stringify(list);
    // createCookie('cartitems', json_cartitems);
    //// //
    setcartitemsCookie('cartitems', json_cartitems, { path: '/' });
    //	Cookies.set('cartitems',json_cartitems)
    setcartitems(list);
  }
  async function MenuDetails() {
    try {
      setIsMenuChoiceLoading(true)
      const user = await Auth.currentCredentials();
      let result = null;

      if (user.authenticated == true && editObject !== null) {
        result = await API.graphql(
          graphqlOperation(GetMenuChoices, {
            MENU_IT_ID: editObject?.MENU_IT_ID,
          })
        );
        console.log(result);

        const s = result?.data.getMENU_CHOICES[0]?.data;
        SetMenuChoice(result?.data.getMENU_CHOICES);
        setIsMenuChoiceLoading(false)
      } else if (user.authenticated == false && editObject !== null) {
        result = await API.graphql({
          query: GetMenuChoices,
          variables: { MENU_IT_ID: editObject?.MENU_IT_ID },
          authMode: 'AWS_IAM',
        });
        const s = result?.data.getMENU_CHOICES[0]?.data;
        SetMenuChoice(result?.data.getMENU_CHOICES);
        setIsMenuChoiceLoading(false)
      }
    } catch (err) {
      console.log(err);
    }
  }

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  async function cartSave(cartarray) {
    const a = 10;
    //	const cartId = getRandomInt(1, 999999);
    let total = 0;
    cartarray.map((item, index) => {
      total = total + parseFloat(item.totalprice) * parseInt(item.quantity);
    });

    try {
      //	const todaydateformate =  formatDate(new Date()) + '-08:15';
      const todaydateformate = moment(new Date()).format('YYYY-MM-DD-hh:mm');
      const data = {
        //		CART_CREATE_DATE: '1970-01-01Z',
        CART_CREATE_DATE: todaydateformate,
        CART_ID: cartarray[0].cartID,
        CART_STATUS: 'CREATED',
        CART_TOTAL_COST: parseFloat(total.toFixed(2)),
        CART_TOTAL_ITEM_QUANTITY: cartarray.length,
        REST_ID: cartarray[0].restId,
      };
      console.log(JSON.stringify(data));
      const result = await API.graphql(
        graphqlOperation(CreateUserOrderMaster, { createUSER_ORDERInput: data })
      );
      console.log(JSON.stringify(result));
      console.log('Ok');
    } catch (err) {
      console.log('error: ', err);
      //		showValidationMsg("Error occured", 'error')
    }

    try {
      cartarray.map((item, index) => {
        //	total = total + parseFloat(item.totalprice) * (parseInt(item.quantity))
        const radiocurrentlist = item.customizationdata?.filter(
          ob => ob?.MUST_CHOOSE_FLAG == true
        );
        let customizationsArray = radiocurrentlist?.map(a => a.CH_ITEM_ID);
        const listcheck = item.customizationdata?.filter(ob => ob?.checkdata);

        listcheck?.map((oob, i) => {
          oob.checkdata?.map(objj => {
            customizationsArray.push(objj.CH_ITEM_ID);
          });
        });
        if (customizationsArray == undefined) customizationsArray = [];
        var customizationstring = "'" + customizationsArray?.join("','") + "'";

        const data = {
          CART_ID: item.cartID,
          CART_ITEM_NUM: index + 1,
          CART_ITEM_QUANTITY: item.quantity,
          CART_ITEM_TOTAL_PRICE: parseFloat(
            (item.quantity * item.totalprice).toFixed(2)
          ),
          MENU_IT_ID: item.menu.MENU_IT_ID,
          CH_ITEM_IDS: customizationstring,
        };

        callCartmutation(data);
      });
      setdiscountId(null);

      try {
        const result = await API.graphql(
          graphqlOperation(GetRestTaxRate, { REST_ID: item?.REST_ID })
        );

        settaxFactor(result.data.getREST_TAX_RATE.TAX_RATE);
        console.log('Ok');
      } catch (err) {
        console.log('error: ', err);
        //		showValidationMsg("Error occured", 'error')
      }

      console.log('Ok');
    } catch (err) {
      console.log('error: ', err);
      //		showValidationMsg("Error occured", 'error')
    }
  }
  async function callCartmutation(data) {
    try {
      console.log(JSON.stringify(data));
      let result2 = await API.graphql(
        graphqlOperation(CreateUserOrderItem, {
          createUSER_ORDER_ITEMInput: data,
        })
      );
      console.log(JSON.stringify(result2));
    } catch (err) {
      console.log(err);
    }
  }

  //Show toast for validation message method
  function showValidationMsg(msg, st) {
    toast({
      title: '',
      description: msg,
      status: st,
      duration: 6000,
      isClosable: true,
      position: 'top',
    });
  }
  function showValidationMsgSpcl(msg, st) {
    //// //
    toast({
      title: '',
      description: msg,
      status: st,
      duration: 9000,
      isClosable: true,
      position: 'top',
    });
  }

  async function ManuData() {
    try {
      setIsLoading(true);

      const user = await Auth.currentCredentials();

      let result = null;
      if (user.authenticated == true) {
        result = await API.graphql(
          graphqlOperation(GetRestMaster, { REST_ID: params.id })
        );

        console.log(
          '............',
          result?.data?.getREST_MASTER?.relatedTaxRewards
        );


        let rewardPrct =
          result?.data?.getREST_MASTER?.relatedTaxRewards?.REWARD_PCT;
        let rewardPt =
          result?.data?.getREST_MASTER?.relatedTaxRewards?.REWARD_BAL;
        console.log(rewardPrct);
        console.log(rewardPt);
        setRewardPercentage(rewardPrct);
        setRewardPoint(rewardPt);

        const a = 10;
      } else {
        result = await API.graphql({
          query: GetRestMasterGuest,
          variables: { REST_ID: params.id },
          authMode: 'AWS_IAM',
        });
        // result = await API.graphql(
        //   graphqlOperation(GetRestMasterGuest, { REST_ID: params.id })
        // );
      }

      localStorage.setItem('restID', result?.data.getREST_MASTER.REST_ID);
      //// //
      const resInfodata = {
        ADDRESS: result?.data.getREST_MASTER.ADDRESS,
        CITY_NAME: result?.data.getREST_MASTER.CITY_NAME,
        RATING: result?.data.getREST_MASTER.RATING,
        REST_NAME: result?.data.getREST_MASTER.REST_NAME,
        REVIEW_NUMBERS: result?.data.getREST_MASTER.REVIEW_NUMBERS,
        OPEN_YN: result?.data.getREST_MASTER.OPEN_YN,

        day: [
          result?.data.getREST_MASTER.SUNDAY_HOURS,
          result?.data.getREST_MASTER.MONDAY_HOURS,
          result?.data.getREST_MASTER.TUESDAY_HOURS,
          result?.data.getREST_MASTER.WEDNESDAY_HOURS,
          result?.data.getREST_MASTER.THURSDAY_HOURS,
          result?.data.getREST_MASTER.FRIDAY_HOURS,
          result?.data.getREST_MASTER.SATURDAY_HOURS,
        ],
      };

      setrestaurantInfo(resInfodata);
      console.log('After API');
      setItem(result?.data.getREST_MASTER);
      ////
      const distinctItemMENU_NAME =
        result.data.getREST_MASTER?.relatedMenuGroups
          .map(item => item.MENU_NAME)
          .filter((value, index, self) => self.indexOf(value) === index);

      const aa =
        result?.data.getREST_MASTER?.relatedMenuGroups[0]?.MENU_GR_NAME;
      console.log(aa);
      SetSubMenu(aa);
      // setcurrentmenu(aa);
      setcurrentmenu(
        result?.data?.getREST_MASTER?.relatedSplTodayByRest?.length !== 0 ?
          'special'
          : result?.data.getREST_MASTER?.relatedMenuGroups[0]?.MENU_GR_NAME);
      //	SetSubMenus(result?.data.getREST_MASTER.relatedMenuGroups)


      setItemMenu(distinctItemMENU_NAME);

      setSpecialList(result?.data?.getREST_MASTER?.relatedSplTodayByRest);
      setSpecialLength(
        result?.data?.getREST_MASTER?.relatedSplTodayByRest?.length
      );
      setUpcomingSpecialList(
        result?.data?.getREST_MASTER?.relatedSplUpcomingByRest
      );
      setUpcomingSpecialLength(
        result?.data?.getREST_MASTER?.relatedSplUpcomingByRest?.length
      );

      setIsLoading(false);
    } catch (err) {
      console.log(err);
      console.log(JSON.stringify(err));
    }
  }

  async function handlecartpop(val) {
    const user = await Auth.currentCredentials();

    if (user.authenticated == true) {
      setCartIsLoading(true);
      setEditObject(val);
      onOpen();
    } else {
      LoginonOpen();

      // setCartIsLoading(true)
      // setEditObject(val)
      // onOpen();
    }
  }

  // async function CustomizeData() {
  //   try {
  //     setIsLoading(true);
  //     const result = await API.graphql(graphqlOperation(ListMasterMenuChoices));
  //     console.log(result);
  //     setCustomizeData(result?.data.listMASTER_MENU_CHOICES);
  //     setIsLoading(false);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  // async function UpdateMenu(data) {
  //   try {
  //     const a = 0;
  //     await API.graphql(
  //       graphqlOperation(UpdateMenuItemMaster, {
  //         updateMENU_ITEM_MASTERInput: data,
  //       })
  //     )
  //       .then(() => {
  //         onClose();
  //         SetTabIndex(0);
  //       })
  //       .then(() => {
  //         ManuData();
  //         showValidationMsg('Menu updated Successfully', 'success');
  //       });
  //     console.log('Ok');
  //   } catch (err) {
  //     console.log('error: ', err);
  //     showValidationMsg('Error occured', 'error');
  //   }
  // }

  function toggle() {
    setTogglebit(current => !current);
  }

  // const onSave = obj => {
  //   console.log(obj);
  //   console.log(Price);
  //   const data = {
  //     MENU_IT_ID: obj?.MENU_IT_ID,
  //     MENU_IT_NAME: obj.MENU_IT_NAME,
  //     MENU_IT_DESC: obj.MENU_IT_DESC,
  //     MENU_IT_PRICE: parseFloat(Price),
  //   };
  //   UpdateMenu(data);
  //   setInEditMode({
  //     status: false,
  //     rowKey: null,
  //   });
  // };
  // const onCancel = () => {
  //   setInEditMode({
  //     status: false,
  //     rowKey: null,
  //   });
  //   // reset the unit price state value
  //   //setPrice(null);
  // };

  async function CallDiscountAPI(code) {
    try {
      const a = 0;
      const result = await API.graphql(
        graphqlOperation(CheckRestDiscValid, {
          REST_ID: item?.REST_ID,
          DISC_CODE: code,
        })
      );

      if (result.data.checkREST_DISC_VALID.DISC_VALID) {
        setdiscountmessage({
          status: true,
          valid: true,
          message: 'Coupon Successfully Added',
        });
        setdiscountId(result.data.checkREST_DISC_VALID.DISCOUNT_ID);
        setdiscountTotal(
          subTotal * result.data.checkREST_DISC_VALID.DISCOUNT_RATE
        );
      } else {
        setdiscountmessage({
          status: true,
          valid: false,
          message: 'Invalid Coupon Code',
        });
        setdiscountTotal(0);
      }
      // .then(
      // 	() => { onClose(); SetTabIndex(0) })
      // 	.then(() => { ManuData(); showValidationMsg("Menu updated Successfully", 'success') })
      console.log('Ok');
    } catch (err) {
      console.log('error: ', err);
      //	showValidationMsg("Error occured", 'error')
    }
    const a = 10;
  }
  //Discount Function
  async function functiononlinecoupons() {
    const restaurants = await API.graphql(
      graphqlOperation(GetRestMaster, { REST_ID: params.id })
    );
    //
    console.log('------------Total Amount----------', subTotal);
    console.log(
      'restaurants?.data?.getREST_MASTER?.relatedTaxRewards :: ',
      restaurants?.data?.getREST_MASTER?.relatedTaxRewards
    );
    const orderMode = pickupbit == 'true' ? 'PICK-UP' : 'Dine-In';
    if (
      (restaurants?.data?.getREST_MASTER?.relatedTaxRewards?.APPLY_DEFAULT ===
        'Y' &&
        restaurants?.data?.getREST_MASTER?.relatedTaxRewards?.DISCOUNT_TYPE ===
        'BOTH' &&
        restaurants?.data?.getREST_MASTER?.relatedTaxRewards
          ?.DISCOUNT_USE_COUNT <
        restaurants?.data?.getREST_MASTER?.relatedTaxRewards
          ?.DISCOUNT_MAX_USAGE_COUNT) ||
      (restaurants?.data?.getREST_MASTER?.relatedTaxRewards?.APPLY_DEFAULT ===
        'Y' &&
        restaurants?.data?.getREST_MASTER?.relatedTaxRewards?.DISCOUNT_TYPE ===
        orderMode &&
        restaurants?.data?.getREST_MASTER?.relatedTaxRewards
          ?.DISCOUNT_USE_COUNT <
        restaurants?.data?.getREST_MASTER?.relatedTaxRewards
          ?.DISCOUNT_MAX_USAGE_COUNT)
    ) {
      if (
        restaurants?.data?.getREST_MASTER?.relatedTaxRewards?.ORDER_AMT_MIN ===
        null
      ) {
        setOnlineCouponStatus(true);
        if (
          restaurants?.data?.getREST_MASTER?.relatedTaxRewards?.DISCOUNT_RATE !=
          null
        ) {
          setdiscountTotal(
            subTotal -
            ((restaurants?.data?.getREST_MASTER?.relatedTaxRewards
              ?.DISCOUNT_RATE *
              100) /
              100) *
            total
          );
          setPercent(
            restaurants?.data?.getREST_MASTER?.relatedTaxRewards
              ?.DISCOUNT_RATE * 100
          );
        } else {
          setdiscountTotal(
            restaurants?.data?.getREST_MASTER?.relatedTaxRewards?.DISCOUNT_AMT
          );
          setPercent(0);
          setOnlinediscountAmnt(
            restaurants?.data?.getREST_MASTER?.relatedTaxRewards?.DISCOUNT_AMT
          );
        }
        setDkiscID(
          restaurants?.data?.getREST_MASTER?.relatedTaxRewards?.DISCOUNT_ID
        );
        setCoupon(
          restaurants?.data?.getREST_MASTER?.relatedTaxRewards?.DISCOUNT_CODE
        );
        setCode(
          restaurants?.data?.getREST_MASTER?.relatedTaxRewards?.DISCOUNT_CODE
        );
      } else {
        if (
          subTotal >=
          restaurants?.data?.getREST_MASTER?.relatedTaxRewards?.ORDER_AMT_MIN
        ) {
          setOnlineCouponStatus(true);
          if (
            restaurants?.data?.getREST_MASTER?.relatedTaxRewards
              ?.DISCOUNT_RATE != null
          ) {
            setdiscountTotal(
              subTotal -
              ((restaurants?.data?.getREST_MASTER?.relatedTaxRewards
                ?.DISCOUNT_RATE *
                100) /
                100) *
              subTotal
            );
            setPercent(
              restaurants?.data?.getREST_MASTER?.relatedTaxRewards
                ?.DISCOUNT_RATE * 100
            );
          } else {
            setdiscountTotal(
              restaurants?.data?.getREST_MASTER?.relatedTaxRewards?.DISCOUNT_AMT
            );
            setPercent(0);
            setOnlinediscountAmnt(
              restaurants?.data?.getREST_MASTER?.relatedTaxRewards?.DISCOUNT_AMT
            );
          }
          setDkiscID(
            restaurants?.data?.getREST_MASTER?.relatedTaxRewards?.DISCOUNT_ID
          );
          setCoupon(
            restaurants?.data?.getREST_MASTER?.relatedTaxRewards?.DISCOUNT_CODE
          );
          setCode(
            restaurants?.data?.getREST_MASTER?.relatedTaxRewards?.DISCOUNT_CODE
          );
        } else {
          setdiscountTotal(0);
          setOnlinediscountAmnt(0);
        }
      }
    }
  }

  function toggle() {
    setTogglebit(current => !current);
  }
  async function userDetails() {
    const result = await API.graphql(graphqlOperation(getUserprofile));

    setPhoneNumber(result?.data?.getUSERPROFILE?.phone_number);

  }

  async function callplaceordermutation() {
    try {
      const data = { REST_ID: cartitems[0]?.restId };
      const result = await API.graphql(
        graphqlOperation(CreateRestOrderGenerate, {
          createREST_ORDER_GENERATEInput: data,
        })
      );
      //	ORDER_ID = result?.data.createREST_ORDER_GENERATE?.ORDER_ID

      return result;
    } catch (err) {
      console.log('error: ', err);
      //	showValidationMsg("Error occured", 'error')
    }
  }

  // rewards balance deduction mutation
  const rewardsDeductionMutation = async (subTotal, ORDER_ID, restId) => {
    const inputData = {
      REST_ID: restId,
      ORDER_ID: ORDER_ID,
      ORDER_AMT: subTotal,
      REWARD_TRANS_TYPE: 'USE',
      REWARD_AMT: `-${rewardPoint >
        subTotal -
        (rewardPoint != null && checkedItem == true ? rewardPoint : 0) +
        taxFactor * (subTotal - discountTotal) -
        discountTotal
        ? rewardPoint -
        (subTotal -
          (rewardPoint != null && checkedItem == true ? rewardPoint : 0) +
          taxFactor * (subTotal - discountTotal) -
          discountTotal)
        : rewardPoint
        }`,
    };
    console.log(
      '-**************************************************************************'
    );
    console.log(inputData);
    try {
      const result = await API.graphql(
        graphqlOperation(createUserRestRewardTrans, {
          createUSER_REST_REWARD_TRANSInput: inputData,
        })
      );
      console.log('reward deduction result');
      console.log(result);
      if (result) {
        console.log('reward deduction success');
      }

      // AsyncStorage.removeItem('cartDetails');
    } catch (err) {
      console.log('error: ', err);
    }
  };

  //Reward Mutation

  async function rewardsMutation(subTotal, ORDER_ID, restId) {
    let reward_amnt = (subTotal / 100) * rewardPercentage;

    const inputData = {
      REST_ID: restId,
      ORDER_ID: ORDER_ID,
      ORDER_AMT: subTotal,
      REWARD_TRANS_TYPE: 'GET',
      REWARD_AMT: reward_amnt,
    };

    console.log(inputData);

    try {
      const result = await API.graphql(
        graphqlOperation(createUserRestRewardTrans, {
          createUSER_REST_REWARD_TRANSInput: inputData,
        })
      );
      console.log('reward mutation result');
      console.log(result);
      if (result) {
        console.log('reward mutation success');
      }
    } catch (err) {
      console.log('error: ', err);
    }
  }

  async function callplaceordermutationw_workflow(ORDER_ID) {
    try {
      let currentSession = await Auth.currentSession();
      const paymentStatus = Radiopayment == 1 ? "Pay Now" : "Pay at Restaurant";
      const orderType = pickupbit == true ? "PICK-UP" : "DINE-IN";
      //setName(currentSession.idToken.payload.email)

      const data = {
        REST_ID: cartitems[0].restId,
        ORDER_ID: ORDER_ID,
        CART_ID: cartitems[0].cartID,
        ORDER_STATUS: 'Submitted',
        ORDER_PAYMENT_ID: 1,

        DISCOUNT_APPLIED: parseFloat(onlinediscountAmnt > 0
          ? onlinediscountAmnt.toFixed(2)
          : ((percent / 100) * total).toFixed(
            2
          )),
        TAX_APPLIED: parseFloat((subTotal * taxFactor).toFixed(2)),
        ORDER_TOTAL_COST_BFR_TAX_BFR_DISC: 1.5,
        ORDER_TOTAL_FINAL_COST: parseFloat(
          (subTotal + (subTotal * taxFactor)
            - (rewardPoint != null && checkedItem == true ? rewardPoint : 0)
            - (onlinediscountAmnt > 0 ? onlinediscountAmnt.toFixed(2) :
              ((percent / 100) * total).toFixed(2))).toFixed(2)
        ),
        ORDER_COMMENTS: commentvalue,
        ORDER_COMMENTS_BY: '',
        USER_EMAIL: currentSession.idToken.payload.email,
        CUSTOMER_NAME: currentSession.idToken.payload.name,
        PAYMENT_STATUS: paymentStatus,
        ORDER_TYPE: orderType
        //		ORDER_STATUS_DATE_TIME: 10
      };
      if (discountId) {
        data.DISCOUNT_ID = discountId;
      }
      if (Radiovalue == '1' && pickupbit == true) {
        const time = Math.round(new Date().valueOf() / 1000);
        //	console.log(time);

        //	const time = Math.round(new Date().getTime() / 1000)
        data.ORDER_PICKUP_DATE_TIME = time;
      } else if (
        (Radiovalue == '2' && pickupbit == true) ||
        pickupbit == false
      ) {
        const date = datevalue;
        let dateobj = null;

        const tmp = timevalue.format('HH:mm');
        dateobj = new Date(date + 'T' + tmp);
        //const time = Math.round(dateobj.getTime() / 1000)
        const time = Math.round(dateobj.valueOf() / 1000);
        data.ORDER_PICKUP_DATE_TIME = time;
      }

      const result = await API.graphql(
        graphqlOperation(CreateUserRestOrderWkflw, {
          createUSER_REST_ORDER_WKFLWInput: data,
        })
      );

      console.log(result);
      console.log(JSON.stringify(result));

      SetOrederStatus(true);
      setcartitems([]);
      //	setpaymentbit(true);

      SetpaymentLoader(false);
      // // //
      setcartitemsCookie('cartitems', '', { path: '/' });

      await rewardsDeductionMutation(subTotal, ORDER_ID, cartitems[0].restId);
      await rewardsMutation(subTotal, ORDER_ID, cartitems[0].restId);
      return result;
    } catch (err) {
      console.log('error: ', err);
      //	showValidationMsg("Error occured", 'error')
      SetpaymentLoader(false);
    }
  }

  // async function RewardPoints() {
  //   try {
  //     history.push({
  //       pathname: '/RewardPoint',
  //       // state: { email: values.email, password: values.password }
  //     });
  //   } catch (error) {
  //     console.log('error loading page: ', error);
  //   }
  // }

  // async function OrderHistory() {
  //   try {
  //     history.push({
  //       pathname: '/OrderHistory',
  //       // state: { email: values.email, password: values.password }
  //     });
  //   } catch (error) {
  //     console.log('error loading page: ', error);
  //   }
  // }

  // async function ChangePassword() {
  //   try {
  //     history.push({
  //       pathname: '/ChangePassword',
  //       // state: { email: values.email, password: values.password }
  //     });
  //   } catch (error) {
  //     console.log('error loading page: ', error);
  //   }
  // }

  const createNotication = async function (data) {
    const datamessage = data;
    ////
    console.log('dmmmmmmmmm', datamessage);
    const headers = {
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: 'NjM2OGMzZDgtYTZhNS00MjFkLWFmNDktMmNjYmQzMzUwNzRk',
    };
    console.log('started');
    ////
    try {
      const response = axios
        .post('https://onesignal.com/api/v1/notifications', datamessage, {
          headers,
        })
        .then(response => console.log(response));
      return response.data;
    } catch (error) {
      console.error(error);
      return error;
    }

    ////
    console.log('reached');
    const { id } = await createNotication(data);
    await viewNotifcation(id);
  };
  const viewNotifcation = async notificationId => {
    ////
    const path = `notifications/${notificationId}?	app_id="78ae918f-39b7-4bdd-8f7c-8c84f1b9da23"`;

    try {
      const response = axios.get(path).then(response => console.log(response));
      return response.data;
    } catch (error) {
      ////
      console.error(error);
      return error;
    }
  };

  async function callcardlesspayment() {
    try {
      SetpaymentLoader(true);

      const result = await callplaceordermutation();
      const ORDER_ID = result?.data.createREST_ORDER_GENERATE?.ORDER_ID;
      setorderid(ORDER_ID);
      ////
      const result_data = await callplaceordermutationw_workflow(ORDER_ID);
      // const [initialized, setInitialized] = useState(false);
      // OneSignal.init({ appId: '78ae918f-39b7-4bdd-8f7c-8c84f1b9da23'}).then(() => {
      //   // setInitialized(true);
      //   OneSignal.showSlidedownPrompt().then(() => {
      //      ////
      //   });
      // })

      let restOwnerContact = await API.graphql(
        graphqlOperation(getRestOwnerContact, {
          REST_ID: result?.data?.createREST_ORDER_GENERATE?.REST_ID,
        })
      );
      ////
      let playerID = '';
      if (restOwnerContact != '') {
        ////
        playerID = restOwnerContact.data.getREST_OWNER_CONTACT.PLAYER_ID;
        var message = {
          app_id: '78ae918f-39b7-4bdd-8f7c-8c84f1b9da23',
          contents: {
            en: `New ${result_data?.data?.createUSER_REST_ORDER_WKFLW?.ORDER_TYPE.toLowerCase()} order placed. Order: #${ORDER_ID}`,
          },
          //include_external_user_ids: [itemDetailsformutation.USER_EMAIL],
          include_player_ids: [playerID],
        };

        createNotication(message);
        ////
        console.log('---------------NOTIFICATION SENT--------------------');
      } else {
        ////
        alert('Please login from valid device, PlayerID not found');
        return;
      }

      console.log('abc');
      console.log(result_data);
    } catch (err) {
      ////
      console.log(err);
    }
  }

  const useOptions = () => {
    const fontSize = '15px';
    const options = useMemo(
      () => ({
        style: {
          base: {
            fontSize,
            color: '#424770',
            letterSpacing: '0.025em',
            fontFamily: 'Source Code Pro, monospace',
            '::placeholder': {
              color: '#aab7c4',
            },
          },
          invalid: {
            color: '#9e2146',
          },
        },
      }),
      [fontSize]
    );

    return options;
  };
  // function Sum(){
  // 	const s=(subTotal  + (taxFactor * (subTotal - discountTotal)) - discountTotal).toFixed(2) - ((rewardPoint==null && checkedItem==false  ? 0 : 2)) ;
  // 	console.log(s);
  // 	return s;
  // 	const a= 20;
  // }

  const CheckoutForm = () => {
    // const stripe = useStripe();
    // const elements = useElements();

    const stripe = useStripe();
    const elements = useElements();
    const options = useOptions();

    const handleSubmit = async event => {
      event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      const element = elements.getElement(CardElement);
      if (element._empty) {
        SetcardValidation(true);
        return;
      }
      //	SetcardpaymentLoader(true);
      // const { error, paymentMethod } = await stripe.createPaymentMethod({
      // 	type: "card",
      // 	card: element,
      // });

      // if (!error) {

      //	console.log("token generated!", paymentMethod);

      let ORDER_ID = null;
      let ORDER_PAYMENT_ID = null;
      //SetcardValidation(false);

      const result = await callplaceordermutation();
      ORDER_ID = result?.data.createREST_ORDER_GENERATE?.ORDER_ID;

      // Backend API
      try {
        //const { id } = paymentMethod;
        ORDER_PAYMENT_ID = 10;
        const tmp =
          Math.round(
            (subTotal +
              taxFactor * (subTotal - discountTotal) -
              discountTotal +
              Number.EPSILON) *
            100
          ) / 100;
        const amt = Math.round(tmp * 100);

        const response = await API.graphql(
          graphqlOperation(CreatePaymentIntent, { amount: amt })
        );

        // const response = await axios.post(
        // 	"http://localhost:8080/stripe/charge",
        // 	{
        // 		amount: amt,
        // 		id: id,
        // 	}s
        // );

        const clientSecret = response?.data?.createPaymentIntent.clientSecret;

        console.log(clientSecret);

        const payload = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: element,
            billing_details: {
              name: 'Jenny Rosen',
            },
          },
        });

        if (payload.error) {
          console.log(payload.error);
        } else {
          try {
            SetpaymentLoader(true);
            SetcardValidation(false);
            setorderid(ORDER_ID);
            const data = {
              REST_ID: cartitems[0].restId,
              CART_ID: cartitems[0].cartID,
              ORDER_ID: ORDER_ID,
              ORDER_PAYMENT_ID: ORDER_PAYMENT_ID,
              PAYMENT_RETURN_OBJ: ORDER_PAYMENT_ID,
              //	PAYMENT_RETURN_OBJ:JSON.stringify(response.data.resobj)
            };
            //var a=JSON.stringify(response.data.resobj);
            const result = await API.graphql(
              graphqlOperation(CreateUserPaymentDetails, {
                createUSER_PAYMENT_DETAILSInput: data,
              })
            );

            console.log(result);
            console.log(JSON.stringify(result));
            //		console.log(response.data.resobj)
            const result_data = await callplaceordermutationw_workflow(
              ORDER_ID
            );
            console.log(result_data);

            SetpaymentLoader(false);
            let restOwnerContact = await API.graphql(
              graphqlOperation(getRestOwnerContact, {
                REST_ID: result?.data?.createREST_ORDER_GENERATE?.REST_ID,
              })
            );
            ////
            let playerID = '';
            if (restOwnerContact != '') {
              ////
              playerID = restOwnerContact.data.getREST_OWNER_CONTACT.PLAYER_ID;
              var message = {
                app_id: '78ae918f-39b7-4bdd-8f7c-8c84f1b9da23',
                contents: {
                  en: `New ${result_data?.data?.createUSER_REST_ORDER_WKFLW?.ORDER_TYPE.toLowerCase()} order placed. Order: #${ORDER_ID}`,
                },
                //include_external_user_ids: [itemDetailsformutation.USER_EMAIL],
                include_player_ids: [playerID],
              };

              createNotication(message);
              ////
              console.log(
                '---------------NOTIFICATION SENT--------------------'
              );
            } else {
              ////
              alert('Please login from valid device, PlayerID not found');
              return;
            }

            //	SetcardpaymentLoader(false)
          } catch (err) {
            console.log('error: ', err);
            //	showValidationMsg("Error occured", 'error')
          }
        }
      } catch (error) {
        console.log('CheckoutForm error ', error);
      }

      // }

      //  else {
      // 	console.log(error.message);
      // }
    };

    // reward mutation

    return (
      <Box pt="15px">
        {/* <form onSubmit={handleSubmit}>
					<label>
						Card number
						<CardNumberElement
							options={options}
							onReady={() => {
								console.log("CardNumberElement [ready]");
							}}
							onChange={event => {
								console.log("CardNumberElement [change]", event);
							}}
							onBlur={() => {
								console.log("CardNumberElement [blur]");
							}}
							onFocus={() => {
								console.log("CardNumberElement [focus]");
							}}
						/>
					</label>
					<label>
						Expiration date
						<CardExpiryElement
							options={options}
							onReady={() => {
								console.log("CardNumberElement [ready]");
							}}
							onChange={event => {
								console.log("CardNumberElement [change]", event);
							}}
							onBlur={() => {
								console.log("CardNumberElement [blur]");
							}}
							onFocus={() => {
								console.log("CardNumberElement [focus]");
							}}
						/>
					</label>
					<label>
						CVC
						<CardCvcElement
							options={options}
							onReady={() => {
								console.log("CardNumberElement [ready]");
							}}
							onChange={event => {
								console.log("CardNumberElement [change]", event);
							}}
							onBlur={() => {
								console.log("CardNumberElement [blur]");
							}}
							onFocus={() => {
								console.log("CardNumberElement [focus]");
							}}
						/>
					</label>
					 {/* <Button mt='10px' type="submit" disabled={!stripe}>
						Pay
					</Button>  */}
        {/* <Box>
					
						<Button  mt='10px' type="submit" disabled={!stripe}>
							Pay now
						</Button>
						<Progress  d={cardpaymentLoader == true ? 'block' : 'none'} mt='10px' size="xs" isIndeterminate />

					</Box>

				</form>  */}

        <form onSubmit={handleSubmit}>
          <CardElement m="5px" />
          <Text
            pt="5px"
            fontSize="13px"
            color="red.500"
            d={cardValidation ? 'block' : 'none'}
          >
            Please enter the card details{' '}
          </Text>
          <Button
            d={paymentLoader == true ? 'none' : 'block'}
            className="primary-btn"
            w="100%"
            mt="15px"
            type="submit"
          >
            Pay Now
          </Button>
        </form>
      </Box>
    );
  };

  // async function getrewardamount() {
  //   try {
  //     const result = await API.graphql(
  //       graphqlOperation(GetRestRewardPct, { REST_ID: cartitems[0]?.restId })
  //     );
  //     setRewardPercentage(result.data.getREST_REWARD_PCT.REWARD_PCT);

  //     const result1 = await API.graphql(
  //       graphqlOperation(GetUserRestRewardBal, { REST_ID: cartitems[0]?.restId })
  //     );
  //     setRewardPoint(result1.data.getUSER_REST_REWARD_BAL.REWARD_BAL);
  //     //setRewardPoint(2);
  //     //	ORDER_ID = result?.data.createREST_ORDER_GENERATE?.ORDER_ID
  //    const a = 10;
  //   } catch (err) {
  //     console.log('error: ', err);
  //     //	showValidationMsg("Error occured", 'error')
  //   }
  // }
  const stripePromise = loadStripe('pk_live_51JO84wKELt63NG6wxTfSP9JzalNmGPzig80XqrFwWKfOvVrQ0o4Ljoi7gX1MZ75CcPZVfDMSQ97axBROUnjQCiY100MQc4jVaW');

  return (
    <Box bg="#f9f9f9">
      {isLoading ? (
        <Box className="orderSpinbox">
          {' '}
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Box>
      ) : (
        <Box bg="#f9f9f9">
          <Helmet>
            <title>Squash | Order</title>
          </Helmet>

          <Flex
            bg="#fff"
            borderBottom="1px solid #e6e6e6"
            position="absolute"
            top="0"
            left="0"
            right="0"
            margin="auto"
            zIndex="1000"
            p="15px"
            justifyContent="space-between"
            alignItems="center"
            direction="row"
          >
            <Header isAdmin={false} toggle={toggle} />
            {/* <Box alignItems="center" flex="1" d="flex">
              <Image maxWidth={{ base: '100px', md: '130px' }} src={Logo} />
              <Link>
                <HamburgerIcon
                  onClick={() => {
                    toggle();
                  }}
                  fontSize="22px"
                  ml="20px"
                />
              </Link> */}
            {/* <RestaurantSelectPopup fromlogin={false} resData={JSON.parse(localStorage.getItem('RestaurantNames'))} open={isOpenselectModal} modalClose={onCloseselectModal} /> */}
            <DeleteAlertDialog
              deletefromcart={deletefromcart}
              onClose={alertonClose}
              isOpen={alertisOpen}
            />
            <LoginPopup
              loginhandle={loginhandle}
              open={LoginisOpen}
              close={LoginonClose}
            />

            <RestaurantDetailsPopup
              restaurantInfo={restaurantInfo}
              open={isOpenDetails}
              close={onCloseDetails}
              savePopup={savePopup}
            />

            {/* <Box d={{ base: 'none', md: 'flex' }} className="restopname">
                
              </Box>
            </Box> */}

            <Box d={isLogin ? 'none' : 'block'} alignItems="center" mr="15px">
              <Link cursor="pointer">
                <FontAwesomeIcon
                  onClick={() => {
                    LoginonOpen();
                  }}
                  fontSize="22px"
                  icon={faSignInAlt}
                />
              </Link>
            </Box>
            {/* <Box d={isLogin ? 'flex' : 'none'}> */}
            {/* <Box>
                {' '}
                Hi, <Box className="headerusername">{'  ' + name}</Box>
              </Box> */}
            {/* <Menu>
                <MenuButton
                  w="auto"
                  minWidth={{ base: '30px', md: '34px' }}
                  h={{ base: '30px', md: '34px' }}
                  // h='auto'
                  ml="15px"
                  p="0px"
                  background="#001d56"
                  color="#ffffff"
                  as={IconButton}
                  aria-label="Options"
                  icon={<FontAwesomeIcon fontSize="22px" icon={faUser} />}
                  variant="outline"
                />
                <MenuList className="edit-profile-dropdown">
                  <MenuItem icon={<EditIcon />}>Edit Profile</MenuItem>
                  <MenuItem icon={<SettingsIcon />}>Account settings</MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon fontSize="22px" icon={faHeart} />}
                  >
                    Favourites
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon fontSize="22px" icon={faHistory} />}
                    onClick={() => OrderHistory()}
                  >
                    Order History
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon fontSize="22px" icon={faAward} />}
                    onClick={() => RewardPoints()}
                  >
                    Rewards
                  </MenuItem>
                  <MenuItem icon={<SettingsIcon />}>
                    Notification settings
                  </MenuItem>
                  <MenuItem
                    icon={<FontAwesomeIcon fontSize="22px" icon={faKey} />}
                    onClick={() => ChangePassword()}
                  >
                    Change Password
                  </MenuItem>

                  <MenuItem
                    icon={
                      <FontAwesomeIcon fontSize="22px" icon={faSignOutAlt} />
                    }
                    onClick={() => signOut()}
                  >
                    Logout
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box> */}
          </Flex>

          <Box
            pt="70px"
            position="relative"
            h="100vh"
            overflow="hidden"
            d="flex"
          >
            <Box
              zIndex="9"
              bg="#fff"
              position="absolute"
              top="0"
              pt="100px"
              left="0"
              h="100%"
              w={togglebit ? '0px' : '200px'}
              display={togglebit ? 'none' : 'block'}
            >
              <UserSidebar />
            </Box>
            <Box
              //  ml={
              //    togglebit ? '0px' : { base: '0', sm: '0', md: '0', lg: '200px' }
              // }
              w="100%"
              p={{ base: '8px', md: '20px' }}
              overflow="auto"
            >
              <Box d="flex" className="menuordermain">
                <Box className="menuorderleftbox">
                  <Box className="card">
                    <Box d="flex" alignItems="center" className='user-title-mobile-style' mb={3}>
                      <Text className="user-title">{item?.REST_NAME}</Text>
                      <Link
                        onClick={() => onOpenDetails()}
                        ml={2}
                        mt={1}
                        fontSize="20px"
                      >
                        <FontAwesomeIcon icon={faInfoCircle} color="#85BAFF" />
                        {/* <EditIcon
                        
                    /> */}
                      </Link>
                    </Box>

                    <Box
                      p={{ base: '0px', md: '0px' }}
                      className="card-content table-padding"
                    >
                      <Box className="scrollable tabnonscrolable m-table">
                        <Tabs
                          className="custom-tab-wrapper"
                          onChange={index => {

                            SetTabIndex(index);
                            console.log('---------------', item.relatedMenuGroups)
                            console.log('---------------', item.relatedMenuGroups)
                            console.log(
                              item.relatedMenuGroups.filter(
                                obj => obj.MENU_NAME == itemMenu[index]
                              )[0]?.MENU_GR_NAME
                            );

                            SetSubMenu(
                              item.relatedMenuGroups.filter(
                                obj => obj.MENU_NAME == itemMenu[index]
                              )[0]?.MENU_GR_NAME
                            );
                            setcurrentmenu(
                              item.relatedMenuGroups.filter(
                                obj => obj.MENU_NAME == itemMenu[index]
                              )[0]?.MENU_GR_NAME
                            );
                          }}
                        >
                          <TabList className="custom-tabs custom-tabs-mobile-style">
                            {/* <Tab
                              p={{ base: '9px', md: '20px' }}
                              className="custom-tab-button"
                            >
                              {' '}
                              
                              Today's Specials{' '}
                            </Tab> */}

                            {itemMenu?.map((item, index) =>
                              (item != 'Special Menu' && item != 'Special Event' && item != 'Special Events') ? (
                                <Tab
                                  p={{ base: '9px', md: '20px' }}
                                  className="custom-tab-button"
                                  key={index}
                                >
                                  {' '}
                                  {/* <Box className="ctabicon">
                                  <FontAwesomeIcon icon={faConciergeBell} />
                                </Box> */}
                                  {item.toUpperCase()}{' '}
                                </Tab>
                              ) : (
                                ''
                              )
                            )}
                          </TabList>

                          <Box className="innermenumobilemain">
                            <Show below='sm'>
                              <Box>
                                <Select onChange={e => onSelectMenuItemFromDropDown(e.target.value)}
                                >

                                  {specialList?.length == 0 ? (
                                    ''
                                  ) : ( 
                                        <option value='special'>Today's Special - {specialLength}</option>
                                  )}

                                  
                                  {item?.relatedMenuGroups
                                    ? item.relatedMenuGroups
                                      ?.filter(
                                        obj =>
                                          obj.MENU_NAME == itemMenu[tabIndex]
                                      )
                                      .map((data, i) => (
                                        <option value={data.MENU_GR_NAME} id={data.MENU_GR_NAME}>
                                          {data.MENU_GR_NAME} - {data.relatedMenuItems.length}
                                        </option>
                                  )) : null }

                                                                  
                                  {upcomingSpecialLength == 0 ? (
                                    ''
                                  ) : (
                                        <option value='upcomingspecial'>Upcoming Specials - {upcomingSpecialLength}</option>
                                  )}

                                </Select>
                              </Box>
                            </Show>

                            <Show above='sm'>
                              <Box className="innermenumobile">
                                <List
                                  id="scroll-div-sidebar"
                                  className="inner-menu"
                                >
                                  {specialList?.length == 0 ? (
                                    ''
                                  ) : (
                                    <ListItem
                                      className={
                                        currentmenu == 'special'
                                          ? 'active inner-menu-item '
                                          : 'inner-menu-item '
                                      }
                                    >
                                      <Link
                                        style={{ textDecoration: 'none' }}
                                        onClick={() => {

                                          SetSubMenu(specialList.MENU_GR_NAME);
                                          setcurrentmenu('special');

                                          var list =
                                            document.querySelector(
                                              "div[id='0'] ul "
                                            )?.children;
                                          var flag = true;
                                          for (
                                            var i = 0, len = list?.length;
                                            i < len;
                                            i++
                                          ) {
                                            const t = list[i].offsetTop;
                                            if (
                                              list[i].attributes['id']
                                                ?.nodeValue ==
                                              specialList?.MENU_GR_NAME &&
                                              flag
                                            ) {
                                              flag = false;
                                              //  const obj=list[i].offsetTop;
                                              //  const submenu=list[i].attributes['id'].nodeValue;
                                              //  const a=10
                                              document.getElementById(
                                                'scroll-div'
                                              ).scrollTop =
                                                list[i].offsetTop - 278;
                                            }
                                          }
                                          //	document.getElementById("scroll-div").scrollTop = 500
                                        }}
                                      >
                                        <span>
                                          <FontAwesomeIcon icon={faSwatchbook} />
                                        </span>
                                        <Box>
                                          <Text fontSize="14px" pl="10px">
                                            Today's Specials
                                          </Text>
                                          <Text fontSize="xs" pl="10px">
                                            No of item : {specialLength}{' '}
                                          </Text>
                                        </Box>
                                      </Link>
                                    </ListItem>
                                  )}

                                  {item?.relatedMenuGroups
                                    ? item.relatedMenuGroups
                                      ?.filter(
                                        obj =>
                                          obj.MENU_NAME == itemMenu[tabIndex]
                                        //		obj.MENU_GR_ID == "MG1"
                                      )
                                      .map((data, i) => (
                                        <ListItem
                                          id={data.MENU_GR_NAME}
                                          className={
                                            currentmenu == data.MENU_GR_NAME
                                              ? 'active inner-menu-item '
                                              : 'inner-menu-item '
                                          }
                                        >
                                          {/* <Box>
                                <FontAwesomeIcon icon={faHamburger} />
                              </Box> */}

                                          <Link
                                            style={{ textDecoration: 'none' }}
                                            onClick={() => {

                                              SetSubMenu(data.MENU_GR_NAME);
                                              setcurrentmenu(data.MENU_GR_NAME);

                                              var list =
                                                document.querySelector(
                                                  "div[id='0'] ul "
                                                )?.children;
                                              var flag = true;
                                              for (
                                                var i = 0, len = list?.length;
                                                i < len;
                                                i++
                                              ) {
                                                const t = list[i].offsetTop;
                                                if (
                                                  list[i].attributes['id']
                                                    ?.nodeValue ==
                                                  data.MENU_GR_NAME &&
                                                  flag
                                                ) {
                                                  flag = false;
                                                  //  const obj=list[i].offsetTop;
                                                  //  const submenu=list[i].attributes['id'].nodeValue;
                                                  //  const a=10
                                                  document.getElementById(
                                                    'scroll-div'
                                                  ).scrollTop =
                                                    list[i].offsetTop - 208;
                                                }
                                              }
                                              //	document.getElementById("scroll-div").scrollTop = 500
                                            }}
                                          >
                                            <span>
                                              <FontAwesomeIcon
                                                icon={faSwatchbook}
                                              />
                                            </span>
                                            <Box>
                                              {' '}
                                              <Text fontSize="14px" pl="10px">
                                                {' '}
                                                {data.MENU_GR_NAME}{' '}
                                              </Text>
                                              <Text fontSize="xs" pl="10px">
                                                No of item :{' '}
                                                {data.relatedMenuItems.length}{' '}
                                              </Text>
                                            </Box>
                                          </Link>
                                        </ListItem>
                                      ))
                                    : null}

                                  {upcomingSpecialLength == 0 ? (
                                    ''
                                  ) : (
                                    <ListItem
                                      className={
                                        currentmenu == 'upcomingspecial'
                                          ? 'active inner-menu-item '
                                          : 'inner-menu-item '
                                      }
                                    >
                                      <Link
                                        style={{ textDecoration: 'none' }}
                                        onClick={() => {


                                          setcurrentmenu('upcomingspecial');
                                        }}
                                      >
                                        <span>
                                          <FontAwesomeIcon icon={faSwatchbook} />
                                        </span>
                                        <Box>
                                          <Text fontSize="14px" pl="10px">
                                            Upcoming Specials
                                          </Text>
                                          <Text fontSize="xs" pl="10px">
                                            No of item : {upcomingSpecialLength}{' '}
                                          </Text>
                                        </Box>
                                      </Link>
                                    </ListItem>
                                  )}
                                </List>
                              </Box>
                            </Show>

                            <Box className="innermenumobile">
                              <TabPanels
                                id="scroll-div"
                                // onScroll={handleScroll}
                                className="inner-content"
                              /*	w={{base:'100%',md:` calc(100% - 225px) !important`}}
                              h={{base:`calc(70vh - 270px)` ,md:`calc(100vh - 320px)`}} */
                              >
                                {itemMenu?.map((ob, index) => (
                                  <TabPanel p={4} key={index}>
                                    <Box id={index}>
                                      <List>
                                        <Box>
                                          {
                                            isCartLoading &&
                                            <Box className="rspinbox">
                                              {' '}
                                              <Spinner
                                                thickness="4px"
                                                speed="0.65s"
                                                emptyColor="gray.200"
                                                color="blue.500"
                                                size="xl"
                                              />
                                            </Box>
                                          }

                                        </Box>
                                        {
                                          (item?.relatedMenuGroups && currentmenu !== 'special' && currentmenu !== 'upcomingspecial')
                                            ? //		tmpArry.findIndex((obj) => obj.CH_ID === rowID)
                                            item.relatedMenuGroups
                                              ?.filter(
                                                obj =>
                                                  obj.MENU_NAME ==
                                                  itemMenu[tabIndex]
                                                  && obj.MENU_GR_NAME == subMenu
                                              )
                                              .map((data, i) =>
                                                //console.log(data)
                                                data?.relatedMenuItems
                                                  ? data.relatedMenuItems?.map(
                                                    (val, index) => (
                                                      <Box
                                                        id={data.MENU_GR_NAME}
                                                        className="inner-content-div"
                                                        d="flex"
                                                        cursor="pointer"
                                                        onClick={() => {
                                                          handlecartpop(val);
                                                        }}
                                                      >
                                                        {isCartLoading ? (
                                                          <Box className="rspinbox">
                                                            {' '}
                                                            <Spinner
                                                              thickness="4px"
                                                              speed="0.65s"
                                                              emptyColor="gray.200"
                                                              color="blue.500"
                                                              size="xl"
                                                            />
                                                          </Box>
                                                        ) : (
                                                          ''
                                                        )}
                                                        <Box
                                                          onClick={() => {
                                                            // setEditObject(val)
                                                            // onOpen();

                                                          }}
                                                          className="fmenubox1"
                                                          mr={3}
                                                        >
                                                          {' '}
                                                          <Box className="fmenuicon">
                                                            {/* <FontAwesomeIcon
                                                                icon={
                                                                  faConciergeBell
                                                                }
                                                              /> */}

                                                            <Image
                                                              src={
                                                                val?.MENU_IT_PIC_LOCATION ==
                                                                  '' ||
                                                                  val?.MENU_IT_PIC_LOCATION ==
                                                                  null
                                                                  ? require('../../assets/images/cuisineImagesNew/DemoDish.jpg')
                                                                    .default
                                                                  : val?.MENU_IT_PIC_LOCATION
                                                              }
                                                              alt="Segun Adebayo"
                                                            />
                                                          </Box>
                                                        </Box>
                                                        <Box
                                                          // onClick={() => {

                                                          // 	setEditObject(val)
                                                          // 	onOpen();
                                                          // }}
                                                          w="50%"
                                                        >
                                                          <Text>
                                                            {' '}
                                                            {val.MENU_IT_NAME}
                                                          </Text>
                                                        </Box>
                                                        <Box
                                                          w="50%"
                                                          d={{
                                                            base: 'none',
                                                            sm: 'none',
                                                            md: 'none',
                                                            lg: 'none',
                                                            xl: 'block',
                                                          }}
                                                        // onClick={() => {

                                                        // 	setEditObject(val)
                                                        // 	onOpen();
                                                        // }}
                                                        >
                                                          <Text>
                                                            {' '}
                                                            {val.MENU_IT_DESC}
                                                          </Text>
                                                        </Box>
                                                        <Box
                                                          w={{
                                                            base: '30%',
                                                            md: '20%',
                                                          }}
                                                          onClick={() => {
                                                            ////
                                                            setInEditMode({
                                                              status: true,
                                                              rowKey:
                                                                val.MENU_IT_ID,
                                                            });
                                                            setPrice(
                                                              val.MENU_IT_PRICE
                                                            );
                                                          }}
                                                          textAlign="right"
                                                        >
                                                          <Text fontWeight="700">
                                                            $
                                                            {val.MENU_IT_PRICE.toFixed(
                                                              2
                                                            )}
                                                          </Text>
                                                        </Box>
                                                      </Box>
                                                    )
                                                  )
                                                  : null
                                              )
                                            : null
                                        }
                                        {
                                          (specialList.length !== 0 && currentmenu === 'special')
                                            ? specialList.map((obj, ind) => (
                                              <Box
                                                className="inner-content-div"
                                                d="flex"
                                                cursor="pointer"
                                                onClick={() => {
                                                  ////
                                                  handlecartpop(obj);
                                                }}
                                              >
                                                <Box
                                                  // onClick={() => {

                                                  // 	setEditObject(val)
                                                  // 	onOpen();
                                                  // }}
                                                  className="fmenubox1"
                                                  mr={3}
                                                >
                                                  {' '}
                                                  <Box className="fmenuicon">
                                                    {/* <FontAwesomeIcon
                                                        icon={faConciergeBell}
                                                      /> */}
                                                    <Image
                                                      src={
                                                        obj.MENU_IT_PIC_LOCATION ==
                                                          '' ||
                                                          obj.MENU_IT_PIC_LOCATION ==
                                                          null
                                                          ? require('../../assets/images/cuisineImagesNew/DemoRes.jpg')
                                                            .default
                                                          : obj.MENU_IT_PIC_LOCATION
                                                      }
                                                      alt="Segun Adebayo"
                                                    />
                                                  </Box>
                                                </Box>

                                                <Box
                                                  onClick={() => {
                                                    ////
                                                    console.log(obj);
                                                  }}
                                                  // 	setEditObject(val)
                                                  // 	onOpen();
                                                  // }}
                                                  w="50%"
                                                >
                                                  <Text>
                                                    {' '}
                                                    {obj.MENU_IT_NAME}
                                                  </Text>
                                                </Box>
                                                <Box
                                                  w="50%"
                                                  d={{
                                                    base: 'none',
                                                    sm: 'none',
                                                    md: 'none',
                                                    lg: 'none',
                                                    xl: 'block',
                                                  }}
                                                // onClick={() => {

                                                // 	setEditObject(val)
                                                // 	onOpen();
                                                // }}
                                                >
                                                  <Text>
                                                    {' '}
                                                    {obj.MENU_IT_DESC}
                                                  </Text>
                                                </Box>

                                                <Box
                                                  w={{
                                                    base: '30%',
                                                    md: '20%',
                                                  }}
                                                  onClick={() => {
                                                    ////

                                                    setInEditMode({
                                                      status: true,
                                                      rowKey: obj.MENU_IT_ID,
                                                    });
                                                    setPrice(
                                                      obj.MENU_IT_PRICE
                                                    );
                                                  }}
                                                  textAlign="right"
                                                >
                                                  <Text fontWeight="700">
                                                    $
                                                    {obj.MENU_IT_PRICE.toFixed(
                                                      2
                                                    )}
                                                  </Text>
                                                </Box>
                                              </Box>
                                            ))
                                            : ''
                                        }
                                        {
                                          (upcomingSpecialList.length !== 0 && currentmenu === 'upcomingspecial')
                                            ? upcomingSpecialList.map(
                                              (obj, ind) => (
                                                <Box
                                                  className="inner-content-div"
                                                  d="flex"
                                                  cursor="pointer"
                                                  onClick={() => {
                                                    ////
                                                    handlecartpop(obj);
                                                  }}
                                                >
                                                  <Box
                                                    // onClick={() => {

                                                    // 	setEditObject(val)
                                                    // 	onOpen();
                                                    // }}
                                                    className="fmenubox1"
                                                    mr={3}
                                                  >
                                                    {' '}
                                                    <Box className="fmenuicon">
                                                      {/* <FontAwesomeIcon
                                                          icon={faConciergeBell}
                                                        /> */}
                                                      <Image
                                                        src={
                                                          obj.MENU_IT_PIC_LOCATION ==
                                                            '' ||
                                                            obj.MENU_IT_PIC_LOCATION ==
                                                            null
                                                            ? require('../../assets/images/cuisineImagesNew/DemoRes.jpg')
                                                              .default
                                                            : obj.MENU_IT_PIC_LOCATION
                                                        }
                                                        alt="Segun Adebayo"
                                                      />
                                                    </Box>
                                                  </Box>

                                                  <Box
                                                    onClick={() => {
                                                      ////
                                                      console.log(obj);
                                                    }}
                                                    // 	setEditObject(val)
                                                    // 	onOpen();
                                                    // }}
                                                    w="50%"
                                                  >
                                                    <Text>
                                                      {' '}
                                                      {obj.MENU_IT_NAME}
                                                    </Text>
                                                  </Box>
                                                  <Box
                                                    w="50%"
                                                    d={{
                                                      base: 'none',
                                                      sm: 'none',
                                                      md: 'none',
                                                      lg: 'none',
                                                      xl: 'block',
                                                    }}
                                                  // onClick={() => {

                                                  // 	setEditObject(val)
                                                  // 	onOpen();
                                                  // }}
                                                  >
                                                    <Text>
                                                      {' '}
                                                      {obj.MENU_IT_DESC}
                                                    </Text>
                                                  </Box>

                                                  <Box
                                                    w={{
                                                      base: '30%',
                                                      md: '20%',
                                                    }}
                                                    onClick={() => {
                                                      ////

                                                      setInEditMode({
                                                        status: true,
                                                        rowKey:
                                                          obj.MENU_IT_ID,
                                                      });
                                                      setPrice(
                                                        obj.MENU_IT_PRICE
                                                      );
                                                    }}
                                                    textAlign="right"
                                                  >
                                                    <Text fontWeight="700">
                                                      $
                                                      {obj.MENU_IT_PRICE.toFixed(
                                                        2
                                                      )}
                                                    </Text>
                                                  </Box>
                                                </Box>
                                              ))
                                            : ''
                                        }
                                        <Box>
                                          {
                                            isCartLoading &&
                                            <Box className="rspinbox">
                                              {' '}
                                              <Spinner
                                                thickness="4px"
                                                speed="0.65s"
                                                emptyColor="gray.200"
                                                color="blue.500"
                                                size="xl"
                                              />
                                            </Box>
                                          }
                                        </Box>
                                      </List>
                                    </Box>
                                  </TabPanel>
                                ))}
                              </TabPanels>
                            </Box>
                          </Box>
                        </Tabs>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {isLoading ? (
                  ''
                ) : (
                  <Box className="orderbox">
                      <Show above='sm'>
                     <Box className="card">
                      <Text className="user-title">
                        {isCheckout ? '' : 'Your Order'}
                      </Text>

                      {cartitems?.length > 0 || orederStatus == true ? (
                        <Box>
                          {orederStatus == false ? (
                            <Box>
                              <Box d={isCheckout ? 'none' : 'block'}>
                                <Box className="orderboxin">
                                  <Box
                                    className="order-tab"
                                    justifyContent="space-between"
                                    w="100%"
                                    flex="1"
                                    d="flex"
                                  >
                                    <Link
                                      textDecoration="none"
                                      w="100%"
                                      onClick={() => {
                                        setpickupbit(true);
                                        setdatevalidation(false);
                                      }}
                                    >
                                      {' '}
                                      <Box
                                        textAlign="center"
                                        borderRadius="25px 0 0 25px"
                                        backgroundColor={
                                          pickupbit == true ? '#1255ac' : ''
                                        }
                                        color={
                                          pickupbit == true
                                            ? '#ffffff'
                                            : '#1255ac'
                                        }
                                      >
                                        Pick-Up
                                      </Box>
                                    </Link>
                                    <Link
                                      textDecoration="none"
                                      w="100%"
                                      onClick={() => {
                                        setpickupbit(false);
                                        setdatevalidation(false);
                                      }}
                                    >
                                      {' '}
                                      <Box
                                        textAlign="center"
                                        borderRadius="0 25px 25px 0"
                                        backgroundColor={
                                          pickupbit == false ? '#1255ac' : ''
                                        }
                                        color={
                                          pickupbit == false
                                            ? '#ffffff'
                                            : '#1255ac'
                                        }
                                      >
                                        Dine-In
                                      </Box>
                                    </Link>
                                  </Box>
                                  {pickupbit == true ? (
                                    <RadioGroup
                                      onChange={setRadioValue}
                                      value={Radiovalue}
                                      m="px"
                                      defaultValue="2"
                                    >
                                      <Stack spacing={5} direction="row">
                                        <Box w="100%" flex="1" d="flex">
                                          <Radio
                                            w="50%"
                                            colorScheme="red"
                                            value="1"
                                          >
                                            ASAP
                                          </Radio>
                                          <Radio colorScheme="green" value="2">
                                            Schedule
                                          </Radio>
                                        </Box>
                                      </Stack>
                                    </RadioGroup>
                                  ) : (
                                    <Box>
                                      <Box className="order-date-time-dinein">
                                        <Box
                                          d="flex"
                                          className="order-date-time"
                                        >
                                          <Box>
                                            <Text>Date</Text>
                                            <Input
                                              type="date"
                                              //value={((specialDateValue) != moment(new Date()).format("YYYY-MM-DD")) ? specialDateValue : datevalue}
                                              value={
                                                Json_specialitem == '' &&
                                                  cartNumber == 0
                                                  ? datevalue
                                                  : specialDateValue
                                              }
                                              onChange={handleChangedate}
                                              size="sm"
                                            />
                                          </Box>
                                          <Box ml={3}>
                                            <Text>Time</Text>
                                            <TimePicker
                                              //value={specialTimevalue != moment('00:00', 'HH:mm') ? specialTimevalue :  timevalue}
                                              // value={
                                              //   Json_specialitem == '' &&
                                              //   cartNumber == 0
                                              //     ? timevalue
                                              //     : specialTimevalue
                                              // }
                                              value={
                                                Json_specialitem == '' &&
                                                  cartNumber == 0
                                                  ? timevalue
                                                  : specialTimevalue
                                              }
                                              onChange={value => {
                                                const aa =
                                                  value.format('hh:mm A');
                                                ////

                                                console.log(aa);
                                                settimeValue(
                                                  moment(aa, 'HH:mm A')
                                                );
                                                setSpecialTimevalue(
                                                  moment(aa, 'HH:mm A')
                                                );
                                                // //
                                              }}
                                              showSecond={false}
                                              use12Hours
                                              allowEmpty
                                              format="hh:mm A"
                                            />
                                          </Box>
                                        </Box>

                                        <Box
                                          d="flex"
                                          justifyContent="space-between"
                                          alignItems="center"
                                          className="party-size"
                                          mb={3}
                                        >
                                          <Text>Party Size</Text>
                                          <Input
                                            w="50%"
                                            type="number"
                                            //value={specialPartysize != 1 ? specialPartysize : partysize}
                                            value={
                                              Json_specialitem == '' &&
                                                cartNumber == 0
                                                ? partysize
                                                : specialPartysize
                                            }
                                            onChange={handleChangepartysize}
                                            size="sm"
                                            min="1"
                                          />
                                        </Box>
                                      </Box>
                                      <Text
                                        fontSize="13px"
                                        d={
                                          datevalidation == true &&
                                            pickupbit == false
                                            ? 'block'
                                            : 'none'
                                        }
                                        color="red"
                                      >
                                        Please enter the valid dine-in details
                                      </Text>
                                    </Box>
                                  )}

                                  {Radiovalue == '2' && pickupbit == true ? (
                                    <Box>
                                      <Box
                                        d="flex"
                                        mb={3}
                                        className="order-date-time"
                                      >
                                        <Input
                                          type="date"
                                          value={datevalue}
                                          onChange={handleChangedate}
                                          size="sm"
                                        />
                                        <TimePicker
                                          value={timevalue}
                                          onChange={value => {
                                            const aa = value.format('hh:mm A');

                                            console.log(aa);
                                            settimeValue(moment(aa, 'HH:mm A'));
                                          }}
                                          showSecond={false}
                                          use12Hours
                                          allowEmpty
                                          format="hh:mm A"
                                        />
                                      </Box>
                                      <Text
                                        fontSize="13px"
                                        d={
                                          datevalidation == true &&
                                            Radiovalue == '2' &&
                                            pickupbit == true
                                            ? 'block'
                                            : 'none'
                                        }
                                        color="red"
                                      >
                                        Please enter the valid date and time for
                                        pickup
                                      </Text>
                                    </Box>
                                  ) : (
                                    ''
                                  )}
                                  <Box d="flex">
                                    <Input
                                      mb={2}
                                      name="phonenumber"
                                      placeholder="phone"
                                      value={phoneNumber}
                                      editable={false}
                                    />
                                    {/* <Button ml={3} mt={3} icon={<FontAwesomeIcon fontSize='22px' icon={faPhone} />}/> */}
                                  </Box>

                                  <OrderedList>
                                    {cartitems.map(item => (
                                      <ListItem>
                                        <Box
                                          d="flex"
                                          justifyContent="space-between"
                                          pr="5px"
                                        >
                                          <Box>
                                            <Text
                                              fontSize="15px"
                                              color="#3D3D3D"
                                              mv={2}
                                            >
                                              {item.menu.MENU_IT_NAME}
                                            </Text>
                                            <Text
                                              fontSize="16px"
                                              fontWeight="500"
                                            >
                                              {item.quantity + 'x$'}
                                              {item.totalprice}
                                            </Text>
                                          </Box>
                                          <Box
                                            d="flex"
                                            className="order-btns"
                                            alignContent="flex-start"
                                          >
                                            <Link
                                              d="flex"
                                              onClick={() => {
                                                //setcurrentcartitemID(item.cartID)
                                                const list = [...cartitems];

                                                const idx = list.findIndex(
                                                  x => x.index === item.index
                                                );
                                                const currentmenu =
                                                  list[idx]?.menu;
                                                seteditcartitem(list[idx]);
                                                setEditObject(currentmenu);
                                                setcurrentcartitemID(
                                                  item.index
                                                );
                                                const a = 10;

                                                onOpen();
                                              }}
                                            >
                                              <EditIcon
                                                color="#85BAFF"
                                              //onClick={() => onEdit({ id: obj.CH_ITEM_ID, currentPrice: obj.CH_PRICE, currentLabel: obj.CH_ITEM_DESC })}
                                              />
                                            </Link>
                                            <Box pl="10px">
                                              <Link
                                                pr="5px"
                                                d="flex"
                                                onClick={() => {
                                                  alertonOpen();

                                                  // const list=[...cartitems]
                                                  setcurrentcartitemID(
                                                    item.index
                                                  );
                                                  // const idx = list.findIndex(x => x.cartID === item.cartID);
                                                  // list.splice(idx, 1);
                                                  // setcartitems(list);
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={faTrashAlt}
                                                  color="#FF7373"
                                                />
                                              </Link>
                                            </Box>
                                          </Box>
                                        </Box>
                                        <Box w="95%">
                                          {item.customizationdata?.map(
                                            (obj, index) => (
                                              <Box display="inline">
                                                <Text
                                                  fontSize="13px"
                                                  display="inline"
                                                >
                                                  {obj.MUST_CHOOSE_FLAG
                                                    ? obj.CH_DESC +
                                                    '(' +
                                                    obj.CH_ITEM_DESC +
                                                    ') ' +
                                                    (index + 1 <
                                                      item.customizationdata
                                                        .length
                                                      ? ', '
                                                      : '')
                                                    : ''}
                                                </Text>

                                                <Text
                                                  fontSize="13px"
                                                  display="inline"
                                                >
                                                  {obj.checkdata
                                                    ? obj.CH_DESC + '('
                                                    : ''}

                                                  {obj.checkdata?.map(
                                                    (x, index) =>
                                                      x.CH_ITEM_DESC +
                                                      (obj.checkdata.length >
                                                        index + 1
                                                        ? ', '
                                                        : '')
                                                  )}
                                                  {obj.checkdata ? ')' : ''}
                                                </Text>
                                              </Box>
                                            )
                                          )}
                                        </Box>
                                      </ListItem>
                                    ))}
                                  </OrderedList>
                                </Box>
                                <hr className="user-divider"></hr>
                                <Box
                                  w="100%"
                                  d="flex"
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Text fontSize="18px" fontWeight="600">
                                    ${subTotal.toFixed(2)}
                                  </Text>
                                  <Button
                                    type="submit"
                                    className="primary-btn"
                                    ml={3}
                                    onClick={() => {
                                      //debugger;
                                      ManuData();
                                      functiononlinecoupons();
                                      setTotal(subTotal);
                                      const date =
                                        Json_specialitem == '' &&
                                          cartNumber == 0
                                          ? datevalue
                                          : specialDateValue;

                                      let dat = null;
                                      if (Json_specialitem != '') {
                                        const sp = JSON.parse(Json_specialitem);
                                        console.log(sp);
                                        ////
                                        if (
                                          (sp.DATE_TO_DATE >= date &&
                                            sp.DATE_FROM_DATE <= date) ||
                                          sp.DATE_TO_DATE == undefined
                                        ) {
                                          if (date != '') {
                                            //const tmp = timevalue.format('HH:mm');
                                            const tmp =
                                              Json_specialitem == ''
                                                ? timevalue.format('HH:mm')
                                                : specialTimevalue.format(
                                                  'HH:mm'
                                                );
                                            dat = new Date(date + 'T' + tmp);
                                            // if(dat<new Date())
                                            // {
                                            // 	setdatevalidation(true)

                                            // }
                                          }
                                          const todaydate = new Date();
                                          const b = timevalue.format('hh:mm A');

                                          if (date != null) {
                                            if (
                                              pickupbit == true &&
                                              Radiovalue == '2'
                                            ) {
                                              if (
                                                date == '' ||
                                                dat < todaydate
                                              ) {
                                                ////
                                                setdatevalidation(true);
                                              } else {
                                                cartSave(cartitems);
                                                setisCheckout(true);
                                              }
                                            } else if (pickupbit == false) {
                                              if (
                                                date == '' ||
                                                dat < new Date()
                                              ) {
                                                ////
                                                setdatevalidation(true);
                                              }
                                              // if (
                                              //   date == ''
                                              // ) {
                                              //   // //
                                              //   setdatevalidation(true);
                                              // }
                                              else {
                                                cartSave(cartitems);
                                                setisCheckout(true);
                                              }
                                            } else {
                                              cartSave(cartitems);
                                              setisCheckout(true);
                                            }
                                          } else {
                                          }
                                          // const sp = JSON.parse(Json_specialitem)
                                          // console.log(sp);
                                          // if(sp.DATE_TO_DATE>=date && sp.DATE_FROM_DATE<=date)
                                          // {
                                          //   alert("OK");
                                        } else {
                                          showValidationMsgSpcl(
                                            'Special Not Available',
                                            'error'
                                          );
                                        }
                                        // console.log(json_cartitems);
                                        // //
                                      } else {
                                        if (date != '') {
                                          const tmp = timevalue.format('HH:mm');

                                          dat = new Date(date + 'T' + tmp);
                                          // if(dat<new Date())
                                          // {
                                          // 	setdatevalidation(true)

                                          // }
                                        }
                                        const todaydate = new Date();
                                        const b = timevalue.format('hh:mm A');

                                        if (date != null) {
                                          if (
                                            pickupbit == true &&
                                            Radiovalue == '2'
                                          ) {
                                            if (date == '' || dat < todaydate) {
                                              //////
                                              setdatevalidation(true);
                                            } else {
                                              cartSave(cartitems);
                                              setisCheckout(true);
                                            }
                                          } else if (pickupbit == false) {
                                            if (
                                              date == '' ||
                                              dat < new Date()
                                            ) {
                                              ////
                                              setdatevalidation(true);
                                            } else {
                                              cartSave(cartitems);
                                              setisCheckout(true);
                                            }
                                          } else {
                                            cartSave(cartitems);
                                            setisCheckout(true);
                                          }
                                        } else {
                                        }
                                      }
                                    }}
                                  >
                                    Proceed to Checkout
                                  </Button>
                                </Box>
                                {/* <Box pr='10px' w='100%' d='flex' justifyContent='space-between' className="totalbox">

														   	<Text fontSize='15px' fontWeight='500'>Sub Total</Text>
														  	<Text fontSize='18px' fontWeight='bold'>${subTotal.toFixed(2)}</Text>
													  	  </Box> */}
                              </Box>

                              <Box d={isCheckout ? 'block' : 'none'}>
                                <Box className="orderboxin order-payment">
                                  <Box className="order-summery">
                                    <Text fontSize="14px" fontWeight="500">
                                      Order Summery :{' '}
                                      <Link>
                                        {' '}
                                        <EditIcon
                                          onClick={() => {
                                            setisCheckout(false);
                                            setdatevalidation(false);
                                          }}
                                        />
                                      </Link>
                                    </Text>
                                    <Box className="order-summery-inner">
                                      {cartitems.map(item => (
                                        <Box
                                          fontSize="13px"
                                          py="2px"
                                          d="flex"
                                          justifyContent="space-between"
                                        >
                                          {' '}
                                          {item.menu.MENU_IT_NAME}{' '}
                                          <span>{'  x ' + item.quantity}</span>
                                        </Box>
                                      ))}

                                      {/* <Text><span>rhyfueg rfyesdfsdgh</span><span>rhyfuegr fyegh</span><span>rhyfudsfegrfyegh</span></Text> */}
                                    </Box>

                                    {Radiovalue == '2' && pickupbit == true ? (
                                      <Text>
                                        Scheduled order at {item?.REST_NAME} on{' '}
                                        {Json_specialitem == '' &&
                                          cartNumber == 0
                                          ? datevalue
                                          : specialDateValue}{' '}
                                      </Text>
                                    ) : pickupbit == false ? (
                                      <Text>
                                        Dine-In at {item?.REST_NAME} on{' '}
                                        {Json_specialitem == '' &&
                                          cartNumber == 0
                                          ? datevalue
                                          : specialDateValue}{' '}
                                      </Text>
                                    ) : (
                                      <Text>
                                        Pickup ASAP at {item?.REST_NAME}
                                      </Text>
                                    )}
                                  </Box>

                                  <Box
                                    d="flex"
                                    className="order-coupen"
                                    justifyContent="space-between"
                                  >
                                    <Input
                                      value={code}
                                      type="text"
                                      onChange={handleChange}
                                      placeholder="APPLY CODE"
                                      isReadOnly={
                                        onlineCouponStatus ? true : false
                                      }
                                    //	size="sm"
                                    />
                                    <Button
                                      onClick={() => {
                                        CallDiscountAPI(value);
                                      }}
                                      bg="#3e88e8"
                                      color="#ffffff"
                                      disabled={
                                        onlineCouponStatus ? true : false
                                      }
                                    >
                                      Apply
                                    </Button>
                                  </Box>
                                  {onlineCouponStatus && (
                                    <Text
                                      fontSize="13px"
                                      pt-1
                                      color="green.500"
                                    >
                                      {onlinediscountAmnt > 0 && '$'}
                                      {onlinediscountAmnt > 0
                                        ? onlinediscountAmnt
                                        : percent}
                                      {onlinediscountAmnt === 0 && '%'} Discount
                                      applied successfully !!!
                                    </Text>
                                  )}
                                  <Text
                                    fontSize="13px"
                                    pt-1
                                    color={
                                      discountmessage.valid == true
                                        ? 'green.500'
                                        : 'red.500'
                                    }
                                  >
                                    {discountmessage.status == true
                                      ? discountmessage.message
                                      : ''}
                                  </Text>

                                  {/* <Box pt="5px" d="flex" style={{display:rewardPoint == null ?'block':'none'}}> */}
                                  {rewardPoint != 0 ? (
                                    <Box>
                                      <Box
                                        pt="10px"
                                        d="flex"
                                        className="reward-check-box"
                                      >
                                        <Checkbox
                                          isChecked={checkedItem}
                                          onChange={e =>
                                            setCheckedItem(e.target.checked)
                                          }
                                        ></Checkbox>
                                        <Text
                                          pl="2"
                                          fontSize="13px"
                                          color="#02ae35"
                                        >
                                          Apply Rewards
                                        </Text>
                                      </Box>
                                      <Box pt="7px" d="flex">
                                        <Text fontSize="12px">
                                          Reward balance :{' '}
                                        </Text>
                                        <Text fontSize="12px">
                                          ${''}
                                          {rewardPoint == 0 || null
                                            ? 0
                                            : rewardPoint}{' '}
                                        </Text>
                                      </Box>

                                      <Text fontSize="12px">
                                        Reward Percentage :{' '}
                                        {rewardPercentage
                                          ? `${rewardPercentage}%`
                                          : 0}
                                      </Text>
                                    </Box>
                                  ) : (
                                    ''
                                  )}

                                  <Box
                                    pt="12px"
                                    mt="12px"
                                    d="flex"
                                    justifyContent="space-between"
                                    fontSize="14px"
                                    borderTop="1px solid #e9e9e9"
                                  >
                                    <Text>Total Cart Amount:</Text>
                                    <Text>${subTotal.toFixed(2)}</Text>
                                  </Box>
                                  <Box
                                    pt="10px"
                                    d="flex"
                                    justifyContent="space-between"
                                    fontSize="14px"
                                  >
                                    {/* <Text fontWeight='bold'>Apply Coupen</Text> */}
                                    <Text>Offer Code :</Text>
                                    <Text>{coupon}</Text>
                                  </Box>
                                  <Box
                                    pt="10px"
                                    // d={
                                    //   discountmessage.valid == true || discountTotal != 0
                                    //     ? 'flex'
                                    //     : 'none'
                                    // }
                                    d="flex"
                                    justifyContent="space-between"
                                    fontSize="14px"
                                  >
                                    <Text>Discount Deduction :</Text>
                                    <Text>
                                      $
                                      {onlinediscountAmnt > 0
                                        ? onlinediscountAmnt.toFixed(2)
                                        : ((percent / 100) * total).toFixed(
                                          2
                                        )}{' '}
                                    </Text>
                                  </Box>

                                  {checkedItem == true ? (
                                    <Box
                                      pt="10px"
                                      d="flex"
                                      justifyContent="space-between"
                                      fontSize="14px"
                                    >
                                      <Text>Reward Amount :</Text>
                                      <Text>
                                        ${rewardPoint == null ? 0 : rewardPoint}
                                      </Text>
                                    </Box>
                                  ) : (
                                    ''
                                  )}

                                  <Box
                                    pt="10px"
                                    d="flex"
                                    justifyContent="space-between"
                                    fontSize="14px"
                                  >
                                    <Text>Tax Amount :</Text>
                                    <Text>
                                      ${(subTotal * taxFactor).toFixed(2)}
                                      {/* {(
                                        taxFactor *
                                        (subTotal - discountTotal)
                                      ).toFixed(2)} */}
                                    </Text>
                                  </Box>

                                  <Box
                                    pt="10px"
                                    pb="20px"
                                    d="flex"
                                    justifyContent="space-between"
                                    fontSize="14px"
                                  >
                                    <Text fontWeight="medium">
                                      Amount Payable :
                                    </Text>

                                    <Text fontWeight="medium">
                                      $
                                      {
                                        (subTotal + (subTotal * taxFactor)
                                          - (rewardPoint != null && checkedItem == true ? rewardPoint : 0)
                                          - (onlinediscountAmnt > 0 ? onlinediscountAmnt.toFixed(2) :
                                            ((percent / 100) * total).toFixed(2))).toFixed(2)
                                      }
                                      {/* {(
                                        subTotal -
                                        (rewardPoint != null &&
                                          checkedItem == true
                                          ? rewardPoint
                                          : 0) +
                                        taxFactor * (subTotal - discountTotal) -
                                        discountTotal
                                      ).toFixed(2) > 0
                                        ? (
                                          subTotal -
                                          (rewardPoint != null &&
                                            checkedItem == true
                                            ? rewardPoint
                                            : 0) +
                                          taxFactor *
                                          (subTotal - discountTotal) -
                                          discountTotal
                                        ).toFixed(2)
                                        : 0} */}
                                    </Text>
                                    {/* {(
                                        subTotal -
                                        (rewardPoint != null &&
                                        checkedItem == true
                                          ? rewardPoint
                                          : 0) +
                                        taxFactor * (subTotal - discountTotal) -
                                        discountTotal
                                      ).toFixed(2)} < rewardPoint  ?

                                    <Text fontWeight="medium">0</Text>
                                    :
                                    <Text fontWeight="medium">
                                      {(
                                        subTotal -
                                        (rewardPoint != null &&
                                        checkedItem == true
                                          ? rewardPoint
                                          : 0) +
                                        taxFactor * (subTotal - discountTotal) -
                                        discountTotal
                                      ).toFixed(2)} */}
                                    {/* </Text> */}
                                  </Box>

                                  <Box pb="5px" mb={3}>
                                    <Input
                                      h="50px"
                                      placeholder="Comments"
                                      type="text"
                                      value={commentvalue}
                                      onChange={handlecommentvalue}
                                      maxLength={100}
                                    ></Input>
                                  </Box>

                                  <RadioGroup
                                    onChange={setRadiopayment}
                                    value={Radiopayment}
                                    m="5px"
                                    defaultValue="2"
                                  >
                                    <Stack spacing={5} direction="row">
                                      <Box w="100%" flex="1" d="flex">
                                        <Radio
                                          fontSize="12px"
                                          w="35%"
                                          colorScheme="red"
                                          value="1"
                                        >
                                          Pay Now
                                        </Radio>
                                        <Radio colorScheme="green" value="2">
                                          Pay at Restaurant
                                        </Radio>
                                      </Box>
                                    </Stack>
                                  </RadioGroup>

                                  <Box
                                    mb={3}
                                    d={Radiopayment == '1' ? 'block' : 'none'}
                                  >
                                    {/* <Text > payment info</Text> */}

                                    <Box b="solid 2px black">
                                      <Elements stripe={stripePromise}>
                                        <CheckoutForm />
                                      </Elements>
                                    </Box>
                                  </Box>

                                  <Progress
                                    d={paymentLoader == true ? 'block' : 'none'}
                                    mt="15px"
                                    size="xs"
                                    isIndeterminate
                                  />
                                  {Radiopayment == '2' ? (
                                    <Button
                                      d={
                                        paymentLoader == true ? 'none' : 'block'
                                      }
                                      mt="15px"
                                      onClick={() => {
                                        // //

                                        console.log(cartitems);
                                        ////

                                        if (Radiopayment === '2') {
                                          ////
                                          callcardlesspayment();
                                        } else {
                                          //	setpaymentbit(true);
                                          setisCheckout(false);
                                        }
                                      }}
                                      w="100%"
                                      colorScheme="blue"
                                    >
                                      Place Order{' '}
                                    </Button>
                                  ) : (
                                    ''
                                  )}
                                </Box>
                                <Box>
                                  {/* <Progress d={paymentLoader == true ? 'block' : 'none'} mt='10px' size="xs" isIndeterminate /> */}

                                  {/* <Button
                                    d={paymentLoader == true ? 'none' : 'block'}
                                    className="primary-btn"
                                    w="100%"
                                    mt="10px"
                                    type="submit"
                                  >
                                    Pay Now
                                  </Button> */}
                                </Box>
                              </Box>
                            </Box>
                          ) : (
                            <Box>
                              <Box>
                                <Image
                                  width="60px"
                                  margin="auto"
                                  src={OrderSuccess}
                                />
                                <Text textAlign="center" pt="15px">
                                  Your order has been placed successfully
                                </Text>

                                <Box pt="10px" pb="10px">
                                  <Box className="order-pickup">
                                    {Radiovalue == '2' && pickupbit == true ? (
                                      <Text>
                                        Scheduled order at {item?.REST_NAME} on{' '}
                                        {datevalue}
                                      </Text>
                                    ) : pickupbit == false ? (
                                      <Text>
                                        Dine-In at {item?.REST_NAME} on{' '}
                                        {Json_specialitem == '' &&
                                          cartNumber == 0
                                          ? datevalue
                                          : specialDateValue}{' '}
                                      </Text>
                                    ) : (
                                      <Text>
                                        Pickup ASAP at {item?.REST_NAME}
                                      </Text>
                                    )}
                                  </Box>
                                  <Box className="order-pickup-details">
                                    <Text>
                                      Order ID: <span>#{orderid}</span>{' '}
                                    </Text>
                                    <Text>
                                      Payment Mode :{' '}
                                      <span>
                                        {Radiopayment == '2' ? ' Cash' : 'Card'}
                                      </span>{' '}
                                    </Text>
                                    <Text>
                                      Discount :{' '}
                                      <span>${onlinediscountAmnt > 0
                                        ? onlinediscountAmnt.toFixed(2)
                                        : ((percent / 100) * total).toFixed(2)} </span>
                                    </Text>
                                    <Text>
                                      Tax Amount :{' '}
                                      <span>
                                        $
                                        {
                                          (subTotal * taxFactor).toFixed(2)
                                        }
                                        {/* {(
                                          taxFactor *
                                          (subTotal - discountTotal)
                                        ).toFixed(2)} */}
                                      </span>{' '}
                                    </Text>
                                    <Text>
                                      Amount Paid :{' '}
                                      <span>
                                        {/* $
                                        {(
                                        subTotal -
                                        (rewardPoint != null &&
                                        checkedItem == true
                                          ? rewardPoint
                                          : 0) +
                                        taxFactor * (subTotal - discountTotal) -
                                        discountTotal
                                      ).toFixed(2)} */}
                                        $
                                        {(subTotal + (subTotal * taxFactor)
                                          - (rewardPoint != null && checkedItem == true ? rewardPoint : 0)
                                          - (onlinediscountAmnt > 0 ? onlinediscountAmnt.toFixed(2) :
                                            ((percent / 100) * total).toFixed(2))).toFixed(2)}
                                        {/* {(
                                          subTotal -
                                          (rewardPoint != null &&
                                            checkedItem == true
                                            ? rewardPoint
                                            : 0) +
                                          taxFactor *
                                          (subTotal - discountTotal) -
                                          discountTotal
                                        ).toFixed(2) > 0
                                          ? (
                                            subTotal -
                                            (rewardPoint != null &&
                                              checkedItem == true
                                              ? rewardPoint
                                              : 0) +
                                            taxFactor *
                                            (subTotal - discountTotal) -
                                            discountTotal
                                          ).toFixed(2)
                                          : 0} */}
                                      </span>
                                    </Text>
                                  </Box>
                                </Box>
                                <Box pt="25px" textAlign="center">
                                  <Link
                                    className="secondary-btn back-btn"
                                    onClick={() => {
                                      history.push({
                                        pathname: '/Home',
                                        //state: { email: values.email, password: values.password }
                                      });
                                    }}
                                  >
                                    Continue to home
                                  </Link>
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </Box>
                      ) : (
                        <Box>
                          <Text textAlign="center" mb="15px">
                            <FontAwesomeIcon icon={faShoppingCart} />
                          </Text>
                          <Text textAlign="center" fontSize="13px">
                            Ready to go !
                          </Text>
                          <Text textAlign="center" fontSize="13px">
                            Add items to get started
                          </Text>
                        </Box>
                      )}
                        </Box>
                      </Show>

                      <Show below='sm'>
                        <Box className="card" style={{paddingTop: 10, paddingBottom: 10}}>
                      <Accordion allowToggle>
                        <AccordionItem style={{border: 0}}>
                            <AccordionButton style={{padding: 0, backgroundColor: 'transparent'}}>
                              <Box as="span" flex='1' textAlign='left'>
                                <Text className="user-title" style={{paddingBottom: 0}}>
                                  {isCheckout ? '' : `Your Order`} 
                                </Text>
                              </Box>
                            <AccordionIcon />
                            </AccordionButton>
                        <AccordionPanel style={{padding: 0}}>
                        {cartitems?.length > 0 || orederStatus == true ? (
                          <Box>
                            {orederStatus == false ? (
                              <Box>
                                <Box d={isCheckout ? 'none' : 'block'}>
                                  <Box className="orderboxin">
                                    <Box
                                      className="order-tab"
                                      justifyContent="space-between"
                                      w="100%"
                                      flex="1"
                                      d="flex"
                                    >
                                      <Link
                                        textDecoration="none"
                                        w="100%"
                                        onClick={() => {
                                          setpickupbit(true);
                                          setdatevalidation(false);
                                        }}
                                      >
                                        {' '}
                                        <Box
                                          textAlign="center"
                                          borderRadius="25px 0 0 25px"
                                          backgroundColor={
                                            pickupbit == true ? '#1255ac' : ''
                                          }
                                          color={
                                            pickupbit == true
                                              ? '#ffffff'
                                              : '#1255ac'
                                          }
                                        >
                                          Pick-Up
                                        </Box>
                                      </Link>
                                      <Link
                                        textDecoration="none"
                                        w="100%"
                                        onClick={() => {
                                          setpickupbit(false);
                                          setdatevalidation(false);
                                        }}
                                      >
                                        {' '}
                                        <Box
                                          textAlign="center"
                                          borderRadius="0 25px 25px 0"
                                          backgroundColor={
                                            pickupbit == false ? '#1255ac' : ''
                                          }
                                          color={
                                            pickupbit == false
                                              ? '#ffffff'
                                              : '#1255ac'
                                          }
                                        >
                                          Dine-In
                                        </Box>
                                      </Link>
                                    </Box>
                                    {pickupbit == true ? (
                                      <RadioGroup
                                        onChange={setRadioValue}
                                        value={Radiovalue}
                                        m="px"
                                        defaultValue="2"
                                      >
                                        <Stack spacing={5} direction="row">
                                          <Box w="100%" flex="1" d="flex">
                                            <Radio
                                              w="50%"
                                              colorScheme="red"
                                              value="1"
                                            >
                                              ASAP
                                            </Radio>
                                            <Radio colorScheme="green" value="2">
                                              Schedule
                                            </Radio>
                                          </Box>
                                        </Stack>
                                      </RadioGroup>
                                    ) : (
                                      <Box>
                                        <Box className="order-date-time-dinein">
                                          <Box
                                            d="flex"
                                            className="order-date-time"
                                          >
                                            <Box>
                                              <Text>Date</Text>
                                              <Input
                                                type="date"
                                                //value={((specialDateValue) != moment(new Date()).format("YYYY-MM-DD")) ? specialDateValue : datevalue}
                                                value={
                                                  Json_specialitem == '' &&
                                                    cartNumber == 0
                                                    ? datevalue
                                                    : specialDateValue
                                                }
                                                onChange={handleChangedate}
                                                size="sm"
                                              />
                                            </Box>
                                            <Box ml={3}>
                                              <Text>Time</Text>
                                              <TimePicker
                                                //value={specialTimevalue != moment('00:00', 'HH:mm') ? specialTimevalue :  timevalue}
                                                // value={
                                                //   Json_specialitem == '' &&
                                                //   cartNumber == 0
                                                //     ? timevalue
                                                //     : specialTimevalue
                                                // }
                                                value={
                                                  Json_specialitem == '' &&
                                                    cartNumber == 0
                                                    ? timevalue
                                                    : specialTimevalue
                                                }
                                                onChange={value => {
                                                  const aa =
                                                    value.format('hh:mm A');
                                                  ////

                                                  console.log(aa);
                                                  settimeValue(
                                                    moment(aa, 'HH:mm A')
                                                  );
                                                  setSpecialTimevalue(
                                                    moment(aa, 'HH:mm A')
                                                  );
                                                  // //
                                                }}
                                                showSecond={false}
                                                use12Hours
                                                allowEmpty
                                                format="hh:mm A"
                                              />
                                            </Box>
                                          </Box>

                                          <Box
                                            d="flex"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            className="party-size"
                                            mb={3}
                                          >
                                            <Text>Party Size</Text>
                                            <Input
                                              w="50%"
                                              type="number"
                                              //value={specialPartysize != 1 ? specialPartysize : partysize}
                                              value={
                                                Json_specialitem == '' &&
                                                  cartNumber == 0
                                                  ? partysize
                                                  : specialPartysize
                                              }
                                              onChange={handleChangepartysize}
                                              size="sm"
                                              min="1"
                                            />
                                          </Box>
                                        </Box>
                                        <Text
                                          fontSize="13px"
                                          d={
                                            datevalidation == true &&
                                              pickupbit == false
                                              ? 'block'
                                              : 'none'
                                          }
                                          color="red"
                                        >
                                          Please enter the valid dine-in details
                                        </Text>
                                      </Box>
                                    )}

                                    {Radiovalue == '2' && pickupbit == true ? (
                                      <Box>
                                        <Box
                                          d="flex"
                                          mb={3}
                                          className="order-date-time"
                                        >
                                          <Input
                                            type="date"
                                            value={datevalue}
                                            onChange={handleChangedate}
                                            size="sm"
                                          />
                                          <TimePicker
                                            value={timevalue}
                                            onChange={value => {
                                              const aa = value.format('hh:mm A');

                                              console.log(aa);
                                              settimeValue(moment(aa, 'HH:mm A'));
                                            }}
                                            showSecond={false}
                                            use12Hours
                                            allowEmpty
                                            format="hh:mm A"
                                          />
                                        </Box>
                                        <Text
                                          fontSize="13px"
                                          d={
                                            datevalidation == true &&
                                              Radiovalue == '2' &&
                                              pickupbit == true
                                              ? 'block'
                                              : 'none'
                                          }
                                          color="red"
                                        >
                                          Please enter the valid date and time for
                                          pickup
                                        </Text>
                                      </Box>
                                    ) : (
                                      ''
                                    )}
                                    <Box d="flex">
                                      <Input
                                        mb={2}
                                        name="phonenumber"
                                        placeholder="phone"
                                        value={phoneNumber}
                                        editable={false}
                                      />
                                      {/* <Button ml={3} mt={3} icon={<FontAwesomeIcon fontSize='22px' icon={faPhone} />}/> */}
                                    </Box>

                                    <OrderedList>
                                      {cartitems.map(item => (
                                        <ListItem>
                                          <Box
                                            d="flex"
                                            justifyContent="space-between"
                                            pr="5px"
                                          >
                                            <Box>
                                              <Text
                                                fontSize="15px"
                                                color="#3D3D3D"
                                                mv={2}
                                              >
                                                {item.menu.MENU_IT_NAME}
                                              </Text>
                                              <Text
                                                fontSize="16px"
                                                fontWeight="500"
                                              >
                                                {item.quantity + 'x$'}
                                                {item.totalprice}
                                              </Text>
                                            </Box>
                                            <Box
                                              d="flex"
                                              className="order-btns"
                                              alignContent="flex-start"
                                            >
                                              <Link
                                                d="flex"
                                                onClick={() => {
                                                  //setcurrentcartitemID(item.cartID)
                                                  const list = [...cartitems];

                                                  const idx = list.findIndex(
                                                    x => x.index === item.index
                                                  );
                                                  const currentmenu =
                                                    list[idx]?.menu;
                                                  seteditcartitem(list[idx]);
                                                  setEditObject(currentmenu);
                                                  setcurrentcartitemID(
                                                    item.index
                                                  );
                                                  const a = 10;

                                                  onOpen();
                                                }}
                                              >
                                                <EditIcon
                                                  color="#85BAFF"
                                                //onClick={() => onEdit({ id: obj.CH_ITEM_ID, currentPrice: obj.CH_PRICE, currentLabel: obj.CH_ITEM_DESC })}
                                                />
                                              </Link>
                                              <Box pl="10px">
                                                <Link
                                                  pr="5px"
                                                  d="flex"
                                                  onClick={() => {
                                                    alertonOpen();

                                                    // const list=[...cartitems]
                                                    setcurrentcartitemID(
                                                      item.index
                                                    );
                                                    // const idx = list.findIndex(x => x.cartID === item.cartID);
                                                    // list.splice(idx, 1);
                                                    // setcartitems(list);
                                                  }}
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faTrashAlt}
                                                    color="#FF7373"
                                                  />
                                                </Link>
                                              </Box>
                                            </Box>
                                          </Box>
                                          <Box w="95%">
                                            {item.customizationdata?.map(
                                              (obj, index) => (
                                                <Box display="inline">
                                                  <Text
                                                    fontSize="13px"
                                                    display="inline"
                                                  >
                                                    {obj.MUST_CHOOSE_FLAG
                                                      ? obj.CH_DESC +
                                                      '(' +
                                                      obj.CH_ITEM_DESC +
                                                      ') ' +
                                                      (index + 1 <
                                                        item.customizationdata
                                                          .length
                                                        ? ', '
                                                        : '')
                                                      : ''}
                                                  </Text>

                                                  <Text
                                                    fontSize="13px"
                                                    display="inline"
                                                  >
                                                    {obj.checkdata
                                                      ? obj.CH_DESC + '('
                                                      : ''}

                                                    {obj.checkdata?.map(
                                                      (x, index) =>
                                                        x.CH_ITEM_DESC +
                                                        (obj.checkdata.length >
                                                          index + 1
                                                          ? ', '
                                                          : '')
                                                    )}
                                                    {obj.checkdata ? ')' : ''}
                                                  </Text>
                                                </Box>
                                              )
                                            )}
                                          </Box>
                                        </ListItem>
                                      ))}
                                    </OrderedList>
                                  </Box>
                                  <hr className="user-divider"></hr>
                                  <Box
                                    w="100%"
                                    d="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                  >
                                    <Text fontSize="18px" fontWeight="600">
                                      ${subTotal.toFixed(2)}
                                    </Text>
                                    <Button
                                      type="submit"
                                      className="primary-btn"
                                      ml={3}
                                      onClick={() => {
                                        //debugger;
                                        ManuData();
                                        functiononlinecoupons();
                                        setTotal(subTotal);
                                        const date =
                                          Json_specialitem == '' &&
                                            cartNumber == 0
                                            ? datevalue
                                            : specialDateValue;

                                        let dat = null;
                                        if (Json_specialitem != '') {
                                          const sp = JSON.parse(Json_specialitem);
                                          console.log(sp);
                                          ////
                                          if (
                                            (sp.DATE_TO_DATE >= date &&
                                              sp.DATE_FROM_DATE <= date) ||
                                            sp.DATE_TO_DATE == undefined
                                          ) {
                                            if (date != '') {
                                              //const tmp = timevalue.format('HH:mm');
                                              const tmp =
                                                Json_specialitem == ''
                                                  ? timevalue.format('HH:mm')
                                                  : specialTimevalue.format(
                                                    'HH:mm'
                                                  );
                                              dat = new Date(date + 'T' + tmp);
                                              // if(dat<new Date())
                                              // {
                                              // 	setdatevalidation(true)

                                              // }
                                            }
                                            const todaydate = new Date();
                                            const b = timevalue.format('hh:mm A');

                                            if (date != null) {
                                              if (
                                                pickupbit == true &&
                                                Radiovalue == '2'
                                              ) {
                                                if (
                                                  date == '' ||
                                                  dat < todaydate
                                                ) {
                                                  ////
                                                  setdatevalidation(true);
                                                } else {
                                                  cartSave(cartitems);
                                                  setisCheckout(true);
                                                }
                                              } else if (pickupbit == false) {
                                                if (
                                                  date == '' ||
                                                  dat < new Date()
                                                ) {
                                                  ////
                                                  setdatevalidation(true);
                                                }
                                                // if (
                                                //   date == ''
                                                // ) {
                                                //   // //
                                                //   setdatevalidation(true);
                                                // }
                                                else {
                                                  cartSave(cartitems);
                                                  setisCheckout(true);
                                                }
                                              } else {
                                                cartSave(cartitems);
                                                setisCheckout(true);
                                              }
                                            } else {
                                            }
                                            // const sp = JSON.parse(Json_specialitem)
                                            // console.log(sp);
                                            // if(sp.DATE_TO_DATE>=date && sp.DATE_FROM_DATE<=date)
                                            // {
                                            //   alert("OK");
                                          } else {
                                            showValidationMsgSpcl(
                                              'Special Not Available',
                                              'error'
                                            );
                                          }
                                          // console.log(json_cartitems);
                                          // //
                                        } else {
                                          if (date != '') {
                                            const tmp = timevalue.format('HH:mm');

                                            dat = new Date(date + 'T' + tmp);
                                            // if(dat<new Date())
                                            // {
                                            // 	setdatevalidation(true)

                                            // }
                                          }
                                          const todaydate = new Date();
                                          const b = timevalue.format('hh:mm A');

                                          if (date != null) {
                                            if (
                                              pickupbit == true &&
                                              Radiovalue == '2'
                                            ) {
                                              if (date == '' || dat < todaydate) {
                                                //////
                                                setdatevalidation(true);
                                              } else {
                                                cartSave(cartitems);
                                                setisCheckout(true);
                                              }
                                            } else if (pickupbit == false) {
                                              if (
                                                date == '' ||
                                                dat < new Date()
                                              ) {
                                                ////
                                                setdatevalidation(true);
                                              } else {
                                                cartSave(cartitems);
                                                setisCheckout(true);
                                              }
                                            } else {
                                              cartSave(cartitems);
                                              setisCheckout(true);
                                            }
                                          } else {
                                          }
                                        }
                                      }}
                                    >
                                      Proceed to Checkout
                                    </Button>
                                  </Box>
                                  {/* <Box pr='10px' w='100%' d='flex' justifyContent='space-between' className="totalbox">

                                  <Text fontSize='15px' fontWeight='500'>Sub Total</Text>
                                  <Text fontSize='18px' fontWeight='bold'>${subTotal.toFixed(2)}</Text>
                                  </Box> */}
                                </Box>

                                <Box d={isCheckout ? 'block' : 'none'}>
                                  <Box className="orderboxin order-payment">
                                    <Box className="order-summery">
                                      <Text fontSize="14px" fontWeight="500">
                                        Order Summery :{' '}
                                        <Link>
                                          {' '}
                                          <EditIcon
                                            onClick={() => {
                                              setisCheckout(false);
                                              setdatevalidation(false);
                                            }}
                                          />
                                        </Link>
                                      </Text>
                                      <Box className="order-summery-inner">
                                        {cartitems.map(item => (
                                          <Box
                                            fontSize="13px"
                                            py="2px"
                                            d="flex"
                                            justifyContent="space-between"
                                          >
                                            {' '}
                                            {item.menu.MENU_IT_NAME}{' '}
                                            <span>{'  x ' + item.quantity}</span>
                                          </Box>
                                        ))}

                                        {/* <Text><span>rhyfueg rfyesdfsdgh</span><span>rhyfuegr fyegh</span><span>rhyfudsfegrfyegh</span></Text> */}
                                      </Box>

                                      {Radiovalue == '2' && pickupbit == true ? (
                                        <Text>
                                          Scheduled order at {item?.REST_NAME} on{' '}
                                          {Json_specialitem == '' &&
                                            cartNumber == 0
                                            ? datevalue
                                            : specialDateValue}{' '}
                                        </Text>
                                      ) : pickupbit == false ? (
                                        <Text>
                                          Dine-In at {item?.REST_NAME} on{' '}
                                          {Json_specialitem == '' &&
                                            cartNumber == 0
                                            ? datevalue
                                            : specialDateValue}{' '}
                                        </Text>
                                      ) : (
                                        <Text>
                                          Pickup ASAP at {item?.REST_NAME}
                                        </Text>
                                      )}
                                    </Box>

                                    <Box
                                      d="flex"
                                      className="order-coupen"
                                      justifyContent="space-between"
                                    >
                                      <Input
                                        value={code}
                                        type="text"
                                        onChange={handleChange}
                                        placeholder="APPLY CODE"
                                        isReadOnly={
                                          onlineCouponStatus ? true : false
                                        }
                                      //	size="sm"
                                      />
                                      <Button
                                        onClick={() => {
                                          CallDiscountAPI(value);
                                        }}
                                        bg="#3e88e8"
                                        color="#ffffff"
                                        disabled={
                                          onlineCouponStatus ? true : false
                                        }
                                      >
                                        Apply
                                      </Button>
                                    </Box>
                                    {onlineCouponStatus && (
                                      <Text
                                        fontSize="13px"
                                        pt-1
                                        color="green.500"
                                      >
                                        {onlinediscountAmnt > 0 && '$'}
                                        {onlinediscountAmnt > 0
                                          ? onlinediscountAmnt
                                          : percent}
                                        {onlinediscountAmnt === 0 && '%'} Discount
                                        applied successfully !!!
                                      </Text>
                                    )}
                                    <Text
                                      fontSize="13px"
                                      pt-1
                                      color={
                                        discountmessage.valid == true
                                          ? 'green.500'
                                          : 'red.500'
                                      }
                                    >
                                      {discountmessage.status == true
                                        ? discountmessage.message
                                        : ''}
                                    </Text>

                                    {/* <Box pt="5px" d="flex" style={{display:rewardPoint == null ?'block':'none'}}> */}
                                    {rewardPoint != 0 ? (
                                      <Box>
                                        <Box
                                          pt="10px"
                                          d="flex"
                                          className="reward-check-box"
                                        >
                                          <Checkbox
                                            isChecked={checkedItem}
                                            onChange={e =>
                                              setCheckedItem(e.target.checked)
                                            }
                                          ></Checkbox>
                                          <Text
                                            pl="2"
                                            fontSize="13px"
                                            color="#02ae35"
                                          >
                                            Apply Rewards
                                          </Text>
                                        </Box>
                                        <Box pt="7px" d="flex">
                                          <Text fontSize="12px">
                                            Reward balance :{' '}
                                          </Text>
                                          <Text fontSize="12px">
                                            ${''}
                                            {rewardPoint == 0 || null
                                              ? 0
                                              : rewardPoint}{' '}
                                          </Text>
                                        </Box>

                                        <Text fontSize="12px">
                                          Reward Percentage :{' '}
                                          {rewardPercentage
                                            ? `${rewardPercentage}%`
                                            : 0}
                                        </Text>
                                      </Box>
                                    ) : (
                                      ''
                                    )}

                                    <Box
                                      pt="12px"
                                      mt="12px"
                                      d="flex"
                                      justifyContent="space-between"
                                      fontSize="14px"
                                      borderTop="1px solid #e9e9e9"
                                    >
                                      <Text>Total Cart Amount:</Text>
                                      <Text>${subTotal.toFixed(2)}</Text>
                                    </Box>
                                    <Box
                                      pt="10px"
                                      d="flex"
                                      justifyContent="space-between"
                                      fontSize="14px"
                                    >
                                      {/* <Text fontWeight='bold'>Apply Coupen</Text> */}
                                      <Text>Offer Code :</Text>
                                      <Text>{coupon}</Text>
                                    </Box>
                                    <Box
                                      pt="10px"
                                      // d={
                                      //   discountmessage.valid == true || discountTotal != 0
                                      //     ? 'flex'
                                      //     : 'none'
                                      // }
                                      d="flex"
                                      justifyContent="space-between"
                                      fontSize="14px"
                                    >
                                      <Text>Discount Deduction :</Text>
                                      <Text>
                                        $
                                        {onlinediscountAmnt > 0
                                          ? onlinediscountAmnt.toFixed(2)
                                          : ((percent / 100) * total).toFixed(
                                            2
                                          )}{' '}
                                      </Text>
                                    </Box>

                                    {checkedItem == true ? (
                                      <Box
                                        pt="10px"
                                        d="flex"
                                        justifyContent="space-between"
                                        fontSize="14px"
                                      >
                                        <Text>Reward Amount :</Text>
                                        <Text>
                                          ${rewardPoint == null ? 0 : rewardPoint}
                                        </Text>
                                      </Box>
                                    ) : (
                                      ''
                                    )}

                                    <Box
                                      pt="10px"
                                      d="flex"
                                      justifyContent="space-between"
                                      fontSize="14px"
                                    >
                                      <Text>Tax Amount :</Text>
                                      <Text>
                                        ${(subTotal * taxFactor).toFixed(2)}
                                        {/* {(
                                          taxFactor *
                                          (subTotal - discountTotal)
                                        ).toFixed(2)} */}
                                      </Text>
                                    </Box>

                                    <Box
                                      pt="10px"
                                      pb="20px"
                                      d="flex"
                                      justifyContent="space-between"
                                      fontSize="14px"
                                    >
                                      <Text fontWeight="medium">
                                        Amount Payable :
                                      </Text>

                                      <Text fontWeight="medium">
                                        $
                                        {
                                          (subTotal + (subTotal * taxFactor)
                                            - (rewardPoint != null && checkedItem == true ? rewardPoint : 0)
                                            - (onlinediscountAmnt > 0 ? onlinediscountAmnt.toFixed(2) :
                                              ((percent / 100) * total).toFixed(2))).toFixed(2)
                                        }
                                        {/* {(
                                          subTotal -
                                          (rewardPoint != null &&
                                            checkedItem == true
                                            ? rewardPoint
                                            : 0) +
                                          taxFactor * (subTotal - discountTotal) -
                                          discountTotal
                                        ).toFixed(2) > 0
                                          ? (
                                            subTotal -
                                            (rewardPoint != null &&
                                              checkedItem == true
                                              ? rewardPoint
                                              : 0) +
                                            taxFactor *
                                            (subTotal - discountTotal) -
                                            discountTotal
                                          ).toFixed(2)
                                          : 0} */}
                                      </Text>
                                      {/* {(
                                          subTotal -
                                          (rewardPoint != null &&
                                          checkedItem == true
                                            ? rewardPoint
                                            : 0) +
                                          taxFactor * (subTotal - discountTotal) -
                                          discountTotal
                                        ).toFixed(2)} < rewardPoint  ?

                                      <Text fontWeight="medium">0</Text>
                                      :
                                      <Text fontWeight="medium">
                                        {(
                                          subTotal -
                                          (rewardPoint != null &&
                                          checkedItem == true
                                            ? rewardPoint
                                            : 0) +
                                          taxFactor * (subTotal - discountTotal) -
                                          discountTotal
                                        ).toFixed(2)} */}
                                      {/* </Text> */}
                                    </Box>

                                    <Box pb="5px" mb={3}>
                                      <Input
                                        h="50px"
                                        placeholder="Comments"
                                        type="text"
                                        value={commentvalue}
                                        onChange={handlecommentvalue}
                                        maxLength={100}
                                      ></Input>
                                    </Box>

                                    <RadioGroup
                                      onChange={setRadiopayment}
                                      value={Radiopayment}
                                      m="5px"
                                      defaultValue="2"
                                    >
                                      <Stack spacing={5} direction="row">
                                        <Box w="100%" flex="1" d="flex">
                                          <Radio
                                            fontSize="12px"
                                            w="35%"
                                            colorScheme="red"
                                            value="1"
                                          >
                                            Pay Now
                                          </Radio>
                                          <Radio colorScheme="green" value="2">
                                            Pay at Restaurant
                                          </Radio>
                                        </Box>
                                      </Stack>
                                    </RadioGroup>

                                    <Box
                                      mb={3}
                                      d={Radiopayment == '1' ? 'block' : 'none'}
                                    >
                                      {/* <Text > payment info</Text> */}

                                      <Box b="solid 2px black">
                                        <Elements stripe={stripePromise}>
                                          <CheckoutForm />
                                        </Elements>
                                      </Box>
                                    </Box>

                                    <Progress
                                      d={paymentLoader == true ? 'block' : 'none'}
                                      mt="15px"
                                      size="xs"
                                      isIndeterminate
                                    />
                                    {Radiopayment == '2' ? (
                                      <Button
                                        d={
                                          paymentLoader == true ? 'none' : 'block'
                                        }
                                        mt="15px"
                                        onClick={() => {
                                          // //

                                          console.log(cartitems);
                                          ////

                                          if (Radiopayment === '2') {
                                            ////
                                            callcardlesspayment();
                                          } else {
                                            //	setpaymentbit(true);
                                            setisCheckout(false);
                                          }
                                        }}
                                        w="100%"
                                        colorScheme="blue"
                                      >
                                        Place Order{' '}
                                      </Button>
                                    ) : (
                                      ''
                                    )}
                                  </Box>
                                  <Box>
                                    {/* <Progress d={paymentLoader == true ? 'block' : 'none'} mt='10px' size="xs" isIndeterminate /> */}

                                    {/* <Button
                                      d={paymentLoader == true ? 'none' : 'block'}
                                      className="primary-btn"
                                      w="100%"
                                      mt="10px"
                                      type="submit"
                                    >
                                      Pay Now
                                    </Button> */}
                                  </Box>
                                </Box>
                              </Box>
                            ) : (
                              <Box>
                                <Box>
                                  <Image
                                    width="60px"
                                    margin="auto"
                                    src={OrderSuccess}
                                  />
                                  <Text textAlign="center" pt="15px">
                                    Your order has been placed successfully
                                  </Text>

                                  <Box pt="10px" pb="10px">
                                    <Box className="order-pickup">
                                      {Radiovalue == '2' && pickupbit == true ? (
                                        <Text>
                                          Scheduled order at {item?.REST_NAME} on{' '}
                                          {datevalue}
                                        </Text>
                                      ) : pickupbit == false ? (
                                        <Text>
                                          Dine-In at {item?.REST_NAME} on{' '}
                                          {Json_specialitem == '' &&
                                            cartNumber == 0
                                            ? datevalue
                                            : specialDateValue}{' '}
                                        </Text>
                                      ) : (
                                        <Text>
                                          Pickup ASAP at {item?.REST_NAME}
                                        </Text>
                                      )}
                                    </Box>
                                    <Box className="order-pickup-details">
                                      <Text>
                                        Order ID: <span>#{orderid}</span>{' '}
                                      </Text>
                                      <Text>
                                        Payment Mode :{' '}
                                        <span>
                                          {Radiopayment == '2' ? ' Cash' : 'Card'}
                                        </span>{' '}
                                      </Text>
                                      <Text>
                                        Discount :{' '}
                                        <span>${onlinediscountAmnt > 0
                                          ? onlinediscountAmnt.toFixed(2)
                                          : ((percent / 100) * total).toFixed(2)} </span>
                                      </Text>
                                      <Text>
                                        Tax Amount :{' '}
                                        <span>
                                          $
                                          {
                                            (subTotal * taxFactor).toFixed(2)
                                          }
                                          {/* {(
                                            taxFactor *
                                            (subTotal - discountTotal)
                                          ).toFixed(2)} */}
                                        </span>{' '}
                                      </Text>
                                      <Text>
                                        Amount Paid :{' '}
                                        <span>
                                          {/* $
                                          {(
                                          subTotal -
                                          (rewardPoint != null &&
                                          checkedItem == true
                                            ? rewardPoint
                                            : 0) +
                                          taxFactor * (subTotal - discountTotal) -
                                          discountTotal
                                        ).toFixed(2)} */}
                                          $
                                          {(subTotal + (subTotal * taxFactor)
                                            - (rewardPoint != null && checkedItem == true ? rewardPoint : 0)
                                            - (onlinediscountAmnt > 0 ? onlinediscountAmnt.toFixed(2) :
                                              ((percent / 100) * total).toFixed(2))).toFixed(2)}
                                          {/* {(
                                            subTotal -
                                            (rewardPoint != null &&
                                              checkedItem == true
                                              ? rewardPoint
                                              : 0) +
                                            taxFactor *
                                            (subTotal - discountTotal) -
                                            discountTotal
                                          ).toFixed(2) > 0
                                            ? (
                                              subTotal -
                                              (rewardPoint != null &&
                                                checkedItem == true
                                                ? rewardPoint
                                                : 0) +
                                              taxFactor *
                                              (subTotal - discountTotal) -
                                              discountTotal
                                            ).toFixed(2)
                                            : 0} */}
                                        </span>
                                      </Text>
                                    </Box>
                                  </Box>
                                  <Box pt="25px" textAlign="center">
                                    <Link
                                      className="secondary-btn back-btn"
                                      onClick={() => {
                                        history.push({
                                          pathname: '/Home',
                                          //state: { email: values.email, password: values.password }
                                        });
                                      }}
                                    >
                                      Continue to home
                                    </Link>
                                  </Box>
                                </Box>
                              </Box>
                            )}
                          </Box>
                        ) : (
                          <Box>
                            <Text textAlign="center" mb="15px">
                              <FontAwesomeIcon icon={faShoppingCart} />
                            </Text>
                            <Text textAlign="center" fontSize="13px">
                              Ready to go !
                            </Text>
                            <Text textAlign="center" fontSize="13px">
                              Add items to get started
                            </Text>
                          </Box>
                        )}
                        </AccordionPanel>
                      </AccordionItem>
                      </Accordion>
                        </Box>
                      </Show>
                    
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          {/* {menuChoice.length > 0 && editObject && isOpen ? ( */}
          {editObject && isOpen && !isMenuChoiceLoading ? (
            <CartPopup
              editcartitem={editcartitem}
              menuChoice={menuChoice}
              cartData={cartitems}
              menu={editObject}
              open={isOpen}
              close={() => {
                onClose();
                setCartIsLoading(false);
                seteditcartitem([]);
                SetMenuChoice([]);
                setEditObject(null);
              }}
              addtocart={addtocart}
            />
          ) : null}
        </Box>
      )}
    </Box>
  );
}
export default RestaurantMenu;
