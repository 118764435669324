import {
  Box,
  Button,
  Text,
  Spinner,
  IconButton,
  Input,
  FormControl,
  InputGroup,
  InputLeftElement,
  Link,
  Badge,
} from '@chakra-ui/react';
import React, { useEffect, useState, useRef } from 'react';
import UserSidebar from './UserSidebar';
import Header from './Header';
import {
  faShoppingCart,
  faSave,
  faHeart,
} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
import { Formik, Form, Field, useFormik } from 'formik';
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
import { EmailIcon, UnlockIcon } from '@chakra-ui/icons';
import { useToast, useDisclosure } from '@chakra-ui/react';
import { CloseIcon, CheckIcon, EditIcon } from '@chakra-ui/icons';
import {
  getCitySearch as GetCitySearch,
  getNearbyCitySearch as GetNearbyCitySearch,
} from '../graphql/queries';
import Cookies from 'js-cookie';

import { Helmet } from 'react-helmet';
import * as yup from 'yup';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { text } from '@fortawesome/fontawesome-svg-core';
import MapPopup from '../screens/UserAuthScreen/Towns/MapPopup';
import {
  faMapMarkerAlt,
  faHome,
  faPhoneAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  getGroupTest,
  listUserTowns as ListUserTowns,
} from '../graphql/queries';
import _ from "lodash";

import { listUserCuisineTypeMasterFavOnlyY as ListUserCuisineTypeMasterFavOnlyY } from '../graphql/queries';
import {
  createUserCuisineTypeMaster as CreateUserCuisineTypeMaster,
  createUserHomeTownMaster as CreateUserHomeTownMaster,
  createUserNearbyTownMaster as CreateUserNearbyTownMaster,
} from '../graphql/mutations';
import { listSearch as listSearch } from '../graphql/queries';
import Quickfilter from '../components/FavoriteCuisineFilter';
const ValidationSchema = yup.object().shape({
  city: yup.string().required('Hometown is required'),
});

const Favorite = () => {
  const history = useHistory();
  const [cartData, setCartData] = useState([]);
  const [togglebit, setTogglebit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const toast = useToast();
  //var username;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [city, setCity] = useState('');
  let [ncities, setNCities] = useState([]);

  const [username, setUsername] = useState('');
  const cache = useRef({});
  const [isEdit, setIsEdit] = useState(true);
  const [neighbouringCity, setNeighbouringCity] = useState([]);
  const [zipCode, setZipCode] = useState('');
  let [cuisinesList, updateCuisines] = useState([]);
  let [filterCuisines, setFilterCuisines] = useState([]);
  let [filterCuisinesNames, setFilterCuisinesNames] = useState([]);
  let [editCuisinesList, setEditCuisineList] = useState([]);
  let [item, setItem] = useState();
  const [editCuisine, setEditCuisine] = useState(true);
  const [isShow, setIsShow] = useState(false);
  const [cityList, setCityList] = useState([]);
  const delayedQuery = useRef(_.debounce(q => searchLocation(q), 800)).current;
  const [edit, setEdit] = useState(true);
  const [isEditable, setIsEditable] = useState(true)
  const cuisineList = [
    {
      CUISINE_ID: 'C1',
      CUISINE_TYPE_NAME: 'American',
      USER_FAVORITE_YN: 'N',
    },
    {
      CUISINE_ID: 'C2',
      CUISINE_TYPE_NAME: 'Indian',
      USER_FAVORITE_YN: 'N',
    },
    {
      CUISINE_ID: 'C3',
      CUISINE_TYPE_NAME: 'Mexican',
      USER_FAVORITE_YN: 'N',
    },
    {
      CUISINE_ID: 'C4',
      CUISINE_TYPE_NAME: 'Italian',
      USER_FAVORITE_YN: 'N',
    },
    {
      CUISINE_ID: 'C5',
      CUISINE_TYPE_NAME: 'Mediterranean',
      USER_FAVORITE_YN: 'N',
    },
    {
      CUISINE_ID: 'C6',
      CUISINE_TYPE_NAME: 'Greek',
      USER_FAVORITE_YN: 'N',
    },
    {
      CUISINE_ID: 'C7',
      CUISINE_TYPE_NAME: 'Thai',
      USER_FAVORITE_YN: 'N',
    },
    {
      CUISINE_ID: 'C8',
      CUISINE_TYPE_NAME: 'Chinese',
      USER_FAVORITE_YN: 'N',
    },
    {
      CUISINE_ID: 'C9',
      CUISINE_TYPE_NAME: 'Japanese',
      USER_FAVORITE_YN: 'N',
    },
    {
      CUISINE_ID: 'C10',
      CUISINE_TYPE_NAME: 'French',
      USER_FAVORITE_YN: 'N',
    },
    {
      CUISINE_ID: 'C11',
      CUISINE_TYPE_NAME: 'Turkish',
      USER_FAVORITE_YN: 'N',
    },
    {
      CUISINE_ID: 'C12',
      CUISINE_TYPE_NAME: 'Persian',
      USER_FAVORITE_YN: 'N',
    },
    {
      CUISINE_ID: 'C13',
      CUISINE_TYPE_NAME: 'Pizza',
      USER_FAVORITE_YN: 'N',
    },
    {
      CUISINE_ID: 'C14',
      CUISINE_TYPE_NAME: 'Vietnamese',
      USER_FAVORITE_YN: 'N',
    },
    {
      CUISINE_ID: 'C15',
      CUISINE_TYPE_NAME: 'Korean',
      USER_FAVORITE_YN: 'N',
    },
    {
      CUISINE_ID: 'C16',
      CUISINE_TYPE_NAME: 'Deli',
      USER_FAVORITE_YN: 'N',
    },
    {
      CUISINE_ID: 'C17',
      CUISINE_TYPE_NAME: 'Fruits-Smoothie',
      USER_FAVORITE_YN: 'N',
    },
    {
      CUISINE_ID: 'C18',
      CUISINE_TYPE_NAME: 'Pastry-Bakery-Ice Cream',
      USER_FAVORITE_YN: 'N',
    },
    {
      CUISINE_ID: 'C19',
      CUISINE_TYPE_NAME: 'Coffee-Bagel',
      USER_FAVORITE_YN: 'N',
    },
  ];

  useEffect(() => {
    let today = moment(new Date(), 'YYYY-MM-DD');
    let formattedtoday = moment(today, 'YYYY-MM-DD').format('YYYY-MM-DD');

    var json_cartitems = Cookies.get('cartitems');
    if (json_cartitems) {
      const items = JSON.parse(json_cartitems);
      setCartData(items);

      const cartlen = items.filter(
        ob => ob.cart_created_date >= formattedtoday
      );
      console.log(cartlen);
      if (cartlen == 0) {
        Cookies.remove('cartitems');
      }
      //const a = 10;
      console.log('cart', items);
    }
    getHomeTown();
    listCuisines();
    checkIfAuthenticated();
    getSelectedCuisines();

    ////debugger
  }, []);
  const checkIfAuthenticated = async () => {
    let currentSession = null;
    //debugger;

    try {
      currentSession = await Auth.currentSession();
      setUsername(currentSession.idToken.payload.email);
    } catch (err) {}
  };
  function showValidationMsg(msg, st) {
    toast({
      title: '',
      description: msg,
      status: st,
      duration: 9000,
      isClosable: true,
      position: 'top',
    });
  }

  /* #region  get user location */
  async function getUserLocation() {
    try {
      if (cache.current["currentZip"]) {
        setCity(cache.current["currentCity"]);
        setZipCode(cache.current["currentZip"]);
        getCityByPostalCode(cache.current["currentZip"]);
      } else {
        setIsLoading(true);
        let { status } = await Location.requestForegroundPermissionsAsync();
        if (status !== "granted") {
          return;
        }
        let location = await Location.getCurrentPositionAsync({});
        const place = await Location.reverseGeocodeAsync({
          latitude: location.coords.latitude,
          longitude: location.coords.longitude,
        });
        if (place.length > 0) {
          getCityByPostalCode(place[0].postalCode);
          setCity(place[0].city);
          cache.current["currentZip"] = place[0].postalCode;
          cache.current["currentCity"] = place[0].city;
        }
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  }
  /* #endregion */
  function checkInput(item) {
    if (item === "") {
      setCity("");
    }
  }

  function clearCity() {
    if (username === 'squashappguest@gmail.com') {
        setCity('');
        checkInput('');
        setIsEditable(false)
    }
};

function hideCityList() {
  setIsShow(false);
}
  async function getHomeTown() {
    // const cities = await API.graphql(
    //   graphqlOperation(GetCitySearch, { CITY_NAME: N })
    // );
    // debugger;
    await API.graphql(graphqlOperation(ListUserTowns))
      .then(result => {
        result.data.listUSER_TOWNS.forEach(element => {
          if (element.ht_ind == 'HT') {
            setCity(element.city);
            getCityByPostalCode(element.zip_code);
            //debugger;
          }
        });

        setNCities(result.data.listUSER_TOWNS);
        console.log(result);
      })
      .catch(error => {});
    //debugger;
  }

  function toggle() {
    setTogglebit(current => !current);
  }

  async function update(data) {
    //debugger;
    console.log(data);
    setCity(data.city);
  }

  if (edit) {
    
    for (var i = 0; i < ncities.length; i++) {
      let index = neighbouringCity.findIndex(
        itm => itm.zip_code === ncities[i].zip_code
      );

      if (index >= 0) {
        neighbouringCity[index].USER_FAVORITE_YN = 'Y';
      }
    }
  }

  function searchLocation(input) {
    input.length >= 3 ? getCityBySearch(input.toLowerCase()) : setIsShow(false);
  }

  const onSelectNeighboring = zip_code => {
    setEdit(false);
    //debugger;
    setNeighbouringCity(oldData => {
      return [
        ...oldData.map(item => {
          if (zip_code === item.zip_code) {
            return {
              ...item,
              USER_FAVORITE_YN: item.USER_FAVORITE_YN === 'Y' ? 'N' : 'Y',
            };
          }
          return item;
        }),
      ];
    });
  };
  async function selectCity(item) {
    //debugger;
    setIsShow(false);
    getCityByPostalCode(item.zip_code);
    setZipCode(item.zip_code);
    setCity(item.city_state_id);
  }

  async function getCityBySearch(city) {
    //debugger;
    if (cache.current[city]) {
      setCityList(cache.current[city]);
      setIsShow(true);
    } else {
      const cities = await API.graphql(
        graphqlOperation(GetCitySearch, { CITY_NAME: city })
      );
      //debugger;
      cities?.data?.getCITY_SEARCH?.length > 0
        ? setIsShow(true)
        : setIsShow(false);
      setCityList(cities?.data?.getCITY_SEARCH);
      cache.current[city] = cities?.data?.getCITY_SEARCH;
    }
  }

  async function getCityByPostalCode(zip_code) {
    try {
      //setNeighborLoader(true);
      setZipCode(zip_code);
      if (cache.current[zip_code]) {
        //debugger;
        setNeighbouringCity(cache.current[zip_code]);
      } else {
        //debugger;
        const cities = await API.graphql(
          graphqlOperation(GetNearbyCitySearch, { ZIPCODE: zip_code })
        );
        cities.data.getNEARBY_CITY_SEARCH.map(function (e) {
          e.USER_FAVORITE_YN = 'N';
        });
        setNeighbouringCity(cities.data.getNEARBY_CITY_SEARCH);
        cache.current[zip_code] = cities.data.getNEARBY_CITY_SEARCH;
      }
      // setNeighborLoader(false);
    } catch (err) {
      // setNeighborLoader(false);
    }
  }

  async function mutateUserHometownFave(ZIP_CODE, USER_HOME_TOWN_YN) {
    setIsLoading(true);
    const inputData = {
      ZIP_CODE: ZIP_CODE,
      USER_HOME_TOWN_YN: USER_HOME_TOWN_YN,
    };

    try {
      await API.graphql(
        graphqlOperation(CreateUserHomeTownMaster, {
          createUSER_HOME_TOWN_MASTERInput: inputData,
        })
      );
    } catch (err) {
      setIsLoading(false);

      showValidationMsg(err.message);
    }
  }

  async function mutateUserNearbyTownFave(ZIP_CODE, USER_FAVORITE_YN) {
    const inputData = {
      ZIP_CODE: ZIP_CODE,
      USER_FAVORITE_YN: USER_FAVORITE_YN,
    };
    setIsLoading(true);
    try {
      await API.graphql(
        graphqlOperation(CreateUserNearbyTownMaster, {
          createUSER_NEARBY_TOWN_MASTERInput: inputData,
        })
      );
    } catch (err) {
      setIsLoading(false);

      showValidationMsg(err.message);
    }
    setIsLoading(false);
  }

  async function mutateUserCuisineFave(CUISINE_ID, USER_FAVE_YN) {
    const inputData = {
      CUISINE_ID: CUISINE_ID,
      USER_FAVORITE_YN: USER_FAVE_YN,
    };
    setIsLoading(true);
    try {
      await API.graphql(
        graphqlOperation(CreateUserCuisineTypeMaster, {
          createUSER_CUISINE_TYPE_MASTERInput: inputData,
        })
      );
    } catch (err) {
      setIsLoading(false);

      showValidationMsg(err.message);
    }
    setIsLoading(false);
  }

  function listCuisines() {
    updateCuisines(cuisineList);
  }

  async function getSelectedCuisines() {
    setIsLoading(true);
    const cuisines = await API.graphql(
      graphqlOperation(ListUserCuisineTypeMasterFavOnlyY)
    );
    //debugger;
    setEditCuisineList(
      cuisines.data.listUSER_CUISINE_TYPE_MASTER_FAV_ONLY_Y.map(elem => ({
        CUISINE_ID: elem.CUISINE_ID,
        CUISINE_TYPE_NAME: elem.CUISINE_TYPE_NAME,
      }))
    );
    setIsLoading(false);
  }

  // function to set favorites while selecting cuisines
  const onSelect = (CUISINE_ID, CUISINE_TYPE_NAME, USER_FAVORITE_YN) => {
    setEditCuisine(false);
    updateCuisines(oldData => {
      return [
        ...oldData.map(item => {
          if (CUISINE_ID === item.CUISINE_ID) {
            return {
              ...item,
              USER_FAVORITE_YN: item.USER_FAVORITE_YN === 'Y' ? 'N' : 'Y',
            };
          }
          return item;
        }),
      ];
    });
  };

  function setUserFav() {
    //debugger;
    if (zipCode == '') {
      showValidationMsg('Please select a city', 'error');
      return;
    } else if (
      cuisinesList.filter(st => st.USER_FAVORITE_YN === 'Y').length == 0
    ) {
      showValidationMsg('Please select a cuisine', 'error');
      return;
    }
    mutateUserHometownFave(zipCode, 'Y');

    neighbouringCity.map(item => {
      if (item.USER_FAVORITE_YN == 'Y') {
        mutateUserNearbyTownFave(item.zip_code, 'Y');
      } else {
        mutateUserNearbyTownFave(item.zip_code, 'N');
      }
    });

    cuisinesList.map(item => {
      if (item.USER_FAVORITE_YN == 'Y') {
        mutateUserCuisineFave(item.CUISINE_ID, 'Y');
      } else {
        mutateUserCuisineFave(item.CUISINE_ID, 'N');
      }
    });
    // updateFavstatus(route.params.user);
    // navigation.navigate("Home");
  }

  // async function listCuisines() {
  //   setIsLoading(true);
  //   try {

  //     const cuisines = await API.graphql(
  //       graphqlOperation(ListUserCuisineTypeMasterFav)
  //     );
  //     debugger;
  //     setCuisineList(
  //       cuisines.data.listUSER_CUISINE_TYPE_MASTER_FAV?.map(elem => ({
  //         CUISINE_ID: elem.CUISINE_ID,
  //         CUISINE_TYPE_NAME: elem.CUISINE_TYPE_NAME,
  //       }))
  //     );
  //     ////debugger;
  //   } catch (err) {
  //     const d = err;
  //   }

  //   setIsLoading(false);
  // }

  return (
    <Box bg="#f9f9f9">
      <Helmet>
        <title>Squash | Favorite</title>
      </Helmet>
      <MapPopup
        open={isOpen}
        close={() => {
          onClose();
        }}
      />
      <Header isAdmin={false} toggle={toggle} />
      <Box pt="70px" position="relative" h="100vh" overflow="hidden" d="flex">
        <Box
          // className="user-sidebar"
          zIndex="9"
          bg="#ffffff"
          position="absolute"
          top="0"
          pt="100px"
          left="0"
          h="100%"
          w={togglebit ? '200px' : '0px'}
          display={togglebit ? 'block' : 'none'}
        >
          <UserSidebar />
        </Box>
        {isLoading ? (
          <Box className="rspinbox">
            {' '}
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Box>
        ) : (
          <Box w="100%" p="20px" overflow="auto">
            <Box className="card content-card">
              <Box
                className="favorite-title"
                d="flex"
                pb="3"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text className="user-title">Set Favorites</Text>

                <Box className='favorite-right'>
                <Text mb={5}>Set your Home Town <span>*</span></Text>
            <Box className='favorite-right-in'>
                <Link
                  onClick={() => {
                    onOpen();
                  }}
                >
                  {' '}
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                </Link>

                <Formik
                  enableReinitialize
                  initialValues={{
                    city: city,
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    // alert(JSON.stringify(values, null, 2));
                    //alert(name);
                    //debugger;
                    setSubmitting(false);
                    update(values);
                    setIsEdit(true);
                    selectCity(values);
                    delayedQuery(values?.city);
                    checkInput(values);
                    getUserLocation()
                  }}
                  validationSchema={ValidationSchema}
                >
                  {formik => {
                    return (
                      <form onSubmit={formik.handleSubmit}>
                          <FormControl mt={6}>
                            <InputGroup>
                              <Input
                                fontSize={['13px', '13px', '18px', '18px']}
                                type="text"
                                isReadOnly={isEdit}
                                onChange={formik.handleChange('city')}
                                value={formik.values.city}
                                placeholder="Select your hometown"
                                //value={name}
                                error={formik.errors.city}
                                onBlur={formik.handleBlur('city')}
                                touched={formik.touched.city}
                                disabled={isEdit}
                              />
                            </InputGroup>
                            {formik.errors.city && formik.touched.city && (
                              <Text className="validationColor">
                                {' '}
                                {formik.errors.city}
                              </Text>
                            )}
                          </FormControl>
                          {isEdit ? (
                            <Box>
                              {' '}
                              <Link>
                                  
                                  <EditIcon
                                    onClick={() => {
                                      setIsEdit(false);
                                    }}
                                  />
                              </Link>{' '}
                            </Box>
                          ) : (
                            // <Button className="primary-btn" pb='4px' h='32px' type='submit' ml='20px' color='#fff' bg="#0554F2" ml={2}

                            // >Save Details</Button>
                            <Box className='favorite-save'>
                              {/* <Button className="primary-btn" pb='4px' h='32px' type='submit' ml='20px' color='#fff' bg="#0554F2" ml={2}
																	>Save Details</Button> */}
                              <Link>
                                <FontAwesomeIcon
                                  icon={faSave}
                                  onClick={() => formik.handleSubmit()}
                                />{' '}
                              </Link>
                              <Link>
                                <CloseIcon
                                  onClick={() => {
                                    setIsEdit(true);
                                  }}
                                />
                              </Link>
                            </Box>
                          )}
                      </form>
                    );
                  }}
                </Formik>
           

                  </Box>
                  </Box>
              </Box>
       

              <Box mb={10}>
                <Text mb={5} fontWeight="bold">Select Neighbouring Towns</Text>

                <Box className='neighbour-town'>
                  {/* {neighbouringCity} */}
                  {neighbouringCity.length == 0
                    ? ''
                    : neighbouringCity.map((obj, ind) => (
                        <Box className='neighbour-town-box'>
                          <Text>{obj.city}</Text>
                          <Box
                            className="upcomingicon1"
                            cursor="pointer"
                            onClick={() => {
                              //debugger;
                              onSelectNeighboring(obj?.zip_code);
                            }}
                          >
                            <FontAwesomeIcon
                              color={
                                obj?.USER_FAVORITE_YN === 'Y'
                                  ? '#00acfe'
                                  : '#555555'
                              }
                              m="5px"
                              icon={faHeart}
                            />{' '}
                            <Badge className="badge-like">
                              {item?.relatedMenuItemLikes?.LIKE_COUNT}
                            </Badge>
                          </Box>
                        </Box>
                      ))}
                </Box>
              </Box>
              <hr class="user-divider"></hr>
              <Box>
                <Text mt={5} fontWeight="bold">Select Cuisines</Text>
                <Box className='favorite-slide'>
                  {cuisinesList?.length > 0 ? (
                    <Quickfilter
                      cuisinesList={cuisinesList}
                      editCuisinesList={editCuisinesList}
                      filterCuisines={filterCuisines}
                      isCuisineEdit={editCuisine}
                      onSelect={onSelect}
                    />
                  ) : (
                    ''
                  )}
                </Box>
              </Box>
              <Box textAlign="center">
              <Button                  
                  
                  display={isLoading ? 'none' : 'inline-block'}
                  className="primary-btn"
                  mt={4}
                  px={4}
                  
                  onClick={() => {
                    //debugger;
                    history.push({
                      pathname: '/Home',
                    });
                    setUserFav();
                  }}
                >Go to Home  </Button>
              </Box>
            </Box>

            <Box className="fixed-position">
              {cartData.length == 0 ? (
                ''
              ) : (
                <Button
                  w="auto"
                  minWidth={{ base: '30px', md: '34px' }}
                  h={{ base: '30px', md: '34px' }}
                  p="0px"
                  background="#001d56"
                  color="#ffffff"
                  as={IconButton}
                  aria-label="Options"
                  icon={
                    <FontAwesomeIcon fontSize="22px" icon={faShoppingCart} />
                  }
                  variant="outline"
                  onClick={() => {
                    history.push({
                      pathname: '/RestaurantMenu/' + cartData[0]?.restId,
                    });
                  }}
                />
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default Favorite;
