import { Box, Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Spinner, Switch, Text, useDisclosure } from '@chakra-ui/react';
import { faFilter, faPlus, faSlidersH, faSort, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { API, graphqlOperation } from 'aws-amplify';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../../components/Header';
import OrderDetailsPopup from '../../../components/OrderDetailsPopup';
import ReportsData from '../../../components/ReportsData';
import Sidebar from '../../../components/sidebar';
import { getAllordersByRest } from '../../../graphql/queries';
import moment from 'moment-js';
import csvDownload from 'json-to-csv-export'
let filterCriteria = ''

const Reports = () => {
    const [togglebit, setTogglebit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [reports, setReports] = useState([]);
    const [repoData, setRepoData] = useState([]);
    const [allReports, setAllReports] = useState([]);    
    const [allRepo, setAllRepo] = useState([]);
    const [total, setTotal] = useState(0);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isDropDownSelected, setDropDownSelected] = useState(false);
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [switchbit, setSwitchbit] = useState(true);

    useEffect(() => {
        getReportsData()
    }, [])

    function LoadAllData() {
        console.log(switchbit)
        setSwitchbit(switchbit => !switchbit)
        console.log(switchbit)
    }
    function toggle() {
        setTogglebit(current => !current);
    }

    const getReportsData = async () => {
        setIsLoading(true);
        let reportsArray = [];
        let repoArray = [];
        let totalAmount = 0;
        const result = await API.graphql(graphqlOperation(getAllordersByRest,
            { REST_ID: localStorage.getItem('Restaurantid') }))

        console.log(result.data.getALLORDERS_BY_REST[result.data.getALLORDERS_BY_REST.length - 1])
        result?.data?.getALLORDERS_BY_REST.forEach((order) => {
            // if (order.ORDER_STATUS == 'Completed') {
                let menuItems = '';
                totalAmount = totalAmount + order.ORDER_TOTAL_FINAL_COST;
                menuItems = <>
                {
                    order?.relatedCartMenuItms?.map(menu => <p>{`${menu.MENU_IT_NAME} x ${menu.CART_ITEM_QUANTITY} : $${menu.CART_ITEM_TOTAL_PRICE?.toFixed(2)}`}</p>
                    // if (menuItems == '') {
                    //     menuItems = `${menu.MENU_IT_NAME} x ${menu.CART_ITEM_QUANTITY} : ${menu.CART_ITEM_TOTAL_PRICE}`;
                    // }
                    // else {
                    //     menuItems = `${menuItems}
                    //     ${menu.MENU_IT_NAME} x ${menu.CART_ITEM_QUANTITY} : ${menu.CART_ITEM_TOTAL_PRICE}`;
                    // }
                    )
                }
                </>;
                let details = {
                    ORDER_ID: order.ORDER_ID,
                    CUSTOMER_NAME: order?.CUSTOMER_NAME?.includes('createUSER_REST_ORDER_WKFLWInput') ? '' : order.CUSTOMER_NAME,
                    ORDER_TYPE: order?.ORDER_TYPE,
                    ORDER_DATE: order.ORDER_STATUS_DATE_TIME,
                    PAYMENT_STATUS: order?.PAYMENT_STATUS?.includes('createUSER_REST_ORDER_WKFLWInput') ? '' : order.PAYMENT_STATUS,
                    ORDER_TOTAL: order.ORDER_TOTAL_FINAL_COST,
                    ORDER_ITEMS: menuItems,
                    TAX_APPLIED: order?.TAX_APPLIED,
                    ORDER_TOTAL_COST_BFR_TAX_BFR_DISC: order?.ORDER_TOTAL_COST_BFR_TAX_BFR_DISC,
                    DISCOUNT_APPLIED: order?.DISCOUNT_APPLIED,
                    ORDER_STATUS: order?.ORDER_STATUS,
                    REWARDS_APPLIED: order?.REWARDS_APPLIED,
                };
                // let mItems= '';
                // menuItems.forEach(item => {
                //     if (mItems === '') mItems = `'${item.id}'`;
                //     else mItems = `${mItems},'${item.id}'`;
                // });
                let repo = {
                    'ORDER ID': order.ORDER_ID,
                    'ORDER DATE': order.ORDER_STATUS_DATE_TIME,
                    'CUSTOMER NAME': order?.CUSTOMER_NAME?.includes('createUSER_REST_ORDER_WKFLWInput') ? '' : order.CUSTOMER_NAME,
                    'ORDER TYPE': order?.ORDER_TYPE,
                    'ORDER STATUS': order?.ORDER_STATUS,
                    'PAYMENT MODE': order?.PAYMENT_STATUS?.includes('createUSER_REST_ORDER_WKFLWInput') ? '' : order.PAYMENT_STATUS,
                    'ORDER TOTAL': order?.ORDER_TOTAL_COST_BFR_TAX_BFR_DISC,
                    'REWARDS APPLIED': order?.REWARDS_APPLIED,
                    'TAX APPLIED': order?.TAX_APPLIED,
                    'DISCOUNT APPLIED': order?.DISCOUNT_APPLIED,
                    'FINAL TOTAL': order.ORDER_TOTAL_FINAL_COST,
                    'ORDER ITEMS':  order?.relatedCartMenuItms?.map(menu => `${menu.MENU_IT_NAME} x ${menu.CART_ITEM_QUANTITY} : $${menu.CART_ITEM_TOTAL_PRICE?.toFixed(2)}`).join(' | '),
                }
                repoArray.push(repo);
                reportsArray.push(details);
                // console.log(details);
            // }
            setReports(reportsArray)
            setAllReports(reportsArray)
            setRepoData(repoArray);
            setAllRepo(repoArray);
            setTotal(totalAmount);
        })
        setIsLoading(false)
    }

    const selectChanges = (value) => {
        if (value == '') {
            setReports(allReports);
            filterCriteria = ''
            setDropDownSelected(false);
            filterReports()
        }
        else {
            setDropDownSelected(true);
            filterCriteria = value;
            filterReports();
        }
    }

    const exportCsv = (data) => {
        const dataToConvert = {
            data: data,
            filename: 'SquashOrderReport',
            delimiter: ',',            
            headers: [
                'ORDER ID',
                'ORDER DATE',
                'CUSTOMER NAME',
                'ORDER TYPE',
                'ORDER STATUS',
                'PAYMENT MODE',
                'ORDER TOTAL',
                'REWARDS APPLIED',
                'TAX APPLIED',
                'DISCOUNT APPLIED',
                'FINAL TOTAL',
                'ORDER ITEMS',
            ]
        };
        csvDownload(dataToConvert);
    };

    const filterReports = () => {
        let today = new Date()
        let totalAmount = 0;
        let newArr = [];
        let newArr2 = [];
        if (filterCriteria == 'today') {
            newArr = allReports.filter(element =>
                moment(element.ORDER_DATE).format('YYYY-MM-DD') == moment(today).format('YYYY-MM-DD')
            );
            newArr2 = repoData.filter(element =>
                moment(element.ORDER_DATE).format('YYYY-MM-DD') == moment(today).format('YYYY-MM-DD')
            );
            setReports(newArr);
            setRepoData([...newArr2]);
        }
        else if (filterCriteria == 'thisMonth') {
            newArr = allReports.filter(element =>
                moment(element.ORDER_DATE).format('MM') == moment(today).format('MM')
            );
            setReports(newArr);
            newArr2 = repoData.filter(element =>
                moment(element.ORDER_DATE).format('MM') == moment(today).format('MM')
            );
            setRepoData(newArr2);
        }
        else if (filterCriteria == 'thisYear') {
            newArr = allReports.filter(element =>
                moment(element.ORDER_DATE).format('YYYY') == moment(today).format('YYYY')
            );
            setReports(newArr);
            newArr2 = repoData.filter(element =>
                moment(element.ORDER_DATE).format('YYYY') == moment(today).format('YYYY')
            );
            setRepoData(newArr2);
        }
        else {
            if (startDate && endDate) {
                console.log(startDate, endDate)
                newArr = allReports.filter(element =>
                (moment(element.ORDER_DATE).format('YYYY-MM-DD') >= startDate &&
                    moment(element.ORDER_DATE).format('YYYY-MM-DD') <= endDate)
                );
                setReports(newArr);
                newArr2 = repoData.filter(element =>
                    (moment(element.ORDER_DATE).format('YYYY-MM-DD') >= startDate &&
                        moment(element.ORDER_DATE).format('YYYY-MM-DD') <= endDate)
                );
                setRepoData(newArr);
            }
            else {
                newArr = [...allReports];
                setReports(newArr)
                newArr2 = [...allRepo];
                setRepoData(newArr2)
            }
        }
        newArr.forEach(order => {
            totalAmount = totalAmount + order.ORDER_TOTAL;
        })
        setTotal(totalAmount)
    }

    const setStart = (sd) => {
        setStartDate(sd);
    }

    const setEnd = (ed) => {
        setEndDate(ed);
    }


    return (
        <Box bg='#f9f9f9'>
            <Helmet>
                <title>Squash | Reports</title>
            </Helmet>
            <Header
                isAdmin={true}
                toggle={toggle} />
            <Box pt='85px' position='relative' h='100vh' overflow='hidden' d='flex'>
                <Box zIndex='9' bg='#fff' position='absolute' top='0' pt='100px' left='0' h='100%'
                    // w={togglebit ? '0px' : '200px'}
                    //  display={togglebit ? 'none' : 'block'}
                    w={{
                        base: togglebit ? '200px' : '0',
                        sm: togglebit ? '200px' : '0',
                        md: togglebit ? '200px' : '0',
                        lg: togglebit ? '0' : '200px',
                    }}
                    d={{
                        base: togglebit ? 'block' : 'none',
                        sm: togglebit ? 'block' : 'none',
                        md: togglebit ? 'block' : 'none',
                        lg: togglebit ? 'none' : 'block',
                    }}
                >
                    <Sidebar current='Reports' togglebit={togglebit} />
                </Box>
                <Box ml={(togglebit ? "0px" : { base: "0", sm: "0", md: "0", lg: '200px' })} w='100%' p='20px' overflow='auto'>
                    <Box className="card no-padding card-flex">
                        <Box className="card-header" position="relative">
                            <Box className="title-heading">
                                <Box flexDirection={'row'}>
                                    <Button onClick={onOpen}><Text color={'gray.500'}><FontAwesomeIcon icon={faSlidersH} /> FIlter</Text></Button>                                    
                                    <Button className='primary-btn' ml={2} onClick={() => exportCsv(repoData)}>Export</Button>
                                </Box>
                                <Box className="icons-wrapper specials-head">
                                    <Box>
                                        <Text fontWeight={'bold'}>
                                            Total : ${total.toFixed(2)}
                                        </Text>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box className="card-content table-padding specialbox">
                            <div style={{marginBottom: 5}}>
                                {
                                    reports.length == 0 ?
                                        null
                                        :
                                        <FormControl display="flex" justifyContent='flex-end' >
                                            <FormLabel htmlFor="status-switch" mb="0">
                                                Show Menu Items
                                            </FormLabel>
                                            <Switch id="status-switch" isChecked={switchbit} onChange={() => LoadAllData()} />
                                        </FormControl>
                                }
                            </div>
                            <Box className="scrollRepo">
                                {isLoading ?
                                    (<Box className="rspinbox" > <Spinner
                                        thickness="4px"
                                        speed="0.65s"
                                        emptyColor="gray.200"
                                        color="blue.500"
                                        size="xl"
                                    /></Box >)
                                    :
                                    reports.length != 0 ?
                                        <ReportsData data={reports} showMenuItems={switchbit} />
                                        : <Box className="rspinbox"> <Text>No Data Available</Text></Box >}
                            </Box >
                        </Box >
                    </Box >
                </Box>
            </Box>


            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Filter Data</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>Start Time : </Text>
                        <Input type="date" width={'50%'} disabled={isDropDownSelected} onChange={e => setStart(e.target.value)} value={startDate} />
                        <Text>End Date : </Text>
                        <Input type="date" width={'50%'} disabled={isDropDownSelected} onChange={e => setEnd(e.target.value)} value={endDate} />
                        <Button colorScheme='green' onClick={filterReports} disabled={isDropDownSelected} marginLeft={10}>
                            Apply
                        </Button>
                        <Select placeholder='Select Filter Options' marginTop={5}
                            onChange={e => selectChanges(e.target.value)} value={filterCriteria}>
                            {/* onLoadStart={setDropDownSelected(false)}> */}
                            <option value='today'>Today</option>
                            <option value='thisMonth'>This Month</option>
                            <option value='thisYear'>This year</option>
                        </Select>
                    </ModalBody>

                    <ModalFooter>
                        <Button colorScheme='blue' onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>


        </Box>

    )
}

export default Reports