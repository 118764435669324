import {
  Box,
  Button,
  Modal,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  ModalBody,
  ModalContent,
  FormLabel,
  Input,
  FormControl,
  Select,
  HStack,
  Text,
  Link

} from "@chakra-ui/react";
import { Multiselect } from 'multiselect-react-dropdown';
import React, { useRef, useState, useEffect } from "react";
import * as yup from 'yup';
import { Formik, Form, Field, FieldArray, useFormik } from 'formik';
import { createRestPollMaster, createSpecialMenuMaster as CreateSpecialMenuMaster } from '../graphql/mutations'
import { Auth, Hub, API, graphqlOperation } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { useToast } from "@chakra-ui/react"
import { date } from "yup/lib/locale";
import { AddIcon, CloseIcon } from '@chakra-ui/icons'

let ValidationSchema = yup.object().shape({
  pollname: yup
    .string()
    .max(50)
    .required('poll name is required'),
  pollquestion: yup
    .string()
    .max(200)
    .required('Poll question is required'),
  choice1: yup
    .string(),
  choice2: yup
    .string(),
  choice3: yup
    .string(),
  choice4: yup
    .string(),

  startdate: yup
    .date()
    .required('Start date is required'),
  enddate: yup
    .date()
    .required('End date is required'),

  status: yup
    .string()
  //.required('Status is required'),


})


function AddPollsPopup(props) {


  const [showchoice3, setshowchoice3] = useState(false);
  const [showchoice4, setshowchoice4] = useState(false);


  useEffect(() => {
    setshowchoice3(!props.open)
    setshowchoice4(!props.open)
  }, [props.open]);

  function formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }




  const initialRef = React.useRef()
  const finalRef = React.useRef()

  return (
    <>
      <Modal
        size={'sx', 'md', 'xl', 'xl'}
        isOpen={props.open}
        onClose={props.close}
        scrollBehavior="inside"
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
      // key={["xs", "sm", "md", "lg", "xl", "full"]}
      >

        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{props.object ? 'Update poll' : 'Create Poll'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Formik
              initialValues={{
                pollname: props.object?.POLL_NAME,
                pollquestion: props.object?.POLL_QUESTION,
                choice1: props.object?.relatedPollChoices[0].POLL_CHOICE_VAL,
                choice2: props.object?.relatedPollChoices[1].POLL_CHOICE_VAL,
                choice3: props.object?.relatedPollChoices[2].POLL_CHOICE_VAL,
                choice4: props.object?.relatedPollChoices[3].POLL_CHOICE_VAL,

                startdate: props.object?.POLL_START_DATE_TIME ? formatDate(new Date(props.object?.POLL_START_DATE_TIME * 1000)) : formatDate(new Date()),
                enddate: props.object?.POLL_END_DATE_TIME ? formatDate(new Date(props.object?.POLL_END_DATE_TIME * 1000)) : formatDate(new Date().setDate(new Date().getDate() + 3)),


                status: props.object?.POLL_STATUS == 'N' ? 'CLOSED' : 'ACTIVE'
              }}

              validationSchema={ValidationSchema}
              onSubmit={(value, { setSubmitting }) => {
                const inputData = {

                  REST_ID: localStorage.getItem('Restaurantid'),
                  POLL_QUESTION: value.pollquestion,
                  POLL_NAME: value.pollname,
                  POLL_END_DATE_TIME: Math.round(new Date(value.enddate).valueOf() / 1000),
                  POLL_START_DATE_TIME: Math.round(new Date(value.startdate).valueOf() / 1000),
                  POLL_CHOICE_1: value.choice1,
                  POLL_CHOICE_2: value.choice2,
                  POLL_CHOICE_3: value.choice3 ? value.choice3 : '',
                  POLL_CHOICE_4: value.choice4 ? value.choice4 : '',
                  POLL_CHOICE_ID_1: "1",
                  POLL_CHOICE_ID_2: "2",
                  POLL_CHOICE_ID_3: "3",
                  POLL_CHOICE_ID_4: "4",
                  POLL_STATUS: value.status


                }
                // createRestPoll(value)
                props.savepoll(inputData)
                setSubmitting(false)
              }}
            >
              {(formik) => {

                return (
                  <form onSubmit={formik.handleSubmit} >
                    <FormControl>
                      <FormLabel>Poll Name</FormLabel>
                      <Input

                        type='text'
                        onChange={formik.handleChange('pollname')}
                        onBlur={formik.handleBlur('pollname')}
                        value={formik.values.pollname}
                        error={formik.errors.pollname}
                        touched={formik.touched.pollname}

                        ref={initialRef}
                      //  ref={formRef}
                      />
                      {formik.errors.pollname && formik.touched.pollname && <Text className="validationColor">{" "} {formik.errors.pollname}</Text>}
                    </FormControl>


                    <FormControl mt={4}>
                      <FormLabel>Poll Question</FormLabel>
                      <Input
                        type='text'
                        onChange={formik.handleChange('pollquestion')}
                        onBlur={formik.handleBlur('pollquestion')}
                        value={formik.values.pollquestion}
                        error={formik.errors.pollquestion}
                        touched={formik.touched.pollquestion}
                      />
                      {formik.errors.pollquestion && formik.touched.pollquestion && <Text className="validationColor">{" "} {formik.errors.pollquestion}</Text>}
                    </FormControl>


                    <FormControl mt={4}>
                      <FormLabel >Choice 1</FormLabel>
                      <Input
                        type='text'
                        onChange={formik.handleChange('choice1')}
                        onBlur={formik.handleBlur('choice1')}
                        value={formik.values.choice1}
                        error={formik.errors.choice1}
                        touched={formik.touched.choice1}
                      />
                      {formik.errors.choice1 && formik.touched.choice1 && <Text className="validationColor">{" "} {formik.errors.choice1}</Text>}
                    </FormControl>
                    <FormControl mt={4}>
                      <FormLabel >Choice 2</FormLabel>
                      <Input
                        type='text'
                        onChange={formik.handleChange('choice2')}
                        onBlur={formik.handleBlur('choice2')}
                        value={formik.values.choice2}
                        error={formik.errors.choice2}
                        touched={formik.touched.choice2}
                      />


                      {formik.errors.choice2 && formik.touched.choice2 && <Text className="validationColor">{" "} {formik.errors.choice2}</Text>}
                    </FormControl>
                    <Link d={showchoice3 ? "none" : 'block'} onClick={() => { setshowchoice3(true) }} > <AddIcon pt='5px' float='right' w={6} h={6} /></Link>

                    <FormControl mt={4} d={(showchoice3 || (props.object?.relatedPollChoices[2].POLL_CHOICE_VAL != '' && props.object?.relatedPollChoices[2].POLL_CHOICE_VAL != null) ? "block" : 'none')}>
                      <FormLabel >Choice 3</FormLabel>
                      <Box d='flex'>
                        <Input
                          type='text'
                          onChange={formik.handleChange('choice3')}
                          onBlur={formik.handleBlur('choice3')}
                          value={formik.values.choice3}
                          error={formik.errors.choice3}
                          touched={formik.touched.choice3}
                        />
                        <Link p='5px'><CloseIcon color="red.500" className="ticonred" onClick={() => { setshowchoice3(false) }} /></Link>
                      </Box>
                      {formik.errors.choice3 && formik.touched.choice3 && <Text className="validationColor">{" "} {formik.errors.choice3}</Text>}
                      <Link d={showchoice4 ? "none" : 'block'} onClick={() => { setshowchoice4(true) }} > <AddIcon pt='5px' float='right' w={6} h={6} /></Link>

                    </FormControl>


                    <FormControl mt={4} d={(showchoice4 || (props.object?.relatedPollChoices[3].POLL_CHOICE_VAL != '' && props.object?.relatedPollChoices[3].POLL_CHOICE_VAL != null) ? "block" : 'none')} >
                      <FormLabel >Choice 4</FormLabel>
                      <Box d='flex'>
                        <Input
                          type='text'
                          onChange={formik.handleChange('choice4')}
                          onBlur={formik.handleBlur('choice4')}
                          value={formik.values.choice4}
                          error={formik.errors.choice4}
                          touched={formik.touched.choice4}
                        />
                        <Link p='5px'><CloseIcon color="red.500" className="ticonred" onClick={() => { setshowchoice4(false) }} /></Link>
                      </Box>
                      {formik.errors.choice4 && formik.touched.choice4 && <Text className="validationColor">{" "} {formik.errors.choice4}</Text>}
                    </FormControl>








                    <Box display='flex' className='mspecialbox' >
                      <FormControl mt={4} pr='10px'>
                        <FormLabel >Start Date</FormLabel>
                        <Input
                          type="date"
                          onChange={formik.handleChange('startdate')}
                          onBlur={formik.handleBlur('startdate')}
                          value={formik.values.startdate}
                          error={formik.errors.startdate}
                          touched={formik.touched.startdate}
                        />
                        {formik.errors.startdate && formik.touched.startdate && <Text className="validationColor">{" "} {formik.errors.startdate}</Text>}
                      </FormControl>


                      <FormControl mt={4}>
                        <FormLabel >End Date</FormLabel>
                        <Input
                          type="date"
                          onChange={formik.handleChange('enddate')}
                          onBlur={formik.handleBlur('enddate')}
                          value={formik.values.enddate}
                          error={formik.errors.enddate}
                          touched={formik.touched.enddate}
                        />
                        {formik.errors.enddate && formik.touched.enddate && <Text className="validationColor">{" "} {formik.errors.enddate}</Text>}
                      </FormControl>

                    </Box>




                    <FormControl mt={4} >
                      <FormLabel>Status</FormLabel>

                      <Select
                        onChange={formik.handleChange('status')}
                        onBlur={formik.handleBlur('status')}
                        value={formik.values.status}
                        error={formik.errors.status}
                        touched={formik.touched.status}

                      >

                        <option value="Y">Open</option>
                        <option value="N">Close</option>


                      </Select>
                      {formik.errors.status && formik.touched.status && <Text className="validationColor">{" "} {formik.errors.status}</Text>}
                    </FormControl>


                    <Box className="popup-buttons">
                      <Button className="secondary-btn" mr={2} onClick={props.close}>Cancel</Button>
                      <Button className="primary-btn" type='submit' ml={2}   >
                        {props.object ? 'Update' : 'Create'}
                      </Button>


                    </Box>
                  </form>
                );
              }}
            </Formik>
          </ModalBody>
        </ModalContent>

      </Modal>

    </>
  )
}

export default AddPollsPopup;
