import {
  Box,
  Container,
  Button,
  Grid,
  GridItem,
  Image,
  Flex,
  Text,
  Badge,
} from '@chakra-ui/react';
import {
  faShoppingCart,
  faSave,
  faHeart,
} from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import 'react-multi-carousel/lib/styles.css';

import Slider from 'react-slick';
import './style.css';

const FavoriteCuisineFilter = props => {
  useEffect(() => {}, []);

  const ImagesFile = {
    cuisine_images: {
      c1: require('../assets/images/cuisineImagesNew/American.jpg').default,
      c2: require('../assets/images/cuisineImagesNew/Indian2.jpg').default,
      c3: require('../assets/images/cuisineImagesNew/Mexican.jpg').default,
      c4: require('../assets/images/cuisineImagesNew/Italian.jpg').default,
      c5: require('../assets/images/cuisineImagesNew/Meditaranian.jpg').default,
      c6: require('../assets/images/cuisineImagesNew/Greek.jpg').default,
      c7: require('../assets/images/cuisineImagesNew/Thai.jpg').default,
      c8: require('../assets/images/cuisineImagesNew/Chinese.jpg').default,
      c9: require('../assets/images/cuisineImagesNew/Japanese.jpg').default,
      c10: require('../assets/images/cuisineImagesNew/French.jpg').default,
      c11: require('../assets/images/cuisineImagesNew/Turkish.jpg').default,
      c12: require('../assets/images/cuisineImagesNew/persian.jpg').default,
      c13: require('../assets/images/cuisineImagesNew/Pizza.jpg').default,
      c14: require('../assets/images/cuisineImagesNew/Vietnamese.jpg').default,
      c15: require('../assets/images/cuisineImagesNew/Korean.jpg').default,
      c16: require('../assets/images/cuisineImagesNew/Deli.jpg').default,
      c17: require('../assets/images/cuisineImagesNew/fruits-smoothie.jpg')
        .default,
      c18: require('../assets/images/cuisineImagesNew/pastry-icecream.jpg')
        .default,
      c19: require('../assets/images/cuisineImagesNew/Coffee.jpg').default,
    },
  };
  function imageSelect(id) {
    ////debugger;

    const cuisineArray = {
      C1: ImagesFile.cuisine_images.c1,
      C2: ImagesFile.cuisine_images.c2,
      C3: ImagesFile.cuisine_images.c3,
      C4: ImagesFile.cuisine_images.c4,
      C5: ImagesFile.cuisine_images.c5,
      C6: ImagesFile.cuisine_images.c6,
      C7: ImagesFile.cuisine_images.c7,
      C8: ImagesFile.cuisine_images.c8,
      C9: ImagesFile.cuisine_images.c9,
      C10: ImagesFile.cuisine_images.c10,
      C11: ImagesFile.cuisine_images.c11,
      C12: ImagesFile.cuisine_images.c12,
      C13: ImagesFile.cuisine_images.c13,
      C14: ImagesFile.cuisine_images.c14,
      C15: ImagesFile.cuisine_images.c15,
      C16: ImagesFile.cuisine_images.c16,
      C17: ImagesFile.cuisine_images.c17,
      C18: ImagesFile.cuisine_images.c18,
      C19: ImagesFile.cuisine_images.c19,
    };
    return cuisineArray[id];
  }

  function ButtonOne() {
    return (
      <button
        // onClick={handleClick}
        aria-label="Go to next slide"
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
      />
    );
  }
  if (props?.isCuisineEdit) {
    //debugger;
    for (var i = 0; i < props?.editCuisinesList.length; i++) {
      let index = props?.cuisinesList.findIndex(
        (itm) => itm.CUISINE_ID === props?.editCuisinesList[i].CUISINE_ID
      );
      //let tempArray = neighbouringCity;
      if (index >= 0) {
        //  if(neighbouringCity[index].USER_FAVORITE_YN === "Y") {
          props.cuisinesList[index].USER_FAVORITE_YN = "Y";
        // }
      }
    }
  }

  const settings = {
    // centerMode: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          infinite: false,
          //centerMode: true,
          centerPadding: '20px',
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          infinite: false,
          // centerMode: true,
          centerPadding: '20px',
          slidesToShow: 2,
        },
      },
    ],
  };
  

  return (
    <Box className="filter-slide">
      <Slider {...settings}>
        {props.cuisinesList.map(item => (
          <Box
            className={
              props.filterCuisines.includes(item.CUISINE_ID)
                ? 'carousel-item active-filter'
                : 'carousel-item'
            }
            cursor="pointer"
          >
            <Image
              src={
                item.CUISINE_ID == 'C99' ||
                item.CUISINE_ID == 'C4,C1' ||
                item.CUISINE_ID == 'C8,C7'
                  ? require('../assets/images/cuisineImagesNew/unknown.jpg')
                      .default
                  : imageSelect(item.CUISINE_ID)
              }
              alt="Segun Adebayo"
            />
            <Text fontSize="13px" textAlign="center">
              {item.CUISINE_TYPE_NAME}
            </Text>
            <Box
              className="upcomingicon1"
              cursor="pointer"
              onClick={() => {
                //debugger;
                  // props.onUpcomingSpecialHeart(item.MENU_IT_ID, item.USER_LIKE_YN, item.USER_BOOKMARK_YN, item.relatedMenuItemLikes.LIKE_COUNT)
                  props?.onSelect(item?.CUISINE_ID,item?.CUISINE_TYPE_NAME,item?.USER_FAVORITE_YN);
              }}
            >
              <FontAwesomeIcon
                color={item?.USER_FAVORITE_YN == 'Y' ? '#00acfe' : '#555555'}
                m="5px"
                icon={faHeart}
              />{' '}
             
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};
export default FavoriteCuisineFilter;
