import {
    Box,
    ListItem,
    Text,
    List
} from "@chakra-ui/react"
import {
    BrowserRouter as Router,
    Link,
} from 'react-router-dom';
import React, { useRef, useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCity, faHome, faSearch,faUser,faBell  } from '@fortawesome/free-solid-svg-icons'
import jwt_decode from "jwt-decode";
import { useHistory } from 'react-router-dom';
const UserSidebar = ({ current }) => {

    const [currentmenu, setcurrentmenu] = useState(current);
    const history = useHistory();
    // useEffect(() => {

    //   //    localStorage.setItem('Currentpage',currentmenu);

    //   }, [currentmenu]);
    return (
        <Box className='scroller-sidebar menu-bar' h='100%' overflow='auto' padding='0 20px 20px 20px' >
            <List spacing={6} h='100%'>
                <ListItem >
                    <Link color="teal.500" to="/Home" >
                        <Box display='flex' alignItems='center'>

                            <FontAwesomeIcon fontSize='22px' icon={faHome} />
                            <Text fontSize='xl' pl="10px">Home</Text>

                        </Box>
                    </Link>
                </ListItem>

                <ListItem >
                    <Link color="teal.500" to="/Towns" >
                        <Box display='flex' alignItems='center'>
                     
                            <FontAwesomeIcon fontSize='22px' icon={faCity} />
                            <Text fontSize='xl' pl="10px">Towns</Text>

                        </Box>
                    </Link>
                </ListItem>

                <ListItem >
                    <Link color="teal.500" to="/Search" >
                        <Box display='flex' alignItems='center'>
                     
                            <FontAwesomeIcon fontSize='22px' icon={faSearch} />
                            <Text fontSize='xl' pl="10px">Search</Text>

                        </Box>
                    </Link>
                </ListItem>

                <ListItem >
                    <Link color="teal.500" to="/Notification" >
                        <Box display='flex' alignItems='center'>
                     
                            <FontAwesomeIcon fontSize='22px' icon={faBell} />
                            <Text fontSize='xl' pl="10px">Notifications</Text>

                        </Box>
                    </Link>
                </ListItem>

                <ListItem >
                    <Link color="teal.500" to="#" >
                        <Box display='flex' alignItems='center'>
                     
                            <FontAwesomeIcon fontSize='22px' icon={faUser} />
                            <Text fontSize='xl' pl="10px">User Account</Text>

                        </Box>
                    </Link>
                </ListItem>
            </List>

        </Box >
    );

}
export default UserSidebar;