
import {
	Box,
	Input,
	Button,
	Text,
	Checkbox,
	Modal,
	ModalHeader,
	ModalOverlay,
	ModalCloseButton,
	ModalBody,
	ModalContent,
	ModalFooter,
	List,
	ListItem,
	ListIcon,
	Heading,
	UnorderedList,
	Radio,
	RadioGroup,
	Link,
	Image

} from "@chakra-ui/react";
import React, { useRef, useState, useEffect } from "react";
import { EditIcon, AddIcon, MinusIcon, StarIcon } from '@chakra-ui/icons'
import Cookies from 'js-cookie'
import { useToast, useDisclosure } from "@chakra-ui/react";
import AddtocartconfirmPopup from './AddtocartconfirmPopup'
import { useHistory } from 'react-router-dom';
import moment from 'moment';
function CartPopup(props) {

	let val = 1;
	const [CustomizationList, SetCustomizationList] = useState([])
	const [Price, setPrice] = useState(0);
	let [quantity, setquantity] = useState(1);
	let [sum, setSum] = useState(0);
	const [radiocheckedvalue, Setradiocheckedvalue] = useState([])
	const [checkboxcurrentlist, Setcheckboxcurrentlist] = useState([])
	const { isOpen: alertisOpen, onOpen: alertonOpen, onClose: alertonClose } = useDisclosure()
	const history = useHistory();

	useEffect(() => {
		//////debugger;
		setstates()
	}, []);


	function setstates() {
		if (props.editcartitem?.quantity) {
			//////debugger;
			setquantity(props.editcartitem?.quantity)
			const radiocurrentlist = props.editcartitem.customizationdata?.filter((ob) => ob?.MUST_CHOOSE_FLAG == true)
			const listcheck = props.editcartitem.customizationdata?.filter((ob) => ob?.checkdata)
			Setcheckboxcurrentlist(listcheck)

			const radioGroup = props.menuChoice?.filter((ob) => ob.data[0].MUST_CHOOSE_FLAG == true)
			const checkGroup = props.menuChoice?.filter((ob) => ob.data[0].MUST_CHOOSE_FLAG == false)



			const list = [];
			const list2 = []
			radioGroup?.map((obj) => {
				let ind = 0;
				radiocurrentlist?.map((current) => {
					if (obj.CH_DESC == current.CH_DESC) {
						ind = obj.data.indexOf(obj.data?.filter((ob) => ob.CH_ITEM_DESC == current.CH_ITEM_DESC)[0])
						list.push({ CH_DESC: obj.CH_DESC, RadioValue: ind })
						list2.push({ ...(obj.data[ind]), CH_DESC: obj.CH_DESC, CH_ID: obj.CH_ID })
					}

				})


			})
			Setradiocheckedvalue(list)
			list2.push.apply(list2, listcheck)
			//////debugger;
			SetCustomizationList(list2)


		}
		else {
			const radioGroup = props.menuChoice?.filter((ob) => ob.data[0].MUST_CHOOSE_FLAG == true)
			//CH_ID
			//	item.data.indexOf(item.data.filter((ob)=>ob.CH_DEFAULT==true)[0])
			const list = [];
			const list2 = []
			radioGroup?.map((obj) => {
				list.push({ CH_DESC: obj.CH_DESC, RadioValue: obj.data.indexOf(obj.data?.filter((ob) => ob.CH_DEFAULT == true)[0]) })
				list2.push({ ...(obj.data?.filter((ob) => ob.CH_DEFAULT == true)[0]), CH_DESC: obj.CH_DESC, CH_ID: obj.CH_ID })
			})
			Setradiocheckedvalue(list)
			//////debugger;
			SetCustomizationList(list2)


		}
	}

	useEffect(() => {
		let sm = 0;
		console.log(props?.editcartitem?.index)
		if (props.open === true) {
			if (props?.editcartitem?.index != undefined)
				sm = Number(props?.editcartitem?.totalprice)
			else {
				CustomizationList?.map((obj) => {
					if (obj.checkdata) {
						obj.checkdata?.map((x) => {
							sm = sm + x.CH_PRICE
						})
					}
					else {
						if (obj.BASE_MODIFIER_IND == false) {
							sm = props.menu.MENU_IT_PRICE + obj.CH_PRICE;
						}
						else {
							sm = sm + obj.CH_PRICE
						}
					}
				})
			}
			if (sm != 0)
				setPrice(sm)
			else
				setPrice(props?.menu?.MENU_IT_PRICE)
			console.log(sm)
		}
		else {
			setPrice(0)
			setquantity(1)
		}

	}, [CustomizationList, quantity, props.open]);

	function handlesubmint(e) {
		e.preventDefault();
	}

	function confirmcartclear(flag) {

		if (flag == true) {
			let today = moment(new Date(), "YYYY-MM-DD");
			let formattedtoday = moment(today, "YYYY-MM-DD").format("YYYY-MM-DD");
			props.addtocart({
				cart_created_date: formattedtoday,
				clearcart: true,
				customizationdata: CustomizationList,
				menu: props.menu,
				totalprice: Price.toFixed(2),
				quantity: quantity,
				cartID: props.editcartitem?.cartID,
				restId: localStorage.getItem('restID')
			});
			props.close()

		}

	}

	return (
		<>


			<Modal blockScrollOnMount={false}

				isOpen={props.open}
				onClose={() => { props.close() }}
				size={'sx', 'md', 'sm', 'sm'}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>{props.menu?.MENU_IT_NAME}</ModalHeader>
					<ModalCloseButton mt='10px' fontSize='17px' color='blue.500' top='0px' className="popup-btn" />
					<ModalBody p='20px'>
						<Box d='flex'>
							<AddtocartconfirmPopup confirmcartclear={confirmcartclear} fromcartpopup={true} onClose={alertonClose} isOpen={alertisOpen} />
							<Image cursor='pointer' onClick={console.log('clicked')}

								// src={require('../assets/images/cuisineImagesNew/DemoRes.jpg').default}
								src={props.menu?.MENU_IT_PIC_LOCATION == '' || props.menu?.MENU_IT_PIC_LOCATION == null ? require('../assets/images/cuisineImagesNew/DemoDish.jpg').default : props.menu?.MENU_IT_PIC_LOCATION}

								alt="Segun Adebayo" />


						</Box>
						<Text fontSize='13px' pt='4px' fontWeight="bold">{props.menu?.MENU_IT_DESC}</Text>
						<form onSubmit={handlesubmint}>
							<Box>
								{props?.menuChoice.length == 1 ? null : (
									<Box overflow='scroll'>

										{props.menuChoice?.map((item) =>

											<Box p='15px'>

												{
													item.data[0]?.MULTI_SELECT_IND ? <Text fontWeight='bold' fontSize='14px'>{item.CH_DESC}</Text> :
														<Box d='flex' pt='10px' pl='10px'>
															<Text fontWeight='bold' fontSize='14px'>{item.CH_DESC}</Text>
															<Text fontSize='14px' pl='5px' textColor='red.500'> ( Must choose )
															</Text>
														</Box>

												}
												{


													item.data?.map((obj) =>
														obj.MULTI_SELECT_IND ?
															<Box d="flex" pt='10px' pl='10px'>
																<Checkbox
																	isChecked={CustomizationList?.filter((ob) => ob?.checkdata).filter((ob) => ob.CH_DESC == item.CH_DESC)[0]?.checkdata.findIndex(x => x.CH_ITEM_ID === obj.CH_ITEM_ID) >= 0 ? true : false}

																	onChange={e => {
																		//	console.log(checkboxcurrentlist.filter((ob)=>ob.CH_DESC==item.CH_DESC)[0]?.checkdata.findIndex(x => x.CH_ITEM_ID === obj.CH_DESC));
																		const a = checkboxcurrentlist?.filter((ob) => ob.CH_DESC == item.CH_DESC)[0]?.checkdata.findIndex(x => x.CH_ITEM_ID === obj.CH_ITEM_ID);
																		//const b=a?.checkdata.findIndex(x => x.CH_ITEM_ID === obj.CH_ITEM_ID);
																		const c = 10;

																		let list = [...CustomizationList]
																		let flag = true;
																		if (e.target.checked) {

																			CustomizationList?.map((ob, index) => {
																				if (ob.CH_DESC == item.CH_DESC) {
																					flag = false;
																					list[index].checkdata.push(obj)
																				}


																			})

																			if (flag)
																				list.push({ checkdata: [obj], CH_DESC: item.CH_DESC, CH_ID: item.CH_ID });
																			//  console.log(JSON.stringify(formik.values.daysId, null, 2))
																			const w = 10;
																			//	SetCustomizationList(list)
																		}
																		else {

																			const outerindex = list.findIndex(x => x.CH_DESC === item.CH_DESC);
																			const innerindex = list[outerindex]?.checkdata.findIndex(x => x.CH_ITEM_ID === obj.CH_ITEM_ID)
																			list[outerindex].checkdata.splice(innerindex, 1)
																			if (list[outerindex].checkdata.length == 0) {
																				list.splice(outerindex, 1)

																			}
																			// const idx = list.findIndex(x => x.CH_ITEM_ID === obj.CH_ITEM_ID);
																			// list.splice(idx, 1);
																		}
																		//list={...CustomizationList}
																		//////debugger;
																		SetCustomizationList(list)
																	}}
																	pr='10px'  ></Checkbox>
																<Text flex='1' fontSize='12px' fontWeight='bold'>{obj.CH_ITEM_DESC}</Text>
																<Text fontSize='12px' fontWeight='bold'>${obj.CH_PRICE}</Text>
															</Box> : ''

													)}


												{<Box d="flex" pt='7px' pl='10px'>
													<RadioGroup value={radiocheckedvalue?.filter((x) => x.CH_DESC == item.CH_DESC)[0]?.RadioValue}
														onChange={(ind) => {
															const list = []
															radiocheckedvalue?.map((ob) => {

																if (ob.CH_DESC == item.CH_DESC) {
																	list.push({ CH_DESC: ob.CH_DESC, RadioValue: parseInt(ind) })

																}
																else {
																	list.push(ob)

																}

															})
															Setradiocheckedvalue(list)

														}}

														w='100%'>
														{
															item.data?.map((obj, index) =>
																!obj.MULTI_SELECT_IND ?
																	<Box d='flex' pt='10px' pl='10px'>
																		<Radio

																			onChange={e => {
																				let list = [...CustomizationList]

																				if (e.target.checked) {
																					const idx = list.findIndex(x => x.CH_ID === item.CH_ID);
																					if (idx >= 0) {
																						list.splice(idx, 1);
																						list.push({ ...obj, CH_DESC: item.CH_DESC, CH_ID: item.CH_ID });

																					}
																					else {
																						list.push({ ...obj, CH_DESC: item.CH_DESC, CH_ID: item.CH_ID });

																					}


																					//  console.log(JSON.stringify(formik.values.daysId, null, 2))
																				}
																				else {
																					const idx = list.findIndex(x => x.CH_ITEM_ID === obj.CH_ITEM_ID);
																					list.splice(idx, 1);
																				}
																				//////debugger;
																				SetCustomizationList(list)
																			}}
																			value={index} pr='10px' ></Radio>
																		<Text flex='1' fontSize='12px' fontWeight='bold'>{obj.CH_ITEM_DESC}</Text>
																		<Text fontSize='12px' fontWeight='bold'>${obj.CH_PRICE}</Text>
																	</Box>


																	: ''
															)
														}
													</RadioGroup>
												</Box>
												}
											</Box>



										)

										}
									</Box>
								)}
							</Box>
							<Box d='flex' justifyContent='center'>
								<Box className='cartbtn'>
									<Link ><MinusIcon
										onClick={() => { if (quantity > 1) setquantity(--quantity) }}
									/></Link>
								</Box>
								<Text m='10px'>{quantity}</Text>
								<Box className='cartbtn'>
									<Link ><AddIcon
										onClick={() => {
											if (quantity >= 1 && quantity < 100) setquantity(++quantity)


										}}
									/></Link>
								</Box>



							</Box>
							<Button type='submit' w='100%' colorScheme="blue" mr={3}
								onClick={() => {
									console.log('.......................', props)
									if (props.fromsearch === true) {
										//debugger;
										let today = moment(new Date(), "YYYY-MM-DD");
										let formattedtoday = moment(today, "YYYY-MM-DD").format("YYYY-MM-DD");
										var specialitem = JSON.stringify({
											cart_created_date: formattedtoday,
											customizationdata: CustomizationList,
											menu: props.menu,
											totalprice: Price.toFixed(2),
											quantity: quantity,
											cartID: props.editcartitem?.cartID,
											restId: props?.menu?.REST_ID
											// restId: localStorage.getItem('restID') 
										});
										localStorage.setItem('specialItem', specialitem);
										history.push(
											{
												//pathname: `/Admin/${localStorage.getItem('Currentpage')}`,
												// pathname: '/RestaurantMenu/' + localStorage.getItem('restID'),
												pathname: '/RestaurantMenu/' + props?.menu?.REST_ID,
												prevouspage: 'searchresult',
												quantity: quantity
												//  state: { email:props.userinfo?.email, password: props.userinfo?.pass }
											}
										);
									}
									else {
										//debugger;
										// var json_cartitems = Cookies.get('cartitems')
										let items = null;
										let lastIndex = 0;
										// if (json_cartitems) {
										// 	items = JSON.parse(json_cartitems);
										// }
										items = props.cartData
										if (items && items?.length > 0) {
											console.log('inside if length > 0', items?.length)
											if (items[0].restId == localStorage.getItem('restID')) {
												items.forEach(element => {
													if (element.index >= lastIndex)
														lastIndex = element.index
												})
												let today = moment(new Date(), "YYYY-MM-DD");
												let formattedtoday = moment(today, "YYYY-MM-DD").format("YYYY-MM-DD");
												props.addtocart({
													cart_created_date: formattedtoday,
													customizationdata: CustomizationList,
													menu: props.menu,
													totalprice: Price.toFixed(2),
													quantity: quantity,
													cartID: props.editcartitem?.cartID,
													restId: localStorage.getItem('restID'),
													index: lastIndex + 1
												});
												props.close()
											}
											else {
												alertonOpen();
											}
											const a = 10;
										}
										else {
											//debugger
											let today = moment(new Date(), "YYYY-MM-DD");
											let formattedtoday = moment(today, "YYYY-MM-DD").format("YYYY-MM-DD");
											props.addtocart({
												cart_created_date: formattedtoday,
												customizationdata: CustomizationList,
												menu: props.menu,
												totalprice: Price.toFixed(2),
												quantity: quantity,
												cartID: props.editcartitem?.cartID,
												restId: localStorage.getItem('restID'),
												index: 1
											});
											props.close()
											// history.push(
											// 	{
											// 		//pathname: `/Admin/${localStorage.getItem('Currentpage')}`,
											// 		pathname: '/RestaurantMenu/' + props.menuChoice[0].REST_ID,
											// 	//	prevouspage: 'Home',
											// 	//	quantity: quantity
											// 		//  state: { email:props.userinfo?.email, password: props.userinfo?.pass }
											// 	}
											// );
										}
									}

								}
								}
							><Box d='flex' justifyContent='space-between' m='15px'>
									<Text pr='120px' >${(Price * quantity).toFixed(2)}</Text>  <Text>{props.editcartitem?.index ? 'Update' : 'Add To Order'}</Text>
								</Box>
							</Button>
						</form>
					</ModalBody>


				</ModalContent>
			</Modal>
		</>
	)
}
export default CartPopup;