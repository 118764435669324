import {
  Box,
  Image,
  H1,
  Text,
  Button,
  FormLabel,
  FormControl,
  ButtonGroup,
  Flex,
  IconButton,
  Link,
  Input,
  Stack,
  InputGroup,
  Spinner,
  Heading,
} from '@chakra-ui/react';
import React, { useRef, useState, useEffect } from 'react';
import { useEditableControls } from '@chakra-ui/react';
import { Storage, Auth, Hub, API, graphqlOperation } from 'aws-amplify';

import { Helmet } from 'react-helmet';
import { useToast, useDisclosure } from '@chakra-ui/react';
import Sidebar from '../../../components/sidebar';
import Header from '../../../components/Header';
import ImageRestaurant from '../../../assets/images/Restaurant.jpg';
import HoursPopup from '../../../components/HoursPopup';
import ReviewPopup from '../../../components/ReviewPopup';
import { CloseIcon, CheckIcon, EditIcon } from '@chakra-ui/icons';
import ReactStars from 'react-rating-stars-component';
import { v4 as uuidv4 } from 'uuid';
import { Formik } from 'formik';
import * as yup from 'yup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHotel,
  faMapMarkerAlt,
  faPhoneAlt,
  faGlobe,
  faMapMarked,
  faCalendarAlt,
  faSave,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import StarRatings from 'react-star-ratings';

import { updateRestMaster as UpdateRestMaster } from '../../../graphql/mutations';
import { getRestMaster as GetRestMaster } from '../../../graphql/queries';
//import EditableControl from '../../../components/EditableControl'
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import config from '../../../aws-exports';
const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket,
} = config;

let ValidationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  address: yup.string().required('Address is required'),
  // .required('price is required'),
  city: yup.string().required('City is required'),
  phone: yup.string().required('phone is required'),
  website: yup.string(),
  photo: yup.string(),
  //.required('max is required'),
});

function EditableControls() {
  const {
    isEditing,
    getSubmitButtonProps,
    getCancelButtonProps,
    getEditButtonProps,
  } = useEditableControls();

  return isEditing ? (
    <ButtonGroup justifyContent="center" size="sm">
      <IconButton icon={<CheckIcon />} {...getSubmitButtonProps()} />
      <IconButton
        className="resbtn2"
        icon={<CloseIcon />}
        {...getCancelButtonProps()}
      />
    </ButtonGroup>
  ) : (
    ''
  );
}

function RestaurantDetails() {
  const [togglebit, setTogglebit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [resData, SetResData] = useState([]);
  const [hours, SetHours] = useState(null);
  const [restaurantName, SetRestaurantName] = useState('');
  const [restaurantAddress, SetRestaurantAddress] = useState('');
  const [restaurantAbout, SetRestaurantAbout] = useState('');
  const [localImage, setLocalImage] = useState('');
  const [restaurantChefCorner, SetRestaurantChefCorner] = useState(
    'Total of 45 chefs are here'
  );
  const [imageFile, setiimageFile] = useState(null);
  let imagePath = '';

  const toast = useToast();

  function SetHour() {
    // console.log('------------', resData?.SATURDAY_HOURS?.split(',')[0].trim(), resData?.SATURDAY_HOURS?.split(',')[1].trim())
    SetHours({
      SundayStartTime: resData?.SUNDAY_HOURS?.split(',')[0]?.includes('CLOSED') ? '' :
        (resData?.SUNDAY_HOURS?.split(',')[0] ? resData?.SUNDAY_HOURS?.split(',')[0]?.split('–')[0] : ''),
      SundayEndTime: resData?.SUNDAY_HOURS?.split(',')[0]?.includes('CLOSED') ? '' :
        (resData?.SUNDAY_HOURS?.split(',')[0] ? resData?.SUNDAY_HOURS?.split(',')[0]?.split('–')[1] : ''),
      SundayStartTime1: resData?.SUNDAY_HOURS?.split(',')[1]?.includes('CLOSED') ? '' :
        (resData?.SUNDAY_HOURS?.split(',')[1] ? resData?.SUNDAY_HOURS?.split(',')[1]?.split('–')[0] : ''),
      SundayEndTime1: resData?.SUNDAY_HOURS?.split(',')[1]?.includes('CLOSED') ? '' :
        (resData?.SUNDAY_HOURS?.split(',')[1] ? resData?.SUNDAY_HOURS?.split(',')[1]?.split('–')[1] : ''),

      MondayStartTime: resData?.MONDAY_HOURS?.split(',')[0]?.includes('CLOSED') ? '' :
        (resData?.MONDAY_HOURS?.split(',')[0] ? resData?.MONDAY_HOURS?.split(',')[0]?.split('–')[0] : ''),
      MondayEndTime: resData?.MONDAY_HOURS?.split(',')[0]?.includes('CLOSED') ? '' :
        (resData?.MONDAY_HOURS?.split(',')[0] ? resData?.MONDAY_HOURS?.split(',')[0]?.split('–')[1] : ''),
      MondayStartTime1: resData?.MONDAY_HOURS?.split(',')[1]?.includes('CLOSED') ? '' :
        (resData?.MONDAY_HOURS?.split(',')[1] ? resData?.MONDAY_HOURS?.split(',')[1]?.split('–')[0] : ''),
      MondayEndTime1: resData?.MONDAY_HOURS?.split(',')[1]?.includes('CLOSED') ? '' :
        (resData?.MONDAY_HOURS?.split(',')[1] ? resData?.MONDAY_HOURS?.split(',')[1]?.split('–')[1] : ''),

      TuesdayStartTime: resData?.TUESDAY_HOURS?.split(',')[0]?.includes('CLOSED') ? '' :
        (resData?.TUESDAY_HOURS?.split(',')[0] ? resData?.TUESDAY_HOURS?.split(',')[0]?.split('–')[0] : ''),
      TuesdayEndTime: resData?.TUESDAY_HOURS?.split(',')[0]?.includes('CLOSED') ? '' :
        (resData?.TUESDAY_HOURS?.split(',')[0] ? resData?.TUESDAY_HOURS?.split(',')[0]?.split('–')[1] : ''),
      TuesdayStartTime1: resData?.TUESDAY_HOURS?.split(',')[1]?.includes('CLOSED') ? '' :
        (resData?.TUESDAY_HOURS?.split(',')[1] ? resData?.TUESDAY_HOURS?.split(',')[1]?.split('–')[0] : ''),
      TuesdayEndTime1: resData?.TUESDAY_HOURS?.split(',')[1]?.includes('CLOSED') ? '' :
        (resData?.TUESDAY_HOURS?.split(',')[1] ? resData?.TUESDAY_HOURS?.split(',')[1]?.split('–')[1] : ''),

      WednesdayStartTime: resData?.WEDNESDAY_HOURS?.split(',')[0]?.includes('CLOSED') ? '' :
        (resData?.WEDNESDAY_HOURS?.split(',')[0] ? resData?.WEDNESDAY_HOURS?.split(',')[0]?.split('–')[0] : ''),
      WednesdayEndTime: resData?.WEDNESDAY_HOURS?.split(',')[0]?.includes('CLOSED') ? '' :
        (resData?.WEDNESDAY_HOURS?.split(',')[0] ? resData?.WEDNESDAY_HOURS?.split(',')[0]?.split('–')[1] : ''),
      WednesdayStartTime1: resData?.WEDNESDAY_HOURS?.split(',')[1]?.includes('CLOSED') ? '' :
        (resData?.WEDNESDAY_HOURS?.split(',')[1] ? resData?.WEDNESDAY_HOURS?.split(',')[1]?.split('–')[0] : ''),
      WednesdayEndTime1: resData?.WEDNESDAY_HOURS?.split(',')[1]?.includes('CLOSED') ? '' :
        (resData?.WEDNESDAY_HOURS?.split(',')[1] ? resData?.WEDNESDAY_HOURS?.split(',')[1]?.split('–')[1] : ''),

      ThursdayStartTime: resData?.THURSDAY_HOURS?.split(',')[0]?.includes('CLOSED') ? '' :
        (resData?.THURSDAY_HOURS?.split(',')[0] ? resData?.THURSDAY_HOURS?.split(',')[0]?.split('–')[0] : ''),
      ThursdayEndTime: resData?.THURSDAY_HOURS?.split(',')[0]?.includes('CLOSED') ? '' :
        (resData?.THURSDAY_HOURS?.split(',')[0] ? resData?.THURSDAY_HOURS?.split(',')[0]?.split('–')[1] : ''),
      ThursdayStartTime1: resData?.THURSDAY_HOURS?.split(',')[1]?.includes('CLOSED') ? '' :
        (resData?.THURSDAY_HOURS?.split(',')[1] ? resData?.THURSDAY_HOURS?.split(',')[1]?.split('–')[0] : ''),
      ThursdayEndTime1: resData?.THURSDAY_HOURS?.split(',')[1]?.includes('CLOSED') ? '' :
        (resData?.THURSDAY_HOURS?.split(',')[1] ? resData?.THURSDAY_HOURS?.split(',')[1]?.split('–')[1] : ''),

      FridayStartTime: resData?.FRIDAY_HOURS?.split(',')[0]?.includes('CLOSED') ? '' :
        (resData?.FRIDAY_HOURS?.split(',')[0] ? resData?.FRIDAY_HOURS?.split(',')[0]?.split('–')[0] : ''),
      FridayEndTime: resData?.FRIDAY_HOURS?.split(',')[0]?.includes('CLOSED') ? '' :
        (resData?.FRIDAY_HOURS?.split(',')[0] ? resData?.FRIDAY_HOURS?.split(',')[0]?.split('–')[1] : ''),
      FridayStartTime1: resData?.FRIDAY_HOURS?.split(',')[1]?.includes('CLOSED') ? '' :
        (resData?.FRIDAY_HOURS?.split(',')[1] ? resData?.FRIDAY_HOURS?.split(',')[1]?.split('–')[0] : ''),
      FridayEndTime1: resData?.FRIDAY_HOURS?.split(',')[1]?.includes('CLOSED') ? '' :
        (resData?.FRIDAY_HOURS?.split(',')[1] ? resData?.FRIDAY_HOURS?.split(',')[1]?.split('–')[1] : ''),

      SaturdayStartTime: resData?.SATURDAY_HOURS?.split(',')[0]?.includes('CLOSED') ? '' :
        (resData?.SATURDAY_HOURS?.split(',')[0] ? resData?.SATURDAY_HOURS?.split(',')[0]?.split('–')[0] : ''),
      SaturdayEndTime: resData?.SATURDAY_HOURS?.split(',')[0]?.includes('CLOSED') ? '' :
        (resData?.SATURDAY_HOURS?.split(',')[0] ? resData?.SATURDAY_HOURS?.split(',')[0]?.split('–')[1] : ''),
      SaturdayStartTime1: resData?.SATURDAY_HOURS?.split(',')[1]?.includes('CLOSED') ? '' :
        (resData?.SATURDAY_HOURS?.split(',')[1] ? resData?.SATURDAY_HOURS?.split(',')[1]?.split('–')[0] : ''),
      SaturdayEndTime1: resData?.SATURDAY_HOURS?.split(',')[1]?.includes('CLOSED') ? '' :
        (resData?.SATURDAY_HOURS?.split(',')[1] ? resData?.SATURDAY_HOURS?.split(',')[1]?.split('–')[1] : ''),
    });
  }

  const a = 10;

  useEffect(() => {
    //   setTimeout(()=>{
    //   RestaurantData();
    //   SetHour();
    // },3000)

    RestaurantData();
    SetHour();
  }, []);

  useEffect(() => {
    SetHour();
    console.log(resData);
  }, [resData]);
  //Show toast for validation message method
  function showValidationMsg(msg, st) {
    toast({
      title: '',
      description: msg,
      status: st,
      duration: 6000,
      isClosable: true,
      position: 'top',
    });
  }

  async function UpdateRestaurantDetails(data) {
    try {
      const a = 0;
      console.log(data);
      setIsLoading(true);
      const result = await API.graphql(
        graphqlOperation(UpdateRestMaster, { updateREST_MASTERInput: data })
      );
      setIsLoading(false);
      //	.then(() => { RestaurantData(); showValidationMsg("Successfully updated Restaurant Details", 'success') })
      // console.log('Ok')
      showValidationMsg('Successfully updated Restaurant Details', 'success');
      RestaurantData();
      console.log(data);
    } catch (err) {
      console.log('error: ', err);
      //showValidationMsg("Error occured", 'error')
    }
  }

  async function RestaurantData() {
    ////debugger;
    try {
      setIsLoading(true);
      const result = await API.graphql(
        graphqlOperation(GetRestMaster, {
          REST_ID: localStorage.getItem('Restaurantid'),
        })
      );
      ////debugger;

      const data = result?.data.getREST_MASTER;
      localStorage.setItem('Restaurantname', data?.REST_NAME);
      // SetRestaurantName(data?.REST_NAME);
      // SetRestaurantAddress(data?.ADDRESS);
      // SetRestaurantAbout('web Site:' + data?.WEBSITE +'Phone :'+data?.PHONE)

      SetResData(data);
      console.log(resData);
      //let b=10;
      const a = resData.MONDAY_HOURS?.substr(
        resData.MONDAY_HOURS.indexOf(' ') + 1
      );
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  function toggle() {
    setTogglebit(current => !current);
  }

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    isOpen: isOpenReviewModal,
    onOpen: onOpenReviewModal,
    onClose: onCloseReviewModal,
  } = useDisclosure();

  async function UpdateHourDetails(obj) {
    try {
      const data = {
        REST_ID: resData?.REST_ID,
        REST_NAME: resData?.REST_NAME,
        SUNDAY_HOURS: obj.SUNDAY_HOURS,
        MONDAY_HOURS: obj.MONDAY_HOURS,
        TUESDAY_HOURS: obj.TUESDAY_HOURS,
        WEDNESDAY_HOURS: obj.WEDNESDAY_HOURS,
        THURSDAY_HOURS: obj.THURSDAY_HOURS,
        FRIDAY_HOURS: obj.FRIDAY_HOURS,
        SATURDAY_HOURS: obj.SATURDAY_HOURS,
      };

      //setIsLoading(true)
      const result = await API.graphql(
        graphqlOperation(UpdateRestMaster, { updateREST_MASTERInput: data })
      );
      //setIsLoading(false)
      //		 .then(() => { RestaurantData(); showValidationMsg("Successfully updated Restaurant Details", 'success') })
      // console.log('Ok')
      onClose();
      showValidationMsg('Successfully updated Restaurant Details', 'success');
      RestaurantData();
    } catch (err) {
      console.log('error: ', err);
      //showValidationMsg("Error occured", 'error')
    }
  }

  async function savetoS3(file) {
    if (file) {
      const productName = file.name.split('.')[0];
      const extension = file.name.split('.')[1];
      const { type: mimeType } = file;

      // const imageData = await fetch(file)
      // const blobData = await imageData.blob()

      // const extension = selectedImage.uri.split(/[#?]/)[0].split('.').pop().trim();
      //       console.log("extension--------->" + extension);
      //       const { type: mimeType } = selectedImage
      //       const key = `images/${uuid.v4()}item-special.${extension}`
      //       const ImgUrl = `https://image.squash.app/public/${key}`
      //       console.log("ImgUrl--------->" + ImgUrl);

      //const key = `images/${uuidv4()}${productName}.${extension}`;
      const key = `${uuidv4()}item-special.${extension}`
      // const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`
      //const url = `https://image.squash.app/public/${key}`;

      const url = `https://imageprod.squash.app/public/${key}`
      //  const inputData = { name: productName , image: url }




      imagePath = url;
      console.log(url);

      try {
        const b = 10;
        const res = await Storage.put(key, file, {
          contentType: 'image/png',
          //   mimeType
        });
        // //debugger;
        const a = 10;
        //  await API.graphql(graphqlOperation(CreateProduct, { input: inputData }))
      } catch (err) {
        console.log('error: ', err);
      }
    }
  }

  // function refreshPage() {
  //   window.location.reload(false);
  // }

  return (
    <Box bg="#f9f9f9">
      <Helmet>
        <title>Squash | RestaurantDetails</title>         {' '}
      </Helmet>
      <Header isAdmin={true} toggle={toggle} />
      <Box pt="85" position="relative" h="100vh" overflow="hidden" d="flex">
        <Box
          zIndex="9"
          bg="#fff"
          position="absolute"
          top="0"
          pt="100px"
          left="0"
          h="100%"
          w={{
            base: togglebit ? '200px' : '0px',
            md: togglebit ? '200px' : '0px',
            lg: togglebit ? '0px' : '200px',
          }}
          display={{
            base: togglebit ? 'block' : 'none',
            md: togglebit ? 'block' : 'none',
            lg: togglebit ? 'none' : 'block',
          }}
        >
          <Sidebar current="RestaurantDetails" togglebit={togglebit} />
        </Box>
        <Box
          ml={togglebit ? '0px' : { base: '0', sm: '0', md: '0', lg: '200px' }}
          w="100%"
          p={{ base: '8px', md: '20px' }}
          overflow="auto"
        >
          {isLoading ? (
            <Box className="rspinbox">
              {' '}
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Box>
          ) : (
            <Box className="no-padding ">
              <Box className="card no-padding" mb={4} position='relative'>
                <Box className="card-header">
                  <Box className="title-heading">
                    <h2>Restaurant Details</h2>
                  </Box>
                </Box>
                <Box className="restaurantmain card-content">

                  <Box className="restaurantleft">
                    <Box className="restaurentdesktopimg">
                      {/* <Image src={ImageRestaurant} />   */}
                      {/* <Image src= {localImage} /> */}
                      {/* <Image src= {imagePath == '' || imagePath == null ? ImageRestaurant : imagePath} /> */}
                      <Image src={resData?.REST_PIC_LOCATION} />
                      {/* <p>{resData.REST_PIC_LOCATION}</p> */}

                      <Box d="flex" className="restaurantleftin">
                        {/* <Link pt='5px' textColor='green.300' fontSize='20px' fontWeight='medium' fontFamily='heading' onClick={onOpen} >Click to edit</Link> */}
                        {/* <Link pt='5px' textColor='green.300' fontSize='20px' fontWeight='medium' onClick={onOpenReviewModal} >Reviews</Link>
									    <Link pt='5px' textColor='green.300' fontSize='20px' fontWeight='medium' >Map</Link> */}

                        <Box d="flex" className="restaurantstar">
                          {/* {!isEdit ? (
                          <Box className="resteditbtns">
                            {' '}
                            <Input
                              type="file"
							  
                              onChange={e => {
                                const file = e.target.files[0];
                                setiimageFile(file);								
                                //    savetoS3(file);
                              }}
                              
                            />{' '}
                          </Box>
                        ) : (
                          ''
                        )} */}
                          {/* <ReactStars
													count={5}

													size={23}
													value={4.6}
													activeColor="#ffd700"
													edit={false}
												/> */}
                          <StarRatings
                            rating={4.6}
                            starRatedColor="yellow"
                            //	changeRating={this.changeRating}
                            numberOfStars={5}
                            name="rating"
                          />
                        </Box>
                      </Box>
                    </Box>
                    {/* 
                  <Box></Box>
                  <Box className="clearfix"></Box>
                  <Box className="resworktimebox">
                    <Box d="flex" mb="10px">
                      <Heading size="15px">
                        {' '}
                        <FontAwesomeIcon
                          icon={faCalendarAlt}
                          className="restimesvg"
                        />{' '}
                        Working hours{' '}
                        <Link>
                          <EditIcon
                            ml="20px"
                            fontSize="20px"
                            onClick={onOpen}
                          />
                        </Link>
                      </Heading>
                    </Box>

                    <Text>
                      <Box>Sunday</Box>{' '}
                      {resData.SUNDAY_HOURS?.substr(
                        resData.SUNDAY_HOURS.indexOf(' ') + 1
                      )}
                    </Text>
                    <Text>
                      <Box> Monday</Box>{' '}
                      {resData.MONDAY_HOURS?.substr(
                        resData.MONDAY_HOURS.indexOf(' ') + 1
                      )}
                    </Text>
                    <Text>
                      <Box>Tuesday</Box>{' '}
                      {resData.TUESDAY_HOURS?.substr(
                        resData.TUESDAY_HOURS.indexOf(' ') + 1
                      )}
                    </Text>
                    <Text>
                      <Box>wednesday</Box>{' '}
                      {resData.WEDNESDAY_HOURS?.substr(
                        resData.WEDNESDAY_HOURS.indexOf(' ') + 1
                      )}
                    </Text>
                    <Text>
                      <Box>Thursday</Box>{' '}
                      {resData.THURSDAY_HOURS?.substr(
                        resData.THURSDAY_HOURS.indexOf(' ') + 1
                      )}
                    </Text>
                    <Text>
                      <Box>Friday</Box>{' '}
                      {resData.FRIDAY_HOURS?.substr(
                        resData.FRIDAY_HOURS.indexOf(' ') + 1
                      )}
                    </Text>
                    <Text>
                      <Box>Saturday</Box>{' '}
                      {resData.SATURDAY_HOURS?.substr(
                        resData.SATURDAY_HOURS.indexOf(' ') + 1
                      )}
                    </Text>
                  </Box> */}
                  </Box>

                  <Box className="restaurantright">


                    {/* {resData?.length>0? */}
                    <HoursPopup
                      hours={hours}
                      open={isOpen}
                      close={onClose}
                      UpdateHourDetails={UpdateHourDetails}
                    />
                    {/* } */}
                    <ReviewPopup
                      open={isOpenReviewModal}
                      close={onCloseReviewModal}
                    />
                    <Formik
                      initialValues={{
                        name: resData?.REST_NAME,
                        address: resData?.ADDRESS,
                        city: resData.CITY_NAME,
                        phone: resData?.PHONE,
                        website: resData?.WEBSITE,
                        photo: resData?.REST_PIC_LOCATION,
                        //REST_PIC_LOCATION:resData?.REST_PIC_LOCATION
                      }}
                      validationSchema={ValidationSchema}
                      onSubmit={(value, { setSubmitting }) => {
                        ////debugger;

                        savetoS3(imageFile);

                        const data = {
                          REST_ID: resData?.REST_ID,
                          REST_NAME: value.name,
                          ADDRESS: value.address,
                          CITY_NAME: value.city,
                          PHONE: value.phone,
                          WEBSITE: value.website,
                          REST_PIC_LOCATION: imagePath,
                          //REST_PIC_LOCATION: imagePath ==null || imagePath ==''? value.photo:imagePath
                        };

                        UpdateRestaurantDetails(data);
                        setSubmitting(false);
                        setIsEdit(true);
                      }}
                    >
                      {formik => {
                        return (

                          <form onSubmit={formik.handleSubmit}>
                            {isEdit ? (
                              <Box className="resteditbtns edit-btn-absolute">
                                {' '}
                                <Link><Text display='flex' alignItems='center'>Edit  <EditIcon ml={2}
                                  onClick={() => {
                                    setIsEdit(false);
                                  }}
                                /></Text>

                                </Link>{' '}
                              </Box>
                            ) : (
                              // <Button className="primary-btn" pb='4px' h='32px' type='submit' ml='20px' color='#fff' bg="#0554F2" ml={2}

                              // >Save Details</Button>
                              <Box className="resteditbtns  edit-btn-absolute">
                                {/* <Button className="primary-btn" pb='4px' h='32px' type='submit' ml='20px' color='#fff' bg="#0554F2" ml={2}
																	>Save Details</Button> */}
                                <Link>
                                  <FontAwesomeIcon
                                    icon={faSave}
                                    onClick={() => formik.handleSubmit()}
                                  />{' '}
                                </Link>
                                <Link>
                                  <CloseIcon
                                    onClick={() => {
                                      setIsEdit(true);
                                    }}
                                  />
                                </Link>
                              </Box>
                            )}
                            <Box className='form-control-50'>   <FormControl mt={4}>
                              <Box d="flex" justifyContent="space-between">
                                <FormLabel>Name</FormLabel>

                              </Box>
                              <Box d="flex" className="detailsinput">
                                <FontAwesomeIcon icon={faHotel} />

                                <Input
                                  type="text"
                                  isReadOnly={isEdit}
                                  onChange={formik.handleChange('name')}
                                  onBlur={formik.handleBlur('name')}
                                  value={formik.values.name}
                                  error={formik.errors.name}
                                  touched={formik.touched.name}
                                ></Input>
                              </Box>
                              {formik.errors.name && formik.touched.name && (
                                <Text className="validationColor">
                                  {' '}
                                  {formik.errors.name}
                                </Text>
                              )}
                            </FormControl></Box>

                            <Box className='form-control-50'>  <FormControl mt={4}>
                              <FormLabel>City </FormLabel>
                              <Box d="flex" className="detailsinput">
                                <FontAwesomeIcon icon={faMapMarked} />
                                <Input
                                  type="text"
                                  isReadOnly={isEdit}
                                  onChange={formik.handleChange('city')}
                                  onBlur={formik.handleBlur('city')}
                                  value={formik.values.city}
                                  error={formik.errors.city}
                                  touched={formik.touched.city}
                                />
                              </Box>
                              {formik.errors.city && formik.touched.city && (
                                <Text className="validationColor">
                                  {' '}
                                  {formik.errors.city}
                                </Text>
                              )}
                            </FormControl></Box>

                            <Box className='form-control-100'> <FormControl mt={4}>
                              <FormLabel>Address </FormLabel>
                              <Box d="flex" className="detailsinput">
                                {' '}
                                <FontAwesomeIcon icon={faMapMarkerAlt} />
                                <Input
                                  type="text"
                                  isReadOnly={isEdit}
                                  onChange={formik.handleChange('address')}
                                  onBlur={formik.handleBlur('address')}
                                  value={formik.values.address}
                                  error={formik.errors.address}
                                  touched={formik.touched.address}
                                />
                              </Box>
                              {formik.errors.address &&
                                formik.touched.address && (
                                  <Text className="validationColor">
                                    {' '}
                                    {formik.errors.address}
                                  </Text>
                                )}
                            </FormControl></Box>

                            <Box className='form-control-50'>

                              <FormControl mt={4} pr="10px">
                                <FormLabel>Phone</FormLabel>
                                <Box d="flex" className="detailsinput">
                                  <FontAwesomeIcon icon={faPhoneAlt} />
                                  <Input
                                    type="text"
                                    isReadOnly={isEdit}
                                    onChange={formik.handleChange('phone')}
                                    onBlur={formik.handleBlur('phone')}
                                    value={formik.values.phone}
                                    error={formik.errors.phone}
                                    touched={formik.touched.phone}
                                  />
                                </Box>
                                {formik.errors.phone && formik.touched.phone && (
                                  <Text className="validationColor">
                                    {' '}
                                    {formik.errors.phone}
                                  </Text>
                                )}
                              </FormControl>
                              <FormControl mt={4}>
                                <FormLabel>Website</FormLabel>
                                <Box d="flex" className="detailsinput">
                                  <FontAwesomeIcon icon={faGlobe} />
                                  <Input
                                    type="text"
                                    isReadOnly={isEdit}
                                    onChange={formik.handleChange('website')}
                                    onBlur={formik.handleBlur('website')}
                                    value={formik.values.website}
                                    error={formik.errors.website}
                                    touched={formik.touched.website}
                                  />
                                </Box>
                                {formik.errors.website &&
                                  formik.touched.website && (
                                    <Text className="validationColor">
                                      {' '}
                                      {formik.errors.website}
                                    </Text>
                                  )}
                              </FormControl>
                            </Box>

                            <Box className='form-control-50'>
                              <FormControl mt={4}>
                                <FormLabel>Image </FormLabel>
                                <Box d="flex" className=" restaurant-file-upload">
                                  <FontAwesomeIcon icon={faPlus} />
                                  <Input
                                    pt="12px"
                                    type="file"
                                    //value="D:\ReactProjects\DineCube\MenuBee\squashweb\src\assets\images\Restaurant.jpg"
                                    isDisabled={isEdit}
                                    onChange={e => {
                                      const file = e.target.files[0];
                                      setiimageFile(file);
                                      setLocalImage(file);
                                      //    savetoS3(file);
                                    }}
                                    onBlur={formik.handleBlur('photo')}
                                    //value={formik.values.photo}
                                    error={formik.errors.photo}
                                    touched={formik.touched.photo}
                                  />
                                </Box>
                                {formik.errors.photo && formik.touched.photo && (
                                  <Text className="validationColor">
                                    {' '}
                                    {formik.errors.photo}
                                  </Text>
                                )}
                              </FormControl>
                            </Box>






                          </form>
                        );
                      }}
                    </Formik>
                  </Box>


                </Box>
              </Box>
              <Box></Box>
              <Box className="clearfix"></Box>
              <Box className="card no-padding " position='relative'>
                <Box className="card-header">
                  <Box className="title-heading">
                    <h2>
                      Working Hours
                    </h2>
                  </Box>
                  <Box className="resteditbtns edit-btn-absolute">
                    <Link>
                      <Text>Edit <EditIcon
                        ml={2}
                        fontSize="20px"
                        onClick={onOpen}
                      /></Text>
                    </Link></Box>
                </Box>
                <Box className="card-content res-work-time" pt={0}>


                  <Text>
                    <FontAwesomeIcon icon={faCalendarAlt} />
                    <Box>Sunday</Box>{' '}
                    {/* {resData.SUNDAY_HOURS?.substr(
                      resData.SUNDAY_HOURS.indexOf(' ') + 1
                    )} */}
                    {
                      resData.SUNDAY_HOURS == 'CLOSED' ? resData.SUNDAY_HOURS :
                        resData?.SUNDAY_HOURS?.split(',')[0] ? resData?.SUNDAY_HOURS?.split(',')[0].trim() : ''
                    }
                    <Text style={{marginTop: -10, marginLeft: 'auto', marginRight: 'auto'}}>
                      {
                        resData?.SUNDAY_HOURS?.split(',')[1] ? resData?.SUNDAY_HOURS?.split(',')[1].trim() : ''
                      }
                    </Text>
                  </Text>
                  <Text>
                    <FontAwesomeIcon icon={faCalendarAlt} />
                    <Box> Monday</Box>{' '}
                    {/* {resData.MONDAY_HOURS?.substr(
                      resData.MONDAY_HOURS.indexOf(' ') + 1
                    )} */}
                    {
                      resData.MONDAY_HOURS == 'CLOSED' ? resData.MONDAY_HOURS :
                        resData?.MONDAY_HOURS?.split(',')[0] ? resData?.MONDAY_HOURS?.split(',')[0].trim() : ''
                    }
                    <Text style={{marginTop: -10, marginLeft: 'auto', marginRight: 'auto'}}>
                      {
                        resData?.MONDAY_HOURS?.split(',')[1] ? resData?.MONDAY_HOURS?.split(',')[1].trim() : ''
                      }
                    </Text>
                  </Text>
                  <Text>
                    <FontAwesomeIcon icon={faCalendarAlt} />
                    <Box>Tuesday</Box>{' '}
                    {/* {resData.TUESDAY_HOURS?.substr(
                      resData.TUESDAY_HOURS.indexOf(' ') + 1
                    )} */}
                    {
                      resData.TUESDAY_HOURS == 'CLOSED' ? resData.TUESDAY_HOURS :
                        resData?.TUESDAY_HOURS?.split(',')[0] ? resData?.TUESDAY_HOURS?.split(',')[0].trim() : ''
                    }
                    <Text style={{marginTop: -10, marginLeft: 'auto', marginRight: 'auto'}}>
                      {
                        resData?.TUESDAY_HOURS?.split(',')[1] ? resData?.TUESDAY_HOURS?.split(',')[1].trim() : ''
                      }
                    </Text>
                  </Text>
                  <Text>
                    <FontAwesomeIcon icon={faCalendarAlt} />
                    <Box>wednesday</Box>{' '}
                    {/* {resData.WEDNESDAY_HOURS?.substr(
                      resData.WEDNESDAY_HOURS.indexOf(' ') + 1
                    )} */}
                    {
                      resData.WEDNESDAY_HOURS == 'CLOSED' ? resData.WEDNESDAY_HOURS :
                        resData?.WEDNESDAY_HOURS?.split(',')[0] ? resData?.WEDNESDAY_HOURS?.split(',')[0].trim() : ''
                    }
                    <Text style={{marginTop: -10, marginLeft: 'auto', marginRight: 'auto'}}>
                      {
                        resData?.WEDNESDAY_HOURS?.split(',')[1] ? resData?.WEDNESDAY_HOURS?.split(',')[1].trim() : ''
                      }
                    </Text>
                  </Text>
                  <Text>
                    <FontAwesomeIcon icon={faCalendarAlt} />
                    <Box>Thursday</Box>{' '}
                    {/* {resData.THURSDAY_HOURS?.substr(
                      resData.THURSDAY_HOURS.indexOf(' ') + 1
                    )} */}
                    {
                      resData.THURSDAY_HOURS == 'CLOSED' ? resData.THURSDAY_HOURS :
                        resData?.THURSDAY_HOURS?.split(',')[0] ? resData?.THURSDAY_HOURS?.split(',')[0].trim() : ''
                    }
                    <Text style={{marginTop: -10, marginLeft: 'auto', marginRight: 'auto'}}>
                      {
                        resData?.THURSDAY_HOURS?.split(',')[1] ? resData?.THURSDAY_HOURS?.split(',')[1].trim() : ''
                      }
                    </Text>
                  </Text>
                  <Text>
                    <FontAwesomeIcon icon={faCalendarAlt} />
                    <Box>Friday</Box>{' '}
                    {/* {resData.FRIDAY_HOURS?.substr(
                      resData.FRIDAY_HOURS.indexOf(' ') + 1
                    )} */}
                    {
                      resData.FRIDAY_HOURS == 'CLOSED' ? resData.FRIDAY_HOURS :
                        resData?.FRIDAY_HOURS?.split(',')[0] ? resData?.FRIDAY_HOURS?.split(',')[0].trim() : ''
                    }
                    <Text style={{marginTop: -10, marginLeft: 'auto', marginRight: 'auto'}}>
                      {
                        resData?.FRIDAY_HOURS?.split(',')[1] ? resData?.FRIDAY_HOURS?.split(',')[1].trim() : ''
                      }
                    </Text>
                  </Text>
                  <Text>
                    <FontAwesomeIcon icon={faCalendarAlt} />
                    <Box>Saturday</Box>{' '}
                    {/* {resData.SATURDAY_HOURS?.substr(
                      resData.SATURDAY_HOURS.indexOf(' ') + 1
                    )} */}
                    {
                      resData.SATURDAY_HOURS == 'CLOSED' ? resData.SATURDAY_HOURS :
                        resData?.SATURDAY_HOURS?.split(',')[0] ? resData?.SATURDAY_HOURS?.split(',')[0].trim() : ''
                    }
                    <Text style={{marginTop: -10, marginLeft: 'auto', marginRight: 'auto'}}>
                      {
                        resData?.SATURDAY_HOURS?.split(',')[1] ? resData?.SATURDAY_HOURS?.split(',')[1].trim() : ''
                      }
                    </Text>
                  </Text>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
export default RestaurantDetails;
