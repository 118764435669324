import {
    Box,
    Button,
    Modal,
    ModalHeader,
    ModalOverlay,
    ModalCloseButton,
    ModalBody,
    ModalContent,
    Input,
    Flex,
    Spinner,
    Image,
    Spacer,
    Text,
    FormControl,
    InputGroup,
    InputLeftElement,
    Avatar,
    Link
} from "@chakra-ui/react";
import { Multiselect } from 'multiselect-react-dropdown';
import React, { useRef, useState, useEffect } from "react";
import * as yup from 'yup';
import { Formik, Form, Field, FieldArray, useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { Auth, Hub, API, graphqlOperation } from 'aws-amplify';
import PreviewPopup from '../components/PreviewPopup'
import { useToast, useDisclosure } from "@chakra-ui/react"
import { EmailIcon, UnlockIcon, PhoneIcon } from '@chakra-ui/icons'
import { Link as RouteLink } from "react-router-dom";
import { useHistory } from 'react-router-dom';

const loginValidationSchema = yup.object().shape({
    name: yup
        .string()
        .email("Please enter valid name")
        .max(50, "Email must be 50 characters or less")
        .required('Name address is required'),
    email: yup
        .string()
        .email("Please enter valid email")
        .max(50, "Email must be 50 characters or less")
        .required('Email address is required'),
    phone: yup
        .string()
        .required('Phone address is required'),
    password: yup

        .string()
        .min(8, ({ min }) => `Password must be at least ${min} characters`)
        .max(30, "Password must be 30 characters or less")
        .required('Password is required'),
})

function EditPreview() {
    const a = 10;
}

function Signup(props) {

    const password = useRef(null)
    const [menuChoice, SetMenuChoice] = useState([])

    const data = props.customizeData?.map((item) => { return { id: item.CH_ID, name: item.CH_DESC } })
    const initialRef = React.useRef()
    const finalRef = React.useRef()
    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory();
    const toast = useToast()
    const [otpBit, setotpBit] = useState(false)

    const [email, setemail] = useState('')
    const [password2, setpassword2] = useState('')

    useEffect(() => {

        //MenuDetails()
    }, []);


    const {
        isOpen: isOpenPreviewModal,
        onOpen: onOpenPreviewModal,
        onClose: onClosePreviewModal
    } = useDisclosure()

    //Show toast for validation message method
    function showValidationMsg(msg, st) {
        toast({
            title: "",
            description: msg,
            status: st,
            duration: 9000,
            isClosable: true,
            position: "top"
        })
    }

    //Amplify signin method
    async function signup(values) {


        try {
            setIsLoading(true);
            let username = values.email;
            setemail(values.email)
            let password = values.password;
            setpassword2(values.password)
            let email = values.email;
            let name = values.name;
            let phone_number = values.phone ? "+" + values.phone : "";
            await Auth.signUp({
                username,
                password,
                attributes: {
                    name,
                    email,
                    phone_number, // optional - E.164 number convention
                    // other custom attributes
                },
            })
                .then((user) => {
                    setotpBit(true)
                }
                )
                .catch((error) => showValidationMsg(error.message, 'error'));
            setIsLoading(false);
        } catch (error) {
            showValidationMsg(error.message, 'error');
            console.log(" Error signing up...", error);
            setIsLoading(false);
        }
    }
    async function confirmSignUp(values) {
        try {
            // setIsLoading(true);
            let code = values.confirmationcode;
            let username = email;

            const email = email;
            const password = password2;

            //Validate confirmation code
            await Auth.confirmSignUp(username, code);

            // //Sign in the user after verification of code
            // await Auth.signIn(email, password).then((err) =>
            //     history.goBack()
            // );
            showValidationMsg('Registration completed successfully','success');
              setIsLoading(false);
        } catch (error) {
            showValidationMsg(error.message,'error');
            console.log(" Error confirm sign up...", error);
            //setIsLoading(false);
        }
    }
    //function to resend confirmation code
    async function resentCode() {
        try {
            let username = email;
            await Auth.resendSignUp(username);
            //   showSuccessMsg("Confirmation code resent successfully");
        } catch (error) {
            showValidationMsg(error.message,'error');
            console.log("Failed to resent confirmation code", error);
        }
    }

    // validations for form submit
    const ConfirmSignUpValidationSchema = yup.object().shape({
        confirmationcode: yup
            .string()
            .max(6, "Invalid confirmation code")
            .matches(/^\d+$/, "Invalid confirmation code")
            .required("Confirmation code is required")

    });

    return (
        <>
            <Modal
                size={'sx', 'md', 'xl', 'xl'}
                isOpen={props.open}
                onClose={() => { props.close(); setotpBit(false) }}
                scrollBehavior="inside"
                initialFocusRef={initialRef}
                finalFocusRef={finalRef}

            >

                <ModalOverlay />
                <ModalContent className="mpopup">
                    <ModalHeader>Sign Up</ModalHeader>
                    <ModalCloseButton className="popup-btn" />
                    <ModalBody pb={6}>
                        {otpBit ?
                            <Box>
                                <Formik
                                    initialValues={{
                                        confirmationcode: ""
                                    }}
                                    validationSchema={ConfirmSignUpValidationSchema}
                                    onSubmit={(values, { setSubmitting }) => {
                                        confirmSignUp(values);
                                        setSubmitting(false)
                                    }}
                                >
                                    {(formik) => {
                                        const { errors, touched, isValid, dirty } = formik;
                                        return (
                                            <form onSubmit={formik.handleSubmit} >
                                                <FormControl my={5}>
                                                    <InputGroup>
                                                        <InputLeftElement
                                                            pointerEvents="none"
                                                            children={<EmailIcon color="gray.300" />}
                                                        />

                                                        <Input
                                                            type="text"
                                                            placeholder="Activation code"
                                                            onChange={formik.handleChange('confirmationcode')}
                                                            onBlur={formik.handleBlur('confirmationcode')}
                                                            touched={formik.touched.email}
                                                            error={errors.confirmationcode}
                                                        />

                                                    </InputGroup>
                                                </FormControl>
                                                {errors.confirmationcode && touched.confirmationcode && (
                                                    <Text className="validationColor" >
                                                        {" "}
                                                        {errors.confirmationcode}
                                                    </Text>
                                                )}
                                                <Button className='primary-button' bg="#0554F2" width="full" mt={4} type="submit">
                                                    CONFIRM OTP
                                                </Button>
                                            </form>

                                        );
                                    }}
                                </Formik>
                                <Box w='100%' textAlign='center'>
                                    <Text fontSize={["8px", "sm", "md", "md"]}  >
                                        Didn't get Activation code ..? {" "}<br />
                                        <Link onClick={() => resentCode()} color="teal.500" justifyContent="center" alignItems="center">
                                            Click here
                                        </Link>
                                        {" "} to resend it again.
                                    </Text>
                                </Box>
                            </Box>

                            :
                            <Formik
                                initialValues={{
                                    name: '',
                                    email: "",
                                    phone: '',
                                    password: '',
                                }}
                                validationSchema={loginValidationSchema}
                                onSubmit={(values, { setSubmitting }) => {
                                    signup(values)
                                    setSubmitting(false)
                                }}
                            >
                                {(formik) => {

                                    return (
                                        <form onSubmit={formik.handleSubmit}  >
                                            <Box m='20px'>
                                                <FormControl mt={6}>
                                                    <InputGroup >
                                                        <InputLeftElement
                                                            pointerEvents="none"
                                                            children={<Avatar size="xs" bg="gray.300" />}
                                                        />
                                                        <Input
                                                            fontSize={["13px", "13px", "18px", "18px"]}
                                                            type="text"
                                                            placeholder="User Name"
                                                            onChange={formik.handleChange('name')}
                                                            onBlur={formik.handleBlur('name')}
                                                            value={formik.values.name}
                                                            error={formik.errors.name}
                                                            touched={formik.touched.name}
                                                        />
                                                    </InputGroup>
                                                </FormControl>
                                                {formik.errors.name && formik.touched.name && <Text className="validationColor" >{formik.errors.name}</Text>}

                                                <FormControl mt={6}>
                                                    <InputGroup >
                                                        <InputLeftElement
                                                            pointerEvents="none"
                                                            children={<EmailIcon color="gray.300" />}
                                                        />
                                                        <Input
                                                            fontSize={["13px", "13px", "18px", "18px"]}
                                                            type="email"
                                                            placeholder="Email"
                                                            onChange={formik.handleChange('email')}
                                                            onBlur={formik.handleBlur('email')}
                                                            value={formik.values.email}
                                                            error={formik.errors.email}
                                                            touched={formik.touched.email}
                                                        />
                                                    </InputGroup>
                                                </FormControl>
                                                {formik.errors.email && formik.touched.email && <Text className="validationColor" >{formik.errors.email}</Text>}

                                                <FormControl mt={6}>
                                                    <InputGroup >
                                                        <InputLeftElement
                                                            pointerEvents="none"
                                                            children={<PhoneIcon color="gray.300" />}
                                                        />
                                                        <Input
                                                            fontSize={["13px", "13px", "18px", "18px"]}

                                                            type="text"
                                                            placeholder="Phone"
                                                            onChange={formik.handleChange('phone')}
                                                            value={formik.values.phone}
                                                            error={formik.errors.phone}
                                                            onBlur={formik.handleBlur('phone')}
                                                            touched={formik.touched.phone}
                                                        />
                                                    </InputGroup>

                                                </FormControl>
                                                {formik.errors.phone && formik.touched.phone && <Text className="validationColor">{" "} {formik.errors.phone}</Text>}


                                                <FormControl mt={6}>
                                                    <InputGroup >
                                                        <InputLeftElement
                                                            pointerEvents="none"
                                                            children={<UnlockIcon color="gray.300" />}
                                                        />
                                                        <Input
                                                            fontSize={["13px", "13px", "18px", "18px"]}
                                                            ref={password}
                                                            type="password"
                                                            placeholder="Password"
                                                            onChange={formik.handleChange('password')}
                                                            value={formik.values.password}
                                                            error={formik.errors.password}
                                                            onBlur={formik.handleBlur('password')}
                                                            touched={formik.touched.password}
                                                        />
                                                    </InputGroup>

                                                </FormControl>
                                                {formik.errors.password && formik.touched.password && <Text className="validationColor">{" "} {formik.errors.password}</Text>}
                                                <Box >
                                                    <Flex mt={4} display={isLoading ? 'flex' : 'none'} >
                                                        <Box w='45%'></Box>
                                                        <Spacer />
                                                        <Box w='55%' >
                                                            <Spinner
                                                                thickness="4px"
                                                                speed="0.65s"
                                                                emptyColor="gray.200"
                                                                color="blue.500"
                                                                size="xl"
                                                            />
                                                        </Box>

                                                    </Flex>

                                                    <Button display={isLoading ? 'none' : 'block'} className='primary-btn'  width="full" mt={4} type="submit">
                                                        SIGNUP
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </form>
                                    );
                                }}
                            </Formik>

                        }
                    </ModalBody>
                    {/* <Box mb='25px' textAlign="center" >
                <Text fontSize={["sm", "md", "lg", "lg"]} >
                  Forgot  Password?{" "}
                  <RouteLink className="clickMe" to='/ForgotPassword'   >
                    ClickHere
                  </RouteLink>
                </Text>
              </Box> */}

                </ModalContent>

            </Modal>

        </>
    )

}

export default Signup;
