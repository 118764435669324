import {
    Button,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
} from "@chakra-ui/react"
import React, { useRef, useState, useEffect } from "react";
import Cookies from 'js-cookie'
import { useHistory } from 'react-router-dom';
import moment from 'moment'
import { Auth, Hub, API, graphqlOperation } from 'aws-amplify';
import { createUserOrderMaster as CreateUserOrderMaster } from '../graphql/mutations'


function AddtocartconfirmPopup(props) {
    ////debugger;

    const [isOpen, setIsOpen] = React.useState(false)
    const onClose = () => setIsOpen(false)
    const cancelRef = React.useRef()
    const history = useHistory();



    async function callCartmutation(data) {

        try {
            let data = null;
            var json_cartitems = Cookies.get('cartitems')
            if (json_cartitems) {
                const items = JSON.parse(json_cartitems);
                let total = 0;
                items?.map((item, index) => {
                    total = total + parseFloat(item.totalprice) * (parseInt(item.quantity))
                    
                })

                const todaydateformate = moment(new Date()).format("YYYY-MM-DD-hh:mm")


                data = {
                    //		CART_CREATE_DATE: '1970-01-01Z',
                    CART_CREATE_DATE: todaydateformate,
                    CART_ID: items[0].cartID,
                    CART_STATUS: "ABANDONED",
                    CART_TOTAL_COST: parseFloat(total.toFixed(2)),
                    CART_TOTAL_ITEM_QUANTITY: items.length,
                    REST_ID: items[0].restId
                }

            }

            Cookies.remove('cartitems');
            //	const todaydateformate =  formatDate(new Date()) + '-08:15';

            console.log(JSON.stringify(data));
            const result = await API.graphql(graphqlOperation(CreateUserOrderMaster, { createUSER_ORDERInput: data }))

            console.log('Ok')



        }
        catch (err) {

            console.log(err)
        }
    }



    return (
        <>
            {/* <Button colorScheme="red" onClick={() => setIsOpen(true)}>
            Delete Customer
          </Button> */}

            <AlertDialog
                isOpen={props.isOpen}
                leastDestructiveRef={cancelRef}
                onClose={props.onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            Remove Item
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Cart already have items from another restaurant, Do you want add new item?
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={props.onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme="red" ml={3}
                                onClick={() => {

                                    props.onClose();

                                    callCartmutation()

                                  

                                    props?.confirmcartclear(true);

                                    // if(props.fromcartpopup==true)
                                    // {


                                        

                                    // }
                                    // else{
                                    //     history.push(
                                    //         {
                                    //             //pathname: `/Admin/${localStorage.getItem('Currentpage')}`,
                                    //             pathname: '/RestaurantMenu/'+	localStorage.getItem('restID') ,
                                    //              prevouspage: 'Home',
                                    //             quantity: props.quantity
                                    //             //  state: { email:props.userinfo?.email, password: props.userinfo?.pass }
                                    //         }
                                    //     );

                                    // }
                                   

                                }}
                            >
                                Yes,Proceed
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}

export default AddtocartconfirmPopup;
