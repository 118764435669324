/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateCityMaster = /* GraphQL */ `
  subscription OnCreateCityMaster {
    onCreateCITY_MASTER {
      id
      CITY_NAME
      STATE
      LATITUDE
      LONGITUDE
      DISPLAY_INDICATOR_YN
    }
  }
`;
export const onCreateCuisineTypeMaster = /* GraphQL */ `
  subscription OnCreateCuisineTypeMaster {
    onCreateCUISINE_TYPE_MASTER {
      CUISINE_ID
      CUISINE_TYPE_NAME
      CUISINE_PIC_LOACTION
      relatedRestaurants {
        REST_ID
        REST_NAME
        ADDRESS
        WEBSITE
        PHONE
        LATITUDE
        LONGITUDE
        RATING
        REVIEW_NUMBERS
        PRICE_LEVEL
        SUNDAY_HOURS
        MONDAY_HOURS
        TUESDAY_HOURS
        WEDNESDAY_HOURS
        THURSDAY_HOURS
        FRIDAY_HOURS
        SATURDAY_HOURS
        CUISINE_TYPE
        CITY_NAME
        TAKEOUT_YN
        DELIVERY_YN
        PRICE_LEVEL_DOLLARS
        REST_OWNER
        CURBSIDE_YN
        DISPLAY_REST_YN
        OPEN_YN
        REST_PIC_LOCATION
        REST_ABOUT
        YELP_ID
        PICKUP_ENABLED_YN
        DINEIN_ENABLED_YN
        RESERVE_ENABLED_YN
        RDELIVERY_ENABLED_YN
        ORDER_PREPTIMEMOD_YN
        MENU_ITEM_INSTR_ENABLED_YN
        RDELIVERY_COMMENT
        APPLY_PROCESS_FEE
        PROCESS_FEE_PCT
        PROCESS_FEE_UNIT
        PROCESS_FEE_AMT
        PROCESS_AMT_UNIT
        PAY_NOW_FEE_MSG
        PAY_REST_FEE_MSG
        relatedMenuGroups {
          REST_ID
          MENU_ID
          MENU_NAME
          MENU_DESC
          MENU_GR_ID
          MENU_GR_NAME
          MENU_GR_DESC
          MENU_GR_ORDER
        }
        relatedSplUpcomingByRest {
          MENU_IT_ID
          MENU_IT_NAME
          REST_ID
          REST_NAME
          CITY_NAME
          CUISINE_TYPE
          MENU_IT_PIC_LOCATION
          DATE_FROM_DATE
          DATE_TO_DATE
          MENU_IT_DESC
          MENU_IT_PRICE
          MENU_ID
          MENU_NAME
          MENU_DESC
          MENU_TYPE
          MENU_GR_ID
          MENU_GR_NAME
          MENU_GR_ORDER
          MENU_ORDER
          MENU_IT_ORDER
          USER_LIKE_YN
          USER_BOOKMARK_YN
          EVERYDAY_YN
          SUNDAY_YN
          MONDAY_YN
          TUESDAY_YN
          WEDNESAY_YN
          THURSDAY_YN
          FRIDAY_YN
          SATURDAY_YN
          EVENT_ALLDAY_YN
          EVENT_TIME
          MENU_IT_TAXABLE_YN
        }
        relatedSplTodayByRest {
          MENU_IT_ID
          MENU_IT_NAME
          REST_ID
          REST_NAME
          CITY_NAME
          CUISINE_TYPE
          MENU_IT_PIC_LOCATION
          DATE_FROM_DATE
          DATE_TO_DATE
          MENU_IT_DESC
          MENU_IT_PRICE
          MENU_ID
          MENU_NAME
          MENU_DESC
          MENU_TYPE
          MENU_GR_ID
          MENU_GR_NAME
          MENU_GR_ORDER
          MENU_ORDER
          MENU_IT_ORDER
          USER_LIKE_YN
          USER_BOOKMARK_YN
          EVERYDAY_YN
          SUNDAY_YN
          MONDAY_YN
          TUESDAY_YN
          WEDNESAY_YN
          THURSDAY_YN
          FRIDAY_YN
          SATURDAY_YN
          EVENT_ALLDAY_YN
          EVENT_TIME
          MENU_IT_TAXABLE_YN
        }
        relatedRestBkmrk {
          REST_ID
          USER_BOOKMARK_YN
          USER_EMAIL
        }
        relatedRestPolls {
          REST_ID
          POLL_ID
          POLL_NAME
          POLL_QUESTION
          POLL_START_DATE_TIME
          POLL_END_DATE_TIME
          POLL_CREATE_DATE_TIME
          POLL_STATUS
          POLL_CHOICE_ID_1
          POLL_CHOICE_1
          POLL_CHOICE_ID_2
          POLL_CHOICE_2
          POLL_CHOICE_ID_3
          POLL_CHOICE_3
          POLL_CHOICE_ID_4
          POLL_CHOICE_4
        }
        relatedTaxRewards {
          REST_ID
          TAX_RATE
          REWARD_PCT
          REWARD_BAL
          DISCOUNT_ID
          DISCOUNT_TYPE
          DISCOUNT_CODE
          DISCOUNT_RATE
          DISCOUNT_AMT
          ORDER_AMT_MIN
          DISCOUNT_MAX_USAGE_COUNT
          DISCOUNT_USE_COUNT
          APPLY_DEFAULT
        }
      }
    }
  }
`;
export const onCreateCustomers = /* GraphQL */ `
  subscription OnCreateCustomers {
    onCreateCustomers {
      id
      name
      phone
      email
    }
  }
`;
export const onCreateRestMaster = /* GraphQL */ `
  subscription OnCreateRestMaster {
    onCreateREST_MASTER {
      REST_ID
      REST_NAME
      ADDRESS
      WEBSITE
      PHONE
      LATITUDE
      LONGITUDE
      RATING
      REVIEW_NUMBERS
      PRICE_LEVEL
      SUNDAY_HOURS
      MONDAY_HOURS
      TUESDAY_HOURS
      WEDNESDAY_HOURS
      THURSDAY_HOURS
      FRIDAY_HOURS
      SATURDAY_HOURS
      CUISINE_TYPE
      CITY_NAME
      TAKEOUT_YN
      DELIVERY_YN
      PRICE_LEVEL_DOLLARS
      REST_OWNER
      CURBSIDE_YN
      DISPLAY_REST_YN
      OPEN_YN
      REST_PIC_LOCATION
      REST_ABOUT
      YELP_ID
      PICKUP_ENABLED_YN
      DINEIN_ENABLED_YN
      RESERVE_ENABLED_YN
      RDELIVERY_ENABLED_YN
      ORDER_PREPTIMEMOD_YN
      MENU_ITEM_INSTR_ENABLED_YN
      RDELIVERY_COMMENT
      APPLY_PROCESS_FEE
      PROCESS_FEE_PCT
      PROCESS_FEE_UNIT
      PROCESS_FEE_AMT
      PROCESS_AMT_UNIT
      PAY_NOW_FEE_MSG
      PAY_REST_FEE_MSG
      relatedMenuGroups {
        REST_ID
        MENU_ID
        MENU_NAME
        MENU_DESC
        MENU_GR_ID
        MENU_GR_NAME
        MENU_GR_DESC
        MENU_GR_ORDER
        relatedMenuItems {
          REST_ID
          MENU_IT_ID
          MENU_IT_NAME
          MENU_IT_DESC
          MENU_IT_PRICE
          MENU_IT_PIC_LOCATION
          POPULAR_YN
          MENU_IT_STATUS
          MENU_IT_TAXABLE_YN
        }
      }
      relatedSplUpcomingByRest {
        MENU_IT_ID
        MENU_IT_NAME
        REST_ID
        REST_NAME
        CITY_NAME
        CUISINE_TYPE
        MENU_IT_PIC_LOCATION
        DATE_FROM_DATE
        DATE_TO_DATE
        MENU_IT_DESC
        MENU_IT_PRICE
        MENU_ID
        MENU_NAME
        MENU_DESC
        MENU_TYPE
        MENU_GR_ID
        MENU_GR_NAME
        MENU_GR_ORDER
        MENU_ORDER
        MENU_IT_ORDER
        USER_LIKE_YN
        USER_BOOKMARK_YN
        EVERYDAY_YN
        SUNDAY_YN
        MONDAY_YN
        TUESDAY_YN
        WEDNESAY_YN
        THURSDAY_YN
        FRIDAY_YN
        SATURDAY_YN
        EVENT_ALLDAY_YN
        EVENT_TIME
        MENU_IT_TAXABLE_YN
        relatedMenuItemLikes {
          MENU_IT_ID
          LIKE_COUNT
        }
      }
      relatedSplTodayByRest {
        MENU_IT_ID
        MENU_IT_NAME
        REST_ID
        REST_NAME
        CITY_NAME
        CUISINE_TYPE
        MENU_IT_PIC_LOCATION
        DATE_FROM_DATE
        DATE_TO_DATE
        MENU_IT_DESC
        MENU_IT_PRICE
        MENU_ID
        MENU_NAME
        MENU_DESC
        MENU_TYPE
        MENU_GR_ID
        MENU_GR_NAME
        MENU_GR_ORDER
        MENU_ORDER
        MENU_IT_ORDER
        USER_LIKE_YN
        USER_BOOKMARK_YN
        EVERYDAY_YN
        SUNDAY_YN
        MONDAY_YN
        TUESDAY_YN
        WEDNESAY_YN
        THURSDAY_YN
        FRIDAY_YN
        SATURDAY_YN
        EVENT_ALLDAY_YN
        EVENT_TIME
        MENU_IT_TAXABLE_YN
        relatedMenuItemLikes {
          MENU_IT_ID
          LIKE_COUNT
        }
      }
      relatedRestBkmrk {
        REST_ID
        USER_BOOKMARK_YN
        USER_EMAIL
      }
      relatedRestPolls {
        REST_ID
        POLL_ID
        POLL_NAME
        POLL_QUESTION
        POLL_START_DATE_TIME
        POLL_END_DATE_TIME
        POLL_CREATE_DATE_TIME
        POLL_STATUS
        POLL_CHOICE_ID_1
        POLL_CHOICE_1
        POLL_CHOICE_ID_2
        POLL_CHOICE_2
        POLL_CHOICE_ID_3
        POLL_CHOICE_3
        POLL_CHOICE_ID_4
        POLL_CHOICE_4
      }
      relatedTaxRewards {
        REST_ID
        TAX_RATE
        REWARD_PCT
        REWARD_BAL
        DISCOUNT_ID
        DISCOUNT_TYPE
        DISCOUNT_CODE
        DISCOUNT_RATE
        DISCOUNT_AMT
        ORDER_AMT_MIN
        DISCOUNT_MAX_USAGE_COUNT
        DISCOUNT_USE_COUNT
        APPLY_DEFAULT
      }
    }
  }
`;
export const onCreateUserLikeItem = /* GraphQL */ `
  subscription OnCreateUserLikeItem($MENU_IT_ID: String) {
    onCreateUSER_LIKE_ITEM(MENU_IT_ID: $MENU_IT_ID) {
      MENU_IT_ID
      LIKE_COUNT
    }
  }
`;
export const onCreateUserOrders = /* GraphQL */ `
  subscription OnCreateUserOrders($REST_ID: String) {
    onCreateUSER_ORDERS(REST_ID: $REST_ID) {
      REST_ID
      ORDER_ID
      CART_ID
      ORDER_STATUS
      ORDER_PICKUP_DATE_TIME
      ORDER_PAYMENT_ID
      DISCOUNT_ID
      DISCOUNT_APPLIED
      TAX_APPLIED
      ORDER_TOTAL_COST_BFR_TAX_BFR_DISC
      ORDER_TOTAL_FINAL_COST
      ORDER_COMMENTS
      ORDER_COMMENTS_BY
      USER_EMAIL
      ORDER_STATUS_DATE_TIME
      ORDER_TYPE
      PARTY_SIZE
      PAYMENT_STATUS
      USER_PHONE
      REWARDS_APPLIED
      CUSTOMER_NAME
      USER_OWNER
      REST_NAME
      TIP_AMOUNT
      PROCESSING_FEE
    }
  }
`;
export const onCreateUserOrdersForUser = /* GraphQL */ `
  subscription OnCreateUserOrdersForUser($REST_ID: String, $ORDER_ID: Int) {
    onCreateUSER_ORDERS_FOR_USER(REST_ID: $REST_ID, ORDER_ID: $ORDER_ID) {
      REST_ID
      ORDER_ID
      CART_ID
      ORDER_STATUS
      ORDER_PICKUP_DATE_TIME
      ORDER_PAYMENT_ID
      DISCOUNT_ID
      DISCOUNT_APPLIED
      TAX_APPLIED
      ORDER_TOTAL_COST_BFR_TAX_BFR_DISC
      ORDER_TOTAL_FINAL_COST
      ORDER_COMMENTS
      ORDER_COMMENTS_BY
      USER_EMAIL
      ORDER_STATUS_DATE_TIME
      ORDER_TYPE
      PARTY_SIZE
      PAYMENT_STATUS
      USER_PHONE
      REWARDS_APPLIED
      CUSTOMER_NAME
      USER_OWNER
      REST_NAME
      TIP_AMOUNT
      PROCESSING_FEE
    }
  }
`;
export const onCreateUserOrdersForUserEmail = /* GraphQL */ `
  subscription OnCreateUserOrdersForUserEmail($USER_EMAIL: String) {
    onCreateUSER_ORDERS_FOR_USER_EMAIL(USER_EMAIL: $USER_EMAIL) {
      REST_ID
      ORDER_ID
      CART_ID
      ORDER_STATUS
      ORDER_PICKUP_DATE_TIME
      ORDER_PAYMENT_ID
      DISCOUNT_ID
      DISCOUNT_APPLIED
      TAX_APPLIED
      ORDER_TOTAL_COST_BFR_TAX_BFR_DISC
      ORDER_TOTAL_FINAL_COST
      ORDER_COMMENTS
      ORDER_COMMENTS_BY
      USER_EMAIL
      ORDER_STATUS_DATE_TIME
      ORDER_TYPE
      PARTY_SIZE
      PAYMENT_STATUS
      USER_PHONE
      REWARDS_APPLIED
      CUSTOMER_NAME
      USER_OWNER
      REST_NAME
      TIP_AMOUNT
      PROCESSING_FEE
    }
  }
`;
export const onCreateRestUserOrders = /* GraphQL */ `
  subscription OnCreateRestUserOrders($REST_ID: String, $ORDER_STATUS: String) {
    onCreateREST_USER_ORDERS(REST_ID: $REST_ID, ORDER_STATUS: $ORDER_STATUS) {
      REST_ID
      ORDER_ID
      CART_ID
      ORDER_STATUS
      ORDER_PICKUP_DATE_TIME
      ORDER_PAYMENT_ID
      DISCOUNT_ID
      DISCOUNT_APPLIED
      TAX_APPLIED
      ORDER_TOTAL_COST_BFR_TAX_BFR_DISC
      ORDER_TOTAL_FINAL_COST
      ORDER_COMMENTS
      ORDER_COMMENTS_BY
      USER_EMAIL
      ORDER_STATUS_DATE_TIME
      ORDER_TYPE
      PARTY_SIZE
      PAYMENT_STATUS
      USER_PHONE
      REWARDS_APPLIED
      CUSTOMER_NAME
      USER_OWNER
      REST_NAME
      TIP_AMOUNT
      PROCESSING_FEE
    }
  }
`;
export const oncreateUserPollResponse = /* GraphQL */ `
  subscription OncreateUserPollResponse($REST_ID: String, $POLL_ID: Int) {
    oncreateUSER_POLL_RESPONSE(REST_ID: $REST_ID, POLL_ID: $POLL_ID) {
      REST_ID
      POLL_ID
      POLL_CHOICE1_ID
      POLL_CHOICE1_VAL
      POLL_CHOICE2_ID
      POLL_CHOICE2_VAL
      POLL_CHOICE3_ID
      POLL_CHOICE3_VAL
      POLL_CHOICE4_ID
      POLL_CHOICE4_VAL
      POLL_CHOICE1_VOTES
      POLL_CHOICE2_VOTES
      POLL_CHOICE3_VOTES
      POLL_CHOICE4_VOTES
      POLL_TOTAL_RESP
      POLL_CHOICE1_PERC
      POLL_CHOICE2_PERC
      POLL_CHOICE3_PERC
      POLL_CHOICE4_PERC
      USER_PREF_POLL_CHOICE_VAL
    }
  }
`;
export const oncreateSpecialMenuMasterWeb = /* GraphQL */ `
  subscription OncreateSpecialMenuMasterWeb($REST_ID: String) {
    oncreateSPECIAL_MENU_MASTER_WEB(REST_ID: $REST_ID) {
      MENU_IT_ID
      MENU_IT_NAME
      REST_ID
      REST_NAME
      CITY_NAME
      CUISINE_TYPE
      MENU_IT_PIC_LOCATION
      DATE_FROM_DATE
      DATE_TO_DATE
      MENU_IT_DESC
      MENU_IT_PRICE
      MENU_ID
      MENU_NAME
      MENU_DESC
      MENU_TYPE
      MENU_GR_ID
      MENU_GR_NAME
      MENU_GR_ORDER
      MENU_ORDER
      MENU_IT_ORDER
      USER_LIKE_YN
      USER_BOOKMARK_YN
      EVERYDAY_YN
      SUNDAY_YN
      MONDAY_YN
      TUESDAY_YN
      WEDNESAY_YN
      THURSDAY_YN
      FRIDAY_YN
      SATURDAY_YN
      EVENT_ALLDAY_YN
      EVENT_TIME
      MENU_IT_TAXABLE_YN
      relatedMenuItemLikes {
        MENU_IT_ID
        LIKE_COUNT
      }
    }
  }
`;
export const oncreateRestPollMaster = /* GraphQL */ `
  subscription OncreateRestPollMaster($REST_ID: String) {
    oncreateREST_POLL_MASTER(REST_ID: $REST_ID) {
      REST_ID
      POLL_ID
      POLL_NAME
      POLL_QUESTION
      POLL_START_DATE_TIME
      POLL_END_DATE_TIME
      POLL_CREATE_DATE_TIME
      POLL_STATUS
      POLL_CHOICE_ID_1
      POLL_CHOICE_1
      POLL_CHOICE_ID_2
      POLL_CHOICE_2
      POLL_CHOICE_ID_3
      POLL_CHOICE_3
      POLL_CHOICE_ID_4
      POLL_CHOICE_4
    }
  }
`;
export const oncreateRestOrderPrepTime = /* GraphQL */ `
  subscription OncreateRestOrderPrepTime($REST_ID: String) {
    oncreateREST_ORDER_PREP_TIME(REST_ID: $REST_ID) {
      REST_ID
      ORDER_PREP_MIN_TIME
      ORDER_PREP_MAX_TIME
      REST_OPERATION_STATUS
    }
  }
`;
export const onCreateMyType = /* GraphQL */ `
  subscription OnCreateMyType {
    onCreateMyType {
      id
      title
      content
      price
      rating
    }
  }
`;
export const onUpdateMyType = /* GraphQL */ `
  subscription OnUpdateMyType {
    onUpdateMyType {
      id
      title
      content
      price
      rating
    }
  }
`;
export const onDeleteMyType = /* GraphQL */ `
  subscription OnDeleteMyType {
    onDeleteMyType {
      id
      title
      content
      price
      rating
    }
  }
`;
