import {
    Box,
    Button,
    Modal,
    ModalHeader,
    ModalOverlay,
    ModalCloseButton,
    ModalBody,
    ModalContent,
    FormLabel,
    Input,
    FormControl,
    Select,
    HStack,
    Checkbox,
    Text

} from "@chakra-ui/react";
import React, { useRef, useState, useEffect } from "react";
import * as yup from 'yup';
import { Formik } from 'formik';



let ValidationSchema = yup.object().shape({

    label: yup
        .string()
        .required('Description is required'),
    menuLabel: yup
        .string()
        .required('Menu label is required'),
    required: yup
        .bool(),
    baseModifier: yup
        .bool(),
    // .required('price is required'),
    min: yup
        .number(),
    //.required('min required'),
    max: yup
        .number()
    //.required('max is required'),

})


function MenuCustomizePopup(props) {

    const initialRef = React.useRef()
    const finalRef = React.useRef()
    const [required, setRequired] = useState(props.object?.MUST_CHOOSE_FLAG);
    const [modifier, setModifier] = useState(props.object?.BASE_MODIFIER_IND);

    useEffect(() => {
        setModifier(props.object?.BASE_MODIFIER_IND);
    }, [props])

    return (
        <>
            <Modal
                size={'sx', 'md', 'xl', 'xl'}
                isOpen={props.open}
                onClose={props.close}
                scrollBehavior="inside"
                initialFocusRef={initialRef}

            >

                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Update Menu Choice</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <Formik
                            initialValues={{

                                label: props.object?.CH_DESC,
                                required: props.object?.MUST_CHOOSE_FLAG,
                                min: props.object?.MIN_QTY,
                                max: props.object?.MAX_QTY,
                                menuLabel: props.object?.CH_LABEL,
                            }}
                            validationSchema={ValidationSchema}
                            onSubmit={(value, { setSubmitting }) => {

                                const data = {
                                    ...props.object, CH_DESC: value.label, CH_LABEL: value.menuLabel, MUST_CHOOSE_FLAG: required,
                                    MIN_QTY: value.min, MAX_QTY: value.max, BASE_MODIFIER_IND: modifier,
                                }
                                props.SaveManuChoice(data)
                                setSubmitting(false)
                            }}
                        >
                            {(formik) => {
                                return (
                                    <form onSubmit={formik.handleSubmit} >

                                        <FormControl mt={4}>
                                            <FormLabel>Label</FormLabel>
                                            <Input
                                                type='text'
                                                onChange={formik.handleChange('label')}
                                                onBlur={formik.handleBlur('label')}
                                                value={formik.values.label}
                                                error={formik.errors.label}
                                                touched={formik.touched.label}
                                            />
                                            {formik.errors.label && formik.touched.label && <Text className="validationColor">{" "} {formik.errors.label}</Text>}
                                        </FormControl>

                                        <FormControl mt={4}>
                                            <FormLabel>Display Label</FormLabel>
                                            <Input
                                                type='text'
                                                onChange={formik.handleChange('menuLabel')}
                                                onBlur={formik.handleBlur('menuLabel')}
                                                value={formik.values.menuLabel}
                                                error={formik.errors.menuLabel}
                                                touched={formik.touched.menuLabel}
                                            />
                                            {formik.errors.label && formik.touched.label && <Text className="validationColor">{" "} {formik.errors.menuLabel}</Text>}
                                        </FormControl>

                                        <FormControl mt={4}>
                                            <Box style={{ display: 'none' }} d='flex'>
                                                <Checkbox mr='10px'
                                                    isChecked={required}
                                                    onChange={e => {
                                                      setRequired(e.target.checked)
                                                    }}

                                                >
                                                </Checkbox>
                                                <FormLabel mt='10px'>Required</FormLabel>
                                            </Box>
                                            <Box d='flex'>
                                                <Checkbox mr='10px'
                                                    style={{ pointerEvents: 'none' }}
                                                    isChecked={modifier}
                                                    onChange={e => {
                                                      setModifier(!modifier)
                                                    }}

                                                >
                                                </Checkbox>
                                                <FormLabel mt='10px'>Flex Price</FormLabel>
                                            </Box>
                                        </FormControl>


                                        <Box display='flex' >

                                            <FormControl mt={4} pr='10px'>
                                                <FormLabel >Max</FormLabel>
                                                <Input
                                                    type="number"
                                                    onChange={formik.handleChange('max')}
                                                    onBlur={formik.handleBlur('max')}
                                                    value={formik.values.max}
                                                    error={formik.errors.max}
                                                    touched={formik.touched.max}
                                                />
                                                {formik.errors.max && formik.touched.max && <Text className="validationColor">{" "} {formik.errors.max}</Text>}
                                            </FormControl>


                                            <FormControl mt={4}>
                                                <FormLabel >Min</FormLabel>
                                                <Input
                                                    type='number'
                                                    onChange={formik.handleChange('min')}
                                                    onBlur={formik.handleBlur('min')}
                                                    value={formik.values.min}
                                                    error={formik.errors.min}
                                                    touched={formik.touched.min}
                                                />
                                                {formik.errors.min && formik.touched.min && <Text className="validationColor">{" "} {formik.errors.min}</Text>}
                                            </FormControl>

                                        </Box>
                                        <Box className="popup-buttons">
                                            <Button bg="#00B2FF" className="secondary-btn" color='#fff' mr={2} onClick={props.close}>Cancel</Button>
                                            <Button className="primary-btn" type='submit' color='#fff' bg="#0554F2" ml={2}   >
                                                Save
                                            </Button>


                                        </Box>
                                    </form>
                                );
                            }}
                        </Formik>
                    </ModalBody>
                </ModalContent>

            </Modal>

        </>
    )
}

export default MenuCustomizePopup;
